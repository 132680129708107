<template lang="pug">
div.main
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="d-none" ref="image">
        <path d="M2.93904 13.5L7.15804 0.5L11.377 13.5L0.31604 5.461H14" fill="#FBBC04"/>
    </svg>
    canvas(v-if="width" ref="canva" :width="width" :height="height")
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            required: true,
            default: 250
        },
        leftIndicators: [],
        ratings: [],
        tendency: [],
    },
    data() {
        return {
            ctx: null,
            xAxisPositions: [],
            yAxisPositions: [],
            topOffset: 20,
            img: {
                googleStar: "@/assets/img/google_star.svg"
            },
            lineHeight: 40,
            columns: 6,
            intrisicOffset: 0,
            columnsContainerWidth: 0,
            savedCanvas: null,
        }
    },
    mounted() {
        this.ctx = this.$refs.canva.getContext("2d");
        this.ctx.font = '12px Sans-serif';

        this.calculatePoints();

        this.drawGrid();
        this.drawLeftIndicators();
        this.drawRowData();
    },
    computed: {
        height() {
            return (this.leftIndicators.length * this.lineHeight)
        },
        isSmallMobile() {
            return this.width < 500
        },
    },
    watch: {
        width() { }
    },
    methods: {
        setColorByTendency(tendency) {
            if (typeof tendency === "number") {
                if (tendency === 0) return "#DC9435"
                const sign = Math.sign(tendency)
                if (sign === -1) return "#FC303B"
                if (sign === 1) return "#58C263"
            }
            return "#8F8C8C"
        },
        calculatePoints() {
            const spaceBetweenStars = this.width / this.columns
            this.intrisicOffset = spaceBetweenStars / 2.5
            this.xAxisPositions = Array.from({ length: this.columns }, (_, index) => (index * spaceBetweenStars) + this.intrisicOffset)

            this.columnsContainerWidth = spaceBetweenStars * (this.columns - 2)
        },
        drawGrid() {
            this.ctx.strokeStyle = '#ddd';
            this.ctx.beginPath();

            this.xAxisPositions.forEach((point, index) => {
                if (index !== 0) {
                    this.ctx.moveTo(point, this.topOffset);
                    this.ctx.lineTo(point, this.height)
                }
            });

            this.xAxisPositions.forEach((point, index) => {
                if (index !== 0) {
                    this.writeTopIndicators(index, point, this.topOffset / 1.7)
                    this.drawSVGOnCanvas(this.$refs.image, point + 5, 0)
                }
            });
            this.ctx.stroke();
        },
        drawLeftIndicators() {
            this.leftIndicators.forEach((indicator, index) => {
                const yAxis = index === 0 ? this.height : this.height - (this.lineHeight * index + 1);
                this.yAxisPositions.push(yAxis)
                this.writeLeftIndicators(indicator, yAxis - this.topOffset / 3)
            });
        },
        drawRowData() {
            const maxPoint = this.xAxisPositions[this.xAxisPositions.length - 1]
            const percentageByColumn = (100 / (this.columns - 2)) / 100

            this.ratings.forEach((rating, index) => {
                if (rating >= 1) {
                    const fixedRating = rating.toFixed(1)
                    const ratingPercentage = (rating * 100) / (this.columns - 1)
                    const xEndPoint = ((ratingPercentage * maxPoint) / 100);
                    const lineOffset = this.lineHeight / 2

                    const isLessThanMiddleNote = rating < 3
                    const isLessThanUnit = rating < 1.5
                    const padding = this.isSmallMobile ? 0 : lineOffset;

                    const widthPercentage = ((rating - 1) * percentageByColumn) * 100
                    const width = ((widthPercentage * this.columnsContainerWidth) / 100) + padding;

                    this.ctx.moveTo(this.xAxisPositions[1] + padding, this.yAxisPositions[index] - lineOffset);
                    !isLessThanUnit && this.drawRoundedRect(this.xAxisPositions[1] - padding, this.yAxisPositions[index] - lineOffset, width, lineOffset, 10)

                    this.ctx.font = '14px Sans-serif';
                    this.ctx.fillStyle = '#000000';
                    this.ctx.textAlign = isLessThanUnit ? "middle" : "end"

                    const xTextPoint = isLessThanMiddleNote ? isLessThanUnit ? xEndPoint + 10 : xEndPoint : xEndPoint - 15
                    this.ctx.fillText(fixedRating, xTextPoint, this.yAxisPositions[index] - lineOffset / 3.5);
                    !isLessThanUnit && this.drawSVGOnCanvas(this.$refs.image, xTextPoint, this.yAxisPositions[index] - lineOffset)

                    this.ctx.fillStyle = this.setColorByTendency(this.tendency[index])
                    this.ctx.textAlign = "start"
                    if (typeof this.tendency[index] === "number") this.ctx.fillText(this.tendency[index]?.toFixed(2), xTextPoint + (isLessThanMiddleNote ? 30 : 25), this.yAxisPositions[index] - lineOffset / 3.5);
                }
            })
        },

        drawRoundedRect(x, y, width, height, radius) {
            this.ctx.beginPath();
            this.ctx.moveTo(x + radius, y);
            this.ctx.lineTo(x + width - radius, y);
            this.ctx.arc(x + width - radius, y + radius, radius, 1.5 * Math.PI, 2 * Math.PI);
            this.ctx.lineTo(x + width, y + height - radius);
            this.ctx.arc(x + width - radius, y + height - radius, radius, 0, 0.5 * Math.PI);
            this.ctx.lineTo(x + radius, y + height);
            this.ctx.arc(x + radius, y + height - radius, radius, 0.5 * Math.PI, Math.PI);
            this.ctx.lineTo(x, y + radius);
            this.ctx.arc(x + radius, y + radius, radius, Math.PI, 1.5 * Math.PI);
            this.ctx.closePath();
            this.ctx.fillStyle = '#DFD8D8'; // Set the fill color
            this.ctx.fill(); // Fill the rectangle
        },

        writeTopIndicators(text, xAxis, yAxis) {
            this.ctx.textAlign = "center"
            this.ctx.fillStyle = '#000000';
            this.ctx.fillText(text, xAxis, yAxis);
        },

        writeLeftIndicators(text, yAxis) {
            this.ctx.fillStyle = '#3A3A3A';
            this.ctx.textAlign = "left"
            this.ctx.fillText(text, this.isSmallMobile ? 0 : this.intrisicOffset, yAxis);
        },
        drawSVGOnCanvas(svgElement, xAxis, yAxis) {
            const svgData = new XMLSerializer().serializeToString(svgElement);
            const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
            const url = URL.createObjectURL(svgBlob);
            const img = new Image();

            img.onload = () => {
                this.ctx.drawImage(img, xAxis, yAxis, 15, 15);
                URL.revokeObjectURL(url);
            };
            img.src = url;
        }
    }
}
</script>

<style>
.main {
    background: #CECECE
}
</style>
