<template lang="pug">
    div
        l-tooltip(:options="{permanent: true, className: 'grPointDataMap', direction: 'center', offset: [0, 34] }") 
            strong {{ data.current_brand || data.name }} 
            p(:style="{'color': getColorByNoteNormalized({note: getNoteNormalized(data.review_rating_global),type: 'color'})}" style="margin-top:0; margin-bottom:0") 
                strong {{ getNoteNormalized(data.review_rating_global) | formatNumber }}
        l-popup.popupInfo
            div
                strong.brand {{ data.current_brand || data.brand_name }} 
            p.name(style="margin:0") {{ data.name }}
            .boxValoration
                span #[translate Valoration]
                span.boxNote(:style="{'background-color': getColorByNoteNormalized({note: getNoteNormalized(data.review_rating_global), type: 'color'})}" style="color:#fff;") {{ getNoteNormalized(data.review_rating_global) | formatNumber }} 
                strong {{ data.review_count }} 
                span #[translate Reviews]
            .boxFeeling
                .boxChart(v-if="data.feeling_percent_positive || data.feeling_percent_neutral || data.feeling_percent_negative")
                    ico-feeling.mr-2.icoCat(:block="false" width="12")
                    span.nameCat {{ $gettext('General feeling') }}
                    div.chart.d-flex
                        .positive(:style="{'width': data.feeling_percent_positive + '%', 'background-color': getColorNote(10,'color')}")
                            .percent {{ data.feeling_percent_positive | formatNumber(0,0) }}%
                        .neutral(:style="{'width': data.feeling_percent_neutral + '%', 'background-color': getColorNote(6,'color')}")
                            .percent {{ data.feeling_percent_neutral | formatNumber(0,0) }}%
                        .negative(:style="{'width': data.feeling_percent_negative + '%', 'background-color': getColorNote(1,'color')}")
                            .percent {{ data.feeling_percent_negative | formatNumber(0,0)  }}%
                .boxChart( v-for="(cat, index) in data.feeling_by_categories" :key="index" v-if="cat.positive || cat.neutral || cat.negative")
                    ico-environment.mr-2.icoCat(v-if="cat.name.toLowerCase() === 'ambiente' || cat.name.toLowerCase() === 'ambiente'" :block="false" width="12")
                    ico-kitchen.mr-2.icoCat(v-if="cat.name.toLowerCase() === 'cocina' || cat.name.toLowerCase() === 'cozinha'" :block="false" width="12")
                    ico-price.mr-2.icoCat(v-if="cat.name.toLowerCase() === 'precio' || cat.name.toLowerCase() === 'preço'" :block="false" width="12")
                    ico-health.mr-2.icoCat(v-if="cat.name.toLowerCase() === 'salud e higiene' || cat.name.toLowerCase() === 'saúde e higiene'" :block="false" width="12")
                    ico-service.mr-2.icoCat(v-if="cat.name.toLowerCase() === 'servicio' || cat.name.toLowerCase() === 'serviço'" :block="false" width="12")
                    ico-order.mr-2.icoCat(v-if="cat.name.toLowerCase() === 'pedido'" :block="false" width="12")
                    ico-product.mr-2.icoCat(v-if="cat.name.toLowerCase() === 'producto'" :block="false" width="12")
                    ico-time.mr-2.icoCat(v-if="cat.name.toLowerCase() === 'tiempo'" :block="false" width="12")
                    span.nameCat {{ cat.name }}
                    div.chart.d-flex
                        .positive(v-if="cat.positive" :style="{'width': cat.positive + '%', 'background-color': getColorNote(10,'color')}")
                            .percent {{ cat.positive | formatNumber(0,0) }} %
                        .neutral(v-if="cat.neutral" :style="{'width': cat.neutral + '%', 'background-color': getColorNote(6,'color')}")
                            .percent {{ cat.neutral | formatNumber(0,0)  }} %
                        .negative(v-if="cat.negative" :style="{'width': cat.negative +'%', 'background-color': getColorNote(1,'color')}")
                            .percent {{ cat.negative | formatNumber(0,0)  }} %
</template>

<script>
import { LPopup, LTooltip } from "vue2-leaflet"
import commonMixins from "@/mixins/common.js"
import IcoEnvironment from "@/components/common/IcoEnvironment.vue"
import IcoKitchen from "@/components/common/IcoKitchen.vue"
import IcoService from "@/components/common/IcoService.vue"
import IcoPrice from "@/components/common/IcoPrice.vue"
import IcoHealth from "@/components/common/IcoHealth.vue"
import IcoFeeling from "@/components/common/IcoFeeling.vue"
import IcoOrder from "@/components/common/IcoOrder.vue"
import IcoProduct from "@/components/common/IcoProduct.vue"
import IcoTime from "@/components/common/IcoTime.vue"
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    mixins: [commonMixins],
    components: {
        LPopup,
        LTooltip,
        IcoEnvironment,
        IcoKitchen,
        IcoService,
        IcoPrice,
        IcoHealth,
        IcoFeeling,
        IcoOrder,
        IcoProduct,
        IcoTime
    }
}
</script>
<style lang="scss" scoped>
.popupInfo {
    width: 266px;
    padding: 5px;

    .brand {
        font-size: 0.875rem;
        color: #222226;
        font-weight: 600;
    }

    .name {
        font-size: 0.875rem;
        color: #222226;
    }

    .boxValoration {
        background-color: #F3F2F2;
        font-size: 0.875rem;
        padding: 10px 5px;
        margin-top: 12px;

        .label {
            font-weight: 600;
            margin-right: 5px;
        }

        .boxNote {
            border-radius: 5px;
            color: #fff;
            padding: 5px;
            text-align: center;
            margin: 0 5px;
        }
    }

    .boxFeeling {
        margin-top: 15px;

        .boxChart {
            margin-bottom: 7px;

            .icoCat,
            .nameCat {
                vertical-align: middle;
            }

            .chart {

                .positive,
                .neutral,
                .negative {
                    height: 12px;
                    position: relative;

                    .percent {
                        text-align: center;
                        font-size: 12px;
                        line-height: 1.2;
                        color: #fff;
                    }
                }

                .neutral {
                    border-right: 1px solid #fff;
                    border-left: 1px solid #fff;
                }
            }
        }
    }
}</style>