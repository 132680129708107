<template lang="pug">
div
  .gridFull(v-if="isLoading")
    v-skeleton-loader(type="table")
  div.gridFull.narrow(v-else)
    v-row.headTitleGrid.relative
      v-col.titleGrid(md="3")
        translate(v-if="!isGenericEntity") Restaurant Ranking
        span(v-else) Ranking de negocios
      v-col.mx-auto.d-flex.items-center.justify-center.py-3(md="6")
        div
          .d-flex.align-baseline(style="font-size: 0.875rem;")
            .block-info(v-if="dataAverage.brand_avg")
              translate Valoración media de la Marca:
              v-chip.chipInfo.mx-2(style="font-size: 14px; color: white;" :color="getColorByNoteNormalized({note: getNoteNormalized(dataAverage.brand_avg), type:'color'})" label) {{getNoteNormalized(dataAverage.brand_avg) | formatNumber(1,1)}}
            .block-info.ml-3(v-if="dataAverage.avg_revs")
              translate Average by number of opinions:
              span.font-weight-med.mx-2 {{getNoteNormalized(dataAverage.avg_revs) | formatNumber(1,1)}}


      v-col.text-right(md="3")
        span.downloadExcel.mr-5(@click="downloadExcel()")
          img(:src="imgCommons.download" width="10")
    v-row.headIndicators.text-left.align-center
      v-col.ml-2.px-1
        translate Establishment
        img.ml-2.cursor-pointer(v-if="typeNode !== 'center'" :src="imgCommons.order" @click="sortAppointments('name')")
      v-col.px-0(v-if="zoneColumnEnabled")
        translate(v-if="level.company") Marca
        translate(v-if="level.brand || level.zone") Zona
      v-col.col-number.text-center(v-for="(item, index) in itemsMonth" :class="{'px-0' : typeNode !== 'center' }")
        span.text-capitalize {{item | moment("DD/MM/YYYY") }}
        img.ml-2.cursor-pointer(v-if="typeNode !== 'center'" :src="imgCommons.order" @click="sortAppointments(index)")
    v-row.bodyIndicators.text-left.align-center.py-1.my-0(v-if="dataRanking" v-for="(item, index) in paginated" :class="{'b-top' : index > 0, 'row-average': item.average}")
      v-col.ml-2.py-0.px-1.text-truncate
        span.link-text(v-if="typeNode !== 'center'" @click="goItemNextLevel(item,true)") {{item.name}}
        span(v-else) {{item.name}}
      v-col.ml-2.pa-0(v-if="zoneColumnEnabled")
        span {{item.region}}
      v-col.pa-0.col-number.text-center(v-for="(elem, index) in item.weekly_rating_global")
        v-chip.chipInfo.mx-2.white--text(v-if="elem" :color="getColorByNoteNormalized({note: elem, type: 'color'})" label small) {{elem | formatNumber}}
        span.mx-2(v-if="!elem") {{elem | formatNumber}}
        p.mb-0 {{ item.weekly_n_reviews[index] }}

</template>

<script>
import SrvNetwork from '@/services/network.js'
import commonMixins from "@/mixins/common.js"
export default {
  props: ['paramCOD'],
  mixins: [commonMixins],
  data() {
    return {
      current: 1,
      pageSize: 20,
      dataAverage: [],
      dataRanking: [],
      sortParam: 0,
      sortDesc: true,
      helpValorationPositioningLastYear: this.$gettext('It is the same concept as the previous graph, shown in an annual date range')
    }
  },
  created() {
    if (this.$store.getters.getNode > 0) {
      this.getPositioningLast()
    }

  },
  mounted() {
    this.scroll()
  },
  computed: {
    itemsMonth() {
      return this.dataRanking.length > 0 ? this.dataRanking[0].weeks : []
    },
    indexStart() {
      return (this.current - 1) * this.pageSize
    },
    indexEnd() {
      return this.indexStart + this.pageSize
    },
    paginated() {
      let arrFiltered = this.dataRanking.slice()
      arrFiltered = this.sortArray(arrFiltered)

      arrFiltered = arrFiltered.slice(0, this.indexEnd)

      return arrFiltered
    },
    zoneColumnEnabled() {
      return this.level.company || this.level.brand || this.level.zone
    }
  },
  watch: {
    filterApply: function () {
      this.getPositioningLast()
    },

  },
  methods: {
    sortArray(arrFiltered) {
      // Sort array
      if (this.sortParam !== '') {
        if (this.sortParam === 'name') {
          arrFiltered = arrFiltered.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            // a must be equal to b
            return 0;
            // return b.name - a.name
          })
        } else {
          arrFiltered = arrFiltered.sort((a, b) => {
            return b.weekly_rating_global[this.sortParam] - a.weekly_rating_global[this.sortParam]
          })
        }
        if (!this.sortDesc) {
          arrFiltered = arrFiltered.reverse()
        }
      }

      return arrFiltered
    },
    // OBTENEMOS INDICADORES
    getPositioningLast() {
      this.$store.commit('LOADING', true)
      SrvNetwork.getPositioningLast12Weeks()
        .then(res => {
          if (res.status === 200) {
            if (this.isAdverbia) {
              res.data.centers.map(e => e.weekly_rating_global = e.weekly_rating_global.map(e => e = e ? e / 2 : e))
            }
            this.dataRanking = res.data.centers
            this.dataAverage = res.data.brand
            this.$store.commit('LOADING', false)
          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    next() {
      this.current++;
    },
    scroll() {
      let wrapper = document.getElementById("app");
      let bottomOfWindow = false
      window.onscroll = () => {
        if (parseInt(document.documentElement.scrollTop) + window.innerHeight === wrapper.offsetHeight || parseInt(document.documentElement.scrollTop) + 1 + window.innerHeight === wrapper.offsetHeight) {
          bottomOfWindow = true
        } else {
          bottomOfWindow = false
        }
        if (bottomOfWindow) {
          this.current++
        }
      };
    },
    sortAppointments(param) {
      this.sortDesc = this.sortParam === param ? !this.sortDesc : true
      this.sortParam = param
    },
    calculateMonth(i) {
      var makeDate = new Date()
      makeDate.setMonth(makeDate.getMonth() - i, 1)
      const month = makeDate.toLocaleString('default', { month: 'short' })
      return month + makeDate.getFullYear().toString().substr(-2)

    }

  }
}
</script>

<style lang="scss" scoped>
.bodyIndicators,
.headIndicators {
  .col-number {
    max-width: 6%;
    min-width: 70px;
  }

}

.gridFull {
  .row {
    margin: 0 -12px;

    &.bodyIndicators {
      height: auto;
    }
  }
}
</style>
