<template lang="pug">
    div
        v-dialog.dialogEdit(v-model="show"
            width="680")
            v-card.dialogEdit
                v-card-text.pb-0
                v-container
                    div.gr-loader-snipper--wrapper(v-if="loading")
                        span.gr-loader-snipper
                    v-row#headerModalEdit.pt-3
                        v-col(cols="10")
                            span #[translate Opening times]: 
                            span {{  type }}
                        v-col.mt-5(cols="2")
                            img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                    v-row#bodyModalEdit(v-if="data && data.googleopenday")
                        v-col.text-left(cols="12")                            
                            v-container
                                v-row
                                    v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                                        div.messageError
                                            span {{ messageError ? messageError : $gettext('An error occurred while recording.') }}
                                
                                v-row.boxDay(v-for="(day, indexDay) in Object.keys(data.googleopenday)")
                                    v-col.text-left.pt-6.pb-0(cols="12")
                                        strong {{ data.googleopenday[day].name }}
                                    v-col.text-left.pt-0.pb-0(cols="4")
                                        .optionsFullEditModal
                                            input(type="radio" v-model="data.googleopenday[day].isclosed" :id="'cerrado' + day + 'Si'" value="false" :name="'cerrado' + day" ) 
                                            label.ml-2(:for="'cerrado' + day + 'Si'") #[translate Open]    
                                            input.ml-6(type="radio" v-model="data.googleopenday[day].isclosed" :id="'cerrado' + day + 'No'" value="true" :name="'cerrado' + day") 
                                            label.ml-2(:for="'cerrado' + day + 'No'") #[translate Closed]
                                    v-col.text-left.pt-0.pb-0.pr-0(cols="5")
                                        .rangeHours.mb-2(v-for="(hours, index) in data.googleopenday[day].times")
                                            input.time(type="time" v-model="hours.starttime") 
                                            span  - 
                                            input.time(type="time" v-model="hours.endtime")
                                            img.ml-2(:src="imgtrash" width="13" @click="deleteHours(day,index)")
                                    v-col.btnAddHours.text-right.pt-3.pb-5(cols="3")
                                        span(@click="addMoreHours(day)") #[translate Add more hours]
                                            img.ml-2.pt-3(:src="imgPlusHours")
                                
                                
                                
                                
                    v-row#footerModalEdit
                        v-col(cols="12")
                            button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
                            button.btnSaveEditModal(@click="save()")  #[translate Save]
</template>
<script>
import commonMixins from '@/mixins/common.js'
import SrvLocal from '@/services/local.js'

export default {
    mixins: [commonMixins],
    props: ['visible','idBussines','type'],
    data(){
        return {
            loading: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            imgtrash: require('@/assets/img/ico-trash.svg'),
            imgPlusHours: require('@/assets/img/ico-plus-hours.svg'),
            name: '',
            data: {},
            maxRangeHours: 4,
            section: 'hours-restaurant',
            saveStatus: true,
            messageError: null
        }
    },
    computed: {
        sectionByType(){
            return this.type ? 'morehours-restaurant' : 'hours-restaurant'
        },
        show: {
            get () {
                return this.visible
            },
            set (value) {
                if (!value) {
                this.$emit('close')
                }
            }
        }
    },
    watch: {
        visible (newValue){
            if (newValue) {
                this.data = {}
                this.loadData()
            }
        }
    },
    methods: {
        addMoreHours (day) {
            if (this.data.googleopenday[day].times && this.data.googleopenday[day].times.length < this.maxRangeHours) {
                this.data.googleopenday[day].times.push({endtime: "",starttime: ""})
            }
        },
        deleteHours (day, index) {
            this.data.googleopenday[day].times.splice(index,1)
        },
        async loadData () {
            //CARGAMOS DATOS
            this.loading = true            
            const res = await SrvLocal.getLocalMarketing(this.sectionByType)

                if (res.status === 200) {
                    if (this.type) {
                        
                        if (res.data.googleextraopendaytype.filter(e => e.hour_type === this.type).length > 0) {
                            this.data = {googleopenday: res.data.googleextraopendaytype.filter(e => e.hour_type === this.type)[0].days }
                        } else {
                            this.data = {googleopenday: {"MONDAY":{"name":"LUNES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"TUESDAY":{"name":"MARTES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"WEDNESDAY":{"name":"MIÉRCOLES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"THURSDAY":{"name":"JUEVES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"FRIDAY":{"name":"VIERNES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"SATURDAY":{"name":"SÁBADO","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"SUNDAY":{"name":"DOMINGO","isclosed":false,"times":[{"starttime":null,"endtime":null}]}}}
                        }
                    } else {
                        this.data = res.data
                    }
                    this.loading = false
                } 
        
        },
        async save () {
            this.loading = true
            let newFormatWeek = this.data
            if (this.type) {
                newFormatWeek = {"googleextraopendaytype": [{"hour_type": this.type, days: this.data.googleopenday}]}
            }
            await SrvLocal.setLocalMarketing(this.sectionByType, newFormatWeek)
            .then(res => {
                if (res.status !== 200) {
                   this.saveStatus = false             
                }
            }, () => {
            })
            if (this.saveStatus) {
                const res = await SrvLocal.syncBunsiness(this.idBussines, 'only_hours')
                if (Object.keys(res.data).includes('success') && !res.data.success) {
                    this.saveStatus = false
                    this.messageError = res.data.error.message
                } else {
                    this.show = false
                    this.$emit('refreshSync')
                }
            }
            this.loading = false
        }
    }
}
</script>
<style lang="scss" scoped>
    #bodyModalEdit{
        .optionsFullEditModal{
            background-color: #F2F2F2;
            border:0;
        }
        
    }
    .messageError{
        background-color: #F5B1B8;
        border: 1px solid #F93549;
        color: #F93549;
        padding:15px;
    }
</style>