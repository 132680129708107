// Stores
// import Stores from '@/store/index'
// // importamos los tipos de mutación de vuex
// import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'

//Store
// import store from '@/store/index'

// funciones comunes
import SrvCommon from '@/services/common'
// Axios
import axios from 'axios'
//axios.defaults.baseURL = appConfig.apiGastroPricing
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  getCountries () {
    axios.defaults.headers.common['Authorization'] = 'Token ' + SrvCommon.getToken()
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'user-countries/')
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getBrands () {
    axios.defaults.headers.common['Authorization'] = 'Token ' + SrvCommon.getToken()
    let params = {}
    params.country = window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'user-brands/',{params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getProvinces (brand) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + SrvCommon.getToken()
    let params = {}
    params.country = window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'brand-province/'+ brand + '/',{params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getCities (brand, province) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + SrvCommon.getToken()
    let country = ''    
    country = '&country='+window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'brand-city/' + brand + '/?province=' + province + country )
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getCps (brand, city) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + SrvCommon.getToken()
    let country = ''
    country = '&country='+window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'brand-postal-code/' + brand + '/?city=' + city + country)
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getChannels () {
    axios.defaults.headers.common['Authorization'] = 'Token ' + SrvCommon.getToken()
    let params = {}
    params.country = window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'channel/',{params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getMoments (brand) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + SrvCommon.getToken()
    let params = {}
    params.country = window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'moment-of-consumption/' + brand + '/', {params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getAggregators () {
    axios.defaults.headers.common['Authorization'] = 'Token ' + SrvCommon.getToken()
    let params = {}
    params.country = window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'delivery-platform/',{params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getFamilies (brand) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + SrvCommon.getToken()
    let params = {}
    params.country = window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {     
      axios.get(appConfig.apiGastroPricing + 'brand-family/' + brand + '/',{params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getPromotionsItems (brand, filters) {
    let params = {}
    if(filters.province.province_id){
      params.province = filters.province.province_id
    }
    if(filters.city.city_id){
      params.city = filters.city.city_id
    }
    params.country = window.localStorage.getItem('_country_selected_gp')

    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'promotions/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  }, 
  getPromotionsItemModalData (brand, menu_item_id, delivery_platform_id, promotion_label = '', status = '', province = '', city = '') {
    let params = {}
    if(province){
      params.province = province.province_id
    }
    if(city){
      params.city = city.city_id
    }
    if(status !== ''){
      params.promotion_status = status
    }
    params.promotion_label = promotion_label
    params.country = window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'promotion-detail/'+ brand + '/' + delivery_platform_id + '/' + menu_item_id + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getPromotionsItemsFinished (brand, filters) {
    let params = {}
    if(filters.province.province_id){
      params.province = filters.province.province_id
    }
    if(filters.city.city_id){
      params.city = filters.city.city_id
    }
    params.promotion_status = 'finished'
    params.country = window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'promotions/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getPromotionsItemsNews (brand, filters) {
    let params = {}
    if(filters.province.province_id){
      params.province = filters.province.province_id
    }
    if(filters.city.city_id){
      params.city = filters.city.city_id
    }
    params.promotion_status = 'new'
    params.country = window.localStorage.getItem('_country_selected_gp')
    
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'promotions/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getVariationPrice (brand, filters) {
    let params = {}
    if(filters.channel.id){
      params.channel = filters.channel.id
    }
    if(filters.province && filters.province.province_id){
      params.province = filters.province.province_id
    }
    if(filters.city && filters.city.city_id){
      params.city = filters.city.city_id
    }
    if(filters.municipality && filters.municipality.municipality_id){
      params.municipality = filters.municipality.municipality_id
    }    
    if(filters.family){
      params.family = filters.family.id
    }
    if(filters.variation_type){
      params.variation_type = filters.variation_type
    }
    params.country = window.localStorage.getItem('_country_selected_gp')
    axios.defaults.headers.common['Authorization'] = 'Token ' + SrvCommon.getToken()
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'menu-item-price-variation-ranking/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
  getMenuItems (brand, filters) {
    let params = {}
    if(filters.province && filters.province.province_id){
      params.province = filters.province.province_id
    }
    if(filters.city && filters.city.city_id){
      params.city = filters.city.city_id
    }
    if(filters.municipality && filters.municipality.municipality_id){
      params.municipality = filters.municipality.municipality_id
    }
    if(filters.channel && filters.channel.id){
      params.channel = filters.channel.id
    }
    if(filters.aggregator && filters.aggregator.id){
      params.delivery_platform = filters.aggregator.id
    }
    if(filters.family){
      params.family = filters.family.id
    }
    params.country = window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'menu-item/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getAggregatorsRanking (brand, filters) {
    let params = {}
    if(filters.province.province_id){
      params.province = filters.province.province_id
    }
    if(filters.city && filters.city.city_id){
      params.city = filters.city.city_id
    }
    if(filters.municipality && filters.municipality.municipality_id){
      params.municipality = filters.municipality.municipality_id
    }
    if(filters.family){
      params.family = filters.family.id
    }
    params.country = window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'menu-item-per-delivery-platform/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getModaldata (data) {
    let params = {}
    if(data.PROVINCE_ID){
      params.province = data.PROVINCE_ID
    }
    if(data.DELIVERY_PLATFORM_ID){
      params.delivery_platform = data.DELIVERY_PLATFORM_ID
    }
    if(data.CHANNEL){
      params.channel = data.CHANNEL.id
    }
    params.country = window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'menu-item-price-variation-history/'+ data.BRAND_ID + '/' + data.MENU_ITEM_ID + '/',{params})
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getProvincesRanking (brand, filters) {
    let params = {}
    if(filters.family){
      params.family = filters.family.id
    }
    if(filters.channel.id){
      params.channel = filters.channel.id
    }
    if(filters.aggregator.id){
      params.delivery_platform = filters.aggregator.id
    }
    params.country = window.localStorage.getItem('_country_selected_gp')
    return new Promise((resolve, reject) => {
      axios.get(appConfig.apiGastroPricing + 'menu-item-per-city/'+ brand + '/',{ params })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getPizzasRanking (brand, filters, excel = false) {    
    return new Promise((resolve, reject) => {
      // axios.get(appConfig.apiGastroPricing + 'pizza-comparator/'+ brand + '/',{ params })
      let params = {}
      if(filters.province.province_id){
        params.province = filters.province.province_id
      }
      let configHeaders = {}
      let query = appConfig.apiGastroPricing + 'pizza-comparator/'+ brand
      if (excel) {
        query += `/?download=True`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }
      configHeaders.params = params
      axios.get(query, configHeaders )
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  // neuvos
  getPizzasPromotions (filters) {    
    return new Promise((resolve, reject) => {
      // "zone", "pizza_dough", "pizza_type", "type", "promo_date", "product_name"
      let params = ''
      if (filters) {
        if (filters.zone) {
          params += params === '' ? '?' : '&'
          params += 'zone=' + filters.zone
        }
        if (filters.pizza_dough) {
          params += params === '' ? '?' : '&'
          params += 'pizza_dough=' + filters.pizza_dough
        }
        if (filters.pizza_type) {
          params += params === '' ? '?' : '&'
          params += 'pizza_type=' + filters.pizza_type
        }
        if (filters.type) {
          params += params === '' ? '?' : '&'
          params += 'type=' + filters.type
        }
        if (filters.promo_date) {
          params += params === '' ? '?' : '&'
          let partes = filters.promo_date.split('-')
          params += 'promo_date__year=' + partes[0]
          params += '&promo_date__week=' + partes[1]
        }
        if (filters.product_name) {
          params += params === '' ? '?' : '&'
          params += 'product_name=' + filters.product_name
        }
      }
      let query = appConfig.apiGastroPricing + 'pizza-promotions/' + params
      axios.get(query )
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getPizzasPromotionsChart (filters) {    
    return new Promise((resolve, reject) => {
      // "zone", "pizza_dough", "pizza_type", "type", "pizza_size", "service", "aggregator", "product_name"
      let params = ''
      if (filters) {
        if (filters.zone) {
          params += params === '' ? '?' : '&'
          params += 'zone=' + filters.zone
        }
        if (filters.pizza_dough) {
          params += params === '' ? '?' : '&'
          params += 'pizza_dough=' + filters.pizza_dough
        }
        if (filters.pizza_type) {
          params += params === '' ? '?' : '&'
          params += 'pizza_type=' + filters.pizza_type
        }
        if (filters.type) {
          params += params === '' ? '?' : '&'
          params += 'type=' + filters.type
        }
        if (filters.pizza_size) {
          params += params === '' ? '?' : '&'
          params += 'pizza_size=' + filters.pizza_size
        }
        if (filters.aggregator) {
          params += params === '' ? '?' : '&'
          params += 'aggregator=' + filters.aggregator
        }
        if (filters.service) {
          params += params === '' ? '?' : '&'
          params += 'service=' + filters.service
        }
        if (filters.promo_date) {
          params += params === '' ? '?' : '&'
          let partes = filters.promo_date.split('-')
          params += 'promo_date__year=' + partes[0]
          params += '&promo_date__week=' + partes[1]
        }
        if (filters.product_name) {
          params += params === '' ? '?' : '&'
          params += 'product_name=' + filters.product_name
        }
      }
      let query = appConfig.apiGastroPricing + 'pizza-promotions-chart/' + params
      axios.get(query )
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  },
  getPizzasPromotionsExtraData () {
    return new Promise((resolve, reject) => {
      let query = appConfig.apiGastroPricing + 'pizza-promotions-extra-data/'
      axios.get(query )
        .then(res => {
          resolve(res)
        })
        .catch(error => {
           reject(error.response)
        })
    })
  }
}