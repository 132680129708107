<template lang="pug">
div.boxSectionHeaderAndBody
  div#navtop(:class="{'anchoEscritorioSubnavCerrado': subNavClosed, 'noTree': $route.meta.isGP}")
    div.sectionmenu
      div.firstlevel
        router-link(:to="{name: 'GlobalSummary', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Global Summary]
        //router-link(v-if="$config.featureConfig.globalGastroRanking && !is_alsea && !['customers','healthypoke','restalia','gastroagencia'].includes(nameInstance) && !isInstanceHotels" :to="{name: 'GlobalGastroRanking', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Gastroranking]
        select-country-and-logout
  div.gridSection
    div.sectionmenu
      filters-and-bread-crumb(:paramCOD="paramCOD")
      router-view.bg-gray

</template>

<script>
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import FiltersAndBreadCrumb from "@/components/filters/filtersAndBreadCrumb.vue"
import SelectCountryAndLogout from "@/components/common/SelectCountryAndLogout.vue"
export default {
  props: ['paramCOD'],
  mixins: [commonMixins],
  data() {
    return {
    }
  },
  components: { FiltersAndBreadCrumb, SelectCountryAndLogout },
  watch: {
    filterForSites: function () {
      this.$store.dispatch('getSitesOpinions')
    }
  },
  computed: {
    isDeliverySelected() {
      if (this.$store.state.common.sitesSelected.value === 'delivery') {
        return { "value": 'delivery', "text": "Delivery" }
      }
      return { "value": 'internet', "text": "Internet" }
    }
  },
  async created() {
    this.$store.commit(types.UPDATE_SECTION, 0)
    if (this.regions.length === 0) {
      await this.$store.dispatch('getRegions')
    }
    this.loadUrlParams()

  }
}
</script>

