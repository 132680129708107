<template lang="pug">
.block-user.d-flex.mt-0.mr-6.align-center.relative
    popup-notification-stats(v-if="hasPremium && $store.getters.getNotificationStats && $store.getters.getNotificationStats.total")
    a.text-no-wrap(v-if="isStaff && getBackLocal()" :href="getBackLocal()" target="_blank" id="btnStaffGoBack") < Back
    v-menu(v-if="$config.featureConfig.showCountryMenu && optionsTree.length > 1 && !$config.featureConfig.showAllTreesInSubnav()" transition="slide-y-transition" bottom offset-y z-index="999999")
        template(v-slot:activator="{ on, attrs }")
            v-btn.mr-2.btn-lang.text-capitalize( v-bind="attrs" v-on="on" text tile small)
                v-img.mr-2(:src="currentTree.image" width="15" height="15")
                span(v-if="currentTree.name == 'customers_es' || currentTree.name == 'grupo_mambo_es'") Restaurantes 
                span(v-else-if="currentTree.name == 'moga_hotels' || currentTree.name == 'grupo_mambo_hotels_es'") Hoteles
                span(v-else) {{currentTree.name}}
        v-list.py-0.text-right.list-options-tree
            v-list-item.py-0.px-4(v-if="currentTree.name !== item.name" v-for="(item, i) in optionsTree" :key="i" @click="setTree(item)")
                v-list-item-title.text.text-left.d-flex.align-center
                    img.mr-3(:src="item.image")
                    span(v-if="item.name == 'customers_es' || item.name == 'grupo_mambo_es'") Restaurantes 
                    span(v-else-if="item.name == 'moga_hotels' || item.name == 'grupo_mambo_hotels_es'") Hoteles
                    span(v-else) {{item.name}}
    p.user-name.mb-0.mr-3(class="text-[#222]") {{userName}}
    v-img(class="ml-auto mr-5 cursor-pointer" :src="imgCommons.power" max-height="16" max-width="14" @click="logout()")
</template>
<script>
import commonMixins from "@/mixins/common.js"
import localMixins from '@/mixins/local.js'
import PopupNotificationStats from "@/components/local/PopupNotificationStats.vue"

export default {
    mixins: [commonMixins, localMixins],
    components: { PopupNotificationStats },
    data() {
        return {
            showPopup: false
        }
    },
    methods: {
        getBackLocal() {
            if (document.querySelector('.tree-active') && document.querySelector('.tree-active').getAttribute('data-backurl')) {
                return document.querySelector('.tree-active').getAttribute('data-backurl')
            }
        }
    },
    computed: {
        showPopupNotificationsStats() {
            if (this.readCookie('popupnotificationstats_' + this.$store.getters.getNode)) {
                return false
            } else {
                return true
            }
        }
    },

}
</script>
<style lang="scss" scoped>
#btnStaffGoBack {
    color: #fff;
    background-color: #4A90E2;
    padding: 0 10px;

    &:hover {
        border-bottom: none;
    }
}

.list-options-tree .v-list-item {
    min-height: auto;

    img {
        width: 15px;
    }
}
</style>