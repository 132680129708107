<template>
    <div class="rangeDatesDefaults" id="rangeDatesDefaults">
        <button @click="show = !show">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width="1em" height="1em"><path d="M940.218182 107.054545h-209.454546V46.545455h-65.163636v60.50909H363.054545V46.545455H297.890909v60.50909H83.781818c-18.618182 0-32.581818 13.963636-32.581818 32.581819v805.236363c0 18.618182 13.963636 32.581818 32.581818 32.581818h861.090909c18.618182 0 32.581818-13.963636 32.581818-32.581818V139.636364c-4.654545-18.618182-18.618182-32.581818-37.236363-32.581819zM297.890909 172.218182V232.727273h65.163636V172.218182h307.2V232.727273h65.163637V172.218182h176.872727v204.8H116.363636V172.218182h181.527273zM116.363636 912.290909V442.181818h795.927273v470.109091H116.363636z"></path></svg>
            Periodo
        </button>
        <div v-if="show" class="options">
            <ul>
                <li @click="applyRange('weekprevious')">Semana anterior</li>
                <li @click="applyRange('month')">Mes actual</li>
                <li @click="applyRange('monthprevious')">Mes anterior</li>
                <li @click="applyRange('year')">Año actual</li>
                <li @click="applyRange('yearprevious')">Año anterior</li>
                <li @click="applyRange('historyGlobal')">Histórico global</li>
            </ul>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    data() {
        return {
            show: false
        }
    },
    computed: {
        semanaAnterior() {
            return [moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')]
        },
        mesActual() {
            return [moment().format('YYYY-MM-01'), moment().format(`YYYY-MM-${moment().daysInMonth()}`)]
        },
        mesAnterior() {
            return [moment(moment().subtract(1, 'month')).format('YYYY-MM-01'), moment(moment().subtract(1, 'month')).format(`YYYY-MM-${moment(moment().subtract(1, 'month')).daysInMonth()}`)]
        },
        anoActual() {
            return [moment().format('YYYY-01-01'), moment().format('YYYY-12-31')]
        },
        anoAnterior() {
            return [moment().format(`${moment().subtract(1, 'year').format('YYYY')}-01-01`), moment().format(`${moment().subtract(1, 'year').format('YYYY')}-12-31`)]
        },
        historyGlobal() {
            return [moment().format('2010-01-01'), moment().format('YYYY-12-31')]
        }
    },
    methods: {
        applyRange(key) {
            let dates
            if (key === 'weekprevious') {
                dates = this.semanaAnterior
            }
            if (key === 'month') {
                dates = this.mesActual
            }
            if (key === 'monthprevious') {
                dates = this.mesAnterior
            }
            if (key === 'year') {
                dates = this.anoActual
            }
            if (key === 'yearprevious') {
                dates = this.anoAnterior
            }
            if (key === 'historyGlobal') {
                dates = this.historyGlobal
            }
            this.show = false
            let queryUpdate = Object.assign({}, this.$route.query);
            queryUpdate.after = dates[0]
            queryUpdate.before = dates[1]
            this.$router.replace({name: this.$route.name, params: this.$route.params, query: queryUpdate})
        },
        closeDropdownOnOutsideClick() {
            document.addEventListener('click', (e) => {
                if (document.getElementById('rangeDatesDefaults') && !document.getElementById('rangeDatesDefaults').contains(e.target)) {
                    this.show = false
                }
            })
        }
    },
    created(){
        this.closeDropdownOnOutsideClick()
    }
}
</script>

<style lang="scss" scoped>
.rangeDatesDefaults{
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;

    font-size: 14px;
    line-height: 1.4;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    text-align: left;
    button{
        display: flex;
        gap: 0.5rem;
        align-items: center;
        height: 34px;
        padding: 6px 30px;
        padding-left: 10px;
        svg{
            width: 1em;
            height: 1em;
            vertical-align: -0.15em;
            fill: currentColor;
            overflow: hidden;
        }
    }
    .options{
        position: absolute;
        margin-top: 1px;
        background-color: #fff;
        margin-bottom: 1px;
        -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
        box-shadow: 0 6px 12px rgba(0,0,0,.175);
        z-index: 2001;
        padding: 10px;
        ul{
            list-style-type: none;
            padding: 0;
            li {
                font-size: 0.75rem;
                color: #1976d2;
                line-height: 20px;
                margin-bottom: 12px;
                cursor: pointer;
            }
        }
    }
}

</style>