import GlobalHome from '../views/Global/Home.vue'
import GlobalSummary from '../views/Global/Pages/Home.vue'
import GlobalGastroRanking from '../views/Global/Pages/Gastroranking.vue'

export default 
    {
        path: '/global',
        name: 'GlobalHome',
        component: GlobalHome,
        redirect: '/global/home/:paramCOD?',
        meta: {Auth: true, disabledForAdverbia: true},
        props: true,
        children: [
          {
            path: 'home/:paramCOD?',
            name: 'GlobalSummary',
            component: GlobalSummary,
            meta: {Auth: true, disabledForAdverbia: true},
            props(route) {
              return  route.query || {}
            }
          },
          {
            path: 'gastroranking/:paramCOD?',
            name: 'GlobalGastroRanking',
            component: GlobalGastroRanking,
            meta: {Auth: true, disabledForAdverbia: true},
            props(route) {
              return  route.query || {}
            }
          }
        ]
      }