<template lang="pug">
div.pa-3.main
    div(ref="cardRef")
        v-card.px-6.py-10.__card(v-if="data.real_rating")
            v-row
                v-col.col-11
                    span {{ data.center_name }}
                    .block-help.ml-auto
                v-col.col-1.d-flex.items-center.justify-end
                    v-tooltip(bottom rigth color="#222226" open-on-click=true content-class="tooltip-help custom-tooltip")
                        template(v-slot:activator="{ on, attrs }")
                            img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                        h1.pb-4 ¿Cómo funciona el Rating Stars de Google?

                        h2 Método de cálculo del número de estrellas
                        p El rating de un negocio en Google se calcula utilizando las estrellas recibidas (de 1 a 5) en todas y cada una de las reviews, tengan contenido o no, desde la primera hasta la última. Google utiliza una fórmula de promedio ponderado para obtener la calificación general, que es la que se muestra públicamente.

                        h2 Redondeo en Google
                        p El rating visible en Google se redondea de la siguiente manera:
                        ul
                            li Si el promedio exacto es menor o igual a 4.450 (como 4.450 o 4.449), Google redondea hacia abajo y muestra una calificación de 4.4.
                            li Si el promedio es superior a 4.450 (como 4.451), Google redondea hacia arriba, mostrando una calificación de 4.5.
                        p Este detalle es importante para entender cómo cada nueva reseña influye en la calificación final visible. Y True Rating te ayuda a entenderlo de forma sencilla, rápida y visual.

                        h2(v-if="isAdverbia") Descripción de la Funcionalidad: Adverbia True Rating
                        h2(v-else) Descripción de la Funcionalidad: Gastroranking True Rating
                        p True Rating te proporciona una visión detallada y precisa del rating de Google para tu negocio, calculado con una precisión de hasta tres decimales, tal y como hace el propio Google. A diferencia de la calificación visible, esta herramienta te permite visualizar el rating real y te indica cuántas reseñas de 5 estrellas adicionales necesitas para alcanzar la siguiente décima en la escala de Google (por ejemplo, de 4.4 a 4.5). O cuántas reseñas de menos de tu puntuación actual te harán perder una décima en tu rating de Google.
                        p Además, incluye un componente histórico para que puedas analizar la evolución de tu calificación con el tiempo, ofreciéndote una visión completa de cómo ha cambiado tu reputación en línea. Esta herramienta es clave para aquellos que buscan un análisis detallado y estratégico de cómo las reseñas impactan en la calificación global, permitiéndote planificar y medir acciones que mejoren el posicionamiento de tu negocio en Google.

                        h2 Barra de Progreso
                        p La Barra de Progreso que está a la derecha de esta pantalla, es una representación visual que muestra la relación entre tu rating actual y los límites más cercanos:
                        ul
                            li 
                                strong Cota Mínima:
                                    span Representa la décima anterior de tu rating actual en Google.
                            li 
                                strong Cota Máxima:
                                    span Representa la décima siguiente de tu rating actual.
                        p La barra tiene como propósito mostrar de manera intuitiva qué tan cerca o lejos está el rating actual de alcanzar la siguiente décima o de caer a la décima anterior. El porcentaje indicado en la barra refleja el progreso desde la cota mínima hasta la cota máxima en relación con el rating actual.
                        p Si tienes cuestiones adicionales, no dudes en contactar con nosotros para profundizar en tus datos tanto como necesites.

            v-row.d-flex.justify-space-around
                //Google rating card
                v-col.col-5.col-md-4.mb-2.mb-md-0.__tooltip
                    div.d-flex.flex-column.text-center
                        span.font-weight-bold.text-16 #[translate Google Ranking]
                        span.text-32 {{ data.historical_rating }}
                        div.d-flex.items-center.justify-center.py-2
                            google-rating(:nota="data.historical_rating" :height="20")
                            span.pl-1.text-14.font-weight-medium {{ data.n_reviews }} #[translate reseñas]

                        one-color-bar-with-text(v-for="(item,index) in rating" :key="item.id" :width="$options.filters.PercentFromValue(item, data.n_reviews)" :label="(rating.length - (index)).toString()" :right-label="item.toString()")
                //Real rating card
                v-col.col-5.mb-2.ml-2.mb-md-0.col-md-3.ml-md-2.__card.--bg-gray.d-flex.pt-6.__tooltip(v-if="data.real_rating")
                    div.d-flex.flex-column.text-center.px-4.py-2
                        span.font-weight-bold.text-16 True Rating
                        span.text-41.font-weight-medium {{ data.real_rating }}
                        span.text-center.text-md-left.text-13 #[translate Se necesitan]
                        div.d-flex.flex-column.flex-md-row.items-center.justify-center.pl-3
                            span.text-32.font-weight-bold {{ data.n_5_stars_for_next_near_decimal }}
                            span.text-12.text-left.pl-2 #[translate reseñas de 5 estrellas para lograr una calificación de] {{ data.next_google_rating }} #[translate estrellas].

                //Progress card
                v-col.col-12.col-md-4.ml-md-2.__card.--border-gray.__tooltip
                    div.d-flex.flex-column.text-center.px-4.py-6.__progress
                        span.font-weight-bold.text-16.pb-6 #[translate Progreso a] {{ data.next_google_rating }}

                        div.--container
                            div.py-8
                                one-color-bar(:width="data.percentage_of_progress" label="3122" :height="20" :inner-text="data.real_rating.toFixed(3)")
                            span.text-18.__label.--top.--left(:class="palette.negative.class") {{ data.previous_google_rating }} 
                                span.text-14(v-if="data.previous_near_decimal") ({{ data.previous_near_decimal.toFixed(3) }})
                            span.text-18.__label.--top.--center(v-if="data.percentage_of_progress") {{data.historical_rating}}
                            span.text-18.font-weight-bold.__label.--bottom.--center(v-if="data.percentage_of_progress") {{data.percentage_of_progress.toFixed(0)}}%
                            span.text-18.__label.--top.--right(:class="palette.positive.class") {{ data.next_google_rating }} 
                                span.text-14(v-if="data.next_near_decimal") ({{ data.next_near_decimal.toFixed(3) }})
                            img.__label.--bottom.--left(:src="img.googleStart")
                            img.__label.--bottom.--right(:src="img.googleStart")

                        div.pt-5
                            span.font-weight-bold.text-16.pb-20 #[translate Tendencia últimos 90 días]

                            div.d-flex.justify-space-around.px-4
                                span(v-if="data.last_real_rating") {{ data.last_real_rating.toFixed(3)}}
                                span(v-else) -
                                div.d-flex.items-center
                                    span.mb-0.ml-2.arrow(:class="getArrow(data.real_rating, data.last_real_rating)")
                                    span.text-18.font-weight-bold.pl-1(v-if="data.real_rating_tendency") {{ data.real_rating_tendency.toFixed(3) }}
                                span(v-if="data.real_rating") {{ data.real_rating.toFixed(3) }}

                                div.position-absolute.top-0.--icon

            v-row.pt-8
                v-col.col-12
                    span.font-weight-bold.text-16.__title #[translate Evolución de la tendencia]
                v-col.col-12.pa-0
                    google-true-rating-chart(:chart-data="chartData" :lower-level="graphicLevels.lower" :upper-level="graphicLevels.upper")

            //- v-row.pt-8.d.flex.d-md-none
            //-     v-col.col-12
            //-         span.font-weight-bold.text-16.__title #[translate Evolución de la tendencia]
            //-     v-col.col-12.pa-0.d-flex.justify-center
            //-         mobile-true-rating-chart(:width="cardWidth-24" :leftIndicators="chartData.labels" :ratings="data.trends.real_ratings" :tendency="data.trends.tendency")

        warning-not-delivery-not-internet(v-else-if="!loading" type="global")
</template>

<script>
import OneColorBarWithText from '@/components/common/OneColorBarWithText.vue';
import googleRating from '@/components/common/googleRating.vue';
import OneColorBar from '@/components/charts/OneColorBar.vue';
import commonMixing from '@/mixins/common';
import brandMixing from '@/mixins/brand';
import WarningNotDeliveryNotInternet from '@/components/common/WarningNotDeliveryNotInternet.vue'
import GoogleTrueRatingChart from '../charts/GoogleTrueRating.vue';
import MobileTrueRatingChart from "../charts/MobileTrueRating"

export default {
    mixins: [commonMixing, brandMixing],
    components: { OneColorBarWithText, googleRating, OneColorBar, WarningNotDeliveryNotInternet, GoogleTrueRatingChart, MobileTrueRatingChart },
    props: {
        data: null,
        loading: {
            type: Boolean,
            require: true
        },
        rating: []
    },
    data() {
        return {
            img: {
                googleStart: require("@/assets/img/google_star.svg")
            },
            cardWidth: 0
        }
    },
    mounted() {
        this.setCardWidth()
    },
    methods: {
        setCardWidth() {
            this.cardWidth = this.$refs?.cardRef?.clientWidth ?? 0
        },
    },
    watch: {
        data() {
            this.rating = this.data?.n_stars_reviews?.reverse()
        }
    },
    computed: {
        chartData() {
            const trends = this.data?.trends
            let data = null
            if (trends) {
                const months = trends.dates.map((item) => this.$moment(item).format("DD MMM"))
                data = {
                    labels: months,
                    datasets: [{
                        data: trends.real_ratings,
                        label: this.$gettext('Rating'),
                        yAxisID: "y-axis-1",
                        fill: false,
                        borderColor: '#9b9b9b',
                        borderWidth: 1,
                        pointBackgroundColor: "#9b9b9b",
                        pointBorderColor: "#9b9b9b",
                        pointRadius: 5,
                        datalabels: {
                            align: 'top',
                        },
                    }]
                }
            }
            return data
        },
        graphicLevels() {
            return { upper: this.data?.trends?.real_ratings?.[0], lower: this.data?.trends?.real_ratings?.[this.data?.trends?.real_ratings?.length - 1] }
        }
    },
}
</script>
<style lang="scss" scoped>
.custom-tooltip {
    width: 750px;
}

.main {
    background: inherit;
    min-width: 100%;

    .__card {
        border-radius: 10px;

        .__tooltip {
            position: relative;

            .--icon {
                position: absolute;
                top: 0;
                right: 10px;
            }
        }

        .__title {
            position: relative;

            ::after {
                position: absolute;
                content: '';
                width: 60%;
                bottom: -5px;
                left: 0;
                border-bottom: 2px solid #BFBFBF;
            }
        }

        &.--bg-gray {
            background: #F1F3F4;
        }

        &.--border-gray {
            border: 2px solid #E2E2E2;
        }

        .__progress {
            .--container {
                position: relative;
                align-items: end;

                .__label {
                    position: absolute;

                    &.--top {
                        top: 0;
                    }

                    &.--bottom {
                        bottom: 0;
                    }


                    &.--left {
                        left: 0;
                    }

                    &.--center {
                        left: 0;
                        right: 0;
                        margin: auto;
                    }

                    &.--right {
                        right: 0;
                    }
                }
            }
        }
    }

}
</style>
        