<template lang="pug">
div#headerCenter.margin-bottom-0.col-12.pb-0
    skeleton-card(v-if="isLoading")
    .borderBrand.pa-3.ma-0(v-if="!isLoading")
        v-row
            .col-9.d-flex.align-center.text-14
                .justify-center
                    img.logoBrand(v-if="dataProfile.center_info && dataProfile.center_info.brand" :src="dataProfile.center_info.brand.logo" height="61")
                .ml-4
                    strong.nameCenter(v-if="dataProfile.name") {{ dataProfile.name }} - {{dataProfile.city_name}}
                    br
                    span.directionCenter #[translate Direction]: {{ dataProfile.street_address }}
                //div
                    span.statusCenterKo {{ dataProfile.google_status }}
</template>

<script>
import SkeletonCard from "@/components/common/skeletonCard.vue";
export default {
  props: ["isLoading", "dataProfile"],
  components: { SkeletonCard },
};
</script>

<style lang="scss">
.logoBrand {
  max-height: 61px;
}
#headerCenter {
  .nameCenter {
    font-size: 1rem;
    font-weight: 600;
  }

  .directionCenter {
    font-size: 0.875rem;
  }

  .statusCenterKo {
    color: #f93549;
    font-size: 1rem;
    font-weight: 600;
  }
}
</style>
