<template>

<svg xmlns="http://www.w3.org/2000/svg" width="16.7" height="16.7" viewBox="0 0 16.7 16.7" :class="{'dBlock': block}">
  <g id="source_icons_clock-outline" transform="translate(-1.15 -1.65)">
    <path id="Trazado_5417" data-name="Trazado 5417" d="M12,6v4.182h4.182" transform="translate(-2.5 0.5)" fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
    <path id="Trazado_5418" data-name="Trazado 5418" d="M9.5,17A7.5,7.5,0,1,0,2,9.5,7.5,7.5,0,0,0,9.5,17Z" transform="translate(0 0.5)" fill="none" :stroke="color"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
  </g>
</svg>
</template>

<script>
export default {
  name: "IcoTime",
  props: {
    color: {
        type: String,
        default: '#000000',
        required: false
    },
    block: {
        type: Boolean,
        default: true,
        required: false
    }
  }
}
</script>

<style scoped>
    .dBlock{
        display: block;
    }
</style>