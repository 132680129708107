<script>
import Chart from 'chart.js'
import { generateChart, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

// CUSTOMIZAMOS LA GRAFICA DE LINEA PARA AÑADIR BARRAS DE COLORES A LOS LATERALES
Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.defaults.global.legend.labels.usePointStyle = true;

const CustomLine = generateChart('custom-line', 'LineWithLine')
export default {
  name: "valid_name",
  extends: CustomLine,
  mixins: [reactiveProp],
  props:['chartData', 'legend'],
  data: () => ({    
    chartDataCustom: null,
    options: {
      tooltips: {
          callbacks: {
              labelColor: function(tooltipItem,chart) {
                  return {
                      borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                      backgroundColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                  }
              },
              label: function(tooltipItems, data) {
                var label = data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.yLabel.toFixed(2)
                return label
              }
          }
      },
      legend: {
          display: true,
          position: 'right',
          align: 'start',          
      },
      plugins: {
        // Change options for ALL labels of THIS CHART
        datalabels: {
            display: false
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
         yAxes: [{
            id: 'y-axis-0',
            position: 'left',
            ticks: {
               stepSize: 1
            },
            gridLines: {
              tickMarkLength: 50  
            },
         }, {
            id: 'y-axis-1',
            position: 'right',
            gridLines: {
              tickMarkLength: 50  
            },
            ticks: {
               stepSize: 1
            }
         }],
         xAxes: [
            {
              type: "time",
              time: {
                  unit: 'day',
                  parser:'DD/MM/YYYY',
                  displayFormats: { day: 'DD/MM/YYYY' },
                  stepSize: 2
              }
            },
          ],
      },
     
    }
  }),
  created () {
    this.chartDataCustom = this.chartData
  },
  watch: {
    chartDataCustom () {
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted () {
    // MOSTRAMOS VALORES DEL EJE Y A IZQUIERDA Y DERECHA

    Chart.plugins.register({
      beforeInit: function(chart) {
        // MAXIMOS Y MINIMOS EN GRAFICA LINEAL
        if(chart.canvas.id == 'custom-line'){
          Chart.controllers.LineWithLine = Chart.controllers.line.extend({ 
  
  draw: function(ease) {
    Chart.controllers.bubble.prototype.draw.call(this, ease);
    var ctx = this.chart.chart.ctx;

      // LINEA VERDE IZQUIERDA
      ctx.beginPath();
      ctx.moveTo(40, 6);
      ctx.lineWidth = 11;
      ctx.strokeStyle = '#89C445';
      ctx.lineTo(40, 370);
      ctx.stroke();
      ctx.save();

      // LINEA NARANJA IZQUIERDA
      ctx.beginPath();
      ctx.moveTo(40, 150);
      ctx.lineWidth = 11;
      ctx.strokeStyle = '#FCAE19';
      ctx.lineTo(40, 220);
      ctx.stroke();
      ctx.save();

      // LINEA ROJA IZQUIERDA
      ctx.beginPath();
      ctx.moveTo(40, 220);
      ctx.lineWidth = 11;
      ctx.strokeStyle = '#F16621';
      ctx.lineTo(40, 370);
      ctx.stroke();
      ctx.save();
      ctx.restore();
    }

})
          chart.options.scales.yAxes[1].ticks.suggestedMin = 0;
          chart.options.scales.yAxes[1].ticks.suggestedMax = 10;
          chart.options.scales.yAxes[0].ticks.suggestedMin = 0;
          chart.options.scales.yAxes[0].ticks.suggestedMax = 10;
        }
        if(chart.config.data.datasets.length <= 1){
          chart.config.options.legend.display = false
        }
        let pasos = 2;
        if(chart.config.data.labels.length > 30){ 
          pasos = Math.ceil(chart.config.data.labels.length / 15);
        }
        if(chart.canvas.id == 'custom-line'){
          chart.config.options.scales.xAxes[0].time.stepSize = pasos;
        }
      }
    });
    this.renderChart(this.chartData, this.options)
  }
}
</script>