<template>
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28">
  <g id="Grupo_11093" data-name="Grupo 11093" transform="translate(-979 -468)">
    <g :stroke="hasInfo ? '#608df2' : '#CECECE'" id="Rectángulo_2539" data-name="Rectángulo 2539" transform="translate(979 468)" fill="#fff"  stroke-width="1">
      <rect width="30" height="28" rx="4" stroke="none"/>
      <rect x="0.5" y="0.5" width="29" height="27" rx="3.5" fill="none"/>
    </g>
    <path :stroke="hasInfo ? '#f93549' : '#CECECE'" id="Trazado_4724" data-name="Trazado 4724" d="M14.352,8.751C14.788,12.783,16.5,14,16.5,14H3s2.25-1.6,2.25-7.2A4.968,4.968,0,0,1,6.568,3.406,4.367,4.367,0,0,1,9.751,2a4.17,4.17,0,0,1,.75.068M15,6.5A2.377,2.377,0,0,0,16.98,5.323a1.958,1.958,0,0,0,.272-1.073A2.25,2.25,0,1,0,15,6.5Zm-3.953,9.751a1.5,1.5,0,0,1-2.6,0" transform="translate(983.5 473.5)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
  </g>
</svg>
</template>

<script>
export default{
    props: ['hasInfo']
}
</script>

<style>
</style>