import rateAnalysis from '../views/GastroPricingRate/Home.vue'
import priceVariations from '../views/GastroPricingRate/Pages/priceVariations.vue'
import rateComparator from '../views/GastroPricingRate/Pages/rateComparator.vue'
import rateComparatorPizzas from '../views/GastroPricingRate/Pages/rateComparatorPizzas.vue'
import aggregatorsRank from '../views/GastroPricingRate/Pages/aggregatorsRank.vue'
import provincesRank from '../views/GastroPricingRate/Pages/provincesRank.vue'

export default {
    path: '/rate-analysis',
    name: 'rateAnalysis',
    component: rateAnalysis,
    redirect: '/rate-analysis/rate-comparator/:paramCOD?',
    meta: {Auth: true, isGP: true},
    props: true,
    children: [
      {
        path: 'price-variations/:paramCOD?',
        name: 'priceVariations',
        component: priceVariations,
        meta: {Auth: true, isGP: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'rate-comparator/:paramCOD?',
        name: 'rateComparator',
        component: rateComparator,
        meta: {Auth: true, isGP: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'aggregators-rank/:paramCOD?',
        name: 'aggregatorsRank',
        component: aggregatorsRank,
        meta: {Auth: true, isGP: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'provinces-rank/:paramCOD?',
        name: 'provincesRank',
        component: provincesRank,
        meta: {Auth: true, isGP: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'pizzas/:paramCOD?',
        name: 'rateComparatorPizzas',
        component: rateComparatorPizzas,
        meta: {Auth: true, isGP: true},
        props(route) {
          return  route.query || {}
        }
      }
    ]
  }