export default {
  data() {
    return {
      img: {
        googleMaps: require("@/assets/img/google-maps-icon.svg"),
        configGoogle: require("@/assets/img/local/config_google.svg"),
        configFacebook: require("@/assets/img/local/config_facebook.svg"),
        facebook: require("@/assets/img/facebook.svg"),
      },
    };
  },
  computed: {
    hasPremium() {
      return this.$store.getters.getUserInfo?.premium_localmarketing;
    },
    isInstanceDashboard() {
      if (window.location.hostname.indexOf("dashboard.") > -1) return true;
      return false;
    },
  },
  methods: {
    getPercentTotalSites(item, site) {
      if (item.accounts_local_manual && item.accounts_local_manual[site]) return item.accounts_local_manual[site].total_percent;
      return 0;
    },
    getTotalSites(item, site) {
      if (item.accounts_local_manual && item.accounts_local_manual[site]) return item.accounts_local_manual[site].total;
      return 0;
    },

    getPercentAccounts(item, site) {
      if (item.accounts_local_manual && item.accounts_local_manual[site]) return item.accounts_local_manual[site].profile_completed;
      return 0;
    },
    getTypeReport(type) {
      let typeName = "";
      if (type === "week") {
        typeName = this.$gettext("Weekly");
      } else if (type === "month") {
        typeName = this.$gettext("Monthly");
      } else if (type === "quarter") {
        typeName = this.$gettext("Quarterly");
      } else if (type === "year") {
        typeName = this.$gettext("Yearly");
      }
      return typeName;
    },
  },
};
