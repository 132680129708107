<template lang="pug">
div
    v-row.px-2.ma-0
        // LOGO MARCA     
        header-center(:isLoading="isLoading" :dataProfile="dataProfile")
        configuration-blocked(:fullyBlocked="dataProfile.fully_blocked")

    google-special-hours.mx-3
    
    //v-row.px-2.ma-0  
        v-card.gridFull.w-full.h-auto.my-4.ma-0.pl-6.pr-9(v-if="!isLoading")
            v-row.ma-0
              v-col.text-14(sm="12")
                strong.mr-16 #[translate Dias cerrados]
                v-row
                  v-col.text-14(sm="6")
                    newday(:hasClose="true" :hasTime="false")
                  v-col.text-14(sm="6")
                    newday(:hasClose="true" :hasTime="false")
            v-row.ma-0
              v-col.text-14(sm="12")
                .d-flex
                  button.blue--text Añadir una fecha nueva
                .d-flex.align-center.pa-2.justify-center
                  button.btnBlue #[translate Aplicar]
        
    //v-row.px-2.ma-0 
        v-card.gridFull.w-full.h-auto.my-4.ma-0.pl-6.pr-9(v-if="!isLoading")
            v-row.ma-0
              v-col.text-14(sm="12")
                strong.mr-16 #[translate Semanas con horario diferente]
                v-row
                  v-col.text-14(sm="8")
                    box-time(type="modal" :hasBorder="true")
                v-row
                  v-col.text-14(sm="8")
                    box-time(type="modal" :hasBorder="true")
                v-row
                  v-col.text-14(sm="8")
                    box-time(type="modal" :hasBorder="true")
                v-row
                  v-col.text-14(sm="8")
                    box-time(type="modal" :hasBorder="true")
                v-row
                  v-col.text-14(sm="8")
                    box-time(type="modal" :hasBorder="true")
                v-row
                  v-col.text-14(sm="8")
                    box-time(type="modal" :hasBorder="true")
                v-row
                  v-col.text-14(sm="8")
                    box-time(type="modal" :hasBorder="true")
                  
            v-row.ma-0
              v-col.text-14(sm="12")
                .d-flex
                  button.blue--text Añadir una semana nueva
                .d-flex.align-center.pa-2.justify-center
                  button.btnBlue #[translate Aplicar]
</template>

<script>
  import SkeletonCard from '@/components/common/skeletonCard.vue'
  import SrvLocal from '@/services/local.js'
  import ConfigurationBlocked from '@/components/local/ConfigurationBlocked.vue'
  import googleSpecialHours from '@/components/local/googleSpecialHours.vue'
  import commonMixins from "@/mixins/common.js"
  import localMixins from "@/mixins/local.js"
  //FICHAS EDICION
  import HeaderCenter from "@/components/local/HeadCenter.vue"

  export default {
    components: {
        SkeletonCard,
        HeaderCenter,
        ConfigurationBlocked,
        googleSpecialHours
    }, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
    mixins: [commonMixins, localMixins],
    data() {
      return {
        showEdit: true, // TEMPORAL PARA SUBIDAS A PRO
        dataProfile: [],
        messageError: null,
        loadingSync: false,
        loadingImportGoogle: false,
        sections: null,
        showOk: false,
        googlespecialday: [],
        sectionsNotCanBlocked: ['storefrontAddress','latlng'],
        img: {
        },
    }   
    },
    async created() {
      this.$store.commit('LOADING', true)
      this.loading = true
      this.$store.commit('LOADING', true)
      this.center = Number(this.$store.getters.getNode)
      await this.getLocalMarketing()
      
      this.loading = false
      this.$store.commit('LOADING', false)
    },    
    watch: {
      filterApply: function () {
        this.getLocalMarketing()
      }
    },
    methods: {      
      async getLocalMarketing() {
        this.$store.commit('LOADING', true)
        const resLocal = await SrvLocal.getLocalMarketing()
        if (resLocal.status === 200) {
          this.dataProfile = resLocal.data          
          this.facebookConfig = resLocal.data.facebook_config
          this.$store.commit('LOADING', false)
        }      
      }
      
    }
  }
  </script>
