// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'

axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  getRankings(order = '-name', search, excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let query = `/ranking_delivery/`;
      query = SrvCommon.getBasicParamsQuery(query)

      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if (Stores.getters.getSitesDeliverySelected.length > 0) {
        Stores.getters.getSitesDeliverySelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      query += '&ordering=' + order

      if (search) {
        query += '&search=' + search
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }

      let token = SrvCommon.getToken()
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getRankingsNext(url) {
    return new Promise((resolve, reject) => {
      let token = SrvCommon.getToken();

      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(url)
        .then(res => {
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getRankingsAverage() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let query = `/reviews_node_ranking/`;
      query = SrvCommon.getBasicParamsQuery(query)

      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if (Stores.getters.getSitesDeliverySelected.length > 0) {
        Stores.getters.getSitesDeliverySelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getDistanceMap && Stores.getters.getDistanceMapActive) {
          query += `&distance=${Stores.getters.getDistanceMap}`
        }
      }

      let token = SrvCommon.getToken();

      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getPositioningLast(excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let query = `/reviews_center_ranking_last_year/`;
      query = SrvCommon.getBasicParamsQuery(query)

      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }

      let token = SrvCommon.getToken()
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },


  getValorationGlobal() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/global_rating/`;
      query = SrvCommon.getBasicParamsQuery(query)

      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getCompetenceGlobalWithChildren(excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/delivery_global_competence/`;
      query = SrvCommon.getBasicParamsQuery(query)
      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }

      if(Stores.getters.getSitesDeliverySelected.length > 0){
        Stores.getters.getSitesDeliverySelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getDistanceMap && Stores.getters.getDistanceMapActive) {
          query += `&distance=${Stores.getters.getDistanceMap}`
        }
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }
      query += `&with_children_data=True`

      // llamada a la API
      let token = SrvCommon.getToken();
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getCompetenceGlobal(excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/delivery_global_competence/`;
      query = SrvCommon.getBasicParamsQuery(query)
      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }

      if(Stores.getters.getSitesDeliverySelected.length > 0){
        Stores.getters.getSitesDeliverySelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getDistanceMap && Stores.getters.getDistanceMapActive) {
          query += `&distance=${Stores.getters.getDistanceMap}`
        }
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }

      // llamada a la API
      let token = SrvCommon.getToken();
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getEvolutionLastYear(reviewType = '') {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/last_year/`;
      query = SrvCommon.getBasicParamsQuery(query)
      if (reviewType) {
        query += `&review_type=${reviewType}`
      } else if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }

      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }

      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getCompetenceLastYear() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/last_year_with_competence_reviews/`;
      query = SrvCommon.getBasicParamsQuery(query)

      query += `&review_type=delivery`

      if(Stores.getters.getSitesDeliverySelected.length > 0){
        Stores.getters.getSitesDeliverySelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }

      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getDistanceMap && Stores.getters.getDistanceMapActive) {
          query += `&distance=${Stores.getters.getDistanceMap}`
        }
      }

      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getGlobalInternet(version = false, excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/summary_internet/`
      query = SrvCommon.getBasicParamsQuery(query)

      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }
      if (version) {
        query += `&version=reviews`
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }

      // llamada a la API
      let token = SrvCommon.getToken();
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getRestaurantsMap() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/map_competence/`

      query += `?tree=${Stores.getters.getTreeSelected.id}`
      // SI EL NIVEL NO EXISTE ES CENTRO
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`
        }
      }

      query += `&review_type=delivery`

      if(Stores.getters.getSitesDeliverySelected.length > 0){
        Stores.getters.getSitesDeliverySelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      if (Stores.getters.getDateAfter) {
        let after = Stores.getters.getDateAfter;
        if (after.indexOf('T')) {
          let partes = after.split('T');
          after = partes[0];
        }
        query += `&after=${after}`
      }
      if (Stores.getters.getDateBefore) {
        let before = Stores.getters.getDateBefore;
        if (before.indexOf('T')) {
          let partes = before.split('T');
          before = partes[0];
        }
        query += `&before=${before}`
      }
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getDistanceMap && Stores.getters.getDistanceMapActive) {
          query += `&distance=${Stores.getters.getDistanceMap}`
        }
      }
      // llamada a la API
      let token = SrvCommon.getToken();
      let configHeaders = {}

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getAggregators(params, excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/delivery_search_position/`

      query += `?tree=${Stores.getters.getTreeSelected.id}`
      // SI EL NIVEL NO EXISTE ES CENTRO
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`
        }
      }

      if (Stores.getters.getDateAfter) {
        let after = Stores.getters.getDateAfter;
        if (after.indexOf('T')) {
          let partes = after.split('T');
          after = partes[0];
        }
        query += `&after=${after}`
      }
      if (Stores.getters.getDateBefore) {
        let before = Stores.getters.getDateBefore;
        if (before.indexOf('T')) {
          let partes = before.split('T');
          before = partes[0];
        }
        query += `&before=${before}`
      }

      // REGION
      // REGION
      if (Stores.getters.getRegionSelected.length > 0) {
        Stores.getters.getRegionSelected.forEach(reg => {
          query += `&region=${reg}`
        })
      }

      // AGGREGATOR
      if (params.aggregator) {
        query += `&aggregator=${params.aggregator}`
      }
      // MOMENT
      if (params.moment) {
        query += `&moment=${params.moment}`
      }
      // MOMENT
      if (params.distance) {
        query += `&distance=${params.distance}`
      }

      // llamada a la API
      let token = SrvCommon.getToken()
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }
}
