import * as types from "@/store/mutation-types";
import * as actionTypes from "@/store/actions-types";
import service from "@/services/booking";

const INITIAL_STATE = {
  pending: false,
  count: 0,
  bookingOpinions: [],
  bookingSearch: null,
  hasError: false,
  clientFilters: [],
  opinionsCategoriesSelected: [],
  clientFilterSelected: [],
  opinionsTypeSelected: [],
  nextUrl: null,
};

export const Booking = {
  state: {
    ...INITIAL_STATE,
  },
  mutations: {
    [types.BOOKING_OPINIONS](state, data) {
      state.bookingOpinions = data;
    },
    [types.BOOKING_IS_PENDING](state, data) {
      state.pending = data;
    },
    [types.BOOKING_OPINIONS_SEARCH](state, data) {
      state.bookingSearch = data;
    },
    [types.BOOKING_FILTER_OPINIONS_CATEGORIES_SELECTED](state, data) {
      state.opinionsCategoriesSelected = data;
    },
    [types.BOOKING_CLIENT_FILTER_SELECTED](state, data) {
      state.clientFilterSelected = data;
    },
    [types.BOOKING_OPINIONS_TYPE_SELECTED](state, data) {
      state.opinionsTypeSelected = data;
    },
    [types.BOOKING_HAS_ERROR](state, data) {
      state.hasError = data;
    },
    [types.BOOKING_COUNT](state, data) {
      state.count = data;
    },
    [types.BOOKING_CLIENT_FILTERS](state, data) {
      state.clientFilters = data;
    },
    [types.BOOKING_NEXT_URL](state, data) {
      state.nextUrl = data;
    },
    [types.BOOKING_CLEARED_STATE](state) {
      const keys = Object.keys(INITIAL_STATE);
      keys.forEach((key) => {
        state[key] = INITIAL_STATE[key];
      });
    },
  },
  getters: {
    getBookingOpinions: (state) => {
      return state.bookingOpinions;
    },
    getBookingSearch: (state) => {
      return state.bookingSearch;
    },
    getIsPending: (state) => state.pending,
    getBookingCount: (state) => state.count,
    getClientTypes: (state) => state.clientFilters,
    getBookingCategoriesSelected: (state) => {
      return state.opinionsCategoriesSelected;
    },
    getBookinClientFilterSelected: (state) => {
      return state.clientFilterSelected;
    },
    getBookingOpinionsTypeSelected: (state) => {
      return state.opinionsTypeSelected;
    },
    getNextUrl: (state) => {
      return state.nextUrl;
    },
  },
  actions: {
    async [actionTypes.BOOKING_GET_DATA_FROM_SERVICE](context, { url, order }) {
      try {
        context.commit(types.BOOKING_IS_PENDING, true);
        const { data } = url ? await service.getNextOpinions(url) : await service.getOpinions(order);
        context.commit(types.BOOKING_OPINIONS, [...context.state.bookingOpinions, ...data.results]);
        context.commit(types.BOOKING_COUNT, data.count);
        context.commit(types.BOOKING_NEXT_URL, data.next);
      } catch (error) {
        context.commit(types.BOOKING_HAS_ERROR, true);
        console.error("[Error: getDataFromServer]", error);
      } finally {
        context.commit(types.BOOKING_IS_PENDING, false);
      }
    },
    async [actionTypes.BOOKING_GET_CLIENT_DATA_FROM_SERVICE](context) {
      try {
        const { data } = await service.getClientTypes();
        context.commit(types.BOOKING_CLIENT_FILTERS, data);
      } catch (error) {
        context.commit(types.BOOKING_HAS_ERROR, true);
        console.error("[Error: getDataFromServer]", error);
      }
    },
    [actionTypes.BOOKING_CLEAR_NEXT_URL](context) {
      context.commit(types.BOOKING_NEXT_URL, null);
    },
    [actionTypes.BOOKING_SET_OPINION_TYPE_SELECTED](context, value) {
      context.commit(types.BOOKING_OPINIONS_TYPE_SELECTED, value);
    },
    [actionTypes.BOOKING_SET_CLIENT_FILTER_SELECTED](context, value) {
      context.commit(types.BOOKING_CLIENT_FILTER_SELECTED, value);
    },
    [actionTypes.BOOKING_SET_CATEGORIES_FILTER_SELECTED](context, value) {
      context.commit(types.BOOKING_FILTER_OPINIONS_CATEGORIES_SELECTED, value);
    },
    [actionTypes.BOOKING_CLEAR_OPINIONS](context) {
      context.commit(types.BOOKING_OPINIONS, []);
    },
    [actionTypes.BOOKING_CLEAR_SEARCH](context) {
      context.commit(types.BOOKING_OPINIONS_SEARCH, null);
    },
    [actionTypes.BOOKING_SET_INITIAL_STATE](context) {
      context.commit(types.BOOKING_CLEARED_STATE, null);
    },
  },
};
