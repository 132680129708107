<template>
<svg xmlns="http://www.w3.org/2000/svg" width="14.058" height="12.9" viewBox="0 0 14.058 12.9" :class="{'dBlock': block}">
  <path id="source_icons_heart" d="M14.558,6.681a3.736,3.736,0,0,1-1.039,2.594C11.987,10.863,10.5,12.52,8.91,14.051a.919.919,0,0,1-1.291-.028L3.038,9.275a3.758,3.758,0,0,1,0-5.187,3.5,3.5,0,0,1,5.074,0l.167.173.166-.172a3.5,3.5,0,0,1,5.074,0A3.736,3.736,0,0,1,14.558,6.681Z" transform="translate(-1.25 -2.25)" fill="none"  :stroke="color" stroke-linejoin="round" stroke-width="1.5"/>
</svg>


</template>

<script>
export default {
  name: "IcoFeeling",
  props: {
    color: {
        type: String,
        default: '#000000',
        required: false
    },
    block: {
        type: Boolean,
        default: true,
        required: false
    }
  }
}
</script>

<style scoped>
    .dBlock{
        display: block;
    }
</style>