<template>
    <button class="buttonUpdateItem d-flex items-center px-3 py-1  text-14">
        <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="21.5" height="21.5" viewBox="0 0 21.5 21.5">
  <g id="Grupo_13661" data-name="Grupo 13661" transform="translate(-1.25 -1.25)">
    <circle id="Elipse_795" data-name="Elipse 795" cx="10" cy="10" r="10" transform="translate(2 2)" fill="none" stroke="#fff" stroke-width="1.5"/>
    <path id="Trazado_14836" data-name="Trazado 14836" d="M16.583,9.667A5.068,5.068,0,0,0,11.988,7,4.825,4.825,0,0,0,7,11" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    <path id="Trazado_14837" data-name="Trazado 14837" d="M14.494,9.722H16.4a.6.6,0,0,0,.6-.6V7.5M7.417,13.667A5.027,5.027,0,0,0,12.012,17,5.241,5.241,0,0,0,17,12" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    <path id="Trazado_14838" data-name="Trazado 14838" d="M9.506,13.622H7.6a.6.6,0,0,0-.6.6V16.4" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
  </g>
</svg>

        <slot></slot>
    </button>
</template>

<script>
export default{

}
</script>

<style scoped>
.buttonUpdateItem{
    background-color: #89C445;
    color: #fff;
    border-radius: 8px;
    
}
</style>