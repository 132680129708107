<template>
    <button class="buttonCancel d-flex items-center px-3 py-1 text-14">
        

        <slot></slot>
    </button>
</template>

<script>
export default{

}
</script>

<style scoped>
.buttonCancel{
    background-color: #fff;
    color: #707070;
    border-radius: 8px;
    border: 1px solid #707070;
    
}
</style>