<template lang="pug">
  div
    v-dialog(v-model='open' scrollable='' max-width='800px' :persistent="true")
      v-card.google-link
        v-toolbar(flat)
          v-toolbar-title Configurar Google
          v-spacer
          v-btn(:disabled="processing" icon @click='$emit("update:open", false)')
            v-icon mdi-close-circle-outline
        v-divider
        template(v-if="verifyTokenGoogle")
          v-card-text(style='height: 300px;')
            div.gr-loader-snipper--modal.flex-column
              span Verificando Token de Google
              span.gr-loader-snipper
        template(v-else-if="isLoadingGoogleUserLocations")
          v-card-text(style='height: 300px;')
            div.gr-loader-snipper--modal.flex-column
              span Cargando Localidades
              span.gr-loader-snipper
        template(v-else-if="!hasTokenGoogle")
          v-card-text(style='height: 300px;')
            span Verificando Token
        template(v-else)
          v-card-text.py-4.overflow-hidden
            v-select.mb-5(:items="options" v-model="optionSelected" item-text="name" return-object label="Solo field" hide-details="true" solo)
            v-text-field(label="Search" autofocus v-model='query' hide-details="true" solo)
          v-divider
          v-card-text(v-if="loadingLocations" style='height: 300px;')
            div.gr-loader-snipper--modal.flex-column
              span Cargando Localidades
              span.gr-loader-snipper
          v-card-text(v-else-if="!items.length" style='height: 300px;')
            div.gr-loader-snipper--modal.flex-column
              span Sin Localidades
          v-card-text(v-else style='height: 300px;')
            div
              v-radio-group(v-model='selection' column='')
                v-radio(v-for="(account, index) in items" :label='account.name' :value='account.google_name' :key="index")
                  template(slot="label")
                    span.d-inline-flex.align-center.text--primary
                      | {{ account.business_name }}
                      | ({{ account.business_address }})
          v-divider
          v-card-actions.justify-center
            v-btn.py-2.my-1(color='blue darken-1' :disabled="processing" outlined tile min-width="100" max-height="30" text='' @click='$emit("update:open", false)')
              | Cerrar
            v-btn.py-2.my-1(v-if="hasTokenGoogle" :loading="processing" color='primary' :disabled="!selection" tile min-width="100" max-height="30" @click='linkGoogle')
              | Asignar

</template>

<script>
import SrvGlobal from "@/services/local";

export default {
  name: "GoogleLink",
  props: {
    open: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
  },
  data() {
    return {
      loadingLocations:true,
      loading: false,
      query: '',
      hasTokenGoogle: false,
      verifyTokenGoogle: false,
      selection: undefined,
      isLoadingGoogleUserLocations: false,
      optionSelected: undefined,
      options: [],
      centers: [],
      center: undefined,
      processing: false,
      urlAuthGoogle: undefined,
      authCodeGoogle: undefined
    }
  },
  watch: {
    async optionSelected (option) {
      if (Object.entries(option['locations']).length === 0) {
        const locationCode = option['account_name']
        await this.getGoogleUserLocations(locationCode)
      }
    },
    open: {
      immediate: true,
      async handler() {
        this.processing = false
        this.verifyTokenGoogle = true
        if (this.open) {
          await this.getTokenGoogle()
          if (this.hasTokenGoogle) {
            this.isLoadingGoogleUserLocations = true
            await this.getGoogleUserLocations()
            this.isLoadingGoogleUserLocations = false
          }
          this.center = this.$store.getters.getNode
        }
      },
    }
  },
  methods: {
    openAuthGoogle() {
      let windowObjectReference = null;
      let previousUrl = null;

      const popupCenter = ({url, title, w, h}) => {
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, title,
          `
            scrollbars=yes,
            width=${w / systemZoom},
            height=${h / systemZoom},
            top=${top},
            left=${left}
            toolbar=no,
            menubar=no
          `
        )

        if (window.focus) newWindow.focus();
        return newWindow
      }

      const openSignInWindow = (url, title) => {
        const w = 600
        const h = 700
        if (windowObjectReference === null || windowObjectReference.closed) {
          windowObjectReference = popupCenter({url, title, w, h})
        } else if (previousUrl !== url) {
          windowObjectReference = popupCenter({url, title, w, h})
          windowObjectReference.focus();
        } else {
          windowObjectReference.focus();
        }
        previousUrl = url;
      };

      let node = ``

      if (this.$store.getters.getNodeLevel === 9) {
        if (this.$store.getters.getNode) {
          node += `?center=${this.$store.getters.getNode}`
        }
      } else {
        if (this.$store.getters.getNode) {
          node += `?node=${this.$store.getters.getNode}`
        }
      }

      window.localStorage.setItem('nodeForGoogle', node)

      openSignInWindow(this.urlAuthGoogle, 'Get Token')
    },
    async linkGoogle() {
      this.processing = true
      const {status, data} = await SrvGlobal.linkGoogle(this.center, this.selection)
      if (status !== 200) {
        this.$emit("update", data)
      } else if (status === 200) {
        this.$emit("refreshPage")
      }
      this.processing = false
      this.$emit("update:open", false)
    },
    async getGoogleUserLocations(locationCode) {
      this.loadingLocations = true
      const {status, data} = await SrvGlobal.getGoogleUserLocations(locationCode)
      if (status === 200) {
        if (data.google_business) {
          if (locationCode) {
            const options = data.google_business.map(item => ({...item, centerSelected: ''}))
            const folder = options.filter(item => item['account_name'] === locationCode)

            if (folder[0]) {
              this.options.filter(item => item['account_name'] === locationCode)[0]['locations'] = folder[0].locations
            }
          } else {
            this.options = data.google_business.map(item => ({...item, centerSelected: ''}))

            if (this.options.length) {
              this.optionSelected = this.options[0]
            }
          }
        }

        if (data.centers) {
          this.centers = data.centers
        }

        this.loadingLocations = false
      }
    },
    async getTokenGoogle() {
      // eslint-disable-next-line no-unused-vars
      const {status, data} = await SrvGlobal.getHasToken()
      this.verifyTokenGoogle = false
      if (status === 200) {
        if (data.has_valid_token) {
          this.hasTokenGoogle = true
        } else {
          this.urlAuthGoogle = data.url
          this.authCodeGoogle = data.code
          this.openAuthGoogle()
        }
      }
    }
  },
  computed: {
    items() {
      if (this.optionSelected) {
        return Object.values(this.optionSelected['locations']).filter(item => {
          return (String(item.business_name).toUpperCase() + ' ' + item.business_address).normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(this.query.toUpperCase())
        })
      } else {
        return []
      }
    }
  }
}
</script>

<style lang="scss">
.google-link {
  .v-list-item__title, .v-select__selections, .v-label {
    font-size: 0.8em;
  }
}

.v-list-item__title {
  font-size: 0.8em;
}
</style>
