<template lang="pug">
router-view.bg-gray.pt-0
</template>

<script>
import commonMixins from "@/mixins/common.js"
export default {
  mixins: [commonMixins],
  data() {
    return {

    }
  },
  created() {
    if (['group'].includes(this.typeNode)) {
      this.$nextTick(() => {
        this.$router.push({ name: 'GlobalSummary', params: { paramCOD: this.$route.params.paramCOD }, query: this.$route.query })
      })
    } else {
      this.$store.commit('LOADING', true)
      this.$store.dispatch('getAllSitesOpinions')
    }
  },
}
</script>
