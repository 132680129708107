<template lang="pug">
div
  div.margin-bottom-0.gridFull
    v-row.flex-center.b-bottom.block-head.mb-0
      v-col(md="2")
        .block-count.pl-1
          span.mr-1.font-weight-med {{reportCounts}}
          translate Reports
      v-col(md="9")
      v-col.text-right(md="1")
        .block-help.ml-auto
          v-tooltip(left color="#222226" open-on-click=true max-width="550" nudge-top="20" nudge-left="10" content-class="tooltip-help tooltip-help-left")
            template(v-slot:activator="{ on, attrs }")
              img(:src="imgCommons.help" v-bind="attrs" v-on="on")
            div
              p.mb-0.pb-0(v-if="!isAdverbia") Opción de descargar los informes, en función del grupo de restaurantes que marca la selección del menú izquierdo.
              p.mb-0.pb-0(v-else) Opción de descargar los informes, en función del grupo de negocios que marca la selección del menú izquierdo.
              p.mb-0.pb-0 Nota importante: los datos que se muestran en el informe, serán los datos que haya en el momento de la descarga. Posteriormente pueden variar por la demora de entrada de datos en los sites
    v-row.contentOutPadding.mt-0
      v-col(md="12").contentOutPadding.tablaGR
        v-data-table(
        :headers="headers"
        :items="reports"
        :page.sync="reports.length / 19"
        :items-per-page="reportCounts"
        hide-default-footer
        class="elevation-1"
        item-key="idGenerate"
        :loading="loading"
        :loading-text="loadingtext"
        )
          template(v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }")
            span {{ header.text }}
          template(v-slot:item.report="{ item }")
            div#block-comment
              span {{ getReportName(item) }}
          template(v-slot:item.start_date="{ item }")
            span.text-date( dark) {{ item.start_date | moment("DD/MM/YYYY") }}
          template(v-slot:item.view="{ item }").text-center
            img.cursor-pointer(:src="imgCommons.download" width="24" @click="downloadReport(item)")
</template>

<script>
import SrvReports from '@/services/reports.js'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"

export default {
  mixins: [commonMixins],
  data() {
    return {
      img: {
        pdf: require('@/assets/img/ico-pdf.svg'),
      },
      reports: [],
      headers: [ // CABECERAS DE LA TABLE COMENTARIOS
        {
          text: this.$gettext('INFORME'),
          align: 'start',
          sortable: false,
          value: 'report',
          width: '37%'
        },
        { text: this.$gettext('FECHA'), value: 'start_date', sortable: false, width: '15%' },
        { text: this.$gettext('VIEW'), value: 'view', sortable: false, width: '6%' }
      ],
      options: {}, // OPCIONES DE V-TABLE
      loading: false, // BOOLEANA PARA MOSTRAR LOADING EN LA TABLE
      loadingtext: this.$gettext('Loading reports'),
      totalWidth: 0,
      nextScrollUrl: null,
      nextCall: false,
      reportCounts: 0
    }
  },
  // OBTENERMOS DEL STORE LOS FILTROS APLICADOS
  async created() {
    if (this.$store.getters.getNode > 0) {
      this.getReports()
    }

  },
  mounted() {
    this.scroll()
  },
  watch: {
    filterApply: function () {
      this.nextScrollUrl = null
      this.getReports()
    },
    ordering: function () {
      this.nextScrollUrl = null
      this.getReports()
    },
    totalWidth: function () {
      return this.totalWidth
    },
    reports(reports) {
      const data = {}
      reports.forEach(item => data[item.id] = item)
      window.data = data
    }
  },
  methods: {
    getReportName(item) {
      let name = ''
      let oStartDate = item.start_date.split('-')
      if (item.end_date) {
        let oEndDate = item.end_date.split('-')
        name = oStartDate[2] + ' a ' + oEndDate[2] + ' de ' + this.months[parseInt(oStartDate[1]) - 1]
        if (oStartDate[1] !== oEndDate[1]) {
          name = oStartDate[2] + ' de ' + this.months[parseInt(oStartDate[1]) - 1] + ' a ' + oEndDate[2] + ' de ' + this.months[parseInt(oEndDate[1]) - 1]
        }
      } else {
        name = item.__str__

      }
      return name
    },
    async downloadReport(item) {
      this.$store.commit(types.DOWNLOADING_EXCEL, true)
      let res
      if (this.nameInstance === "goiko") {
        res = await SrvReports.getReportExcelDownload(item.id)
      } else {
        res = await SrvReports.getReportExcelRestaliaDownload(item.id)
      }
      if (res.status === 200) {
        var blob = new Blob([res.data], {
          type: res.headers["content-type"],
        })
        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        let type = 'N'
        let id
        if (item.center) {
          type = 'C'
          id = item.center.id
        } else {
          id = item.node.id
        }

        let center = document.querySelector('[data-id="' + id + '"][data-type="' + type + '"]')
        let elementName = center.getAttribute('data-name')
        let name = elementName.replaceAll(' ', '-')
        link.download = `report_${name}_${this.getReportName(item, '-')}.xlsx`
        this.$store.commit(types.DOWNLOADING_EXCEL, false)
        link.click()
      }
      //


    },
    // LLAMAMOS AL SERVICIO QUE CARGA LOS COMENTARIOS CON LOS FILTROS APLICADOS
    sortArray(arrFiltered) {
      // Sort array
      arrFiltered = arrFiltered.sort((a, b) => {
        if (new Date(a.end_date) > new Date(b.end_date)) return -1
        if (new Date(a.end_date) < new Date(b.end_date)) return 1
        if (b.period_days > a.period_days) return 1
        if (b.period_days < a.period_days) return -1
      })
      return arrFiltered
    },
    getReports: async function () {
      if (!this.nextScrollUrl) {
        //report excel only goiko
        let res
        if (this.nameInstance === "goiko") {
          res = await SrvReports.getReportsExcel()
        } else {
          res = await SrvReports.getReportsExcelRestalia()
        }
        if (res.status === 200) {
          this.reports = this.sortArray(res.data.results)
          this.reportCounts = res.data.count
          this.nextScrollUrl = res.data.next
          this.loading = false
        }

      } else {
        SrvReports.getReportsNext(this.nextScrollUrl)
          .then(res => {
            if (res.status === 200) {
              this.nextScrollUrl = res.data.next
              this.reportCounts = res.data.count
              res.data.results.forEach(element => {
                this.reports.push(element)
              });
              this.reports = this.sortArray(this.reports)
              //this.$store.commit(types.FEEDBACK_OPINIONS, {results: custom})
              this.nextCall = false
            } else {
              // this.message = 'Se ha producido un error. Intentelo más tarde.'
            }
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      }
    },
    scroll() {
      window.onscroll = () => {
        if (this.$route.name === 'ReportsListExcel') {
          var body = document.body,
            html = document.documentElement;
          var height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);
          height = parseInt(height, 10) - 1
          let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height || parseInt((document.documentElement.scrollTop + window.innerHeight).toFixed(0), 10) + 1 == height || (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height + 1
          if (this.nextScrollUrl && bottomOfWindow && !this.nextCall) {
            this.nextCall = true
            this.getReports()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.paddingAnual {
  color: #000;
  font-weight: 600;
}

.paddingTrimestral {
  color: #FF7700;
  padding-left: 30px;
}

.paddingMensual {
  padding-left: 60px;
  color: #417505;
}

.paddingSemanal {
  padding-left: 90px;
  color: #8143B8;
}

.type {
  padding-left: 0;
}

.boxOrder {
  float: right;
  margin-top: 4px;

  .btnOrder {
    display: block;
    line-height: 0;
    cursor: pointer;

  }
}

.tipo {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 3px;
  margin: 0 auto;
}

.filters {
  padding: 15px 0;
}

.block-count {
  font-size: 1.125rem;
}

.block-head {
  border-bottom: 1px solid #DFE3E6;
}
</style>
