<template lang="pug">
  div
    div.margin-bottom-0.gridFull
      v-row.flex-center.block-head.mb-0
        v-col(md="2")
          .block-count.pl-1
            span.mr-1.font-weight-med {{reviewsCounts}}
            translate Reviews
        v-col(md="10")
          div.filters
            span.downloadExcel.mr-5(@click="downloadExcelCustom()")
              img(:src="imgCommons.download" width="10")
            span.labelFilter #[translate Opinion Sites]:
            div.filter
              filter-tree-menu(:min-width="134" type="unique" label-class="text-capitalize-first-letter" :options="opinionSitesApp" :itemsChecked="opinionSitesAppSelected" @checked="onCheckOpinionSitesApp" id-key="value" label-key="text")

      v-row.contentOutPadding.mt-0
        v-col(md="12").contentOutPadding.tablaGR
          v-data-table#tableReviewsApps(
            :headers="headers"
            :items="reviews"
            :page.sync="pageCustom"
            :items-per-page="itemsPerPageCustom"
            hide-default-footer
            class="elevation-1"
            item-key="external_id"
            :loading="loading"
            :loading-text="loadingtext"
          )
            template(v-slot:item.user_name="{ item }")
              div.boxNoteAndName
                span.boxDown(v-if="showDownComment(item)")
                  img.down(:src="imgCommons.down" :class="{'rotate':item.comentariocompleto}" @click="mostrarComentario(item)")
                span.py-1.mb-0.ml-6 {{ item.user_name }}
              p.py-1.mb-0.ml-8
                span.star-ratings-css(title=".625" :style="'--data-x:' + item.rating_global / 2" :class="{'apple': item.site_info.code !== 'GPS'}")

            template(v-slot:item.text="{ item }")
              .d-flex.align-baseline
                div
                  p.comentario.mb-0(:style="'max-width: ' + totalWidth + 'px'" :class="{'comentarioRecortado': (!item.comentariocompleto), 'marginTop20': (item.verrespuesta)}" dark) {{ getTextOpinion(item) }}
              .align-baseline.boxReply(v-if="item.verrespuesta")
                div.labelReply
                  strong Developer response
                div.replyReview
                  p.mb-0 {{ item.reply.text }}

            template(v-slot:item.source_date="{ item }")
              span.text-date( dark) {{ item.source_date | moment("DD/MM/YYYY") }}
            template(v-slot:item.site="{ item }")
              img(v-if="item.site_info.code === 'GPS'" :src="img.googlePlay")
              img(v-else="item.site === 'apple'" :src="img.appleStore")
            template(v-slot:item.reply="{ item }")
              div.text-center
                img.buttonShowReply(v-if="item.reply" :src="img.reply" @click="showReply(item)")

</template>

<script>
import MultiSelect from '@/components/filters/Multipleselect.vue'
import SrvAttention from '@/services/attention.js'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import FilterTreeMenu from "@/components/filters/filterTreeMenu";

export default {
  mixins: [commonMixins],
  components: {MultiSelect, FilterTreeMenu}, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
  data() {
    return {
      img: {
        googlePlay: require('@/assets/img/Google_Play.svg'),
        appleStore: require('@/assets/img/App_Store.svg'),
        reply: require('@/assets/img/ico-reply-yes.svg')
      },
      ordering: '-source_date',
      reviews: [],
      opinionSitesApp: [
        {value: '1', text: 'Google Play'},
        {value: '2', text: 'Apple store'}
      ],
      opinionSitesAppSelected: [],
      headers: [
        {text: this.$gettext('VALORATION'), value: 'user_name', sortable: false, width: '15%'},
        {text: this.$gettext('REVIEW'), value: 'text', sortable: false, width: '70%'},
        {text: this.$gettext('DATE'), value: 'source_date', sortable: false, width: '5%'},
        {text: this.$gettext('SITE'), value: 'site', sortable: false, width: '5%'},
        {text: this.$gettext('RESP'), value: 'reply', sortable: false, width: '5%'},
      ],
      loading: false,
      loadingtext: this.$gettext('Loading reviews'),
      totalWidth: 680,
      nextScrollUrl: null,
      nextCall: false,
      reviewsCounts: 0
    }
  },
  // OBTENERMOS DEL STORE LOS FILTROS APLICADOS
  async created() {
    if (this.$store.getters.getNode > 0) {
      this.getReviews()
    }
    if (this.reviews.length === 0) {
      if (this.$store.getters.getComments.length > 0) {
        this.reviews = this.$store.getters.getComments.results
        this.loading = false
      }
    }
  },
  mounted() {
    this.scroll()
  },
  watch: {
    filterApply: function () {
      this.nextScrollUrl = null
      this.reviews = []
      this.getReviews()
    },
    totalWidth: function () {
      return this.totalWidth
    },
    reviews(reviews) {
      const data = {}
      reviews.forEach(item => data[item.id] = item)
      window.data = data
    }
  },
  computed: {
    itemsPerPageCustom() {
      if (this.pagination) {
        return this.itemsPerPage
      }
      return this.reviews.length
    },
    pageCustom: {
      get() {
        if (this.pagination) {
          return this.page
        }
        return this.reviews.length
      },
      set(val) {
        this.page = val
      }
    },
  },
  methods: {
    downloadExcelCustom() {
      this.downloadExcel(null)
    },
    goItemNextLevelCustom(item) {
      let itemCustom = {}
      itemCustom.id = item.center.id
      itemCustom.name = item.center.name
      itemCustom.level = 9
      this.goItemNextLevel(itemCustom, true)
    },
    getTextOpinion(item) {
      let text = ''

      if (item.text !== null && item.text !== '') {
        text += item.text
      }
      return text
    },

    showDownComment: function (item) {
      if (item.text && item.text.length > 80) {
        return true;
      }
      return true
    },
    showReply(item) {
      item.verrespuesta = !item.verrespuesta
    },
    totalCommentWidth() {
      setTimeout(() => {
        if (document.querySelector('#tableReviewsApps')) {
          let box = document.querySelector('#tableReviewsApps')
          this.totalWidth = (box.offsetWidth * 55) / 100
          let comentarios = document.querySelectorAll('.comentario')
          for (let i = 0; i < comentarios.length; i++) {
            comentarios[i].style.width = this.totalWidth + 'px'
          }
        }
        window.addEventListener('resize', function () {
          if (document.querySelector('#tableReviewsApps')) {
            let box = document.querySelector('#tableReviewsApps')
            let totalWidth = (box.offsetWidth * 55) / 100
            let comentarios = document.querySelectorAll('.comentario')
            for (let i = 0; i < comentarios.length; i++) {
              comentarios[i].style.width = totalWidth + 'px'
            }
          }
        })
      }, 100)
    },
    // SE PULSA PARA DESPLEGAR COMENTARIO COMPLETO
    // atributo "comentariocompleto mapeado en la petición al api"
    mostrarComentario: function (item) {
      if (item.comentariocompleto == true) {
        item.comentariocompleto = false
      } else {
        item.comentariocompleto = true
      }
    },
    // FUNIONES LLAMADAS DESDE EL COMPONENTE MULTISELECT CON EMMIT

    onCheckOpinionSitesApp(val) {
      if (val && val.length) {
        this.$store.commit(types.APPS_FILTER_OPINIONS_SITES_SELECTED, val)
        this.opinionSitesAppSelected = val
      } else {
        this.$store.commit(types.APPS_FILTER_OPINIONS_SITES_SELECTED, [])
      }
      this.opinionSitesAppSelected = []
      this.nextScrollUrl = null
      this.reviews = []
      this.getReviews()
    },
    // LLAMAMOS AL SERVICIO QUE CARGA LOS COMENTARIOS CON LOS FILTROS APLICADOS
    getReviews: function () {
      this.$store.commit('LOADING', true)

      if (!this.nextScrollUrl) {
        SrvAttention.getReviewsApp(this.ordering)
          .then(res => {
            if (res.status === 200) {
              let custom = res.data.results.map((obj) => ({...obj, ['comentariocompleto']: false, ['verrespuesta']: false}))
              this.reviews = custom;
              this.reviewsCounts = res.data.count
              this.nextScrollUrl = res.data.next
              this.$store.commit(types.FEEDBACK_OPINIONS, {results: custom})
              this.loading = false
              this.totalCommentWidth()
              this.$store.commit('LOADING', false)

            } else {
              // this.message = 'Se ha producido un error. Intentelo más tarde.'
            }
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      } else {
        SrvAttention.getReviewsNextApp(this.nextScrollUrl)
          .then(res => {
            if (res.status === 200) {
              let ind = this.reviews.length;
              let custom = res.data.results.map((obj, index) => ({...obj, ['comentariocompleto']: false, ['verrespuesta']: false, ['idGenerate']: obj.id + '-' + (index + ind)}));
              custom.forEach(element => {
                this.reviews.push(element)
              });
              //this.reviews.push(custom);
              this.nextScrollUrl = res.data.next
              this.$store.commit(types.FEEDBACK_OPINIONS, {results: custom})
              this.nextCall = false
              //this.totalCommentWidth();
            } else {
              // this.message = 'Se ha producido un error. Intentelo más tarde.'
            }
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      }
    },
    scroll() {
      window.onscroll = () => {
        if (this.$route.name === 'AttentionAppsReviews') {
          var body = document.body,
            html = document.documentElement
          var height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight)
          height = parseInt(height, 10) - 1
          let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height || parseInt((document.documentElement.scrollTop + window.innerHeight).toFixed(0), 10) + 1 == height || (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height + 1
          if (this.nextScrollUrl && bottomOfWindow && !this.nextCall) {
            this.nextCall = true
            this.getReviews()
          }
        }
      }
    },

  },
}
</script>

<style lang="scss" scoped>


.boxNoteAndName {
  min-width: 30px;

  .boxDown {
    min-width: 20px;
    width: 10%;
    text-align: center;

    .down {
      margin-top: 5px;
      display: inline-block;
      transition: all ease 0.3s;
      cursor: pointer;

      &.rotate {
        transform: rotate(90deg)
      }
    }
  }

  .boxDown {
    display: inline-block;
    vertical-align: middle;
  }
}

/* ESTRELLAS */


.star-ratings-css {
  unicode-bidi: bidi-override;
  color: #CECECF;
  font-size: 25px;
  height: 25px;
  width: 100px;
  margin-left: 10px;
  position: relative;
  line-height: 25px;

  &:before {
    content: '★★★★★';
    top: 0;
    position: absolute;
  }

  &:after {
    color: #F59643;
    content: '★★★★★';
    position: absolute;
    z-index: 1;
    display: block;
    left: 0;
    top: 0;
    width: calc(var(--module-size) * var(--data-x));
    overflow: hidden;
  }

  &.apple {
    &:after {
      color: #52575A;
    }
  }

}

.comentario {
  transition: all ease 0.3s;
  font-size: 0.813rem;
  margin-top: -4px;

  &.marginTop20 {
    margin-top: 20px;
  }

  &.comentarioRecortado {
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    min-width: 0;
  }
}

.boxReply {

  padding-bottom: 30px;

  margin-top: 10px;
  position: relative;

  &:before {
    width: calc(100% + 30px);
    padding: 0;
    content: " ";
    border-top: 1px solid #D3D3D3;
    display: block;
    left: -15px;
    position: absolute;
  }

  .labelReply {
    padding: 20px 0 12px 10px;
  }

  .replyReview {
    background-color: #EBEBEB;
    border-radius: 4px;
    font-size: 13px;
    padding: 10px;
    width: 100%;
  }
}

.buttonShowReply {
  cursor: pointer;
}

.filters {
  padding: 15px 0;
}

.block-count {
  font-size: 1.125rem;
}

.block-head {
  border-bottom: 1px solid #DFE3E6;
}

</style>
