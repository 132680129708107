<template lang="pug">
  v-row.px-2.ma-0.gr-global
    div.col-12
      skeleton-card(v-if="loading")
      v-card(v-else)
        v-card-title
          translate Gastroranking rating
          .block-help.ml-auto
            v-tooltip(left color="#222226" open-on-click=true max-width="750" content-class="tooltip-help tooltip-help-left")
                template(v-slot:activator="{ on, attrs }")
                    img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                div
                    p.mb-0.pb-0 GastroRanking es un índice de valoración de restaurantes que recopila y convierte en una nota única, los comentarios y puntuaciones que se emiten en todas las páginas de opiniones, reservas y redes sociales sobre los restaurantes.
                    p.mb-0.pb-0 El valor que se obtiene, simula cómo pensamos a la hora de valorar un restaurante. Por lo que se asemeja bastante a la idea que percibe un usuario cuando lee las opiniones en Internet.
                    p.mb-0.pb-0 GastroRanking se obtiene mediante un algoritmo que ajusta y recalcula diariamente aplicando criterios muy importantes tales como:
                    ul
                        li Número total de opiniones. A más opiniones, más fiabilidad.
                        li Análisis semántico.
                        li Influencia del usuario que emite la opinión.
                        li Antigüedad / vigencia de la valoración. A más reciente, más valiosa.
                        li Subvaloraciones: peso de los apartados que hacen referencia a la cocina, servicio, ambiente y precio.
                        li El resultado final será un índice ponderado de valor 0-10, de idéntica escala que las que realizan los usuarios sobre los restaurantes.
                    p.mb-0.pb-0 De esta manera, analizando toda la información existente en Internet sobre un restaurante, GastroRanking proporciona una información más fiable, precisa y representativa de la valoración del mismo por parte de sus clientes.

        v-data-table(:headers="headers" :items="data" :loading="loading" hide-default-footer class="elevation-1")
          template(v-slot:header.name="{ header }")
            | &nbsp;
          template(v-slot:item.gastrorank="{ item }")
            div.d-flex.justify-space-between.align-center
              div.trend-bar.bar-gray
                div.trend-bar(:style="{ maxWidth: `${ calcBarPercentage(item.gastrorank) }%`, backgroundColor: `${ getColorNote(item.gastrorank,'color') }` }")
              div.trend-value
                span(:class="getColorNote(item.gastrorank)") {{ item.gastrorank }}
          template(v-slot:item.tend_gastrorank="{ item }")
            span(:class="getColorNote(item.tend_gastrorank)") {{ item.tend_gastrorank }}
          template(v-slot:item.trend="{ item }")
            v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
              template(v-slot:activator="{ on, attrs }")
                span.arrow(v-bind="attrs" v-on="on" :class="item.trend")
              span {{ item.tend_gastrorank | formatNumber }}

      v-card(v-if="datasets.length")
        v-card-title
          translate Evolution of the GastroRanking valuation
          .block-help.ml-auto
            v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="20" nudge-left="10" content-class="tooltip-help tooltip-help-left")
              template(v-slot:activator="{ on, attrs }")
                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
              div
                p.mb-0.pb-0 {{ $gettext('Evolution of the GastroRanking rating for the current month and one year back.') }}

        div.col-12
          chart-line(:chartData="{ labels, datasets }" :minY="minY" :maxY="maxY")

</template>
<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import SrvGastroRanking from '@/services/gastroranking.js'
import commonMixins from "@/mixins/common.js"
import chartLine from '@/components/charts/line-gr.vue'

export default {
  components: {SkeletonCard, chartLine},
  mixins: [commonMixins],
  data() {
    return {
      headers: [
        {
          text: this.$gettext('Nombre'),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$gettext('Nota'),
          align: 'start',
          value: 'gastrorank',
          width: '50%'
        },
        {
          text: this.$gettext('N° de Opiniones'),
          align: 'center',
          value: 'review_count',
        },
        {
          text: this.$gettext('P.A.'),
          align: 'center',
          value: 'tend_gastrorank',
        },
        {
          text: this.$gettext('Tendencia'),
          align: 'center',
          value: 'trend',
        },
      ],
      data: [],
      loading: true,
      datasets: [],
      maxY: 10,
      minY: 0,
      labels: [
        this.$gettext('ENE'),
        this.$gettext('FEB'),
        this.$gettext('MAR'),
        this.$gettext('ABR'),
        this.$gettext('MAY'),
        this.$gettext('JUN'),
        this.$gettext('JUL'),
        this.$gettext('AGO'),
        this.$gettext('SEP'),
        this.$gettext('OCT'),
        this.$gettext('NOV'),
        this.$gettext('DIC'),
      ],
    }
  },
  async mounted() {
    const {status, data} = await SrvGastroRanking.getGastroRank()
    if (status === 200) {
      let min = new Array()
      let max = new Array()
      this.data = data.map((item, index) => {
        item.trend = this.getArrow(item.gastrorank,item.tend_gastrorank)
        this.datasets.push({
          label: item.name,
          backgroundColor: this.colours[index],
          borderColor:  this.colours[index],
          fill: false,
          data: item.year_data
        })
        // COMPROBAMOS MINIMOS Y MAXIMOS
        min.push(Math.min(...item.year_data))
        max.push(Math.max(...item.year_data))

        this.labels = item.year_month_label.map(month => this.$gettext(month))

        return item
      })
      this.loading = false
    } else {
      this.logout()
    }
  },
  methods: {
    calcBarPercentage(value) {
      return (value / 10) * 100
    },
    calcBarColor(value) {
      return value > 5 ? '#3CC480' : '#E8523F'
    }
  }
}
</script>

<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>

<style lang="scss" scoped>
.trend-bar {
  display: flex;
  flex: 1;
  height: 10px;
  background: transparent;
  border-radius: 10px;
}

.bar-gray {
  background: #efefef;
}

.trend-value {
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}


.v-card {
  margin-bottom: 2em;

  .v-card__title {
    position: relative;
    font-size: .875rem;
    font-weight: 600;

    span {
      &:after {
        content: " ";
        width: 100px;
        height: 1.8px;
        background-color: #979797;
        display: block;
      }
    }
  }
}
</style>
