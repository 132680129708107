<template lang="pug">
div
    v-dialog.dialogEdit(v-model="show"
        width="680")
        v-card.dialogEdit
            v-card-text.pb-0
            v-container
                div.gr-loader-snipper--wrapper(v-if="loading")
                    span.gr-loader-snipper
                skeleton-card(v-if="loading")
                v-row#headerModalEdit.pt-3(v-if="!loading")
                    v-col(cols="10")
                        span(v-if="!isAdverbia") #[translate Restaurant images]
                        span(v-else) Imágenes del negocio
                    v-col.mt-5(cols="2")
                        img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                v-row#bodyModalEdit(v-if="!loading")
                    v-col(cols="12")
                        v-container
                            v-row
                                Errors(v-if="errors" :errors="errors")
                                v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                                    div.messageError
                                        span {{ messageError ? messageError : $gettext('An error occurred while recording.') }}                                                                      
                                v-col.text-left.pb-0(cols="8")
                                    p
                                        span #[translate The photo size requirements are as follows:]
                                        br
                                        span #[translate Size: 250 × 250 - 5000 × 5000 pix]
                                        br
                                        span #[translate Weight: 10 KB - 5 MB]
                                    p 
                                        span IMPORTANTE!!!
                                        br
                                        span Si ya tienes una imagen de Cover, Logo o Perfil,  y deseas cambiarla, porfavor, eliminala primero y luego podrá estar disponible dicha categoría para subir una nueva!
                                v-col.pb-0(cols="4")
                                    img.preview(:src="url" v-if="url" id="imgUploading")
                                    <div v-else class="d-flex items-center h-full justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                                            <path id="Trazado_5018" data-name="Trazado 5018" d="M9,3h6l2,2h4a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V6A1,1,0,0,1,3,5H7Zm3,16a6,6,0,1,0-6-6A6,6,0,0,0,12,19Zm0-2a4,4,0,1,1,4-4A4,4,0,0,1,12,17Z" transform="translate(-2 -3)" fill="#fff"/>
                                        </svg>
                                    </div>                                        

                                v-col.text-left(cols="12")                                    
                                    v-row                                        
                                        v-col(md="6")                                                
                                            v-file-input.addPhoto(
                                                :rules="rules"
                                                @change="setImagePreview"
                                                v-model="image"
                                                id="file" ref="file"
                                                accept="image/png, image/jpeg, image/bmp"
                                                :placeholder="placeholderAddPhoto"
                                                prepend-icon="mdi-camera"
                                                label="")
                                        v-col(md="6")
                                            span.titleSelectCategory #[translate Select a Category]:
                                            select.selectFullEditModal(v-model="categoriePhoto")
                                                option(v-for="cat in categoriesPhoto" :value="cat") {{ cat.name}}

                    v-col.boxThumbnails(cols="12")
                        v-container
                            v-row
                                v-col.text-left(cols="12")                                    
                                    v-row                                        
                                        v-col(md="2" v-for="(photo, index) in data.googlephotos")
                                            .photo
                                                span {{ photo.category }}
                                                div.boxPhoto
                                                    img(:src="photo.photo_thumbnail_url")
                                                div.text-center.removePhoto
                                                    img(:src="imgtrash" @click="removePhoto(index)")



                v-row#footerModalEdit
                    v-col(cols="12")
                        button.btnCancelEditModal(@click.stop="handleCancel()") #[translate Cancel]
                        button.btnSaveEditModal(@click="save()")  #[translate Save]
</template>
<script>
import commonMixins from '@/mixins/common.js'
import SrvLocal from '@/services/local.js'
import SkeletonCard from '@/components/common/skeletonCard.vue'
import Errors from '@/components/common/Errors.vue'

export default {
    mixins: [commonMixins],
    components: { SkeletonCard, Errors },
    props: ['visible', 'idBussines'],
    data() {
        return {
            loading: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            noMap: require('@/assets/img/no-map.svg'),
            imgtrash: require('@/assets/img/ico-trash.svg'),
            name: '',
            url: null,
            image: null,
            data: {},
            categoriesPhoto: [],
            photosChanged: false,
            categoriePhoto: { name: "Otras", photo_type: "ADDITIONAL" },
            section: 'photos-restaurant',
            rules: [
                value => !value || value.size < 5000000 || this.$gettext('Avatar size should be less than 5 MB!'),
            ],
            file: '',
            saveStatus: true,
            messageError: null,
            errors: null
        }
    },
    watch: {
        visible(newValue) {
            if (newValue) {
                this.data = {}
                this.loadData()
            }
        }
    },
    computed: {
        placeholderAddPhoto() {
            return this.$gettext('Add a photo')
        },
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    methods: {
        handleCancel() {
            this.show = false
            this.clearStates()
        },
        clearStates() {
            this.image = null
            this.url = null
            this.categoriePhoto = { name: "Otras", photo_type: "ADDITIONAL" }
        },
        handleFileUpload() {
            this.file = this.$refs.file.input.files[0];
        },
        removePhoto(key) {
            this.data.googlephotos.splice(key, 1)
            this.photosChanged = true
        },
        setImagePreview() {
            this.url = this.image ? URL.createObjectURL(this.image) : ""
        },

        async loadData() {
            this.loading = true
            await SrvLocal.getCategoriesPhoto(this.idBussines)
                .then(res => {
                    if (res.status === 200) {
                        this.categoriesPhoto = res.data
                        this.loading = false
                    }
                }, () => {
                })
            await SrvLocal.getLocalMarketing(this.section)
                .then(res => {
                    if (res.status === 200) {
                        this.data = res.data
                        this.loading = false
                    }
                }, () => {
                })
        },
        async save() {
            // IDS
            this.loading = true
            if (this.photosChanged) {
                let ids = { googlephotos: [] }
                this.data.googlephotos.forEach(element => {
                    ids.googlephotos.push(element.id)
                })
                const { status } = await SrvLocal.setLocalMarketing(this.section, ids)
                if (status !== 200) {
                    this.saveStatus = false
                }
            }
            let valid = true
            if (this.image) {
                let img = document.querySelector('#imgUploading') ?? URL.createObjectURL(this.image)
                if (img?.naturalHeight < 250 || img?.naturalHeight > 5000 || img?.naturalWidth < 250 || img?.naturalWidth > 5000) {
                    valid = false
                    this.errors = 'Se deben respetar los requisitos de las imagenes.'
                }
                /* minimum 480 x 270; maximum 2120 x 1192 */
                /*if (e.category.photo_type === 'COVER' && (img.naturalHeight < 270 || img.naturalHeight > 1192 || img.naturalWidth < 480 || img.naturalWidth > 2120)) {
                    return true
                }*/

                try {
                    if (valid) {
                        const { status } = await SrvLocal.uploadLocalMarketingPhoto(this.idBussines, this.image, this.categoriePhoto.photo_type)
                        if (status !== 200) {
                            this.saveStatus = false
                        }
                        if (this.saveStatus) {
                            this.clearStates()
                        }

                    }
                } catch (e) {
                    this.loading = false
                }
            }

            if (this.saveStatus) {
                const res = await SrvLocal.syncBunsinessPhotos(this.$store.getters.getNode)
                if (Object.keys(res.data).includes('success') && !res.data.success) {
                    this.saveStatus = false
                    this.messageError = res.data.error.message
                } else {
                    this.show = false
                    this.$emit('refreshSync')
                }
            }

            this.loading = false
        }
    }
}
</script>
<style lang="scss" scoped>
.boxThumbnails {
    border-top: 1px solid #000;
}

.addPhoto {
    border: 2px solid #00ADFC;
    max-height: 53px;
    color: #00ADFC;
}

.preview {
    height: 73px;
}

.titleSelectCategory {
    font-weight: 600;
}

.boxPhoto {
    height: 65px;
    overflow: hidden;
}

.removePhoto {
    img {
        cursor: pointer;
    }
}

.messageError {
    background-color: #F5B1B8;
    border: 1px solid #F93549;
    color: #F93549;
    padding: 15px;
}
</style>