import SatisfactionHome from '../views/Satisfaction/Home.vue'
import SatisfactionQuiz from '../views/Satisfaction/Pages/Quiz.vue'
import SatisfactionQuizIndicators from '../views/Satisfaction/Pages/Indicators.vue'
import SatisfactionQuizComments from '../views/Satisfaction/Pages/Comments.vue'
import SatisfactionQuizRankings from '../views/Satisfaction/Pages/Rankings.vue'
import SatisfactionSac from '../views/Satisfaction/Pages/Sac.vue'
import SatisfactionSacEvolution from '../views/Satisfaction/Pages/ClaimSACEvolution.vue'
import SatisfactionSacClaims from '../views/Satisfaction/Pages/ClaimSACClaims.vue'
import SatisfactionSacSuggestions from '../views/Satisfaction/Pages/ClaimSACSuggestions.vue'

export default {
    path: '/satisfaction',
    name: 'SatisfactionHome',
    redirect: '/satisfaction/quiz/indicators/:paramCOD?',
    component: SatisfactionHome,
    meta: {Auth: true},
    props: true,
    children: [
          {
            path: 'sac',
            name: 'SatisfactionSac',
            component: SatisfactionSac,
            redirect: '/satisfaction/sac/evolution/:paramCOD?',
            props: true,
            meta: {Auth: true},
            children: [
              {
                path: 'evolution/:paramCOD?',
                name: 'SatisfactionSacEvolution',
                component: SatisfactionSacEvolution,
                meta: {Auth: true},
                props(route) {
                  return  route.query || {}
                }
              },
              {
                path: 'claims/:paramCOD?',
                name: 'SatisfactionSacClaims',
                component: SatisfactionSacClaims,
                meta: {Auth: true},
                props(route) {
                  return  route.query || {}
                }
              },
              {
                path: 'suggestions/:paramCOD?',
                name: 'SatisfactionSacSuggestions',
                component: SatisfactionSacSuggestions,
                meta: {Auth: true},
                props(route) {
                  return  route.query || {}
                }
              },
            ]
          },
          {
            path: 'quiz',
            name: 'SatisfactionQuiz',
            component: SatisfactionQuiz,
            redirect: '/satisfaction/quiz/indicators/:paramCOD?',
            props: true,
            meta: {Auth: true},
            children: [
              {
                path: 'indicators/:paramCOD?',
                name: 'SatisfactionQuizIndicators',
                component: SatisfactionQuizIndicators,
                meta: {Auth: true},
                props(route) {
                  return  route.query || {}
                }
              },
              {
                path: 'comments/:paramCOD?',
                name: 'SatisfactionQuizComments',
                component: SatisfactionQuizComments,
                meta: {Auth: true},
                props(route) {
                  return  route.query || {}
                }
              },
              {
                path: 'rankings/:paramCOD?',
                name: 'SatisfactionQuizRankings',
                component: SatisfactionQuizRankings,
                meta: {Auth: true},
                props(route) {
                  return  route.query || {}
                }
              }
          ]
        },
    ]
  }