<template>
    <div class="cartDishPrices">
        <div class="cartDishPrice" v-for="(price, index) in data" :key="index">
            <!--<span class="cartDishQuantity">{{ price.quantity }}</span>-->
            <span>{{ price.price }} {{ price.currency}}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            default: null,
            type: Array,
            required: true
        }
    }
}
</script>

<style>
.cartDishPrices{
    display: flex;
    
}
.cartDishPrice{
    color:#4A90E2;
    font-size: 18px;
    font-weight: 600;
    margin-left: 22px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.cartDishQuantity{
    color: #222226;
    font-size: 14px;
    font-weight: 400;
}
</style>