<template lang="pug">
div
    div.gridFull
        v-row.headTitleGrid 
            v-col.titleGrid(md="8")
                span #[translate Restaurant ranking]
            v-col(md="4")
                div.filters
                    span.downloadExcel.mr-5(@click="downloadExcelCustom()")
                        img(:src="imgCommons.download" width="10")
                    span.labelFilter #[translate Questionnaire Type]:
                    div.filter                            
                        multi-select.inline-block(:options="surveyTypes" @checked="onCheckSurveyTypes")
        v-row
            v-col#tableRanking(md="12")
                div.headerTableRanking.row.ma-0
                    div.headerItem.col-id
                        p.mb-0 #
                        img(:src="imgCommons.sort" @click="sortdata('name')") 
                    div.headerItem.col-text
                        p
                    div.headerItem.col-number
                        p.mb-0 #[translate No. of Questionnaires]
                        img(:src="imgCommons.order" @click="sortdata('-total_surveys')") 
                    div.headerItem.col-info
                        p.mb-0 #[translate Satisfaction]
                        img(:src="imgCommons.order" @click="sortdata('-avg_satisfaction')") 
                    div.headerItem.col-info
                        p.mb-0 #[translate Repetition]
                        img(:src="imgCommons.order" @click="sortdata('-avg_return')") 
                    div.headerItem.col-info
                        p.mb-0 #[translate Recommendation]
                        img(:src="imgCommons.order" @click="sortdata('-avg_suggest')") 
                    div.headerItem.col-number
                        p.mb-0 #[translate No. of Comments]
                    div.headerItem.col-percent
                        p.mb-0 #[translate Positive]
                    div.headerItem.col-percent
                        p.mb-0 #[translate Negative]
                div.contentTableRanking.row.ma-0
                    p.ml-3(v-if="!itemsRanking") #[translate No data]
                    div.itemContent.col-12.pa-0(v-for="(item, index) in itemsRanking" :key="index")
                        div.item.col-id
                            p.mb-0 {{index + 1}}
                        div.item.col-text
                            p.mb-0(:class="{'link-text': index > 0}" @click="goItemNextLevel(item,true)") {{ item.name }}
                        div.item.col-number.text-right.pr-10
                            p.mb-0 {{ item.total_surveys }}
                        div.item.col-info
                            p.mb-0(:class="getColor(item.avg_satisfaction)") {{ item.avg_satisfaction | formatNumber(1,1) }}
                        div.item.col-info
                            p.mb-0(:class="getColor(item.avg_return)") {{ item.avg_return | formatNumber(1,1) }}
                        div.item.col-info
                            p.mb-0(:class="getColor(item.avg_suggest)") {{ item.avg_suggest | formatNumber(1,1) }}
                        div.item.col-number.text-right.pr-9
                            p.mb-0 {{ item.total_survey_comments }}
                        div.item.col-percent
                            div.vertical-middle
                                span.itemBoxBgColorTable(:class="getColor(item.percentPlus)" dark) &nbsp;
                                p.mb-0.ml-1 &nbsp; {{ ((item.positive_surveys / item.total_surveys) * 100).toFixed(0)}} %
                        div.item.col-percent
                            div.vertical-middle
                                span.itemBoxBgColorTable(class="bg-minimun" dark) &nbsp; 
                                p.mb-0.ml-1 &nbsp;&nbsp; {{ ((item.negative_surveys / item.total_surveys) * 100).toFixed(0)}} %
                               
            
</template>

<script>
import MultiSelect from '@/components/filters/Multipleselect.vue'
import SrvSatisfaction from '@/services/satisfaction.js'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"

export default {
    mixins: [commonMixins],
    data() {
        return {
            surveyTypes: [
                { value: 1, text: 'Local' },
                { value: 2, text: 'Take away' },
                { value: 3, text: 'Delivery' },
                { value: 4, text: 'Sin tipo' }
            ],
            surveyTypesSelected: [],
            page: 1,
            itemsRanking: [],
            itemsPerPage: 25,
            nextScrollUrl: null,
            nextCall: false,
            headers: [
                {
                    text: '#',
                    align: 'start',
                    sortable: false,
                    value: 'pos',
                },
                { text: '', value: 'name' },
                { text: this.$gettext('No. of Questionnaires'), value: 'numberQuestions', align: 'center' },
                { text: this.$gettext('Satisfaction'), value: 'pointsSatisfaction', align: 'center' },
                { text: this.$gettext('Repetition'), value: 'pointsRepetition', align: 'center' },
                { text: this.$gettext('Recommendation'), value: 'pointsRecomendation', align: 'center' },
                { text: this.$gettext('No. of Comments'), value: 'numberComments', align: 'center', sortable: false, },
                { text: this.$gettext('Positive'), value: 'percentPlus', align: 'center', sortable: false, },
                { text: this.$gettext('Negative'), value: 'percentNegative', align: 'center', sortable: false, },
            ],
            ordering: 'total_surveys'
        }
    },
    components: { MultiSelect },
    created() {
        // window.addEventListener('scroll', this.handleScroll)
        if (this.itemsRanking.length == 0) {
            this.itemsRanking = this.$store.getters.getRanking.data
            if (!this.itemsRanking) {
                if (this.$store.getters.getNode > 0) {
                    this.getRanking();
                }
            }
        }

    },
    mounted() {
        this.scroll()
    },
    methods: {
        downloadExcelCustom() {
            let params = { ordering: this.ordering }
            this.downloadExcel(params)
        },
        // METODO QUE EMITE EL COMPONENTE MULTISELECT    
        onCheckSurveyTypes(val) {
            this.surveyTypesSelected = val
            this.$store.commit(types.SATISFACTION_FILTER_SURVEY_TYPES_SELECTED, val)
            this.getRanking()
        },

        // OBTENEMOS EL RANKING
        getRanking: function (nextUrl = null, ordering = 'total_surveys') {
            if (!nextUrl) {
                SrvSatisfaction.getSatisfactionRanking(ordering)
                    .then(res => {
                        if (res.status === 200) {
                            this.itemsRanking = res.data.results
                            this.nextScrollUrl = res.data.next
                            this.$store.commit(types.SATISFACTION_RANKING, res.data)
                            this.loading = false
                        } else {
                            // this.message = 'Se ha producido un error. Intentelo más tarde.'
                        }
                    }, () => {
                        // this.message = 'Se ha producido un error. Intentelo más tarde.'
                    })
            } else {
                SrvSatisfaction.getSatisfactionRankingNext(nextUrl)
                    .then(res => {
                        if (res.status === 200) {
                            res.data.results.forEach(element => {
                                this.itemsRanking.push(element)
                            });
                            this.nextScrollUrl = res.data.next
                            this.$store.commit(types.SATISFACTION_RANKING, res.data)
                            this.nextCall = false
                        } else {
                            // this.message = 'Se ha producido un error. Intentelo más tarde.'
                        }
                    }, () => {
                        // this.message = 'Se ha producido un error. Intentelo más tarde.'
                    })
            }
        },
        scroll() {
            window.onscroll = () => {
                if (this.$route.name === 'SatisfactionQuizRankings') {
                    var body = document.body,
                        html = document.documentElement
                    var height = Math.max(body.scrollHeight, body.offsetHeight,
                        html.clientHeight, html.scrollHeight, html.offsetHeight)
                    height = parseInt(height, 10) - 1
                    let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height || parseInt((document.documentElement.scrollTop + window.innerHeight).toFixed(0), 10) + 1 == height || (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height + 1
                    if (this.nextScrollUrl && bottomOfWindow && !this.nextCall) {
                        this.nextCall = true
                        this.getRanking(this.nextScrollUrl)
                    }
                }
            }
        },
        sortdata(ordering) {
            if (ordering === this.ordering && ordering !== 'total_surveys') {
                ordering = ordering.substring(1)
            } else if (ordering === this.ordering && ordering === 'total_surveys') {
                ordering = '-' + ordering
            }
            this.ordering = ordering
            this.getRanking(null, this.ordering)
        }
    },
    watch: {
        filterApply: function () {
            this.getRanking()
        }
    },
}
</script>

<style lang="scss" scoped>
.filters {
    display: flex;
    align-items: center;
}

#tableRanking {
    padding: 2px;

    .headerTableRanking {
        .headerItem {
            text-align: center;
            font-size: 0.75rem;
            border-top: 1px solid #9797973d;
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            &.col-id {
                max-width: 4%;
                flex: 0 0 4%;

                p {
                    font-size: 0.875rem;
                    margin-left: 20%;
                }
            }

            &.col-text {
                max-width: 22%;
                flex: 0 0 22%;
            }

            &.col-number {
                max-width: 14%;
                flex: 0 0 14%;
            }

            &.col-info {
                max-width: 10%;
                flex: 0 0 10%;
            }

            &.col-percent {
                max-width: 8%;
                flex: 0 0 8%;
            }

            img {
                cursor: pointer;
            }
        }
    }

    .contentTableRanking {
        .itemContent {
            text-align: center;
            font-size: 0.875rem;
            border-top: 1px solid #9797973d;
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: center;

            .item {
                &.col-id {
                    max-width: 4%;
                    flex: 0 0 4%;

                    p {
                        margin-left: -35%;
                    }
                }

                &.col-text {
                    max-width: 22%;
                    flex: 0 0 22%;
                    text-align: left;

                    p {
                        // color:#4A90E2;
                    }
                }

                &.col-number {
                    max-width: 14%;
                    flex: 0 0 14%;
                }

                &.col-info {
                    max-width: 10%;
                    flex: 0 0 10%;

                    p {
                        width: 60px;
                        padding: 3px 10px;
                        border-radius: 7px;
                        margin: auto;
                        color: #fff;
                    }
                }

                &.col-percent {
                    max-width: 8%;
                    flex: 0 0 8%;

                    div {
                        text-align: left;
                        margin-left: 25%;
                    }
                }
            }
        }
    }
}

.v-application #tableRanking .elevation-1 {
    box-shadow: none !important;
}</style>