<template lang="pug">
div.margin-bottom-0
            skeleton-card(v-if="isLoading")
            v-card.gridFull.h-auto.ml-3.mb-4.ma-0.pl-6.pr-9(v-if="!isLoading && !phoneNumbers && !titleNotification && !regularHours && !categories && !boolAttributes && !latlng")
                v-row.ma-0
                    v-col.d-flex.align-center.text-14.py-6(sm="12")
                        
                        span.mr-16 #[translate Este centro no tiene nuevas notificaciones.]
            //v-card.gridFull.h-auto.ml-3.mb-4.ma-0.pl-6.pr-9(v-if="!isLoading")
                //v-row.ma-0
                    v-col.d-flex.align-center.text-14.py-6(sm="12")
                        img.mr-4(:src="img.warning")
                        span.mr-16 #[translate Tu empresa se ha marcado como cerrada permanentemente]
                        button.btnBlue #[translate Cerrar]

                        
            box-notification(v-if="storefrontAddress" :idBussines="center" :data="storefrontAddress" :hasShadow="sadow")
            box-notification(v-if="latlng" :idBussines="center" :data="latlng" :hasShadow="sadow")
            box-notification(v-if="titleNotification" :idBussines="center" :data="titleNotification" :hasShadow="sadow")
            box-notification(v-if="regularHours" :idBussines="center" :data="regularHours" :hasShadow="sadow")
            box-notification(v-if="categories" :idBussines="center" :data="categories" :hasShadow="sadow")
            box-notification(v-if="boolAttributes" :idBussines="center" :data="boolAttributes" :hasShadow="sadow")
            box-notification(v-if="phoneNumbers" :idBussines="center" :data="phoneNumbers" :hasShadow="sadow")
            box-notification(v-if="websiteUri" :idBussines="center" :data="websiteUri" :hasShadow="sadow")

            
</template>

<script>
  import SkeletonCard from '@/components/common/skeletonCard.vue'
  import SrvLocal from '@/services/local.js'
  import BoxNotification from '@/components/local/BoxNotification.vue'

export default {
    props:['center', 'isLoading','sadow', 'load'],
    components: {
        BoxNotification,
        SkeletonCard
    },
    data(){
        return {
            notifications: []
        }
    },
    created(){
        this.getNotifications()
    },
    methods:{
        async getNotifications() {
            const res = await SrvLocal.getNotifications(this.center)
            if (res.status === 200) {
            res.data.map( e => {
                    if (e.endpoint_type == "ATTRIBUTE") {
                    e.suggested_status = e.suggested_value.values[0]
                    }
                })
            this.notifications = res.data
            }
      },
    },
    computed: {
      storefrontAddress(){
        let data = this.notifications.filter(e => e.affected_field === 'storefrontAddress')  
        if (data.length > 0) {      
          let res = {help_text: data[0].help_text ,key: 'storefrontAddress', title: data[0].display_name, type_notification: 'info', message: 'Es necesario que esta notificacion la revises directamente en google',
          actual: data[0].suggested_value, anterior: data[0].current_value}
          return res
        } else {
          return null
        }
      },
      latlng(){
        let data = this.notifications.filter(e => e.affected_field === 'latlng')  
        if (data.length > 0) {      
          let res = {help_text: data[0].help_text ,key: 'latlng', title: data[0].display_name, type_notification: 'info', message: 'Es necesario que esta notificacion la revises directamente en google',
          actual: [data[0].suggested_value], anterior: [data[0].current_value]}
          return res
        } else {
          return null
        }
      },
      titleNotification(){
        let data = this.notifications.filter(e => e.affected_field === 'title')  
        if (data.length > 0) {      
          let res = {help_text: data[0].help_text ,key: 'title', title: 'Titulo', type_notification: 'acept', message: 'Google ha actualizado tus atributos',
          actual: [data[0].suggested_value], anterior: [data[0].current_value]}
          return res
        } else {
          return null
        }
      },
      regularHours(){
        let data = this.notifications.filter(e => e.affected_field === 'regularHours')  
        if (data.length > 0) {    
        let res = {help_text: data[0].help_text, key: 'regularHours', title: 'Horario de apertura', type_notification: 'acept', message: 'Google ha actualizado tus atributos',
        actual: data[0].suggested_value, anterior: data[0].current_value}
        return res
        } else {
          return null
        }
      },
      categories(){
        let data = this.notifications.filter(e => e.affected_field === 'categories')   
        if (data.length > 0) {          
        let res = {help_text: data[0].help_text, key: 'categories', title: data[0].display_name, type_notification: 'acept', message: 'Google ha actualizado tú categoría',
        actual: data[0].suggested_value, anterior: data[0].current_value}
        return res
        } else {
          return null
        }
      },
      boolAttributes(){
        let data = this.notifications.filter(e => e.endpoint_type === 'ATTRIBUTE')
        if (data.length > 0) {          
        let res = {key: 'attributes', title: 'Nuevos atributos disponibles', type_notification: 'attributes', message: 'Google ha actualizado tú categoría',
        actual: '', anterior: '', bool_attributes: data}
        return res
        } else {
          return null
        }
      },
      phoneNumbers(){
        let data = this.notifications.filter(e => e.affected_field === 'phoneNumbers')
        if (data.length > 0) {          
          let res = {help_text: data[0].help_text, key: 'phoneNumbers', title: data[0].display_name, type_notification: 'acept', message: 'Google ha actualizado tú teléfono',
          actual: data[0].suggested_value, anterior: data[0].current_value}
          return res
        } else {
          return null
        }
      },
      websiteUri(){
        let data = this.notifications.filter(e => e.affected_field === 'websiteUri')
        if (data.length > 0) {          
          let res = {help_text: data[0].help_text, key: 'websiteUri', title: data[0].display_name, type_notification: 'acept', message: 'Google ha actualizado tú dirección web',
          actual: [data[0].suggested_value], anterior: [data[0].current_value]}
          return res
        } else {
          return null
        }
      },
    },
    watch:{
      load(newValue){
        if (newValue) {
          this.getNotifications()
        }

      }

    }
}
</script>