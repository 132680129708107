<template lang="pug">
    div.boxSectionHeaderAndBody
      div#navtop(:class="{'anchoEscritorioSubnavCerrado': subNavClosed, 'noTree': $route.meta.isGP}")
        div.sectionmenu
            div.firstlevel
              router-link(:to="{name: 'AttentionInfoEvolution', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Info]
              router-link(:to="{name: 'AttentionAppsIndicators', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate APPs]
              select-country-and-logout     
      div.gridSection
        div.sectionmenu
          div.secondlevel
            .quiz
              router-link(v-if="$router.history.current.name.indexOf('AttentionInfo') >= 0" :to="{name: 'AttentionInfoEvolution', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Evolution]
              router-link(v-if="$router.history.current.name.indexOf('AttentionInfo') >= 0" :to="{name: 'AttentionInfoIncidents', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Incidents]
            .sac
              router-link(v-if="$router.history.current.name.indexOf('AttentionApps') >= 0" :to="{name: 'AttentionAppsIndicators', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Indicators]
              router-link(v-if="$router.history.current.name.indexOf('AttentionApps') >= 0 && $store.getters.getNodeLevel == 1" :to="{name: 'AttentionAppsReviews', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Reviews]
          div.pr-5.thirstlevel(:class="{'pt-12': this.$router.history.current.name.indexOf('Attention') >= 0}")
            v-row.flex-center
              v-col.pr-0
                bread-crumbs( :paramCOD="paramCOD")
              v-col.col-filters.col-filters-big-center.py-0
                div.filters
                  span.downloadExcel(v-if="showDownloadInHome" @click="downloadExcel()")
                    img(:src="imgCommons.download" width="10")
                  div.filter.filtroDate
                    range-date
          router-view.bg-gray

</template>

<script>
import RangeDate from '@/components/filters/RangeDates.vue'
import BreadCrumbs from '@/components/common/Breadcrumbs.vue'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import SelectCountryAndLogout from "@/components/common/SelectCountryAndLogout.vue"
export default {
  props: ['paramCOD'],
  mixins: [commonMixins],
  data(){
    return{
    }
  },
  components: { RangeDate, BreadCrumbs, SelectCountryAndLogout },
  created(){
    this.$store.commit(types.UPDATE_SECTION,21)
  }
}
</script>

<style lang="scss" scoped>
#satisfactionhome{
  background-color: #F2F2F2;
  height: 100%;
  border-top-left-radius: 30px;
}
</style>
