<template>
<div class="localPost text-14">
    <div class="image">
        <div class="type d-flex">
            <span class="flex-1">{{ typeEs }}</span>
            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="19.344" height="19.739" viewBox="0 0 19.344 19.739">
                <g id="Google__G__Logo" transform="translate(1184.577 765.171)">
                    <path id="Trazado_5014" data-name="Trazado 5014" d="M-1089.33-687.239v3.822h5.312a4.551,4.551,0,0,1-1.983,2.97l3.2,2.485a9.642,9.642,0,0,0,2.943-7.258,11.334,11.334,0,0,0-.18-2.019Z" transform="translate(-85.378 -69.857)" fill="#4285f4"/>
                    <path id="Trazado_5015" data-name="Trazado 5015" d="M-1171.081-651.791l-.722.553-2.557,1.992h0a9.868,9.868,0,0,0,8.81,5.446,9.42,9.42,0,0,0,6.532-2.387l-3.2-2.485a5.879,5.879,0,0,1-3.329.951,5.892,5.892,0,0,1-5.527-4.064Z" transform="translate(-9.158 -101.632)" fill="#34a853"/>
                    <path id="Trazado_5016" data-name="Trazado 5016" d="M-1183.518-712.61a9.748,9.748,0,0,0-1.059,4.423,9.748,9.748,0,0,0,1.059,4.423c0,.009,3.284-2.548,3.284-2.548a5.917,5.917,0,0,1-.314-1.875,5.917,5.917,0,0,1,.314-1.875Z" transform="translate(0 -47.115)" fill="#fbbc05"/>
                    <path id="Trazado_5017" data-name="Trazado 5017" d="M-1165.551-761.241a5.385,5.385,0,0,1,3.777,1.471l2.826-2.826a9.469,9.469,0,0,0-6.6-2.575,9.852,9.852,0,0,0-8.811,5.446l3.284,2.548A5.892,5.892,0,0,1-1165.551-761.241Z" transform="translate(-9.157)" fill="#ea4335"/>
                </g>
            </svg>
            <img class="mr-4" v-if="isDemoFacebook && data.facebook_resource_id" src="@/assets/img/local/FCB.svg" width="25" />
            <img v-if="isDemoFacebook && data.instagram_resource_id" src="@/assets/img/local/INS.svg" width="25" />
            <svg v-if="data.state ==='LIVE'" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path id="Trazado_5020" data-name="Trazado 5020" d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm-1-6,7.071-7.071L16.66,7.515,11,13.172,8.174,10.343,6.76,11.757Z" transform="translate(-2 -2)" fill="#7ed321"/>
            </svg>
            <svg v-else-if="data.state==='PROCESSING'" xmlns="http://www.w3.org/2000/svg" width="18.948" height="16.65" viewBox="0 0 18.948 16.65">
            <path id="Trazado_5026" data-name="Trazado 5026" d="M11.726,2.95,20.3,17.8a.9.9,0,0,1-.779,1.35H2.373a.9.9,0,0,1-.779-1.35L10.167,2.95a.9.9,0,0,1,1.559,0Zm-1.68,11.7v1.8h1.8v-1.8Zm0-6.3v4.5h1.8V8.35Z" transform="translate(-1.473 -2.5)" fill="#f70"/>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g id="Grupo_11141" data-name="Grupo 11141" transform="translate(-1316.74 -221)">
                    <circle id="Elipse_615" data-name="Elipse 615" cx="10" cy="10" r="10" transform="translate(1316.74 221)" fill="#f93549"/>
                    <g id="Grupo_11142" data-name="Grupo 11142" transform="translate(1323.257 227.677)">
                    <line id="Línea_1068" data-name="Línea 1068" x2="6" y2="6" transform="translate(0.483 0.322)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
                    <line id="Línea_1069" data-name="Línea 1069" x1="6" y2="6" transform="translate(0.483 0.322)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
                    </g>
                </g>
            </svg>


        </div>
        <img :src="data.media ? data.media[0].googleUrl : noPhoto" /> 

    </div>
    <div class="infoPost">
        <div class="d-flex items-end">
            <strong class="flex-1">
                <span v-if="data.topicType === 'OFFER' || data.topicType === 'EVENT'">
                {{ $moment(data.event.schedule.startDate.year + '-' + data.event.schedule.startDate.month + '-' + data.event.schedule.startDate.day).format('DD/MM/YYYY') }} -
                {{ $moment(data.event.schedule.endDate.year + '-' + data.event.schedule.endDate.month + '-' + data.event.schedule.endDate.day).format('DD/MM/YYYY') }}
                </span>
            </strong>
            <button class="mr-2" @click="editPost()">
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
                    <g id="Grupo_9689" data-name="Grupo 9689" transform="translate(0)">
                        <g id="Grupo_8463" data-name="Grupo 8463" transform="translate(0 0)">
                        <rect id="Rectángulo_2239" data-name="Rectángulo 2239" width="31" height="31" rx="4" transform="translate(0)" fill="#f2f2f2"/>
                        </g>
                        <g id="edit-3_2_" data-name="edit-3 (2)" transform="translate(7.154 8.609)">
                        <path id="Trazado_4643" data-name="Trazado 4643" d="M12,20h9.164" transform="translate(-4.872 -4.503)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Trazado_4644" data-name="Trazado 4644" d="M15.141,3.441a1.9,1.9,0,0,1,2.7,0,1.929,1.929,0,0,1,0,2.715L6.6,17.47,3,18.376l.9-3.62Z" transform="translate(-3 -2.879)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                    </g>
                </svg>
            </button>
            <button @click="showConfirmDelete = true">
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
                    <g id="Grupo_11099" data-name="Grupo 11099" transform="translate(0 0.115)">
                        <rect id="Rectángulo_2872" data-name="Rectángulo 2872" width="31" height="31" rx="4" transform="translate(0 -0.115)" fill="#f2f2f2"/>
                        <g id="trash-2_2_" data-name="trash-2 (2)" transform="translate(8.812 7.22)">
                        <path id="Trazado_4678" data-name="Trazado 4678" d="M3,6H17.187" transform="translate(-3 -2.847)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Trazado_4679" data-name="Trazado 4679" d="M16.034,5.153V16.187a1.576,1.576,0,0,1-1.576,1.576H6.576A1.576,1.576,0,0,1,5,16.187V5.153m2.365,0V3.576A1.576,1.576,0,0,1,8.941,2h3.153A1.576,1.576,0,0,1,13.67,3.576V5.153" transform="translate(-3.424 -2)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <line id="Línea_948" data-name="Línea 948" y2="5.133" transform="translate(5.634 7.108)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <line id="Línea_949" data-name="Línea 949" y2="5.133" transform="translate(8.714 7.108)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                    </g>
                </svg>

            </button>
        </div>
        <span v-if="data.topicType === 'OFFER' || data.topicType === 'EVENT'">{{ data.eventName }}</span>
        <p>{{ data.summary }}</p>
    </div>
    <removePost :visible="showConfirmDelete" @close="showConfirmDelete = $event" @removeAccepted="deletePost">
        <p class="text-center">¿Estas seguro que desea borrar la publicación?</p>
    </removePost>
    <addEvent @saved="editedPost" :post="data" :idBussines="idBussines" :visible="showAddEvent" @close="showAddEvent = $event"></addEvent>
    <addQuotation :post="data" :idBussines="idBussines" :visible="showAddQuotation" @close="showAddQuotation = $event"></addQuotation>
    <addNew :post="data" :idBussines="idBussines" :visible="showAddNew" @close="showAddNew = $event"></addNew>
</div>
</template>

<script>
import commonlMixins from '@/mixins/common.js'
import localMixins from '@/mixins/local.js'
import SrvLocalPosts from '@/services/localPosts.js'
import removePost from '@/components/local/posts/removePost.vue'
import addEvent from '@/components/local/posts/addEvent.vue'
  import addQuotation from '@/components/local/posts/addQuotation.vue'
  import addNew from '@/components/local/posts/addNew.vue'
  
export default{
    props: ['data', 'idBussines'],
    mixins: [localMixins, commonlMixins],
    components: {
        removePost,
        addEvent,
        addQuotation,
        addNew},
    data(){
        return {
            status: 'ok',
            showAddEvent: false,
            showAddQuotation: false,
            showAddNew: false, 
            showConfirmDelete: false,
            noPhoto: require('@/assets/img/no_plato.png')
        }
    },
    computed: {
        typeEs(){
            if (this.data.topicType === 'STANDARD') {
                return 'PUBLICACIÓN'
            } else if (this.data.topicType === 'OFFER') {
                return 'OFERTA'
            } else {
                return 'EVENTO'
            }
        }
    },
    methods: {
        editedPost(data){
            this.$emit('saved', data)
        },
        async deletePost(){
            const res = await SrvLocalPosts.deletePost(this.idBussines, this.data.name)
            if (res.status === 200) {
                this.$emit('deletePost', this.data.name)
            }
        },
        editPost(){
            if (this.data.topicType === 'STANDARD') {
                this.showAddNew = true
            }
            if (this.data.topicType === 'OFFER') {
                this.showAddQuotation = true
            }
            if (this.data.topicType === 'EVENT') {
                this.showAddEvent = true
            }
        }
    }
}
</script>
<style lang="scss" scoped>

.localPost{
    border-radius: 4px;
    border:1px solid #CECECE;
    .image{
        position: relative;
        .type{
            position: absolute;
            border-radius: 20px;
            background-color: #fff;
            width: calc(100% - 30px);
            margin-left: 15px;
            top: 14px;
            padding: 10px 15px;
            display: flex;
            align-items: center;
        }
    }
    .infoPost{
        padding: 10px 18px;
    }
}

</style>