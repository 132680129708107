
// Importamos mutaciones
import * as types from '@/store/mutation-types'

const AppReviews = {
  state: {
    opinionSitesAppSelected: [],
    pending: false,
    reviews: []
  },
  mutations: {
    [types.APPS_FILTER_OPINIONS_SITES_SELECTED] (state, data) {
      state.opinionSitesAppSelected = data
    },
    [types.APPS_UPDATE] (state, data) {
      state.pending = data
    },
    [types.APPS_OPINIONS] (state, data) {
      state.reviews = data
    }
  },
  getters: {
    'getOpinionSitesAppSelected': state => {
      return state.opinionSitesAppSelected
    },
  }
}

export default AppReviews
