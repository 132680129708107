import axios from "axios";
import store from "@/store/index";
import appConfig from "@/config-app.js";
import SrvCommon from "@/services/common";

axios.defaults.baseURL = appConfig.api;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {
  getOpinions(order) {
    let query = "/reservations/";

    query = SrvCommon.getBasicParamsQuery(query);

    let token = SrvCommon.getToken();
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    if (store.getters.getBookingOpinionsTypeSelected.length > 0) {
      for (let type in store.getters.getBookingOpinionsTypeSelected) {
        query += "&" + store.getters.getBookingOpinionsTypeSelected[type].value + "=true";
      }
    }

    if (store.getters.getBookingCategoriesSelected.length > 0) {
      for (let cat in store.getters.getBookingCategoriesSelected) {
        query += `&categories=${store.getters.getBookingCategoriesSelected[cat].value}`;
      }
    }

    if (store.getters.getBookinClientFilterSelected.length > 0) {
      for (let type in store.getters.getBookinClientFilterSelected) {
        query += `&travel_type=${store.getters.getBookinClientFilterSelected[type].value}`;
      }
    }

    if (store.getters.getBookingSearch) {
      query += `&search=${store.getters.getBookingSearch}`;
    }

    if (store.getters.getBookingCategoriesSelected.length > 0) {
      for (let category in store.getters.getBookingCategoriesSelected) {
        query += `&categories=${store.getters.getBookingCategoriesSelected[category].value}`;
      }
    }

    if (order) query += "&ordering=" + order;

    return axios.get(query);
  },
  getNextOpinions(url) {
    let token = SrvCommon.getToken();
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(url);
  },
  getClientTypes() {
    let query = "/reservations/travel-type";

    let token = SrvCommon.getToken();
    axios.defaults.headers.common["Authorization"] = "Token " + token;

    return axios.get(query);
  },
  downloadXlsFile(query_params) {
    return new Promise((resolve, reject) => {
      let query = `/reservations/`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;

      query += `?tree=${store.getters.getTreeSelected.id}`;
      if (store.getters.getNodeLevel === 9) {
        if (store.getters.getNode) {
          query += `&center=${store.getters.getNode}`;
        }
      } else {
        if (store.getters.getNode) {
          query += `&node=${store.getters.getNode}`;
        }
      }

      query += `&before=${query_params.endDate}&after=${query_params.startDate}&format=xlsx`;

      let configHeaders = {};
      configHeaders = SrvCommon.getConfigHeadersExcel();

      axios
        .get(query, configHeaders)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
};
