
// Importamos mutaciones
import * as types from '@/store/mutation-types'

// importamos servicios
import SrvSatisfaction from '@/services/satisfaction'


const satisfaction = {
  state: {
    'satisfaction': null,
    commentTypesSelected: [],
    commentLanguageSelected: [],
    claimOriginSelected: [],
    claimCharacterSelected: [],
    reasonTypesSelected: [],
    consumptionTypesSelected: [],
    surveyTypesSelected: [],
    pending: false,
    indicators:[],
    comments:[],
    claims:[],
    ranking:[],
  },
  actions: {
    getSatisfactionComments ({commit}) {
      SrvSatisfaction.getSatisfactionComments()
        .then(res => {
          if (res.status === 200) {
            commit(types.SATISFACTION_UPDATE, res.data)
          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })

      return null
    },
  },
  mutations: {
    [types.SATISFACTION_FILTER_COMMENT_TYPES_SELECTED] (state, data) {
      state.commentTypesSelected = data
    },
    [types.SATISFACTION_FILTER_COMMENT_LANGUAGES_SELECTED] (state, data) {
      state.commentLanguageSelected = data
    },
    [types.SATISFACTION_FILTER_REASON_TYPES_SELECTED] (state, data) {
      state.reasonTypesSelected = data
    },
    [types.SATISFACTION_FILTER_CONSUMPTION_TYPES_SELECTED] (state, data) {
      state.consumptionTypesSelected = data
    },
    [types.SATISFACTION_FILTER_SURVEY_TYPES_SELECTED] (state, data) {
      state.surveyTypesSelected = data
    },
    [types.SATISFACTION_FILTER_ORIGIN_TYPES_SELECTED] (state, data) {
      state.claimOriginSelected = data
    },
    [types.SATISFACTION_FILTER_CHARACTER_TYPES_SELECTED] (state, data) {
      state.claimCharacterSelected = data
    },
    [types.SATISFACTION_UPDATE] (state, data) {
      state.satisfaction = data
    },
    [types.SATISFACTION_INDICATORS] (state, data) {
      state.indicators = data
    },
    [types.SATISFACTION_COMMENTS] (state, data) {
      state.comments = data
    },
    [types.SATISFACTION_CLAIMS] (state, data) {
      state.claims = data
    },
    [types.SATISFACTION_RANKING] (state, data) {
      state.ranking = data
    },
  },
  getters: {
    'getSatisfaction': state => {
      return state.satisfaction
    },
    'getCommentTypesSelected': state => {
      return state.commentTypesSelected
    },
    'getCommentLanguageSelected': state => {
      return state.commentLanguageSelected
    },
    'getClaimOriginTypesSelected': state => {
      return state.claimOriginSelected
    },
    'getClaimCharacterTypesSelected': state => {
      return state.claimCharacterSelected
    },
    'getReasonTypesSelected': state => {
      return state.reasonTypesSelected
    },
    'getConsumptionTypesSelected': state => {
      return state.consumptionTypesSelected
    },
    'getSurveyTypesSelected': state => {
      return state.surveyTypesSelected
    },
    'getIndicators': state => {
      return state.indicators
    },  
    'getComments': state => {
      return state.comments
    },
    'getClaims': state => {
      return state.claims
    },
    'getRanking': state => {
      return state.ranking
    },   
  }
}

export default satisfaction