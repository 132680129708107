<template lang="pug">
v-row.px-2.ma-0
  div.margin-bottom-0.col-12.mb-3
    skeleton-card(v-if="loading")
    v-card#tableRankings.ma-0(v-if="!loading")
      .card-content
        .row.text-left.mx-0.mt-0
          #tablePerfilesRestaurantes.tableGlobal
            div.headerTableRanking.row.ma-0
              v-col.headerItem.ml-2.pa-0.text-left.brand(sm="3")
                v-row
                  v-col.justify-start(sm="7")
                    translate Brand
                  v-col.justify-start(sm="5")
                    translate(v-if="!isAdverbia") No. Restaurants
                    span(v-else) Nº Negocios
              v-col.headerItem.colSiteLocalMark.colGoogle
                v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
                  template(v-slot:activator="{ on, attrs }")
                    img(:src="img.googleMaps" v-bind="attrs" v-on="on")
                  span Google Maps
              v-col.headerItem.colSiteLocalMark(:class="{'isGroup': typeNode === 'group'}")

                v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
                  template(v-slot:activator="{ on, attrs }")
                    img(:src="img.facebook" v-bind="attrs" v-on="on")
                  span Facebook
              v-col.headerItem.colSiteLocalMark(v-for="(site, keySite) in siteLocalMark"  :class="{'notExtended': !localMarketingExtended}")
                v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
                  template(v-slot:activator="{ on, attrs }")
                    img(:src="getImageSiteLocalMark(keySite)" v-bind="attrs" v-on="on")
                  span {{ site }}


            div.contentTableRanking.row.ma-0(v-if="!isInstanceDashboard && dataProfile.node_level_0")
              p.ml-3(v-if="!dataProfile.node_level_0") #[translate No data]
              div.itemContent.col-12.pa-0
                v-col.ml-2.pa-0.text-left(sm="3")
                  v-row
                    v-col.justify-start(sm="7")
                      translate {{dataProfile.node_level_0.company}}
                    v-col.justify-start(sm="5")
                      div.flex-basis-100
                        translate {{dataProfile.node_level_0.total_rest}}
                      div
                        .chartMiniPie.mr-2(:style="getColorMiniChartPie(dataProfile.node_level_0.percent_profile_completed)")
                      div.flex-basis-100
                        span.percentNumber {{dataProfile.node_level_0.percent_profile_completed | formatNumber(0,0)}}%
                v-col.colSiteLocalMark.colGoogle
                  div.flex-basis-100
                    span(:style="{'color': getColorByPercent(dataProfile.node_level_0.total_profiles_google_percent) }") {{dataProfile.node_level_0.total_profiles_google }}
                  div
                    .chartMiniPie(:style="getColorMiniChartPie(dataProfile.node_level_0.percent_google_profile_completed)")
                  div.flex-basis-100
                    span.percentNumber {{dataProfile.node_level_0.percent_google_profile_completed | formatNumber(0,0)}}%
                v-col.colSiteLocalMark(:class="{'isGroup': typeNode === 'group'}")
                  div.flex-basis-100
                    span(:style="{'color': getColorByPercent(dataProfile.node_level_0.total_profiles_facebook_percent) }") {{dataProfile.node_level_0.total_profiles_facebook }}
                  div
                    .chartMiniPie(:style="getColorMiniChartPie(dataProfile.node_level_0.percent_facebook_profile_completed)")
                  div.flex-basis-100
                    span.percentNumber {{dataProfile.node_level_0.percent_facebook_profile_completed | formatNumber(0,0)}}%
                v-col.colSiteLocalMark(v-for="(site,keySite) in siteLocalMark" :class="{'notExtended': !localMarketingExtended}")
                  div.flex-basis-100
                    span(:style="{'color': getColorByPercent(getPercentTotalSites(dataProfile.node_level_0,keySite)) }") {{getTotalSites(dataProfile.node_level_0,keySite)}}
                  div
                    .chartMiniPie(:style="getColorMiniChartPie(getPercentAccounts(dataProfile.node_level_0,keySite))")
                  div.flex-basis-100
                    span.percentNumber {{getPercentAccounts(dataProfile.node_level_0,keySite) | formatNumber(0,0)}}%

            div.contentTableRanking.row.ma-0
              p.ml-3(v-if="!dataProfile.node_level_1") #[translate No data]

              div.itemContent.col-12.pa-0(v-for="(item, index) in dataProfile.node_level_1" :key="index")
                v-col.ml-2.pa-0.text-left(sm="3")
                  v-row
                    v-col.justify-start(sm="7")
                      span.link-text(@click="goItemNextLevel(item)") {{item.brand}}
                    v-col.justify-start(sm="5")
                      div.flex-basis-100
                        translate {{item.total_profiles }}
                      div
                        .chartMiniPie.mr-2(:style="getColorMiniChartPie(item.percent_profile_completed)")
                        span.percentNumber.ml-2 {{item.percent_profile_completed | formatNumber(0,0)}}%
                v-col.colSiteLocalMark.colGoogle
                  div.flex-basis-100
                    span(:style="{'color': getColorByPercent(item.total_profiles_google_percent) }") {{item.total_profiles_google }}
                  div
                    .chartMiniPie(:style="getColorMiniChartPie(item.percent_google_profile_completed)")
                  div.flex-basis-100
                    span.percentNumber {{item.percent_google_profile_completed | formatNumber(0,0)}}%
                v-col.colSiteLocalMark(:class="{'isGroup': typeNode === 'group'}")
                  div.flex-basis-100
                    span(:style="{'color': getColorByPercent(item.total_profiles_facebook_percent) }") {{item.total_profiles_facebook }}
                  div
                    .chartMiniPie(:style="getColorMiniChartPie(item.percent_facebook_profile_completed)")
                  div.flex-basis-100
                    span.percentNumber {{item.percent_facebook_profile_completed | formatNumber(0,0)}}%
                v-col.colSiteLocalMark(v-for="(site, keySite) in siteLocalMark"  :class="{'notExtended': !localMarketingExtended}")
                  div.flex-basis-100
                    span(:style="{'color': getColorByPercent(getPercentTotalSites(item,keySite)) }") {{getTotalSites(item,keySite)}}
                  div
                    .chartMiniPie(:style="getColorMiniChartPie(getPercentAccounts(item,keySite))")
                  div.flex-basis-100
                    span.percentNumber {{getPercentAccounts(item,keySite) | formatNumber(0,0)}}%
</template>

<script>
import SkeletonCard from "@/components/common/skeletonCard.vue";
import commonMixins from "@/mixins/common.js";
import localMixins from "@/mixins/local.js";
import brandMixin from "@/mixins/brand.js";
export default {
  components: { SkeletonCard },
  mixins: [commonMixins, localMixins, brandMixin],
  props: ["siteLocalMark", "dataProfile", "loading"],
};
</script>
