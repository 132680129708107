<template lang="pug">
v-app(v-if="loadOK" dark :class="classSelectorLevelOfMenu")
  bk-modal-limit-exceded-excel
  bk-modal-downloading-excel
  div#filaInicial(v-if="!['WhatsAppPhotos','WhatsAppResponse','appwelcome','googletoken','facebooktoken','validatetokengoogle','FacebookConfig','404','home','Restore','RestoreSet'].includes($route.name)")
    div#navbar-limit
      bk-navbar
    div#boxSubnav(:class="{'anchoSubnavCerrado': subNavClosed, 'noTree': $route.meta.isGP}")
      bk-subnav(:nodo="codeParam")
    div.bg-gray#escritorio(:class="{'noTree': $route.meta.isGP, 'bg-gray': $route.name !== 'home'  && $route.name !== 'Restore', 'anchoEscritorioSubnavCerrado': subNavClosed }")
      div.gr-desktop-loader--wrapper(v-if="isRouteLoading")
        div.gr-desktop-loader
          div.gr-loader-snipper--wrapper
            span.gr-loader-snipper.red-loader
      router-view(v-else)
  div(v-else-if="['WhatsAppPhotos','appwelcome','WhatsAppResponse'].includes($route.name)")
    router-view
  div#login(v-else)
    router-view
</template>

<script>
import { mapGetters } from 'vuex'
import * as types from '@/store/mutation-types'
import BkSubnav from '@/components/common/Subnav.vue'
import BkNavbar from '@/components/common/Navbar.vue'
import BkModalLimitExcededExcel from '@/components/modals/LimitExcededExcel.vue'
import BkModalDownloadingExcel from '@/components/modals/DownloadingExcel.vue'
import commonMixins from "@/mixins/common.js";
import appConfig from '@/config-app.js'

export default {
  name: 'app',
  mixins: [commonMixins],
  data() {
    return {
      userAccount: '',
      loadOK: false
    }
  },
  components: { BkNavbar, BkSubnav, BkModalLimitExcededExcel, BkModalDownloadingExcel },
  mounted() {
    document.querySelector('body').setAttribute('id', appConfig.brand())
    document.title = appConfig.brand().toUpperCase()
    let headTitle = document.querySelector('head');
    let setFavicon = document.createElement('link');
    setFavicon.setAttribute('rel', 'shortcut icon');
    if (appConfig.brand() === 'adverbia') {
      setFavicon.setAttribute('href', '/adverbia.png');
    } else {
      setFavicon.setAttribute('href', '/gastroranking.png');
    }
    headTitle.appendChild(setFavicon);
    function loadscript(src, id, callback) {
      const script = document.createElement('script')
      script.src = src
      id && (script.id = id)
      script.async = true
      callback && (script.onload = callback)
      document.body.appendChild(script)
    }
    if (window.location.hostname === 'dashboard.gastroranking.pro') {
      const jqReady = function () {

        //loadscript('https://helpdesk.gastroranking.pro/assets/form/form.js', 'zammad_form_script', null)

        loadscript('https://helpdesk.gastroranking.pro/assets/chat/chat.min.js', null, function () {

          setTimeout(() => {
            // eslint-disable-next-line no-undef
            //$('#feedback-form').ZammadForm({messageTitle: 'Feedback Form', messageSubmit: 'Submit', messageThankYou: "Thank you for your inquiry (#%s)! We'll contact you as soon as possible.", modal: true});
            // eslint-disable-next-line no-undef
            $(function () { new ZammadChat({ background: '#F93549', fontSize: '12px', chatId: 1, show: false }); });
          }, 2000)
        })
      }
      loadscript('https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js', null, jqReady)
      let hotJarScript = document.createElement('script')
      hotJarScript.text = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3493826,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
      document.head.appendChild(hotJarScript)

    }

  },
  created() {
    this.$store.commit(types.COMMON_DATE_AFTER, this.$moment().add({ 'months': -1 }).format('YYYY-MM-DD'));
    this.$store.commit(types.COMMON_DATE_BEFORE, this.$moment().format('YYYY-MM-DD'));
    // Var for control call all components after response user account
    this.loadOK = true
  },
  watch: {
    $route() {
      if (this.$route.name != 'FeedBackOpinions' && this.$route.name != 'FeedBackKeywords') {
        if (this.$store.getters.getInternetAutomatic) {
          this.$store.commit(types.COMMON_TYPES_SELECTED_INTERNET_AUTOMATIC, false)
          window.location = window.location.href.replace('type=internet', '')
        }
      }
    }
  },
  computed: {
    ...mapGetters(['isRouteLoading']),
    classSelectorLevelOfMenu() {
      return this.$route.path.split('/').length > 4 ? 'twoLevelOfMenu' : 'oneLevelOfMenu'
    },
    subNavClosed: function () {
      return !this.$store.getters.getOpen
    },
    codeParam() {
      return this.$route.params.paramCOD !== undefined ? this.$route.params.paramCOD : ''
    },
    filterTypeRestaurant() {
      return [this.$store.getters.getDateAfter, this.$store.getters.getDateBefore, this.$store.getters.getNode, this.$store.getters.getTypesSelected, this.$store.getters.getSitesSelected].join()
    }
  },
}
</script>

<style lang="scss">
@import "./assets/sass/style.scss";

#app {
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #222226;

  #filaInicial {
    flex-wrap: nowrap;

    #escritorio {
      width: 81.115%;
      float: left;
      height: 100%;
      min-height: 100vh;

      &.anchoEscritorioSubnavCerrado {
        width: 93.12%;
      }

      &.noTree {
        width: 96.115%;
        margin-left: 3.88%;
      }
    }

    #boxSubnav {

      margin-left: 3.88%;
      display: block;
      float: left;
      width: 15%;
      height: 100%;

      &.noTree {
        width: 0;
        display: none;
      }

      &.anchoSubnavCerrado {
        width: 3%;

        &.noTree {
          width: 0;
        }
      }
    }
  }
}

#navbar-limit {
  position: fixed;
  height: 100vh;
  width: 3.88%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
