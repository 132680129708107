<template lang="pug">
div.bar-container(:style="`height: ${height}px`")
    div.progress(:style="`max-width:${width}%;`")
        span.innerText.font-weight-bold(v-if="innerText") {{ innerText  }}
</template>

<script>
export default {
    props: {
        width: {
            default: 0
        },
        height: {
            default: 8
        },
        innerText: {
            type: String,
            required: false
        }
    },
}
</script>


<style lang="scss" scoped>
.bar-container {
    background: #F1F3F4;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;

    .progress {
        position: "relative";
        background: #FBBC04;
        width: 0%;
        height: inherit;
        animation: 1.5s ease-in forwards width-animation;
        text-align: end;

        .innerText {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            align-content: center;
            padding: 0 5px;
        }

        @keyframes width-animation {
            0% {
                width: 0px;
            }

            100% {
                width: 100%;
            }

        }
    }

}
</style>