<template lang="pug">
div
    div.margin-nav(v-show="show" md="2")
        v-container#sub-nav(v-show="$store.getters.getShowTree")
            v-row.fixed-sidebar.mx-0(ref="prueb")
                v-col.text-left.pa-0.pt-0
                    div#tituloTree
                        span(:class="{'tDelivery': $store.getters.getIdSection == 8}") {{ secctionName }}
                        v-btn.btnIco(@click="toggleSubNav()")
                            img(:src="img.return" width="16")
                    #arbol
                        ul.treeRoot(v-if="itemsTreeF.length > 0 && itemsTreeF[0].children" )
                            tree-menu(:label="itemsTreeF[0].name" :nodo="nodo" :item="itemsTreeF[0]" :nodes="initialNodes" ref="refarbol" :id="itemsTreeF[0].id" :slug="slugInit" :depth="0" :onelevel="false" :firstElement="isMultiTree" :clickInit="true")
                        ul.treeRoot(v-else)
                            tree-menu(v-if="itemsTreeF.length > 0 && itemsTreeF[0].name" :label="itemsTreeF[0].name" :nodo="nodo" :item="itemsTreeF[0]" :nodes="itemsTreeF" ref="refarbol" :id="itemsTreeF[0].id" :slug="itemsTreeF[0].id" :depth="0" :onelevel="true")

        v-container#sub-nav(v-show="!$store.getters.getShowTree")
            v-row.fixed-sidebar.mx-0(ref="prueb")
                v-col.text-left.pa-0.pt-0
                    div#tituloTree
                        span {{ secctionName }}
                        v-btn.btnIco(@click="toggleSubNav()")
                            img(:src="img.return" width="16")
                    #arbol
                        ul.treeRoot.noTree
                            div.boxLabel.onelevel
                                div.labelItemTree
                                    span.itemTreeInfo
                                        span.labelText {{ nameCenter }}

    v-container.margin-nav#treeHide(v-show="!show")
        v-row
            v-col#treeHide.text-left
                div#tituloTree
                    v-btn.btnIco.return(@click="toggleSubNav()")
                        img(:src="img.return" width="16")
</template>
<script>
import * as types from '@/store/mutation-types'
// import SrvCommon from '@/services/common.js';
import TreeMenu from './TreeMenu.vue'

export default {
    props: ['nodo'],
    components: { TreeMenu },
    data() {
        return {
            // itemsTree: [], // ELEMENTOS DEL ARBOL
            openIds: [], // ELEMENTOS ABIERTOS
            item: '', // ELEMENTO ACTUAL
            img: { // IMAGENES UTILIZADAS
                return: require('@/assets/img/nav/nav-return.svg'),
            },
            //show: true,
        }
    },
    created() {
        this.show = this.$store.getters.getOpen
    },
    methods: {
        onOpen: function (items) {
            this.openIds = items;
            this.$store.commit(types.TREE_OPEN_IDS, items);
        },
        // GUARDAMOS EL ESTADO DEL SUBNAV PARA APLICAR MÁS O MENOS ANCHO EN LA CAJA #ESCRITORIO
        toggleSubNav: function () {
            if (this.show) {
                this.show = false;
            } else {
                this.show = true;
            }
        },
        // ABRIMOS LISTA DE CENTROS
        toggleCenter: function (item) {
            if (item.show_centers) {
                item.show_centers = false;
            } else {
                item.show_centers = true;
            }
        },
    },
    watch: {
        showLevels: function () {
            this.openIds = this.$store.getters.getOpenIds
        }
    },
    computed: {
        show: {
            get: function () {
                return this.$store.getters.getOpen
            },
            set: function (newValue) {
                this.$store.commit(types.OPEN_SUBNAV, newValue)
            }
        },
        nameCenter() {
            return this.$store.getters.getCenterNoTree
        },
        isMultiTree: function () {
            if (this.itemsTreeF.length > 1) {
                return true
            } else {
                return false
            }
        },
        slugInit: function () {
            if (this.isMultiTree) {
                return ''
            } else {
                return this.itemsTreeF[0].id
            }
        },
        initialNodes: function () {
            let nodes = []
            if (this.itemsTreeF.length > 1) {
                nodes = this.itemsTreeF
            } else {
                nodes = this.itemsTreeF[0].children.length > 0 ? this.itemsTreeF[0].children : this.itemsTreeF[0].centers
            }
            return nodes
        },
        showLevels: function () {
            return this.$store.getters.getOpenIds
        },
        itemsTreeF: function () {
            return this.$store.getters.getTreeData
        },
        // PINTAMOS EL NOMBRE DE LA SECCIÓN DESDE EL STORE
        secctionName: function () {
            let name = '';
            switch (this.$store.getters.getIdSection) {
                case 0:
                    name = this.$gettext('GLOBAL VIEW');
                    break;
                case 1:
                    name = this.$gettext('INTERNET');
                    break;
                case 2:
                    name = this.$gettext('FEEDBACK');
                    break;
                case 3:
                    name = this.$gettext('SATISFACTION');
                    break;
                case 4:
                    name = this.$gettext('REPORTS');
                    break;
                case 5:
                    name = this.$gettext('LOCAL');
                    break;
                case 6:
                    name = this.$gettext('ACCOUNT');
                    break;
                case 8:
                    name = this.$gettext('DELIVERY ANALYTICS');
                    break;
                case 21:
                    name = this.$gettext('CUSTOMER SUPPORT');
                    break;
                default:
                    name = '';
            }

            return name;
        },
        TreeId: function () {
            return this.$store.getters.getNode
        }

    }
}
</script>

<style lang="scss" scoped>
.margin-nav {
    height: 100%;
}

#sub-nav {
    padding: 12px 0;

    .fixed-sidebar {
        position: fixed;
        width: 15%;

        .tree-active {
            color: #F93549;
        }
    }

    #arbol {

        .treeRoot {
            &.noTree {
                .labelItemTree {
                    margin-left: 5%;

                    .labelText {
                        cursor: inherit;
                    }
                }

                .boxLabel.onelevel {
                    border-bottom: 0;
                }

                .boxLabel:hover {
                    background-color: transparent !important;

                }
            }
        }

    }
}

.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

#arbol {

    height: 91vh;
    overflow-y: auto;
    overflow-x: hidden;

    // scrollbar-color: orange lightyellow;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: #787d83;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:active {
        background-color: #787d83;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #2c3136;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-track {
        background: #e1e1e1;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track:hover {
        background: #E6EAF0;
    }

    &::-webkit-scrollbar-track:active {
        background: #E6EAF0;
    }

    .v-treeview-node__label {

        .level-2,
        .level-3 {
            a {
                font-size: 0.813rem;
            }
        }
    }

    a {
        color: #fff;
        text-decoration: none;
        font-size: 0.875rem;
    }

    .centros {
        background-color: transparent;
    }

    .dropdownCenter {
        float: right;
        background-color: transparent;
        padding: 0;
        margin: 0;
        box-shadow: none;
        text-align: center;
        height: auto;
        min-width: auto;
        color: red;
        transition: all ease 0.3s;

        &.open {
            transform: rotate(-90deg);
        }

        i {
            margin-right: 3px;
        }
    }
}
</style>
