import { render, staticRenderFns } from "./BarTrheeColors.vue?vue&type=template&id=5c3bab6c&scoped=true&lang=pug"
import script from "./BarTrheeColors.vue?vue&type=script&lang=js"
export * from "./BarTrheeColors.vue?vue&type=script&lang=js"
import style0 from "./BarTrheeColors.vue?vue&type=style&index=0&id=5c3bab6c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c3bab6c",
  null
  
)

export default component.exports