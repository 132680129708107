<template>
    <div class="filterRangeDistance text-12 overflow-hidden" :class="{ 'inMap': isViewInMap }">
        <div class="boxCheck">
            <input type="checkbox" :checked="$store.getters.getDistanceMapActive" @change="setActiveFilter" class="mr-2">
            <span class="text-no-wrap">Buscar competencia en:</span>
        </div>
        <div class="flex-1 boxRange" :class="{ 'opacity-50': !$store.getters.getDistanceMapActive }">
            <input class="ml-2" type="range" :disabled="!$store.getters.getDistanceMapActive" v-model="range" id="distancia"
                name="distancia" min="1" :max="this.$store.getters.getDistanceMapMax">
            <label for="distancia" class="mx-2 text-13"> {{ Number(range) | formatNumber(0, 0) }} m.</label>
            <button @click="applySearch" class="py-1 px-2"
                :class="{ 'opacity-50': !$store.getters.getDistanceMapActive }">Buscar</button>
        </div>

    </div>
</template>

<script>
import * as types from '@/store/mutation-types'

export default {
    props: {
        isViewInMap: {
            default: false,
        }
    },
    data() {
        return {
            range: null
        }
    },
    created() {
        this.range = this.$store.getters.getDistanceMap
    },
    computed: {
    },
    methods: {
        setActiveFilter(even) {
            this.$store.commit('COMMON_DISTANCE_MAP_ACTIVE', even.target.checked)
        },
        applySearch() {
            if (this.$store.getters.getDistanceMapActive) {
                this.$store.commit(types.COMMON_DISTANCE_MAP, this.range)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.filterRangeDistance {
    height: 36px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .12);
    border-radius: 4px;
    padding-right: 6px;
    padding-left: 17px;

    input {
        accent-color: #0075FF;
    }

    button {
        background-color: #0075FF;
        color: #fff;
    }

    .boxCheck {
        display: inline-flex;
        align-items: center;
    }

    .boxRange {
        display: inline-flex;
        align-items: center;
    }

    &.inMap {
        flex-direction: column;
        justify-content: start;
        text-align: left;
        height: auto;
        padding-bottom: 6px;
        padding-left: 6px;

        .boxCheck,
        .boxRange {
            width: 100%;
        }

        .boxRange {
            label {
                flex: 1;
            }

            input {
                width: 40%;
                margin-left: 0 !important;
            }
        }
    }
}</style>