<template lang="pug">
div
  // SATISFACCION GENERAL
  .gridFull(v-if="isLoading")
    v-skeleton-loader(type="table")
  div.gridFull(v-if="!isLoading")
    v-row.headTitleGrid 
      v-col.titleGrid.headSatisfaction
        span #[translate Satisfaction]
      v-col.titleGrid.headRepetition
        span #[translate Repetition]
      v-col.titleGrid.headRecomendation
        span #[translate Recommendation]

    v-row 
      v-col(md="12")
        v-row.headIndicators.text-left
          v-col.colName
          v-col.colPointer.text-center #[translate Quiz]
          v-col.colPointer.text-center #[translate Note]
          v-col.colPointer.text-center #[translate Hall]
          v-col.colPointer.text-center #[translate Delivery]
          v-col.colPointer.text-center #[translate Take away]
          v-col.colSeparatorLeft &nbsp;
          v-col.colPointer.text-center #[translate Note]
          v-col.colPointer.text-center #[translate Hall]
          v-col.colPointer.text-center #[translate Delivery]
          v-col.colPointer.text-center #[translate Take away]
          v-col.colSeparatorLeft &nbsp;
          v-col.colPointer.text-center #[translate Note]
          v-col.colPointer.text-center #[translate Hall]
          v-col.colPointer.text-center #[translate Delivery]
          v-col.colPointer.text-center #[translate Take away]
        v-row.bodyIndicators.text-left(v-for="(indicator, index) in indicators" :key="index" :class="{'border-bottom': indicators.length > 1}" )
          v-col.colName(@click="goItemNextLevel(indicator)" :class="{'link-text': index > 0}") {{ indicator.name }}
          v-col.colPointer.text-right {{ indicator.count | formatNumber }}
          v-col.colPointer(:class="getColorNote(indicator.rating_global)").text-center {{ indicator.rating_global | formatNumber(1,1) }}
          v-col.colPointer(v-if="indicator.rating_local" :class="getColorNote(indicator.rating_local)").text-center {{ indicator.rating_local | formatNumber(1,1) }}
          v-col.colPointer.text-center(v-else) -
          v-col.colPointer(v-if="indicator.rating_delivery" :class="getColorNote(indicator.rating_delivery)").text-center {{ indicator.rating_delivery | formatNumber(1,1) }}
          v-col.colPointer.text-center(v-else) -
          v-col.colPointer(v-if="indicator.rating_takeaway" :class="getColorNote(indicator.rating_takeaway)").text-center {{ indicator.rating_takeaway | formatNumber(1,1) }}
          v-col.colPointer.text-center(v-else) -
          // return
          v-col.colSeparatorLeft &nbsp;  
          v-col.colPointer(:class="getColorNote(indicator.rating_return)").text-center {{ indicator.rating_return | formatNumber(1,1) }}
          v-col.colPointer(v-if="indicator.rating_return_local" :class="getColorNote(indicator.rating_return_local)").text-center {{ indicator.rating_return_local | formatNumber(1,1) }}
          v-col.colPointer.text-center(v-else) -
          v-col.colPointer(v-if="indicator.rating_return_delivery" :class="getColorNote(indicator.rating_return_delivery)").text-center {{ indicator.rating_return_delivery | formatNumber(1,1) }}
          v-col.colPointer.text-center(v-else) -
          v-col.colPointer(v-if="indicator.rating_return_takeaway" :class="getColorNote(indicator.rating_return_takeaway)").text-center {{ indicator.rating_return_takeaway | formatNumber(1,1) }}
          v-col.colPointer.text-center(v-else) -
          // recomendation
          v-col.colSeparatorLeft &nbsp;
          v-col.colPointer(:class="getColorNote(indicator.rating_suggest)").text-center {{ indicator.rating_suggest | formatNumber(1,1) }}
          v-col.colPointer(v-if="indicator.rating_suggest_local" :class="getColorNote(indicator.rating_suggest_local)").text-center {{ indicator.rating_suggest_local | formatNumber(1,1) }}
          v-col.colPointer.text-center(v-else) -
          v-col.colPointer(v-if="indicator.rating_suggest_delivery" :class="getColorNote(indicator.rating_suggest_delivery)").text-center {{ indicator.rating_suggest_delivery | formatNumber(1,1) }}
          v-col.colPointer.text-center(v-else) -
          v-col.colPointer(v-if="indicator.rating_suggest_takeaway" :class="getColorNote(indicator.rating_suggest_takeaway)").text-center {{ indicator.rating_suggest_takeaway | formatNumber(1,1) }}
          v-col.colPointer.text-center(v-else) -

  div.gridFull(v-if="!isLoading")
    v-row.headTitleGrid 
      v-col(md="2").titleGrid
        span #[translate Evolution]
      v-col.optionsCharts(md="6")
        span.showChart.cursor-pointer(:class="{'active': chartShowed === 'global'}" @click="chartShowed = 'global'") #[translate Satisfaction]
        span.showChart.cursor-pointer(:class="{'active': chartShowed === 'repetition'}" @click="chartShowed = 'repetition'")  #[translate Repetition]
        span.showChart.cursor-pointer(:class="{'active': chartShowed === 'suggest'}" @click="chartShowed = 'suggest'")  #[translate Recommendation]
      v-col.iconInfo.text-right(md="4")
        .block-help.ml-auto
          v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="40" nudge-left="10" content-class="tooltip-help tooltip-help-left")
            template(v-slot:activator="{ on, attrs }")
              img(:src="imgCommons.help" v-bind="attrs" v-on="on")
            div {{ helpChart }}
    v-row.bodyIndicators(v-if="chartShowed === 'global'")
      v-col(md="12")
        chart-line(:chartData="chartdataGlobal")
    v-row.bodyIndicators(v-if="chartShowed === 'repetition'")
      v-col(md="12")
        chart-line(:chartData="chartdataReturn")
    v-row.bodyIndicators(v-if="chartShowed === 'suggest'")
      v-col(md="12")
        chart-line(:chartData="chartdataSuggest")
</template>

<script>
import commonMixins from "@/mixins/common.js";
import chartLine from '@/components/charts/line.vue'

export default {
  components: { chartLine },
  mixins: [commonMixins],
  props: ['indicators', 'chartdataGlobal', 'chartdataReturn', 'chartdataSuggest'],
  data() {
    return {
      helpSatisfaccion: this.$gettext('General information on the Company by number of questionnaires and satisfaction by types of consumption; Value taken from the sum of marks by the number of surveys'),
      helpRepeat: this.$gettext('General information on the Company by number of questionnaires with repetition by types of consumption and their evolution, Value taken from the sum of marks by the number of surveys'),
      helpRecommendation: this.$gettext('General information on the Company by number of questionnaires with recommendation by types of consumption and their evolution, Value taken from the sum of notes by the number of surveys'),
      helpSatisfaccionBrand: this.$gettext('General information on the Brand by number of questionnaires and satisfaction by types of consumption; Value taken from the sum of marks by the number of surveys'),
      helpRepeatBrand: this.$gettext('General information on the Brand by number of questionnaires with repetition by types of consumption and their evolution, Value taken from the sum of marks by the number of surveys'),
      helpRecommendationBrand: this.$gettext('General information on the Brand by number of questionnaires with recommendation by types of consumption and their evolution, Value taken from the sum of notes by the number of surveys'),
      helpSatisfaccionCenter: this.$gettext('General information about the center by number of questionnaires and satisfaction by types of consumption; Value taken from the sum of grades by the number of surveys.'),
      helpRepeatBrandCenter: this.$gettext('General information of the center by number of questionnaires with repetition by types of consumption and their evolution, Value taken from the sum of marks by the number of surveys'),
      helpRecommendationCenter: this.$gettext('General information of the center by number of questionnaires with recommendation by types of consumption and their evolution, Value taken from the sum of marks by the number of surveys'),
      chartShowed: 'global'
    }
  },
  computed: {
    helpChart: function () {
      let help = this.helpSatisfaccion
      if (this.chartShowed === 'repetition') { help = this.helpRepeat }
      if (this.chartShowed === 'suggest') { help = this.helpRecommendation }
      if (this.typeNode === 'brand') {
        if (this.chartShowed === 'global') { help = this.helpRepeatBrand }
        if (this.chartShowed === 'repetition') { help = this.helpRepeatBrand }
        if (this.chartShowed === 'suggest') { help = this.helpRecommendationBrand }
      }
      if (this.typeNode === 'center') {
        if (this.chartShowed === 'global') { help = this.helpRepeatCenter }
        if (this.chartShowed === 'repetition') { help = this.helpRepeatCenter }
        if (this.chartShowed === 'suggest') { help = this.helpRecommendationCenter }
      }
      return help
    }
  },
}
</script>
<style lang="scss" scoped>
.xsdasgrs {
  width: auto;
}

.optionsCharts {
  .showChart {
    background-color: #EFEFEF;
    border-radius: 5px;
    padding: 5px 10px;
    color: #0EB5E9;
    margin-right: 14px;
    transition: all ease 0.3s;
    min-width: 101px;
    font-size: 0.875rem;

    &.active,
    &:hover {
      background-color: #0EB5E9;
      color: #fff;
    }
  }
}

.titleChart {
  flex: 0 0 12.3333333333%;
  max-width: 12.3333333333%;
}

.colName {
  flex: 0 0 12.54545454%;
  max-width: 12.54545454%;
}

.colSeparatorLeft {
  flex: 0 0 6.7%;
  max-width: 6.7%;
}

.gridFull {
  .bodyIndicators {
    &.center {
      margin-top: 0;
    }

    .colPointer {
      text-align: right;
    }
  }
}

.colPointer {
  flex: 0 0 5.36%;
  max-width: 5.36%;
  padding-right: 0;
  padding-left: 0;
}

.headSatisfaction {
  flex: 0 0 39.468%;
  max-width: 39.468%;
  margin-right: 6.7%;
}

.headRepetition {
  flex: 0 0 21.468%;
  max-width: 21.468%;
  margin-right: 6.7%;
}

.headRecomendation {
  flex: 0 0 21.468%;
  max-width: 21.468%;
  margin-right: 0;
}

.colGlobalCenter {
  flex: 0 0 39.468%;
  max-width: 39.468%;
}

.colReturnCenter {
  flex: 0 0 21.468%;
  max-width: 21.468%;
}

.colSuggestCenter {
  flex: 0 0 21.468%;
  max-width: 21.468%;
}
</style>