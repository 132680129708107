<template>
<div>
  <div class="row px-2 ma-0 ma-4" :class="{'premiumDisabled': !hasPremium}">
    <div id="cartContainer">
        <Header :name="dataProfile.name"></Header>
        <!--<Menus></Menus>-->
        <!--<InfoMenuActive></InfoMenuActive>-->
        <div id="automaticSync" class="my-2">
          <button class="btnSyncExportGoogle mr-2" :class="{'opacity-50': dataMenu.length === 0}" @click="getMenusUpdateToGoogle()">
            <span> {{ $gettext('Subir menú a google') }} </span>
            <div class="gr-loader-snipper--wrapper" v-if="loadingSync">
              <span class="gr-loader-snipper"></span>
            </div>
          </button>
          <button class="btnSyncExportGoogle" @click="getMenusUpdateFromGoogle()">
            <div class="gr-loader-snipper--wrapper" v-if="loadingImportGoogle">
              <span class="gr-loader-snipper"></span>
            </div>
            <span>{{ $gettext('Recoger menú de google') }}</span>
          </button>
        </div>
        <div id="cartGrid">
          <ListSections id="cartSections" :items="dataMenu" @sectionCreatedOk="addNewSection"></ListSections>
          <Sections id="cartPlates" :sections="dataMenu" @productOk="addNeWProduct" @sectionDeleteOk="removeSection"></Sections>
        </div>
      </div>
  </div>
  <ModalGetMenuGoogleWarning :visible="show" @close="show=false"></ModalGetMenuGoogleWarning>
</div>    
        
            
</template>
<script>
  import SrvLocal from '@/services/local.js'
  import SrvLocalMenu from '@/services/localMenu.js'
  import commonMixins from "@/mixins/common.js"
  import localMixins from "@/mixins/local.js"
  import ModalGetMenuGoogleWarning from '@/components/local/menu/ModalGetMenuGoogleWarning.vue'
  //FICHAS EDICION
  import data from '@/components/local/menu/dataNotPremium.json'
  import Header from "@/components/local/menu/Header.vue"
  //import Menus from "@/components/local/menu/Menus.vue"
  //import InfoMenuActive from "@/components/local/menu/InfoMenuActive.vue"
  import ListSections from "@/components/local/menu/ListSections.vue"
  import Sections from "@/components/local/menu/Sections.vue"
  export default {
    components: {
        Header,
        /*Menus,
        InfoMenuActive,*/
        ListSections,
        Sections,
        ModalGetMenuGoogleWarning

    }, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
    mixins: [commonMixins, localMixins],
    data() {
      return {
        dataMenu: data,
        dataProfile: [],
        messageError: null,
        loadingSync: false,
        loadingImportGoogle: false,
        center: undefined,
        show: false,
      }
    },
    async created() {
      this.$store.commit('LOADING', true)
      this.loading = true
      this.center = Number(this.$store.getters.getNode)
      await this.getLocalMarketing()      
      if (this.hasPremium) {
        await this.getData()
      }
      this.loading = false
      this.$store.commit('LOADING', false)
    },    
      
    watch: {
      filterApply: function () {
        this.getLocalMarketing()
        if (this.hasPremium) {
          this.getData()
        }
      }
    },
    methods: {
      removeSection(id){
        if (this.hasPremium) {
          this.dataMenu = this.dataMenu.filter(e => e.id != id)
        }
      },
      addNewSection(data){
        if (this.hasPremium) {
          this.dataMenu.push(data)
        }
      },
      addNeWProduct(data) {
        if (this.hasPremium) {
          let sectionEditedProduct = this.dataMenu.filter(e => e.id === data.idsection)[0]
          if (data.status === 'removed') {
            sectionEditedProduct.items = sectionEditedProduct.items.filter(e => e.id !== data.product.id)
          } else if (data.status === 'edited') {
            sectionEditedProduct.items = sectionEditedProduct.items.map(e => {
              if (e.id === data.product.id) {
                e = data.product
              }
              return e
            })
          } else {
            sectionEditedProduct.items.push(data.product)
          }
        }
      },
      async getData(){
        if (this.hasPremium) {
          const res = await SrvLocalMenu.getMenus(this.center)
          if (res.status === 200) {
            this.dataMenu = res.data
          }
        }
      },
      async getMenusUpdateFromGoogle(){
        if (this.hasPremium) {
          this.loadingImportGoogle = true
          try {
            const res = await SrvLocalMenu.getMenusUpdateFromGoogle(this.center)
            if (res.status === 200) {
              this.getData()
            } else {
              this.show = true
            }
            this.loadingImportGoogle = false
          } catch (e) {
            this.show = true
            this.loadingImportGoogle = false
          }
        }
      },
      async getMenusUpdateToGoogle(){
        if (this.hasPremium && this.dataMenu.length > 0) {
          this.loadingSync = true
          await SrvLocalMenu.getMenusUpdateToGoogle(this.center)
          this.loadingSync = false
        }
      },
      sync(idBussines) {
        if (this.hasPremium) {
          if (!this.loadingSync) {
            this.loadingSync = true
            if (idBussines !== '' && typeof idBussines === 'number') {
              SrvLocal.syncBunsiness(idBussines)
                .then(res => {
                  if (res.status === 200) {
                    if (Object.keys(res.data).includes('success') && !res.data.success) {
                      this.messageError = res.data.error.message
                      this.showError = true
                    }
                    this.loadingSync = false
                  }
                }, () => {
                  this.loadingSync = false
                })
            }
          }
        }
      },
      
      getLocalMarketing() {
        this.$store.commit('LOADING', true)
        SrvLocal.getLocalMarketing()
          .then(res => {
            if (res.status === 200) {
              
              this.dataProfile = res.data
              this.facebookConfig = res.data.facebook_config
              this.$store.commit('LOADING', false)
            }
          }, () => {
          })
      }      
      
    }
  }
  </script>
<style>
body{
  background-color: #F2F2F2;
}
#cartContainer{
  width: 100%;
  margin: 0 auto;
}
#cartSections{
  width: 16.291%;
        background-color: #fff;
        padding: 16px 20px 16px 16px;
        border-radius: 8px;
        margin-top: 0;
  }
  #cartGrid{
        display: flex;
    }
    #cartPlates{
        width: 82.26%;
        margin-left: 1.444%;
    }
</style>