<template lang="pug">
div.barthreecolors(:class="{'slim':slim, 'big': big}")
    div.container
        v-tooltip(color="#89C445" :disabled="!tooltip" content-class="tooltip-help" top :style="'width: ' + greenWidth + '%;'")
            template(v-slot:activator="{ on, attrs }")
                div(v-bind="attrs" v-on="on" :style="'width: ' + greenWidth + '%;'"  :class="{'relative': showPercent}")   
                    .barThree.barGreen(v-if="green > 0" :data-percent="green + '%'" :class="{'less20': green < 20}" :style="'height:' + height + 'px'")   
            span {{ green }}%
        v-tooltip(color="#FCAE19" :disabled="!tooltip" content-class="tooltip-help" top :style="'width: ' + orangeWidth + '%;'")
            template(v-slot:activator="{ on, attrs }")  
                div(v-bind="attrs" v-on="on" :style="'width: ' + orangeWidth + '%;'" :class="{'relative': showPercent}")
                    .barThree.barOrange(v-if="orange > 0" :data-percent="orange + '%'" :class="{'guides': lineGuides, 'less20': orange < 20}" :style="'height:' + height + 'px'")
                        .guideLeft(v-if="lineGuides" :style="'height:' + heightGuides + 'px'")
                        .guideRigth(v-if="lineGuides" :style="'height:' + heightGuides + 'px'")
            span {{ orange }}%
        v-tooltip(color="#F36627" :disabled="!tooltip" content-class="tooltip-help" top :style="'width: ' + redWidth + '%;'")
            template(v-slot:activator="{ on, attrs }")  
                div(v-bind="attrs" v-on="on" :style="'width: ' + redWidth + '%;'" :class="{'relative': showPercent}")
                    .barThree.barRed(v-if="red > 0" :data-percent="red + '%'" :class="{'less20': red < 20}" :style="'height:' + height + 'px'")
            span {{ red }}%
    .barThree.barDefault(v-if="!red && !green && !orange" style="width:100%")
    div.d-flex.flex-column(v-if="renderDetails && !height")
        v-row.col-auto.align-center.no-gutters.py-0
            v-col.pl-1
                span.text-12 {{ green }}%
            v-col
                span.circle.green
            v-col.pl-1(v-if="greenValue")
                span.text-12 ({{ greenValue }})
            v-col.pl-1(v-else) -
        v-row.col-auto.align-center.no-gutters.py-0
            v-col.pl-1 
                span.text-12 {{ orange }}%
            v-col
                span.circle.orange
            v-col.pl-1(v-if="orangeValue")
                span.text-12 ({{ orangeValue }})
            v-col.pl-1(v-else) -
        v-row.col-auto.align-center.no-gutters.py-0
            v-col.pl-1 
                span.text-12 {{ red }}%
            v-col
                span.circle.red
            v-col.pl-1(v-if="redValue") 
                span.text-12 ({{ redValue }})
            v-col.pl-1(v-else) -
    div.d-flex.flex-column(v-if="renderDetails && slim && height")
        v-row.align-center.no-gutters.py-0
            v-col.col-4.justify-center.items-center
                span.mr-1.circle.green
                span.text-11 {{ green }}%
            v-col.col-4.justify-center.items-center
                span.mr-1.circle.orange
                span.text-11 {{ orange }}%
            v-col.col-4.justify-center.items-center
                span.mr-1.circle.red
                span.text-11 {{ red }}%
</template>

<script>
export default {
    name: "chartBarThreeColors",
    props: {
        height: {
            default: null
        },
        total: {
            required: false
        },
        renderDetails: {
            default: false
        },
        showPercent: {
            type: Boolean,
            required: false,
            default: false
        },
        tooltip: {
            type: Boolean,
            required: false,
            default: false
        },
        percentGreen: {
            type: Number,
            required: true,
        },
        percentOrange: {
            type: Number,
            required: true,
        },
        percentRed: {
            type: Number,
            required: true,
        },
        slim: {
            type: Boolean,
            default: false,
            required: false
        },
        lineGuides: {
            type: Boolean,
            default: false,
            required: false,
        },
        heightGuides: {
            type: Number,
            default: 0,
            required: false
        },
        big: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            green: 0,
            greenWidth: 0,
            greenValue: 0,
            orangeValue: 0,
            redValue: 0,
            orange: 0,
            orangeWidth: 0,
            red: 0,
            redWidth: 0
        }
    },
    created() {
        let total = this.percentGreen + this.percentOrange + this.percentRed
        this.green = this.$options.filters.RoundedNumber(this.percentGreen);
        this.orange = this.$options.filters.RoundedNumber(this.percentOrange);
        this.red = this.$options.filters.RoundedNumber(this.percentRed);
        this.greenValue = this.$options.filters.IntegerValue(this.$options.filters.ValueFromPercent(this.total, this.percentGreen))
        this.redValue = this.$options.filters.IntegerValue(this.$options.filters.ValueFromPercent(this.total, this.percentRed))
        this.orangeValue = this.$options.filters.IntegerValue(this.$options.filters.ValueFromPercent(this.total, this.percentOrange))
        this.greenWidth = this.$options.filters.RoundedNumber(this.percentGreen * 100 / total);
        this.orangeWidth = this.$options.filters.RoundedNumber(this.percentOrange * 100 / total);
        this.redWidth = this.$options.filters.RoundedNumber(this.percentRed * 100 / total);
    },
}
</script>

<style lang="scss" scoped>
$green: #89C445;
$orange: #FCAE19;
$red: #F36627;

.barthreecolors {
    width: 100%;
    display: flex;
    position: relative;
    height: var(--height-guides);
    flex-direction: column;

    .container {
        background: red;
        padding: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        overflow: hidden;
    }

    div:first-child {
        display: flex;
        position: relative;
        width: 100%;
    }

    .circle {
        height: 10px;
        width: 10px;
        border-radius: 100%;

        .red {
            background: $red;
        }

        .orange {
            background: $orange;
        }

        .green {
            background: $green;
        }
    }

    .percent {
        top: 0;
        line-height: 8px;
        font-size: 8px;
        color: #fff;
        left: 40%;
        display: flex;
        height: 100%;
        align-items: center;
        position: absolute;
    }

    .barThree {
        height: 9px;

        &.barDefault {
            background-color: #ccc;
            overflow: hidden;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &.barGreen {
            background-color: $green;
        }

        &.barOrange {
            background-color: $orange;

            &.guides {
                position: relative;

                .guideLeft {
                    content: " ";
                    width: 1px;
                    background-color: #000;
                    left: -1px;
                    display: block;
                    position: absolute;
                }

                .guideRigth {
                    content: " ";
                    width: 1px;
                    background-color: #000;
                    right: -1px;
                    display: block;
                    position: absolute;
                }
            }
        }

        &.barRed {
            background-color: $red;

            &::after {
                right: -20px;
            }
        }
    }

    &.slim {
        padding-top: 0;

        .barThree {
            height: 12px;

            &::after {
                content: '';
                display: none;
            }
        }
    }

    &.big {
        .barThree {
            height: 20px;
            position: relative;


            &.barRed {
                &::after {
                    right: 0;
                }
            }

            &.less20 {
                &:after {
                    content: "";
                    display: none;
                }
            }
        }
    }
}
</style>