<template lang="pug">
v-card.gridFull.ma-0
    v-skeleton-loader(type="card-heading")
    v-skeleton-loader(type="table-row-divider")
    v-skeleton-loader(type="table-row")
    v-skeleton-loader(type="table-row")
</template>

<script>

export default {
    data() {
        return {

        }
    },
    methods: {

    },
}
</script>

