<template lang="pug">
    .scatterHtml.row
      v-col(cols="1")
      v-col.px-0(cols="6")
        translate.color-red Danger
      v-col.text-right.color-blue.px-0(cols="4")
        translate Referents
      v-col(cols="12")
      v-col(cols="1")
        .block-chart.row.justify-center(:style="'height: ' + baseHeight + 'px;'")
          span.axeY(v-if="!minorAxisY || ( minorAxisY && !(index % 2))" v-for="(item, index) in itemAxeY" :style="'top: ' + itemAxeYTop * index + 'px;'") {{item}}
      v-col(cols="10")
        .block-chart.row(:style="'height: ' + baseHeight + 'px;'")
          span.lineAxeX(v-for="(item, index) in itemsAxeX" :style="'left: ' + base * index + '%;'")
          span.lineAxeY(v-if="!minorAxisY || ( minorAxisY && !(index % 2))" v-for="(item, index) in itemAxeY" :style="'top: ' + itemAxeYTop * index + 'px;'")
          span.averageX(v-if="data != ''" :style="'left:' + calculateX(averageX) + '%'")
          span.averageY(v-if="data != ''" :style="'bottom:' + calculateY(averageY) + 'px'")

          v-tooltip.text-left(top v-for="(item) in data")
            template(v-slot:activator="{ on, attrs }")
              span.pointScatter(v-bind="attrs"
                v-on="on" :style="'left: ' + calculateX(item.x) + '%; bottom:' + calculateY(item.y) + 'px'" )
            span.float-left.text-left {{item.name}} #[br]
              translate Note:
              span.ml-3 {{item.x | formatNumber}} #[br]
              translate Nº reviews:
              span.ml-3 {{item.y}}

          //- span.pointScatter(v-for="item in data" :style="'left: ' + calculateX(item.x) + '%; bottom:' + calculateY(item.y) + 'px'" )

        .block-chart-axe.row.mt-8
          v-col(cols="12")
          v-col.pa-0
            v-chip.px-4.white--text.axeX( v-for="(item, index) in itemsAxeX" :color="getColorByNoteNormalized({note: item-1,type: 'color'})" label small :style="'left: ' + calculateX(index) + '%;'") {{item - 1}}

      v-col.pa-0(cols="12")
      v-col(cols="1")
      v-col.px-0(cols="4")
        translate.color-red To correct
      v-col.text-center(cols="2")
        translate.text-gray-ligth Valoration
      v-col.px-0(cols="4").text-right.color-blue
        translate On the right track
      v-col(cols="12")

</template>
<script>
import commonMixins from "@/mixins/common.js"

export default {
  name: "scatterHtml",
  mixins: [commonMixins],
  props: ['data', 'maxYAxes', 'averageX', 'averageY'],
  data(){
      return {
        baseHeight: '200',
        itemAxeY: [],
        itemAxeYTop: '',
        minorAxisY: false,
      }
  },
  created(){
      this.calculateYAxePos()
  },
  watch: {
    maxYValue(){
      this.calculateYAxePos()
    }
  },
  computed: {
    base() {
      return this.isAdverbia ? 20 : 10
    },
    baseY() {
      return this.isAdverbia ? 5 : 10
    },
    itemsAxeX() {
      return this.isAdverbia  ? 6 : 11
    },
    maxYValue(){
      return  (Math.round(this.maxYAxes / this.base)) * this.base
    }
  },
  methods:{
    calculateX(x){
      return x * this.base - 2
    },
    calculateY(y){
      return (y * this.baseHeight) / this.maxYValue
    },
    calculateYAxePos(){
      this.itemAxeY = []
      const factor = this.maxYValue / this.baseY
      let numItemsAxe = this.maxYValue / factor
      let positionsIterator = this.maxYValue
      this.itemAxeYTop = this.baseHeight / numItemsAxe

      this.minorAxisY = this.maxYValue > 90 ? true : false

      while (positionsIterator > 0) {
        this.itemAxeY.push(positionsIterator)
        positionsIterator -= factor
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .scatterHtml{
    width: 90%;
    .block-chart{
      position: relative;
      .axeY{
        position: absolute;
        font-size: 0.75rem;
        color:#9B9B9B;
        margin-top: -8px;
      }
      .lineAxeY{
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #E0E0E0;
      }
      .lineAxeX{
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: #E0E0E0;
      }
      .averageY{
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: #3D3935;
      }
      .averageX{
        position: absolute;
        width: 4px;
        height: 100%;
        background-color: #3D3935;
      }
      .pointScatter{
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 15px;
        border: 1px solid #fff;
        background-color: #CECECE;
        cursor: pointer;
      }
    }
    .block-chart-axe{
      position: relative;
      .axeX{
        position: absolute;
      }
    }

  }
</style>
