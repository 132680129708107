import DeliveryHome from "../views/Delivery/Home.vue";
import DeliveryResumeHome from "../views/Delivery/Resume/Home.vue";
import DeliveryRankingRestaurantsHome from "../views/Delivery/RankingRestaurants/Home.vue";
import DeliveryGPSHome from "../views/Delivery/Gps/Home.vue";
import DeliveryCompetenceHome from "../views/Delivery/Competence/Home.vue";
import DeliveryCompetenceGlobal from "../views/Delivery/Competence/Global.vue";
import IndividualDeliveryCompetence from "../views/Delivery/IndividualCompetence/Home.vue";
import DeliveryCompetenceMap from "../views/Delivery/Competence/Map.vue";
import DeliveryCompetenceCompareLocals from "../views/Delivery/Competence/CompetenceCompareLocals.vue";
import IndividualDeliveryCompetenceCompareLocals from "../views/Delivery/IndividualCompetence/CompareLocals.vue";
import DeliveryPosicionamientoHome from "../views/Delivery/Posicionamiento/Home.vue";

export default {
  path: "/delivery/:paramCOD?",
  name: "DeliveryHome",
  component: DeliveryHome,
  meta: { Auth: true, disabledForAdverbia: true },
  props: true,
  children: [
    {
      path: "/delivery/resume/:paramCOD?",
      name: "DeliveryResumeHome",
      component: DeliveryResumeHome,
      meta: { Auth: true, disabledForAdverbia: true },
      props: true,
    },
    {
      path: "/delivery/ranking-restaurants/:paramCOD?",
      name: "DeliveryRankingRestaurantsHome",
      component: DeliveryRankingRestaurantsHome,
      meta: { Auth: true, disabledForAdverbia: true },
      props: true,
    },
    {
      path: "/delivery/gps/:paramCOD?",
      name: "DeliveryGPSHome",
      component: DeliveryGPSHome,
      meta: { Auth: true, disabledForAdverbia: true },
      props: true,
    },
    {
      path: "/delivery/competence/:paramCOD?",
      name: "DeliveryCompetenceHome",
      component: DeliveryCompetenceHome,
      redirect: "/delivery/competence/global/:paramCOD?",
      meta: { Auth: true, disabledForAdverbia: true },
      props: true,
      children: [
        {
          path: "/delivery/competence/global/:paramCOD?",
          name: "DeliveryCompetenceGlobal",
          component: DeliveryCompetenceGlobal,
          meta: { Auth: true, disabledForAdverbia: true },
          props: true,
          children: [],
        },
        {
          path: "/delivery/competence/map/:paramCOD?",
          name: "DeliveryCompetenceMap",
          component: DeliveryCompetenceMap,
          meta: { Auth: true, disabledForAdverbia: true },
          props: true,
          children: [],
        },
        {
          path: "/delivery/competence/compare-locals/:paramCOD?",
          name: "DeliveryCompetenceCompareLocals",
          component: DeliveryCompetenceCompareLocals,
          meta: { Auth: true, notShowDownload: true, filterRangeDistance: true },
          props(route) {
            return route.query || {};
          },
        },
      ],
    },
    {
      path: "/delivery/individual-competence/:paramCOD?",
      name: "DeliveryIndividualCompetence",
      component: DeliveryCompetenceHome,
      redirect: "/delivery/individual-competence/global/:paramCOD?",
      meta: { Auth: true, disabledForAdverbia: true },
      props: true,
      children: [
        {
          path: "/delivery/individual-competence/global/:paramCOD?",
          name: "IndividualDeliveryCompetenceGlobal",
          component: IndividualDeliveryCompetence,
          meta: { Auth: true, disabledForAdverbia: true },
          props: true,
          children: [],
        },
        {
          path: "/delivery/individual-competence/compare-locals/:paramCOD?",
          name: "IndividualDeliveryCompetenceCompareLocals",
          component: IndividualDeliveryCompetenceCompareLocals,
          meta: { Auth: true, notShowDownload: true },
          props(route) {
            return route.query || {};
          },
        },
      ],
    },
    {
      path: "/delivery/posicionamiento/:paramCOD?",
      name: "DeliveryPosicionamientoHome",
      component: DeliveryPosicionamientoHome,
      meta: { Auth: true, disabledForAdverbia: true },
      props: true,
    },
  ],
};
