<template lang="pug">
  div
    div
      v-dialog(v-model='open' scrollable='' max-width='800px' :persistent="true")
        v-card.google-link
          v-toolbar(flat)
            v-toolbar-title Setup Google
            v-spacer
          v-divider
          template
            v-card-text.py-4.overflow-hidden
              v-select.mb-5(:items="options" v-model="optionSelected" item-text="name" item-value="locations" label="Opciones" hide-details="true" solo)
              v-text-field(label="Search" autofocus v-model='query' hide-details="true" solo)
            v-divider
            v-card-text(style='height: 300px;')
              div
                v-radio-group(v-model='selection' column='')
                  v-radio(v-for="account in items" :label='account.name' :value='account.google_name')
                    template(slot="label")
                      span.d-inline-flex.align-center.text--primary
                        | {{ account.business_name }}
                        | ({{ account.business_address }})
            v-divider
            v-card-actions.justify-center
              v-btn.py-2.my-1(v-if="hasTokenGoogle" :loading="processing" color='primary' :disabled="!selection" tile min-width="100" max-height="30" @click='linkGoogle')
                | Assign

    div.d-flex.justify-center.align-center(style="padding: 20px; text-align: center")
      div
        | {{ output }}
      div(v-if="error" style="color: white")
        span {{ $gettext('Oops, there was a problem, please contact us!') }}
      div(v-else-if="!ready")
        div.gr-loader-snipper--modal
          span.gr-loader-snipper
      div(v-else-if="ready" style="color: white")
        span {{ $gettext('Thank you, you can now return to the GastroRanking PRO app!') }}

</template>

<script>

export default {
  name: "GoogleTokenApp",
  data() {
    return {
      open: false,
      processing: false,
      options: [],
      centers: [],
      query: '',
      optionSelected: undefined,
      selection: undefined,
      verifyTokenGoogle: false,
      hasTokenGoogle: false,
      isLoadingGoogleUserLocations: false,
      ready: false,
      error: false,
      output: '',
      linkingURL: undefined
    }
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.$route.query.url) {
        window.localStorage.setItem('__TOKEN', this.$route.params.token)
        window.localStorage.setItem('__UID', this.$route.params.uid)
        setTimeout(() => {
          window.location = atob(String(this.$route.query.url))
        }, 5000)
      } else {
        const uid = window.localStorage.getItem('__UID')
        const token = window.localStorage.getItem('__TOKEN')

        if (uid && token) {
          const response = await fetch(`https://${this.config_app.domain}/backend/local_marketing/edit_google_login/${uid}/${token}/`, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json;charset=UTF-8'
            }),
            body: JSON.stringify({
              code: this.$route.query.code,
              status: this.$route.query.state
            })
          })

          if (response.status === 201) {
            const data = await response.json()

            if (data.google_business_status === 'Linked') {
              this.ready = true
            } else {

              this.open = true

              if (data.google_business) {
                this.options = data.google_business.map(item => ({...item, centerSelected: ''}))
              }

              if (this.options.length) {
                this.optionSelected = this.options[0].locations
                this.hasTokenGoogle = true
              }

              this.linkingURL = data.url_data.url
            }

          } else {
            this.error = true
          }
        }

      }
    })
  },
  computed: {
    config_app () {
      return this.$config
    },
    items() {
      if (this.optionSelected) {
        return Object.values(this.optionSelected).filter(item => {
          return (String(item.business_name).toUpperCase() + ' ' + item.business_address).normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(this.query.toUpperCase())
        })
      } else {
        return []
      }
    }
  },
  methods: {
    async linkGoogle() {
      this.processing = true
      const {status} = await fetch(`${this.linkingURL}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json;charset=UTF-8'
        }),
        body: JSON.stringify({
          google_name: this.selection
        })
      })

      this.open = false

      if (status === 200 || status === 201) {
        this.ready = true
      } else {
        this.error = true
      }

      this.processing = false
    }
  }
}
</script>

<style lang="scss" scoped>
.gr-loader-snipper--modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .gr-loader-snipper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: wk-wait-open 1s infinite;
    width: 20px;
    height: 20px;
    pointer-events: none;
  }

  .gr-loader-snipper:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border: 1px solid transparent;
    border-bottom-color: #ffffff;
    border-left-color: #ffffff;
    transform: rotate(-45deg);
    border-radius: 50%;
    animation: wk-wait-open 1s infinite linear;
  }
}
</style>
