<template>
  <v-dialog v-model="isActive" width="1100">
    <v-card class="dialogEdit">
      <v-container fluid>
        <!-- Header -->
        <v-row class="items-center strong-divider">
          <v-col cols="11" class="d-flex items-center">
            <img :src="img.config" class="py-2 ml-2">
            <strong class="ml-2 mx-4">Nueva configuración</strong>
            <input type="text" v-model="configName" maxlength="54" class="rounded input configName"/>
          </v-col>
          <v-btn icon @click="close" class="close-button">
            <img :src="img.cancel" width="23" height="23">
          </v-btn>
        </v-row>

        <v-row class="divider">
          <v-col cols="4" class="d-flex items-center">
            <span class="pr-2">Marca</span>
            <input type="text" v-model="marca" maxlength="54" class="rounded input w-full" disabled/>
          </v-col>
          <v-col cols="5" class="d-flex items-center">
            <span class="pr-2">Restaurantes</span>
            <select class="selectCustom selectCustomExt w-full" @change="handleRestaurantChange($event)" v-model="actionSelect">
              <option default value="default">Todos</option>
            </select>
          </v-col>
          <v-col cols="3" class="d-flex items-center">
            <span class="pr-2">Tipo</span>
            <select class="selectCustom selectCustomExt w-full" @change="handleRestaurantChange($event)" v-model="actionSelect">
              <option default value="default">Todos</option>
            </select>
          </v-col>
        </v-row>

        <!-- Opinions Section -->
        <v-row justify="center divider">
          <v-col cols="12" class="d-flex items-center py-6">
            <span class="text-subtitle-1 pr-4 font-weight-bold">Opiniones</span>
            <p class="caption text-13 mb-0"> Responder a opiniones con Nº de estrellas en la opinión de:</p>
          </v-col>

          <v-row no-gutters class="mb-4 justify-center items-center" style="max-width: 75%">
            <div class="stars-container d-flex w-full justify-space-around">
              <v-checkbox v-model="ratings" label="" value="5">
                <template v-slot:label>
                  <stars-combinations :bigger="true" :combination="['5']"/>
                </template>
              </v-checkbox>
              <v-checkbox v-model="ratings" label="" value="4">
                <template v-slot:label>
                  <stars-combinations :bigger="true" :combination="['4']"/>
                </template>
              </v-checkbox>
              <v-checkbox v-model="ratings" label="" value="3">
                <template v-slot:label>
                  <stars-combinations :bigger="true" :combination="['3']"/>
                </template>
              </v-checkbox>
              <v-checkbox v-model="ratings" label="" value="2-1">
                <template v-slot:label>
                  <stars-combinations :bigger="true" :combination="['2-1']"/>
                </template>
              </v-checkbox>
            </div>
          </v-row>
        </v-row>

        <v-row>
          <v-col cols="12" class="mb-4 pa-4">
            <v-row align="center" class="mb-2">
              <img :src="img.ia" class="py-2">
              <v-col>
                <div class="text-13 font-weight-bold">Configura las respuestas automáticas generadas con IA</div>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="4" class="d-flex items-center px-2">
                <span class="pr-2 text-13">Tono de respuesta</span>
                <select class="selectCustom selectCustomExt w-full" @change="handleRestaurantChange($event)" v-model="actionSelect">
                  <option default value="default">Cercano/Informal</option>
                </select>
              </v-col>
              <v-col cols="4" class="d-flex items-center px-2">
                <span class="pr-2 text-13">Longitud de las respuestas</span>
                <select class="selectCustom selectCustomExt w-full" @change="handleRestaurantChange($event)" v-model="actionSelect">
                  <option default value="default">Cercano/Informal</option>
                </select>
              </v-col>
              <v-col cols="4" class="d-flex items-center px-2">
                <span class="pr-2 text-13">Idioma</span>
                <select class="selectCustom selectCustomExt w-full" @change="handleRestaurantChange($event)" v-model="actionSelect">
                  <option default value="default">En el idioma de la opinión</option>
                </select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>


        <v-row class="mb-4 pa-4">
          <v-col cols="5">
            <span class="text-subtitle-1 mb-2 font-weight-bold text-13">Palabras clave</span>
            <p class="text-13">
              Puedes incluir hasta 5 palabras (keyword) para que la IA las incluya en su respuesta para mejorar el SEO.
              Ejemplo: "burger", "Madrid", "mejores", "carne", "caseras"...
            </p>
          </v-col>

          <v-col cols="7">
            <v-row no-gutters align="center" class="mb-2">
              <v-col class="d-flex items-center">
                <input type="text" v-model="newKeyword" placeholder="Añadir palabra clave" maxlength="54" class="rounded input"/>
                <v-btn color="primary" @click="addKeyword" :disabled="keywords.length >= 5" class="ml-2">
                  Añadir palabra
                </v-btn>
              </v-col>
            </v-row>
            <v-chip-group>
              <v-chip v-for="keyword in keywords" :key="keyword" close @click:close="removeKeyword(keyword)">
                {{ keyword }}
              </v-chip>
            </v-chip-group>
          </v-col>

        </v-row>
        <v-row class="mb-4 pa-4 strong-divider">
          <v-col cols="5">
            <span class="text-subtitle-1 mb-2 font-weight-bold text-13">Entidad de Marca</span>
            <p class="text-13">
              Introduce una descripción de marca para que las respuestas de la IA sean lo más acertadas posibles. (max.
              150 caracteres)
            </p>
          </v-col>

          <v-col cols="7">
            <v-row no-gutters align="center" class="mb-2">
              <v-col class="d-flex items-center">
                <v-textarea v-model="brandDescription" label="Descripción de marca" outlined counter="150" maxlength="150"
                            rows="3"></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="auto">
            <button class="outlined-button">
              <span class="span.text-14">Cancelar</span>
            </button>
          </v-col>
          <v-col cols="auto">
            <button class="primary-button">
              <span class="span.text-14">Guardar</span>
            </button>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import StarsCombinations from "@/components/feedback/StarsCombinations.vue";

export default {
  props: {
    isActive: {type: Boolean, default: false},
  },
  components: {
    StarsCombinations
  },
  data() {
    return {
      img: {
        config: require("@/assets/img/feedback/edit-config.svg"),
        cancel: require("@/assets/img/feedback/cancel.svg"),
        ia: require("@/assets/img/feedback/ia.svg"),
      },
      actionSelect: "default",
      configName: '',
      marca: 'Marca',
      restaurante: 'Todos',
      tipo: 'Propio',
      ratings: [],
      opinionTypes: [],
      categoria: 'Cocina',
      tono: 'Cercano/informal',
      longitud: 'Corta',
      idioma: 'En el idioma de la opinión',
      newKeyword: '',
      keywords: ['burger'],
      brandDescription: ''
    }
  },
  methods: {
    handleRestaurantChange() {
    },
    addKeyword() {
      if (this.newKeyword && this.keywords.length < 5) {
        this.keywords.push(this.newKeyword)
        this.newKeyword = ''
      }
    },
    removeKeyword(keyword) {
      this.keywords = this.keywords.filter(k => k !== keyword)
    },
    close() {
      this.$emit("onHidden")
    },
    cancel() {
      // Implement cancel logic
    },
    save() {
      // Implement save logic
    }
  }
}
</script>

<style scoped>
.main {
  position: fixed;
  z-index: 10;
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.selectCustomExt {
  min-height: 40px;
}

.primary-button {
  background-color: #00ADFC;
}

.primary-button span {
  color: white
}

.outlined-button span {
  color: #CECECF
}

.outlined-button {
  border: 1px solid #9B9B9B;
}

.outlined-button, .primary-button {
  padding: 10px 20px;
}

.caption {
  color: #8A8A8A;
}

.stars-container {
  background-color: #E8E9ED;
  border-radius: 4px;
}

input {
  border: #D7DAE2 solid 1px;
  min-height: 40px;
  padding: 0 10px;
}

.strong-divider {
  border-bottom: 1px solid #707070;
}

.divider {
  border-bottom: 1px solid #CECECE;
}

.divider, .strong-divider {
  padding: 18px 0;
}

.configName {
  width: 70%;
}
</style>
