/* App */
export const PENDING = "PENDING"; // Para cuando consultamos la api y todavía no hemos recibido respuesta.
export const NOT_PENDING = "NOT_PENDING";
export const LOADING = "LOADING"; // IS LOADING
export const GLOBAL_LOADING = "GLOBAL_LOADING"; // GLOBAL LOADING

/* Auth - USER */
export const LOGIN = "LOGIN"; // Para logarse
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"; // una vez estemos logados
export const LOGOUT = "LOGOUT"; // para hacer logout
export const SET_USER = "SET_USER";
export const UPDATE_USER_COUNTRY = "UPDATE_USER_COUNTRY"; // para actualizar el pais del usuario.
export const USER_NAME = "USER_NAME"; // to save user name

/* NAVBAR */
export const UPDATE_SECTION = "UPDATE_SECTION";

/* SUBNAV */
export const OPEN_SUBNAV = "OPEN_SUBNAV";
export const BREAD_CRUMB = "BREAD_CRUMB";
export const SET_NODE = "SET_NODE";
export const SET_NODE_LEVEL = "SET_NODE_TYPE";
export const SET_TREE_DATA = "SET_TREE_DATA";
export const SET_TREE_DATA_WITHOUT_FILTER = "SET_TREE_DATA_WITHOUT_FILTER";

/* TREE */
export const TREE_UPDATE = "TREE_UPDATE";
export const TREE_OPEN_IDS = "TREE_OPEN_IDS";
export const TREE_SHOW_TREE = "TREE_SHOW_TREE";
export const TREE_CENTER_NO_TREE = "TREE_CENTER_NO_TREE";

/* COMMON */
export const COMMON_DO_NOT_PROCESS_FILTER = "COMMON_DO_NOT_PROCESS_FILTER";
export const COMMON_DATE_AFTER = "COMMON_DATE_AFTER";
export const COMMON_DATE_BEFORE = "COMMON_DATE_BEFORE";
export const COMMON_TYPES_RESTAURANT = "COMMON_TYPES_RESTAURANT";
export const COMMON_TYPES_RESTAURANT_SELECTED = "COMMON_TYPES_RESTAURANT_SELECTED";
export const COMMON_CENTER_MODELS = "COMMON_CENTER_MODELS";
export const COMMON_CENTER_MODELS_SELECTED = "COMMON_CENTER_MODELS_SELECTED";
export const COMMON_SITES_SELECTED = "COMMON_SITES_SELECTED";
export const COMMON_ALL_SITES_OPINIONS = "COMMON_ALL_SITES_OPINIONS";
export const COMMON_SITES_OPINIONS = "COMMON_SITES_OPINIONS";
export const COMMON_TYPES_SELECTED = "COMMON_TYPES_SELECTED";
export const COMMON_TYPES_SELECTED_INTERNET_AUTOMATIC = "COMMON_TYPES_SELECTED_INTERNET_AUTOMATIC";
export const COMMON_REASONS = "COMMON_REASONS";
export const COMMON_CATEGORIES = "COMMON_CATEGORIES";
export const COMMON_REGIONS = "COMMON_REGIONS";
export const COMMON_REGION_SELECTED = "COMMON_REGION_SELECTED";
export const COMMON_AGGREGATOR_ID = "COMMON_AGGREGATOR_ID";
export const COMMON_MOMENT_ID = "COMMON_MOMENT_ID";
export const COMMON_DISTANCE_ID = "COMMON_DISTANCE_ID";
export const QUERY_PARAMS = "QUERY_PARAMS";
export const USER_INFO = "USER_INFO";
export const TREE_SELECT = "TREE_SELECT";
export const DOWNLOADING_EXCEL = "DOWNLOADING_EXCEL";
export const LIMIT_EXCEDED_EXCEL = "LIMIT_EXCEDED_EXCEL";
export const COMMON_HAS_DELIVERY = "COMMON_HAS_DELIVERY";
export const COMMON_HAS_COMPETENCE = "COMMON_HAS_COMPETENCE";
export const COMMON_HAS_EMPLOYEES = "COMMON_HAS_EMPLOYEES";
export const COMMON_HAS_DISHKEYWORDS = "COMMON_HAS_DISHKEYWORDS";
export const COMMON_HAS_CUSTOM_SURVEYS = "COMMON_HAS_CUSTOM_SURVEYS";
export const COMMON_SITES_DELIVERY_SELECTED = "COMMON_SITES_DELIVERY_SELECTED";
export const COMMON_DISTANCE_MAP = "COMMON_DISTANCE_MAP";
export const COMMON_DISTANCE_MAP_MAX = "COMMON_DISTANCE_MAP_MAX";
export const COMMON_DISTANCE_MAP_ACTIVE = "COMMON_DISTANCE_MAP_ACTIVE";
export const COMMON_SHOW_NOT_INTERNET = "COMMON_SHOW_NOT_INTERNET";
export const COMMON_SHOW_NOT_DELIVERY = "COMMON_SHOW_NOT_DELIVERY";
export const COMMON_IS_HOTEL = "COMMON_IS_HOTEL";
export const COMMON_TOP_SECTION_HEIGHT = "COMMON_TOP_SECTION_HEIGHT";

/* SATISFACTION */
export const SATISFACTION_FILTER_COMMENT_TYPES_SELECTED = "SATISFACTION_FILTER_COMMENT_TYPES_SELECTED";
export const SATISFACTION_FILTER_COMMENT_LANGUAGES_SELECTED = "SATISFACTION_FILTER_COMMENT_LANGUAGES_SELECTED";
export const SATISFACTION_FILTER_REASON_TYPES_SELECTED = "SATISFACTION_FILTER_REASON_TYPES_SELECTED";
export const SATISFACTION_FILTER_CONSUMPTION_TYPES_SELECTED = "SATISFACTION_FILTER_CONSUMPTION_TYPES_SELECTED";
export const SATISFACTION_FILTER_SURVEY_TYPES_SELECTED = "SATISFACTION_FILTER_SURVEY_TYPES_SELECTED";
export const SATISFACTION_FILTER_ORIGIN_TYPES_SELECTED = "SATISFACTION_FILTER_ORIGIN_TYPES_SELECTED";
export const SATISFACTION_FILTER_CHARACTER_TYPES_SELECTED = "SATISFACTION_FILTER_CHARACTER_TYPES_SELECTED";
export const SATISFACTION_INDICATORS = "SATISFACTION_INDICATORS";
export const SATISFACTION_COMMENTS = "SATISFACTION_COMMENTS";
export const SATISFACTION_RANKING = "SATISFACTION_RANKING";
export const SATISFACTION_UPDATE = "SATISFACTION_UPDATE";
export const SATISFACTION_CLAIMS = "SATISFACTION_CLAIMS";

/* APPS REVIEWS */
export const APPS_FILTER_OPINIONS_SITES_SELECTED = "APPS_FILTER_OPINIONS_SITES_SELECTED";
export const APPS_OPINIONS = "APPS_OPINIONS";
export const APPS_UPDATE = "APPS_UPDATE";

/* FEEDBACK */
export const FEEDBACK_FILTER_OPINIONS_TYPES_SELECTED = "FEEDBACK_FILTER_OPINIONS_TYPES_SELECTED";
export const FEEDBACK_UPDATE = "FEEDBACK_UPDATE";
export const FEEDBACK_FILTER_OPINIONS_CATEGORIES_SELECTED = "FEEDBACK_FILTER_OPINIONS_CATEGORIES_SELECTED";
export const FEEDBACK_FILTER_OPINIONS_SITES_SELECTED = "FEEDBACK_FILTER_OPINIONS_SITES_SELECTED";
export const FEEDBACK_OPINIONS = "FEEDBACK_OPINIONS";
export const FEEDBACK_OPINIONS_SEARCH = "FEEDBACK_OPINIONS_SEARCH";

/* BOOKING */
export const BOOKING_OPINIONS = "BOOKING_OPINIONS";
export const BOOKING_OPINIONS_SEARCH = "FEEDBACK_OPINIONS_SEARCH";
export const BOOKING_HAS_ERROR = "BOOKING_HAS_ERROR";
export const BOOKING_COUNT = "BOOKING_COUNT";
export const BOOKING_CLIENT_FILTERS = "BOOKING_CLIENT_FILTERS";
export const BOOKING_FILTER_OPINIONS_CATEGORIES_SELECTED = "BOOKING_FILTER_OPINIONS_CATEGORIES_SELECTED";
export const BOOKING_NEXT_URL = "BOOKING_NEXT_URL";
export const BOOKING_OPINIONS_TYPE_SELECTED = "BOOKING_OPINIONS_TYPE_SELECTED";
export const BOOKING_CLIENT_FILTER_SELECTED = "BOOKING_CLIENT_FILTER_SELECTED";
export const BOOKING_IS_PENDING = "BOOKING_IS_PENDING";
export const BOOKING_CLEARED_STATE = "BOOKING_CLEARED_STATE";

/* REPORTS */
export const REPORTS = "REPORTS";
export const REPORT_FILTER_TYPES_SELECTED = "REPORT_FILTER_TYPES_SELECTED";

/* GASTROPRICING */
export const GP_BRANDS = "GP_BRANDS"; // brand options
export const GP_BRAND_SELECT = "GP_BRAND_SELECT"; // brand option selected
export const GP_PROVINCES = "GP_PROVINCES"; // PROVINCE OPTIONS
export const GP_PROVINCE_SELECT = "GP_PROVINCE_SELECT"; // PROVINCE OPTION SELECTED
export const GP_FAMILIES = "GP_FAMILIES"; // PROVINCE OPTIONS
export const GP_FAMILY_SELECT = "GP_FAMILY_SELECT"; // PROVINCE OPTION SELECTED
export const GP_CITIES = "GP_CITIES"; // CITIES OPTIONS
export const GP_CITY_SELECT = "GP_CITY_SELECT"; // CITIES OPTION SELECTED
export const GP_POSTAL_CODES = "GP_POSTAL_CODES"; // POSTAL CODE OPTIONS
export const GP_POSTAL_CODE_SELECT = "GP_POSTAL_CODE_SELECT"; // POSTAL CODE OPTION SELECTED
export const GP_MOMENTS = "GP_MOMENTS"; // MOMENTS SELECTED
export const GP_MOMENT_SELECT = "GP_MOMENT_SELECT"; // MOMENTS SELECTED
export const GP_CHANNELS = "GP_CHANNELS"; // MOMENTS SELECTED
export const GP_CHANNEL_SELECT = "GP_CHANNEL_SELECT"; // MOMENTS SELECTED
export const GP_AGGREGATORS = "GP_AGGREGATORS"; // AGGREGATORS SELECTED
export const GP_AGGREGATOR_SELECT = "GP_AGGREGATOR_SELECT"; // AGGREGATORS SELECTED
export const GP_COUNTRY_SELECTED = "GP_COUNTRY_SELECTED"; // COUNTRI ACTIVE

/* DELIVERY */
export const REASONS_SELECTED = "REASONS_SELECTED";

/* LOCAL MARKETING */
export const LOCAL_MARKETING_NOTIFICATIONS_STATS = "LOCAL_MARKETING_NOTIFICATIONS_STATS";
export const LOCAL_RANGE_DATES = "LOCAL_RANGE_DATES";
export const LOCAL_PENDING = "LOCAL_PENDING";
export const CAN_MODIFY_SERVICES_LIST = "CAN_MODIFY_SERVICES_LIST";
export const LOCAL_GOOGLE_SEARCH = "LOCAL_GOOGLE_SEARCH";
