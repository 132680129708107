<template>
    <button class="buttonAddItem d-flex items-center px-3 py-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="19.5" height="19.5" viewBox="0 0 19.5 19.5" class="mr-1">
  <path id="Trazado_5492" data-name="Trazado 5492" d="M7.4,11H11m3.6,0H11m0,0V7.4M11,11v3.6M11,20a9,9,0,1,0-9-9A9,9,0,0,0,11,20Z" transform="translate(-1.25 -1.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
</svg>
        <slot></slot>
    </button>
</template>

<script>
export default{

}
</script>

<style scoped>
.buttonAddItem{
    background-color: #4A90E2;
    color: #fff;
    border-radius: 8px;
    
}
</style>