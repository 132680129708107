import ReportsHome from '../views/Reports/Home.vue'
import ReportsList from '../views/Reports/Pages/List.vue'
import ReportsListExcel from '../views/Reports/Pages/ListExcel.vue'

export default {
    path: '/reports',
    name: 'ReportsHome',
    component: ReportsHome,
    redirect: '/reports/home/:paramCOD?',
    meta: {Auth: true},
    props: true,
    children: [
      {
        path: 'home/:paramCOD?',
        name: 'ReportsList',
        component: ReportsList,
        meta: {Auth: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'list-excel/:paramCOD?',
        name: 'ReportsListExcel',
        component: ReportsListExcel,
        meta: {Auth: true},
        props(route) {
          return  route.query || {}
        }
      }
    ]
  }