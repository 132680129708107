<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 18" :class="{'dBlock': block}">
<g id="Capa_2" data-name="Capa 2">
    <g id="Capa_1-2" data-name="Capa 1">
        <path d="M20.5,5.64,11,.13a1,1,0,0,0-1,0L.5,5.64A1,1,0,0,0,.13,7a1,1,0,0,0,.87.5,1,1,0,0,0,.49-.13l9-5.22,9,5.22A1,1,0,0,0,20.87,7,1,1,0,0,0,20.5,5.64Z"  :fill="color" />
        <path d="M13.38,11.75A4,4,0,0,0,10.5,5a4,4,0,0,0-2.89,6.75,5.74,5.74,0,0,0-1.35,1A5.93,5.93,0,0,0,4.5,17v1h2V17a4,4,0,0,1,8,0v1h2V17A6,6,0,0,0,13.38,11.75ZM10.5,7a2,2,0,0,1,0,4h0a2,2,0,1,1,0-4Z"  :fill="color" />
    </g>
</g>
</svg>
</template>

<script>
export default {
  name: "IcoEnvironment",
  props: {
    color: {
        type: String,
        default: '#000000',
        required: false
    },
    block: {
        type: Boolean,
        default: true,
        required: false
    }
  }
}
</script>

<style scoped>
    .dBlock{
        display: block;
    }
</style>