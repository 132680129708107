// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'

axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {

  getReviews(order, excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/reviews/`;
      query = SrvCommon.getBasicParamsQuery(query)
      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      if (Stores.getters.getOpinionsTypesSelected.length > 0) {
        for (let type in Stores.getters.getOpinionsTypesSelected) {
          query += '&' + Stores.getters.getOpinionsTypesSelected[type].value + '=true'
        }
      }

      if (Stores.getters.getOpinionsCategoriesSelected.length > 0) {
        for (let cat in Stores.getters.getOpinionsCategoriesSelected) {
          query += `&categories=${Stores.getters.getOpinionsCategoriesSelected[cat].value}`
        }
      }
      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }

      if (Stores.getters.getOpinionsSearch) {
        query += `&search=${Stores.getters.getOpinionsSearch}`
      }

      if (order === 'source_date') {
        order += ',source_time'
      } else if (order === '-source_date') {
        order += ',-source_time'
      }
      query += '&ordering=' + order + ',-id'
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }
      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, getReviewsNext(url) {
    return new Promise((resolve, reject) => {
      let token = SrvCommon.getToken();

      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(url)
        .then(res => {
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, getReviewsCount() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/reviews/`;
      query = SrvCommon.getBasicParamsQuery(query)
      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      if (Stores.getters.getOpinionsTypesSelected.length > 0) {
        for (let type in Stores.getters.getOpinionsTypesSelected) {
          query += '&' + Stores.getters.getOpinionsTypesSelected[type].value + '=true'
        }
      }

      if (Stores.getters.getOpinionsCategoriesSelected.length > 0) {
        for (let cat in Stores.getters.getOpinionsCategoriesSelected) {
          query += `&categories=${Stores.getters.getOpinionsCategoriesSelected[cat].value}`
        }
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }
      if (Stores.getters.getOpinionsSearch) {
        query += `&search=${Stores.getters.getOpinionsSearch}`
      }
      query += '&count=True'
      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, getAnswersParams() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/predictive_response_params`;

      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, getAnswersCategoriesByLanguage(language, centerId) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let query = `/semantic_category_list?country=${language.toLowerCase()}`

      if (Stores.getters.getNode) {
        query += `&center=${centerId}`
      }

      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, getAnswers(language, category, type, category_type = null) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)

      let query = `/predictive_reply_review/?language=${language.toUpperCase()}&category=${category}&review_type=${type}`;
      if (category_type) {
        query += `&category_type=${category_type}`
      }
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },

  getDishRanking(search = '', excel = false) {
    return new Promise((resolve, reject) => {

      Stores.commit(types.PENDING)
      let query = `/dishnametags/`
      query = SrvCommon.getBasicParamsQuery(query)

      if (search) {
        query += `&search=${search}`
      }

      let configHeaders = {}

      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }

      let token = SrvCommon.getToken()

      axios.defaults.headers.common['Authorization'] = 'Token ' + token

      axios.get(query, configHeaders)
        .then(res => {
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },

  getReviewsDishRanking(props, excel = false) {
    return new Promise((resolve, reject) => {

      Stores.commit(types.PENDING)
      let query = `/dishnametags/${props['type']}`
      query = SrvCommon.getBasicParamsQuery(query)

      let configHeaders = {}

      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }

      // dish id
      query += `&dish=${props['id']}`

      let token = SrvCommon.getToken()

      axios.defaults.headers.common['Authorization'] = 'Token ' + token

      axios.get(query, configHeaders)
        .then(res => {
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },

  getKeywords(type = '', excel = false) {
    // semantinccategoryterms
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/semantinccategoryterms/`
      query = SrvCommon.getBasicParamsQuery(query)
      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      } else if (type !== '') {
        query += `&review_type=${type}`
      }
      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      if (Stores.getters.getOpinionsTypesSelected.length > 0) {
        for (let type in Stores.getters.getOpinionsTypesSelected) {
          query += '&' + Stores.getters.getOpinionsTypesSelected[type].value + '=true'
        }
      }

      if (Stores.getters.getOpinionsCategoriesSelected.length > 0) {
        for (let cat in Stores.getters.getOpinionsCategoriesSelected) {
          query += `&categories=${Stores.getters.getOpinionsCategoriesSelected[cat].value}`
        }
      }
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, getEmployees(order, excel = false) {
    // employees
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/employees_by_center/`
      query = SrvCommon.getBasicParamsQuery(query)
      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      if (Stores.getters.getOpinionsTypesSelected.length > 0) {
        for (let type in Stores.getters.getOpinionsTypesSelected) {
          query += '&' + Stores.getters.getOpinionsTypesSelected[type].value + '=true'
        }
      }

      if (Stores.getters.getOpinionsCategoriesSelected.length > 0) {
        for (let cat in Stores.getters.getOpinionsCategoriesSelected) {
          query += `&categories=${Stores.getters.getOpinionsCategoriesSelected[cat].value}`
        }
      }
      query += '&ordering=' + order
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, getReviewsForEmploye(id, employedCenter, order) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/reviews/`;
      query = SrvCommon.getBasicParamsQuery(query)
      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      if (Stores.getters.getOpinionsTypesSelected.length > 0) {
        for (let type in Stores.getters.getOpinionsTypesSelected) {
          query += '&' + Stores.getters.getOpinionsTypesSelected[type].value + '=true'
        }
      }

      if (Stores.getters.getOpinionsCategoriesSelected.length > 0) {
        for (let cat in Stores.getters.getOpinionsCategoriesSelected) {
          query += `&categories=${Stores.getters.getOpinionsCategoriesSelected[cat].value}`
        }
      }
      if (order === 'source_date') {
        order += ',source_time'
      } else if (order === '-source_date') {
        order += ',-source_time'
      }
      query += '&ordering=' + order + ',-id'
      query += '&employees=' + id + '&center=' + employedCenter
      let configHeaders = {}
      /*if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }*/
      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, getNextReviewsForEmploye(nextUrl) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let configHeaders = {}

      // llamada a la API
      let token = SrvCommon.getToken();

      if (!nextUrl) reject("[ERROR_getNextReviewsForEmploye]: next or previous is required")

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(nextUrl, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, getDishKeywords(order, excel = false) {
    // employees
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/dishkeywords_by_center/`
      query = SrvCommon.getBasicParamsQuery(query)
      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      if (Stores.getters.getOpinionsTypesSelected.length > 0) {
        for (let type in Stores.getters.getOpinionsTypesSelected) {
          query += '&' + Stores.getters.getOpinionsTypesSelected[type].value + '=true'
        }
      }

      if (Stores.getters.getOpinionsCategoriesSelected.length > 0) {
        for (let cat in Stores.getters.getOpinionsCategoriesSelected) {
          query += `&categories=${Stores.getters.getOpinionsCategoriesSelected[cat].value}`
        }
      }
      query += '&ordering=' + order
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, getReviewsForDish(id, employedCenter, order) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/reviews/`;
      query = SrvCommon.getBasicParamsQuery(query)
      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      if (Stores.getters.getOpinionsTypesSelected.length > 0) {
        for (let type in Stores.getters.getOpinionsTypesSelected) {
          query += '&' + Stores.getters.getOpinionsTypesSelected[type].value + '=true'
        }
      }

      if (Stores.getters.getOpinionsCategoriesSelected.length > 0) {
        for (let cat in Stores.getters.getOpinionsCategoriesSelected) {
          query += `&categories=${Stores.getters.getOpinionsCategoriesSelected[cat].value}`
        }
      }
      if (order === 'source_date') {
        order += ',source_time'
      } else if (order === '-source_date') {
        order += ',-source_time'
      }
      query += '&ordering=' + order + ',-id'
      query += '&dishkeywords=' + id + '&center=' + employedCenter
      let configHeaders = {}
      /*if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }*/
      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, getAvailableCategories(id, type) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/available-categories/`;
      query = SrvCommon.getBasicParamsQuery(query) + `&review_id=${id}&review_type=${type}`
      let configHeaders = {}

      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query, configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, removeOrAddCategorie({review_id, category_id, action, review_type}) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/available-categories/`;
      /*let params = {}
      params = {review_id,category_id, action, review_type}*/
      const data = new FormData()
      data.append('review_id', review_id)
      data.append('category_id', category_id)
      data.append('action', action)
      data.append('review_type', review_type)
      // llamada a la API
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios({method: 'post', url: query, data: data})
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, getAnswersChatGPT({review, n = 1}) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/chatgpt/answer_review/`;

      // llamada a la API
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.post(query, {data: review, n: n})
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }, getAutomaticAnswersConfigs() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/ai-reply-config/`;

      // llamada a la API
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token


      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `?center=${Stores.getters.getNode}`
        }
      } else {
        if (Stores.getters.getNode) {
          query += `?node=${Stores.getters.getNode}`
        }
      }

      console.log({query})

      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
}
