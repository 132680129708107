// Stores
import Stores from "@/store/index";
// importamos los tipos de mutación de vuex
import * as types from "@/store/mutation-types";
// Config
import appConfig from "@/config-app.js";
// funciones comunes
import SrvCommon from "@/services/common";

// Axios
import axios from "axios";
axios.defaults.baseURL = appConfig.api;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
//
//
// business_category/
export default {
  getPosts(id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/center/${id}/mybusiness/posts/`;
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getButtonActions() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/local_marketing/mybusiness/buttonactions/`;
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  setPost(id, data, to_facebook = null, to_instagram = null) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/center/${id}/mybusiness/posts/`;
      let method = "post";
      if (to_facebook || to_instagram) {
        query += "?";
      }
      if (to_facebook) {
        query += "to_facebook=True";
      }
      if (to_instagram) {
        if (to_facebook) {
          query += "&";
        }
        query += "to_instagram=True";
      }
      if (data.get("name")) {
        method = "put";
      }
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios[method](query, data)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  deletePost(id, name) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/center/${id}/mybusiness/posts/`;
      let method = "delete";
      query += `?name=${name}`;
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios[method](query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  setPostMultiple(data) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/local_marketing/batch_update/`;
      let method = "post";
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios[method](query, data)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  deleteScheduledPost(id) {
    return new Promise((resolve, reject) => {
      if (!id) return reject("missing id");

      Stores.commit(types.PENDING);

      let query = `/local_marketing/batch_update/${id}`;
      let method = "delete";
      let token = SrvCommon.getToken();

      axios.defaults.headers.common["Authorization"] = "Token " + token;

      axios[method](query)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  updateScheduledPost(data, id) {
    return new Promise((resolve, reject) => {
      if (!id) return reject("missing id");

      Stores.commit(types.PENDING);

      let query = `/local_marketing/batch_update/${id}`;
      let method = "put";
      let token = SrvCommon.getToken();

      axios.defaults.headers.common["Authorization"] = "Token " + token;

      axios[method](query, data)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
};
