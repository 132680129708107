<template lang="pug">
  div

    div.margin-bottom-0.gridFull

      v-row.headTitleGrid

        v-col.titleGrid(md="4")
          span #[translate Ranking de Platos]

        v-col(md="8")
          div.filters
            span.downloadExcel.mr-5(@click="downloadExcelCustom()")
              img(:src="imgCommons.download" width="10")
            span.labelFilter #[translate Search]:
              div.filter.ml-2.mr-4
                input.filterBySearch(type="text" v-model="search")
                button.filterBySearchButton.clean(@click="clean" v-if="search")
                  img(:src="img.clean")
                button.filterBySearchButton(@click="getDishRanking")
                  img(:src="img.search")

      v-row
        .card-content
        .row.text-left
          v-col.col-6(v-if="data")
            table.tsm-table
              thead
                td
                  div #[span #] #[translate PLATOS MÁS VALORADOS]
                td
                  div #[translate Plato]
                td
                  div #[translate No. of times]
                td
                  div #[translate Restaurante]

              tbody
                tr
                  td(v-if="!data")
                    p.ml-3 #[translate No data]

                template(v-for="(item, index) in data['favourites']")
                  tr
                    td
                      div {{ index + 1 }}
                    td
                      div {{ item.name }}
                    td
                      div {{ item['favourite_count'] | formatNumber }}
                    td
                      span(@mousedown="gotoRestaurant([item['favouritedish__review__center'], item['favouritedish__review__center__name']])") {{ item['favouritedish__review__center__name'] }}


          v-col.col-6(v-if="data")
            table.tsm-table
              thead
                td.red-color
                  div #[span #] #[translate PLATOS MENOS VALORADOS]
                td
                  div #[translate Plato]
                td
                  div #[translate No. of times]
                td
                  div #[translate Restaurante]

              tbody
                tr
                  td(v-if="!data")
                    p.ml-3 #[translate No data]

                template(v-for="(item, index) in data['unfavourites']")
                  tr
                    td
                      div {{ index + 1 }}
                    td
                      div {{ item.name }}
                    td
                      div {{ item['unfavourite_count'] | formatNumber }}
                    td
                      span(@mousedown="gotoRestaurant([item['unfavouritedish__review__center'], item['unfavouritedish__review__center__name']])") {{ item['unfavouritedish__review__center__name'] }}

</template>

<script>
import SrvFeedback from '@/services/feedback.js'
import commonMixins from "@/mixins/common.js"

export default {
  mixins: [commonMixins],
  components: {},
  data() {
    return {
      img: {
        search: require('@/assets/img/buscar.svg'),
        clean: require('@/assets/img/borrar.svg'),
        delivery: require('@/assets/img/ico-delivery.svg'),
        local: require('@/assets/img/ico-local.svg')
      },
      loading: true,
      data: null,
      search: ''
    }
  },
  async created() {
    if (this.$store.getters.getNode > 0) {
      this.getDishRanking()
    }
  },
  methods: {
    clean() {
      this.search = ''
      this.getDishRanking()
    },
    downloadExcelCustom() {
      let params = { search: this.search }
      this.downloadExcel(params)
    },
    gotoRestaurant(item) {
      let itemCustom = {}
      itemCustom.id = item[0]
      itemCustom.name = item[1]
      itemCustom.level = 9
      this.goItemNextLevel(itemCustom, true)
    },
    getDishRanking() {
      SrvFeedback.getDishRanking(this.search)
        .then(res => {
          if (res.status === 200) {
            this.data = res.data
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.gridFull {
  overflow: hidden;
}

.filterBySearch {
  vertical-align: middle;
  font-size: 0.75rem;
  border: 1px solid #D7DAE2;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0;
  background: white;
  color: #1976d2;
  height: 34px;
  padding-left: 16px;
}

.filterBySearchButton {
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #D7DAE2;
  border-left: 0;
  height: 34px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 10px;

  &.clean {
    border-right: 1px solid #D7DAE2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  img {
    width: 14px;
    margin-top: 5px;
  }
}

.tsm-table {
  width: 100%;
  margin-left: 1px;
  border-collapse: collapse;

  thead {
    font-weight: normal;
    font-size: 12px;

    td {
      padding: 0.5rem 0.5rem 0.25rem;
      border-bottom: solid 2px #9797973d;
    }

    td:nth-child(1) {
      color: #89C445;
      min-width: 7rem;
      max-width: 7rem;
      padding-left: 0.5rem;

      div {
        text-align: center;
      }

      &.red-color {
        color: #D0021B;
      }
    }

    td:nth-child(3) {
      min-width: 4rem;

      div {
        display: flex;
        justify-content: center;
      }
    }

    td:nth-child(4) {
      min-width: 5rem;
    }
  }

  tbody {
    font-weight: normal;
    font-size: 14px;

    tr:last-child {
      td {
        padding: 0.5rem 0.5rem 0.7rem;
        border-bottom: solid 1px transparent;
      }
    }

    td {
      padding: 0.5rem;
      border-bottom: solid 1px #9797973d;
    }

    td:nth-child(1) {
      div {
        display: flex;
        justify-content: center;
      }
    }

    td:nth-child(2) {
      font-weight: 600;
    }

    td:nth-child(3) {
      div {
        display: flex;
        justify-content: center;
      }
    }

    td:nth-child(4) {
      color: #2C7DFF;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
