<template>
    <button class="buttonUpdateItem d-flex items-center px-3 py-1  text-14" :class="{'isActive': isActive}">
      <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="21.5" height="21.5" viewBox="0 0 21.5 21.5">
  <path :stroke="isActive ? '#fff' : '#f93549'" id="Trazado_14839" data-name="Trazado 14839" d="M9.172,14.828,12,12m2.828-2.828L12,12m0,0L9.172,9.172M12,12l2.828,2.828M12,22A10,10,0,1,0,2,12,10,10,0,0,0,12,22Z" transform="translate(-1.25 -1.25)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
</svg>


        <slot></slot>
    </button>
</template>

<script>
export default{
  props:{
    isActive: {
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.buttonUpdateItem{
    background-color: #fff;
    color: #f93549;
    border:1px solid #f93549;
    border-radius: 8px;
    &.isActive{
      background-color: #f93549;
      color: #fff;
    }
}
</style>