<template lang="pug"> 
div.margin-bottom-0.col-4
    //skeleton-card(v-if="isLoading")
    .ma-0.pl-6.pr-6.pb-4.queOfrece
        div.gr-loader-snipper--wrapper(v-if="loading")
            span.gr-loader-snipper
        v-row.headTitleGrid
            v-col.titleGrid(md="12")
                span {{ bool_attributes[0].group_name }}
        .body
            v-row
                v-col(sm="12")

            v-row.d-flex.align-center(v-for="(item, index) in bool_attributes")
                v-col.py-1(sm="7")
                    span {{ item.attribute_name }}
                v-col.py-1(sm="5")
                    button.queOfreceCheck.mr-2(:class="{'on': item.value}" :disabled="!hasPremium" @click="changeStatus(item,true)") Si
                    button.queOfreceCheck(:class="{'on': !item.value}" :disabled="!hasPremium" @click="changeStatus(item,false)") No
        v-row#footer.mt-6
            v-col(cols="12")
                button.btnSave(@click="save()") #[translate Save]
</template>
<script>
import SrvLocal from "@/services/local.js";
export default {
  props: ["bool_attributes", "hasPremium"],
  data() {
    return {
      showWarning: false,
      loading: false,
      section: "attributes",
    };
  },
  computed: {},
  methods: {
    changeStatus(item, status) {
      if (this.hasPremium) {
        item.value = status;
        item.isEdited = true;
      }
    },
    async save() {
      if (this.hasPremium) {
        this.loading = true;

        const res = await SrvLocal.setLocalMarketing(this.section, { bool_attributes: this.bool_attributes.filter((e) => e.isEdited) });
        if (res.status === 200) {
          this.$emit("update:bool_attributes", this.bool_attributes);
        }
        this.loading = false;
      }
    },
  },
  watch: {
    bool_attributes() {},
  },
};
</script>
<style lang="scss" scoped>
#footer {
  background-color: #fff;
  border-top: 1px solid #707070;
  text-align: center;
  padding: 8px 0 16px 0;
  .btnSave {
    text-align: center;
    padding: 8px 0 8px 0;
    background-color: #6aa0df;
    color: #fff;
    min-width: 118px;
    margin-left: 13px;
    border-radius: 4px;
  }
}
.queOfrece {
  border: 1px solid #cecece;
  border-radius: 4px;
  padding-top: 15px;
  .titleGrid {
    font-size: 13px;
    font-weight: bold;
    padding-top: 15px;
    span {
      border-bottom: 1px solid #979797;
    }
  }
}
</style>
