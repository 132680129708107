<template lang="pug">
    v-col.margin-bottom-0.flex-full(sm="12" :class="{'premiumDisabled': !hasPremium, 'notShadow pa-0': idsBussines}")
            skeleton-card(v-if="isLoading")
            v-card.gridFull.h-auto.my-4.ma-0(:class="{'pl-6 pr-9 mt-2': !idsBussines, 'notShadow mt-0': idsBussines}" v-if="!isLoading")
                v-row.ma-0
                    v-col.text-14(sm="12")
                        strong.mr-16 #[translate Bloquear ficha]
                        p  #[translate Bloquea tu ficha para que ni google ni nadie pueda modificar tu infomación, asi evitaras que la competencia edite tu ficha de forma maliciosa.]

                
                v-row.ma-0
                    v-col.text-14(sm="12")
                      .borderNotificationAction.pa-2
                        v-row
                          v-col(:sm="idsBussines ? 4 : 2")
                            span Bloquear secciones:
                            div.d-flex.align-center
                              <v-checkbox id="all" class="d-inline mr-2 my-0 py-0" :disabled="!hasPremium" @change="selectOrDeselectAll"  hide-details value :ripple="false"></v-checkbox>
                              <label for="all" title="Marcar o desmarcar todas">Todas</label>
                          v-col.d-flex.align-center.flex-wrap(:sm="idsBussines ? 8 : 7")
                            div.d-flex.align-center.flex-50.mb-2(v-for="(item, index) in sections" :class="{'opacity-50': sectionsNotCanBlocked.includes(item.name)}")
                              <v-checkbox :id="item.name" class="mr-2 my-0 py-0" :disabled="!hasPremium || sectionsNotCanBlocked.includes(item.name)" v-model="item.auto_update" hide-details value :ripple="false"></v-checkbox>
                              <label :for="item.name" :title="item.help_text">- {{ item.display_name }}</label>
                           
                        v-row.ma-0(v-if="!idsBussines")
                          v-col.text-14(sm="12")
                            .d-flex.align-center.pa-2.justify-center
                              button.btnBlue(@click="saveBlockedSections(false)") #[translate Guardar Cambios]
            edited-ok(:show="showOk" @update:show="updateShow")
              p.mt-3.py-3.text-center 
                strong(v-if="sections && sections.some(e => e.auto_update)") Bloqueadas las secciones correctamente.
                strong(v-else ) Desbloqueadas las secciones correctamente.
</template>

<script>
import SkeletonCard from "@/components/common/skeletonCard.vue";
import SrvLocal from "@/services/local.js";
import BoxNotification from "@/components/local/BoxNotification.vue";
import commonMixins from "@/mixins/common.js";
import localMixins from "@/mixins/local.js";
//FICHAS EDICION
import Newday from "@/components/local/Newday.vue";
import HeaderCenter from "@/components/local/HeadCenter.vue";
import BoxTime from "@/components/local/BoxTime.vue";
import EditedOk from "@/components/modals/EditedOk.vue";

export default {
  props: ["idsBussines", "show", "clickedSaved", "fullyBlocked"],
  components: {
    BoxNotification,
    SkeletonCard,
    HeaderCenter,
    Newday,
    BoxTime,
    EditedOk,
  }, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
  mixins: [commonMixins, localMixins],
  data() {
    return {
      showEdit: true, // TEMPORAL PARA SUBIDAS A PRO
      dataProfile: [],
      messageError: null,
      loadingSync: false,
      loadingImportGoogle: false,
      sections: null,
      showOk: false,
      sectionsNotCanBlocked: ["storefrontAddress", "latlng"],
      img: {},
    };
  },
  async created() {
    this.$store.commit("LOADING", true);
    this.loading = true;
    this.center = Number(this.$store.getters.getNode);
    await this.getLocations();
    this.loading = false;
    this.$store.commit("LOADING", false);
  },
  watch: {
    filterApply: function () {
      this.getLocations();
    },
    show: function (newVal) {
      if (newVal) {
        this.sections = null;
        this.getLocations();
      }
    },
    clickedSaved: function (newVal) {
      if (newVal) {
        this.saveBlockedSections(false);
      }
    },
  },
  computed: {
    allChecked() {
      let items = this.sections.filter((e) => !this.sectionsNotCanBlocked.includes(e.name));
      if (items.every((e) => e.auto_update)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    updateShow(event) {
      this.showOk = event;
      this.$emit("close");
    },
    selectOrDeselectAll($event) {
      if (this.hasPremium) {
        this.sections.filter((e) => !this.sectionsNotCanBlocked.includes(e.name)).map((e) => (e.auto_update = $event));
      }
    },
    async saveBlockedSections(all) {
      if (this.hasPremium) {
        const sectionsFiltered = this.sections
          .filter((e) => !this.sectionsNotCanBlocked.includes(e.name))
          .map((e) => {
            return { name: e.name, auto_update: all ? true : e.auto_update };
          });
        if (!this.idsBussines) {
          const res = await SrvLocal.setStatusLocationSectionFields(this.center, { center: this.center, values: sectionsFiltered });
          if (res.status === 200) {
            if (all) {
              this.getLocations();
            }
            this.showOk = true;
          }
        } else {
          let data = { block_details: { center_ids: this.idsBussines, values: sectionsFiltered }, update_type: "BLOCK" };
          const res = await SrvLocal.setMultipleBatchUpdate(data);
          if (res.status === 200) {
            if (all) {
              this.getLocations();
            }
            this.showOk = true;
          }
        }
      }
    },
    async getLocations() {
      let id = this.center;
      if (this.idsBussines) {
        id = this.idsBussines[0];
      }
      const resSections = await SrvLocal.getLocationSectionFields(id);
      if (resSections.status === 200) {
        if (this.idsBussines) {
          resSections.data.map((e) => (e.auto_update = false));
        }
        this.sections = resSections.data.sort((a,b) => {
          if(a.display_name.toLowerCase() < b.display_name.toLowerCase()) return -1;
          if(a.display_name.toLowerCase() > b.display_name.toLowerCase()) return 1;
          return 0;
        })
        this.sections = this.sections.filter(e => !this.sectionsNotCanBlocked.includes(e.name))
      }
    },
  },
};
</script>

<style lang="scss">
.notShadow {
  box-shadow: none;
}
</style>
