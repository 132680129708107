<template lang="pug">
div.margin-bottom-0.col-6
    skeleton-card(v-if="isLoading")
    v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading")
        .d-flex.headTitleGrid
            .block-title.titleGrid
                translate.mb-0.title-card Webs of
                span.ml-1.tDelivery Delivery
            .block-help.ml-auto
                v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="40" nudge-left="10" content-class="tooltip-help tooltip-help-left")
                    template(v-slot:activator="{ on, attrs }")
                        img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                    span {{helpDeliveryWebs}}
                v-icon.mb-1(v-if="typeNode!=='group'" @click="goFeedback('delivery')") mdi-chevron-right

        .card-content
            .row.text-left
                #tableWebDelivery.tableGlobal
                    div.headerTableRanking.row.ma-0
                        div.headerItem.col-name
                            p
                        div.headerItem.col-nopiniones
                            p.mb-0 Pedido
                        div.headerItem.col-nopiniones
                            p.mb-0 Opiniones
                        div.headerItem.col-nopiniones
                            p.mb-0 Comentarios
                        div.headerItem.col-nlocales
                            p.mb-0 Total Opiniones
                        div.headerItem.col-nlocales
                            p.mb-0 (Puntuación / Satisfacción)
                        div.headerItem.col-puntuacion
                            p.mb-0 (Puntuación / Satisfacción Web)

                    div.contentTableRanking.row.ma-0
                        p.ml-3(v-if="!data && !data[0].delivery_rating") #[translate No data]
                        div.itemContent.col-12.pa-0(v-for="(item, index) in data[0].delivery_rating" :key="index")
                            div.item.col-name.d-flex
                                .boxLogo
                                    img(:src="item.logo")
                                span.align-self-center.pl-1 {{item.name}}
                            div.item.col-nopiniones.text-center
                                span.mb-0.sNumber(v-if="item.n_orders") {{ item.n_orders || 0 }}
                                span.mb-0.sNumber(v-else) -
                                v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                    template(v-slot:activator="{ on, attrs }")
                                        span.arrow.ml-4(v-bind="attrs" v-on="on" :class="getArrow(item.n_orders, item.tend_n_orders)")
                                    span {{ item.tend_n_orders | formatNumber }}
                            div.item.col-nopiniones.text-center
                                span.mb-0(v-if="item.count") {{ item.count | formatNumber }}
                                span.mb-0(v-else) -
                                v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                    template(v-slot:activator="{ on, attrs }")
                                        span.arrow.ml-4(v-bind="attrs" v-on="on" :class="getArrow(item.count, item.tend_count)")
                                    span {{ item.tend_count | formatNumber }}
                            div.item.col-nopiniones.text-center
                                span.mb-0.sNumber(v-if="item.text_count") {{ item.text_count | formatNumber }}
                                span.mb-0.sNumber(v-else) -
                                v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                    template(v-slot:activator="{ on, attrs }")
                                        span.mb-0.arrow.ml-4(v-bind="attrs" v-on="on" :class="getArrow(item.text_count, item.tend_text_count)")
                                    span {{ item.tend_text_count | formatNumber }}
                            div.item.col-nlocales.text-center
                                span.mb-0.pr-2(v-if="item.h_n_reviews") {{item.h_n_reviews | formatNumber}}     
                                span.mb-0.pr-2(v-else) -                               
                            div.item.col-puntuacion.text-center
                                span.mb-0.pr-1(v-if="item.site_rating") {{ item.site_rating | formatNumber }}
                                span.mb-0.pr-1(v-else) -
                                v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                    template(v-slot:activator="{ on, attrs }")
                                        span.mb-0.arrow.ml-4(v-bind="attrs" v-on="on" :class="getArrow(item.site_rating, item.tend_site_rating)")
                                    span {{ item.tend_site_rating | formatNumber }}
                            div.item.text-center(style="margin:auto")
                                div.d-flex(v-if="item.code === 'GLV'")
                                    div.d-flex.justify-center.items-center.mr-1
                                        img(:src="img.imgRatingGlovoRegular" style="width:20px;height:20px")
                                        span.text-12 {{item.count_positive | formatNumber }}
                                    div.d-flex.justify-center.items-center
                                        img(:src="img.imgRatingBad" style="width:20px;height:20px")
                                        span.text-12 {{item.count_negative | formatNumber }}
                                span.mb-0.sNumber(v-else)  {{ item.h_site_rating | formatNumber }}

</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import commonMixins from "@/mixins/common.js";
export default {
    props: ['data'],
    mixins: [commonMixins],
    components: { SkeletonCard },
    data() {
        return {
            helpDeliveryWebs: this.$gettext('Depending on the selected period and the filters, the data of the Delivery aggregators where opinions have been received during that period are displayed and the trend with respect to the same period of the previous year is calculated. It is analyzed about the group of restaurants that marks the selection in the left menu'),
            img: {
                imgRatingBad: require('@/assets/img/glovo_rating_bad.svg'),
                imgRatingGlovoRegular: require('@/assets/img/rating_regular.png'),
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.tableGlobal {
    .headerTableRanking {
        .headerItem {
            text-align: center;
            font-size: 10px;
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                cursor: pointer;
            }
        }
    }

    .contentTableRanking {
        .itemContent {
            text-align: center;
            font-size: 0.875rem;
            border-top: 1px solid #9797973d;
            height: 50px;
            display: flex;
            align-items: center;

            &:last-child {
                border-bottom: 1px solid #9797973d;
            }
        }

    }
}

#tableWebDelivery {

    .col-name {
        max-width: 20%;
        flex: 0 0 20%;
        padding: 0 10px;
        text-align: left;

        img {
            height: 28px;
        }

        .boxLogo,
        span {
            display: inline-block;
        }

        .boxLogo {
            width: 27%;
        }
    }

    .col-nopiniones {
        max-width: 13%;
        flex: 0 0 13%;
        padding-right: 2%;
        justify-content: center;
    }

    .col-nlocales {
        max-width: 13%;
        flex: 0 0 13%;
        padding-right: 2%;

        .sNumber {
            min-width: 50%;
            display: inline-block;
        }

        .arrow {
            margin-left: 9%;
        }
    }

    .col-puntuacion {
        max-width: 13%;
        flex: 0 0 13%;

        .sNumber {
            min-width: 50%;
            display: inline-block;
        }

        .arrow {
            margin-left: 9%;
        }
    }
}
</style>
