<template lang="pug">
    div
      gp-filter-top-analysis#filtersFixed(@getData="getProvincesRanking" :type="'provincesRank'")
      v-container.gastroprice-content.with-menu.d-flex.flex-column(fluid)    
        .block-content.row.ma-0
          v-container.table-data.ma-0.pa-0(v-if="isLoading" fluid)
            v-skeleton-loader(type="table")
          v-container.table-data.ma-0.pa-0.flipped-block.flipped(v-if="!isLoading" style="position: absolute; top: 124px;background-color: transparent;" fluid)
            .table-content
              .header.d-flex.headerProvince(style="height:62px" v-if="data && data.header")
                v-col.fixed-width.p-relative( v-for="(item, index) in data.header.slice(0, 1)")
                  translate.font-weight-med.font-14(v-if="index == 0")
                  span.font-weight-med.font-14 {{item}}
                  svg.sort-icon.inactive.cursor-pointer(v-if="index>0" xmlns="http://www.w3.org/2000/svg" width="12.828" height="12" viewBox="0 0 12.828 12" :class="{'activeSort': index === idSortCol, 'sortAsc': sortDesc !== false }" @click="sortCol(index)")
                    g(id="download" transform="translate(11.414 11) rotate(180)")
                      path(id="Trazado_4786" data-name="Trazado 4786" d="M0,0,5,5l5-5" transform="translate(0 5)" fill="none" stroke="#4a90e2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2")
                      line(id="Línea_1029" data-name="Línea 1029" y1="10" transform="translate(5)" fill="none" stroke="#4a90e2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2")
              .content-item.pr-5.d-flex.align-center.border-bottom-gray(v-if="data" v-for="(row, index) in data.rows" :class="{'bg-ligth-gray': index % 2 != 0}")
                v-col.fixed-width( v-for="(item, i) in row.slice(0, 1)")
                  span {{item | formatNumber(2,2)}}
                  span(v-if="item && i > 0") €
          v-container.items.table-data.ma-0.pa-0.flipped-block.flipped(v-if="!isLoading" style="margin-left: 220px !important; top: 64px;" fluid)
            .table-content
              .header.border-b-dark-gray.d-flex.headerColumnsData(v-if="data && data.header")
                v-col.fixed-width.p-relative.border-col-rigth.text-center.pr-5( v-for="(item, index) in data.header.slice(1, data.header.lenght)" )
                  translate.font-weight-med.font-14(v-if="index == 0")
                  span.font-weight-med.font-14 {{item}}
                  span.sort-icon.inactive.cursor-pointer(:class="{'activeSort': index === idSortCol, 'sortAsc': sortDesc !== false }" @click="sortCol(index)") 
                    v-icon mdi-arrow-up
                  //svg.sort-icon.inactive.cursor-pointer(xmlns="http://www.w3.org/2000/svg" width="12.828" height="12" viewBox="0 0 12.828 12" :class="{'activeSort': index === idSortCol, 'sortAsc': sortDesc !== false }" @click="sortCol(index)")
                    g(id="download" transform="translate(11.414 11) rotate(180)")
                      path(id="Trazado_4786" data-name="Trazado 4786" d="M0,0,5,5l5-5" transform="translate(0 5)" fill="none" stroke="#4a90e2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2")
                      line(id="Línea_1029" data-name="Línea 1029" y1="10" transform="translate(5)" fill="none" stroke="#4a90e2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2")
              .content-item.d-flex.align-center.border-bottom-gray(v-if="data" v-for="(row, index) in data.rows" :class="{'bg-ligth-gray': index % 2 != 0}")
                v-col.fixed-width.border-col-rigth.text-center.font-weight-med( v-for="(item, i) in row.slice(1, row.lenght)")
                  span {{item | formatNumber(2,2) }} 
                  span(v-if="item") €

</template>

<script>
import GpFilterTopAnalysis from '@/components/filters/filter-top-analysis.vue'
import SrvGastroPricing from '@/services/gastropricing.js'

export default {
  components: {GpFilterTopAnalysis},
  name: 'aggregatorsRank',
  data() {
    return {
      data: [],
      idSortCol: '',
      sortDesc: true
    }
  },
  created(){
  },
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    }
  },
  methods: {
    setHeightHeaderProvince() {
      let headerData = document.querySelector('.headerColumnsData')
      if (headerData) {
        let headerProvince = document.querySelector('.headerProvince')
        let addTwoPixels = 0
        if (window.innerWidth > 1400) {
          addTwoPixels = 2 
        }
        headerProvince.setAttribute('style','height:' + (headerData.offsetHeight + addTwoPixels) + 'px;')
      }
    },
    getProvincesRanking(data){
      this.$store.commit('LOADING', true)
      SrvGastroPricing.getProvincesRanking(data.brand, data.filters)
      .then(res => {
          if (res.status === 200) {
            this.data = res.data.table
            this.scrollWithMouse()
            this.$store.commit('LOADING', false)
            this.$nextTick(() =>{
              this.setHeightHeaderProvince()
            })
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    sortCol(id){
      if(id === this.idSortCol){
        this.sortDesc = !this.sortDesc
      }
      this.idSortCol = id
      this.sortArray()
    },
    sortArray(){

      this.data.rows = this.data.rows.sort((a, b) => {
        return b[this.idSortCol] - a[this.idSortCol]
      })
      if(!this.sortDesc){
        this.data.rows = this.data.rows.reverse()
      }
      return this.data
    },
    scrollWithMouse() {
      setTimeout(() => {
        const slider = document.querySelector('.items');
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener('mousedown', (e) => {
          isDown = true;
          slider.classList.add('active');
          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
          isDown = false;
          slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
          isDown = false;
          slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
          if(!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider.offsetLeft;
          const walk = (x - startX) * 3; //scroll-fast
          slider.scrollLeft = scrollLeft - walk;
        });
      }, 1000)
    }
  },
  mounted () {
    let elementBody = document.querySelector('.gastroprice-content')
    elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
    this.setHeightHeaderProvince()
    this.$nextTick(() =>{
      window.addEventListener('resize', function () {
        let elementBody = document.querySelector('.gastroprice-content')
        if (elementBody) {
          elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
        }
        let headerData = document.querySelector('.headerColumnsData')
        if (headerData) {
          let headerProvince = document.querySelector('.headerProvince')
          let addTwoPixels = 0
          if (window.innerWidth > 1400) {
            addTwoPixels = 2 
          }
          headerProvince.setAttribute('style','height:' + (headerData.offsetHeight + addTwoPixels) + 'px;')
        }
      })
    })
  }
}
</script>
<style lang="scss" scoped>
.fixed-width{
  flex: 0 0 200px;
  width: 200px;
}
.table-content{
  width: fit-content;
  .header{
    .sort-icon{
      position: absolute;
      right: 5px;
      bottom: 5px;
      .v-icon{
        font-size: 16px;
      }
    }
  }
  .content-item{
    height: 42px;
    span{
      font-size: 0.813rem;

    }
  }
}

.flipped-block{
  width: 95%;
  margin: auto;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #498FE1 #e1e1e1;
    //SCROLL
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #498FE1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: #498FE1;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #498FE1;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track:hover{
    background: #E6EAF0;
  }
  &::-webkit-scrollbar-track:active {
    background: #E6EAF0;
  }

}
.flipped, .flipped .table-content{
  transform:rotateX(180deg);
  -ms-transform:rotateX(180deg); /* IE 9 */
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
  height: max-content;
}

.inactive{
  stroke: #CECECE;
  path{
    stroke: #CECECE;
  }
  line{
    stroke: #CECECE;
  }
  &.activeSort{
    path{
    stroke: #4A90E2;
    }
    line{
      stroke: #4A90E2;
    }
    &.sortAsc{
      transform: rotate(180deg);
    }
  }
}

.absolute {
  position: absolute;
}

.border-bottom-gray {
  border-bottom: 1px solid #D9DFE8;  
}

.items {
  cursor: grab;
}
</style>
