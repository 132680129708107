<template lang="pug">
    div
        v-dialog.dialogEdit(v-model="show"
            width="680")
            v-card.dialogEdit
                v-card-text.pb-0
                v-container
                    div.gr-loader-snipper--wrapper(v-if="loading")
                        span.gr-loader-snipper
                    v-row#headerModalEdit.pt-3
                        v-col(cols="10")
                            span #[translate Error]
                        v-col.mt-5(cols="2")
                            img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                    v-row#bodyModalEdit
                        v-col(cols="12")
                            v-container
                                v-row
                                    v-col.text-left.pt-6.pb-4(cols="12")
                                        div.messageError
                                            span {{ message }}
                                    
                    v-row#footerModalEdit
                        v-col(cols="12")
                            button.btnCancelEditModal(@click.stop="show=false") #[translate Cerrar]
</template>
<script>
import commonMixins from '@/mixins/common.js'

export default {
    mixins: [commonMixins],
    props: ['visible','message'],
    data(){
        return {
            loading: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            section: 'data-error',
        }
    },
    computed: {
        show: {
            get () {
                return this.visible
            },
            set (value) {
                if (!value) {
                this.$emit('close')
                }
            }
        },
        showPriceRange () {
            return this.priceRange.length > 0 ? true : false
        }
    }
}
</script>
<style lang="scss" scoped>
    #autocompleteCategories{
        background-color: #fff;
        input{
            font-size: 0.875rem !important;
        }
    }
    .theme--light.v-text-field--solo-inverted.v-input--is-focused>.v-input__control>.v-input__slot {
        background-color: #fff;
    }
    .messageError{
        background-color: #F5B1B8;
        border: 1px solid #F93549;
        color: #F93549;
        padding:15px;
    }
</style>
