import { render, staticRenderFns } from "./editPhotosMultiple.vue?vue&type=template&id=4ca35087&scoped=true&lang=pug"
import script from "./editPhotosMultiple.vue?vue&type=script&lang=js"
export * from "./editPhotosMultiple.vue?vue&type=script&lang=js"
import style0 from "./editPhotosMultiple.vue?vue&type=style&index=0&id=4ca35087&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ca35087",
  null
  
)

export default component.exports