<template lang="pug">
//ACTUALMENTE SOLO ACTIVA LA OPCIÓN SATISFACCIÓN
v-row#nav(floating fluid)
  v-list
    v-list-item
      span.btnIco(to="#")
        img.logoNav(v-if="nameInstance !== 'gastroagencia'" :src="logoNav" width="28")
    v-divider(v-if="!is_alsea")
    v-list-item(v-if="!isAdverbia && !is_alsea")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          span.btnIco( @click="goGlobal()" :class="{'router-link-active': getIdSectionActive === 0}")
            img(:src="img.global" :width="iconNavWidth" v-bind="attrs" v-on="on")
        span #[translate Global]
    v-divider(v-if="!is_alsea")
    v-list-item(v-if="!is_alsea")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          span.btnIco( @click="goInternet()" :class="{'router-link-active': getIdSectionActive === 1}")
            img(:src="img.internet" :width="iconNavWidth" v-bind="attrs" v-on="on")
        span #[translate Internet]
    v-list-item(v-if="!is_alsea")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          span.btnIco( @click="goFeedBack()" :class="{'router-link-active': getIdSectionActive === 2, 'disabled-link': typeNode === 'group'}")
            img(:src="img.opiniones" :width="iconNavWidth" v-bind="attrs" v-on="on")
        span #[translate Feedback]

    v-list-item(v-if="(['gruposaona','azarbe','healthypoke','dashboard','customers','customersmx','mahalopoke','restalia','demo2','andilana','foodbox'].includes(nameInstance) || $config.domain.indexOf('localhost') > -1) && $config.featureConfig.hpSatisfaction")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          span.btnIco( @click="goSatisfactionHealthyPoke()" :class="{'router-link-active': getIdSectionActive === 3, 'disabled-link': !hasCustomSurveys}")
            img(:src="img.satisfaccion" :width="iconNavWidth" v-bind="attrs" v-on="on")
        span #[translate Satisfaction]

    //v-list-item(v-if="is_alsea")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          span.btnIco( @click="goSatisfaction()" :class="{'router-link-active': getIdSectionActive === 3}")
            img(:src="img.satisfaccion" :width="iconNavWidth" v-bind="attrs" v-on="on")
        span #[translate Satisfaction]

    v-divider(v-if="$config.featureConfig.sectionDelivery && !isGenericEntity && !is_alsea")
    v-list-item(v-if="$config.featureConfig.sectionDelivery && !isGenericEntity && !is_alsea")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          span.btnIco( @click="goDelivery()" :class="{'router-link-active': Number(getIdSectionActive) === 8 }")
            img(:src="img.delivery" :width="iconNavWidth" v-bind="attrs" v-on="on")
        span #[translate Delivery analytics]

    v-list-item(v-if="$config.featureConfig.sectionDelivery && !isGenericEntity && !is_alsea")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          span.btnIco( @click="goDeliveryReviews()" :class="{'router-link-active': Number(getIdSectionActive) === 9, 'disabled-link': typeNode === 'group'}")
            img(:src="img.deliveryReviews" :width="iconNavWidth" v-bind="attrs" v-on="on")
        span Opiniones

    //v-divider(v-if="$config.featureConfig.sectionDelivery")

    v-list-item(v-if="$config.featureConfig.attentionSection && hasAttention")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          span.btnIco( @click="goAttention()" :class="{'router-link-active': getIdSectionActive === 21}")
            img(:src="img.satisfaccion" :width="iconNavWidth" v-bind="attrs" v-on="on")
        span #[translate Attention]
    v-divider(v-if="!is_alsea")
    v-list-item(v-if="$config.featureConfig.showListReports && !is_alsea && hasReports")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          span.btnIco(@click="goReports()" :class="{ 'router-link-active': getIdSectionActive === 4 }")
            img(:src="img.informes" :width="iconNavWidth"  v-bind="attrs" v-on="on")
        span #[translate Reports]

    v-divider(v-if="$config.featureConfig.gastroPricing && (is_alsea || nameInstance === 'amrest' || nameInstance === 'localhost') && hasGP && !isAdverbia")
    v-list-item(v-if="$config.featureConfig.gastroPricing && (is_alsea || nameInstance === 'amrest' || nameInstance === 'localhost') && hasGP && !isAdverbia")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          span.btnIco( @click="goRate()" :class="{'router-link-active': getIdSectionActive === 6 }")
            img(:src="img.rate" :width="iconNavWidth" v-bind="attrs" v-on="on")
        span #[translate Rate Analysis]
    v-list-item(v-if="$config.featureConfig.gastroPricing && (is_alsea || nameInstance === 'amrest' || nameInstance === 'localhost') && hasGP && !isAdverbia")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          span.btnIco( @click="goPromotion()" :class="{'router-link-active': getIdSectionActive === 7 }")
            img(:src="img.promotion" :width="iconNavWidth" v-bind="attrs" v-on="on")
        span #[translate Analysis of Promotion]
    v-divider
    v-list-item(v-if="!is_alsea")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          span.btnIco.relative( @click="goLocal()" :class="{ 'router-link-active': getIdSectionActive === 5 }")
            img(:src="img.local" :width="iconNavWidth" v-bind="attrs" v-on="on")
            span.tickHasNotifications(v-if="hasPremium && $store.getters.getNotificationStats && $store.getters.getNotificationStats.total") {{ $store.getters.getNotificationStats.total }}

        span #[translate Local Marketing]
    v-divider(v-if="!is_alsea && hasGP && !isAdverbia")
    v-list-item(v-if="!is_alsea && nameInstance !== 'amrest' && hasGP && !isAdverbia")
      v-tooltip(right)
        template(v-slot:activator="{ on, attrs }")
          span.btnIco(:to="urlGastroPricing")
            img.logoNav(:src="img.logoGp" width="28" v-bind="attrs" v-on="on")
        span #[translate GastroPricing]


</template>

<script>
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js";
import localMixins from "@/mixins/local.js"
import mixinBrand from '@/mixins/brand.js'
export default {
  mixins: [commonMixins, localMixins, mixinBrand],
  data() {
    return {
      iconNavWidth: 16,
      img: {
        global: require('@/assets/img/nav/nav-estadisticas.svg'),
        internet: require('@/assets/img/nav/nav-internet.svg'),
        opiniones: require('@/assets/img/nav/nav-comentario.svg'),
        satisfaccion: require('@/assets/img/nav/nav-cara.svg'),
        informes: require('@/assets/img/nav/nav-nota.svg'),
        local: require('@/assets/img/nav/nav-casa.svg'),
        cuenta: require('@/assets/img/nav/nav-persona.svg'),
        logo: require('@/assets/img/nav/nav-logo.svg'),
        logoGp: require('@/assets/img/nav/nav-logo-gp.svg'),
        rate: require('@/assets/img/nav/analisis_tarifas.svg'),
        promotion: require('@/assets/img/nav/analisis_promos.svg'),
        delivery: require('@/assets/img/nav/e-bike-2-line.svg'),
        deliveryReviews: require('@/assets/img/nav/delivery-reviews.svg')
      }
    }
  },
  computed: {
    urlGastroPricing() {
      let subdomain = this.$config.domain.slice(0, this.$config.domain.indexOf('.gastroranking.pro'))
      if (subdomain) {
        return `https://${subdomain}.gastropricing.pro`
      }
      return ''
    },
    hasHelpdesk() {
      return window.location.hostname === 'dashboard.gastroranking.pro'
    },
    hasGP() {
      return this.$store.getters.getUserInfo?.config?.has_gp
    },
    hasAttention() {
      return this.$store.getters.getUserInfo?.config?.has_attention && this.$store.getters.getUserInfo?.has_satisfaction_menu
    },
    hasCustomSurveys() {
      return this.$store.getters.getHasCustomSurveys
    },
    getIdSectionActive() {
      return this.$store.getters.getIdSection
    },
    codeParam() {
      return this.$route.params.paramCOD != undefined ? this.$route.params.paramCOD : ''
    },
    typeNode() {
      if (this.$store.getters.getNodeLevel == 9) {
        return 'center';
      } else if (this.$store.getters.getNodeLevel == 1 || this.$store.getters.getNodeLevel == 2 || this.$store.getters.getNodeLevel == 3) {
        return 'brand';
      } else {
        return 'group';
      }
    },
    urlLocalMarketing() {
      let url = 'LocalHome'
      if (this.typeNode === 'center') {
        url = 'LocalRestaurant'
      }
      return url
    }
  },
  methods: {
    cleanNavigationNoTree() {
      this.$store.commit(types.OPEN_SUBNAV, true)
      let paramCod = ''
      let params = this.$route.query
      if (!this.$store.getters.getShowTree) {
        let center = document.querySelector('[data-id="' + this.$store.getters.getNode + '"][data-type="C"]')
        paramCod = center.getAttribute('data-slug') + '-C'
        delete params.no_tree
        this.$store.commit(types.TREE_SHOW_TREE, true)
      } else {
        paramCod = this.codeParam
      }
      return { paramCod, params }
    },
    async goGlobal() {
      this.resetFilters()
      this.setIdSection(0)
      let oParams = this.cleanNavigationNoTree()
      let paramCod = oParams.paramCod
      let params = {}
      await this.$router.push({ name: 'GlobalHome', params: { paramCOD: paramCod }, query: params })
    },
    async goInternet() {
      if (this.$route.path.indexOf('feedback') < 0) {
        this.resetFilters()
      }
      this.setIdSection(1)
      let oParams = this.cleanNavigationNoTree()
      let paramCod = oParams.paramCod
      let params = {}
      await this.$router.push({ name: 'NetworkHome', params: { paramCOD: paramCod }, query: params })
    },
    async goFeedBack() {
      if (this.$route.path.indexOf('network') < 0) {
        this.resetFilters()
      }
      this.setIdSection(2)
      let oParams = this.cleanNavigationNoTree()
      let paramCod = oParams.paramCod
      let params = {}
      await this.$router.push({ name: 'FeedBackHome', params: { paramCOD: paramCod }, query: params })
    },
    async goSatisfaction() {
      this.resetFilters()
      this.setIdSection(3)
      let oParams = this.cleanNavigationNoTree()
      let paramCod = oParams.paramCod
      let params = {}
      await this.$router.push({ name: 'SatisfactionHome', params: { paramCOD: paramCod }, query: params })
    },
    async goSatisfactionHealthyPoke() {
      this.resetFilters()
      this.setIdSection(3)
      let oParams = this.cleanNavigationNoTree()
      let paramCod = oParams.paramCod
      let params = oParams.params
      await this.$router.push({ name: 'HealthyPokeSatisfactionHome', params: { paramCOD: paramCod }, query: params })
    },
    async goLocal() {
      this.resetFilters()
      this.setIdSection(5)
      let oParams = this.cleanNavigationNoTree()
      let paramCod = oParams.paramCod
      let params = oParams.params
      await this.$router.push({ name: this.urlLocalMarketing, params: { paramCOD: paramCod }, query: params })
    },
    async goReports() {
      this.resetFilters()
      this.setIdSection(4)
      let oParams = this.cleanNavigationNoTree()
      let paramCod = oParams.paramCod
      let params = oParams.params
      await this.$router.push({ name: 'ReportsHome', params: { paramCOD: paramCod }, query: params })
    },
    async goRate() {
      this.resetFilters()
      this.setIdSection(6)
      let oParams = this.cleanNavigationNoTree()
      let paramCod = oParams.paramCod
      let params = {}
      await this.$router.push({ name: 'rateAnalysis', params: { paramCOD: paramCod }, query: params })
    },
    async goPromotion() {
      this.resetFilters()
      this.setIdSection(7)
      let oParams = this.cleanNavigationNoTree()
      let paramCod = oParams.paramCod
      let params = {}
      await this.$router.push({ name: 'promotionsAnalysis', params: { paramCOD: paramCod }, query: params })
    },
    async goAttention() {
      this.resetFilters()
      this.setIdSection(21)
      let oParams = this.cleanNavigationNoTree()
      let paramCod = oParams.paramCod
      let params = {}
      await this.$router.push({ name: 'AttentionHome', params: { paramCOD: paramCod }, query: params })
    },
    async goDelivery() {
      if (this.$route.path.indexOf('delivery') < 0) {
        this.resetFilters()
      }
      this.setIdSection(8)
      let oParams = this.cleanNavigationNoTree()
      let paramCod = oParams.paramCod
      let params = {}
      await this.$router.push({ name: 'DeliveryResumeHome', params: { paramCOD: paramCod }, query: params })
    },
    async goDeliveryReviews() {
      if (this.$route.path.indexOf('delivery') < 0) {
        this.resetFilters()
      }
      this.setIdSection(9)
      let oParams = this.cleanNavigationNoTree()
      let paramCod = oParams.paramCod
      let params = {}
      await this.$router.push({ name: 'DeliveryReviewsReviews', params: { paramCOD: paramCod }, query: params })
    },
    // GUARDAMOS EN EL STORE EL IDENFITICADOR DE LA SECCIÓN
    setIdSection: function (id) {
      this.$store.commit(types.UPDATE_SECTION, id);
    },
    resetFilters() {

      this.$store.commit(types.COMMON_TYPES_RESTAURANT_SELECTED, [])
      this.$store.commit(types.COMMON_REGION_SELECTED, [])
      this.$store.commit(types.COMMON_TYPES_SELECTED, null)
      this.$store.commit(types.COMMON_SITES_SELECTED, [])
      this.$store.commit(types.COMMON_SITES_DELIVERY_SELECTED, [])
    }
  }
}
</script>

<style scoped lang="scss">
#nav {
  background-color: #F93549;
  height: 100%;
  padding: 5px;
  margin: 0;

  .v-list {
    display: block;
    background-color: transparent;
    padding: 0;
    width: 100%;
    position: relative;

    .v-divider {
      background-color: #000;
      margin: 0 auto 15px auto;
      max-width: 60%;
    }

    .v-list-item {
      align-self: flex-start;
      text-align: center;
      padding: 0;

      &.help-desk-chat {
        bottom: 50px;
        position: absolute;
        width: 100%;
      }

      &.help-desk-form {
        bottom: 0;
        position: absolute;
        width: 100%;
      }

      a,
      span {
        cursor: pointer;

        &.btnIco {
          background-color: transparent;
          padding: 0;
          min-width: 0;
          box-shadow: inherit;
          margin: 0 auto;
          border-radius: 0;

          img {
            transition: all ease 0.3s;

            &:hover {
              filter: grayscale(-36);
              filter: hue-rotate(0deg) brightness(0%);
            }

            &.logoNav:hover {
              filter: inherit;
            }
          }
        }

        &.router-link-active {
          img {
            filter: grayscale(-36);
            filter: hue-rotate(0deg) brightness(0%);
          }
        }

        &.disabled-link {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }

  .tickHasNotifications {
    border-radius: 8px;
    background-color: #FFC400;
    color: #000;
    display: block;
    top: -8px;
    position: absolute;
    right: -16px;
    padding: 2px 4px;
    font-size: 10px;
  }
}
</style>
