<template lang="pug">
  p(style="padding: 30px; color: white")
    a(href="grp://restaurants.gastroranking.pro") Open GastroRanking PRO

</template>

<script>

export default {
  name: "OpenApp"
}
</script>

<style lang="scss">
</style>
