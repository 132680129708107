<script>
import Chart from 'chart.js'
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
import mixinBrand from '@/mixins/brand.js'

export default {
  name: "Competence",
  extends: Bar,
  mixins: [reactiveProp, mixinBrand],
  props:['chartData'],
  data: () => ({
    type: 'bar',
    name: 'Competence',
    
  }),
  watch: {
    chartData () {
      this.renderChart(this.chartData, this.options)
      
    }
  },
  computed: {
    options() {
      return {        
        chartArea: {
        backgroundColor: '#EBEBEB'
    },
    tooltips: {
            callbacks: {
                label: function(tooltipItem) {
                    var label;
                    label = new Intl.NumberFormat('de-DE',{maximumFractionDigits: 1}).format(tooltipItem.yLabel);
                    return label;
                }
            }
        },
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          left: 40,
          right: 40,
          top: 40,
          bottom: 10
        }
      },
      legend: {
          display: false,
      },
      scaleLabel: {
        fontColor: '#000',
        fontSize: '56px'
      },
      plugins: {
        
        datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: function(value) {
                return new Intl.NumberFormat('de-DE',{maximumFractionDigits: 1}).format(value);
            },
            color: '#666',
            font: {
                weight: 'bold'
            }
        }
        
      },
      
      scales: {
            xAxes: [{
                stacked: true,
                gridLines:{
                  display: false,
                    color: '#EBEBEB'
                },
                barThickness: 30,
                maxBarThickness: 30,
            }],
            yAxes: [{
                stacked: true,
                gridLines:{
                    color: '#fff'
                },
              ticks: {
                  min: 0,
                  max: this.isAdverbia ? 5 : 10
              }
            }]
      },      
    }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
    Chart.pluginService.register({
        beforeDraw: function (chartInstance) {
            if (chartInstance.config.options.chartArea && chartInstance.config.options.chartArea.backgroundColor) {
                var ctx = chartInstance.chart.ctx;
                ctx.save();
                ctx.fillStyle = chartInstance.config.options.chartArea.backgroundColor;
                ctx.fillRect(0, 0, ctx.canvas.clientWidth, ctx.canvas.clientHeight);
                ctx.restore();
            }
            var yScale = chartInstance.scales["y-axis-0"];
    var canvas = chartInstance.chart;
    
    if (chartInstance && chartInstance.config && chartInstance.config.data && chartInstance.config.data.lines) {
      let yValue
      //LINE SECTOR
      yValue = yScale.getPixelForValue(chartInstance.config.data.lines.sector.rating_global);
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(canvas.chartArea.left, yValue);
      ctx.lineTo(canvas.chartArea.right, yValue);
      ctx.strokeStyle = '#000';
      ctx.stroke();
      //LINE BRAND
      yValue = yScale.getPixelForValue(chartInstance.config.data.lines.brand.rating_global);
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(canvas.chartArea.left, yValue);
      ctx.lineTo(canvas.chartArea.right, yValue);
      ctx.strokeStyle = 'red';
      ctx.stroke();
      return
    }
        }
    });
    
  }
}
</script>
