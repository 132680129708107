<template lang="pug">
//- div.margin-bottom-0( :class="[{'opacity-50':!hasSurvey},col]")
div.margin-bottom-0(class="col")
    skeleton-card(v-if="isLoading")
    v-card.gridFull.ma-0(v-if="!isLoading && dataSummary.length" :class="{'borderDelivery': typeOpinionSelected === 'delivery'}")
        .d-flex.headTitleGrid
            .block-title.titleGrid
                translate.mb-0.title-card {{title }}
                span.ml-1.tDelivery(v-if="typeOpinionSelected === 'delivery'") Delivery
            .block-help.ml-auto
                v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="20" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                    template(v-slot:activator="{ on, attrs }")
                        img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                    span {{helpFeeling}}

        .card-content
            .row.text-left
                #tableKantar.tableGlobal
                    div.headerTableRanking.row.ma-0
                        div.headerItem.col-name
                            p
                        div.headerItem.col-quiz
                            p.mb-0 #[translate Quiz]
                        // div.headerItem.col-satisfaccion
                        //     p.mb-0 #[translate Satisf.]

                    div.contentTableRanking.row.ma-0
                        p.ml-3(v-if="!dataSummary") #[translate No data]
                        div.itemContent.col-12.pa-0(v-for="(item, index) in dataSummary" :key="index")
                            div.item.col-name.text-left.pl-2(:class="getTextBlackIfGroup(item)")
                                v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                    template(v-slot:activator="{ on, attrs }")
                                        span.ellipsis1Col(v-if="index == 0" v-bind="attrs" v-on="on") {{item.name}}
                                        span.link-text.ellipsis1Col(v-if="index>0" @click="goItemNextLevel(item)" v-bind="attrs" v-on="on") {{item.name}}
                                    span(v-if="index == 0") {{item.name}}
                                    span(v-if="index>0" ) {{item.name}}
                            div.item.col-quiz.text-right
                                span.pr-4 {{item.total | formatNumber}}
                                // v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                //     template(v-slot:activator="{ on, attrs }")
                                //         p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(item.survey_count, item.tend_survey_count)")
                                //     span {{ item.total | formatNumber }}
                                // div.item.col-satisfaccion
                                //     span.pr-4(:class="getColorNote(item.survey_rating_global)") {{item.survey_rating_global|formatNumber(1,1)}}
                                //     v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                //         template(v-slot:activator="{ on, attrs }")
                                //             p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(item.survey_rating_global, item.tend_survey_rating_global)")
                                //         span {{ item.tend_survey_rating_global | formatNumber }}

                        // div.itemContent.col-12.pa-0(v-if="typeNode === 'center' && dataSummary[0].chain_data")
                        //     div.item.col-name.media-cadena
                        //         translate Chain mean
                        //     div.item.col-quiz.text-right
                        //         span.pr-4 {{dataSummary[0].chain_data.survey_count}}
                        //         v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                        //             template(v-slot:activator="{ on, attrs }")
                        //                 p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(dataSummary[0].chain_data.survey_count, dataSummary[0].chain_data.tend_survey_count)")
                        //             span {{ dataSummary[0].chain_data.tend_survey_count | formatNumber }}
                        //     div.item.col-satisfaccion
                        //         span.pr-4(:class="getColorNote(dataSummary[0].chain_data.survey_rating_global)") {{dataSummary[0].chain_data.survey_rating_global|formatNumber(1,1)}}
                        //         v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                        //             template(v-slot:activator="{ on, attrs }")
                        //                 p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(dataSummary[0].chain_data.survey_rating_global, dataSummary[0].chain_data.tend_survey_rating_global)")
                        //             span {{ dataSummary[0].chain_data.tend_survey_rating_global | formatNumber }}

</template>

<script>
import commonMixins from "@/mixins/common.js"
import SkeletonCard from '@/components/common/skeletonCard.vue'

export default {
    props: {
        'dataSummary': [],
        'title': {
            type: String,
            required: true,
        },
        'col': {
            type: String,
            required: false,
            default: '.col-3'
        }
    },
    mixins: [commonMixins],
    components: { SkeletonCard },
    data() {
        return {
            helpFeeling: this.$gettext('From the semantic analysis of opinions, we obtain the sentiment data and its trend compared to the same selected period of the previous year.')
        }
    }
}
</script>

<style lang="scss" scoped>
#tableKantar {
    .col-name {
        max-width: 43.33%;
        flex: 0 0 43.33%;
        padding-left: 10px;
        text-align: left;

        &.media-cadena {
            color: #979797;

            span {
                letter-spacing: -0.02px;
            }
        }
    }

    .col-quiz {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .col-satisfaccion {
        max-width: 28.33%;
        flex: 0 0 28.33%;
        margin-right: 2%;
        justify-content: center;
    }
}
</style>