<template lang="pug">
div.px-2.ma-0
  v-row.px-2.ma-0
    div.margin-bottom-0.col-12(v-if="isLoading")
      skeleton-card
    div.margin-bottom-0.col-6(v-for="(item, index) in dataGps" :class="{'col-12': index === 0}")

      v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading")
        .d-flex.headTitleGrid
          .block-title.titleGrid
            translate.mb-0.title-card(v-if="index === 0") Gastroranking Promoter Score
            translate.mb-0.title-card(v-else) GPS
            span.text-regular &nbsp; {{ item.name }}
          .block-help.ml-auto
            v-tooltip(v-if="index === 0" left color="#222226" open-on-click=true max-width="550" nudge-bottom="245" nudge-left="10" content-class="tooltip-help tooltip-help-left")
              template(v-slot:activator="{ on, attrs }")
                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
              div
                span #[translate The GastroRanking Promoter Score (GPS) is an indicator analogous to the NPS (Net Promote Score) and serves to measure customer loyalty and satisfaction. It works by analyzing the probability that a person will recommend the restaurant. But instead of using surveys, use the notes that customers leave on the Internet.]
                br
                span #[translate In this way we define]:
                ul
                  li #[translate Rating from 1 to 3 stars: They are detractors. Customers who not only won't recommend, but probably won't speak highly of the restaurant. There are studies that indicate that a high value of this indicator is directly related to the growth capacity of the business]
                  li #[translate 4-star rating: They are neutral customers. They have left satisfied, but have not had a "Wow!" to assure us that they will speak highly of the restaurant]
                  li #[translate 5 star rating. They are considered promoters. They are very satisfied customers who are highly likely to recommend the restaurant]
                span #[translate The GPS is a very useful indicator to obtain a very quick view of the customer experience in any restaurant and to follow its evolution allows to easily identify the impact of the measures adopted to improve said experience. The importance of this KPI lies in the fact that there are empirical studies that directly relate the NPS with the growth capacity of a business. The higher the value, the more opportunity for growth.]
                br
                span #[translate And the scale of values shown is as follows]:
                ul
                  li #[translate -100% to 0.50%: Red]
                  li #[translate 0.51% to 25.0%: Orange]
                  li #[translate Above 25.0%: Green]
                span #[translate It is analyzed about the group of restaurants that marks the selection in the left menu]
        .card-content
          .row
            v-col(:class="{'col-4': index === 0, 'col-5': index !== 0 }")
              .numberOpinion.text-body-2
                translate Total n. of opinions:
                span.font-weight-med.text-big &nbsp; {{ item.count_rated }}
              .vsPaAndVsaa
                div
                  v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                    template(v-slot:activator="{ on, attrs }")
                      span.icoStatus(v-bind="attrs" v-on="on" :class="getArrow(item.count_rated, item.tend_count_rated)")
                    span {{ item.tend_count_rated | formatNumber }}
                  span.caption(:class="{'cNegative': item.count_rated - item.tend_count_rated < 0, 'cPositive': item.count_rated - item.tend_count_rated > 0 }") {{ item.count_rated - item.tend_count_rated }};  {{ item.count_rated_percent | formatNumber }}%
                  span.caption vs p.a.

              div.gastroRankingPromoterScorePercent(v-if="index !== 0")
                .percent(:class="getColorPercent(item.gps)")
                  span {{ getPercentGrPromotterPretty(item.gps) }}%
                  //img(:src="getImgArrow(item.gps - item.tend_gps)")
                .label.caption
                  translate GastroRanking Promoter Score (GPS)

            v-col(:class="{'col-3': index === 0, 'col-7': index !== 0 }")
              .boxDetractoresAndPromotores
                .detractores
                  .boxFace
                    img(:src="imgCommons.detractoresFace"  :style="'width:' + getWidthFace(item.negative_percent,index)")
                  .peopleInfo
                    img(:src="img.detractoresPeople")
                    .lTitle.font-weight-med.cNegative
                      translate Detractors
                    .data(:class="{'text-body-1': index === 0, 'text-body-2': index !== 0 }")
                      strong {{ item.count_negative }}
                      span ({{ item.negative_percent | formatNumber }}%)

                .promotores
                  .boxFace
                    img(:src="img.promotoresFace" :style="'width:' + getWidthFace(item.positive_percent,index)" )
                  .peopleInfo
                    img(:src="img.promotoresPeople")
                    .lTitle.font-weight-med.cPositive
                      translate Promoters
                    .data(:class="{'text-body-1': index === 0, 'text-body-2': index !== 0 }")
                      strong {{ item.count_positive }}
                      span ({{ item.positive_percent | formatNumber }}%)
            v-col.col-5(v-if="index === 0")
              .boxGaugeTwoColorsAndGrPromotorScorePercent
                chart-gauge-two-colors.chartGaugeTwoColors(:percent="item.gps")
                div.gastroRankingPromoterScorePercent
                  .percent(:class="getColorPercent(item.gps)")
                    span {{ getPercentGrPromotterPretty(item.gps) }} %
                    //img(:src="getImgArrow(item.gps - item.tend_gps)")
                  .label.caption
                    translate GastroRanking Promoter Score (GPS)
              .boxBrandPvsPaAndPvsPaA
                div
                  v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                    template(v-slot:activator="{ on, attrs }")
                      span.icoStatus(v-bind="attrs" v-on="on" :class="getArrow(item.gps, item.tend_gps)")
                    span {{ item.tend_gps | formatNumber }}
                  span.caption {{ item.gps_diff | RoundedNumber }}
                  span.caption p vs P.A.

    gps-by-site(:dataGps="dataGps" :isDelivery="true")

    div.margin-bottom-0.col-12(v-if="dataGps.length")
      v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading")
        .d-flex.headTitleGrid
          .block-title.titleGrid
            translate.mb-0.title-card Evolution GPS
          .block-help.ml-auto
            v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="30" nudge-left="10" content-class="tooltip-help tooltip-help-left")
              template(v-slot:activator="{ on, attrs }")
                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
              span {{helpEvolutionGPS}}
        .card-content
          .row
            v-col.col-12
              chart-line(:chartData="chartdata")
</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import SrvDeliveryGPS from '@/services/delivery-gps'
import chartLine from '@/components/charts/lineYear.vue'
import chartGaugeTwoColors from '@/components/charts/GaugeTwoColors.vue'
import commonMixins from "@/mixins/common.js"
import GpsBySite from '@/components/global/GpsBySite.vue'

export default {
  mixins: [commonMixins],
  components: { chartLine, SkeletonCard, chartGaugeTwoColors, GpsBySite }, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
  data() {
    return {
      deliveryColors: {
        'Just Eat': '#FF8000',
        'Glovo': '#FFCC1B',
        'Uber Eats': '#06C167'
      },
      dataGps: [],
      HeaderCategories: [],
      data: [],
      chartdata: [],
      img: {
        promotoresFace: require('@/assets/img/promotoresCara.svg'),
        detractoresPeople: require('@/assets/img/detractoresPeople.svg'),
        promotoresPeople: require('@/assets/img/promotoresPeople.svg'),
        arrowNegative: require('@/assets/img/flecha_down.svg'),
        arrowPositive: require('@/assets/img/flecha_up.svg')
      },
      helpEvolutionGPS: this.$gettext('It consists of the evolution of the GPS over time in order to be able to evaluate the impact of the measures adopted on customer satisfaction measured through online evaluations and following the NPS criteria, as explained in the previous graph.')
    }
  },
  created() {
    this.$store.dispatch('getAllSitesOpinions')
    this.$store.commit('LOADING', true)
    if (this.$store.getters.getNode > 0) {
      this.getGpsFeeling()
    }
  },
  watch: {
    filterApply: function () {
      this.getGpsFeeling()
    }
  },
  methods: {
    getPercentGrPromotterPretty(gps) {
      let result = gps
      if (result > 0) {
        result = '+ ' + this.$options.filters.formatNumber(result)
      }
      return this.$options.filters.formatNumber(result)
    },
    getImgArrow(valor) {
      if (valor > 0) {
        return this.img.arrowPositive
      } else {
        return this.img.arrowNegative
      }
    },
    getWidthFace(num, index) {
      let widthFace = 0
      let divi = 1.2

      if (index !== 0) {
        divi = 1.5
      }
      widthFace = num / divi < 10 ? 10 : num / divi

      return widthFace + '%'
    },
    getGpsPercent(gps, tend) {
      return gps - tend
    },
    getGpsFeeling() {
      this.$store.commit('LOADING', true)
      SrvDeliveryGPS.getGpsFeeling()
        .then(res => {
          if (res.status === 200) {
            this.dataGps = res.data
            if (this.typeNode === 'center') {
              this.dataGps = [res.data]
            }
            this.generateDatasets();
            this.$store.commit('LOADING', false)
          }
        }, () => {
        })
    },
    getGpsValues: function (year) {
      //return Number.parseFloat(x).toFixed(2);
      let gps = [];
      for (let month in year) {
        gps.push(year[month].gps)
      }
      return gps
    },
    // GENERAMOS CHARDATA PARA LAS GRAFICAS
    generateDatasets: function () {
      let datasets = [];

      // Media
      datasets.push({
        label: 'Media Delivery',
        backgroundColor: this.colours[0],
        borderColor: this.colours[0],
        fill: false,
        data: this.getGpsValues(this.dataGps[0].year_gps),
        dataTotal: this.dataGps[0].year_gps
      })

      let labels = []

      for (let brand in this.dataGps[0]['sites']) {
        datasets.push({
          label: this.dataGps[0]['sites'][brand].name,
          backgroundColor: this.deliveryColors[this.dataGps[0]['sites'][brand].name],
          borderColor: this.deliveryColors[this.dataGps[0]['sites'][brand].name],
          fill: false,
          data: this.getGpsValues(this.dataGps[0]['sites'][brand].year_gps),
          dataTotal: this.dataGps[0]['sites'][brand].year_gps
        })
      }

      for (let index in this.dataGps[0]['year_gps']) {
        const date = this.$moment(this.dataGps[0]['year_gps'][index].month)
        labels.push(`${this.$gettext(date.format('MMM'))} ${date.format('YYYY')}`)
      }

      this.chartdata = { labels, datasets }
    },
  },

}
</script>
<style lang="scss" scoped>
.v-application .gastroRankingPromoterScorePercent .caption {
  letter-spacing: 0.01em !important;
}

.col-12 {
  .gastroRankingPromoterScorePercent {
    margin-top: 0;
  }

  .boxDetractoresAndPromotores {
    .boxFace {
      height: 56px;
    }

    .peopleInfo {
      img {
        width: 48px;
      }
    }
  }

  .boxGaugeTwoColorsAndGrPromotorScorePercent {
    width: 65%;
    vertical-align: middle;
    display: inline-block;
  }

  .boxBrandPvsPaAndPvsPaA {
    width: 33%;
    vertical-align: middle;
    display: inline-block;
  }
}

.v-card {
  .card-content {
    .header {
      .text {
        color: #161616a4;
      }
    }

    .content-row {
      color: #161616;
      height: 50px;
    }
  }

}

.gridFull {
  .titleGrid {
    .text-regular {
      font-weight: 400;
    }
  }
}

.vsPaAndVsaa {
  margin-top: 15px;

  .icoStatus {
    margin-right: 10px;
  }
}

.boxBrandPvsPaAndPvsPaA {
  margin-top: 15px;

  .icoStatus {
    margin-right: 10px;
  }
}

.gastroRankingPromoterScorePercent {
  margin-top: 28px;

  .percent {
    font-size: 1.75rem;
    text-align: center;

    img {
      height: 21px;
      margin-left: 5px;
    }
  }

  .label {
    color: #52575A;
  }
}

.cPositive {
  color: #89C445;
}

.cPositiveDark {
  color: #89C445;
}

.cNegative {
  color: #FA746E;
}

.cNeutralDark {
  color: #F5A623;
}

.cNegativeDark {
  color: #D61A23;
}

.boxDetractoresAndPromotores {
  display: flex;
  width: 285px;

  .detractores {
    width: 120px;
    margin-right: 26px;
  }

  .promotores {
    width: 120px;
  }

  .boxFace {
    height: 47px;
    padding-bottom: 8px;
    align-items: flex-end;
    display: flex;
    border-bottom: 2px solid rgba(112, 112, 112, 0.3);
    text-align: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .peopleInfo {
    text-align: center;

    .data {
      color: #52575A;
    }
  }
}

.boxGaugeTwoColorsAndGrPromotorScorePercent {
  text-align: center;
}

.chartGaugeTwoColors {
  max-width: 133px;
  margin: 0 auto;
}
</style>
