import LocalHome from '../views/Local/Home.vue'
import LocalStatusTasks from '../views/Local/Pages/StatusTasks.vue'
import LocalList from '../views/Local/Pages/Home.vue'
import LocalRestaurant from '../views/Local/Pages/Restaurant.vue'
import LocalNotifications from '../views/Local/Pages/Notifications.vue'
import LocalConfiguration from '../views/Local/Pages/Configuration.vue'
import LocalMenuProductService from '../views/Local/Pages/LocalMenuProductService.vue'
import LocalPosts from '../views/Local/Pages/Posts.vue'
import LocalServicios from '../views/Local/Pages/Services.vue'

export default {
    path: '/local',
    name: 'LocalHome',
    component: LocalHome,
    redirect: '/local/home/:paramCOD?',
    meta: {Auth: true},
    props: true,
    children: [
      {
        path: 'home/:paramCOD?',
        name: 'LocalList',
        component: LocalList,
        meta: {Auth: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'status-tasks/:paramCOD?',
        name: 'LocalStatusTasks',
        component: LocalStatusTasks,
        meta: {Auth: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'restaurant/:paramCOD?',
        name: 'LocalRestaurant',
        component: LocalRestaurant,
        meta: {Auth: true, isOnlyCenter: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'notifications/:paramCOD?',
        name: 'LocalNotifications',
        component: LocalNotifications,
        meta: {Auth: true, isOnlyCenter: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'configuration/:paramCOD?',
        name: 'LocalConfiguration',
        component: LocalConfiguration,
        meta: {Auth: true, isOnlyCenter: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'analytics/',
        name: 'LocalAnalytics',
        redirect: 'analytics/google/:paramCOD?',
        component: () => import(/* webpackChunkName: "DeliveryReviewsReviews" */ '../views/Local/Pages/Metrics/Home.vue'),
        meta: {Auth: true},
        props(route) {
          return  route.query || {}
        },
        children: [
          {
            path: 'google/:paramCOD?',
            name: 'LocalAnalyticsGoogle',
            component: () => import(/* webpackChunkName: "DeliveryReviewsReviews" */ '../views/Local/Pages/Metrics/Google.vue'),
            meta: {Auth: true},
            props(route) {
              return  route.query || {}
            }
          },
          {
            path: 'meta/:paramCOD?',
            name: 'LocalAnalyticsMeta',
            component: () => import(/* webpackChunkName: "DeliveryReviewsReviews" */ '../views/Local/Pages/Metrics/Meta.vue'),
            meta: {Auth: true},
            props(route) {
              return  route.query || {}
            }
          }
        ]
      },
      {
        path: 'menu-product-service/:paramCOD?',
        name: 'LocalMenuProductService',
        component: LocalMenuProductService,
        meta: {Auth: true, isOnlyCenter: true, disabledForAdverbia: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'posts/:paramCOD?',
        name: 'LocalPosts',
        component: LocalPosts,
        meta: {Auth: true, isOnlyCenter: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'servicios/:paramCOD?',
        name: 'LocalServicios',
        component: LocalServicios,
        meta: {Auth: true, isOnlyCenter: true},
        props(route) {
          return  route.query || {}
        }
      },
    ]
  }