<template lang="pug">
  div.boxSectionHeaderAndBody
    div#navtop(ref="navBarRef" :class="{'noTree': $route.meta.isGP}")
      div.sectionmenu
        div.firstlevel
          router-link(:to="{name: 'FeedBackOpinions', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Read and Reply Opinions]
          router-link(v-if="$config.featureConfig.feedbackKeywords" :to="{name: 'FeedBackKeywords', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Keywords]
          router-link(v-if="$config.featureConfig.feedbackEmployees && hasEmployees" :to="{name: 'FeedBackEmployees', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Employees]
          router-link(v-if="$store.getters.getHasDishKeywords" :to="{name: 'FeedBackDish', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Platos]
          router-link(v-if="$config.featureConfig.feedbackDishRanking && hasDishRanking && !isHotel" :to="{name: 'FeedBackDishRanking', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Ranking de Platos]
          router-link(v-if="hasAutomaticConfigs" :to="{name: 'AutomaticAnswers', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Respuestas automáticas con IA]
          router-link(v-if="isHotel" :to="{name: 'FeedBackBooking', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Reservas]
          select-country-and-logout
    div.gridSection
      div.sectionmenu
        filters-and-bread-crumb(:paramCOD="paramCOD" :onlyNetwork="true" ref="filtersRef")
        warning-not-delivery-not-internet(v-if="itemNotHasInternet" type="internet")
        router-view.bg-gray

</template>

<script>
import FiltersAndBreadCrumb from "@/components/filters/filtersAndBreadCrumb.vue"
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import WarningNotDeliveryNotInternet from '@/components/common/WarningNotDeliveryNotInternet.vue'
import SelectCountryAndLogout from "@/components/common/SelectCountryAndLogout.vue"

export default {
  props: ['paramCOD', 'type', 'site', 'typeRestaurant', 'after', 'before'],
  mixins: [commonMixins],
  data() {
    return {
      OptionTypeSelect: '',
      enableIAConfigTo: ['liz@gastroranking.es', 'ronaldo@gastroranking.es', "ruth@gastroranking.es"],
    }
  },
  components: {FiltersAndBreadCrumb, WarningNotDeliveryNotInternet, SelectCountryAndLogout},
  async created() {
    this.$store.commit(types.UPDATE_SECTION, 2)
    if (this.$store.getters.getInternetAutomatic) {
      this.OptionTypeSelect = 'internet'
    }
    this.loadUrlParams()
  },
  mounted() {
    const height = this.$refs.navBarRef.clientHeight + this.$refs.filtersRef.containerHeight
    this.$store.commit(types.COMMON_TOP_SECTION_HEIGHT, height)
    console.log({getUserInfo: this.$store.getters.getUserInfo})
  },
  computed: {
    hasDishRanking() {
      return this.$store.getters.getUserInfo?.config['has_dish_ranking']
    }, hasAutomaticConfigs() {
      return this.$store.getters.getUserInfo['can_create_ai_reply_configs'] && this.enableIAConfigTo.includes(this.$store.getters.getUserInfo['email'])
    },
    isHotel() {
      return this.$store.getters?.getTreeData[0]?.is_hotel
    }
  }
}
</script>

<style lang="scss" scoped>
#satisfactionhome {
  background-color: #F2F2F2;
  height: 100%;
  border-top-left-radius: 30px;
}

.sectionmenu {
  .thirstlevel {
    background-color: #F2F2F2;
    margin-top: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
}
</style>
