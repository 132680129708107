<template lang="pug">
  div
    div.gridFull.pa-0
      v-row.flex-center.b-bottom.mb-0.no-gutters.main(:style="`top: ${headerHeight}px`")
        v-col.py-4.d-flex.items-center(md="4")
          .block-count.pl-4.font-weight-med
            span.mr-1 {{configurationsCount}}
            translate configuraciones
          button.primary-button(@click="showEditionModal=true")
            span.text-13 Añadir una Configuración
        v-col(md="8")
          div.filters
            span.labelFilter #[translate Buscar restaurante]:
              div.filter.ml-2.mr-4
                input.filterBySearch(type="text" v-model="search")
                button.filterBySearchButton.clean(@click="cleanBySearch" v-if="$route.query.search || search")
                  img(:src="img.clean")
                button.filterBySearchButton(@click="filterBySearch")
                  img(:src="img.search")

            span.labelFilter.d-flex.items-center #[translate Nº estrellas]:
              div.filter.ml-2.mr-4
                filter-tree-menu(:options="starsNumber" label-class="text-capitalize-first-letter" id-key="id" label-key="name")
            span.labelFilter.d-flex.items-center #[translate Texto]:
              div.filter.ml-2.mr-4
                filter-tree-menu(:options="textOptions" label-class="text-capitalize-first-letter" id-key="id" label-key="name")
      v-row.no-gutters
        automatic-answers-table(style="width:100%")

    new-a-i-configuration-modal(:isActive="showEditionModal" @onHidden="handleHidden")
</template>

<script>
import AutomaticAnswersTable from "@/components/feedback/AutomaticAnswersTable.vue"
import NewAIConfigurationModal from "@/components/feedback/NewAIConfigurationModal.vue"
import FilterTreeMenu from "@/components/filters/filterTreeMenu";

export default {
  props: {},
  components: {AutomaticAnswersTable, FilterTreeMenu, NewAIConfigurationModal},
  data() {
    return {
      showEditionModal: false,
      configurationsCount: 6,
      search: "",
      img: {
        search: require('@/assets/img/buscar.svg'),
        clean: require('@/assets/img/borrar.svg')
      },
      starsNumber: ["1 estrellas", "2 estrellas", "3 estrellas"],
      textOptions: ["Si", "No"]
    }
  },
  computed: {
    headerHeight() {
      return this.$store.getters.getTopSectionHeight - 2
    }
  },
  methods: {
    handleHidden(){
      this.showEditionModal = !this.showEditionModal
    },
    cleanBySearch() {
      // this.$store.commit(types.FEEDBACK_OPINIONS_SEARCH, null)
    },
    filterBySearch() {

    }
  }
}
</script>

<style lang="scss" scoped>
.primary-button {
  background-color: #00ADFC;
  padding: 10px 20px;
  margin-left: 46px;
  border-radius: 4px;

  span {
    color: white;
  }
}

.filterBySearch {
  vertical-align: middle;
  font-size: 0.75rem;
  border: 1px solid #D7DAE2;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0;
  background: white;
  color: #1976d2;
  height: 34px;
  padding-left: 16px;
}

.filterBySearchButton {
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #D7DAE2;
  border-left: 0;
  height: 34px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 10px;

  &.clean {
    border-right: 1px solid #D7DAE2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  img {
    width: 14px;
    margin-top: 5px;
  }
}

.main {
  position: sticky;
  z-index: 2;
  background: #fff;
}

.action-button {
  background-color: #EBEBEB;
  border-radius: 2px;
  max-width: fit-content;
}
</style>
