<template lang="pug">
  div
      v-dialog.dialogEdit(v-model="show"
          width="611")
          v-card.dialogEdit
              v-card-text.pb-0
              v-container#modalLocalMenu
                  v-row#headerModalEdit.pt-3
                      img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                      v-col.d-flex.items-center(cols="10")                      
                        span.ml-2.ch {{ $gettext('Recoger el menú de Google') }}
                  v-row#bodyModalEdit
                      v-col.text-center.text-14(cols="12")
                        <svg xmlns="http://www.w3.org/2000/svg" width="56.187" height="50.701" viewBox="0 0 56.187 50.701">
                            <g id="Grupo_11268" data-name="Grupo 11268" transform="translate(0.617 -0.512)">
                                <path id="Trazado_5008" data-name="Trazado 5008" d="M47.911,48.712H7.043a5.081,5.081,0,0,1-4.4-7.614L23.072,5.561a5.081,5.081,0,0,1,8.81,0L52.316,41.1A5.081,5.081,0,0,1,47.911,48.712Z" transform="translate(0 0)" fill="none" stroke="#f57200" stroke-linecap="round" stroke-width="5"/>
                                <path id="Trazado_5009" data-name="Trazado 5009" d="M12,9V19.163" transform="translate(15.477 9.225)" fill="none" stroke="#f57200" stroke-linecap="round" stroke-width="5"/>
                                <path id="Trazado_5010" data-name="Trazado 5010" d="M12,17.027,12.025,17" transform="translate(15.477 21.548)" fill="none" stroke="#f57200" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                            </g>
                        </svg>

                        p.mb-4.mt-2 {{ $gettext('Ups, parece que no tienes ningún menú configurado en tu ficha de Google.') }}
                        p {{ $gettext('No hemos podido descargarlo, pero puedes empezar a crearlo aquí mismo y después subirlo a Google con el botón de subida.') }}
                                   
                                  
                  

</template>
<script>
import commonMixins from '@/mixins/common.js'
export default {
  mixins: [commonMixins],
  props: {
    visible: {
        default: false,
        required: false
    },    
    data: {
        default: () => ({"display_name": null, "description": null, "price": null, "image": null})
    }
  },
  data(){
      return {
          imgClose: require('@/assets/img/ico-close-edit.svg'),
      }
  },
  computed: {
      show: {
          get () {
              return this.visible
          },
          set (value) {
              if (!value) {
              this.$emit('close')
              }
          }
      }
  }
}
</script>
<style lang="scss" scoped>

#bodyModalEdit{
    min-height: 300px;
    display: flex;
    align-items: center;
}
</style>
