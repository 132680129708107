<template lang="pug">
  div
      v-dialog.dialogEdit(v-model="show"
          width="527")
          v-card.dialogEdit
              v-card-text.pb-0
              v-container#modalLocalMenu
                  div.gr-loader-snipper--wrapper(v-if="loading")
                      span.gr-loader-snipper
                  v-row#headerModalEdit.pt-3(v-if="data")
                      <svg class="close" xmlns="http://www.w3.org/2000/svg" width="12.607" height="12.607" viewBox="0 0 12.607 12.607"  @click.stop="show=false">
                            <path id="source_icons_cancel_4_" data-name="source_icons_cancel (4)" d="M6.758,17.243,12,12m5.243-5.243L12,12m0,0L6.758,6.757M12,12l5.243,5.243" transform="translate(-5.698 -5.697)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      </svg>
                      v-col.d-flex.items-center(cols="10")
                        <svg xmlns="http://www.w3.org/2000/svg" width="26.39" height="33.5" viewBox="0 0 26.39 33.5">
                            <g id="Grupo_11506" data-name="Grupo 11506" transform="translate(-35.938 -425.333)">
                                <g id="Rectángulo_3052" data-name="Rectángulo 3052" transform="translate(36 425.333)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                <rect width="26" height="30" stroke="none"/>
                                <rect x="1" y="1" width="24" height="28" fill="none"/>
                                </g>
                                <line id="Línea_1402" data-name="Línea 1402" x2="16.393" transform="translate(41.183 431.567)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                <line id="Línea_1391" data-name="Línea 1391" x2="24.39" transform="translate(36.938 457.833)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                <rect id="Rectángulo_3178" data-name="Rectángulo 3178" width="17" height="10" rx="4" transform="translate(41 435.833)" fill="#fff"/>
                            </g>
                        </svg>

                        span.ml-2.ch(v-if="!edit && !remove") {{ $gettext('Añadir plato') }}
                        span.ml-2.ch2(v-else-if="!edit && remove") {{ $gettext('Eliminar plato') }} {{  data.display_name }}
                        span.ml-2.ch3(v-else) {{ $gettext('Editar plato') }} {{  data.display_name }}
                  v-row#bodyModalEdit
                      v-col(cols="12")
                        v-container(v-if="data")
                            v-row(v-if="!remove")
                                div.w-full(v-if="createdOk")
                                    p.my-9.text-center.w-full Producto creado correctamente.
                                div.w-full(v-else-if="updatedOk")
                                    p.my-9.text-center.w-full Producto editado correctamente.
                                div.w-full(v-else)
                                  v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                                      div.messageError
                                          span {{ messageError ? messageError : $gettext('An error occurred while recording.') }}
                                  v-col.text-left.pt-6.pb-0(cols="12")
                                      label #[translate Nombre del producto]
                                      input.d-block.w-full(type="text" v-model="data.display_name")
                                  v-col.text-left.pt-6.pb-0(cols="12")
                                      label #[translate Descripción]
                                      textarea.d-block.w-full(type="text" v-model="data.description")
                                  v-col.text-left.pt-6.pb-0(cols="12")
                                      label #[translate Precio]
                                      input.d-block.w-full(type="text" v-model="data.price")
                                  v-col(md="12")                                                
                                    v-file-input.addPhoto(
                                        :rules="rules"
                                        @change="Preview_image"
                                        v-model="data.image"
                                        id="file" ref="file"
                                        accept="image/png, image/jpeg, image/bmp"
                                        :placeholder="placeholderAddPhoto"
                                        prepend-icon="mdi-camera"
                                        label="Seleccionar imagen")
                                  v-col#helpimage.text-left.pb-0(cols="8")
                                    p
                                        span #[translate The photo size requirements are as follows:]
                                        br
                                        span #[translate Size: 250 × 250 - 5000 × 5000 pix]
                                        br
                                        span #[translate Weight: 10 KB - 5 MB]
                            v-row(v-else)
                                p.my-9.text-center.w-full(v-if="!removedOk") ¿Seguro que quieres eliminar el producto?
                                p.my-9.text-center.w-full(v-else) Producto eliminado correctamente.      
                                  
                  v-row#footerModalEdit
                    v-col.d-flex.items-center.justify-center(cols="12" v-if="!removedOk && !updatedOk && !createdOk")
                        <ButtonAddItem v-if="!edit && !remove" @click.native="save()"><span class="text-14">Añadir producto</span></ButtonAddItem>
                        <ButtonCancel class="mr-3" v-if="remove && !removedOk" @click.native="remove = false;edit=true;">Cancelar</ButtonCancel>
                        <ButtonRemoveItem v-if="edit && !remove" :isActive="remove" @click.native="remove = true;edit = false">Eliminar producto</ButtonRemoveItem>
                        <ButtonRemoveItem v-if="!edit && remove" :isActive="remove" @click.native="save()">Eliminar producto</ButtonRemoveItem>
                        <ButtonUpdateItem v-if="edit" class="ml-3" @click.native="save()">Actualizar producto</ButtonUpdateItem>
                    v-col.d-flex.items-center.justify-center(v-else)
                        <ButtonCancel class="mr-3"  @click.native="show = false">Aceptar</ButtonCancel>
                          //button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
                          //button.btnSaveEditModal(@click="save()")  #[translate Save]

</template>
<script>
import commonMixins from '@/mixins/common.js'
import SrvLocalMenu from '@/services/localMenu.js'
import ButtonAddItem from '@/components/local/menu/ButtonAddItem.vue'
import ButtonUpdateItem from '@/components/local/menu/ButtonUpdateItem.vue'
import ButtonRemoveItem from '@/components/local/menu/ButtonRemoveItem.vue'
import ButtonCancel from '@/components/local/menu/ButtonCancel.vue'
export default {
  mixins: [commonMixins],
  components: {ButtonAddItem, ButtonUpdateItem, ButtonRemoveItem, ButtonCancel},
  props: {
    idSection: {default: null},
    visible: {
        default: false,
        required: false
    },
    idBussines: {
        default: null
    },
    isEdit: {
        default: false,
        required: false,
        type: Boolean
    },
    isRemove: {
        default: false,
        required: false,
        type: Boolean
    },
    data: {
        default: () => ({"display_name": null, "description": null, "price": null, "image": null})
        
    }
  },
  data(){
      return {
        removedOk: false,
        createdOk: false,
        updatedOk: false,
          loading: false,
          showAddEditSection: false,
          imgClose: require('@/assets/img/ico-close-edit.svg'),
          categories: [],
          priceRange: [],
          customCategories: [],
          googlePrimaryCategoryCustom: {},
          section: 'data-restaurant',
          saveStatus: true,
          messageError: null,
          rules: [
                value => !value || value.size < 5000000 || this.$gettext('Avatar size should be less than 5 MB!'),
            ],
            file: '',
      }
  },
  watch: {
      visible (newValue){
          if (newValue) {
            this.removedOk = false
              this.createdOk = false
              this.updatedOk = false
              this.$emit('remove', false)
              if (this.data && this.data.id) {
                this.$emit('edit',true)
              } 
          } else {
            this.data = {"display_name": null, "description": null, "price": null, "image": null}
          }
      }
  },
  computed: {
    placeholderAddPhoto () {
            return this.$gettext('Add a photo')
        },
      show: {
          get () {
              return this.visible
          },
          set (value) {
              if (!value) {
              this.$emit('close')
              }
          }
      },
      remove: {
          get () {
              return this.isRemove
          },
          set (val) {
                this.$emit('remove', val)
          }
      },
      edit: {
          get () {
              return this.isEdit
          },
          set (val) {
                this.$emit('edit', val)
          }
      },
  },
  methods: {
    cleanErrorPhoto(){
        document.getElementById('helpimage').style = '';
    },
    handleFileUpload(){
        this.cleanErrorPhoto()
            this.file = this.$refs.file.input.files[0];
        },
        removePhoto () {
            this.cleanErrorPhoto()
            this.data.image = null
            this.photosChanged = true
        },
        Preview_image() {
            this.cleanErrorPhoto()
            if (this.data.image) {
                this.url = URL.createObjectURL(this.data.image)
            } else {
                this.url = ''
            }
        },
    async save () {
        let price
        if (String(this.data.price).indexOf(',') === -1) {
            price = this.data.price
        } else {
            price = Number(this.data.price.replaceAll('.','').replace(',','.')).toFixed(2)
        }
        
        
        let action = this.data.id ? 'updated' : 'created'
        if (this.remove) { 
            action = 'delete'
        }
        let formData = new FormData()
        let jsonItem = {id: this.data.id, "display_name": this.data.display_name, "description": this.data.description}

        if (this.data.price) {
            jsonItem.prices = [{"currency": "EUR", "price": price}]
        }
        formData.append('json_data',JSON.stringify(jsonItem))
        if (this.data.image && typeof this.data.image !== 'string') {
            formData.append('image', this.data.image)
        } 

        try {
            const res = await SrvLocalMenu.setProduct(this.idBussines, formData, action, this.idSection, this.data.id)
            if (res.status === 201 || res.status === 200) {
                this.$emit('productOk', {status: res.status === 200 ? 'edited' : 'created', idsection: this.idSection, product: res.data})
            }
            if (res.status === 200) {
                this.updatedOk = true
            }
            if (res.status === 201) {
                this.createdOk = true
            }
            if (res.status === 204) {
                this.removedOk = true
                this.$emit('productOk', {status: 'removed', idsection: this.idSection, product: {id: this.data.id}})
            }
        } catch (e) {
            if (e.response.data && e.response.data.image) {
                document.getElementById('helpimage').style = 'color:red';
            }
        }
        
    }
  }
}
</script>
<style lang="scss" scoped>
  #autocompleteCategories{
      background-color: #fff;
      input{
          font-size: 0.875rem !important;
      }
  }
  .theme--light.v-text-field--solo-inverted.v-input--is-focused>.v-input__control>.v-input__slot {
      background-color: #fff;
  }
  .messageError{
      background-color: #F5B1B8;
      border: 1px solid #F93549;
      color: #F93549;
      padding:15px;
  }
  .addPhoto{
        border: 2px solid #4A90E2;
        max-height: 53px;
        color: #fff;
        background-color: #4A90E2;
    }
    .preview{
        height: 73px;
    }
</style>
