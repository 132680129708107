<template>
    <div>
        <div class="addPhotos d-flex relative">
            <div v-if="imagePreview" class="image relative">
                <button @click="deletePhoto()" class="absolute pill font-weight-med">Borrar</button>
                <img ref="imagePreviewRef" :src="imagePreview" />
            </div>
            <div v-else>
                <label for="photoPost" class="d-flex flex-column align-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
                        <path id="Trazado_5018" data-name="Trazado 5018"
                            d="M9,3h6l2,2h4a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V6A1,1,0,0,1,3,5H7Zm3,16a6,6,0,1,0-6-6A6,6,0,0,0,12,19Zm0-2a4,4,0,1,1,4-4A4,4,0,0,1,12,17Z"
                            transform="translate(-2 -3)" fill="#00adfc" />
                    </svg>

                    <span>Añadir foto</span></label>
                <input id="photoPost" class="d-none" type="file" @change="onFileChange" accept="image/png, image/jpeg">
            </div>
        </div>
        <div class="pt-1">
            <p v-if="maxSize && showErrorMessage" class="text-red">El tamaño máximo permitido es de {{ formatedMaxSize }}MB
            </p>
        </div>
    </div>
</template>
<script>
export default {
    props: ['image', 'maxSize'],
    data() {
        return {
            showErrorMessage: false
        }
    },
    computed: {
        imagePreview() {
            if (this.image) {
                if (typeof this.image === 'string') {
                    return this.image
                } else {
                    const imageUrl = URL.createObjectURL(this.image)
                    return imageUrl
                }
            } else {
                return null
            }
        },
        formatedMaxSize() {
            return this.maxSize ? this.maxSize / 1000000 : 0
        }
    },
    methods: {
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            if (this.maxSize) {
                if (files[0].size > this.maxSize) {
                    return this.showErrorMessage = true
                }
            }
            this.showErrorMessage = false
            this.$emit('loadImage', files[0])
        },
        deletePhoto() {
            this.$emit('removeImage')
        },
    }
}
</script>
<style lang="scss" scoped>
.addPhotos {
    border: 1px solid #00ADFC;
    height: 200px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    .image {
        height: 200px;
    }

    .pill {
        background: #000;
        color: #fff;
        padding: 10px;
        border-radius: 0 5px 5px 0;
        font-size: 14px;
    }
}
</style>