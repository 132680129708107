const domain = window.location.hostname;

// SHOW ALL TREES IN SUBNAV
const showAllTreesInSubnav = () => {
  return ["grupomambo"].includes(domain.split(".")[0]);
};

// Featuyre flags
let featureConfig = {
  hpSatisfaction: true,
  googleToken: true,
  googleUpdateAll: true,
  facebookUpdateAll: true,
  facebookToken: true,
  facebookSetupButton: true,
  facebookSetupButtonInProfile: true,
  facebookBulkSetup: true,
  googleSetupButtonInProfile: true,
  googlePendingRestaurants: true,
  showListReports: true,
  gastroPricing: true,
  showCountryMenu: true,
  feedbackKeywords: true,
  feedbackDishRanking: true,
  globalGastroRanking: true,
  socialReply: true,
  feedbackEmployees: true,
  attentionSection: true,
  feelingCategory: true,
  mapCompetence: true,
  viewAggregators: true,
  sectionDelivery: true,
  keyQuestionNPS: "rating_recommendation",
  showAllTreesInSubnav: showAllTreesInSubnav,
};

let api = "";
let apiGastroPricing = "";
if (domain === "localhost") {
  const subdominioActivo = "ibersol"; // 13
  api = `https://${subdominioActivo}.gastroranking.pro/backend/api/v1/`;
  //api = `http://cloud-jorge.duckdns.org:8000/backend/api/v1/`
  apiGastroPricing = `https://${subdominioActivo}.gastroranking.pro/backend/api/v1/gastropricing/`;
  //apiGastroPricing = `http://cloud-jorge.duckdns.org:8000/backend/api/v1/gastropricing/`
} else {
  api = `https://${domain}/backend/api/v1/`;
  apiGastroPricing = `https://${domain}/backend/api/v1/gastropricing/`;

  // Turn on/off features for production
  featureConfig = {
    hpSatisfaction: true,
    googleToken: true,
    googleUpdateAll: true,
    facebookUpdateAll: true,
    facebookToken: true,
    facebookSetupButton: true,
    facebookSetupButtonInProfile: true,
    facebookBulkSetup: true,
    googleSetupButtonInProfile: true,
    googlePendingRestaurants: true,
    showListReports: true,
    gastroPricing: true,
    showCountryMenu: true,
    feedbackKeywords: true,
    feedbackDishRanking: true,
    globalGastroRanking: true,
    socialReply: true,
    feedbackEmployees: true,
    attentionSection: true,
    feelingCategory: true,
    mapCompetence: true,
    viewAggregators: true,
    sectionDelivery: true,
    keyQuestionNPS: "rating_recommendation",
    showAllTreesInSubnav: showAllTreesInSubnav,
  };
}

let log = function (data) {
  if (["127.0.0.1", "localhost"].includes(window.location.hostname)) {
    console.log(data);
  }
};

// Obtenemos idioma del usuario
let userLocale = navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage;
userLocale = userLocale.startsWith("es-") ? "es" : userLocale;

let language = "";
switch (userLocale) {
  case "es":
  case "ca":
  case "gl":
  case "eu":
    language = "es";
    break;
  case "pt":
    language = "pt";
    break;
  default:
    language = "en";
}

const brand = () => {
  if (api.indexOf("adverbia") > -1) {
    return "adverbia";
  } else {
    return "gastroranking";
  }
};

export default {
  domain,
  language,
  api,
  apiGastroPricing,
  featureConfig,
  log,
  brand,
};
