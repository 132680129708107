<template lang="pug">
  div#gridPrincipal.height-100
    v-row.vertical-center.height-100
      v-col.text-left(md="12")
        div#imagenLogin
          img(:src="img.login")
        div#frmLogin  
          img#logo(:src="logoLogin")   
          div#frmForm
            p.mensajeError(v-if="this.errorMsgShow && errorMsgPwd.length === 0") {{ errorMsg }}
            p.mensajeError(v-else-if="this.errorMsgShow && errorMsgPwd.length > 0")
              ul
                li(v-for="error in errorMsgPwd") {{error}}
            p.cwhite 
              strong #[translate Reset your password?]
            p.cwhite #[translate Enter your new password! ;-)]
            v-row(v-if="this.statusOk")
              v-col.text-center(cols="12")
                p #[translate Password reseted return to ]
                  button.cwhite(@click="home") #[translate Login]
            form
              div.margin-bottom-32
                input.fieldWhite(type="password" v-model="password" name="password" :placeholder="placeholder.pwd" :class="{ 'errorField':errorPwd }" @focus="resetError")
                span.mensajeErrorCampo(v-if="errorPwd") #[translate This field is required]
              div.margin-bottom-32
                input.fieldWhite(type="password" v-model="repeat" name="repeat" :placeholder="placeholder.pwdconfirm" @focus="resetError")
                span.mensajeErrorCampo(v-if="errorPwd") #[translate This field is required]
              p
              v-row#recordarmeyOlvidado
                v-col.text-left(md="5")
                  div
                    button.cwhite(@click="home") < #[translate Return]
                v-col.text-right(md="7")
              div.text-center
                button#entrar( type="button" @click="send" @keyup.enter="send") #[translate Send]
</template>
<script>
import SrvCommon from '@/services/common.js'
import mixinBrand from '@/mixins/brand.js'

export default {
  name: 'RestoreSet',
  mixins: [mixinBrand],
  data () {
    return {
      img: {
        login: require('@/assets/img/portada.jpg'),
        logo: require('@/assets/img/logo-intro.svg')
      },
      errorMsg: false,
      errorPwd: false,
      errorMsgShow: false,
      errorMsgPwd: [],
      statusOk: false,
      okMsg: false,
      password: null,
      repeat: null,
      placeholder: {
        'pwd': this.$gettext('Password'),
        'pwdconfirm': this.$gettext('Password repeat'),
      },
    }
  },
  created () {
    let token = this.$route.query.token
    this.resetError()
    if (token) {
      SrvCommon.restorePasswordTokenCheck({token: token})
      .then(() => {
        // console.log(res)        
      }, (error) => {
        this.errorMsgShow = true
        if (error.response && error.response.status == 404) {
          if (error.response.data.status && error.response.data.status === "expired"){
            this.errorMsg = this.$gettext('The token is expired, request another one')
          } else {
            this.errorMsg = this.$gettext('The token is not correct request another one.')
          }
        } else {
          this.errorMsg = this.$gettext('An error happenned. try again later.')
        }
      })
    } else{
      this.errorMsgShow = true
      this.errorMsg = this.$gettext('Error! incorrect token data')
    }
  },
  computed: {
    year () {
      return (new Date()).getFullYear()
    },
    config_app () {
      return this.$config.configApp
    }
  },
  methods: {
    send () {
      let password = this.password
      let confirm = this.repeat
      let token = this.$route.query.token
      this.resetError()
      if (password != confirm) {
        this.errorMsgShow = true
        this.errorPwd = true
        this.errorMsg = this.$gettext('different password in verification field')
      }

      if (!this.errorPwd) {
          SrvCommon.restorePasswordSet(password, token)
          .then(res => {
            if (res.data.status === 'OK') {
              this.statusOk = true
            }
          }, (error) => {
            this.errorMsgShow = true
            if (error.response.data.password) {            
              this.errorMsgPwd = error.response.data.password
              // this.errorMsg = error.response.data.password[0]
            } else {
              this.errorMsg = this.$gettext('Error! incorrect data')
            }
          })
      }
    },
    home () {
        this.$router.push({name: 'home'})
    },
    resetError () {
      this.errorPwd = false
      this.errorMsgShow = false
      this.errorMsg = false
      this.statusOk = false
      this.okMsg = false
    },
  }
}
</script>
<style lang="scss" scoped>
  
  #fraseBienvenida{
    text-align: center;
    font-size: 1.125rem;
  }
  #gridPrincipal{
    max-width: 1200px;
    margin: 0 auto;
  }
  #frmLogin,#imagenLogin{
    display: inline-block;
  }
  #frmLogin{
    width: 39%;
    margin-left: -16%;
    vertical-align: top;
    margin-top: 128px;
    #frmForm{
      max-width: 86%;
      margin: 0 auto;
      .errorField{
        border:2px solid #000;
        color:#000;
      }
      .mensajeError{
        font-weight: bold;
      }
      .mensajeErrorCampo{
        color:#000;
        font-weight: bold;
      }
    }
  }
  #imagenLogin{
    width: 71.2%; // 865 de 1150 
    text-align: left;
    margin-top: 50px;
  }
  #logo{
    margin-bottom: 40px;
    display:inline-block;
    max-width: 468px;
  }
  .fieldWhite{
    background-color: #fff;
    text-align: center;
    border-bottom:0;
    border-radius:5px;
    color:#000;
    max-width: 400px;
    max-height: 46px;
    font-size: 0.938rem;
  }
  .cwhite{
    color:#fff;
  }

  input{
    display:block;
    width: 100%;
    border:none;
    font-size:1.125rem;
    padding:16px 10px;
    &#recordar{
      display: inline;
      width: auto;
      border:none;
    }
  }
  .height-100{
    height:100%;
  }
  #entrar{
    color:#fff;
    border:1px solid #fff;
    border-radius:5px;
    padding:12px 50px;
    margin-top:32px;
    transition: all ease 0.3s;
    &:hover{
      background-color: #fff;
      color:#000;
    }
  }
  #recordarmeyOlvidado{
    .v-input--selection-controls{
      margin-top:0;
      padding-top: 0;
      .theme--light{
        color:#fff;
      }
    }
  }
  @media (max-width: 1024px) {
    #gridPrincipal {
      width: 93%;
    }
    #imagenLogin{
      display: none;
    }
    #frmLogin{
      margin: 0 auto;
      display: block;
      width: 100%;
    }
  }
    
</style>
