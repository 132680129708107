export const SESSION_STORAGE_KEYS = {
  COMPARATIVE_LOCALS: "comparative_locals",
};

export const sessionStorage = {
  get: (key) => {
    const value = window.sessionStorage.getItem(key);

    return JSON.parse(value);
  },
  set: (key, value) => {
    const formatedValue = JSON.stringify(value);
    return window.sessionStorage.setItem(key, formatedValue);
  },
  clear: (key) => {
    return window.sessionStorage.removeItem(key);
  },
};
