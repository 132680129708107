<template lang="pug">
    div#gastropricing.boxSectionHeaderAndBody
      div#navtop(:class="{'anchoEscritorioSubnavCerrado': subNavClosed, 'noTree': $route.meta.isGP}")
        div.sectionmenu
            div.firstlevel
              router-link.mx-4.menu-gp.text-decoration-none(:to="{name: 'promoComparator', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Promo Comparator]
              router-link.mx-4.menu-gp.text-decoration-none(:to="{name: 'promoNews', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate News]
              router-link.mx-4.menu-gp.text-decoration-none(:to="{name: 'promoFinished', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Finished]
              router-link.mx-4.menu-gp.text-decoration-none(v-if="is_alsea" :to="{name: 'promoPizzas', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Promociones Pizza]
              select-country-and-logout    
      div.gridSection
        div.sectionmenu
          div.pr-5.thirstlevel
            v-row.flex-center
              v-col.col-filters.col-filters-big-home.pt-0
                div.filters

          router-view.bg-gray

</template>

<script>
import * as types from '@/store/mutation-types';
import commonMixins from "@/mixins/common.js";
import SrvGastroPricing from '@/services/gastropricing.js'
import SelectCountryAndLogout from "@/components/common/SelectCountryAndLogout.vue"

export default {
  props: ['paramCOD' ,'type','site','typeRestaurant', 'after', 'before'],
  components: {SelectCountryAndLogout},
  mixins: [commonMixins],
  data(){
    return{
    }
  },
  async created(){
    this.$store.commit(types.UPDATE_SECTION,7);
    this.loadUrlParams()
  },
  computed: {
    countrieNameActive () {
        let country = this.optionsTree.filter(c => c.code === this.countrieCodActive);
        return country[0].name
      },
      countrieCodActive () {
        let cod = window.localStorage.getItem('_country_selected_gp')
        return cod
      },
    optionsTree(){
        // SI YA TENIAN TOKEN Y NO SE HABIAN CARGADO LOS PAISES
        if (!window.localStorage.getItem('_countries_gp')) {
          this.loadCountries()
        }
        return JSON.parse(window.localStorage.getItem('_countries_gp'))
      }

  },
  methods: {
    loadCountries () {
      SrvGastroPricing.getCountries().then(res => {
            window.localStorage.setItem('_country_selected_gp',res.data[0].code)
            window.localStorage.setItem('_countries_gp',JSON.stringify(res.data))
            location.reload()
          }, (error) => {
            this.errorMsg = error.data.non_field_errors[0]
          })
    },
    setCountry (code){
        window.localStorage.setItem('_country_selected_gp', code)
        this.$store.commit(types.GP_COUNTRY_SELECTED,code)
        location.reload();
        this.$router.push({name: 'rateAnalysis', params: {paramCOD: this.paramCOD}, query: this.queryParams})
      },
      icoFlag (code) {
        return require('@/assets/img/flags/'+ code.toString().toLowerCase() +'.svg')
      },
  }
}
</script>

<style lang="scss" scoped>
#satisfactionhome{
  background-color: #F2F2F2;
  height: 100%;
  border-top-left-radius: 30px;
}
.sectionmenu{
.thirstlevel{
  background-color: #F2F2F2;
  margin-top: 0;
  padding-bottom:12px;
  padding-top:12px;
}
}

</style>
