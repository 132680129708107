<template lang="pug">
  div
    v-col(md="12").contentOutPadding.tablaGR
      v-data-table(
        :headers="headers"
        :items="configs"
        hide-default-footer
        class="elevation-1"
        item-key="idGenerate"
      )
        template(v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }")
          span.d-flex(v-if="header.value === 'actions'")
            span
          span(v-else) {{ header.text }}

        template(v-slot:item.config_name="{ item }")
          span.d-flex.items-center
            img(:src="img.config")
            span.pl-3.text-13 {{ item.name }}
        template(v-slot:item.brand="{ item }")
          span(v-if="item.brand").d-flex.items-center.text-13 {{ item.brand }}
          span(v-else).d-flex.items-center.text-13 -
        template(v-slot:item.restaurant="{ item }")
          span(v-if="item.restaurant").d-flex.items-center.text-13 {{ item.restaurant }}
          span(v-else ).d-flex.items-center.text-13 -
        template(v-slot:item.category="{ item }")
          span(v-if="item.category").d-flex.items-center.text-13 {{ item.category }}
          span(v-else).d-flex.items-center.text-13 -
        template(v-slot:item.stars="{ item }")
          stars-combination(:combination="item.stars")
        template(v-slot:item.text="{ item }")
          span.d-flex.items-center.text-13
            span(v-if="item.text") #[translate SI]
            span(v-else) #[translate NO]
        template(v-slot:item.automatic_answer="{ item }")
          div.d-flex.justify-center
            switch-sync(:sync="item.automatic_answer")
        template(v-slot:item.actions="{ item }")
          div.d-flex.justify-center
            button.action-button.px-2.py-4
              img(:src="img.edit" style="width: 20px; height: 20px")
            button.action-button.px-2.py-4
              img(:src="img.trash" style="width: 20px; height: 18px")
</template>

<script>
import StarsCombination from "@/components/feedback/StarsCombinations.vue"
import SwitchSync from "@/components/local/SwitchSync.vue"
import SrvFeedback from '@/services/feedback.js'

export default {
  props: {},
  components: {StarsCombination, SwitchSync},
  data() {
    return {
      img: {
        config: require('@/assets/img/config.svg'),
        trash: require('@/assets/img/trash.svg'),
        edit: require('@/assets/img/edit.svg'),
      },
      configs: []
    }
  },
  mounted() {
    this.getAutomaticAnswersConfigs()
  },
  computed: {
    headers() {
      return [
        {
          text: "NOMBRE CONFIGURACIÓN",
          align: "start",
          sortable: false,
          value: "config_name",
        },
        {text: "MARCA", value: "brand", align: "start", sortable: false},
        {text: "RESTAURANTE", value: "restaurant", align: "start", sortable: false},
        {text: "ESTRELLAS", value: "stars", align: "start", sortable: false, width: "10%"},
        {text: "TEXTO", value: "text", sortable: false},
        {text: "CATEGORÍA", value: "category", align: "start", sortable: false},
        {text: "RESPUESTA AUTOMÁTICA", value: "automatic_answer", sortable: false, width: "10%"},
        {text: "ACCIONES", value: "actions", sortable: false, width: "10%"},
      ];
    },
  },
  methods: {
    async getAutomaticAnswersConfigs() {
      try {
        const {data: {results}} = await SrvFeedback.getAutomaticAnswersConfigs()
        this.configs = results
      } catch (error) {
        console.error("[ERROR: getAutomaticAnswersConfigs]:", error)
      }
    }
  }
}
</script>

<style scoped>
.action-button {
  background-color: #EBEBEB;
  border-radius: 2px;
  max-width: fit-content;
}
</style>
