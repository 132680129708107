<template lang="pug">
    div.margin-bottom-0.col-6
            skeleton-card(v-if="isLoading")
            v-card.gridFull.ma-0(v-if="!isLoading && dataSummary.length" :class="{'borderDelivery': typeOpinionSelected === 'delivery' || isDelivery}")
                .d-flex.headTitleGrid
                    .block-title.titleGrid
                        translate.mb-0.title-card Feeling
                        span.ml-1.tDelivery(v-if="typeOpinionSelected === 'delivery'  || isDelivery") Delivery
                    .block-help.ml-auto
                        v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="83" nudge-left="10" content-class="tooltip-help tooltip-help-left")
                            template(v-slot:activator="{ on, attrs }")
                                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                            div
                                span #[translate The notes of each opinion are identified to calculate the distribution between positive, neutral and negative opinions, with the following criteria:]
                                br
                                span #[translate In this way we define]:
                                ul(v-if="!isAdverbia")
                                    li #[translate Positives]: 10 – 7,5
                                    li #[translate Neutral]: 7,5- 5
                                    li #[translate Negatives]: 5 - 0
                                ul(v-else)
                                    li #[translate Positives]: 5 - 4,5
                                    li #[translate Neutral]: 4,5 - 3
                                    li #[translate Negatives]: 3 - 1
                                span(v-if="!isAdverbia") #[translate It is analyzed about the group of restaurants that marks the selection in the left menu and the filters applied compared to the same period of the previous year (Tend).]
                                span(v-else) Se analiza sobre el grupo de negocios que marca la selección en el menú de la izquierda y los filtros aplicados en comparación con el mismo periodo del año anterior (Tend).
                        

                .card-content
                    .row.text-left
                        #tableSentimiento.tableGlobal
                            div.headerTableRanking.row.ma-0
                                div.headerItem.col-name
                                    p
                                div.headerItem.col-sentimiento
                                    p.mb-0 #[translate Feeling]
                                div.headerItem.col-positives
                                    p.mb-0 #[translate Positives]
                                div.headerItem.col-neutral
                                    p.mb-0 #[translate Neutral]
                                div.headerItem.col-negatives
                                    p.mb-0 #[translate Negatives]
                                div.headerItem.col-tend
                                    p.mb-0 #[translate Tend]

                            div.contentTableRanking.row.ma-0
                                p.ml-3(v-if="!dataSummary") #[translate No data]
                                div.itemContent.col-12.pa-0(v-for="(item, index) in dataSummary" :key="index")
                                    div.item.col-name(:class="getTextBlackIfGroup(item)")
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.ellipsis1Col(v-if="index == 0" v-bind="attrs" v-on="on") {{item.name}}
                                                span.link-text.ellipsis1Col(v-if="index>0" @click="goItemNextLevel(item)" v-bind="attrs" v-on="on") {{item.name}}
                                            span(v-if="index == 0") {{item.name}}
                                            span(v-if="index>0" ) {{item.name}}
                                    div.item.col-sentimiento
                                        p.mb-0
                                            chart-bar-three-colors(:slim="true" :percentGreen="item.review_percent_positive" :percentOrange="item.review_percent_neutral" :percentRed="item.review_percent_negative")
                                    div.item.col-positives.text-right
                                        p.mb-0 {{ item.review_percent_positive | RoundedNumber }} %    
                                        p.numberOpinionSmall.mb-0 ({{ item.review_count_positive | formatNumber }})
                                    div.item.col-neutral.text-right
                                        p.mb-0 {{ item.review_percent_neutral | RoundedNumber }} % 
                                        p.numberOpinionSmall.mb-0 ({{ item.review_count_neutral | formatNumber }})
                                    div.item.col-negatives.text-right
                                        p.mb-0 {{ item.review_percent_negative | RoundedNumber }} % 
                                        p.numberOpinionSmall.mb-0 ({{ item.review_count_negative | formatNumber }})
                                    div.item.col-tend
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(item.review_percent_positive, item.tend_review_percent_positive)")
                                            span {{ item.tend_review_percent_positive | formatNumber }}
                                div.itemContent.col-12.pa-0(v-if="typeNode === 'center' && dataSummary[0].chain_data")
                                    div.item.col-name.media-cadena
                                        translate Chain mean
                                    div.item.col-sentimiento
                                        p.mb-0
                                            chart-bar-three-colors(:slim="true" :percentGreen="dataSummary[0].chain_data.review_percent_positive" :percentOrange="dataSummary[0].chain_data.review_percent_neutral" :percentRed="dataSummary[0].chain_data.review_percent_negative")
                                    div.item.col-positives.text-right
                                        p.mb-0 {{ dataSummary[0].chain_data.review_percent_positive | RoundedNumber }} % 
                                        p.numberOpinionSmall.mb-0 ({{ dataSummary[0].chain_data.review_count_positive | formatNumber }})
                                    div.item.col-neutral.text-right
                                        p.mb-0 {{ dataSummary[0].chain_data.review_percent_neutral | RoundedNumber }} % 
                                        p.numberOpinionSmall.mb-0 ({{ dataSummary[0].chain_data.review_count_neutral | formatNumber }})
                                    div.item.col-negatives.text-right
                                        p.mb-0 {{ dataSummary[0].chain_data.review_percent_negative | RoundedNumber }} % 
                                        p.numberOpinionSmall.mb-0 ({{ dataSummary[0].chain_data.review_count_negative | formatNumber }})
                                    div.item.col-tend
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(dataSummary[0].chain_data.review_percent_positive, dataSummary[0].chain_data.tend_review_percent_positive)")
                                            span {{ dataSummary[0].chain_data.tend_review_percent_positive | formatNumber }}

</template>

<script>
import commonMixins from "@/mixins/common.js"
import SkeletonCard from '@/components/common/skeletonCard.vue'
import ChartBarThreeColors from '@/components/charts/BarTrheeColors.vue'
export default {
    props: {
        'dataSummary': [],
        'isDelivery': {
            required: false,
            default: false,
            type: Boolean
        }
    },
    mixins: [commonMixins],
    components: {SkeletonCard, ChartBarThreeColors},
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
#tableSentimiento{
    .col-name{
        max-width: 25%;
        flex: 0 0 25%;
        margin-right: 2.5%;
        padding-left: 10px;
        text-align: left;
        
        &.textBlackGroup{
            color:#000;                
        }
        &.media-cadena{
            color:#979797;
            span{
                letter-spacing: -0.02px;
            }
        }
    }
    .col-sentimiento{
        max-width: 21%;
        flex: 0 0 21%;
        margin-right: 2.5%;
        text-align: center;
        justify-content: center;
    }
    .col-positives{
        max-width: 11%;
        flex: 0 0 11%;
        margin-right: 2.5%;
    }
    .col-neutral{
        max-width: 11%;
        flex: 0 0 11%;
        margin-right: 2.5%;
    }
    .col-negatives{
        max-width: 11%;
        flex: 0 0 11%;
        margin-right: 2.5%;
    }
    .col-tend{
        max-width: 8%;
        flex: 0 0 8%;
        margin-right: 0.5%;
        justify-content: center;
    }
    .numberOpinionSmall{
        font-size: 0.75rem;
        color: #707070;
    }
}
</style>