// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  getSatisfactionComments (params, excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      let query = `/satisfaction_comments/`;
      let limit = 25;
      if(params['limit']){
        limit = params['limit'];
      }
      query += `?limit=${limit}`
      if(params['offset']){
        query += `&offset=${params['offset']}`
      }
      if(params['language']){
        for(var lang in params['language']){
          query += `&language=${params['language'][lang].value}`
        }
      }
      if(params['sentiment']){
        for(var sentiment in params['sentiment']){
          query += `&sentiment=${params['sentiment'][sentiment].value}`
        }
      }
      if(params['reason']){
        for(var reason in params['reason']){
          query += `&reason=${params['reason'][reason].value}`
        }
      }
      if(params['survey_type']){
        for(var survey_type in params['survey_type']){
          query += `&survey_type=${params['survey_type'][survey_type].value}`
        }
      }
      if(Stores.getters.getDateAfter){
        query += `&after=${Stores.getters.getDateAfter}T00:00:00`
      }
      if(Stores.getters.getDateBefore){
        query += `&before=${Stores.getters.getDateBefore}T00:00:00`
      }
      // SI EL NIVEL NO EXISTE ES CENTRO
      if(Stores.getters.getNodeLevel == 9){
        if(Stores.getters.getNode){
          query += `&center=${Stores.getters.getNode}`
        }
      }else{
        if(Stores.getters.getNode){
          query += `&node=${Stores.getters.getNode}`
        }
      }

      // SI VIENE EL TIPO DE CENTRO
      if(Stores.getters.getTypesRestaurantSelected.length > 0){
        Stores.getters.getTypesRestaurantSelected.forEach(type => {
          query += `&center_type=${type}`
        })
      }

      query += `&tree=${Stores.getters.getTreeSelected.id}`
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }

      axios.get(query,configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          setTimeout(() => {
            resolve(res)
          }, 1000)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getSatisfactionIndicators (excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/satisfaction_indicators/`;
      query = SrvCommon.getBasicParamsQuery(query)

      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }
      axios.get(query,configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getSatisfactionRanking (order = '-name', excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/satisfaction_center_ranking/`;
      query = SrvCommon.getBasicParamsQuery(query)

      if(Stores.getters.getSurveyTypesSelected){
        for(var survey_type in Stores.getters.getSurveyTypesSelected){
          query += `&survey_type=${Stores.getters.getSurveyTypesSelected[survey_type].value}`
        }
      }

      query += '&ordering='+order
      // llamada a la API
      let token = SrvCommon.getToken()
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query,configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getSatisfactionRankingNext (url) {
    return new Promise((resolve, reject) => {
      let token = SrvCommon.getToken();

      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(url)
        .then(res => {
          Stores.commit(types.NOT_PENDING)
            resolve(res)
        })
        .catch(error => {
          Stores.commit(types.NOT_PENDING)
            const status = error.response.status
            if (status === 401) {
              SrvCommon.logout()
            }
            reject(error)
        })
    })
  },
  getSatisfactionSacEvolution (excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/claim_evolution/`;
      query = SrvCommon.getBasicParamsQuery(query)

      // llamada a la API
      let token = SrvCommon.getToken()
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query,configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getSatisfactionSacClaims (name = 'claims', excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/${name}/`;
      query = SrvCommon.getBasicParamsQuery(query)

      if(Stores.getters.getClaimOriginTypesSelected.length > 0){
        for(var source in Stores.getters.getClaimOriginTypesSelected){
          query += `&source=${Stores.getters.getClaimOriginTypesSelected[source].value}`
        }
      }
      if(Stores.getters.getClaimCharacterTypesSelected.length > 0){
        for(var kind in Stores.getters.getClaimCharacterTypesSelected){
          query += `&kind=${Stores.getters.getClaimCharacterTypesSelected[kind].value}`
        }
      }
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }
      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query,configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getSatisfactionSacClaimsNext (url) {
    return new Promise((resolve, reject) => {
      let token = SrvCommon.getToken();

      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(url)
        .then(res => {
          Stores.commit(types.NOT_PENDING)
            resolve(res)
        })
        .catch(error => {
          Stores.commit(types.NOT_PENDING)
            const status = error.response.status
            if (status === 401) {
              SrvCommon.logout()
            }
            reject(error)
        })
    })
  },
  getSatisfactionSacClaimsCount (name = 'claims') {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/${name}/`;
      query = SrvCommon.getBasicParamsQuery(query)

      if(Stores.getters.getClaimOriginTypesSelected.length > 0){
        for(var source in Stores.getters.getClaimOriginTypesSelected){
          query += `&source=${Stores.getters.getClaimOriginTypesSelected[source].value}`
        }
      }
      if(Stores.getters.getClaimCharacterTypesSelected.length > 0){
        for(var kind in Stores.getters.getClaimCharacterTypesSelected){
          query += `&kind=${Stores.getters.getClaimCharacterTypesSelected[kind].value}`
        }
      }
      query += '&count=True'
      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getSatisfactionSacClaimsSources () {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/claim_sources/`;
      query = SrvCommon.getBasicParamsQuery(query)
      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getSatisfactionSacClaimsKinds () {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/claim_kinds/`;
      query = SrvCommon.getBasicParamsQuery(query)
      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }
}
