<template lang="pug">
div(ref="containerRef")
    v-row.px-2.ma-0
        div.margin-bottom-0.pa-3.col-12
            v-card.gridFull.ma-0
                .card-content
                    .row.d-flex.justify-center.py-4
                        div(v-if="!dataAverage.rating_global")
                            span ...
                        .d-flex.align-baseline(v-else style="font-size: 0.875rem;")
                            .block-info(v-if="dataAverage.rating_global")
                                translate Valoración media de la Marca:
                                v-chip.chipInfo.mx-2(style="font-size: 14px; color: white;" :color="getColorByNoteNormalized({note:getNoteNormalized(dataAverage.rating_global), type:'color'})" label) {{getNoteNormalized(dataAverage.rating_global) | formatNumber(1,1)}}
                            .block-info.ml-3(v-if="dataAverage.avg_reviews")
                                translate Valoración media de Mercado:
                                span.font-weight-med.mx-2(v-if="dataFilteredByBrand && dataFilteredByBrand[0]") {{ getNoteNormalized(dataFilteredByBrand[0].sector.review_rating_global) | formatNumber(1,1)}}

        info-competence(:data="individualCompetence" :title="$gettext('Comparativa')")

</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import SrvNetwork from '@/services/network.js'
import commonMixins from "@/mixins/common.js"
import brandMixin from "@/mixins/brand.js"
import BoxCompetenceLastYear from '@/components/network/CompetenceLastYear.vue'
import BoxFeelingCategory from '@/components/global/FeelingCategory.vue'
import FilterTreeMenu from "@/components/filters/filterTreeMenu"
import InfoCompetence from "@/components/competence/InfoCompetence.vue"

export default {
    mixins: [commonMixins, brandMixin],
    components: { InfoCompetence, FilterTreeMenu, BoxFeelingCategory, SkeletonCard, BoxCompetenceLastYear },
    data() {
        return {
            dataAverage: [],
            data: [],
            individualCompetence: [],
            dataCompetenceLastYear: [],
            helpAverageGroupByValoration: this.$gettext('Data of the brand compared to its competitors: Average of the marks of all restaurants / number of restaurants'),
            helpAverageGroupByValorationAdverbia: 'Datos de la marca en comparación con sus competidores: Media de las marcas de todos los negocios / número de negocios.',
            competenceBrands: [],
            competenceBrandsSelected: [],
            maxWidth: 0
        }
    },
    created() {
        this.$store.commit('LOADING', true)
        if (this.$store.getters.getNode > 0) {
            this.getRankingAverage()
            this.getCompetence()
            if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
                this.getCompetenceLastYear()
            }
        }
    },
    mounted() {
        this.maxWidth = this.$refs.containerRef.clientWidth

        this.observer = new ResizeObserver((entry) => {
            if (entry.length) {
                const [el] = entry
                this.maxWidth = el.contentRect.width
            }
        })

        this.observer?.observe(this.$refs.containerRef)
    },
    methods: {
        getRankingAverage() {
            SrvNetwork.getRankingsAverage()
                .then(res => {
                    if (res.status === 200) {
                        this.dataAverage = res.data

                    } else {
                        // this.message = 'Se ha producido un error. Intentelo más tarde.'
                    }
                }, () => {
                    // this.message = 'Se ha producido un error. Intentelo más tarde.'
                })
        },
        onCheckPaintCompetence(v) {
            this.competenceBrandsSelected = v
        },
        getCompetenceLastYear() {
            SrvNetwork.getCompetenceLastYear()
                .then(res => {
                    if (res.status === 200) {
                        res.data.forEach((e) => e.show = true)
                        this.dataCompetenceLastYear = res.data
                    }
                }, () => {
                })
        },
        progressbarWidth: function (points) {
            if (this.isAdverbia) {
                return 'width:' + (points * 20) + '%';
            } else {
                return 'width:' + (points * 10) + '%';
            }
        },
        getProgressBarClass: function (points) {
            if (points < 5) {
                return 'minimun';
            } else if (points <= 7.5) {
                return 'medium';
            } else {
                return 'large';
            }
        },
        // OBTENEMOS EL RANKING
        getCompetence: function () {
            this.$store.commit('LOADING', true)
            SrvNetwork.getIndividualCompetence()
                .then(res => {
                    if (res.status === 200) {
                        const _formatedData = []
                        for (const item in res.data) {
                            if (Object.hasOwnProperty.call(res.data, item)) {
                                if (item === "children_data") {
                                    res.data[item].forEach(element => _formatedData.push({ showCompetence: false, items: [...element] }));
                                } else {
                                    item === "brand_information" ? _formatedData.unshift({ showCompetence: false, items: [...res.data[item]] }) : _formatedData.push({ showCompetence: false, items: [...res.data[item]] });
                                }

                            }
                        }
                        this.data = res.data
                        this.competenceBrands = []
                        this.competenceBrandsSelected = []
                        this.individualCompetence = [..._formatedData]
                        this.$store.commit('LOADING', false)
                    } else {
                        // this.message = 'Se ha producido un error. Intentelo más tarde.'
                    }
                }, () => {
                    // this.message = 'Se ha producido un error. Intentelo más tarde.'
                })
        },
    },
    watch: {
        filterApply: function () {
            this.getRankingAverage()
            this.getCompetence();
            if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
                this.getCompetenceLastYear()
            }
        },
        distanceMap(newVal) {
            if (newVal) {
                this.getRankingAverage()
                this.getCompetence();
                if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
                    this.getCompetenceLastYear()
                }
            }
        },
        getDistanceActive() {
            this.getRankingAverage()
            this.getCompetence();
            if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
                this.getCompetenceLastYear()
            }
        }
    },
    computed: {
        distanceMap() {
            return this.$store.getters.getDistanceMap
        },
        getDistanceActive() {
            return this.$store.getters.getDistanceMapActive
        },
        brandsCompetenceActive() {
            return this.competenceBrandsSelected.map(c => c.value || c.code)
        },
        dataCompetenceLastYearFilteredByBrand() {
            let brands = this.dataCompetenceLastYear
            if (this.competenceBrandsSelected.length > 0) {
                brands = this.dataCompetenceLastYear.filter((b) => {
                    if (this.brandsCompetenceActive.includes(b.name)) {
                        return b
                    }
                })
                brands = [this.dataCompetenceLastYear[0], this.dataCompetenceLastYear[1], ...brands]
            }
            return brands
        },
        dataFilteredByBrand() {
            let brands = this.data
            if (this.competenceBrandsSelected.length > 0) {
                brands = this.data.filter((b) => {
                    if (this.brandsCompetenceActive.includes(b.name)) {
                        return b
                    }
                })
                brands = [this.data[0], ...brands]
            }
            return brands
        },
        dataFilteredByBrandForChart() {
            let brands = this.data
            if (this.competenceBrandsSelected.length > 0) {
                brands = this.data.filter((b) => {
                    if (this.brandsCompetenceActive.includes(b.name)) {
                        return b
                    }
                })
            }
            if (this.typeNode !== 'group') {
                brands[0].competence = [brands[0].sector, ...brands.slice(1, brands.length)]
            } else {
                brands = [this.data[0], ...brands]
            }
            return brands
        },
        getDataSetSentiments() {
            let labels = []
            let datasets = [{ label: '', backgroundColor: [], fill: true, data: [], maxBarThickness: 52 }, { label: '', backgroundColor: [], fill: true, data: [], maxBarThickness: 52 }, { label: '', backgroundColor: [], fill: true, data: [], maxBarThickness: 52 }]
            let bgPositive = '#89C445'
            let bgNegative = '#F36627'
            let bgNeutral = '#FCAE19'
            let dataPositive = []
            let dataNegative = []
            let dataNeutral = []
            if (this.data[0]) {
                labels.push(this.$gettext('Average competence'))
                dataPositive.push(this.$options.filters.formatNumber(this.data[0].sector.review_percent_positive, 1, 1).replace(',', '.'))
                dataNegative.push(this.$options.filters.formatNumber(this.data[0].sector.review_percent_negative, 1, 1).replace(',', '.'))
                dataNeutral.push(this.$options.filters.formatNumber(this.data[0].sector.review_percent_neutral, 1, 1).replace(',', '.'))
                for (let item in this.dataFilteredByBrand) {
                    labels.push(this.dataFilteredByBrand[item].name)
                    dataPositive.push(this.$options.filters.formatNumber(this.dataFilteredByBrand[item].review_percent_positive, 1, 1).replace(',', '.'))
                    dataNegative.push(this.$options.filters.formatNumber(this.dataFilteredByBrand[item].review_percent_negative, 1, 1).replace(',', '.'))
                    dataNeutral.push(this.$options.filters.formatNumber(this.dataFilteredByBrand[item].review_percent_neutral, 1, 1).replace(',', '.'))
                }
            }

            datasets[2].backgroundColor = bgPositive
            datasets[2].data = dataPositive
            datasets[2].label = this.$gettext('Positives')
            datasets[1].backgroundColor = bgNeutral
            datasets[1].data = dataNeutral
            datasets[1].label = this.$gettext('Neutral')
            datasets[0].backgroundColor = bgNegative
            datasets[0].data = dataNegative
            datasets[0].label = this.$gettext('Negatives')
            return { labels, datasets }
        },
        getAvgSector() {
            if (this.data[0] && this.data[0].sector) {
                return { 'sector_avg_review_count': this.data[0].sector.avg_review_count, 'sector_avg_rating_global': this.data[0].sector.review_rating_global }
            } else {
                return { 'sector_avg_review_count': 0, 'sector_avg_rating_global': 0 }
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.fixed-position {
    position: fixed;
    z-index: 2;
    right: 20px;
    background: #F2F2F2;
}

.chip {
    width: 20px;
    height: 8px;
    border-radius: 15px;

    &.chip-blue {
        background-color: #00A6D9;
    }

    &.chip-black {
        background-color: #222226;
    }

    &.chip-red {
        background-color: #F93549;
    }
}

.color-blue {
    font-size: 0.75rem;
    color: #00A6D9;
}

.color-black {
    font-size: 0.75rem;
    color: rgba(22, 22, 22, 0.8);
}

.color-red {
    font-size: 0.75rem;
    color: #F93549;
}

.tableGlobal {

    .headerTableRanking {
        .headerItem {
            text-align: right;
            font-size: 0.75rem;
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: right;
            color: rgba(22, 22, 22, 0.8);

            img {
                cursor: pointer;
            }
        }
    }

    .contentTableRanking {
        .itemContent {
            text-align: center;
            font-size: 0.875rem;
            border-top: 1px solid #9797973d;
            height: 41px;
            display: flex;
            align-items: center;
        }
    }
}

.v-application #tableRanking .elevation-1 {
    box-shadow: none !important;
}

#tableValoracionMedia {
    .col-name {
        max-width: 20.33%;
        flex: 0 0 20.33%;
        margin-right: 2.5%;
        padding-left: 10px;
        text-align: left;
        justify-content: initial;

        &.textBlackGroup {
            color: #000;
        }

        &.media-cadena {
            color: #979797;

            span {
                letter-spacing: -0.02px;
            }
        }
    }

    .col-note {
        max-width: 35.47%;
        flex: 0 0 35.47%;
        margin-right: 2.5%;
        text-align: left;
        justify-content: initial;
    }

    .col-opinions {
        max-width: 10.47%;
        flex: 0 0 10.47%;
        margin-right: 2.5%;
    }
}

.gridFull {
    .bodyIndicators {
        .progressbar {
            margin-bottom: 0;
        }
    }
}

.colBrand {
    width: 14%;
    flex: 14%;
    max-width: 14%;

    .colChartBrand {
        width: 10%;
        min-width: 90px;
        display: inline-block;
        margin-right: 1%;
    }
}

.rowMainSector {
    display: flex;
    width: 100%;
}

.rowFilterBrandCompetence {
    padding-left: 20px;

    .gr-filter-input-wrapper {
        margin-bottom: 0;
    }
}
</style>
