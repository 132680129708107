<template lang="pug">
div
  v-container.gastroprice-content.with-menu.d-flex.flex-column(fluid)    
    #boxMapCompetence.selectFilterPizza.block-content.flex-nowrap
      #filtersFixed.filter-top.d-flex.flex-wrap.pl-0(v-if="dataFilters")
        //filtro tipo promocion
        v-col.col-brand.mr-1.pl-0
          v-select.pt-0.selectFilterGP(v-model="promocionTipoSelect" :items="dataFilters.promotion_types" placeholder="Tipo promoción"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeTipoPromocion")
            template(slot="append")
              v-icon(color="terciary") mdi-chevron-down
            template(v-slot:selection="{ item, index }")
              span.terciary--text.text-uppercase.text-elipsis {{ item.name }}
            template(v-slot:item="{ item }")
              span.option-filter-brand {{ item.name }}
        //filtro zona
        v-col.col-brand.mr-1.pl-0
          v-select.pt-0.selectFilterGP(v-model="zoneSelect" :items="dataFilters.zones" placeholder="Zona"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeZone")
            template(slot="append")
              v-icon(color="terciary") mdi-chevron-down
            template(v-slot:selection="{ item, index }")
              span.terciary--text.text-uppercase.text-elipsis {{ item.name }}
            template(v-slot:item="{ item }")
              span.option-filter-brand {{ item.name }}
        //filtro tipo masa
        v-col.col-brand.mr-1.pl-0
          v-select.pt-0.selectFilterGP(v-model="tipoMasaSelect" :items="dataFilters.doughs" placeholder="Tipo de masa"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeTipoMasa")
            template(slot="append")
              v-icon(color="terciary") mdi-chevron-down
            template(v-slot:selection="{ item, index }")
              span.terciary--text.text-uppercase.text-elipsis {{ item.name }}
            template(v-slot:item="{ item }")
              span.option-filter-brand {{ item.name }}
        //filtro tipo pizza
        v-col.col-brand.mr-1.pl-0
          v-select.pt-0.selectFilterGP(v-model="tipoPizzaSelect" :items="dataFilters.pizza_types" placeholder="Tipo de pizza"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeTipoPizza")
            template(slot="append")
              v-icon(color="terciary") mdi-chevron-down
            template(v-slot:selection="{ item, index }")
              span.terciary--text.text-uppercase.text-elipsis {{ item.name }}
            template(v-slot:item="{ item }")
              span.option-filter-brand {{ item.name }}
        v-col.col-brand.mr-1.pl-0
          div.filter.filtroDate
            date-picker.pt-1(class="calendar-cn" v-model="calendar.date" value-type="format" 
            :formatter="momentFormat" 
            :lang="calendar.lang" 
            :format="calendar.format" type="week" 
            :first-day-of-week="calendar.firstDayOfWeek" 
            :clearable="calendar.clearable" 
            :disabled-date="notBeforeToday" 
            :not-before="dataFilters.promo_date" 
            @input="changeCalendar")
        //filtro por producto
        v-col.col-brand.mr-1.pl-0
          v-autocomplete.autocompleteProducts.v-text-field(clearable dense :items="productsCustom" placeholder="Producto" v-model="productSelected" @change="changeProduct")
            template(slot="append")
              v-icon(color="terciary") mdi-chevron-down
    //TABLA PROMOCIONES
    .gridFull.mt-10(v-if="isLoading")
      v-skeleton-loader(type="table")
    div#boxDataTablePromotionsPizza.d-flex.flex-wrap(v-else)
      div.brandPizza(v-for="(brand, index) in brandsShow")
        .head.d-flex.align-center
          .colFormato.colPizza
            img(:src="getImageBrand(brand)"  style="height:35px")
            span.d-block {{ brand }}
          .colDelivery.colPizza
            span Delivery Propio
          .colGlovo.colPizza
            img(:src="logoGlovo" style="height:18px")
            span.d-block Glovo
          .colJustEat.colPizza
            img(:src="logoJustEat" style="height:18px")
            span.d-block Just Eat
          .colUberEats.colPizza
            img(:src="logoUberEats" style="height:18px")
            span.d-block Uber Eats
          .colSala.colPizza
            span Sala
          .colTakeAway.colPizza
            span Take Away
        // PROMOCIONES DE PIZZAS ESTANDAR
        .body(v-if="promocionTipoSelect.name !== 'Puntuales'")
          .d-flex.align-center(v-for="(formato, index) in formatosFiltered" :class="{'rowBlue': index % 2 != 0}")
            .colFormato.colPizza.d-flex.align-center
              span {{ formato }}
            .colDelivery.colPizza
              div.text-right.deliveryPrice &nbsp;
              .d-flex
                div.previusPrice.text-right {{ getDataPropio(brand,formato,'Delivery Propio').previous_price }}  
                div.price.text-right {{ getDataPropio(brand,formato,'Delivery Propio').price }}                 
              div.text-right.datePromo {{ getDataPropio(brand,formato,'Delivery Propio').end_date }}
            .colGlovo.colPizza
              .d-flex.boxDelivery
                div.previusPrice {{ getDataPropio(brand,formato,'Glovo', true).previous_aggregator_delivery_price }}
                div.text-right.deliveryPrice {{ getDataPropio(brand,formato,'Glovo', true).aggregator_delivery_price }}
              .d-flex
                div.previusPrice.text-right {{ getDataPropio(brand,formato,'Glovo', true).previous_price }}
                div.price.text-right {{ getDataPropio(brand,formato,'Glovo', true).price }}                 
              div.text-right.datePromo {{ getDataPropio(brand,formato,'Glovo', true).end_date }}
            .colJustEat.colPizza
              .d-flex.boxDelivery
                div.previusPrice {{ getDataPropio(brand,formato,'Just Eat', true).previous_aggregator_delivery_price }}
                div.text-right.deliveryPrice {{ getDataPropio(brand,formato,'Just Eat', true).aggregator_delivery_price }}
              .d-flex
                div.previusPrice.text-right {{ getDataPropio(brand,formato,'Just Eat', true).previous_price }}    
                div.price.text-right {{ getDataPropio(brand,formato,'Just Eat', true).price }}                 
              div.text-right.datePromo {{ getDataPropio(brand,formato,'Just Eat', true).end_date }}
            .colUberEats.colPizza
              .d-flex.boxDelivery
                div.previusPrice {{ getDataPropio(brand,formato,'Uber Eats', true).previous_aggregator_delivery_price }}
                div.text-right.deliveryPrice {{ getDataPropio(brand,formato,'Uber Eats', true).aggregator_delivery_price }}
              .d-flex
                div.previusPrice.text-right {{ getDataPropio(brand,formato,'Uber Eats', true).previous_price }}   
                div.price.text-right {{ getDataPropio(brand,formato,'Uber Eats', true).price }}                 
              div.text-right.datePromo {{ getDataPropio(brand,formato,'Uber Eats', true).end_date }}
            .colSala.colPizza
              div.text-right.deliveryPrice &nbsp;
              .d-flex
                div.previusPrice.text-right {{ getDataPropio(brand,formato,'Dine-in').previous_price }}   
                div.price.text-right {{ getDataPropio(brand,formato,'Dine-in').price }}                 
              div.text-right.datePromo {{ getDataPropio(brand,formato,'Dine-in').end_date }}
            .colTakeAway.colPizza
              div.text-right.deliveryPrice &nbsp;
              .d-flex
                div.previusPrice.text-right {{ getDataPropio(brand,formato,'Take away').previous_price }}   
                div.price.text-right {{ getDataPropio(brand,formato,'Take away').price }}
              div.text-right.datePromo {{ getDataPropio(brand,formato,'Take away').end_date }} 
        // PROMOCIONES DE PIZZAS ESTANDAR
        .body(v-if="promocionTipoSelect.name === 'Puntuales'")
          div(v-if="dataTable && !Object.keys(dataTable).includes(brand)")
            div.pa-3.sinPromo Sin promociones
          div(v-else-if="dataTable && Object.keys(dataTable).includes(brand)")
            div(v-for="(promo, indexPromo) in dataTable[brand]")
              .d-flex.align-center(v-for="(formatoPromo, index) in promo" :class="{'rowBlue': index % 2 != 0}")

                .colFormato.colPizza.d-flex.flex-column.justify-center
                  strong.d-block.namePromo {{ indexPromo }}
                  span {{ index }}
                .colDelivery.colPizza
                  .d-flex
                    div.previusPrice.text-right {{ getDataPropioPuntual(brand,indexPromo,index,'Delivery Propio').previous_price }}  
                    div.price.text-right {{ getDataPropioPuntual(brand,indexPromo,index,'Delivery Propio').price }}                 
                  div.text-right.datePromo {{ getDataPropioPuntual(brand,indexPromo,index,'Delivery Propio').end_date }}
                .colGlovo.colPizza
                  .d-flex.boxDelivery
                    div.previusPrice {{ getDataPropioPuntual(brand,indexPromo,index,'Glovo', true).previous_aggregator_delivery_price }}
                    div.text-right.deliveryPrice {{ getDataPropioPuntual(brand,indexPromo,index,'Glovo', true).aggregator_delivery_price }}
                  .d-flex
                    div.previusPrice.text-right {{ getDataPropioPuntual(brand,indexPromo,index,'Glovo', true).previous_price }}
                    div.price.text-right {{ getDataPropioPuntual(brand,indexPromo,index,'Glovo', true).price }}                 
                  div.text-right.datePromo {{ getDataPropioPuntual(brand,indexPromo,index,'Glovo', true).end_date }}
                .colJustEat.colPizza
                  .d-flex.boxDelivery
                    div.previusPrice {{ getDataPropioPuntual(brand,indexPromo,index,'Just Eat', true).previous_aggregator_delivery_price }}
                    div.text-right.deliveryPrice {{ getDataPropioPuntual(brand,indexPromo,index,'Just Eat', true).aggregator_delivery_price }}
                  .d-flex
                    div.previusPrice.text-right {{ getDataPropioPuntual(brand,indexPromo,index,'Just Eat', true).previous_price }}    
                    div.price.text-right {{ getDataPropioPuntual(brand,indexPromo,index,'Just Eat', true).price }}                 
                  div.text-right.datePromo {{ getDataPropioPuntual(brand,indexPromo,index,'Just Eat', true).end_date }}
                .colUberEats.colPizza
                  .d-flex.boxDelivery
                    div.previusPrice {{ getDataPropioPuntual(brand,indexPromo,index,'Uber Eats', true).previous_aggregator_delivery_price }}
                    div.text-right.deliveryPrice {{ getDataPropioPuntual(brand,indexPromo,index,'Uber Eats', true).aggregator_delivery_price }}
                  .d-flex
                    div.previusPrice.text-right {{ getDataPropioPuntual(brand,indexPromo,index,'Uber Eats', true).previous_price }}   
                    div.price.text-right {{ getDataPropioPuntual(brand,indexPromo,index,'Uber Eats', true).price }}                 
                  div.text-right.datePromo {{ getDataPropioPuntual(brand,indexPromo,index,'Uber Eats', true).end_date }}
                .colSala.colPizza
                  div.text-right.deliveryPrice &nbsp;
                  .d-flex
                    div.previusPrice.text-right {{ getDataPropioPuntual(brand,indexPromo,index,'Dine-in').previous_price }}   
                    div.price.text-right {{ getDataPropioPuntual(brand,indexPromo,index,'Dine-in').price }}                 
                  div.text-right.datePromo {{ getDataPropioPuntual(brand,indexPromo,index,'Dine-in').end_date }}
                .colTakeAway.colPizza
                  div.text-right.deliveryPrice &nbsp;
                  .d-flex
                    div.previusPrice.text-right {{ getDataPropioPuntual(brand,indexPromo,index,'Take away').previous_price }}   
                    div.price.text-right {{ getDataPropioPuntual(brand,indexPromo,index,'Take away').price }}
                  div.text-right.datePromo {{ getDataPropioPuntual(brand,indexPromo,index,'Take away').end_date }}   

    //GRAFICA
    .gridFull(v-if="isLoading")
      v-skeleton-loader(type="table")
    div#boxChartPromotion.gridFull.mt-4(v-else)
      v-row.headTitleGrid
        v-col.titleGrid(md="3")
          translate Evolución precio medio
        v-col.iconInfo(md="9")
          .selectFilterPizza.filter-top.d-flex.flex-wrap(v-if="dataFilters")
            //filtro tamaño pizza
            v-col.col-brand.pt-0.mr-1.pl-0
              v-select.pt-0.selectFilterGP(v-model="sizePizzaSelect" :items="dataFilters.sizes" placeholder="Tamaño"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeSizePizza")
                template(slot="append")
                  v-icon(color="terciary") mdi-chevron-down
                template(v-slot:selection="{ item, index }")
                  span.terciary--text.text-uppercase.text-elipsis {{ item.name }}
                template(v-slot:item="{ item }")
                  span.option-filter-brand {{ item.name }}
            //filtro canal
            v-col.col-brand.pt-0.mr-1.pl-0
              v-select.pt-0.selectFilterGP(v-model="channelSelect" :items="aggregatorsAndServices" placeholder="Canal"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeChannel")
                template(slot="append")
                  v-icon(color="terciary") mdi-chevron-down
                template(v-slot:selection="{ item, index }")
                  span.terciary--text.text-uppercase.text-elipsis {{ item.name }}
                template(v-slot:item="{ item }")
                  span.option-filter-brand {{ item.name }}

      v-row.bodyIndicators.text-left.align-center.mt-3
        chart-evolution-price(:chartData="{labels, datasets}" :legend="legends" style="width:100%") 

</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es'
import SrvGastroPricing from '@/services/gastropricing.js'
import ChartEvolutionPrice from '@/components/charts/EvolutionMiddlePrice.vue'
export default {
  components: { DatePicker, ChartEvolutionPrice },
  name: 'promoPizzas',
  data() {
    return {
      dataFilters: null,
      dataTable: null,
      dataChart: null,
      datasets: null,
      legends: null,
      labels: null,
      zoneSelect: null,
      tipoMasaSelect: null,
      tipoPizzaSelect: null,
      promocionTipoSelect: null,
      productSelected: null,
      sizePizzaSelect: null,
      channelSelect: null,
      formatos: [],
      brands: [],
      'calendar': {
        'shortcuts': false,
        'clearable': false,
        'date': null,
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
            firstWeekContainsDate: 1,
          },
          monthBeforeYear: false,
        },
        'format': 'YYYY-MM-DD', // 'MMM YYYY'
        'firstDayOfWeek': 1
      },
      momentFormat: {
        //[optional] Date to String
        stringify: (date) => {
          return date ? this.$moment(date).format('YYYY-WW') : ''
        },
        //[optional]  String to Date
        parse: (value) => {

          return value ? this.$moment(value, 'YYYY-WW').toDate() : null
        },
        //[optional] getWeekNumber
        getWeek: (date) => {
          return this.$moment(date).isoWeek() // a number
        }
      }


    }
  },
  async created() {
    await this.getDataFilters()
    await this.getDataPromotions()
    await this.getDataChart()
  },
  computed: {
    limitDate() {
      return this.$moment().format('YYYY-MM') + '-01'
    },
    brandsShow() {
      return this.brands
    },
    isLoading() {
      return this.$store.getters.isLoading
    },
    productsCustom() {
      let products = []
      if (this.dataFilters.products) {
        this.dataFilters.products.forEach((p) => {
          products.push(p)
        })
      }
      return products
    },
    logoGlovo() {
      let url = null
      if (this.dataFilters && this.dataFilters.aggregators) {
        let find = this.dataFilters.aggregators.filter(a => a.name.toLowerCase() === 'glovo')
        if (find.length > 0) {
          url = find[0].image
        }
      }
      return url
    },
    logoJustEat() {
      let url = null
      if (this.dataFilters && this.dataFilters.aggregators) {
        let find = this.dataFilters.aggregators.filter(a => a.name.toLowerCase() === 'just eat')
        if (find.length > 0) {
          url = find[0].image
        }
      }
      return url
    },
    logoUberEats() {
      let url = null
      if (this.dataFilters && this.dataFilters.aggregators) {
        let find = this.dataFilters.aggregators.filter(a => a.name.toLowerCase() === 'uber eats')
        if (find.length > 0) {
          url = find[0].image
        }
      }
      return url
    },
    formatosFiltered() {
      if (this.promocionTipoSelect.name === 'Puntuales') {
        return this.formatos
      } else {
        let sizes = []
        this.dataFilters.sizes.forEach((s) => {
          if (s.name.toLowerCase() !== '3 familiares' && s.name.toLowerCase() !== '3 medianas') {
            sizes.push(s.name)
          }
        })
        return sizes
      }
    },
    aggregatorsAndServices() {
      let aggregators = this.dataFilters.aggregators.map((a) => {
        a.type = 'aggregator'
        return a
      })
      let services = this.dataFilters.services.map((s) => {
        s.type = 'service'
        return s
      })
      return [...aggregators, ...services]
    }
  },
  methods: {
    notBeforeToday(date) {
      if (this.$moment(date) <= this.$moment(this.dataFilters.promo_date_end)) {
        return false
      } else {
        return true
      }
    },
    getImageBrand(brand) {
      let url = null
      if (this.dataFilters && this.dataFilters.brands) {
        let find = this.dataFilters.brands.filter(a => a.name.toLowerCase() === brand.toLowerCase())
        if (find.length > 0) {
          url = find[0].image
        }
      }
      return url
    },
    getDataPropio(brand, format, type, channel = false) {
      let data = { price: null, previous_price: null, end_date: null, aggregator_delivery_price: null, previous_aggregator_delivery_price: null }
      if (!channel) {
        if (this.dataTable && this.dataTable[brand] && this.dataTable[brand][format] && this.dataTable[brand][format][type] && this.dataTable[brand][format][type]['null']) {
          data.price = this.dataTable[brand][format][type]['null'].price ? this.$options.filters.formatNumber(this.dataTable[brand][format][type]['null'].price, 2, 2) : null
          data.previous_price = this.dataTable[brand][format][type]['null'].previous_price ? this.$options.filters.formatNumber(this.dataTable[brand][format][type]['null'].previous_price, 2, 2) : null
          data.end_date = this.dataTable[brand][format][type]['null'].end_date ? this.$moment(this.dataTable[brand][format][type]['null'].end_date).format('MM/YY') : null
        }
      } else {
        if (this.dataTable && this.dataTable[brand] && this.dataTable[brand][format] && this.dataTable[brand][format]['null'] && this.dataTable[brand][format]['null'][type]) {
          data.price = this.dataTable[brand][format]['null'][type].price ? this.$options.filters.formatNumber(this.dataTable[brand][format]['null'][type].price, 2, 2) : null
          data.previous_price = this.dataTable[brand][format]['null'][type].previous_price ? this.$options.filters.formatNumber(this.dataTable[brand][format]['null'][type].previous_price, 2, 2) : null
          data.aggregator_delivery_price = this.dataTable[brand][format]['null'][type].aggregator_delivery_price ? this.$options.filters.formatNumber(this.dataTable[brand][format]['null'][type].aggregator_delivery_price, 2, 2) : null
          data.previous_aggregator_delivery_price = this.dataTable[brand][format]['null'][type].previous_aggregator_delivery_price ? this.$options.filters.formatNumber(this.dataTable[brand][format]['null'][type].previous_aggregator_delivery_price, 2, 2) : null
          data.end_date = this.dataTable[brand][format]['null'][type].end_date ? this.$moment(this.dataTable[brand][format]['null'][type].end_date).format('MM/YY') : null


        }
      }
      return data
    },
    getDataPropioPuntual(brand, promo, format, type, channel = false) {
      let data = { price: null, previous_price: null, end_date: null, aggregator_delivery_price: null }
      if (!channel) {
        if (this.dataTable && this.dataTable[brand] && this.dataTable[brand][promo] && this.dataTable[brand][promo][format] && this.dataTable[brand][promo][format][type] && this.dataTable[brand][promo][format][type]['null']) {
          data.price = this.dataTable[brand][promo][format][type]['null'].price ? this.$options.filters.formatNumber(this.dataTable[brand][promo][format][type]['null'].price, 2, 2) : null
          data.previous_price = this.dataTable[brand][promo][format][type]['null'].previous_price ? this.$options.filters.formatNumber(this.dataTable[brand][promo][format][type]['null'].previous_price, 2, 2) : null
          data.end_date = this.dataTable[brand][promo][format][type]['null'].end_date ? this.$moment(this.dataTable[brand][promo][format][type]['null'].end_date).format('MM/YY') : null
        }
      } else {
        if (this.dataTable && this.dataTable[brand] && this.dataTable[brand][promo] && this.dataTable[brand][promo][format] && this.dataTable[brand][promo][format]['null'] && this.dataTable[brand][promo][format]['null'][type]) {
          data.price = this.dataTable[brand][promo][format]['null'][type].price ? this.$options.filters.formatNumber(this.dataTable[brand][promo][format]['null'][type].price, 2, 2) : null
          data.previous_price = this.dataTable[brand][promo][format]['null'][type].previous_price ? this.$options.filters.formatNumber(this.dataTable[brand][promo][format]['null'][type].previous_price, 2, 2) : null
          data.aggregator_delivery_price = this.dataTable[brand][promo][format]['null'][type].aggregator_delivery_price ? this.$options.filters.formatNumber(this.dataTable[brand][promo][format]['null'][type].aggregator_delivery_price, 2, 2) : null
          data.end_date = this.dataTable[brand][promo][format]['null'][type].end_date ? this.$moment(this.dataTable[brand][promo][format]['null'][type].end_date).format('MM/YY') : null


        }
      }
      return data
    },
    changeCalendar() {
      let partes = this.calendar.date.split('-')

      let query = { ...this.$route.query, promo_date__year: partes[0], promo_date__week: partes[1] }
      this.$router.replace({ query: query }).catch(() => { })
      this.getDataPromotions()
    },
    changeZone() {
      let query = { ...this.$route.query, pizza_zone: this.zoneSelect.id }
      this.$router.replace({ query: query }).catch(() => { })
      this.getDataPromotions()
      this.getDataChart()
    },
    changeTipoMasa() {
      let query = { ...this.$route.query, pizza_doughs: this.tipoMasaSelect.id }
      this.$router.replace({ query: query }).catch(() => { })
      this.getDataPromotions()
      this.getDataChart()
    },
    changeTipoPizza() {
      let query = { ...this.$route.query, pizza_type_pizza: this.tipoPizzaSelect.id }
      this.$router.replace({ query: query }).catch(() => { })
      this.getDataPromotions()
      this.getDataChart()
    },
    changeTipoPromocion() {
      let query = { ...this.$route.query, pizza_type_promotion: this.promocionTipoSelect.id }
      this.$router.replace({ query: query }).catch(() => { })
      this.getDataPromotions()
      this.getDataChart()
    },
    changeProduct() {
      let query = { ...this.$route.query, pizza_product: this.productSelected }
      this.$router.replace({ query: query }).catch(() => { })
      this.getDataPromotions()

    },
    changeSizePizza() {
      let query = { ...this.$route.query, pizza_size: this.sizePizzaSelect.id }
      this.$router.replace({ query: query }).catch(() => { })
      this.getDataChart()
    },
    changeChannel() {
      let query = this.$route.query
      if (this.channelSelect.type === 'aggregator') {
        delete query.pizza_service
        let queryNew = { ...query, pizza_aggregator: this.channelSelect.id }
        this.$router.replace({ query: queryNew }).catch(() => { })
      } else {
        delete query.pizza_aggregator
        let queryNew = { ...query, pizza_service: this.channelSelect.id }
        this.$router.replace({ query: queryNew }).catch(() => { })
      }
      this.getDataChart()
    },
    getColorBrand(brand) {
      let brandLo = brand.toLowerCase()
      if (brandLo === "domino's") {
        return '#006494'
      }
      if (brandLo === "telepizza") {
        return '#FF010B'
      }
      if (brandLo === "pizza hut") {
        return '#921126'
      }
      if (brandLo === "papa john's") {
        return '#077954'
      }
    },
    async getDataFilters() {
      this.$store.commit('LOADING', true)
      const res = await SrvGastroPricing.getPizzasPromotionsExtraData()
      if (res.status === 200) {
        res.data.brands.forEach((brand) => {
          this.brands.push(brand.name)
        })
        res.data.sizes.forEach((size) => {
          this.formatos.push(size.name)
        })
        this.zoneSelect = res.data.zones[0] //pizza_zone
        if (this.$route.query.pizza_zone) {
          this.zoneSelect = res.data.zones.filter((i) => i.id == this.$route.query.pizza_zone)[0]
        }
        if (this.$route.query.promo_date__year) {
          this.calendar.date = this.$route.query.promo_date__year + '-' + this.$route.query.promo_date__week
        }
        this.tipoMasaSelect = res.data.doughs[0] //pizza_doughs
        if (this.$route.query.pizza_doughs) {
          this.tipoMasaSelect = res.data.doughs.filter((i) => i.id == this.$route.query.pizza_doughs)[0]
        }
        this.tipoPizzaSelect = res.data.pizza_types[0] //pizza_type_pizza
        if (this.$route.query.pizza_type_pizza) {
          this.tipoPizzaSelect = res.data.pizza_types.filter((i) => i.id == this.$route.query.pizza_type_pizza)[0]
        }
        this.promocionTipoSelect = res.data.promotion_types[0] //pizza_type_promotion
        if (this.$route.query.pizza_type_promotion) {
          this.promocionTipoSelect = res.data.promotion_types.filter((i) => i.id == this.$route.query.pizza_type_promotion)[0]
        }
        //this.productSelected = res.data.products[0] //pizza_product
        if (this.$route.query.pizza_product) {
          this.productSelected = this.$route.query.pizza_product
        }
        this.sizePizzaSelect = res.data.sizes[0] //pizza_size
        if (this.$route.query.pizza_size) {
          this.sizePizzaSelect = res.data.sizes.filter((i) => i.id == this.$route.query.pizza_size)[0]
        }
        this.channelSelect = res.data.aggregators[0] //pizza_aggregator
        if (this.$route.query.pizza_aggregator) {
          this.channelSelect = res.data.aggregators.filter((i) => i.id == this.$route.query.pizza_aggregator)[0]
        }
        if (this.$route.query.pizza_service) {
          this.channelSelect = res.data.services.filter((i) => i.id == this.$route.query.pizza_service)[0]
        }
        if (!this.calendar.date) {
          this.calendar.date = res.data.year_end + '-' + res.data.week_end
        }
        this.dataFilters = res.data
        this.$store.commit('LOADING', false)
      }
    },
    async getDataPromotions() {
      // "zone", "pizza_dough", "pizza_type", "type", "promo_date", "product_name"
      this.$store.commit('LOADING', true)
      const res = await SrvGastroPricing.getPizzasPromotions({
        zone: this.zoneSelect.id, pizza_dough: this.tipoMasaSelect.id, pizza_type: this.tipoPizzaSelect.id, type: this.promocionTipoSelect.id,
        product_name: this.productSelected, promo_date: this.calendar.date ? this.calendar.date : null
      })
      if (res.status === 200) {
        this.dataTable = res.data

        this.$store.commit('LOADING', false)
      }
    },
    async getDataChart() {
      this.$store.commit('LOADING', true)
      // "zone", "pizza_dough", "pizza_type", "type", "pizza_size", "service", "aggregator", "product_name"
      let aggregator = null
      let service = null
      if (this.$route.query.pizza_aggregator) {
        aggregator = this.$route.query.pizza_aggregator
      } else {
        service = this.$route.query.pizza_service
      }
      const res = await SrvGastroPricing.getPizzasPromotionsChart({
        zone: this.zoneSelect.id, pizza_dough: this.tipoMasaSelect.id, pizza_type: this.tipoPizzaSelect.id, type: this.promocionTipoSelect.id,
        pizza_size: this.sizePizzaSelect.id, aggregator: aggregator, service: service, product_name: this.productSelected, promo_date: this.calendar.date ? this.calendar.date : null
      })
      if (res.status === 200) {
        this.dataChart = res.data
        this.legends = Object.keys(this.dataChart)
        this.datasets = []
        this.labels = []
        let dataBrand = []
        Object.keys(this.dataChart).forEach((brand) => {
          for (let i = 0; i < this.dataChart[brand].length; i++) {
            if (!this.labels.includes(this.dataChart[brand][i][0])) {
              this.labels.push(this.dataChart[brand][i][0])
            }
          }
        })
        this.labels.sort()
        //as
        Object.keys(this.dataChart).forEach((brand) => {
          dataBrand = []
          for (let i = 0; i < this.labels.length; i++) {
            let num = null
            this.dataChart[brand].forEach((e) => {
              if (e[0] == this.labels[i]) {
                num = e[1]
              }
            })
            dataBrand.push(num)
          }
          this.datasets.push({
            label: brand,
            backgroundColor: this.getColorBrand(brand),
            borderColor: this.getColorBrand(brand),
            fill: false,
            data: dataBrand
          })

        })
        this.$store.commit('LOADING', false)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.namePromo {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.col-brand {
  flex: 0 0 187px;
  max-width: 187px;
}

.selectFilterGP {
  font-size: 0.75rem;
}

#boxDataTablePromotionsPizza {
  margin-top: 55px;

  .brandPizza {
    width: 49%;
    background-color: #fff;
    border: 1px solid #D9DFE8;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    border-radius: 4px;
    margin-bottom: 20px;

    &:nth-child(even) {
      margin-left: 1%;
    }

    .head {
      color: #000000;
      font-size: 0.813rem;
      font-weight: 600;
      border-bottom: 1px solid #000000;
      text-align: center;

      .colPizza {
        min-height: 76px;
        display: flex;
        align-items: center;
        flex-flow: column;
        justify-content: center;
      }
    }

    .body {

      .sinPromo,
      .colFormato {
        font-size: 0.813rem;
      }

      .colPizza {
        border-bottom: 1px solid #CECECE;
        min-height: 81.52px;
        display: flex;
        align-self: stretch;
        flex-flow: column;

        .datePromo {
          font-size: 0.688rem;
          color: #4F4853;
        }

        .previusPrice {
          color: #F93549;
          width: 50%;
        }

        .price {
          font-weight: 600;
          width: 50%;
        }

        .deliveryPrice {
          color: #00AFE5;
          min-height: 24px;
        }

        &.borderChange {
          border: 1px solid #F93549;
        }

        .boxDelivery {
          align-items: baseline;

          .previusPrice {
            width: 50%;
            font-size: 0.875rem;
          }

          .deliveryPrice {
            width: 50%;
          }
        }
      }
    }

    .rowBlue {
      background-color: #FAFDFE;
    }

    .colPizza {
      padding: 8px;
    }

    .colFormato {
      width: 18.30%;
      border-right: 1px solid #000000;
    }

    .colDelivery {
      width: 13.61%;
      border-right: 1px solid #CECECE;
    }

    .colGlovo {
      width: 13.61%;
      border-right: 1px solid #CECECE;
    }

    .colJustEat {
      width: 13.61%;
      border-right: 1px solid #CECECE;
    }

    .colUberEats {
      width: 13.61%;
      border-right: 1px solid #000000;
    }

    .colSala {
      width: 13.61%;
      border-right: 1px solid #CECECE;
    }

    .colTakeAway {
      width: 13.61%
    }
  }
}

#boxChartPromotion {
  margin-left: 0;

  .selectFilterPizza {
    background-color: transparent;
  }
}

.option-filter-brand {
  font-size: 0.813rem;
}
</style>