<template>
<svg version="1.1" id="cat" sodipodi:docname="fr (1).svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:svg="http://www.w3.org/2000/svg" 	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16.9 16.9" 	 style="enable-background:new 0 0 16.9 16.9;" xml:space="preserve">
<path class="st0" fill="#FCDD09" d="M10.5,0.3C10.5,0.3,10.5,0.3,10.5,0.3C5.9-0.9,1.4,1.9,0.2,6.4c-1.1,4.5,1.6,9.1,6.2,10.2
	c4.5,1.1,9.1-1.6,10.2-6.2S15,1.4,10.5,0.3z"/>
<path class="st1" fill="#DA121A" d="M3.8,15.5h9.2c0.8-0.5,1.5-1.2,2.1-2H1.7C2.3,14.3,3,15,3.8,15.5z"/>
<path class="st1" fill="#DA121A" d="M13.4,1.6H3.5c-0.8,0.5-1.4,1.2-2,2h13.8C14.8,2.8,14.2,2.2,13.4,1.6z"/>
<path class="st1" fill="#DA121A" d="M0.2,6.4C0.2,6.8,0.1,7.2,0.1,7.6h16.8c-0.1-0.7-0.2-1.4-0.5-2H0.5C0.4,5.9,0.3,6.1,0.2,6.4z"/>
<path class="st1" fill="#DA121A" d="M0.6,11.5h15.7c0.1-0.3,0.3-0.7,0.3-1.1c0.1-0.3,0.1-0.6,0.2-0.9H0.1C0.2,10.2,0.3,10.9,0.6,11.5z"/>
</svg>

</template>

<script>
export default {
  name: "flag-ca"
}
</script>