<template lang="pug">
div
  .gridFull(v-if="isLoading")
    v-skeleton-loader(type="table")
  div.gridFull.narrow(v-else)
    v-row.headTitleGrid 
      v-col.titleGrid(md="4")
        translate(v-if="!isGenericEntity") Restaurant Ranking
        span(v-else) Ranking de negocios
      v-col.titleInfo(md="6")
        .d-flex.align-baseline
          .block-info(v-if="dataAverage.rating_global")
            translate Valoratión average:
            v-chip.chipInfo.mx-2(:color="getColorByNoteNormalized({note: dataAverage.rating_global, type: 'color'})" label) {{dataAverage.rating_global | formatNumber(1,1)}}
          .block-info.ml-3(v-if="dataAverage.avg_reviews")
            translate Average by number of opinions:
            span.font-weight-med.mx-2 {{dataAverage.avg_reviews}}
      v-col.iconInfo.text-right(md="2")
        span.downloadExcel.mr-5(@click="downloadExcel()")
          img(:src="imgCommons.download" width="10")
        .d-inline-block
          v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="50" nudge-left="10" content-class="tooltip-help tooltip-help-left")
            template(v-slot:activator="{ on, attrs }")
              img(:src="imgCommons.help" v-bind="attrs" v-on="on")
            span(v-if="!isAdverbia") {{helpRanking}}
            span {{ helpRankingAdverbia }}
    v-row.headIndicators.mt-0
      v-col.py-0(sm="2") 
        v-text-field.filter-text.search-icon(
          v-if="typeNode !== 'center'"
          v-model="filters.nameItem"
          :label="labelSearch"
          dense
          hide-details
          outlined)
          template(v-slot:append)
            img.mt-n1(
              style="width:19px;height:19px"
              :src="imgCommons.search"
              alt="")
    v-row.headIndicators.text-left.align-center.flex-nowrap
      v-col.colId
        translate # 
      v-col.colName
        translate Name
        img.ml-1.cursor-pointer(:src="imgCommons.order" @click="sortAppointments('name')")

      v-col.colBrand
        translate Brand
      v-col.colGPS
        translate % GPS
        img.ml-1.cursor-pointer(:src="imgCommons.order" @click="sortAppointments('gps')")
      v-col.colDetractores.d-flex.flex-col.justify-center.align-center.cNegative
        img(src="@/assets/img/detractoresPeople.svg" width="40")
        div.d-flex.items-center.justify-center
          translate Detractors
          img.ml-1.cursor-pointer(:src="imgCommons.order" @click="sortAppointments('gps_negative_reviews')")
      v-col.colPromotores.d-flex.flex-col.justify-center.align-center.cPositive
        img(src="@/assets/img/promotoresPeople.svg" width="40")
        div.d-flex.items-center.justify-center
          translate Promotores
          img.ml-1.cursor-pointer(:src="imgCommons.order" @click="sortAppointments('gps_positive_reviews')")
      v-col.text-right.colNota
        translate Assessment
        img.ml-1.cursor-pointer(:src="imgCommons.order" @click="sortAppointments('rating_global')")
      v-col.text-right.colNOpiniones
        translate Nº of reviews
        img.ml-1.cursor-pointer(:src="imgCommons.order" @click="sortAppointments('total_reviews')")
      v-col.d-flex.align-center.colProvincia.flex-nowrap
        translate Province


    v-row.bodyIndicators.text-left.align-center.mt-3(v-if="dataRanking" v-for="(item, index) in paginated" :class="{'b-top' : index > 0, 'row-average': item.average}") 
      v-col.py-0.colId(v-if="!item.average")
        span {{item.pos}}
      v-col.colName(v-if="!item.average")
        span(v-if="typeNode !== 'center'" :class="{'link-text': item.level.level > 0}" @click="goItemNextLevel(item,true)") {{item.name}}
        span(v-else) {{item.name}}
      v-col.py-0.colBrand(v-if="!item.average")
        span {{item.level.name}}
      v-col.d-flex.align-center.colGPS.justify-center
        span(:class="getColorPercent(item.average ? item.gps * 100 : item.gps)") {{ item.gps | formatNumber}} %
      v-col.d-flex.align-center.colDetractores.justify-center
        span {{ item.gps_negative_reviews }}
      v-col.d-flex.align-center.colPromotores.justify-center
        span {{ item.gps_positive_reviews }}
      v-col.py-0.text-right.colNota(v-if="!item.average")
        v-chip.chipInfo.mx-2.white--text.text-right(:color="getColorByNoteNormalized({note: item.rating_global, type: 'color'})" label small) {{item.rating_global | formatNumber(1,1)}}
      v-col.py-0.text-right.colNOpiniones(v-if="!item.average")
        span.pr-5 {{item.total_reviews}}
      v-col.py-0.colProvincia(v-if="!item.average")
        span {{item.region}}
      // FILA VALORACION MEDIA
      v-col.d-flex(v-if="item.average")
        img.mr-5(:src="imgCommons.arrows" )
        span.font-weight-med {{textAverage}}
</template>

<script>
import SrvNetwork from '@/services/network.js'
import commonMixins from "@/mixins/common.js"
export default {
  props: ['paramCOD'],
  mixins: [commonMixins],
  data() {
    return {
      current: 1,
      pageSize: 20,
      dataRanking: [],
      dataAverage: [],
      filters: {
        province: '',
        nameItem: ''
      },
      labelSearch: this.$gettext('Search'),
      sortParam: 'rating_global',
      sortDesc: true,
      helpRanking: this.$gettext('Ranking of the restaurants of the whole group by number of opinions and valuation during the sectioned period, according to the type of restaurants. Use the Sites selector to see only your information. You can sort the columns and use the search engine to see the position of the restaurants with respect to the Company average. And the search engine that is further to the right to filter by province'),
      helpRankingAdverbia: 'Ranking de los negocios de todo el grupo por número de opiniones y valoración durante el periodo seccionado, según el tipo de negocios. Utilice el selector de Sitios para ver sólo su información. Puedes ordenar las columnas y utilizar el buscador para ver la posición de los negocios respecto a la media de la Compañía. Y el buscador que está más a la derecha para filtrar por provincia'

    }
  },
  created() {
    if (this.$store.getters.getNode > 0) {
      this.getRankingAverage()
      this.getRanking()
    }

  },
  mounted() {
    this.scroll()
  },
  computed: {
    indexStart() {
      return (this.current - 1) * this.pageSize
    },
    indexEnd() {
      return this.indexStart + this.pageSize
    },
    paginated() {
      // Init vars
      let filtered = false
      let arrFiltered = this.dataRanking.slice()
      let mainFormat = this.$options.filters.formatNumber((this.dataAverage.rating_global + 0.01), 1, 2).replace(',', '.')
      // Filter array

      if (this.filters.nameItem && this.filters.nameItem.toLowerCase().trim()) {
        arrFiltered = arrFiltered.filter(c => (c.name && c.name.toLowerCase().indexOf(this.filters.nameItem.toLowerCase().trim()) > -1) ||
          (c.region && c.region.toLowerCase().indexOf(this.filters.nameItem.toLowerCase().trim()) > -1))
        filtered = true
        arrFiltered.push({ 'average': true, 'level': '', 'name': '', 'region': '', 'rating_global': mainFormat, 'gps': this.dataAverage.rating_global })
        arrFiltered = this.sortArray(arrFiltered)
      }

      if (!filtered) {
        arrFiltered.push({ 'average': true, 'level': '', 'name': '', 'region': '', 'rating_global': mainFormat, 'gps': this.dataAverage.rating_global })
        arrFiltered = this.sortArray(arrFiltered)
        arrFiltered = arrFiltered.slice(0, this.indexEnd)
      }
      let rank = 0
      let arrFilteredCorrectIndex = arrFiltered.map((a) => {
        if (!Object.keys(a).includes('average')) {
          rank += 1
          a.pos = rank
        }
        return a
      })

      return arrFilteredCorrectIndex

    },
    correctIndex() {
      // var vm = this;
      return function (data) {
        let param = ''

        if (this.sortParam === 'total_reviews') {
          param = 'avg_reviews'
        } else {
          param = 'rating_global'
        }

        if (this.sortDesc) {
          if (data[this.sortParam] < this.dataAverage[param]) {
            return 0
          }
        } else {
          if (data[this.sortParam] > this.dataAverage[param]) {
            return 0
          }
        }
        return 1
      };

    },
    textAverage() {
      if (this.sortParam === 'total_reviews') {
        return this.typeNode !== 'center' ? this.$gettext('Average by Opinions') : this.$gettext('Brand Average by Opinions')
      } else {
        return this.typeNode !== 'center' ? this.$gettext('Average by Valuation') : this.$gettext('Brand Average by Valuation')
      }
    }
  },
  watch: {
    filterApply: function () {
      this.getRanking()
      this.getRankingAverage()
    },
  },
  methods: {
    sortArray(arrFiltered) {
      // Sort array
      arrFiltered = arrFiltered.sort((a, b) => {
        // return b[this.sortParam] - a[this.sortParam]
        let secondOrder = 'total_reviews'
        if (this.sortParam != 'rating_global') {
          secondOrder = 'rating_global'
        }
        if (this.sortParam === 'name') {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        } else {
          if (b[this.sortParam] != a[this.sortParam]) {
            return b[this.sortParam] - a[this.sortParam]
          } else {
            return b[secondOrder] - a[secondOrder]
          }
        }

      })
      if (!this.sortDesc) {
        arrFiltered = arrFiltered.reverse()
      }
      return arrFiltered
    },
    // OBTENEMOS INDICADORES
    getRanking() {
      this.$store.commit('LOADING', true)
      SrvNetwork.getRankings()
        .then(res => {
          if (res.status === 200) {
            if (this.isAdverbia) {
              res.data.map(e => e.rating_global = e.rating_global ? e.rating_global / 2 : e.rating_global)
            }
            this.dataRanking = res.data
            this.$store.commit('LOADING', false)
          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    getRankingAverage() {
      SrvNetwork.getRankingsAverage()
        .then(res => {
          if (res.status === 200) {
            if (this.isAdverbia) {
              res.data.avg_reviews = res.data.avg_reviews / 2
              res.data.rating_global = res.data.rating_global / 2
            }
            this.dataAverage = res.data

          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    next() {
      this.current++;
    },
    scroll() {
      let wrapper = document.getElementById("app")
      let bottomOfWindow = false
      window.onscroll = () => {
        if (parseInt(document.documentElement.scrollTop) + window.innerHeight === wrapper.offsetHeight || parseInt(document.documentElement.scrollTop) + 1 + window.innerHeight === wrapper.offsetHeight) {
          bottomOfWindow = true
        } else {
          bottomOfWindow = false
        }
        if (bottomOfWindow) {
          this.current++
        }
      };
    },
    sortAppointments(param) {
      this.sortDesc = this.sortParam === param ? !this.sortDesc : true
      this.sortParam = param
    },
  }
}
</script>

<style scoped lang="scss">
.colId {
  fleX: 0 0 3%;
}

.colName {
  flex: 0 0 18%
}

.colBrand {
  flex: 0 0 10%
}

.colGPS {
  flex: 0 0 7%
}

.colDetractores {
  flex: 0 0 10%
}

.colPromotores {
  flex: 0 0 10%
}

.colNota {
  flex: 0 0 10%
}

.colNOpiniones {
  flex: 0 0 13%
}

.colProvincia {
  flex: 0 0 18%
}
</style>