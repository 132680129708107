import { render, staticRenderFns } from "./CompetenceLastYear.vue?vue&type=template&id=01f966f5&scoped=true&lang=pug"
import script from "./CompetenceLastYear.vue?vue&type=script&lang=js"
export * from "./CompetenceLastYear.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01f966f5",
  null
  
)

export default component.exports