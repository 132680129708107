
<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18" :class="{'dBlock': block}">
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path d="M3.93,6.64a1,1,0,0,0,1.4,0,5.7,5.7,0,0,0,1.49-3c0-.05,0-.11,0-.16A4.12,4.12,0,0,0,5.21.2,1,1,0,0,0,3.83.4,1,1,0,0,0,4,1.8a2.09,2.09,0,0,1,.83,1.58,3.68,3.68,0,0,1-.94,1.85A1,1,0,0,0,3.93,6.64Z" :fill="color" />
        <path d="M8.81,6.64a1,1,0,0,0,1.4,0,5.7,5.7,0,0,0,1.49-3c0-.05,0-.11,0-.16A4.1,4.1,0,0,0,10.09.2,1,1,0,0,0,8.71.4a1,1,0,0,0,.2,1.4,2.12,2.12,0,0,1,.82,1.58,3.6,3.6,0,0,1-.94,1.85A1,1,0,0,0,8.81,6.64Z" :fill="color" />
        <path d="M13.69,6.64a1,1,0,0,0,1.4,0,5.7,5.7,0,0,0,1.49-3,.86.86,0,0,0,0-.16A4.09,4.09,0,0,0,15,.2a1,1,0,0,0-1.38.2,1,1,0,0,0,.2,1.4,2.08,2.08,0,0,1,.82,1.58,3.6,3.6,0,0,1-.94,1.85A1,1,0,0,0,13.69,6.64Z"  :fill="color" />
        <path d="M17.43,7.39H1.71A1.72,1.72,0,0,0,0,9v.17a9.53,9.53,0,0,0,19,0A1.7,1.7,0,0,0,17.43,7.39ZM9.5,16A7.52,7.52,0,0,1,2,9.38H17A7.54,7.54,0,0,1,9.5,16Z"  :fill="color" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IcoKitchen",
  props: {
    color: {
        type: String,
        default: '#000000',
        required: false
    },
    block: {
        type: Boolean,
        default: true,
        required: false
    }
  }
}
</script>

<style scoped>
    .dBlock{
        display: block;
    }
</style>