<template lang="pug">
    //GRID CATEGORIAS COVER MANAGER
    div.margin-bottom-0.col-6
        skeleton-card(v-if="isLoading && !data.length")
        v-card.gridFull.ma-0(v-if="!isLoading && data.length" :class="{'borderDelivery': typeOpinionSelected === 'delivery'}")
            .d-flex.headTitleGrid
                .block-title.titleGrid
                    span.mb-0.title-card {{ $gettext('Rating in Cover Manager') }}
                    //span.ml-1.tDelivery(v-if="typeOpinionSelected === 'delivery'") Delivery
                .block-help.ml-auto
                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="40" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                        template(v-slot:activator="{ on, attrs }")
                            img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                        span(v-if="isAdverbia") {{help}}
                        span(v-else="isAdverbia") {{helpAdverbia}}
            .card-content
                .row.text-left
                    #tableCategoriasMarcas.tableGlobal
                        div.headerTableRanking.row.ma-0
                            div.headerItem.col-name
                            div.headerItem.col-numberTest
                            div.headerItem.col-global
                            div.headerItem.col-numero
                                .boxIcon
                                    v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
                                        template(v-slot:activator="{ on, attrs }")
                                            div(v-bind="attrs" v-on="on")
                                                ico-environment(width="15px")
                                        span {{ $gettext('Environment')|Capitalize }}  
                            div.headerItem.col-numero
                                .boxIcon
                                    v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
                                        template(v-slot:activator="{ on, attrs }")
                                            div(v-bind="attrs" v-on="on")
                                                ico-service(width="15px")
                                        span {{ $gettext('Service')|Capitalize }}  
                            div.headerItem.col-numero
                                .boxIcon
                                    v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
                                        template(v-slot:activator="{ on, attrs }")
                                            div(v-bind="attrs" v-on="on")
                                                ico-kitchen(width="15px")
                                        span {{ $gettext('Kitchen')|Capitalize }}  
                            div.headerItem.col-numero
                                .boxIcon
                                    v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
                                        template(v-slot:activator="{ on, attrs }")
                                            div(v-bind="attrs" v-on="on")
                                                ico-price(width="12px")
                                        span {{ $gettext('Price')|Capitalize }}                                      

                        div.headerTableRanking.row.ma-0
                            div.headerItem.col-name
                                p
                            div.headerItem.col-numberTest
                                p.mb-0 {{ $gettext('No. of surveys') }}
                            div.headerItem.col-global
                                p.mb-0 Global
                            div.headerItem.col-numero
                                p.mb-0 {{ $gettext('Environment')|Capitalize }}
                            div.headerItem.col-numero
                                p.mb-0 {{ $gettext('Service')|Capitalize }}
                            div.headerItem.col-numero
                                p.mb-0 {{ $gettext('Kitchen')|Capitalize }}
                            div.headerItem.col-numero
                                p.mb-0 {{ $gettext('Price')|Capitalize }}

                            div.contentTableRanking.row.ma-0
                                p.ml-3(v-if="!data") #[translate No data]
                                div.itemContent.col-12.pa-0(v-for="(item, index) in data" :key="index")
                                    div.item.col-name(:class="getTextBlackIfGroup(item)")
                                        span(v-if="index == 0") {{item.name}}
                                        span.link-text(v-if="index>0" @click="goItemNextLevel(item)") {{item.name}}
                                    div.item.col-numberTest
                                        span {{item.current_period.review_count}}
                                    div.item.col-global
                                        span.mb-0(:class="getColorNote(item.current_period.rating_global)") {{ item.current_period.rating_global | formatNumber(1,1) }}
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.arrow.ml-4(:class="getArrow(item.current_period.rating_global,item.tend_period.rating_global)")
                                            span {{ item.tend_period.rating_global | formatNumber }}
                                    div.headerItem.col-numero
                                        p.mb-0(:class="getColorNote(item.current_period.ambient)") {{ item.current_period.ambient | formatNumber(1,1)}}
                                    div.headerItem.col-numero
                                        p.mb-0(:class="getColorNote(item.current_period.service)") {{ item.current_period.service | formatNumber(1,1)}}
                                    div.headerItem.col-numero
                                        p.mb-0(:class="getColorNote(item.current_period.cuisine)") {{ item.current_period.cuisine | formatNumber(1,1)}}
                                    div.headerItem.col-numero
                                        p.mb-0(:class="getColorNote(item.current_period.price)") {{ item.current_period.price | formatNumber(1,1)}}
                                    //div.item.col-numero(v-for="(note, categorie) in item.categories" :key="categorie")
                                        p.mb-0(:class="getColorNote(note)") {{ note | formatNumber(1,1) }} 
                            
</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import commonMixins from "@/mixins/common.js"
import brandMixin from "@/mixins/brand.js"
import IcoEnvironment from "@/components/common/IcoEnvironment.vue"
import IcoKitchen from "@/components/common/IcoKitchen.vue"
import IcoService from "@/components/common/IcoService.vue"
import IcoPrice from "@/components/common/IcoPrice.vue"

export default {
    props:['data'],
    mixins: [commonMixins, brandMixin],
    components: { SkeletonCard, IcoEnvironment, IcoKitchen, IcoService, IcoPrice },
    data(){
        return {
            help: this.$gettext('En función del periodo seleccionado, se muestran las opiniones recibidas y la valoración de los distintos items incluidos en las encuestas de Cover Manager. Se calcula la tendencia con respecto al mismo periodo del año anterior. Se analiza sobre el grupo de restaurantes que marca la selección en el menú de la izquierda.'),
            helpAdverbia: this.$gettext('En función del periodo seleccionado, se muestran las opiniones recibidas y la valoración de los distintos items incluidos en las encuestas de Cover Manager. Se calcula la tendencia con respecto al mismo periodo del año anterior. Se analiza sobre el grupo de negocios que marca la selección en el menú de la izquierda.')
        }
    },
}
</script>

<style lang="scss" scoped>
#tableCategoriasMarcas{
    .headerTableRanking{
        .headerItem{
            justify-content: center;
            .boxIcon{
                background-color: #F2F2F2;
                border-radius: 50px;
                padding: 10px;
                
            }
        }
    }
        .col-name{
            max-width: 25%;
            flex: 0 0 25%;
            padding-left: 2%;
            text-align: left;
            &.textBlackGroup{
                color:#000;
            }
            &.media-cadena{
                color:#979797;
                span{
                    letter-spacing: -0.02px;
                }
            }
        }
        .col-numero{            
            margin-right: 2%;
            flex: 0 0 8%;
            max-width: 8%;

        }
        .col-numberTest{
            width: 16%;
            margin-right: 2%;
        }
        .col-global{
            width: 12%;
            margin-right: 2% ;
            text-align: center;
            justify-content: center;
        }
    }
</style>