<template lang="pug">
div.boxSectionHeaderAndBody
  div#navtop(:class="{'anchoEscritorioSubnavCerrado': subNavClosed}")
    div.sectionmenu
      div.firstlevel.d-flex.justify-space-between
        div
          router-link(:to="{name: 'NetworkResume', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Resume]
          router-link(v-if="typeNode !== 'group'" :to="{name: 'NetworkValoration', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Positioning]
          router-link(v-if="typeNode === 'group'" :to="{name: 'NetworkValorationRanking', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Positioning]
          router-link(:to="{name: 'NetworkGps', params: {paramCOD: this.paramCOD}, query: queryParams}") {{ isAdverbia ? 'APS' : 'GPS' }}
          router-link.text-no-wrap(:to="{name: 'GoogleTrend', params: {paramCOD: this.paramCOD}, query: queryParams}") True Rating
          router-link(v-if="nodeLevel && hasCompetence" :to="{name: 'NetworkCompetence', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Competence]
          router-link.text-no-wrap(v-if="nodeLevel" :to="{name: 'IndividualCompetence', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Comparador de marca]
        select-country-and-logout              
  div.gridSection
    div.sectionmenu
      div.secondlevel(:class="{'d-none': this.$router.history.current.name.indexOf('NetworkValoration') < 0 && this.$router.history.current.name.indexOf('NetworkCompetence') < 0}")

        .submenuValoration(v-if="$router.history.current.name.indexOf('NetworkValoration') >= 0 ")
          span(v-if="typeNode !== 'group'")
            router-link(:to="{name: 'NetworkValorationPositioning', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Positioning]
            router-link(:to="{name: 'NetworkValorationPositioningLast', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Positioning last year]
            router-link(:to="{name: 'NetworkValorationPositioningLast12Weeks', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Positioning last 12 weeks]

          router-link(:to="{name: 'NetworkValorationRanking', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Ranking]
        .submenuValoration(v-if="$router.history.current.name.indexOf('NetworkCompetence') >= 0 ")
          router-link(v-if="!['IndividualNetworkCompetenceGlobal', 'IndividualNetworkCompetenceCompareLocals'].includes($router.history.current.name)" :to="{name: 'NetworkCompetenceGlobal', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Global]
          router-link(v-else :to="{name: 'IndividualNetworkCompetenceGlobal', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Global]
          router-link(v-if="($config.featureConfig.mapCompetence || userName.indexOf('gastroranking.es') > -1 || userName.indexOf('theysay.me') > -1) && hasCompetence && !['IndividualNetworkCompetenceGlobal', 'IndividualNetworkCompetenceCompareLocals'].includes($router.history.current.name)" :to="{name: 'NetworkCompetenceMap', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Map]
          router-link(v-if="!['IndividualNetworkCompetenceGlobal', 'IndividualNetworkCompetenceCompareLocals'].includes($router.history.current.name) && ($config.featureConfig.mapCompetence || userName.indexOf('gastroranking.es') > -1 || userName.indexOf('theysay.me') > -1)" :to="{name: 'NetworkCompetenceCompareLocals', params: {paramCOD: this.paramCOD}, query: queryParams}")
            span(v-if="isGenericEntity") #[translate Comparar negocios]
            span(v-else) #[translate Comparar locales]
          router-link(v-else-if="$config.featureConfig.mapCompetence || userName.indexOf('gastroranking.es') > -1 || userName.indexOf('theysay.me') > -1" :to="{name: 'IndividualNetworkCompetenceCompareLocals', params: {paramCOD: this.paramCOD}, query: queryParams}")
            span(v-if="isGenericEntity") #[translate Comparar negocios]
            span(v-else) #[translate Comparar locales]

      filters-and-bread-crumb(:paramCOD="paramCOD" :onlyNetwork="true" :only-bread-crumb="avoidFiltersRender")
      warning-not-delivery-not-internet(v-if="itemNotHasInternet && this.$store.getters.getShowNotInternet" type="internet")
      router-view.bg-gray.pt-0
</template>

<script>
import FiltersAndBreadCrumb from "@/components/filters/filtersAndBreadCrumb.vue"
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import WarningNotDeliveryNotInternet from '@/components/common/WarningNotDeliveryNotInternet.vue'
import SelectCountryAndLogout from "@/components/common/SelectCountryAndLogout.vue"
import brandMixin from '@/mixins/brand.js'
import Breadcrumbs from "@/components/common/Breadcrumbs.vue"

export default {
  props: ['paramCOD', 'type', 'site', 'typeRestaurant', 'after', 'before'],
  mixins: [commonMixins, brandMixin],
  components: { FiltersAndBreadCrumb, WarningNotDeliveryNotInternet, SelectCountryAndLogout, Breadcrumbs },
  created() {
    this.$store.commit(types.UPDATE_SECTION, 1)
    this.loadUrlParams()
  },
  watch: {
    filterForSites: function () {
      this.$store.dispatch('getSitesOpinions')
    },
  },
  computed: {
    avoidFiltersRender() {
      return ["GoogleTrend"].includes(this.$route.name)
    }
  }
}
</script>


