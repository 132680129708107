<template>
    <div class="d-flex" id="popupNotificationStats" v-if="showPopup">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.8" height="18.498" viewBox="0 0 16.8 18.498">
  <path id="Trazado_5027" data-name="Trazado 5027" d="M15.75,7.44a5.629,5.629,0,0,0-1.493-3.846,4.877,4.877,0,0,0-7.213,0A5.629,5.629,0,0,0,5.55,7.44C5.55,13.787,3,15.6,3,15.6H18.3S15.75,13.787,15.75,7.44ZM12.12,18.15a1.7,1.7,0,0,1-2.941,0" transform="translate(-2.25 -1.25)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
</svg>
    <div class="flex-1 mx-2 text-13">
        <p class="mb-0">Tienes <strong>{{ $store.getters.getNotificationStats.total_restaurants_with_notifications }} {{ isGenericEntity ? 'negocios' : 'restaurantes' }} </strong> con <strong>{{ $store.getters.getNotificationStats.total }} notificaciones</strong>
pendientes de revisar.</p>
    </div>        
    <button @click="createCookie()"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
  <g id="x-circle_2_" data-name="x-circle (2)" transform="translate(0.501 0.5)">
    <circle id="Elipse_587" data-name="Elipse 587" cx="6.5" cy="6.5" r="6.5" transform="translate(0.499 0.5)" fill="none" stroke="#3d3935" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line id="Línea_852" data-name="Línea 852" x1="5" y2="5" transform="translate(4.499 4.5)" fill="none" stroke="#3d3935" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <line id="Línea_853" data-name="Línea 853" x2="5" y2="5" transform="translate(4.499 4.5)" fill="none" stroke="#3d3935" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>
</svg>
</button>
    </div>
</template>
<script>
import brandMixin from '@/mixins/brand.js'
export default{
    mixins: [brandMixin],
    data(){
        return {

        }
    },
methods:{
    createCookie(){
        this.$cookies.set(`popupnotificationstats_${this.$store.getters.getNode}`,this.$store.getters.getNode,60 * 60 * 12)
    },
},
computed:{
    showPopup(){
        if (this.$cookies.isKey(`popupnotificationstats_${this.$store.getters.getNode}`)) {
            return false
        } else {
            return true
        }
    }
}

}
</script>
<style scoped>
#popupNotificationStats{
    background-color: #FF929D;
    border: 1px solid #F93549;
    border-radius: 4px;
    width: 333px;
    padding: 11px;
    align-items: start;
    position: absolute;
    right: 0;
    top: 27px;
    z-index: 20;
}
</style>