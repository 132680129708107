<template lang="pug">
div.margin-bottom-0.pa-3.col-12
    skeleton-card(v-if="isLoading")
    v-card.gridFull.ma-0(v-if="!isLoading" :class="{'borderDelivery': typeOpinionSelected === 'delivery'}")
        .d-flex.headTitleGrid.d-flex
            .block-title.titleGrid
                translate.mb-0.title-card Sentimiento por categorias
                span.ml-1.tDelivery(v-if="typeOpinionSelected === 'delivery'") Delivery
            .block-legend.d-flex.align-center
                p.mr-3.ml-15.mb-0.chip-legend.positive
                translate Positive
                p.mx-3.mb-0.chip-legend.neutral
                translate Neutral
                p.mx-3.mb-0.chip-legend.negative
                translate Negatives
            .block-help.ml-auto

        .card-content
            .row.text-left
                .tableGlobal
                    div.row.ma-0(v-if="data && data.length")
                        div.headerItem.col-name
                            p
                        div.headerItem.col-cat(v-for="cat in data[0].categories" :style="getWithColCategory(75, data[0].categories)")
                            p.mb-0 {{ cat }}             

                    div.contentTableRanking.row.ma-0
                        p.ml-3(v-if="!data") #[translate No data]
                        // ELEMENTO ACTIVO
                        div.itemContent.col-12.px-0.py-2
                            div.item.col-name
                                strong {{ elementActive.name }}
                            div.item.col-cat(v-for="itemCat in elementActive.feeling_by_categories" :style="getWithColCategory(75, elementActive.feeling_by_categories)")
                                chart-bar-three-colors(:lineGuides="true" :tooltip="true" :heightGuides="((data.length - 1) * 56) + 82" :percentGreen="itemCat.positive || 0" :percentOrange="itemCat.neutral || 0" :percentRed="itemCat.negative || 0" :render-details="true" :total="itemCat.count" :big="true")

                        // MEDIA DE LA COMPETENCIA
                        div.itemContent.col-12.px-0.py-2
                            div.item.col-name
                                span Media de la competencia
                            div.item.col-cat(v-for="itemCat in elementActive.sector.feeling_by_categories")
                                chart-bar-three-colors(:slim="true" :lineGuides="false" :tooltip="true" :percentGreen="itemCat.positive || 0" :percentOrange="itemCat.neutral || 0" :percentRed="itemCat.negative || 0" :render-details="true" :total="itemCat.count")

                        div.itemContent.col-12.pa-0.pt-2.pb-2(v-for="(item, index) in others" :key="index")
                            div.item.col-name
                                span {{item.name}}
                                div.numberRestaurants
                                    span(v-if="!isGenericEntity") {{ item.n_centers }} #[translate Restaurants]
                                    span(v-else) {{ item.n_centers }} Negocios
                            div.item.col-cat(v-for="itemCat in item.feeling_by_categories")
                                chart-bar-three-colors(:slim="true" :lineGuides="false" :tooltip="true" :percentGreen="itemCat.positive || 0" :percentOrange="itemCat.neutral || 0" :percentRed="itemCat.negative || 0" :render-details="true" :total="itemCat.count")
                            
                                    
</template>
<script>

import SkeletonCard from '@/components/common/skeletonCard.vue'
import commonMixins from "@/mixins/common.js"
import ChartBarThreeColors from '@/components/charts/BarTrheeColors.vue'

export default {
    props: ['data'],
    components: { SkeletonCard, ChartBarThreeColors },
    mixins: [commonMixins],
    data() {
        return {
            HelpCompetence: this.$gettext('')
        }
    },
    computed: {
        elementActive() {
            return this.data.slice(0, 1)[0]
        },
        others() {
            return this.data.slice(1, this.data.length)
        }
    }
}
</script>

<style lang="scss" scoped>
.headTitleGrid {
    .block-legend {
        font-size: 0.75rem;
    }
}

.col-name {
    width: 20%;
    text-align: left;
    padding-left: 15px;
}

.col-cat {
    flex: auto;
    margin-right: 2%;
}

.tableGlobal {
    .contentTableRanking {
        .itemContent {
            &.group {
                border: 0;
            }

            &:nth-child(even) {
                background-color: #F2F2F2;
            }
        }
    }
}

.numberRestaurants {
    font-size: 0.75rem;
}

.headerItem {

    &.col-cat {
        background-color: #F2F2F2;
        margin: 10px 2% 10px 0;
        font-size: 0.875rem;
        font-weight: 600;
        text-align: center;
        padding: 5px 0;
    }
}
</style>