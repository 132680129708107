<template>
    <header id="cartHeader">
        RESTAURANTE <span id="cartRestaurantName">{{ name }}</span>
    </header>

</template>
<script>
    export default {
        props: ['name']
    }
</script>

<style scoped>
#cartHeader{
    background-color: #222226;
    color: #fff;
    font-size: 14px;
    border-radius: 6px;
    padding: 24.5px 16px;
}
#cartRestaurantName{
    margin-left: 16px;
    font-size: 16px;
    font-weight: 600;
}
</style>