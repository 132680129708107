<template lang="pug">
  v-container
    v-row
      v-col
        p Facebook
</template>

<script>

export default {
  name: 'FacebookConfig',
  data() {
    return {
      img: {
        background_img: require('@/assets/img/404/error_fondo.svg'),
        error: require('@/assets/img/404/error.svg')
      }
    }
  },
  created() {
  },
  components: {},
  methods: {}
}
</script>
<style lang="scss">
#login {
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: white;
}
</style>
