<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16.293" height="16.7" viewBox="0 0 16.293 16.7" :class="{'dBlock': block}">
        <path id="source_icons_handbag" d="M6.135,7.167H3.459A1.573,1.573,0,0,0,2,8.833v7.5A1.573,1.573,0,0,0,3.459,18H15.134a1.573,1.573,0,0,0,1.459-1.667v-7.5a1.573,1.573,0,0,0-1.459-1.667H12.459m-6.324,0V3.5A.472.472,0,0,1,6.573,3h5.448a.472.472,0,0,1,.438.5V7.167m-6.324,0h6.324m-6.324,0v4m6.324-4v4" transform="translate(-1.15 -2.15)" fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.7"/>
      </svg>

</template>

<script>
export default {
  name: "IcoOrder",
  props: {
    color: {
        type: String,
        default: '#000000',
        required: false
    },
    block: {
        type: Boolean,
        default: true,
        required: false
    }
  }
}
</script>

<style scoped>
    .dBlock{
        display: block;
    }
</style>