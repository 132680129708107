<template>
    <div id="getInfoCentersCompetenceChatGPT">
        <div class="d-flex header items-center">
            <img :src="isAdverbia ? ImgRobotAdverbia : ImgRobot" />
            <span class="flex-1 pl-4 text-11">INFORME DE COMPETENCIA</span>
            <img :src="ImgLogoChatgpt" />
        </div>
        <div class="filter pa-2" v-if="typeNode === 'center'">
            <range-distance @getRestaurants="getRestaurants()" :isViewInMap="true"></range-distance>
        </div>
        <div class="infoNumberOfCenters">
            <p>
                {{ $gettext(isGenericEntity ? 'Selecciona máximo 4 negocios de la competencia y genera un informe analizando los datos con chatGPT' : 'Selecciona máximo 4 locales de la competencia y genera un informe analizando los datos con chatGPT') }}
            </p>
        </div>
        <ul v-if="competence && competence.length > 0" class="scrollCustom flex-1"
            :class="{ 'premiumDisabled': !hasChatGptMap }">
            <li v-for="(center, index) in competence" :key="index">
                <input :id="'centerCompetence' + center.center_id"
                    :disabled="!hasChatGptMap || centersChecked.length >= 4 && !centersChecked.includes(center.center_id)"
                    type="checkbox" v-model="centersChecked" :value="center.center_id" />
                <label :for="'centerCompetence' + center.center_id">
                    {{ center.name }} <br />
                    {{ center.brand_name }} <br />
                    {{ center.address }}
                </label>
            </li>

        </ul>
        <div class="flex-1 justify-center d-flex items-center text-center" :class="{ 'premiumDisabled': !hasChatGptMap }"
            v-else><strong>No se han encontrado competidores a esta distancia.</strong></div>
        <div class="actions">
            <button @click="getInfoCompetence" :class="{ 'disabled': centersChecked.length === 0 }">Generar Informe de
                Competencia</button>
        </div>
        <ModalResponseChatGpt :visible="showResponse" :response="response" @close="setClose"></ModalResponseChatGpt>
    </div>
</template>
<script>
import SrvGlobal from '@/services/global.js'
import ModalResponseChatGpt from '@/components/competence/modalResponseChatGpt.vue'
import RangeDistance from '@/components/competence/RangeDistance.vue'
import CommonMixins from '@/mixins/common.js'
import brandMixin from '@/mixins/brand.js'
export default {
    props: ['centers'],
    components: { ModalResponseChatGpt, RangeDistance },
    mixins: [CommonMixins, brandMixin],
    created() {
    },
    data() {
        return {
            ImgRobot: require('@/assets/img/robot_pp.png'),
            ImgRobotAdverbia: require('@/assets/img/robot_ad_peque.png'),
            ImgLogoChatgpt: require('@/assets/img/logochatgpt.png'),
            centersChecked: [],
            showResponse: false,
            response: null
        }
    },
    methods: {
        setClose() {
            this.showResponse = false
            this.response = null
        },
        async getInfoCompetence() {
            if (this.hasChatGptMap) {
                let data = { data: { own: this.own, competence: this.centers.filter(e => this.centersChecked.includes(e.center_id)) } }
                this.showResponse = true
                const res = await SrvGlobal.getInfoCompetenceCenterChatGPT(data)
                if (res.status === 200) {
                    this.response = res.data.response
                }
            }
        }
    },
    computed: {
        hasChatGptMap() {
            return this.$store.getters.getUserInfo['can_use_chatgpt_on_maps']
        },
        own() {
            let centers = this.centers.filter(e => e.isOwn)
            centers.map(e => {
                delete e.isOwn
                return e
            })
            return centers
        },
        competence() {
            return this.centers.filter(e => !e.isOwn)
        }
    }
}
</script>
<style scoped lang="scss">
#getInfoCentersCompetenceChatGPT {
    width: 25%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: calc(100vh - 100px);
    font-size: 12px;

    .header {
        font-size: 11px;
        padding: 10px 10px 5px 10px;
    }

    .infoNumberOfCenters {
        background-color: #EEF3EF;
        padding: 10px;

        p {
            margin-bottom: 0;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        overflow-y: scroll;
        margin-bottom: 10px;
        margin-right: 10px;
        margin-top: 7px;

        li {
            display: flex;
            align-items: start;
            font-size: 11px;
            border-bottom: 1px solid #EEF3EF;
            padding: 6px 0px 6px 14px;
        }

        input {
            margin-right: 5px;
            margin-top: 2px;
        }
    }

    .actions {
        background-color: #EEF3EF;
        padding-top: 42px;
        padding-bottom: 52px;
        text-align: center;

        button {
            background-color: #0075FF;
            color: #fff;
            border-radius: 5px;
            padding: 5px 15px;

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
}
</style>