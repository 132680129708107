<template lang="pug">
div.categories.d-flex
  div(v-if="tags && tags.length > 0")
    span.boxcategory(v-for="category in tags")
      tag-internal(:category="category" :hasEditTags="hasEditTags" @removeTag="removeTagInternal" @linkFilterByCategory="linkFilterByCategory" :indexComment="indexComment" :hasLink="true")
      tag-internal( v-for="subcat in category.children" :hasEditTags="hasEditTags" @removeTag="removeTagInternal" :category="{...category, subcat: subcat}" :indexComment="indexComment" @linkFilterByCategory="linkFilterByCategory" :isSubcat="true" :hasLink="true")
  span.addTag(v-if="hasEditTags" @click="openModalAddCategories") +
  v-dialog(v-model="showAddCategories" max-width="223")
    v-card
      .headModal(class="lighten-2")
        span Añadir una categoría 
        button(@click="showAddCategories = false")
          svg(xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20")
            g(id="x-circle_2_" data-name="x-circle (2)" transform="translate(-1 -1)")
              circle(id="Elipse_587" data-name="Elipse 587" cx="9" cy="9" r="9" transform="translate(2 2)" fill="none" stroke="#3d3935" stroke-linecap="round" stroke-linejoin="round" stroke-width="2")
              line(id="Línea_852" data-name="Línea 852" x1="6" y2="6" transform="translate(8 8)" fill="none" stroke="#3d3935" stroke-linecap="round" stroke-linejoin="round" stroke-width="2")
              line(id="Línea_853" data-name="Línea 853" x2="6" y2="6" transform="translate(8 8)" fill="none" stroke="#3d3935" stroke-linecap="round" stroke-linejoin="round" stroke-width="2")


      v-row(no-gutters)

        v-col(cols="12")
          .tags 
            tag-internal(v-for="(item, index) in categories" :category="item" @click.native="addTag(item)" :hasEditTags="false" :indexComment="indexComment")
  span.d-flex.gap-0_5em.flex-row(v-if="complaint_products")
    span.text-12.rounded.border-gray.px-2.py-1.bg-white(v-for="complaintProduct in complaintProducts") {{ complaintProduct }}

</template>

<script>
import SrvFeedback from "@/services/feedback.js"
import TagInternal from '@/components/common/TagInternal.vue'
export default {
  components: { TagInternal },
  props: {
    'tags': [],
    'complaint_products': {
      required: false,
      default: null,
    },
    hasEditTags: {
      type: Boolean,
      required: true,
      default: false,
    },
    indexComment: {
      type: Number,
      required: false,
      default: 0
    },
    idComment: {
      type: Number,
      required: false,
      default: 0
    },
    isDelivery: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      showAddCategories: false,
      categories: null
    }
  },
  methods: {
    async addTag(item) {
      const res = await SrvFeedback.removeOrAddCategorie({ review_id: this.idComment, category_id: item.id, action: 'add', review_type: !this.isDelivery ? 'internet' : 'delivery' })
      if (res.status === 200) {
        this.showAddCategories = false
        this.$emit('addTagOk', { indexComment: this.indexComment, category: item })
      }
    },
    async loadTags() {
      if (this.hasEditTags) {
        const res = await SrvFeedback.getAvailableCategories(this.idComment, !this.isDelivery ? 'internet' : 'delivery')
        if (res.status === 200) {
          this.categories = res.data
        }
      }
    },
    linkFilterByCategory(val) {
      this.$emit('linkFilterByCategory', val)
    },
    removeTagInternal(data) {
      this.$emit('removeTag', data)
    },
    openModalAddCategories() {
      this.loadTags()
      this.showAddCategories = true
    }
  }
}
</script>
<style scoped lang="scss">
.addTag {
  background-color: #89C445;
  border-radius: 22px;
  width: 22px;
  height: 22px;
  color: #fff;
  display: inline-flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal {
  background-color: #FFFFFF;
}

.headModal {
  background-color: #FFFFFF;
  color: #3D3935;
  font-size: 14px !important;
  border-bottom: 1px solid #3D3935;
  padding: 10px;
  display: flex;
  align-items: center;

  button {
    position: absolute;
    right: 10px;
  }
}

.tags {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 20px;

  .category {
    width: fit-content;
    min-width: 76px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
</style>
