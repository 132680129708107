<template lang="pug">
div
  .gridFull(v-if="isLoading")
    v-skeleton-loader(type="table")
  div.gridFull(v-else)
    v-row.headTitleGrid.relative
      v-col.titleGrid(md="3")
        translate(v-if="!isGenericEntity") Restaurant positioning
        span(v-else) Posicionamiento de negocios
      v-col.mx-auto.d-flex.items-center.justify-center.py-3(md="6")
        div
          .d-flex.align-baseline(style="font-size: 0.875rem;")
            .block-info(v-if="dataAverage.rating_global")
              translate Valoración media de la Marca:
              v-chip.chipInfo.mx-2(style="font-size: 14px; color: white;" :color="getColorByNoteNormalized({note: getNoteNormalized(dataAverage.rating_global), type: 'color'})" label) {{getNoteNormalized(dataAverage.rating_global) | formatNumber(1,1)}}
            .block-info.ml-3(v-if="dataAverage.avg_reviews")
              translate Average by number of opinions:
              span.font-weight-med.mx-2 {{dataAverage.avg_reviews}}


      v-col.iconInfo.text-right(md="3")
        .d-inline-block
          v-tooltip(left color="#222226" open-on-click=true max-width="550" nudge-bottom="105" nudge-left="10" content-class="tooltip-help tooltip-help-left")
            template(v-slot:activator="{ on, attrs }")
              img(:src="imgCommons.help" v-bind="attrs" v-on="on")
            div
              span(v-if="!isAdverbia") #[translate Graphic representation of the restorations based on their grades and number of opinions in the selected period. 4 quadrants are identified]:
              span(v-else) Representación gráfica de los negocios en función de sus calificaciones y del número de dictámenes en el periodo seleccionado. Se identifican 4 cuadrantes.
              ul
                li #[translate Top right: High number of opinions and valuation above the average of the chain. They are the referents]
                li(v-if="!isAdverbia") #[translate Bottom right: Low number of opinions and valuation above the average of the chain. They are restaurants to enhance your communication]
                li(v-else) Abajo a la derecha: Bajo número de opiniones y valoración por encima de la media de la cadena. Son negocios para mejorar su comunicación
                li #[translate Top left: High number of opinions and valuation below the average of the chain: They require an Action Plan because in addition to having a low valuation their visibility is high]
                li #[translate Down left. Low number of opinions and valuation below the average of the chain. They require an Action Plan, but with less priority than the previous ones]
    v-row.bodyIndicators.text-left.align-center.mt-3
      v-col.text-right(cols="1")
        translate.text-gray-ligth.y-label nº of reviews
      v-col.row.ml-2.py-0
        chart-scatter(:data="dataChart" :maxYAxes = "maxYAxes" :averageX="getNoteNormalized(dataAverage.rating_global)" :averageY="dataAverage.avg_reviews")
</template>

<script>
import SrvNetwork from '@/services/network.js'
import chartScatter from '@/components/charts/scatterHtml.vue'
import commonMixins from "@/mixins/common.js"
export default {
  components: { chartScatter },
  mixins: [commonMixins],
  props: ['paramCOD'],
  data() {
    return {
      current: 1,
      pageSize: 20,
      dataRanking: [],
      dataAverage: [],
      dataChart: [],
      maxYAxes: 0,
      filters: {
        province: '',
        nameItem: ''
      },
      sortParam: 'total_reviews',
      sortDesc: true,

    }
  },
  created() {
    this.getRankingAverage();
    if (this.$store.getters.getNode > 0) {
      this.getRanking();
    }
  },
  mounted() {
    this.scroll()
  },
  computed: {
    indexStart() {
      return (this.current - 1) * this.pageSize
    },
    indexEnd() {
      return this.indexStart + this.pageSize
    },
    paginated() {
      // Init vars
      let filtered = false
      let el = this
      let arrFiltered = this.dataRanking.slice()


      // Filter array
      if (this.filters.province.toLowerCase().trim()) {
        arrFiltered = arrFiltered.filter(function (item) {
          if (item.region) {
            let regionLow = item.region.toLowerCase()
            return (regionLow || '').includes(el.filters.province.toLowerCase().trim())
          }
        })
        arrFiltered.push({ 'average': true, 'level': '', 'name': '', 'region': '', 'rating_global': this.dataAverage.rating_global + 0.001, 'total_reviews': this.dataAverage.avg_reviews + 0.001 })
        arrFiltered = this.sortArray(arrFiltered)
        filtered = true
      }
      if (this.filters.nameItem.toLowerCase().trim()) {
        arrFiltered = arrFiltered.filter(c => c.name.toLowerCase().indexOf(this.filters.nameItem.toLowerCase().trim()) > -1)
        filtered = true
        arrFiltered.push({ 'average': true, 'level': '', 'name': '', 'region': '', 'rating_global': this.dataAverage.rating_global + 0.001, 'total_reviews': this.dataAverage.avg_reviews + 0.001 })
        arrFiltered = this.sortArray(arrFiltered)
      }

      if (!filtered) {
        arrFiltered.push({ 'average': true, 'level': '', 'name': '', 'region': '', 'rating_global': this.dataAverage.rating_global + 0.001, 'total_reviews': this.dataAverage.avg_reviews + 0.001 })
        arrFiltered = this.sortArray(arrFiltered)
        arrFiltered = arrFiltered.slice(0, this.indexEnd)
      }

      return arrFiltered

    },
  },
  watch: {
    filterApply: function () {
      this.getRanking()
    },
  },
  methods: {
    sortArray(arrFiltered) {
      // Sort array
      arrFiltered = arrFiltered.sort((a, b) => {
        return b[this.sortParam] - a[this.sortParam]
      })
      if (!this.sortDesc) {
        arrFiltered = arrFiltered.reverse()
      }
      return arrFiltered
    },
    // OBTENEMOS INDICADORES
    getRanking() {
      this.$store.commit('LOADING', true)
      SrvNetwork.getRankings()
        .then(res => {
          if (res.status === 200) {
            this.dataRanking = res.data

            this.generateDataChart(this.dataRanking)
            this.$store.commit('LOADING', false)
          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    getRankingAverage() {
      SrvNetwork.getRankingsAverage()
        .then(res => {
          if (res.status === 200) {
            this.dataAverage = res.data

          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    scroll() {
      let wrapper = document.getElementById("app")
      let bottomOfWindow = false
      window.onscroll = () => {
        if (parseInt(document.documentElement.scrollTop) + window.innerHeight === wrapper.offsetHeight || parseInt(document.documentElement.scrollTop) + 1 + window.innerHeight === wrapper.offsetHeight) {
          bottomOfWindow = true
        } else {
          bottomOfWindow = false
        }
        if (bottomOfWindow) {
          this.current++
        }
      };
    },
    generateDataChart() {
      this.dataChart = []
      this.maxYAxes = 0
      this.dataRanking.forEach(item => {
        this.dataChart.push({ name: item.name, x: this.getNoteNormalized(item.rating_global), y: item.total_reviews })
        if (item.total_reviews > 0) {
          const log = Math.floor(Math.log10(item.total_reviews))
          const max = (this.maxYAxes > item.total_reviews ? this.maxYAxes : item.total_reviews)
          this.maxYAxes = (Math.ceil(max / (Math.pow(10, log)))) * (Math.pow(10, log))
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.y-label {
  transform: rotate(270deg);
  display: inline-block;
  width: 175px;
}
</style>
