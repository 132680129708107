<template lang="pug">
  div.d-flex.fill-height.width-100.justify-center
    div.gr-loader-snipper--wrapper(v-if="!loaded")
      span.gr-loader-snipper
    div#gridPrincipal.height-100(v-else)
      v-row.vertical-center.height-100
        v-col.text-left(md="12")
          div#imagenLogin
            img(:src="img.login")
          div#frmLogin
            img#logo(:src="logoLogin")
            div#frmForm
              p.mensajeError(v-if="this.errorMsgShow") {{ errorMsg }}
              p#fraseBienvenida.cwhite #[translate Hello! Please enter your account details.]
              form
                div.margin-bottom-32
                  input.fieldWhite(type="email" v-model="email" :placeholder="placeholder.email" :class="{ 'errorField':errorEmail }" @focus="resetError")
                  span.mensajeErrorCampo(v-if="errorEmail") #[translate This field is required]
                div.margin-bottom-32.relative
                  input.fieldWhite(:type="typeInput" v-model="password" :placeholder="placeholder.pwd" :class="{ 'errorField':errorPwd }" @focus="resetError")
                  span.btnShowHidePassword(@click="showPassword = !showPassword")
                    <img v-if="!showPassword" :src="img.eye" />
                    <img v-if="showPassword" :src="img.eyeOff" />
                  span.mensajeErrorCampo(v-if="errorPwd") #[translate This field is required]
                p

                v-row#recordarmeyOlvidado

                  v-col.text-left(md="5")
                    v-checkbox.cwhite(v-model="checkboxRecordar"
                    color="white")
                      template.cwhite(v-slot:label)
                        div
                          v-tooltip.cwhite(bottom)
                            template.cwhite(v-slot:activator="{ on }")
                              span.cwhite #[translate Remember me]
                  v-col.text-right(md="7")
                    // TEMPORALMENTE OCULTO CON V-IF 1!=1
                    a.cwhite(@click="passwordRestore") #[translate I have forgotten the password]
                div.text-center
                  button#entrar( type="button" @click="send" @keyup.enter="send") #[translate ENTER]
</template>
<script>
import SrvCommon from '@/services/common.js';
import commonMixins from "@/mixins/common.js"
import { getTreeAboutConfig } from '@/services/tree.js'
import mixinBrand from '@/mixins/brand.js'
export default {
  name: 'Auth',
  mixins: [mixinBrand, commonMixins],
  data () {
    return {
      img: {
        login: require('@/assets/img/portada.jpg'),
        logo: require('@/assets/img/logo-intro.svg'),
        eye: require('@/assets/img/eye.svg'),
        eyeOff: require('@/assets/img/eye-off.svg')
      },
      email: null,
      typeInput: 'password',
      password: null,
      errorMsg: false,
      errorEmail: false,
      errorPwd: false,
      errorMsgShow: false,
      showPassword: false,
      placeholder: {
        'email': this.$gettext('Email'),
        'pwd': this.$gettext('Password')
      },
      page_password_reset: this.$config.page_password_reset,
      checkboxRecordar: false,
      loaded: false
    }
  },
  created () {
    // Verificamos si viene el token en query string. Si es asi intentamos
    if(SrvCommon.getToken() || SrvCommon.getToken()) {
      this.autoLogin()
    } else {
      setTimeout(() => {
        this.$nextTick(() => {
          this.loaded = true
        })
      }, 1000)
    }
  },
  computed: {
    year () {
      return (new Date()).getFullYear()
    },
    config_app () {
      return this.$config.configApp
    },
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    send () {
      let email = this.email
      let pwd = this.password
      this.resetError()

      let RegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/
      if (email === null || email === '' || !email.match(RegEx)) {
        this.errorMsg = true
        this.errorEmail = true
      }

      if (pwd === null || pwd === '') {
        this.errorMsg = true
        this.errorPwd = true
      }

      if (!this.errorMsg) {
        this.loaded = false
        this.$store.dispatch('login', {username: email, password: pwd})
          .then(async res => {
            if(this.checkboxRecordar){
              window.localStorage.setItem('_token', res.data.token)
              window.localStorage.setItem('_user', this.email)
            }else{
              window.localStorage.setItem('_token', res.data.token)
              window.sessionStorage.setItem('_user', this.email)
            }
            this.$store.commit('USER_NAME', this.email)
            this.userAccount = await SrvCommon.getAccount()
            if (!this.is_alsea) {
              if (this.userAccount.data.trees.length) {
                if(this.$store.getters.getTreeData.length === 0){
                  await getTreeAboutConfig()
                  this.loading = false
                }
                await this.autoLogin()
              } else {
                window.localStorage.removeItem('_user_info')
                window.localStorage.removeItem('_token')
                window.localStorage.removeItem('_user')
                window.sessionStorage.removeItem('_token')
                window.sessionStorage.removeItem('_user')
                this.$store.commit('LOGOUT')
                window.location = '/'
              }
            } else {
              await this.autoLogin()
            }
          }, (error) => {
            this.errorMsgShow = true
            this.loaded = true
            if (error.data.non_field_errors[0]) {
              this.errorMsg = error.data.non_field_errors[0]
            } else {
              this.errorMsg = this.$gettext('Error! incorrect data')
            }
          })
      }
    },
    passwordRestore() {
        this.$router.push({name: 'Restore'})
    },
    resetError () {
      this.errorMsgShow = false
      this.errorEmail = false
      this.errorPwd = false
      this.errorMsg = false
    },
    async autoLogin () {
      if (this.nameInstance === 'alsea' || this.nameInstance === 'localhost') {
        await this.$router.push({name: 'rateAnalysis'})
      } else {
        await this.$router.push({name: this.nameSectionMain})
      }
    }
  },
  watch: {
    showPassword(newVal)  {
      if (newVal) {
          this.typeInput = 'text'
      } else {
          this.typeInput = 'password'
      }
    }
  }
}

</script>
<style lang="scss" scoped>
  .btnShowHidePassword{
    position: absolute;
    top:15px;
    right: 10px;
  }
  #fraseBienvenida{
    text-align: center;
    font-size: 1.125rem;
  }
  #gridPrincipal{
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
  }
  #frmLogin,#imagenLogin{
    display: inline-block;
  }
  #frmLogin{
    width: 39%;
    margin-left: -16%;
    vertical-align: top;
    margin-top: 128px;
    #frmForm{
      max-width: 86%;
      margin: 0 auto;
      .errorField{
        border:2px solid #000;
        color:#000;
      }
      .mensajeError{
        font-weight: bold;
      }
      .mensajeErrorCampo{
        color:#000;
        font-weight: bold;
      }
    }
  }
  #imagenLogin{
    width: 71.2%; // 865 de 1150
    text-align: left;
    margin-top: 50px;
  }
  #logo{
    margin-bottom: 40px;
    display:inline-block;
  }
  .fieldWhite{
    background-color: #fff;
    text-align: center;
    border-bottom:0;
    border-radius:5px;
    color:#000;
    max-width: 400px;
    max-height: 46px;
    font-size: 0.938rem;
  }
  .cwhite{
    color:#fff;
  }

  input{
    display:block;
    width: 100%;
    border:none;
    font-size:1.125rem;
    padding:16px 10px;
    &#recordar{
      display: inline;
      width: auto;
      border:none;
    }
  }
  .height-100{
    height:100%;
  }
  .width-100{
    width:100%;
  }
  #entrar{
    color:#fff;
    border:1px solid #fff;
    border-radius:5px;
    padding:12px 50px;
    margin-top:32px;
    transition: all ease 0.3s;
    &:hover{
      background-color: #fff;
      color:#000;
    }
  }
  #recordarmeyOlvidado{
    .v-input--selection-controls{
      margin-top:0;
      padding-top: 0;
      .theme--light{
        color:#fff;
      }
    }
  }
  //  @media (min-width: 2000px) {
  //     #gridPrincipal{
  //       max-width: 1600px;
  //     }
  //   }
</style>
