/* BOOKING */
export const BOOKING_GET_DATA_FROM_SERVICE = "BOOKING_GET_DATA_FROM_SERVICE";
export const BOOKING_GET_CLIENT_DATA_FROM_SERVICE = "BOOKING_GET_CLIENT_DATA_FROM_SERVICE";
export const BOOKING_CLEAR_NEXT_URL = "BOOKING_CLEAR_NEXT_URL";
export const BOOKING_SET_OPINION_TYPE_SELECTED = "BOOKING_SET_OPINION_TYPE_SELECTED";
export const BOOKING_SET_CLIENT_FILTER_SELECTED = "BOOKING_SET_CLIENT_FILTER_SELECTED";
export const BOOKING_SET_CATEGORIES_FILTER_SELECTED = "BOOKING_SET_CATEGORIES_FILTER_SELECTED";
export const BOOKING_CLEAR_OPINIONS = "BOOKING_CLEAR_OPINIONS";
export const BOOKING_CLEAR_SEARCH = "BOOKING_CLEAR_SEARCH";
export const BOOKING_SET_INITIAL_STATE = "BOOKING_SET_INITIAL_STATE";

/* COMMON */
export const SET_IS_HOTEL = "SET_IS_HOTEL";
