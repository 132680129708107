<template>
    <div class="boxErrors">
        <svg xmlns="http://www.w3.org/2000/svg" width="17.557" height="15.5" viewBox="0 0 17.557 15.5" class="mr-2">
            <g id="Xnix_Line_" data-name="Xnix/Line/" transform="translate(0.778 0.75)">
                <g id="Group_8551" data-name="Group 8551">
                <path id="vector" d="M15.65,10.493,9.834,1.045a2.131,2.131,0,0,0-3.667,0L.35,10.493A2.281,2.281,0,0,0,2.183,14H13.817A2.281,2.281,0,0,0,15.65,10.493Z" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <path id="Vector-2" data-name="Vector" d="M0,0V5" transform="translate(8 8) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
                </g>
                <path id="Vector-3" data-name="Vector" d="M0,0V1" transform="translate(8 11) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/>
            </g>
        </svg>
        <div class="errors">
            <p v-if="typeof errors === 'string'" class="mb-0">{{ errors}} </p>
            <ul v-else>
                <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props: ['errors']
}
</script>
<style lang="scss" scoped>
.boxErrors{
    background-color: #f58093;
    color: #fff;
    display: flex;
    padding: 8px 16px;
    border-radius: 4px;
    align-items: center;
    width: 100%;
    svg{
        margin-right: 16px;
    }
    .errors{
        display: flex;
        align-items: center;
        flex: 1;
    }
}

</style>