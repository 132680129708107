<template lang="pug">
    div
        v-dialog.dialogEdit(v-model="show"
            width="680")
            v-card.dialogEdit
                v-card-text.pb-0
                v-container.pa-0
                    div.gr-loader-snipper--wrapper(v-if="loading")
                        span.gr-loader-snipper
                    v-row#headerModalEdit.pa-2.pt-3.ma-0
                        v-col(cols="10")
                            span #[translate Notificaciones]: 
                            span {{ nameCenter }}
                        v-col.mt-5(cols="2")
                            img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                    #bodyModalEdit.ma-2.scrollCustom.p-1
                        notifications(:center="idBussines" :hasShadow="false" :load="load")                        
                    v-row#footerModalEdit.pa-2.ma-0
                        v-col(cols="12")
                            button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
</template>
<script>
import commonMixins from "@/mixins/common.js";
import Notifications from "@/components/local/Notifications.vue";

export default {
  mixins: [commonMixins],
  props: ["visible", "idBussines", "nameCenter"],
  components: { Notifications },
  data() {
    return {
      loading: false,
      imgClose: require("@/assets/img/ico-close-edit.svg"),
      data: {},
      categories: [],
      priceRange: [],
      customCategories: [],
      googlePrimaryCategoryCustom: {},
      section: "data-restaurant",
      saveStatus: true,
      messageError: null,
      load: false,
    };
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.data = {};
        this.load = true;
        //this.loadData()
      } else {
        this.load = false;
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    showPriceRange() {
      return this.priceRange.length > 0 ? true : false;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
#autocompleteCategories {
  background-color: #fff;
  input {
    font-size: 0.875rem !important;
  }
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot {
  background-color: #fff;
}
.messageError {
  background-color: #f5b1b8;
  border: 1px solid #f93549;
  color: #f93549;
  padding: 15px;
}
.titleSectionNotifications {
  margin-bottom: 34px;
  position: relative;
  &:after {
    position: absolute;
    width: 100px;
    height: 2px;
    content: (" ");
    background-color: #c6c6c8;
    left: 0;
    bottom: -5px;
  }
}
#bodyModalEdit {
  padding: 0;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  .btn {
    color: #fff;
    min-width: 118px;
    font-weight: 600;
    margin-left: 13px;
    padding: 5px;
    font-size: 0.875rem;
  }

  .btnAcepted {
    background-color: #00acfb;
  }
}
</style>
