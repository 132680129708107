<template lang="pug">
    div#breadcrumbs
        div(v-if="showTree")
            p.d-flex.items-center.justify-start.ellipsis(:class="{'borderBottomNone': !borderBottom}" v-if="itemsBreadsCrumbs.length > 0")
                span(v-for="(ele, index) in itemsBreadsCrumbs" :key="index")
                    img.separator(v-if="index > 0" :src="img.separator" :width="iconWidth") 
                    router-link(v-if="index + 1 < itemsBreadsCrumbs.length" @click.native="setItem(index,ele)" :to="{name: $route.name, params: {paramCOD: generateParam(ele)}, query: query}") {{ ele.name }}
                    span.itemInitial(v-else) {{ ele.name }}
            p.ellipsis.itemInitial(:class="{'borderBottomNone': !borderBottom}" v-else) 
                span(v-if="itemInitial") {{ itemInitial }}
        div(v-else)
            p.ellipsis.itemInitial(:class="{'borderBottomNone': !borderBottom}")
                span {{ nameCenter }}
        
</template>

<script>
import * as types from '@/store/mutation-types'

export default {
    props: {
        borderBottom: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data (){
        return{
            iconWidth: 5,
            itemsCrumbs: [],
            img: {
                separator: require('@/assets/img/separator-breadcrumb.svg'),
            }
        }
    },
    computed:{
        nameCenter () {
            let center = document.querySelector('[data-id="' + this.$store.getters.getNode + '"][data-type="C"]')
            let name = center.getAttribute('data-name')
            this.$store.commit(types.TREE_CENTER_NO_TREE, name)
            return name
        },
        showTree () {
            return this.$store.getters.getShowTree
        },
        query () {
            return this.$store.getters.queryParams
        },
        itemsBreadsCrumbs(){
            this.setItemsBreadCrumbs()
            let strBreadCrumb = this.$store.getters.getBreadcrumb.toString()
            if (strBreadCrumb !== '' && typeof strBreadCrumb === 'string') {
                let items = []
                items = strBreadCrumb.split('-')
                let arbol = this.$store.getters.getTreeData
                for(let index in items){
                    arbol = this.getItemsBreads(arbol, items[index])
                }
            }
            return this.itemsCrumbs
        },
        itemInitial(){
            let val = ''
            if(this.$store.getters.getTreeData.length > 0){
                val = this.$store.getters.getTreeData[0].name
            }
            return val
        }
    },
    methods:{
        setItemsBreadCrumbs(){
            this.itemsCrumbs = []
        },
        getItemsBreads(arbol, id){
            let proxTree = []
            for(let index in arbol){
                if(parseInt(arbol[index].id) === parseInt(id)){
                    
                    this.itemsCrumbs.push({id:arbol[index].id,name:arbol[index].name,level:arbol[index].level})
                    if(arbol[index].children && arbol[index].children.length > 0){
                        proxTree = arbol[index].children
                    } else {
                        proxTree = arbol[index].centers
                    }
                }
            }
            return proxTree
        },
        generateParam(item){            
            let type = 'C'
            if(item.level >= 0 && item.level < 9){
                type = 'N'
            }
            let selector = item.id+'-'+item.name.replaceAll(' ','')+'-'+type
            let elementTree = document.getElementById(selector)
            if (elementTree.getAttribute('class').includes('itemTreeInfofirsElementHide')) {
                let elementsTree = document.querySelectorAll('.itemTreeInfo')
                elementTree = elementsTree[1]
            }
            return elementTree ? elementTree.getAttribute('data-slug') + '-' + type : ''
        },
        setItem: function(index,item){
            this.$store.commit(types.SET_NODE,item.id)
            let level = 9
            if(item.level >= 0 && item.level < 9){
                level = item.level
            }
            let slugAndType = this.generateParam(item)
            let slug = slugAndType.substr(0,slugAndType.length-2)
            this.$store.commit(types.BREAD_CRUMB, slug);
            this.$store.commit(types.SET_NODE_LEVEL,level);
            setTimeout(()=> {
              let elementoActivoArbol = document.querySelector('[data-slug="' + slug + '"]')
              elementoActivoArbol.scrollIntoView({ block: 'start',  behavior: 'smooth' });
            }, 500)

        },
    },
}
</script>

<style lang="scss" scoped>
#breadcrumbs{
    padding-left: 20px;
    .ellipsis{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;
        /*direction: rtl;*/
        text-align: left;
        display: block;
        border-bottom: 1px solid #ccc;
        height: 32px;
    }
    a{
        text-decoration: none;
        color:#4A90E2;
        font-size: 0.75rem;
    }
    .separator{
        margin:0 10px;
    }
    .itemInitial{
        font-size: 0.75rem;
    }
    .borderBottomNone{
        border-bottom: none;
    }
}
</style>