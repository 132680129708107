<template>

<v-row :class="{'premiumDisabled':!hasPremium}">
    <v-col md="12">
        <p class="mb-1">
            <strong>{{ $gettext('Añadir otro horario') }}</strong>
        </p>
        <button class="addHourType pa-1 ma-1" v-for="(item, index) in hour_typesAvailable" :key="index" @click="createNewSchedul(item)">+ {{ item.localize_display_name }}</button>
    </v-col>
</v-row>                  
   
</template>
<script>
import SrvLocal from '@/services/local.js'

export default{
    props:['hourTypesUsed', 'hasPremium','dataProfile'],
    data() {
        return {
            hourTypes: null
        }
    },
    computed: {
        hour_typesAvailable() {
            if (this.hourTypes) {
                return this.hourTypes.filter(t => {
                    if (this.dataProfile.googleextraopendaytype.filter(g => g.hour_type === t.localize_display_name).length === 0) {
                        return true
                    }
                })
            } 
            return []
        }
    },
    created(){
        this.getBussinesExtraHoursType()
    },
    methods: {
        async getBussinesExtraHoursType(){
            const resHours = await SrvLocal.getBussinesExtraHoursType()
            if (resHours.status === 200) {
                this.hourTypes = resHours.data
            }
        },
        createNewSchedul(item) {
            this.$emit('onCreateSchedul', item)
        }
    }
}
</script>

<style>
.addHourType{
  border: 1px solid #000;
  border-radius: 4px;
}
</style>