<template lang="pug">
div
    v-row.px-2.ma-0(:class="{'premiumDisabled': !hasPremium}")
        // LOGO MARCA     
        v-col.margin-bottom-0.flex-full(sm="12" :class="{'premiumDisabled': !hasPremium}")
            skeleton-card(v-if="isLoading")
            v-card.gridFull.h-auto.my-4.mt-2.ma-0.pl-6.pr-9(v-if="!isLoading && !errors")
                v-row.ma-0.headAndButtons.items-end
                    v-col.text-14(sm="6")
                        strong.mr-16 #[translate Publicaciones]
                    v-col.justify-end.d-flex(sm="6")
                      <button @click="openAddNew" class="btnAdd text-14 mr-2">{{ $gettext('Agregar una publicación') }}</button>
                      <button @click="openAddQuotation" class="btnAdd text-14 mr-2">{{ $gettext('Agregar una oferta') }}</button>
                      <button @click="openAddEvent" class="btnAdd text-14">{{ $gettext('Agregar un evento') }}</button>
                v-row.ma-0(v-if="posts.length > 0")
                    v-col.text-14(sm="3" v-for="(post, index) in posts")
                      <Post :key="index" :data="post" :idBussines="center" @saved="refreshPosts" @deletePost="refreshPosts"></Post>                    
                v-row.ma-0(v-else)
                    v-col.text-14(sm="3")
                      <PostEmpty></PostEmpty>
                edited-ok(:show="showOk" @update:show="showOk = $event")
                  p.mt-3.py-3.text-center 
                    strong Bloqueadas las secciones correctamente.
                <addEvent @saved="refreshPosts" :idBussines="center" :visible="showAddEvent" @close="showAddEvent = $event"></addEvent>
                <addQuotation @saved="refreshPosts" :idBussines="center" :visible="showAddQuotation" @close="showAddQuotation = $event"></addQuotation>
                <addNew @saved="refreshPosts" :idBussines="center" :visible="showAddNew" @close="showAddNew = $event"></addNew>
            v-card.gridFull.h-auto.my-4.mt-2.ma-0.pl-6.pr-9.d-flex.flex-column.items-center.pa-9(v-if="!isLoading && errors")
              <img src="@/assets/img/no_hay_datos.svg" />
              <p class="mt-6">Algo ha salido mál, probablemente este centro no está vinculado a algun negocio del api de google, si el error persiste contacta a soporte</p>

</template>

<script>
  import SkeletonCard from '@/components/common/skeletonCard.vue'
  import SrvLocal from '@/services/local.js'
  import SrvLocalPosts from '@/services/localPosts.js'
  import commonMixins from "@/mixins/common.js"
  import localMixins from "@/mixins/local.js"
  //FICHAS EDICION
  import EditedOk from '@/components/modals/EditedOk.vue'
  import Post from '@/components/local/posts/Post.vue'
  import PostEmpty from '@/components/local/posts/PostEmpty.vue'
  import addEvent from '@/components/local/posts/addEvent.vue'
  import addQuotation from '@/components/local/posts/addQuotation.vue'
  import addNew from '@/components/local/posts/addNew.vue'

  export default {
    components: {
        SkeletonCard,
        EditedOk,
        Post,
        PostEmpty,
        addEvent,
        addQuotation,
        addNew
    }, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
    mixins: [commonMixins, localMixins],
    data() {
      return {
        showEdit: true, // TEMPORAL PARA SUBIDAS A PRO
        dataProfile: [],
        posts: [],
        showAddEvent: false,
        showAddQuotation: false,
        showAddNew: false, 
        messageError: null,
        loadingSync: false,
        loadingImportGoogle: false,
        sections: null,
        showOk: false,
        sectionsNotCanBlocked: ['storefrontAddress','latlng'],
        img: {
        },
        errors: null
    }   
    },
    async created() {
      this.$store.commit('LOADING', true)
      this.loading = true
      this.$store.commit('LOADING', true)
      this.center = Number(this.$store.getters.getNode)
      await this.getLocalMarketing()
      
      this.loading = false
      this.$store.commit('LOADING', false)
    },    
    watch: {
      filterApply: function () {
        this.getLocalMarketing()
      }
    },
    methods: {   
      openAddNew(){
        if (this.hasPremium) {
          this.showAddNew = true
        }
      },
      openAddEvent(){
        if (this.hasPremium) {
          this.showAddEvent = true
        }
      },
      openAddQuotation(){
        if (this.hasPremium) {
          this.showAddQuotation = true
        }
      },

      refreshPosts(){
        this.getLocalMarketing()
      },  
      async getLocalMarketing() {
        this.$store.commit('LOADING', true)
        const resLocal = await SrvLocal.getLocalMarketing()
        if (resLocal.status === 200) {
          this.dataProfile = resLocal.data
          this.facebookConfig = resLocal.data.facebook_config
          
        }
        if (this.hasPremium) {
          try {
            const resPosts = await SrvLocalPosts.getPosts(this.center)
            if (resPosts.status === 200) {
              this.posts = resPosts.data.localPosts ? resPosts.data.localPosts : []
              this.$store.commit('LOADING', false)
            }
        } catch (e) {
          if (e.response.status === 404) {
            this.errors = true
            this.$store.commit('LOADING', false)
          }
        }
        } else {
          this.$store.commit('LOADING', false)
        }     
      },      
    }
  }
  </script>
<style scoped>
  .btnAdd{
    background-color: #00ADFC;
    color:#fff;
    padding: 9.5px;
  }
  .headAndButtons{
    border-bottom:2px solid #E7E7E7;
  }
</style>
