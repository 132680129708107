<template>
    <div class="mb-4" :class="{'premiumDisabled': !hasPremium}">
        <div v-if="!answers">
            <strong>Solicitar 3 respuestas a chatGPT</strong>
            <button @click="getDataChatGPT()" class="btnGetAnwersChatGpt btnWithLoading">
                <div class="gr-loader-snipper--wrapper" v-if="loading">
                    <span class="gr-loader-snipper"></span>
                </div>
                PEDIR
            </button>
        </div>
        <div v-else>
            <strong>Sugerencia de respuesta de chatGPT</strong>
            <div class="d-flex answerChatGpt" v-for="(answer, index) in answers" :key="index">
                <div class="flex-1 pa-2 text">{{ answer }}</div>
                <div class=""><button class="btnGetAnwersChatGpt select" @click="setAnswer(answer)">Seleccionar</button></div>
            </div>
        </div>
        
    </div>
</template>
<script>
import SrvFeedback from '@/services/feedback.js'
export default{
    props: ['item', 'hasPremium'],
    data(){
        return {
            answers: null,
            loading: false
        }
    },
    methods: {
        async getDataChatGPT(){
            if (this.hasPremium) {
                if (!this.loading) {
                    this.loading = true
                    const res = await SrvFeedback.getAnswersChatGPT({review: this.item.text, n: 3})
                    if (res.status === 200) {
                        this.answers = res.data.response
                    }
                    this.loading = false
                }
            }
        },
        setAnswer(text){
            if (this.hasPremium)
                this.$emit('setAnswer', {item: this.item, text: text})
        }
    }
}
</script>
<style lang="scss" scoped>
.btnGetAnwersChatGpt{
    background-color: #1778d1;
    color: #fff;
    padding: 2px 10px;
    margin-left: 5px;
    position: relative;
    &.select{
        background-color: #fff;
        color: #1778d1;
        border: 1px solid #1778d1;
    }
}
.answerChatGpt{
    
    margin-bottom: 5px;
    align-self: flex-start;
    .text{
        background-color: #f3f1f1;
    }
}
</style>