<template lang="pug">
div.pr-5.thirstlevel(v-if="this.$router.history.current.name.indexOf('NetworkCompetenceMap') < 0 && this.$router.history.current.name.indexOf('NetworkAggregators') < 0 && this.$router.history.current.name.indexOf('DeliveryCompetenceMap') < 0 && this.$router.history.current.name.indexOf('DeliveryAggregators') < 0" 
:style="calculatedTop" :class="{'sticky':isSticky}" ref="containerRef")
    v-row.flex-center
        v-col.pr-0
            bread-crumbs( :paramCOD="paramCOD")
        v-col.col-filters.col-filters-big-center.py-0(v-if="$route.path.indexOf('reports') < 0 && !avoidRenderFilters")
            div.filters(v-if="$route.name === 'FeedBackDishRanking'")
                div.filter.filtroDate
                    range-date
            div.filters(v-else)
                span.downloadExcel(v-if="showDownloadInHome && !$route.meta.notShowDownload" @click="downloadExcel()")
                    img(:src="imgCommons.download" width="10")
                div.gr-filter-input-wrapper(v-if="!onlyNetwork && !isDelivery")
                    span.label #[img.mr-2(:src="img.imgSites")] #[translate Opinion Sites]
                    div.menu
                        one-select-tree.inline-block(classFixedMenuVuetify="vMenuFixedTop" :options="opinionSites" :itemsChecked="typeOpinionSelected" :itemCheckChild="siteSelected" @checked="onCheckOpinionsSites")
                div.gr-filter-input-wrapper(v-if="onlyNetwork || $router.history.current.name.indexOf('NetworkValoration') >= 0 || $router.history.current.name.indexOf('NetworkGps') >= 0 || $router.history.current.name.indexOf('NetworkResume') >= 0 || $router.history.current.name.indexOf('NetworkCompetence') >= 0")
                    span.label #[img.mr-2(:src="img.imgSites")] #[translate Opinion Sites]
                    div.menu
                        one-select-tree.inline-block(classFixedMenuVuetify="vMenuFixedTop" :options="opinionSites" :itemsChecked="'internet'" :onlyNetwork="true" :itemCheckChild="siteSelected" @checked="onCheckOpinionsSites")
                div.gr-filter-input-wrapper(v-if="isDelivery && opinionAllSitesDelivery?.length")
                    span.label #[img.mr-2(:src="img.imgFilterDelivery" width="16")] #[translate Webs de delivery]
                    div.menu
                        filter-tree-menu(classFixedMenuVuetify="vMenuFixedTop" label-class="text-capitalize-first-letter" :options="opinionAllSitesDelivery" :itemsChecked="allSitesDeliverySelect" @checked="onCheckSitesDelivery" id-key="id" label-key="name")
                div.gr-filter-input-wrapper(v-if="typeNode !== 'center' && $route.name !=='FeedBackKeywords' && regions?.length")
                    translate.label Province
                    div.menu
                        filter-tree-menu(classFixedMenuVuetify="vMenuFixedTop" label-class="text-capitalize-first-letter" :options="regions" :itemsChecked="regionSelect" @checked="onCheckRegion" id-key="value" label-key="text")
                div.gr-filter-input-wrapper(v-if="typeNode !== 'center' && $route.name !=='FeedBackKeywords' && restaurantTypes?.length")
                    span.label(v-if="isGenericEntity") #[img.mr-2(:src="img.imgRestaurant")] Tipo de negocio
                    span.label(v-else) #[img.mr-2(:src="img.imgRestaurant")] #[translate Type of Restaurant]
                    div.menu
                        filter-tree-menu(classFixedMenuVuetify="vMenuFixedTop" label-class="" :options="restaurantTypes" :itemsChecked="typeRestaurantSelected" @checked="onCheckRestaurantTypes" id-key="value" label-key="text")
                div.gr-filter-input-wrapper(v-if="$route.name !=='FeedBackKeywords' && $route.name !=='FeedBackEmployees' && centerModels?.length")
                    span.label #[img] #[translate Model]
                    div.menu
                        filter-tree-menu(classFixedMenuVuetify="vMenuFixedTop" :options="centerModels" :itemsChecked="centerModelsSelected" @checked="onCheckCenterModels" id-key="value" label-key="text")
                .filtroTipoRestaurante.edit-restaurant.position-relative(v-if="1 == 2 && typeNode === 'center'")
                    v-btn(color="#4A90E2") #[img(:src="img.imgRestaurantWhite")] #[translate.ml-2.white--text Edit restaurant]
                div.filter.filtroDate.mr-0(v-if="!['NetworkValorationPositioningLast','NetworkValorationPositioningLast12Weeks'].includes($router.history.current.name)" )
                    range-date
                div.filter(v-if="!['NetworkValorationPositioningLast','NetworkValorationPositioningLast12Weeks'].includes($router.history.current.name)")
                    <RangeDatesDefaults></RangeDatesDefaults>
                div.filter(v-if="(['NetworkCompetenceGlobal','DeliveryCompetenceGlobal'].includes($router.history.current.name) && typeNode === 'center') || $route.meta.filterRangeDistance")
                    <range-distance></range-distance>
</template>

<script>
import RangeDate from '@/components/filters/RangeDates.vue'
import MultiSelect from '@/components/filters/Multipleselect.vue'
import OneSelect from '@/components/filters/OneSelect.vue'
import OneSelectTree from '@/components/filters/OneSelectTree.vue'
import BreadCrumbs from '@/components/common/Breadcrumbs.vue'
import commonMixins from "@/mixins/common.js"
import brandMixin from '@/mixins/brand.js'
import FilterTreeMenu from "@/components/filters/filterTreeMenu"
import RangeDistance from '@/components/competence/RangeDistance.vue'
import RangeDatesDefaults from '@/components/filters/RangeDatesDefaults.vue'

export default {
    mixins: [commonMixins, brandMixin],
    expose: ['containerHeight'],
    props: {
        'paramCOD': {
            required: true,
            type: String
        },
        'onlyNetwork': {
            required: false,
            type: Boolean,
            default: false
        },
        'onlyBreadCrumb': {
            required: false,
            type: Boolean,
            default: false
        },
        'isDelivery': {
            required: false,
            type: Boolean,
            default: false
        },
        'isSticky': {
            required: false,
            type: Boolean,
            default: true
        }
    },
    components: { FilterTreeMenu, RangeDate, MultiSelect, OneSelect, BreadCrumbs, OneSelectTree, RangeDistance, RangeDatesDefaults },
    data() {
        return {
            img: {
                imgRestaurant: require('@/assets/img/icon_restaurant.png'),
                imgRestaurantWhite: require('@/assets/img/restaurant-white.png'),
                imgSites: require('@/assets/img/filter-sites.png'),
                imgFilterDelivery: require('@/assets/img/ico-delivery.svg'),
            },
            containerHeight: 0
        }
    },
    computed: {
        calculatedTop() {
            return this.$route.path.split('/').length > 4 ? 'top:100px' : 'top:55px'
        },
        avoidRenderFilters() {
            return ["GlobalGastroRanking", "DeliveryPosicionamientoHome", "FeedBackBooking", "AutomaticAnswers"].includes(this.$route.name) || this.onlyBreadCrumb
        }
    },
    mounted() {
        this.containerHeight = this.$refs.containerRef.clientHeight;
    }
}
</script>

