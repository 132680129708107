<template lang="pug">
div
  v-row.d-flex.justify-space-between.pl-5.align-center.mx-5.py-5.main.filters(ref="filtersRef" :style="`top: ${headerHeight}px`")
    span {{ count }} #[translate Opinions]
    div.d-flex.items-center
      div.d-flex.spacing.pr-2
        range-date
      span.cursor-pointer(@click="downloadXlsFile")
        img(:src="imgCommons.download" width="20")

  div
    div.gridFull
      v-row.flex-center.b-bottom.block-head.mb-0.white-bg.main.table(:style="`top: ${topSectionHeight-1}px`")
        v-col.col-12
          div.filters
            span.labelFilter #[translate Search]:
              div.filter.ml-2.mr-4
                input.filterBySearch(type="text" v-model="search")
                button.filterBySearchButton.clean(@click="cleanBySearch" v-if="$route.query.search || search") 
                  img(:src="img.clean")
                button.filterBySearchButton(@click="filterBySearch") 
                  img(:src="img.search")                

            span.labelFilter #[translate Comentarios]:
              div.filter.ml-2.mr-4
                filter-tree-menu(:options="opinionsTypes" label-class="text-capitalize-first-letter" :itemsChecked="opinionsTypesSelectedMaped" @checked="onCheckOpinionsTypes" id-key="id" label-key="name")

            span.labelFilter #[translate Categories]:
              div.filter.ml-2.mr-4
                filter-tree-menu(:options="opinionsCategories" label-class="text-capitalize-first-letter" :itemsChecked="opinionsCategoriesSelected" @checked="onCheckOpinionsCategories" id-key="id" label-key="name")    

            span.labelFilter #[translate Cliente]:
              div.filter.ml-2.mr-4
                filter-tree-menu(:options="clientsFilters" label-class="text-capitalize-first-letter" :itemsChecked="clientFilterSelected" @checked="onCheckClientFilter" id-key="id" label-key="name")    
      v-row.no-gutters
        booking-table(:opinions="opinions" :loading="pending" style="width:100%" @setOrder="setOrder")
            
</template>

<script>
import * as types from '@/store/mutation-types';
import * as actionTypes from '@/store/actions-types';
import RangeDate from '@/components/filters/RangeDates.vue'
import BookingTable from "@/components/global/BookingTable"
import FilterTreeMenu from "@/components/filters/filterTreeMenu";
import commonMixing from '@/mixins/common';

export default {
  components: { RangeDate, BookingTable, FilterTreeMenu },
  mixins: [commonMixing],
  data() {
    return {
      img: {
        sort: require('@/assets/img/mini_arrow_shack_disable.svg'),
        search: require('@/assets/img/buscar.svg'),
        clean: require('@/assets/img/borrar.svg')
      },
      opinionsTypes: [
        { id: 'with_text', name: this.$gettext('With comments') },
        { id: 'without_text', name: this.$gettext('Without comments') },
        { id: 'positives', name: this.$gettext('Positives') },
        { id: 'negatives', name: this.$gettext('Negatives') },
      ],
      ordering: '-source_date',
      filteredOpinions: [],
      tableWidth: 0,
      filterHeight: 0,
      filterSectionHeight: 0,
      observer: null
    }
  },
  created() {
    this.getOpinions({})
  },
  mounted() {
    this.scroll();
  },
  computed: {
    startDate() {
      return this.$moment(this.$store.getters.getRangeDatesMetrics[0]).format("YYYY-MM-DD")
    },
    endDate() {
      return this.$moment(this.$store.getters.getRangeDatesMetrics[1]).format("YYYY-MM-DD")
    },
    nodeLevel() {
      return this.$store.getters.getNodeLevel
    },
    search: {
      get() {
        return this.$store.getters.getBookingSearch
      },
      set(val) {
        this.$store.commit(types.BOOKING_OPINIONS_SEARCH, val)
      }
    },
    opinions() {
      return this.$store.getters.getBookingOpinions
    },
    count() {
      return this.$store.getters.getBookingCount
    },
    pending() {
      return this.$store.getters.getIsPending
    },
    opinionsTypesSelectedMaped() {
      return this.$store.getters.getBookingOpinionsTypeSelected.map(c => c.value)
    },
    clientsFilters() {
      return this.$store.getters.getClientTypes.map(item => ({ name: this.$ngettext(item.label), id: item.value }))
    },
    opinionsCategories() {
      let categoriesCrud = []
      let selectedCat = this.$store.getters.getTypesSelected ? this.$store.getters.getTypesSelected : 'internet'
      if (this.$store.getters.getCategories[selectedCat]) {
        categoriesCrud = this.$store.getters.getCategories[selectedCat].categories
      }
      return categoriesCrud
    },
    opinionsCategoriesSelected() {
      return this.$store.getters.getBookingCategoriesSelected.map(c => c.value)
    },
    clientFilterSelected() {
      return this.$store.getters.getBookinClientFilterSelected.map(c => c.value)
    },
    headerHeight() {
      return this.$store.getters.getTopSectionHeight - 2
    },
    topSectionHeight() {
      return this.headerHeight + (this.$refs?.filtersRef?.clientHeight ?? 0)
    },
  },
  watch: {
    ordering: function () {
      this.cleanNextURl()
      this.getOpinions({ order: this.ordering })
    },
  },
  methods: {
    downloadXlsFile() {
      this.$store.commit(types.DOWNLOADING_EXCEL, true);

      this.downloadExcel({ startDate: this.startDate, endDate: this.endDate })
    },
    handleWindowResize() {
      this.tableWidth = this.$refs.tableRef.clientWidth
    },
    getOpinions({ url = null, order = null }) {
      this.$store.dispatch(actionTypes.BOOKING_GET_DATA_FROM_SERVICE, { url, order });
    },
    cleanNextURl() {
      this.$store.dispatch(actionTypes.BOOKING_CLEAR_NEXT_URL)
      this.$store.dispatch(actionTypes.BOOKING_CLEAR_OPINIONS)
    },
    cleanBySearch() {
      this.$store.dispatch(actionTypes.BOOKING_CLEAR_SEARCH)
      this.cleanNextURl()
      this.getOpinions({})
    },
    setOrder(val) {
      this.ordering = val
    },
    filterBySearch() {
      this.cleanNextURl()
      this.getOpinions({})
    },
    onCheckOpinionsTypes(val) {
      this.$store.dispatch(actionTypes.BOOKING_SET_OPINION_TYPE_SELECTED, val)
      this.cleanNextURl()
      this.getOpinions({})
    },
    onCheckOpinionsCategories(val) {
      this.$store.dispatch(actionTypes.BOOKING_SET_CATEGORIES_FILTER_SELECTED, val)
      this.cleanNextURl()
      this.getOpinions({})
    },
    onCheckClientFilter(val) {
      this.$store.dispatch(actionTypes.BOOKING_SET_CLIENT_FILTER_SELECTED, val)
      this.cleanNextURl()
      this.getOpinions({})
    },
    scroll() {
      window.onscroll = () => {
        if (this.$route.name === 'FeedBackBooking') {
          var body = document.body,
            html = document.documentElement;
          var height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);
          height = parseInt(height, 10) - 1

          let bottomOfWindow = false
          if (document.documentElement.scrollTop + window.innerHeight > height - 5 && document.documentElement.scrollTop + window.innerHeight < height + 5) {
            bottomOfWindow = true
          }

          if (this.$store.getters.getNextUrl && bottomOfWindow && !this.pending) {
            this.getOpinions({ url: this.$store.getters.getNextUrl });
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  position: sticky;
  z-index: 2;

  &.filters {
    background: #F2F2F2;
  }

  &.table {
    background: #fff;
    margin: 0 1px;
  }
}

.spacing {
  gap: 1rem;
}

.filterBySearch {
  vertical-align: middle;
  font-size: 0.75rem;
  border: 1px solid #D7DAE2;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0;
  background: white;
  color: #1976d2;
  height: 34px;
  padding-left: 16px;
}

.filterBySearchButton {
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #D7DAE2;
  border-left: 0;
  height: 34px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 10px;

  &.clean {
    border-right: 1px solid #D7DAE2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  img {
    width: 14px;
    margin-top: 5px;
  }
}
</style>

