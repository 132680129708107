<template>
<svg id="Capa_2" data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" width="15" height="14.158" viewBox="0 0 15 14.158" :class="{'dBlock': block}">
  <g id="Capa_1" data-name="Capa 1" transform="translate(0 0)">
    <path id="Trazado_5329" data-name="Trazado 5329" d="M3.863,5.211a.785.785,0,0,0,1.1,0A4.472,4.472,0,0,0,6.13,2.858c0-.039,0-.086,0-.126A3.232,3.232,0,0,0,4.867.159a.787.787,0,0,0-.949,1.255,1.64,1.64,0,0,1,.651,1.24,2.887,2.887,0,0,1-.737,1.451A.785.785,0,0,0,3.863,5.211Z" transform="translate(-0.759 -0.009)"  :fill="color" />
    <path id="Trazado_5330" data-name="Trazado 5330" d="M8.745,5.211a.785.785,0,0,0,1.1,0,4.472,4.472,0,0,0,1.169-2.354c0-.039,0-.086,0-.126A3.216,3.216,0,0,0,9.749.159a.78.78,0,0,0-.926,1.255,1.663,1.663,0,0,1,.643,1.24,2.824,2.824,0,0,1-.737,1.451A.785.785,0,0,0,8.745,5.211Z" transform="translate(-1.784 -0.009)" :fill="color"/>
    <path id="Trazado_5331" data-name="Trazado 5331" d="M13.628,5.211a.785.785,0,0,0,1.1,0A4.472,4.472,0,0,0,15.9,2.858a.675.675,0,0,0,0-.126A3.209,3.209,0,0,0,14.656.159a.78.78,0,0,0-.926,1.255,1.632,1.632,0,0,1,.643,1.24,2.824,2.824,0,0,1-.737,1.451.785.785,0,0,0-.008,1.106Z" transform="translate(-2.796 -0.009)" :fill="color"/>
    <path id="Trazado_5332" data-name="Trazado 5332" d="M13.759,7.39H1.35A1.354,1.354,0,0,0,0,8.653v.133a7.527,7.527,0,0,0,15,0A1.337,1.337,0,0,0,13.759,7.39ZM7.5,14.145a5.924,5.924,0,0,1-5.92-5.193H13.42A5.939,5.939,0,0,1,7.5,14.145Z" transform="translate(0 -1.512)" :fill="color"/>
  </g>
</svg>
</template>

<script>
export default {
  name: "IcoProduct",
  props: {
    color: {
        type: String,
        default: '#000000',
        required: false
    },
    block: {
        type: Boolean,
        default: true,
        required: false
    }
  }
}
</script>

<style scoped>
    .dBlock{
        display: block;
    }
</style>