import promotionsAnalysis from '../views/GastroPricingPromotion/Home.vue'
import promoComparator from '../views/GastroPricingPromotion/Pages/promoComparator.vue'
import promoNews from '../views/GastroPricingPromotion/Pages/promoNews.vue'
import promoFinished from '../views/GastroPricingPromotion/Pages/promoFinished.vue'
import promoPizzas from '../views/GastroPricingPromotion/Pages/promoPizzas.vue'

export default {
    path: '/promotions-analysis',
    name: 'promotionsAnalysis',
    component: promotionsAnalysis,
    redirect: '/promotions-analysis/promo-comparator/:paramCOD?',
    meta: {Auth: true, isGP: true},
    props: true,
    children: [
      {
        path: 'promo-comparator/:paramCOD?',
        name: 'promoComparator',
        component: promoComparator,
        meta: {Auth: true, isGP: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'promo-news/:paramCOD?',
        name: 'promoNews',
        component: promoNews,
        meta: {Auth: true, isGP: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'promo-finished/:paramCOD?',
        name: 'promoFinished',
        component: promoFinished,
        meta: {Auth: true, isGP: true},
        props(route) {
          return  route.query || {}
        }
      },
      {
        path: 'promo-pizzas/:paramCOD?',
        name: 'promoPizzas',
        component: promoPizzas,
        meta: {Auth: true, isGP: true},
        props(route) {
          return  route.query || {}
        }
      }
    ]
  }