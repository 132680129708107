<template lang="pug">
div.text-center        
    div.warnkingNotDeliveryNotInternet( :class="{'borderDelivery': type === 'delivery'}")
        button.close(@click="close()")
            img(:src="imgClose")
        img(:src="icoNotData")
        p.mt-8 
            strong(v-if="type === 'internet'") #[translate No hay datos disponibles de internet.] 
            strong(v-else-if="type === 'global'") #[translate No hay datos disponibles] 
            strong(v-else) #[translate No hay datos disponibles de delivery.] 
</template>

<script>
export default {
    props: ['type'],
    data() {
        return {
            icoNotData: require('@/assets/img/nodata.svg'),
            imgClose: require('@/assets/img/ico-close-edit.svg'),

        }
    },
    methods: {
        close() {
            if (this.type === 'internet') {
                this.$store.commit('COMMON_SHOW_NOT_INTERNET', false)
            }
            if (this.type === 'delivery') {
                this.$store.commit('COMMON_SHOW_NOT_DELIVERY', false)
            }
        }
    }
}
</script>

<style lang="scss">
.warnkingNotDeliveryNotInternet {
    position: fixed;
    background-color: #fff;
    border-radius: 8px;
    padding: 80px;
    z-index: 10;
    max-width: fit-content;
    margin: auto;
    top: 0;
    left: 45%;
    bottom: 0;
    max-height: fit-content;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);

    .close {
        position: absolute;
        top: 10px;
        right: 20px;
    }

}
</style>