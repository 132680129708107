<template>
    <button @click="setSort()" class="p-[4px]">
        <svg :class="{ 'rotate-180': isActive && orderByActual.indexOf('-') === 0 }" xmlns="http://www.w3.org/2000/svg"
            width="9.914" height="9.907" viewBox="0 0 9.914 9.907">
            <path class="hover-stroke-[#3b86ff]" :stroke="isActive ? '#3b86ff' : '#999ea5'" id="source_icons_arrow-down_3_"
                data-name="source_icons_arrow-down (3)" d="M3.84,0V8m0,0L0,4.16M3.84,8,7.68,4.16"
                transform="translate(8.797 9.117) rotate(180)" fill="none" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="1.58" />
        </svg>
    </button>
</template>
<script>
export default {
    props: ['orderBy', 'orderByActual'],
    data() {
        return {

        }
    },
    computed: {
        isActive() {
            return this.orderBy.replaceAll('-', '') == this.orderByActual.replaceAll('-', '')
        }
    },
    methods: {
        setSort() {
            let keySort
            if (this.orderBy.replaceAll('-', '') != this.orderByActual.replaceAll('-', '')) {
                keySort = this.orderBy
            } else if (this.orderByActual.indexOf('-') < 0) {
                keySort = this.orderBy.replaceAll('-', '')
                let fields = keySort.split(',')
                keySort = fields.map(e => '-' + e).join()
            } else {
                keySort = this.orderBy.replaceAll('-', '')
            }

            this.$emit('sort', keySort)
        }
    }
}

</script>
<style scoped>
button svg path {
    transition: all ease 0.3s;
}

button:hover svg path {
    stroke: #3b86ff;
}</style>