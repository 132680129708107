<template lang="pug">
  div.gridSection
    div.gridSection
      div
          v-col.col-filters.col-filters-big-home.pt-0.pb-0
            div.filters
              span.downloadExcel(v-if="showDownloadInHome" @click="downloadExcelCustom()")
                img(:src="imgCommons.download" width="10")
              div.gr-filter-input-wrapper
                translate.label Province
                div.menu
                  filter-tree-menu(label-class="text-capitalize-first-letter" :options="regions" :itemsChecked="regionSelect" @checked="onCheckRegion" id-key="value" label-key="text")
              div.gr-filter-input-wrapper(v-if="typeNode !== 'center'")
                translate.label Aggregator
                div.menu
                  filter-tree-menu(type="radio" :optionSelectAll="false" label-class="text-capitalize-first-letter" radioValueKey="text" :options="aggregatosForCountry" :selected="filters.aggregatorSelected" @checked="onCheckAggregator" id-key="value" label-key="text")
              div.gr-filter-input-wrapper
                translate.label Momento de Consumo
                div.menu
                  filter-tree-menu(type="radio" :optionSelectAll="false" label-class="text-capitalize-first-letter" radioValueKey="text" :options="filters.moments" :selected="filters.momentSelected" @checked="onCheckMoment" id-key="value" label-key="text")
              div.gr-filter-input-wrapper
                translate.label Distancia
                div.menu
                  filter-tree-menu(type="radio" :optionSelectAll="false" label-class="text-capitalize-first-letter" radioValueKey="text"  :options="filters.distances" :selected="filters.distanceSelected" @checked="onCheckDistance" id-key="value" label-key="text")
              div.filter.filtroDate
                range-date
      .gridFull(v-if="isLoading")
        v-skeleton-loader(type="table")
      div.gridFull.narrow.borderDelivery(v-else)
        v-row.headTitleGrid
          v-col.titleGrid(md="4")
            translate Delivery Analytics
            p.mt-2.mb-0 {{ filters.aggregatorSelected }}
          v-col.titleInfo(md="6")
          v-col.iconInfo.text-right(md="2")
        v-row.headIndicators.text-left.align-center
          v-col.colName
            translate Name
            img.ml-2.cursor-pointer(:src="imgCommons.order" @click="sortAppointments('name')")
          v-col.text-center.colHour
            span Avg
            img.ml-2.cursor-pointer(:src="imgCommons.order" @click="sortAppointments('rating')")
          v-col.text-right.colHour
            span Rating
            img.ml-2.cursor-pointer(:src="imgCommons.order" @click="sortAppointments('rating_base')")
          v-col.text-right.colHour(v-for="head in headers")
            span.font-weight-med(v-if="head === 'avg'" style="text-transform:uppercase") MEDIA
            span.font-weight-med(v-else style="text-transform:uppercase") {{ head }}
            img.ml-1.cursor-pointer(:src="imgCommons.order" @click="sortAppointments(head)")

        v-row.bodyIndicators.text-left.align-center.mt-3(v-if="dataRanking" v-for="(item, index) in paginated" :class="{'b-top' : index > 0, 'row-average': item.average}")
          v-col.colName
            span {{item.name}}
          v-col.text-right.colHour
            p.mb-0.box(:style="'background-color:' + getColorNote(item.rating,'color')") {{item.rating | formatNumber}}
          v-col.text-right.colHour
            div.d-flex.justify-center.items-center
              div.d-flex.justify-center.items-center(v-if="filters.aggregatorSelected === 'Glovo'")
                div.d-flex.justify-center.items-center.text-center(v-if="formatNumber(item['rating_base']) === '-'")
                  div -
                div.d-flex.justify-center.items-center.gap-0_5em(v-else)
                  GlovoRating(:percent="item['rating_base'] || 0 ")
                  span {{ item['rating_base'] | formatNumber }}%

              div.d-flex.justify-center.items-center(v-if="filters.aggregatorSelected === 'Uber'")
                div.d-flex.justify-center.items-center(v-if="formatNumber(item['rating_base']) === '-'")
                  div -
                div.d-flex.justify-center.items-center.gap-0_5em(v-else)
                  img(:src="img.imgStar")
                  span {{ item['rating_base'] | formatNumber }}

            //- p.mb-0.box(:style="'background-color:' + getColorNote(item.rating,'color')") {{item.rating | formatNumber}}
          v-col.text-right.colHour(v-for="hour in headers")
            span(v-if="item.positions[hour]" :class="{'font-weight-med': hour === 'avg'}") {{item.positions[hour] |formatNumber}}
            span(v-else) -
</template>

<script>
import FilterTreeMenu from "@/components/filters/filterTreeMenu"
import RangeDate from '@/components/filters/RangeDates.vue'

import * as types from '@/store/mutation-types'
import GlovoRating from '@/components/common/GlovoRating.vue'
import SrvNetwork from '@/services/network.js'
import commonMixins from "@/mixins/common.js"
export default {
  props: ['paramCOD'],
  mixins: [commonMixins],
  components: {GlovoRating, FilterTreeMenu, RangeDate},
  data(){
    return{
      current: 1,
      pageSize: 20,
      dataRanking: [],
      img:{
        imgStar: require('@/assets/img/star.svg'),
      },
      filters:{
        aggregators: [{text:'Glovo', value:1},{text:'Uber', value:2}],
        moments: [{text:'Mediodia', value:1}, {text:'Noche', value:2}],
        distances: [{text:'500 m', value:1}, {text:'1.000 m', value:2}, {text:'1.500 m',value:3}, {text:'2.000 m',value:4}],
        aggregatorSelected: null,
        aggregatorSelectedId: null,
        momentSelected: null,
        momentSelectedId: null,
        distanceSelected: null,
        distanceSelectedId: null
      },
      labelSearch: this.$gettext('Search'),
      sortParam: 'name',
      sortDesc: true,
      helpRanking: this.$gettext(''),
      validHoursMorning: ["12:30","13:00","13:30","14:00","14:30","15:00","15:30","16:00","16:30"],
      validHoursNigth: ["19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00"]
    }
  },
  created(){
      if (this.$store.getters.getAggregatorId) {
        this.filters.aggregatorSelected = this.filters.aggregators[parseInt(this.$store.getters.getAggregatorId)-1].text
        this.filters.aggregatorSelectedId = this.filters.aggregators[parseInt(this.$store.getters.getAggregatorId)-1].value
      } else {
        this.filters.aggregatorSelected = this.aggregatosForCountry[0].text
        this.filters.aggregatorSelectedId = this.aggregatosForCountry[0].value
      }
      if (this.$store.getters.getMomentId) {
        this.filters.momentSelected = this.filters.moments[parseInt(this.$store.getters.getMomentId)-1].text
        this.filters.momentSelectedId = this.filters.moments[parseInt(this.$store.getters.getMomentId)-1].value
      } else {
        this.filters.momentSelected = this.filters.moments[0].text
        this.filters.momentSelectedId = this.filters.moments[0].value
      }
      if (this.$store.getters.getDistanceId) {
        this.filters.distanceSelected = this.filters.distances[parseInt(this.$store.getters.getDistanceId)-1].text
        this.filters.distanceSelectedId = this.filters.distances[parseInt(this.$store.getters.getDistanceId)-1].value
      } else {
        this.filters.distanceSelected = this.filters.distances[0].text
        this.filters.distanceSelectedId = this.filters.distances[0].value
      }
      if(this.$store.getters.getNode > 0){
        this.getRanking()
      }
  },
  computed:{
    aggregatosForCountry () {
      if (this.$store.getters.getTreeSelected.prefix.indexOf('fr') > -1) {
        return [this.filters.aggregators[1]]
      }
      return this.filters.aggregators
    },
    headers () {
      let headers = []
      if (this.filters.momentSelectedId === 1 ) {
        headers = this.validHoursMorning
      } else {
        headers = this.validHoursNigth
      }
      headers.push('avg')
      return headers

    },
    paginated() {
      let arrFiltered = [...this.dataRanking]
      arrFiltered = arrFiltered.sort((a, b) => {
        if (this.sortParam === 'name') {
          if (a.name > b.name) {
            return 1
          }
          if (a.name < b.name) {
            return -1
          }
          return 0
        } else {
          let aPos = a.positions[this.sortParam] || 0
          let bPos = b.positions[this.sortParam] || 0
          return parseFloat(aPos) - parseFloat(bPos)
        }
      })
      if(!this.sortDesc){
        arrFiltered = arrFiltered.reverse()
      }
      return arrFiltered
    }
  },
  watch: {
    filterApply: function(){
      this.getRanking()
    },
  },
  methods: {
    formatNumber(val, min = 0, max = 1) {
      if (typeof (val) !== 'string') {
        if (val === null) {
          return '-'
        }
        return Number(val).toLocaleString('de-De', {minimumFractionDigits: min, maximumFractionDigits: max})
      }
      return val
    },
    downloadExcelCustom() {
      this.downloadExcel({aggregator: this.filters.aggregatorSelectedId, moment: this.filters.momentSelectedId, distance:  this.filters.distanceSelectedId }, this.dataRanking.length)
    },
    onCheckAggregator(val) {
      this.filters.aggregatorSelected = val.text
      let filtrados = this.filters.aggregators.filter( item => item.text == val.text )
      this.filters.aggregatorSelectedId = filtrados[0].value
      this.$store.commit(types.COMMON_AGGREGATOR_ID, this.filters.aggregatorSelectedId)
      this.updateUrl()
      this.getRanking()
    },
    onCheckMoment(val) {
      this.filters.momentSelected = val.text
      let filtrados = this.filters.moments.filter( item => item.text == val.text )
      this.filters.momentSelectedId = filtrados[0].value
      this.$store.commit(types.COMMON_MOMENT_ID, this.filters.momentSelectedId)
      this.updateUrl()
      this.getRanking()
    },
    onCheckDistance(val) {
      this.filters.distanceSelected = val.text
      let filtrados = this.filters.distances.filter( item => item.text == val.text )
      this.filters.distanceSelectedId = filtrados[0].value
      this.$store.commit(types.COMMON_DISTANCE_ID, this.filters.distanceSelectedId)
      this.updateUrl()
      this.getRanking()
    },
    // OBTENEMOS INDICADORES
    getRanking() {
      this.$store.commit('LOADING', true)
      SrvNetwork.getAggregators({aggregator: this.filters.aggregatorSelectedId, moment: this.filters.momentSelectedId, distance:  this.filters.distanceSelectedId })
          .then(res => {
            if (res.status === 200) {
              this.dataRanking = res.data
              this.$store.commit('LOADING', false)
            } else {
              // this.message = 'Se ha producido un error. Intentelo más tarde.'
            }
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
    },
    sortAppointments(param) {
      this.sortDesc = this.sortParam === param ? !this.sortDesc : true
      this.sortParam = param
    },
  }
}
</script>

<style lang="scss" scoped>
.box{
    width: 50px;
    border-radius: 5px;
    color:#fff;
    text-align: center;
    display:inline-block;
    margin-right: 23px;
    padding-top: 4px;
    padding-bottom: 4px;
}
.colName{
  flex: 18%;
}
.colHour{
  flex: 6%;
  padding-left: 0;
}
.colMean{
    font-weight: 600;
  }
.gridSection{
  padding-top: 12px;
  padding-bottom: 12px;
}
.filters {
  
  text-align: right;
  flex-wrap: wrap;
  padding-right: 10px;
}
.gridFull.narrow{ .bodyIndicators{
  margin-left: -10px;
  margin-right: -10px;
  height: auto !important;
  .colHour{
    span{
      padding-right: 23px;
    }
  }
  &:nth-child(even){
    background-color: #F7F8F9;
  }
}
}
</style>
