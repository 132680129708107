<template>
<div class="localPost text-14">
    <div class="image">
        <p>Todavía no hay publicaciones</p>
    </div>
    <div class="infoPost">
        
    </div>
</div>
</template>

<script>
import localMixins from '@/mixins/local.js'
export default{
    mixins: [localMixins],
    data(){
        return {
            status: 'ok'
        }
    }
}
</script>
<style lang="scss" scoped>

.localPost{
    border-radius: 4px;
    border:1px solid #CECECE;
    .image{
        font-weight: bold;
        background-color: #F2F2F2;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 182px;
    }
    .infoPost{
        height: 135px;
    }
}

</style>