<script>
import Chart from 'chart.js'
import { generateChart, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

// CUSTOMIZAMOS LA GRAFICA DE LINEA PARA AÑADIR BARRAS DE COLORES A LOS LATERALES
Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.defaults.global.legend.labels.usePointStyle = true;
const CustomLine = generateChart('custom-line-gr', 'LineWithLine')
export default {
  name: "valid_name",
  extends: CustomLine,
  mixins: [reactiveProp],
  props:['chartData', 'legend', 'maxY', 'minY'],
  data: () => ({
    chartDataCustom: null,
  }),
  created () {
    this.chartDataCustom = this.chartData
  },
  watch: {
    chartDataCustom () {
      // MOSTRAMOS VALORES DEL EJE Y A IZQUIERDA Y DERECHA
      this.renderChart(this.chartData, this.options)
    }
  },
  computed: {
    options () {
      return {
      animation: {
        duration: 0
      },
      tooltips: {
          callbacks: {
              labelColor: function(tooltipItem,chart) {
                  return {
                      borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                      backgroundColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                  }
              },
              label: function(tooltipItems, data) {
                var label = data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.yLabel.toFixed(2)
                return label
              }
          }
      },
      legend: {
          display: true,
          position: 'right',
          align: 'start',
          maxWidth: 150,
          fullSize: false
      },
      plugins: {
        // Change options for ALL labels of THIS CHART
        datalabels: {
            display: false
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
         yAxes: [{
            id: 'y-axis-0-gr',
            position: 'left',
            ticks: {
               beginAtZero: true,
               steps: 10,
               stepValue: 2,
               max: this.maxY,
               min: this.minY
            },
            gridLines: {
              tickMarkLength: 50
            },
         }, {
            id: 'y-axis-1-gr',
            position: 'right',
            gridLines: {
              tickMarkLength: 50
            },
            ticks: {
               beginAtZero: true,
                steps: 10,
                stepValue: 2,
                max: this.maxY,
                min: this.minY
            }
         }]
      },
      }
    }
  },
  mounted () {
      Chart.plugins.register({
        beforeInit: function(chart) {
          if (chart.canvas.id == 'custom-line-gr') {
            Chart.controllers.LineWithLine = Chart.controllers.line.extend({
              draw: function(ease) {
                Chart.controllers.bubble.prototype.draw.call(this, ease);
                var ctx = this.chart.chart.ctx;
                // LINEA VERDE IZQUIERDA
                ctx.beginPath();
                ctx.moveTo(40, 6);
                ctx.lineWidth = 11;
                ctx.strokeStyle = '#89C445';
                ctx.lineTo(40, 94);
                ctx.stroke();
                ctx.save();

                // LINEA NARANJA IZQUIERDA
                ctx.beginPath();
                ctx.moveTo(40, 94);
                ctx.lineWidth = 11;
                ctx.strokeStyle = '#FCAE19';
                ctx.lineTo(40, 220);
                ctx.stroke();
                ctx.save();

                // LINEA ROJA IZQUIERDA
                ctx.beginPath();
                ctx.moveTo(40, 188);
                ctx.lineWidth = 11;
                ctx.strokeStyle = '#F16621';
                ctx.lineTo(40, 370);
                ctx.stroke();
                ctx.save();

                ctx.restore();
                
              }   
            })
          }
        }
      });

      this.renderChart(this.chartData, this.options)

  }
}
</script>