<template lang="pug">
.filter-top.d-flex.flex-wrap
  v-col.col-brand.mr-1.pl-0
    v-select.pt-0.selectFilterGP(v-model="brandSelect" :items="brands" :placeholder="placeholder.brand"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeBrand")
      template(slot="append")
        v-icon(color="terciary") mdi-chevron-down
      template(v-slot:selection="{ item, index }")
        img(:src="item.logo" width="41" height="22") 
        span.terciary--text.text-uppercase.text-elipsis {{ item.name }}
      template(v-slot:item="{ item }")
        img.mr-2.mb-2.img-option(:src="item.logo" width="79" height="42") 
        span.option-filter-brand {{ item.name }}
  v-col.col-province.mr-1.pl-0
    v-select.pt-0.selectFilterGP(v-model="filters.province" :items="provinces" item-text="province_name" return-object :placeholder="placeholder.province" no-data-text="no hay provincias para esta marca" color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" :disabled="!brandSelect" @change="changeFilter('province')" clearable)
      template(slot="append")
        v-icon(color="terciary") mdi-chevron-down
      template(v-slot:selection="{ item, index }")
        span.terciary--text.text-uppercase.text-elipsis {{ item.province_name }}
      template(v-slot:item="{item }")
        span.option-filter( :class="{'selected' : filters.province.province_name === item.province_name}") {{ item.province_name }}
  v-col.col-location.mr-1.pl-0
    v-select.pt-0.selectFilterGP(v-model="filters.city" :items="cities" item-text="city_name" return-object :placeholder="placeholder.location" no-data-text="no hay ciudades para esta provincia"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeFilter('city')" :disabled="!filters.province" clearable)
      template(slot="append")
        v-icon(color="terciary") mdi-chevron-down
      template(v-slot:selection="{ item, index }")
        span.terciary--text.text-uppercase.text-elipsis {{ item.city_name }}
      template(v-slot:item="{ item }")
        span.option-filter {{ item.city_name }} ({{ item.center_count }})
  //v-col.col-cp.mr-1.pl-0
    v-select.pt-0(v-model="filters.municipality" :items="cps" item-text="municipality_postal_code" return-object :placeholder="placeholder.postal_code" no-data-text="no hay códigos postales para esta ciudad" color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeFilter('municipality')" :disabled="!filters.city" clearable)
      template(slot="append")
        v-icon(color="terciary") mdi-chevron-down
      template(v-slot:selection="{ item, index }")
        span.terciary--text.text-uppercase {{ item.municipality_postal_code }}
      template(v-slot:item="{ item }")
        span.option-filter {{ item.municipality_postal_code }}
  v-col.col-moment.mr-1.pl-0(v-if="type !== 'promotions'")
    v-select.pt-0.selectFilterGP(v-model="filters.moment_of_consumption" :items="moments" item-text="name" return-object :placeholder="placeholder.moment"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" :disabled="!brandSelect" @change="changeFilter('moment_of_consumption')" clearable)
      template(slot="append")
        v-icon(color="terciary") mdi-chevron-down
      template(v-slot:selection="{ item, index }")
        span.terciary--text.text-uppercase.text-elipsis {{ item.name }}
      template(v-slot:item="{ item }")
        span.option-filter {{ item.name }}
  v-col.col-channel.mr-1.pl-0(v-if="type !== 'promotions'")
    v-select.pt-0.selectFilterGP(v-model="filters.channel" :items="channels" item-text="name" return-object :placeholder="placeholder.channel"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" :disabled="!brandSelect" @change="changeFilter('channel')" clearable)
      template(slot="append")
        v-icon(color="terciary") mdi-chevron-down
      template(v-slot:selection="{ item, index }")
        span.terciary--text.text-uppercase.text-elipsis {{ item.name }}
      template(v-slot:item="{ item }")
        span.option-filter {{ item.name }}
  v-col.col-aggregator.pl-0(v-if="type !== 'promotions'")
    v-select.pt-0.selectFilterGP(v-model="filters.aggregator" :items="aggregators" item-text="name" return-object :placeholder="placeholder.Aggregator"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" :disabled="!brandSelect || filters.channel.name !== 'Delivery'" @change="changeFilter('aggregator')" clearable)
      template(slot="append")
        v-icon(color="terciary") mdi-chevron-down
      template(v-slot:selection="{ item, index }")
        span.terciary--text.text-uppercase.text-elipsis {{ item.name }}
      template(v-slot:item="{ item }")
        span.option-filter {{ item.name }}
</template>

<script>
import SrvGastroPricing from '@/services/gastropricing.js'

export default {
  props: ['type'],
  data() {
    return {
      brands: [],
      provinces: [],
      cities: [],
      cps: [],
      channels: [],
      moments: [],
      aggregators: [],
      filters: {
        province: '',
        city: '',
        municipality: '',
        moment_of_consumption: '',
        channel: '',
        aggregator: '',
      },
      brandSelect: '',

      placeholder: {
        brand: this.$gettext('BRAND'),
        province: this.$gettext('PROVINCE'),
        location: this.$gettext('LOCATION'),
        postal_code: this.$gettext('POSTAL CODE'),
        moment: this.$gettext('MOMENT OF CONSUMPTION'),
        channel: this.$gettext('CHANNEL'),
        Aggregator: this.$gettext('AGGREGATORS')
      }
    }
  },
  created() {
    if (!this.$store.getters.hasBrand) {
      SrvGastroPricing.getCountries().then(res => {
        if (res.status === 200) {
          const result = res.data.filter(c => c.code == window.localStorage.getItem('_country_selected_gp'))
          if (result.length > 0) {
            window.localStorage.setItem('_country_selected_gp', result[0].code)
          } else {
            window.localStorage.setItem('_country_selected_gp', res.data[0].code)
          }
          window.localStorage.setItem('_countries_gp', JSON.stringify(res.data))
          this.getBrands()
        }
      })
    } else {
      this.brands = this.$store.getters.hasBrands
      this.brandSelect = this.$store.getters.hasBrand
      this.provinces = this.$store.getters.hasProvinces
      this.channels = this.$store.getters.hasChannels
      if (this.$store.getters.hasMoments === '') {
        this.getMoments()
      }
      this.moments = this.$store.getters.hasMoments
      this.aggregators = this.$store.getters.hasAggregators
      if (this.$store.getters.hasProvince) {
        this.filters.province = this.$store.getters.hasProvince
        this.cities = this.$store.getters.hasCities
        if (this.$store.getters.hasCity) {
          this.filters.city = this.$store.getters.hasCity
          this.cps = this.$store.getters.hasPostalCodes
          if (this.$store.getters.hasPostalCode) {
            this.filters.municipality = this.$store.getters.hasPostalCode
          }
        }
      }
      if (this.$store.getters.hasMoment) {
        this.filters.moment_of_consumption = this.$store.getters.hasMoment
      }
      if (this.$store.getters.hasChannel) {
        this.filters.channel = this.$store.getters.hasChannel
      }
      if (this.$store.getters.hasAggregator) {
        this.filters.aggregator = this.$store.getters.hasAggregator
      }
      this.$emit("getData", { brand: this.$store.getters.hasBrand.id, filters: this.filters });

    }

  },
  methods: {
    async getBrands() {
      SrvGastroPricing.getBrands()
        .then(async res => {
          if (res.status === 200) {
            this.brands = res.data.brands
            this.$store.commit('GP_BRANDS', res.data.brands)
            if (this.brands.length > 0) {
              if (this.$store.getters.hasBrand && this.brands.filter(e => e.id === this.$store.getters.hasBrand.id).length > 0) {
                this.brandSelect = this.$store.getters.hasBrand
              } else {
                this.brandSelect = this.brands[0]
              }
              this.$store.commit('GP_BRAND_SELECT', this.brandSelect)
              this.getFamilies(this.brandSelect.id)
              this.getProvinces(this.brandSelect.id)
              this.filters.channel = await this.getChannels(true)
              this.filters.moment_of_consumption = await this.getMoments(true)
              this.getAggregators()

              this.$emit("getData", { brand: this.brandSelect.id, filters: this.filters });
            }
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    getProvinces(brand) {
      SrvGastroPricing.getProvinces(brand)
        .then(res => {
          if (res.status === 200) {
            this.provinces = res.data
            this.$store.commit('GP_PROVINCES', res.data)

          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    getCities(province) {
      SrvGastroPricing.getCities(this.brandSelect.id, province.province_id)
        .then(res => {
          if (res.status === 200) {
            this.cities = res.data
            this.$store.commit('GP_CITIES', res.data)
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    getCps(city) {
      SrvGastroPricing.getCps(this.brandSelect.id, city.city_id)
        .then(res => {
          if (res.status === 200) {
            this.cps = res.data
            this.$store.commit('GP_POSTAL_CODES', res.data)
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    async getFamilies(brand) {
      let valAwait = ''
      await SrvGastroPricing.getFamilies(brand)
        .then(res => {
          if (res.status === 200) {
            this.families = res.data
            this.$store.commit('GP_FAMILIES', res.data)
            valAwait = res.data
          }
        }, () => {
        })
      return valAwait
    },
    async getChannels(first = false) {
      let valAwait = ''
      await SrvGastroPricing.getChannels()
        .then(res => {
          if (res.status === 200) {
            this.channels = res.data
            this.$store.commit('GP_CHANNELS', res.data)
            if (first) {
              res.data.forEach(element => {
                if (element.name === 'Delivery') {
                  this.$store.commit('GP_CHANNEL_SELECT', element)
                  valAwait = element
                }
              })
            }
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
      return valAwait
    },
    async getMoments(first = false) {
      let valAwait = ''
      await SrvGastroPricing.getMoments(this.brandSelect.id)
        .then(res => {
          if (res.status === 200) {
            this.moments = res.data.results
            this.$store.commit('GP_MOMENTS', res.data.results)
            if (first) {
              res.data.results.forEach(element => {
                if (element.name === 'Almuerzo') {
                  this.$store.commit('GP_MOMENT_SELECT', element)
                  valAwait = element
                }
              })
            }
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
      return valAwait
    },
    getAggregators() {
      SrvGastroPricing.getAggregators()
        .then(res => {
          if (res.status === 200) {
            this.aggregators = res.data
            this.$store.commit('GP_AGGREGATORS', res.data)
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    // getImgUrl(name) {
    //   return require('@/assets/img/logos/logo_'+ name + ".png")
    // },
    changeBrand() {
      this.$store.commit('GP_BRAND_SELECT', this.brandSelect)
      this.provinces = []
      this.$store.commit('GP_PROVINCE_SELECT', '')
      this.filters.province = ''
      this.getFamilies(this.brandSelect.id)
      this.getProvinces(this.brandSelect.id)

      this.$emit("getData", { brand: this.brandSelect.id, filters: this.filters });
      this.getMoments()
    },
    changeFilter(type) {
      if (this.filters[type] === null) {
        this.filters[type] = ''
      }
      if (type === 'province') {
        if (this.filters.province) {
          this.getCities(this.filters.province)
        }
        this.filters.city = ''
        this.filters.municipality = ''
        this.cps = []
        this.$store.commit('GP_POSTAL_CODES', [])
      }
      if (type === 'city') {
        if (this.filters.city) {
          //this.getCps(this.filters.city)
        }
        this.filters.municipality = ''
      }
      this.$store.commit('GP_PROVINCE_SELECT', this.filters.province)
      this.$store.commit('GP_CITY_SELECT', this.filters.city)
      this.$store.commit('GP_POSTAL_CODE_SELECT', this.filters.municipality)
      this.$store.commit('GP_MOMENT_SELECT', this.filters.moment_of_consumption)
      this.$store.commit('GP_CHANNEL_SELECT', this.filters.channel)
      this.$store.commit('GP_AGGREGATOR_SELECT', this.filters.aggregator)

      this.$emit("getData", { brand: this.brandSelect.id, filters: this.filters });
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-top {
  .col-brand {
    flex: 0 0 260px;
    max-width: 260px;

  }

  .col-province {
    flex: 0 0 187px;
    max-width: 187px;
  }

  .col-location {
    flex: 0 0 150px;
    max-width: 150px;
  }

  .col-cp {
    flex: 0 0 170px;
    max-width: 170px;
  }

  .col-moment {
    flex: 0 0 222px;
    max-width: 222px;
  }

  .col-channel {
    flex: 0 0 152px;
    max-width: 152px;
  }

  .col-aggregator {
    flex: 0 0 172px;
    max-width: 172px;
  }

  .col {
    span {
      font-size: 14px;
    }
  }
}

.v-input--is-disabled {
  opacity: 0.6;
}
</style>
