<template lang="pug">
div.boxSectionHeaderAndBody
  div#navtop(:class="{'anchoEscritorioSubnavCerrado': subNavClosed, 'noTree': $route.meta.isGP }")
    div.sectionmenu
      div.firstlevel
        router-link(:to="{name: 'ReportsList', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Reports]
        router-link(v-if="hasReportsExcel && typeNode == 'brand'" :to="{name: 'ReportsListExcel', params: {paramCOD: this.paramCOD}, query: queryParams}" ) #[translate Reports Excel]
        select-country-and-logout
      div.gridSection
        filters-and-bread-crumb(:paramCOD="paramCOD" :is-sticky="false")
        router-view.bg-gray

</template>

<script>
import FiltersAndBreadCrumb from "@/components/filters/filtersAndBreadCrumb.vue"
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import SelectCountryAndLogout from "@/components/common/SelectCountryAndLogout.vue"
export default {
  props: ['paramCOD', 'type', 'site', 'typeRestaurant', 'after', 'before'],
  mixins: [commonMixins],
  components: { FiltersAndBreadCrumb, SelectCountryAndLogout },
  async created() {
    this.$store.commit(types.UPDATE_SECTION, 4)
    this.loadUrlParams()
  }
}
</script>

<style lang="scss" scoped>
.sectionmenu {
  .thirstlevel {
    background-color: #F2F2F2;
    margin-top: 0;
    padding-bottom: 12px;
    padding-top: 12px;
  }
}
</style>
