import FacebookLocationsApp from '../components/local/FacebookLocationsApp.vue'
import GoogleTokenApp from "@/components/local/GoogleTokenApp.vue"
import AppWelcome from '../views/App/AppWelcome.vue'
import OpenApp from "@/components/local/OpenApp.vue"

export default {
    path: '/app',
    name: 'app',
    component: () => import('../views/App/App.vue'),
    meta: {Auth: false},
    props: true,
    children: [
    {
        path: 'openapp',
        name: 'facebooktoken',
        component: OpenApp,
        meta: {Auth: false}
    },
    {
        path: 'facebooktoken',
        name: 'facebooktoken',
        component: FacebookLocationsApp,
        meta: {Auth: false}
    },
    {
        path: 'googletoken/',
        name: 'googletoken',
        component: GoogleTokenApp,
        meta: {Auth: false}
    },
    {
        path: 'googletoken/:uid/:token',
        name: 'googletoken',
        component: GoogleTokenApp,
        meta: {Auth: false}
    },
    {
        path: 'welcome',
        name: 'appwelcome',
        component: AppWelcome,
        meta: {Auth: false},
        props(route) {
        return  route.query || {}
        }
    }
]
}