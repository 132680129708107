// Importamos mutaciones
import * as types from "@/store/mutation-types";
import moment from "moment";
const local = {
  state: {
    pending: false,
    range: [moment().add({ months: -1 }).toDate(), moment().toDate()],
    canModifyServicesList: false,
    search: null,
  },
  mutations: {
    [types.LOCAL_PENDING](state, data) {
      state.pending = data;
    },
    [types.LOCAL_RANGE_DATES](state, data) {
      state.range = data;
    },
    [types.CAN_MODIFY_SERVICES_LIST](state, data) {
      state.canModifyServicesList = data;
    },
    [types.LOCAL_GOOGLE_SEARCH](state, data) {
      state.search = data;
    },
  },
  getters: {
    getRangeDatesMetrics: (state) => {
      return state.range;
    },
    getCanModifyServicesList: (state) => {
      return state.canModifyServicesList;
    },
    getIsPending: (state) => state.pending,
    getLocalGoogleSearch: (state) => {
      return state.search;
    },
  },
};

export default local;
