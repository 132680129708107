// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
  getGlobal (version = false, excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/summary/`;
      query = SrvCommon.getBasicParamsQuery(query)

      if(Stores.getters.getTypesSelected){
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if(Stores.getters.getSitesSelected.length > 0){
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }
      if (version) {
        query += `&version=reviews`
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }

      // llamada a la API
      let token = SrvCommon.getToken();
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query,configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getInfoCoverManager () {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/cmn_summary/`;
      query = SrvCommon.getBasicParamsQuery(query)

      /*if(Stores.getters.getTypesSelected){
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }*/
      /*if(Stores.getters.getSitesSelected){
        query += `&site=${Stores.getters.getSitesSelected}`
      }*/
      /*if (version) {
        query += `&version=reviews`
      }*/

      /**
       * center_model
       */
      /*if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }*/

      // llamada a la API
      let token = SrvCommon.getToken()
      let configHeaders = {}
      /*if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }*/

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query,configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getInfoCompetenceCenterChatGPT(data) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/chatgpt/competence_report/`;
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.post(query, data)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }

}
