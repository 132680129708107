
<template>
    <!-- {
                          "display_name": "Ensalada",
                          "description": "ensalada 2",
                          "prices": [
                              {
                                  "currency": "EUR",
                                  "price": 5.09
                              }
                          ]
                      } -->
    <div class="cartDish">
        <div class="cartDishTags">
            <!--<DishTag  v-for="(tag, indexTag) in data.tags" :key="indexTag" :type="tag.id">{{ tag.name }}</DishTag>            -->
        </div>
        <div class="cartDishInfoImage">
            <div class="cartDishImage">
                <img :src=" data.image ? data.image : noPhoto" />
            </div>
            <div class="cartDishInfo">
                <div class="cartDishName d-flex w-full">
                    <div class="flex-1">{{ data.display_name }}</div>
                    <ButtonEditItem @click.native="editProduct"><span class="text-12">Editar producto</span></ButtonEditItem>
                </div>
                
                <div class="cartDishDescription">
                    {{ data.description }}
                </div>
                <div class="cartDishAttributes">
                    <!--<img v-for="(allergen, index) in data.typeAllergens" :key="index" :src="require('@/assets/img/local/menu/' + allergen)" width="24" />-->
                </div>
            </div>
        </div>
        <div class="cartDishExtraInfo">
            <div class="cartDishExtraInfoDiet">
                <!--<TypeHot :levelHot="data.levelHot"></TypeHot>-->
                <!--<TypeDiet :data="data.typeDiet"></TypeDiet>-->
            </div>
            
            <div class="cartDishExtraInfoPrices">
                <DishPrices :data="data.prices"></DishPrices>
            </div>
        </div>
        <ModalAddDish :data="dataCustom"         
        @productOk="$emit('productOk', $event)"
 :idSection="idSection" :visible="showAddEditProduct" :idBussines="$store.getters.getNode" @close="showAddEditProduct=false" @remove="remove=$event"  @edit="edit=$event" :isEdit="edit" :isRemove="remove"></ModalAddDish>

    </div>
</template>
<script>
/*import DishTag from '@/components/local/menu/DishTag.vue'
import TypeHot from '@/components/local/menu/TypeHot.vue'
import TypeDiet from '@/components/local/menu/TypeDiet.vue'*/
import DishPrices from '@/components/local/menu/DishPrices.vue'
import ModalAddDish from '@/components/local/menu/ModalAddDish.vue'
import ButtonEditItem from '@/components/local/menu/ButtonEditItem.vue'
import localMixins from "@/mixins/local.js"

export default {
    components: { DishPrices, ModalAddDish, ButtonEditItem },
    mixins: [ localMixins],
    props: {
        data: {
            default: {},
            required: true
        },
        idSection: { default: null}
    },
    data(){
        return {
            showAddEditProduct: false,
            remove: false,
            edit: true,
            noPhoto: require('@/assets/img/no_plato.png')
        }
    },
    computed:{
        dataCustom(){
            return {id: this.data.id, image: this.data.image, display_name: this.data.display_name, description: this.data.description, price: this.data.prices.length > 0 ? this.data.prices[0].price : null}
        }
    },
    methods: {
        editProduct(){
            if (this.hasPremium) {
                this.showAddEditProduct = true
            }
        }
    }
}
</script>

<style scoped>
.cartDish{
    border-radius: 8px;
    box-shadow: 0px 3px 6px rgba(0,0,0,.16);
    padding: 6px 12px;
    margin-bottom: 2%;
    width: calc(50% - 2%);

}
.cartDish:nth-child(even){
            
            margin-left: 2%;
            }
.cartDishTags{
    display: flex;
    gap: 4px;
}
.cartDishInfoImage{
    display: flex;
    margin-top: 5px;
}
.cartDishInfo{
    margin-left: 16px;
    width: 70%;
}
.cartDishName{
    font-size: 14px;
    color: #222226;
    font-weight: 600;
}
.cartDishDescription{
    color: #4F4853;
    font-size: 12px;
}
.cartDishAttributes{
    margin-top: 20px;
}
.cartDishImage{
    max-width: 29%;
}
.cartDishAttributes img{
    margin-right: 10px;
}
.cartDishExtraInfo{
display: flex;

}
.cartDishExtraInfoDiet{
width: 100%;
max-width: 128px;
justify-content: flex-end;
display: flex;
align-items: center;
}
.cartDishExtraInfoPrices{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
</style>