<template>
  <svg id="Grupo_626" data-name="Grupo 626" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12" height="12" viewBox="0 0 12 12">
    <defs>
      <clipPath id="clip-path">
        <circle id="Elipse_25" data-name="Elipse 25" cx="6" cy="6" r="6"/>
      </clipPath>
    </defs>
    <g id="Grupo_625" data-name="Grupo 625" clip-path="url(#clip-path)">
      <g id="https___upload.wikimedia.org_wikipedia_commons_3_32_Flag_of_Spain__28Civil_29.svg-0" data-name="https___upload.wikimedia.org_wikipedia_commons_3_32_Flag_of_Spain_%28Civil%29.svg-0" transform="translate(-2 0.156)">
        <rect id="Rectángulo_2501" data-name="Rectángulo 2501" width="17" height="12" transform="translate(0 -0.156)" fill="#c60b1e"/>
        <rect id="Rectángulo_2502" data-name="Rectángulo 2502" width="17" height="6" transform="translate(0 2.844)" fill="#ffc400"/>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: "flag-es"
}
</script>