<template lang="pug">
div.margin-bottom-0.col-12
        skeleton-card(v-if="!dataGps")
        v-card.gridFull.ma-0(v-if="dataGps" :class="{'borderDelivery': isDelivery}")
          .d-flex.headTitleGrid
            .block-title.titleGrid
              span.mb-0.title-card {{ isAdverbia ? 'Adverbia Promoter Score (APS)' : 'GastroRanking Promoter Score (GPS)' }}
              span.ml-1.tDelivery(v-if="isDelivery") Delivery
            .block-help.ml-auto
              v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="40" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                template(v-slot:activator="{ on, attrs }")
                  img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                span {{helpNOp}}
          .card-content
            #tableWebsDelivery
              .head
                .row
                  .col.colAgregador
                  .col.colNPedidos
                    span {{ isAdverbia ? 'Nº de Opiniones' : 'Nº de Pedidos' }}
                  .col.colNComentarios
                    span Nº de comentarios
                  .col.colPedidosErroneos
                    div.d-flex.justify-center.items-center.gap-2em
                      div.d-flex.w-full.items-center.justify-end
                        span Detractores
                      img(src="@/assets/img/detractors-promoters.svg")
                      div.d-flex.w-full.items-center.justify-start
                        span Promotores
                  .col.colPedidosCancelados
                    span {{ isAdverbia ? 'Adverbia Promoter Score (APS)' : 'GastroRanking Promoter Score (GPS)' }}

              .body(v-if="dataGps && dataGps[0] && dataGps[0].sites")
                .row(v-for="(item, index) in dataGps[0]['sites']" :key="index")
                  .col.colAgregador
                    img(:src="item.logo")
                    span.font-weight-medium {{ item.name }}
                  .col.colNPedidos
                    span {{ item.count || 0 }}
                  .col.colNComentarios
                    span {{ item.text_count || 0 }}
                  .col.colPedidosErroneos
                    div.d-grid.justify-center.items-center.gap-1em(style="grid-template-columns: 2rem 1fr 2rem")
                      div
                        span(v-if="!item['count_negative_percent'] || item['count_negative_percent'] === 0")
                        span(v-else) {{ item['count_negative_percent'] | roundedNumber }}%
                      div.flex-fill.bg-gray.h-1
                        div.d-grid(style="grid-template-columns: 1fr 1px 1fr")
                          div.w-full.h-1.d-flex.justify-end
                            div.h-1(style="background: #D61A23" :style="{ width: `${item['count_negative_percent']}%`}")
                          div.relative
                            div.bg-black.h-2.absolute(style="width: 1px; transform: translateY(-25%);")
                          div.w-full.h-1
                            div.h-1(style="background: #87C644" :style="{ width: `${item['count_positive_percent']}%`}")
                      div
                        span(v-if="!item['count_positive_percent'] || item['count_positive_percent'] === 0")
                        span(v-else) {{ item['count_positive_percent'] | roundedNumber }}%

                  .col.colPedidosCancelados
                    span(v-if="item.gps") {{ item.gps | formatNumber }}%
                    span(v-else) -

</template>

<script>
import commonMixins from "@/mixins/common.js"
import brandMixin from "@/mixins/brand.js"
import SkeletonCard from '@/components/common/skeletonCard.vue'
export default {
  mixins: [commonMixins, brandMixin],
  props: ['dataGps', 'isDelivery'],
  components: {SkeletonCard},
  data() {
    return {
        helpNOp: this.$gettext('Total number of opinions received from all the Websites during the selected period of the company and the different brands compared to the same period of the previous year (Tend). Use the website selector to see only the information on a specific website. You also have a filter of restaurant types for a more detailed analysis. It is analyzed about the group of restaurants that marks the selection in the left menu and the filters applied.'),
    }
}
}
</script>

<style lang="scss" scoped>
#tableWebsDelivery {
  margin: 0 -10px -10px;

  .row {
    margin: 0;
  }

  .colAgregador {
    width: 14%;
    flex: 0 0 14%;
  }

  .colNPedidos {
    width: 8%;
    flex: 0 0 8%;
  }

  .colNComentarios {
    width: 10%;
    flex: 0 0 10%;
  }

  .colPedidosErroneos {
    width: 50%;
    flex: 0 0 50%;
  }

  .colPedidosCancelados {
    width: 18%;
    flex: 0 0 18%;
  }

  .colTiempoEspera {
    width: 7%;
    flex: 0 0 7%;
  }

  .colConversion {
    width: 8%;
    flex: 0 0 8%;
  }

  .colOverall {
    width: 8%;
    flex: 0 0 8%;
  }

  .colNewUsers {
    width: 9%;
    flex: 0 0 9%;
  }

  .colPuntuacion {
    width: 18%;
    flex: 0 0 18;
  }

  .head {
    font-size: 0.75rem;
    border-bottom: 1px solid #E0E0E0;
    text-align: center;
  }

  .body {
    .row {
      border-bottom: 1px solid #E0E0E0;
      align-items: center;
      font-size: 0.875rem;
      padding: 5px 0;
      text-align: center;

      .colAgregador {
        display: flex;
        align-items: center;

        span {
          margin-left: 10%;
        }
      }
    }
  }
}</style>