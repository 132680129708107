<template lang="pug">
v-row.boxDay
    
    .text-left.pt-0.pb-0.pr-0
        .d-flex(:class="{'opacity-50': closed}")
            div.mr-9
                .rangeHours.mb-2(v-for="(time, index) in times")
                    input.time(type="time" v-model="time.starttime" @change="updateTimes") 
                    span  - 
                    input.time(type="time" v-model="time.endtime" @change="updateTimes")
                    img.ml-2(:src="imgtrash" width="13" v-if="times.length > 1" @click="removeHour(index)")                
            .d-flex.align-center.btnAddHours.text-right.pt-3.pb-5
                span(@click="addHour()") #[translate Add more hours]
                img.ml-2(:src="imgPlusHours" @click="addHour()")
        
</template>
<script>
export default {
    props:['data','closed'],
    data() {
        return {
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            imgtrash: require('@/assets/img/ico-trash.svg'),
            imgPlusHours: require('@/assets/img/ico-plus-hours.svg'),
            times: []
        }
    },
    created() {
        this.times = this.data
    },
    methods: {
        addHour() {
            this.times.push({startime: null, endtime: null})
            this.updateTimes()
        },
        removeHour(index) {
            this.times.splice(index, 1)
            this.updateTimes()
        },
        updateTimes() {
            this.$emit('updateTimes', this.times)
        }
    },
    watch: {
        data() {
            this.times = this.data
        }
    }

}
</script>
<style lang="scss" scoped>
        .optionsFullEditModal{
            background-color: #F2F2F2;
            border:0;
        }
        .time{
            border:1px solid #CECECE;
            padding:6px;
            color:#4A90E2;
        }
        .btnAddHours{
            align-self: flex-end;
            span{
                font-size: 0.875rem;
            }
        }
        .boxDay{
            &.border{border-bottom: 1px solid #707070;}
            
        }
    </style>