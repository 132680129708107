<template lang="pug">
    div
        v-dialog.dialogEdit(v-model="show"
            width="680")
            v-card.dialogEdit
                v-card-text.pb-0
                v-container
                    div.gr-loader-snipper--wrapper(v-if="loading")
                        span.gr-loader-snipper
                    v-row#headerModalEdit.pt-3
                        v-col(cols="10")
                            span(v-if="!post") #[translate Añadir un servicio]
                            span(v-else) #[translate Editar servicio]
                        v-col.mt-5(cols="2")
                            img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                    v-row#bodyModalEdit(v-if="!showEditedOkMultipleCenters")
                        v-col(cols="12")
                            v-container
                                v-row
                                    v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                                        div.messageError
                                            span {{ $gettext('An error occurred while recording.') }}
                                    
                                    v-col(v-if="idsBussinesModeMultiple && notPermited && notPermited.length > 0")
                                        p(@click="showCenters = !showCenters") Pulsa #[strong aquí] para ver centros seleccionados que no permiten crear servicios 
                                        ul(v-if="showCenters") 
                                            li(v-for="centro in notPermited") {{ centro.center }}

                                    v-col.text-left.pb-0(cols="6" v-if="!idsBussinesModeMultiple")
                                        label.d-block.mr-2 #[translate Category]
                                        select.selectFullEditModal(:id="'category'+index" v-model="category" class="flex-1" @change="getServicesByCategory")
                                            option(v-for="(boton, index) in categories" :value="boton.categoryId") {{ boton.name }}
                                    v-col(cols="12" v-if="showOptionLoadScructured")
                                        p.mb-0 Seleccione uno de los servicios estructurados o rellene los campos para crear un servicio personalizado.
                                    div.w-full(:class="{'opacity-50': !idsBussinesModeMultiple && !post && !category}")
                                        v-col.pl-8.text-left.pb-0(cols="12" v-if="showOptionLoadScructured")
                                            strong.d-block.mr-2 #[translate Crear servicio estructurado]                                    
                                        v-col.pl-8(v-if="servicesByCategory && showOptionLoadScructured" cols="12")
                                            button.scrtucturedType(v-for="service in servicesByCategory"
                                            :class="{'active': sctructuredService == service.service_type_id}" 
                                            @click="setSctructuredService(service)") + {{ service.display_name }}
                                        v-col.pl-8.text-left.pb-0(cols="12" v-if="showOptionLoadScructured")
                                            strong.d-block.mr-2 #[translate O crear servicio personalizado]
                                        v-col.pl-8.text-left.pb-0(cols="12" :class="{'opacity-50': sctructuredService}")
                                            label.d-block #[translate Servicio] (nombre)
                                            input.inputFullEditModal(:id="'display_name'+index" type="text" v-model="display_name" :readonly="sctructuredService" maxlength="120")
                                            p.text-right
                                               span {{ display_name ? display_name.length : 0 }} / 120
                                        v-col.pl-8(cols="12" :class="{'opacity-50': sctructuredService}")
                                            v-row
                                                v-col.text-left.pb-0(cols="6")
                                                    label.d-block.mr-2 #[translate Precio]
                                                    select.selectFullEditModal(:id="'priceType' + index" v-model="priceCode" class="flex-1" :disabled="sctructuredService")
                                                        option(v-for="(price, index) in pricesTypes" :value="price.name.code") {{ price.name.translation }}
                                                v-col.text-left.pb-0(cols="6")
                                                    label.d-block &nbsp;
                                                    input.inputFullEditModal(:id="'price' + index" type="text" :class="{'opacity-50':priceCodeNotPrice.includes(priceCode)}" :readonly="priceCodeNotPrice.includes(priceCode) || sctructuredService" v-model="price" placeholder="Precio del servicio")
                                        v-col.pl-8.text-left.pb-0(cols="12" :class="{'opacity-50': sctructuredService}")
                                            span #[translate Descripción]
                                            textarea.textareaFullEditModal(v-model="descriptionLimited" :readonly="sctructuredService" maxlength="200")
                                            p.text-right
                                               span {{ description ? description.length : 0 }} / 200
                    v-row(v-else)
                        v-col(cols="12" class="my-5")
                            p.text-center Servicio procesado correctamente.
                    v-row#footerModalEdit(v-if="!showEditedOkMultipleCenters")
                        v-col(cols="12")
                            button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
                            button.btnSaveEditModal(v-if="idsBussinesModeMultiple" @click="showConfirm=true")  #[translate Guardar]
                            button.btnSaveEditModal(v-else @click="validate()")  #[translate Guardar]
                            
                    v-row#footerModalEdit(v-else)
                        v-col(cols="12")
                            button.btnBlue(@click.stop="show=false") 
                                span #[translate Aceptar]
        ModalConfirmMultipleEdit(:visible="showConfirm"  @close="showConfirm=false" @confirmed="validate()")

</template>
<script>
import commonMixins from '@/mixins/common.js'
import SrvLocalServices from '@/services/localServices.js'
import ModalConfirmMultipleEdit from '@/components/local/confirmMultipleEdit.vue'

import DatePicker from 'vue2-datepicker';
import addPhotos from '@/components/local/posts/addPhotos.vue'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es'
export default {
    mixins: [commonMixins],
    props: ['visible','idBussines','post','idsBussinesModeMultiple','index','notPermited'],
    components: {DatePicker, addPhotos, ModalConfirmMultipleEdit},
    data(){
        return {
            loading: false,
            showConfirm: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            imgCalendar: require('@/assets/img/local/calendar.svg'),
            category: null,
            categories: null,
            servicesByCategory: null,
            pricesTypes: null,
            priceCode: null,
            display_name: '',
            sctructuredService: null,
            price: null,
            description: null,
            priceCodeNotPrice: ['NO_PRICE','FREE'],
            showCenters: false,
            saveStatus: true,
            errorEventName: false,
            errorStartDate: false,
            errorEndDate: false,
            showEditedOkMultipleCenters: false
        }
    },
    watch: {
        price () {
            if (document.getElementById('price' + this.index)) {
                document.getElementById('price' + this.index).style = ''
            }
        },
        display_name () {
            if (document.getElementById('display_name' + this.index)) {
                document.getElementById('display_name' + this.index).style = ''
            }
        },
        category () {
            if (!this.post && document.getElementById('category' + this.index)) {
                document.getElementById('category' + this.index).style = ''
            }
        },
        priceCode () {
            if (document.getElementById('priceType' + this.index)) {
                document.getElementById('priceType' + this.index).style = ''
            }
            if (document.getElementById('price' + this.index)) {
                document.getElementById('price' + this.index).style = ''
            }
        },
        visible (newValue){
            if (newValue) {
                this.showEditedOkMultipleCenters = false
                if (this.post) {
                    let postData = Object.assign({}, this.post)
                    this.priceCode = postData.service_price[0].price_type
                    this.display_name = postData.display_name
                    this.description = postData.description
                    this.category = postData.category
                } else {
                    this.categories = null
                    this.category = null
                    this.servicesByCategory = null
                    this.sctructuredService = null

                }
                this.loadData()
            }
        },      
    },
    computed: {
        descriptionLimited: {
            get () {
                return this.description
            },
            set (val) {
                this.description = val.substr(0,200)
            }
        },
        showOptionLoadScructured () {
            if (!this.post && !this.idsBussinesModeMultiple) {
                return true
            }
            return false
        },
        show: {
            get () {
                return this.visible
            },
            set (value) {
                if (!value) {
                this.$emit('close')
                }
            }
        },
    },
    methods: {
        setSctructuredService(service) {
            this.sctructuredService = this.sctructuredService == service.service_type_id ? null : service.service_type_id
            this.price = null
            this.display_name = null
            this.description = null
            this.priceCode = null
        },
        validate(){
            this.save()
        },
        async getPricesTypes () {
            const res = await SrvLocalServices.getPricesTypes()
            if (res.status === 200) {
                this.pricesTypes = res.data.filter(e => e.name.code !== 'FROM')
            }
        },
        async getServicesByCategory () {
            const res = await SrvLocalServices.getServicesByCategory(this.idBussines)
            if (res.status === 200) {
                this.servicesByCategory = res.data
            }
        },
        async loadData () {
            this.getPricesTypes()
            if (!this.idsBussinesModeMultiple) {
                const res = await SrvLocalServices.getCategories(this.idBussines)
                if (res.status === 200) {
                    this.categories = res.data
                }
                this.getServicesByCategory()
            }
            
        },
        async save () {
            if (!this.loading) {
                this.loading = true
                let dataStructured = {"services": [
                        {"structure_type":"structured_service",
                            "service_type": this.sctructuredService,
                        "service_price": [{
                            "price_type": "NO_PRICE"
                        }]}
                        ]}
                if (this.sctructuredService) {
                    try {
                        const res = await SrvLocalServices.setService(this.idBussines, dataStructured)
                        if (res.status === 200) {
                            // console.log(res)
                        }
                    } catch ( e ) {
                        // console.log(e)
                    }
                } else {
                    let dataCustom = {"services": [
                        {"structure_type":"free_form_service",
                        "category": this.category,
                        "display_name": this.display_name,
                        "service_price": [{
                            "price_type": this.priceCode,
                            "currency": 'EUR'
                        }],
                        "description": this.description
                        }
                        ]}
                    let isValid = true
                    if (!this.priceCodeNotPrice.includes(this.priceCode) && !this.price) {
                        isValid = false
                        document.getElementById('price' + this.index).style = 'border: 1px solid red'
                    }
                    if (!this.display_name) {
                        isValid = false
                        document.getElementById('display_name' + this.index).style = 'border: 1px solid red'
                    }
                    if (!this.idsBussinesModeMultiple && !this.post && !this.category) {
                        isValid = false
                        document.getElementById('category' + this.index).style = 'border: 1px solid red'
                    }
                    if (!this.priceCode) {
                        isValid = false
                        document.getElementById('priceType' + this.index).style = 'border: 1px solid red'
                    }
                    try {
                        if (isValid) {
                            let price
                                if (String(this.price).indexOf(',') === -1) {
                                    price = this.price
                                } else {
                                    price = Number(this.price.replaceAll('.','').replace(',','.')).toFixed(2)
                                }
                            if (this.post && this.post.id) {
                                dataCustom = {
                                    "id": this.post.id,
                                    "display_name": this.display_name,
                                    "service_price": [{
                                        "price_type": this.priceCode,
                                        "currency": 'EUR'
                                    }],
                                    "description": this.description
                                }
                                
                                if (!this.priceCodeNotPrice.includes(this.priceCode)) {
                                    
                                    dataCustom.service_price[0].price = price
                                }
                            } else {
                                if (!this.priceCodeNotPrice.includes(this.priceCode)) {
                                    dataCustom.services[0].service_price[0].price = price
                                }                               
                            }
                            if (!this.idsBussinesModeMultiple) {
                                const res = await SrvLocalServices.setService(this.idBussines, dataCustom)
                                if (res.status === 201) {
                                    this.$emit('saved',{data: res.data, 'action': 'update'})
                                    this.$emit('close')
                                }
                            } else {
                                let ids = this.idsBussinesModeMultiple

                                if (this.notPermited && this.notPermited.length > 0) {
                                    let idsNotPermited = this.notPermited.map(e => e.center_id)
                                    ids = ids.filter(e => !idsNotPermited.includes(e))
                                }
                                dataCustom = {
                                    'center_ids': ids,
                                    'services_details': [
                                        dataCustom.services[0]
                                    ],
                                    'update_type': 'SERV'
                                }
                                const res = await SrvLocalServices.setServiceMultiple(dataCustom) 
                                if (res.status === 200) {
                                    this.showEditedOkMultipleCenters = true
                                }
                                this.$emit('close')
                            }
                        }
                    } catch ( e ) {
                        // console.log(e)        
                    }   
                }
                
                this.loading = false
            }           
        }
    }
}
</script>
<style lang="scss" scoped>
    .scrtucturedType{
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #CECECE;
        &.active{
            background-color: #00ADFC;
            border: 0;
            color: #fff;
        }
    }
    .boxDeliveryService{
        background-color: #F2F2F2;
        border-radius: 4px;
        width: 100%;
        margin: 6px;
        &.noService{
            .urlDeliveryService{
                color: #CECECE;
            }
        }
        img{
            vertical-align: middle;
        }
    }
    .long{
        height: 136px;
    }
    .messageError{
        background-color: #F5B1B8;
        border: 1px solid #F93549;
        color: #F93549;
        padding:15px;
    }
    #bodyModalEdit{
        .inputFullEditModal{
            &.textDisabled{
                background-color: #f8f8f8;
                color:#CECECE;
            }
        }
    }
    .icoCalendar{
        height: 36px;
    }
</style>
