<template>
    <div class="progressbar w-full">
        <span class="meter meter-long" :style="'height:' + height + 'px'">
            <span v-if="note" :style="progressbarWidth(getNoteNormalized(note))" 
            :class="getColorByNoteNormalized({note: getNoteNormalized(note), type: 'classBG'})"></span>
        </span>
        <span v-if="showNote" class="ml-8 points font-weight-med" :class="getColorByNoteNormalized({note: getNoteNormalized(note), type: 'class'})">
            {{ getNoteNormalized(note) | formatNumber(1,1) }}
        </span>
    </div>
</template>
<script>
import mixinsBrand from '@/mixins/brand'
import mixinsCommon from '@/mixins/common'
export default {
    mixins: [ mixinsBrand, mixinsCommon],
    props: ['note','showNote', 'height'],
    data() {
        return {

        }
    },
    methods: {
        progressbarWidth: function(points){
            if (this.isAdverbia) {
                return 'width:' + (points * 20) + '%';
            } else {
                return 'width:' + (points * 10) + '%';
            }
        }
    }
}
</script>