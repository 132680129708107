<template lang="pug">
    div
        v-dialog.dialogEdit(v-model="show"
            width="680")
            v-card.dialogEdit
                v-card-text.pb-0
                v-container
                    div.gr-loader-snipper--wrapper(v-if="loading")
                        span.gr-loader-snipper
                    v-row#headerModalEdit.pt-3
                        v-col(cols="10")
                            span #[translate Configuration]
                        v-col.mt-5(cols="2")
                            img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                    v-row#bodyModalEdit.scrollCustom.pa-0(style="height:400px; overflow-y:scroll")
                        v-col(cols="12")
                            v-container
                                configuration-blocked(:idsBussines="idsBussinesModeMultiple" @close="$emit('close')" :show="visible" :clickedSaved="clickedSaved")

                    v-row#footerModalEdit
                        v-col(cols="12")
                            //button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
                            button.btnSaveEditModal(@click="showConfirm = true")  #[translate Guardar cambios]
        ModalConfirmMultipleEdit(:visible="showConfirm"  @close="showConfirm=false" @confirmed="clickedSaved = true")

</template>
<script>
import commonMixins from '@/mixins/common.js'
import ConfigurationBlocked from '@/components/local/ConfigurationBlocked.vue'
import ModalConfirmMultipleEdit from '@/components/local/confirmMultipleEdit.vue'

export default {
    mixins: [commonMixins],
    props: ['visible','idsBussinesModeMultiple'],
    components: {ConfigurationBlocked, ModalConfirmMultipleEdit},
    data(){
        return {
            loading: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            section: 'data-restaurant',
            saveStatus: true,
            messageError: null,
            clickedSaved: false,
            showConfirm: false
        }
    },
    watch: {
        visible (newValue){
            if (newValue) {
                this.clickedSaved = false
                ///this.data = {}
            }
        }
    },
    computed: {
        show: {
            get () {
                return this.visible
            },
            set (value) {
                if (!value) {
                this.$emit('close')
                }
            }
        },        
    },
    methods: {
    }
}
</script>
