<script>
import { Doughnut, mixins } from "vue-chartjs";
import ChartDataLabels from 'chartjs-plugin-datalabels';
const { reactiveProp } = mixins;

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  plugins: [ChartDataLabels],
  props: ["data", "options"],
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>