<template lang="pug">
  div
    v-dialog(v-model='open' scrollable='' max-width='800px' :persistent="true")
      v-card.default-anwsers
        v-toolbar(flat)
          v-toolbar-title #[translate Default Anwsers]
          v-spacer
          div.d-flex.justify-center.align-center.mr-4
            div.gr-loader-snipper--modal(v-if="loading")
              span.gr-loader-snipper
            v-btn.d-flex.justify-center.align-center(v-else v-for="(lang, index) in languages" :key="index" icon @mousedown='setLang(lang)')
              component.flag(:is="`flag-${lang}`" :class="{ active: lang === currentLang }")

          v-btn(icon @click="handleClose")
            v-icon mdi-close-circle-outline
        v-divider
        v-card-text.pb-2.overflow-hidden
          div.d-flex.align-center.pa-2.column-gap-0_5em.row-gap-0_5em.flex-wrap(style="min-height=40px")
            span.category(v-for="(category, index) in categories" :class="{ active: category.id === currentCategory, parentActive: catFirst === category.id }" @mousedown="setCategory(category)")
              | {{ $gettext(category.name) }}
          div.d-flex.align-center.pa-2.column-gap-0_5em.row-gap-0_5em.flex-wrap(v-if="subCategories.length > 0")
            span.category(v-for="(category, index) in subCategories" :class="{ active: category.id === currentCategory }" @mousedown="setCategory(category, true)")
              | {{ $gettext(category.name) }}
        v-divider
        div.d-flex.align-center.pt-2.px-2.column-gap-0_5em.mx-6
          span.type(v-for="(type) in anwserType" :class="{ active: type.id === currentType }" @mousedown="setType(type.id)")
            | {{ $gettext(type.name) }}
        v-divider
        v-card-text(style='height: 300px;')
          div.gr-loader-snipper--modal(v-if="renderEmptyData")
            span #[No hay data disponible]
          div.gr-loader-snipper--modal(v-if="loading")
            span.gr-loader-snipper
          div(v-else)
            div.d-flex.flex-column.row-gap-1em.py-4
              div(v-for="(anwser, index) in anwsers")
                div.px-4.py-4.anwser.rounded.cursor-pointer(:class="{ active: index === currentAnwser }" @mousedown="setAnwser(index, anwser)")
                  | {{ anwser }}
        v-divider
        v-card-actions.justify-center
          v-btn.py-2.my-1(color='blue darken-1' outlined tile min-width="100" max-height="30" text='' @click='$emit("update:open", false)')
            | #[translate Cerrar]
          v-btn.py-2.my-1(color='primary' tile min-width="100" max-height="30" :disabled="!selectedAnwser" @click='$emit("onSelect", selectedAnwser); $emit("update:open", false)')
            | #[translate Seleccionar]

</template>

<script>
import FlagEs from "@/components/common/flag-es";
import FlagEn from "@/components/common/flag-en";
import FlagFr from "@/components/common/flag-fr";
import FlagCa from "@/components/common/flag-ca";
import FlagIt from "@/components/common/flag-it";
import FlagPt from "@/components/common/flag-pt";
import SrvFeedback from "@/services/feedback";

export default {
  name: "Awners",
  components: {FlagEs, FlagEn, FlagFr, FlagCa, FlagIt, FlagPt},
  props: {
    open: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
    centerId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      loading: false,
      processing: false,
      currentCategory: undefined,
      currentCategoryFirstLevel: undefined,
      categoryType: null,
      currentType: undefined,
      renderEmptyData: false,
      currentLang: undefined,
      currentAnwser: undefined,
      selectedAnwser: undefined,
      anwserType: [{id: 1, name: 'Positive'}, {id: 2, name: 'Negative'}],
      allAnwserType: [],
      categories: [],
      subCategories: [],
      languages: ["es"],
      // Mocking data
      data: {
        0: {
          0: {
            0: ['Respuesta Positiva Cocina Determinada 1', 'Respuesta Positiva Cocina Determinada 2', 'Respuesta Positiva Cocina Determinada 3', 'Respuesta Positiva Cocina Determinada 4', 'Respuesta Positiva Cocina Determinada 5', 'Respuesta Positiva Cocina Determinada 6', 'Respuesta Positiva Cocina Determinada 7', 'Respuesta Positiva Cocina Determinada 8'],
            1: ['Respuesta Negativa Cocina Determinada 1', 'Respuesta Negativa Cocina Determinada 2', 'Respuesta Negativa Cocina Determinada 3'],
          },
          1: {
            0: ['Respuesta Positiva Salud e Higiene Determinada 1', 'Respuesta Positiva Salud e Higiene Determinada 2', 'Respuesta Positiva Salud e Higiene Determinada 3'],
            1: ['Respuesta Negativa Salud e Higiene Determinada 1', 'Respuesta Negativa Salud e Higiene Determinada 2', 'Respuesta Negativa Salud e Higiene Determinada 3'],
          },
          2: {
            0: ['Respuesta Positiva Servicio Determinada 1', 'Respuesta Positiva Servicio Determinada 2', 'Respuesta Positiva Servicio Determinada 3'],
            1: ['Respuesta Negativa Servicio Determinada 1', 'Respuesta Negativa Servicio Determinada 2', 'Respuesta Negativa Servicio Determinada 3'],
          },
          3: {
            0: ['Respuesta Positiva Precio Determinada 1', 'Respuesta Positiva Precio Determinada 2', 'Respuesta Positiva Precio Determinada 3'],
            1: ['Respuesta Negativa Precio Determinada 1', 'Respuesta Negativa Precio Determinada 2', 'Respuesta Negativa Precio Determinada 3'],
          },
          4: {
            0: ['Respuesta Positiva Ambiente Determinada 1', 'Respuesta Positiva Ambiente Determinada 2', 'Respuesta Positiva Ambiente Determinada 3'],
            1: ['Respuesta Negativa Ambiente Determinada 1', 'Respuesta Negativa Ambiente Determinada 2', 'Respuesta Negativa Ambiente Determinada 3'],
          }
        },
        1: {
          0: {
            0: ['Default Positive Kitchen Anwser 1', 'Default Positive Kitchen Anwser 2', 'Default Positive Kitchen Anwser 3'],
            1: ['Default Negative Kitchen Anwser 1', 'Default Negative Kitchen Anwser 2', 'Default Negative Kitchen Anwser 3']
          },
          1: {
            0: ['Default Positive Health and hygiene Anwser 1', 'Default Positive Health and hygiene Anwser 2', 'Default Positive Health and hygiene Anwser 3'],
            1: ['Default Negative Health and hygiene Anwser 1', 'Default Negative Health and hygiene Anwser 2', 'Default Negative Health and hygiene Anwser 3'],
          },
          2: {
            0: ['Default Positive Service Anwser 1', 'Default Positive Service Anwser 2', 'Default Positive Service Anwser 3'],
            1: ['Default Negative Service Anwser 1', 'Default Negative Service Anwser 2', 'Default Negative Service Anwser 3'],
          },
          3: {
            0: ['Default Positive Price Anwser 1', 'Default Positive Price Anwser 2', 'Default Positive Price Anwser 3'],
            1: ['Default Negative Price Anwser 1', 'Default Negative Price Anwser 2', 'Default Negative Price Anwser 3'],
          },
          4: {
            0: ['Default Positive Environment Anwser 1', 'Default Positive Environment Anwser 2', 'Default Positive Environment Anwser 3'],
            1: ['Default Negative Environment Anwser 1', 'Default Negative Environment Anwser 2', 'Default Negative Environment Anwser 3'],
          }
        },
      },
      anwsers: [],
      answersByCountry: [],
      defaultType: undefined
    }
  },
  watch: {
    open: {
      immediate: true,
      async handler(value) {
        await this.$nextTick(async () => {
          value && await this.load()
        })
      },
    },
    async currentLang(lang) {
      if (this.currentCategory !== undefined && this.currentType !== undefined) {
        await this.getAnswers(lang, this.currentCategory, this.currentType)
      }
    },
    async currentCategory(category) {
      if (this.currentLang !== undefined && this.currentType !== undefined) {
        await this.getAnswers(this.currentLang, category, this.currentType)
      }
    },
    async currentType(type) {
      if (this.currentLang !== undefined && this.currentCategory !== undefined) {
        await this.getAnswers(this.currentLang, this.currentCategory, type)
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit("update:open", false)
      this.languages = ["es"]
    },
    async load() {
      this.renderEmptyData = false
      this.loading = true
      this.currentCategory = undefined;
      this.currentType = undefined;
      this.currentLang = undefined;
      await this.getAnswersCategoriesByLanguage('es')
      await this.getConfig()
      this.processing = false
      this.currentAnwser = undefined;
      this.selectedAnwser = undefined;
      this.loading = false
    },
    setCategory(category, isSub = false) {
      if (!isSub) {
        this.currentCategoryFirstLevel = category.id
      }
      this.currentCategory = category.id;
      if (!category.review_types.includes(this.currentType)) {
        this.currentType = category.review_types[0]
      }
      this.anwserType = this.allAnwserType.filter(t => category.review_types.includes(t.id))
      if (!isSub) {
        this.subCategories = []
      }
      if (category.children && category.children.length > 0) {
        this.subCategories = category.children
      }
      this.currentAnwser = undefined;
      this.selectedAnwser = undefined;
    },
    setType(type) {
      this.currentType = type;
      this.currentAnwser = undefined;
      this.selectedAnwser = undefined;
    },
    setLang(index) {
      this.currentLang = index;
      this.currentAnwser = undefined;
      this.selectedAnwser = undefined;
    },
    setAnwser(index, awnser) {
      this.currentAnwser = index;
      this.selectedAnwser = awnser;
    },
    getConfig: async function () {
      const {status, data} = await SrvFeedback.getAnswersParams()
      if (status === 200) {
        const _lang = Object.values(data.language).map(item => item.toLowerCase())
        try {
          const responses = await Promise.all(
            _lang.map((lang) =>
              SrvFeedback.getAnswers(lang, this.currentCategory, this.currentType, this.categoryType)
            )
          )
          responses.forEach(({data}, index) => {
            if (data.results.length) this.languages = [...new Set([...this.languages, _lang[index]])]
          })
          this.currentLang = this.languages[0]

          this.allAnwserType = Object.keys(data.type_value).map(k => {
            return {id: data.type_value[k], 'name': k}
          })
        } catch (error) {
          console.error(`[ERROR_getConfig]: ${error}`)
        }
      }
    },
    async getAnswersCategoriesByLanguage(language) {
      try {
        console.log({centerID: this.centerId})
        const {status, data} = await SrvFeedback.getAnswersCategoriesByLanguage(language, this.centerId)
        if (status === 200) {
          if (data.results.length > 0) {
            this.categoryType = data.results[0]?.type
          } else {
            return this.renderEmptyData = true
          }
          const _categories = data.results.map(item => (
            {id: item.id, name: item.name, review_types: item.review_types, children: item.children || []}
          ))
          this.categories = _categories.filter((cat) => !["Con Comentario", "Sin Comentario"].includes(cat.name))
          this.currentCategory = this.categories[0]?.id

          if (this.categories[0]?.children && this.categories[0]?.children.length > 0) {
            this.subCategories = this.categories[0]?.children
          }
          this.currentType = this.categories[0]?.review_types[0]
          this.anwserType = this.allAnwserType.filter(t => this.categories[0]?.review_types.includes(t.id))
        }
      } catch (error) {
        // console.error(`[ERROR_getAnswersCategoriesByLanguage]: ${error}`)
      }
    },
    async getAnswers(language, category, type) {
      this.loading = true
      const {status, data} = await SrvFeedback.getAnswers(language, category, type, this.categoryType)
      if (status === 200) {
        this.anwsers = data.results.map(item => item.text)
      }
      this.loading = false
    },
  }
  ,
  computed: {
    catFirst() {
      return this.currentCategoryFirstLevel
    }
  }
}
</script>

<style lang="scss">
.default-anwsers {
  .v-label {
    font-size: 0.8em;
  }

  .flag {
    width: 2em;
    padding: 0.25em;
    border: solid #f3f6f6 1px;
    border-radius: 50%;

    &:hover {
      border: solid #6fadd4 1px;
    }

    &.active {
      border: solid #00ADFC 1px;
    }
  }

  .category {
    border-radius: 15px;
    padding: 2px 10px;
    font-size: 0.75rem;
    background-color: #CECECE;
    color: white;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #b3b1b1;
    }

    &.parentActive {
      background-color: #666666;
    }

    &.active {
      background-color: #222226;
    }
  }

  .type {
    padding: 5px 15px;
    font-size: 0.85rem;
    background-color: transparent;
    color: #707070;
    cursor: pointer;
    user-select: none;

    &:hover {
      background-color: #f8f6f6;
    }

    &.active {
      box-shadow: inset 0 -3px 0 0px #f93549;
      background-color: #faf7f7;
    }
  }

  .anwser {
    background-color: #ECECEC;
    border: solid 2px #ECECEC;
    user-select: none;

    &:hover {
      background-color: #dcdcdc;
    }

    &.active {
      border: solid 2px #4A90E2;
    }
  }
}
</style>
