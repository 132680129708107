import { render, staticRenderFns } from "./ValorationPositioningLast12Weeks.vue?vue&type=template&id=28e9a94c&scoped=true&lang=pug"
import script from "./ValorationPositioningLast12Weeks.vue?vue&type=script&lang=js"
export * from "./ValorationPositioningLast12Weeks.vue?vue&type=script&lang=js"
import style0 from "./ValorationPositioningLast12Weeks.vue?vue&type=style&index=0&id=28e9a94c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28e9a94c",
  null
  
)

export default component.exports