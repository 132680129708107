<script>
import Chart from 'chart.js'
import { generateChart, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

// CUSTOMIZAMOS LA GRAFICA DE LINEA PARA AÑADIR BARRAS DE COLORES A LOS LATERALES
Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.defaults.global.legend.labels.usePointStyle = true;

const CustomLine = generateChart('custom-line', 'line')
export default {
  name: "valid_name",
  extends: CustomLine,
  mixins: [reactiveProp],
  props:['chartData', 'legend'],
  data: () => ({    
    chartDataCustom: null,
    options: {
      tooltips: {
          callbacks: {
              labelColor: function(tooltipItem,chart) {
                  return {
                      borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                      backgroundColor: chart.config.data.datasets[tooltipItem.datasetIndex].borderColor,
                  }
              },
              label: function(tooltipItems, data) {
                var label = data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.yLabel.toFixed(2)
                return label
              }
          }
      },
      legend: {
          display: true,
          position: 'right',
          align: 'start',          
      },
      plugins: {
        // Change options for ALL labels of THIS CHART
        datalabels: {
            display: false
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
         yAxes: [{
            id: 'y-axis-0',
            position: 'left',
            ticks: {
               stepSize: 1
            },
            gridLines: {
              tickMarkLength: 50  
            },
         }],
         xAxes: [
            {
              type: "time",
              time: {
                  unit: 'week',
                  parser:'YYYY-MM-DD',
                  displayFormats: { day: 'YYYY-MM-DD' },
                  stepSize: 1
              }
            },
          ],
      },
     
    }
  }),
  created () {
    this.chartDataCustom = this.chartData
  },
  watch: {
    chartDataCustom () {
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>