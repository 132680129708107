<template lang="pug">
div
    v-row.px-2.ma-0
        // LOGO MARCA     
        header-center(:isLoading="isLoading" :dataProfile="dataProfile")
        notifications(:center="center" :isLoading="isLoading" :hasShadow="true")
        
            
</template>
<script>
  import SkeletonCard from '@/components/common/skeletonCard.vue'
  import SrvLocal from '@/services/local.js'
  import commonMixins from "@/mixins/common.js"
  import localMixins from "@/mixins/local.js"
  import Notifications from '@/components/local/Notifications.vue'
  //FICHAS EDICION

  import HeaderCenter from "@/components/local/HeadCenter.vue"
  
  export default {
    components: {
        SkeletonCard,
        HeaderCenter,
        Notifications
    }, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
    mixins: [commonMixins, localMixins],
    data() {
      return {
        dataProfile: [],
        messageError: null,
        loadingSync: false,
        center: undefined,
      }
    },
    async created() {
      this.$store.commit('LOADING', true)
      this.loading = true
      this.center = Number(this.$store.getters.getNode)
      await this.getLocalMarketing()      
      
      this.loading = false
      this.$store.commit('LOADING', false)
    },    
      
    watch: {
      filterApply: function () {
        this.getLocalMarketing()
      }
    },
    methods: {      
      sync(idBussines) {
        if (!this.loadingSync) {
          this.loadingSync = true
          if (idBussines !== '' && typeof idBussines === 'number') {
            SrvLocal.syncBunsiness(idBussines)
              .then(res => {
                if (res.status === 200) {
                  if (Object.keys(res.data).includes('success') && !res.data.success) {
                    this.messageError = res.data.error.message
                    this.showError = true
                  }
                  this.loadingSync = false
                }
              }, () => {
                this.loadingSync = false
              })
          }
        }
      },
      
      getLocalMarketing() {
        this.$store.commit('LOADING', true)
        SrvLocal.getLocalMarketing()
          .then(res => {
            if (res.status === 200) {
              
              this.dataProfile = res.data
              this.facebookConfig = res.data.facebook_config
              this.$store.commit('LOADING', false)
            }
          }, () => {
          })
      }      
      
    }
  }
  </script>
