<template lang="pug">
    div
        v-dialog.dialogEdit(v-model="show"
            width="680")
            v-card.dialogEdit
                v-card-text.pb-0
                v-container
                    div.gr-loader-snipper--wrapper(v-if="loading")
                        span.gr-loader-snipper
                    v-row#headerModalEdit.pt-3
                        v-col(cols="10")
                            span #[translate Location]
                        v-col.mt-5(cols="2")
                            img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                    v-row#bodyModalEdit
                        v-col(cols="12")
                            v-container
                                v-row
                                    v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                                        div.messageError
                                            span {{ $gettext('An error occurred while recording.') }}                                                                       
                                    v-col.text-left.pb-0(cols="12")
                                        span #[translate Street]
                                        input.inputFullEditModal(type="text" v-model="data.street_address")                                    
                                    v-col.text-left.pb-0(cols="6")
                                        span #[translate Postal code]
                                        input.inputFullEditModal(type="text" v-model="data.postal_code")
                                    v-col.text-left.pb-0(cols="6")
                                        span #[translate City]
                                        input.inputFullEditModal(type="text" v-model="data.city_name")                                   
                                    v-col.text-left.pb-10(cols="12")
                                        img(:src="noMap")

                                        
                    v-row#footerModalEdit
                        v-col(cols="12")
                            button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
</template>
<script>
import commonMixins from '@/mixins/common.js'
import SrvLocal from '@/services/local.js'

export default {
    mixins: [commonMixins],
    props: ['visible','idBussines'],
    data(){
        return {
            loading: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            noMap: require('@/assets/img/no-map.svg'),
            name: '',
            data: {},
            section: 'location-restaurant',
            saveStatus: true
        }
    },
    watch: {
        visible (newValue){
            if (newValue) {
                this.data = {}
                this.loadData()
            }
        }
    },
    computed: {
        show: {
            get () {
                return this.visible
            },
            set (value) {
                if (!value) {
                this.$emit('close')
                }
            }
        }
    },
    methods: {
        async loadData () {
            this.loading = true            
            await SrvLocal.getLocalMarketing(this.section)
            .then(res => {
                if (res.status === 200) {
                    this.data = res.data
                    this.loading = false
                } 
            }, () => {
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .messageError{
        background-color: #F5B1B8;
        border: 1px solid #F93549;
        color: #F93549;
        padding:15px;
    }
</style>