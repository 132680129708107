<template>
    <div>
        <span id="cartListSectionsLegend">SECCIONES:</span>
        <ButtonAddItem class="mt-2 w-full" @click.native="addSection()"><span class="text-12 ">Añadir sección</span></ButtonAddItem>
        <div id="cartListSections">
            <button @click="goToSection(item.id)" v-for="(item, index) in items" :key="index">{{ item.name }}</button>
        </div>
        <ModalAddSection :visible="showAddEditSection" :idBussines="center" @sectionDeleteOk="$emit('sectionDeleteOk',$event)" @sectionCreatedOk="$emit('sectionCreatedOk',$event)" @close="showAddEditSection=false" @remove="remove=$event"  @edit="edit=$event" :isEdit="edit" :isRemove="remove"></ModalAddSection>
    </div>
    
</template>

<script>
import ButtonAddItem from '@/components/local/menu/ButtonAddItem.vue'
import ModalAddSection from '@/components/local/menu/ModalAddSection.vue'
import localMixins from "@/mixins/local.js"

    export default {
        components: {ButtonAddItem, ModalAddSection},
        mixins: [localMixins],
        props: {
            items: {
                default: [],
                required: true
            }
        },
        data(){
            return {
                showAddEditSection: false,
                remove: false,
                edit: false,
                center: null
            }
        },
        created(){
            this.center = Number(this.$store.getters.getNode)
        },
        methods: {
            addSection(){
                if (this.hasPremium) {
                    this.showAddEditSection = true
                }
            },
            goToSection(id){
                if (this.hasPremium) {
                    const el = document.querySelector('#section' + id)
                    el.scrollIntoView()
                }
            }
        }
    }
</script>
<style scoped>
#cartListSections{
    display: flex;
        flex-direction: column;
        margin-top: 16px;
}
#cartListSections button{
    font-size: 14px;
    text-align: left;
    background-color: #EEF5F9;
    border: 1px solid #4A90E2;
    padding: 16px;
    border-radius: 6px;
    margin-bottom: 16px;
    color: #161719;
    text-decoration: none;
    margin-right: 6px;
}
#cartListSectionsLegend{
        display: block;
        font-size: 12px;
    }
</style>