<template lang="pug">
  router-view
</template>

<script>

export default {
    data(){
        return {
        }
    },
    // OBTENERMOS DEL STORE LOS FILTROS APLICADOS 
    created(){

    },
    mounted() {
    },
}
</script>

<style lang="scss" scoped>

</style>