<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: "DeliveryEvolutionMainNumberOpinion",
  extends: Line,
  mixins: [reactiveProp],
  props:['chartData'],
  data: () => ({
    type: 'line',
    options: {
      responsive: true,
      maintainAspectRatio: false,

        legend: {
            display: true,
            align: 'start',
            position: 'right',
            labels:{
                boxWidth: '30',
                usePointStyle: true
            }
        },
        legendCallback: function() {
          return 'aa'
        },
        plugins: {
                datalabels: {
                    display: true
                }
              },
        layout: {
            padding: {
                left: 20,
                right: 20,
                top: 25,
                bottom: 10
            }
        },
        showTooltips: false,
        scales: {
            yAxes: [
                {
                    type: "linear",
                    position: "left",

                    ticks: {
                        steps: 11,
                        min: 0,
                        max: 10,
                    },
                    id: "y-axis-1",
                },
                {
                    type: "linear",
                    position: "right",
                    id: "y-axis-2",
                    ticks: {
                        fontColor: "#4a90e2",
                    },
                    datalabels: {
                        display: false
                    },
                    gridLines: {
                        display: false,
                    }

                },
            ]
        },
      }
  }),
  watch: {
    chartData () {
      this.options.scales.yAxes[0].ticks.max = this.chartData.max
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
