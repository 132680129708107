<template lang="pug">
div
  v-col(md="12").tablaGR.tableContainer
    v-data-table(
      :headers="headers"
      :items="opinions"
      :page.sync="pageCustom"
      :items-per-page="itemsPerPageCustom"
      hide-default-footer
      item-key="idGenerate"
      :loading="loading"
      :loading-text="loadingtext"
      @page-count="pageCount = $event"
    )
      template(v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }")
        span.d-flex(v-if="header.value === 'reservationId'")
          span {{ header.text }}
          .boxOrder
            <sort orderBy="reservation_id" :orderByActual="ordering" @sort="setOrder($event)" />
        span.d-flex(v-else-if="header.value === 'business'")
          span {{ header.text }}
        span.d-flex(v-else-if="header.value === 'dates'")
          span {{ header.text }}
          .boxOrder
            <sort orderBy="source_date" :orderByActual="ordering" @sort="setOrder($event)" />
        span.d-flex(v-else-if="header.value === 'rating'")
          span {{ header.text }}
          .boxOrder
            <sort orderBy="review__rating_global" :orderByActual="ordering" @sort="setOrder($event)" />
        span(v-else) {{ header.text }}

      template(v-slot:item.reservationId="{ item }")
        div.d-flex.align-start.item-container.flex-column
          img.booking.align-self-center.pb-1(v-if="item.review.site_logo" :src="item.review.site_logo")
          span {{item.reservation_id}}

      template(v-slot:item.business="{ item }")
        div.d-flex.align-start.item-container
          span.link-text(v-if="typeNode !== 'center'" @click="goItemNextLevelCustom(item)") {{item.review.center.name}}
          span(v-else) {{item.review.center.name}}

      template(v-slot:item.client="{ item }")
        div.d-flex.flex-column.align-start.item-container
          span.pb-2.font-weight-black {{ item.review.user_name }}
          span
            span.fi(:class="`fi-${item?.user_country_code?.toLowerCase()}`")
            span.pl-2 {{ countries[item.user_country_code] }}
          span.pt-1
            img(:src="img[item?.reservation_info.customer_type?.toLowerCase()]")
            span.pl-2 {{ clientTypeLabels[item?.reservation_info.customer_type?.toLowerCase()] }}
          span.d-flex.align-center
            img.icon(:src="img.calendar")
            span.pl-2(v-if="item.reservation_info.num_nights === 1") {{ item.reservation_info.num_nights }} #[translate Noche]
            span.pl-2(v-else) {{ item.reservation_info.num_nights }} #[translate Noches]

      template(v-slot:item.dates="{ item }")
        div.d-flex.align-start.item-container.flex-column
          div.d-flex.align-center
            img.icon(:src="img.calendar")
            span.pl-1.text-date {{ item.source_date ?? '-' }}
          div.d-flex.align-center.pt-1
            img(:src="img.in")
            span.pl-1.text-date {{ item.reservation_info.check_in_date ?? '-' }}
          div.d-flex.align-center.pt-1
            img(:src="img.out")
            span.pl-1.text-date {{ item.reservation_info.check_out_date ?? '-' }}
          div.d-flex.align-center.pt-1.pl-1
            img(:src="img.review")
            span.pl-1.text-date {{ item.review.source_date ?? '-' }}

      template(v-slot:item.habNumber="{ item }")
        div.d-flex.item-container.flex-column
          span.pl-1.text-center(v-for="(room,key) in item.room_ids" :key="key+'_room'") {{ room ?? '-' }}

      template(v-slot:item.personNumber="{ item }")
        div.d-flex.align-start.item-container.flex-column
          span.d-flex(v-if="item.n_adult") {{ item.n_adult }} 
            .pl-1 #[translate Adultos]
          span.d-flex(v-if="item.n_junior") {{ item.n_junior }} 
            .pl-1 #[translate Adolescentes]
          span.d-flex(v-if="item.n_child") {{ item.n_child }} 
            .pl-1 #[translate Niños]
          span.d-flex(v-if="item.n_infant") {{ item.n_infant }} 
            .pl-1 #[translate Infantes]

      template(v-slot:item.habType="{ item }")
        div.d-flex.align-start.item-container
          img.icon.mt-1(:src="img.bed")
          span.d-flex.flex-column
            span.pl-2(v-for="(hab,index) in item.room_names" :key="index+'_hab'") {{ hab ?? '-' }}

      template(v-slot:item.rating="{ item }")
        div.d-flex.align-start.item-container.justify-center
          span.text-center {{ item.review.rating_global ? `${item.review.rating_global}/10` : '-' }}

      template(v-slot:item.comments="{ item }")
        div.d-flex.align-start.flex-column.my-2.item-container
          p.d-flex.flex-row-reverse(v-if="item?.review?.text?.includes('Positivo:')") {{ getPositiveReview(item.review.text) }}
            img.reaction-img.icon-reaction(:src="img.positive") 
          p.mb-0.d-flex.flex-row-reverse(v-if="item?.review.text?.includes(`\nNegativo:`)") {{ getNegativeReview(item.review.text) }}
            img.reaction-img.icon-reaction(:src="img.negative") 
          p(v-if="!item?.review?.text?.includes(`\nNegativo:`)&&!item?.review?.text?.includes('Positivo:')") {{ item.review.text}}
</template>

<script>
import countryCodes from "country-codes-list"
import commonMixins from "@/mixins/common.js";
import Sort from "@/components/common/Sort.vue"

export default {
  mixins: [commonMixins],
  components: { Sort },
  props: {
    opinions: {
      type: Array,
      default: null,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
      required: true,
    },
    pagination: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      page: 1, // PAGINA EN LA QUE ESTAMOS
      pageCount: 0, // PAGINAS TOTALES
      itemsPerPage: 10, // ELEMENTOS POR PAGINA
      img: {
        calendar: require("@/assets/img/calendar.svg"),
        in: require("@/assets/img/ico_entrar.png"),
        out: require("@/assets/img/ico_salir.png"),
        positive: require("@/assets/img/positive_review.png"),
        negative: require("@/assets/img/negative_review.png"),
        bed: require("@/assets/img/ico_cama.png"),
        group_of_friends: require("@/assets/img/ico_grupo.png"),
        couples: require("@/assets/img/ico_pareja.png"),
        solo_travellers: require("@/assets/img/ico_solo.png"),
        families: require("@/assets/img/ico_familia.png"),
        review: require("@/assets/img/ico_resena.png"),
      },
      clientTypeLabels: {
        group_of_friends: "Viaje en grupo",
        couples: "Viaje en pareja",
        solo_travellers: "Viaje solitario",
        families: "Viaje en familia",
      },
      ordering: "-source_date",
      options: {}, // OPCIONES DE V-TABLE
      loadingtext: this.$gettext("Loading comments"),
      totalWidth: 0,
      cols: {
        comments: 3,
        business: 2,
        client: 2,
        date: 2,
      }
    };
  },
  watch: {
    opinions() {
      if (this.pagination) {
        this.pageCount = Math.ceil(this.opinions.length / this.itemsPerPage);
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "reservationId",
        },
        {
          text: this.$gettext("NEGOCIO"),
          align: "start",
          sortable: false,
          value: "business",
        },
        { text: this.$gettext("CLIENTE"), value: "client", sortable: false, width: "10%" },
        { text: this.$gettext("FECHAS"), value: "dates", sortable: false },
        { text: this.$gettext("TIPO DE HAB"), value: "habType", sortable: false },
        { text: `Nº ${this.$gettext("HABITACIÓN")}`, align: "center", value: "habNumber", sortable: false },
        { text: `Nº ${this.$gettext("PERSONAS")}`, align: "start", value: "personNumber", sortable: false },
        { text: this.$gettext("VALORACION"), value: "rating", sortable: false },
        { text: this.$gettext("COMENTARIO"), value: "comments", sortable: false, width: "30%" },
      ];
    },
    hasEditTags() {
      return true;
    },
    itemsPerPageCustom() {
      if (this.pagination) {
        return this.itemsPerPage;
      }
      return this.opinions.length;
    },
    pageCustom: {
      get() {
        if (this.pagination) {
          return this.page;
        }
        return 1;
      },
      set(val) {
        this.page = val;
      },
    },
    countries() {
      return countryCodes.customList('countryCode', '{countryNameLocal}')
    },
  },
  methods: {
    goItemNextLevelCustom(item) {
      let itemCustom = {};
      itemCustom.id = item.center;
      itemCustom.name = item.review.center.name;
      itemCustom.level = 9;
      return this.goItemNextLevel(itemCustom, true);
    },
    getNegativeReview(originalReview) {
      try {
        const pattern = new RegExp(`\nNegativo:(.*)`);
        return originalReview.match(pattern)[1]?.trim() ?? '';
      } catch (error) {
        return "-"
      }
    },
    getPositiveReview(originalReview) {
      try {
        const pattern = new RegExp(`Positivo:(.*?)\nNegativo:`);
        return originalReview.match(pattern)[1]?.trim() ?? '';
      } catch (error) {
        return "-"
      }
    },
    setOrder(val) {
      this.ordering = val;
      this.page = 1;
      this.$emit("setOrder", this.ordering);
    },
  },
};
</script>

<style lang="scss" scoped>
.tableContainer {
  padding: 0px;

  p,
  span {
    font-size: 0.75rem;
  }

  .booking {
    width: 20px;
  }

  .item-container {
    min-height: 100%;
    padding: 10px 0px;

    .icon {
      width: 15px;
      height: 15px;
    }

    .reaction-img {
      height: 15px;
      width: 15px;
      margin-right: 5px;
      margin-top: 3px;
    }
  }

  .boxOrder {
    float: right;
    padding-left: 4px;
  }
}
</style>
