// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
//
//
// business_category/
export default {
  getMenusUpdateFromGoogle(id) {
    //https://tastia.gastroranking.pro/backend/api/v1/site_localmarketing/?center=234&tree=1
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/center/${id}/menus/update-from-google/`
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getMenusUpdateToGoogle(id) {
    //https://tastia.gastroranking.pro/backend/api/v1/site_localmarketing/?center=234&tree=1
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/center/${id}/menus/patch-to-google/`
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getMenus(id) {
    //https://tastia.gastroranking.pro/backend/api/v1/site_localmarketing/?center=234&tree=1
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/center/${id}/menus/sections/`
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  setSection(id, data, action = 'create', idSection = null) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/center/${id}/menus/sections/`
      let method = 'post'
      if (action === 'updated') {
        //query += `${idSection}/`
        method = 'put'
      } else if (action === 'delete') {
        query += `${idSection}/`
        method = 'delete'
      }
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios[method](query, idSection ? null : data)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  setProduct(id, data, action = 'created', idSection = null, idProduct) {
    //https://demo.gastroranking.pro/backend/api/v1/center/336/menus/sections/4/
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/center/${id}/menus/sections/${idSection}/`
      let method = 'post'
      if (action === 'updated') {
        method = 'put'
      } else if (action === 'delete') {
        query += `${idProduct}/`
        method = 'delete'
      }
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios[method](query, data, { "Content-Type": "multipart/form-data" })
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })

  }
}
