<template lang="pug">

            .card-content
                .row.text-left.mt-2
                    chart-evolution-last-year(:chartData="getChartData()" style="width:100%; height:280px")
</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import ChartEvolutionLastYear from '@/components/charts/EvolutionLastYear.vue'
import commonMixins from "@/mixins/common.js";
export default {
    props:['chartData', 'isDelivery'],
    mixins: [commonMixins],
    components: { SkeletonCard, ChartEvolutionLastYear },
    methods: {
        getChartData (){
            return {
                labels: this.chartData.months,
                datasets: [
                {
                    label: this.$gettext('Negatives'),
                    data: this.negativesPercent,
                    backgroundColor: "#F36627",
                    stack: 1
                },
                {
                    label: this.$gettext('Neutral'),
                    data: this.neutralsPercent,
                    backgroundColor: "#FCAE19",
                    stack: 1
                },
                

                {
                    label: this.$gettext('Positives'),
                    data: this.positivesPercent,
                    backgroundColor: "#89C445",
                    stack: 1
                }
                ]
            }
        }
    },
    computed:{
        positivesPercent(){
            let numbers = this.chartData.positives_percent
            let numbersFormat
            if (numbers) {
                numbersFormat = numbers.map((number) => {
                return this.$options.filters.formatNumber(number,1,1).replace(',','.')
            })
            }
            return numbersFormat
        },
        neutralsPercent(){
            let numbers = this.chartData.neutrals_percent
            let numbersFormat
            if (numbers) {
                numbersFormat = numbers.map((number) => {
                return this.$options.filters.formatNumber(number,1,1).replace(',','.')
            })
            }
            return numbersFormat
        },
        negativesPercent(){
            let numbers = this.chartData.negatives_percent
            let numbersFormat
            if (numbers) {
                numbersFormat = numbers.map((number) => {
                return this.$options.filters.formatNumber(number,1,1).replace(',','.')
            })
            }
            return numbersFormat
        }

    }
}
</script>
