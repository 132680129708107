import AttentionHome from '../views/Attention/Home.vue'
import AttentionInfo from '../views/Attention/Pages/Info.vue'
import AttentionInfoEvolution from '../views/Attention/Pages/AttentionInfoEvolution.vue'
import AttentionInfoIncidents from '../views/Attention/Pages/AttentionInfoIncidents.vue'
import AttentionApps from '../views/Attention/Pages/Apps.vue'
import AttentionAppsIndicators from '../views/Attention/Pages/AttentionAppsIndicators.vue'
import AttentionAppsReviews from '../views/Attention/Pages/AttentionAppsReviews.vue'

export default {
    path: '/attention',
    name: 'AttentionHome',
    redirect: '/attention/info/evolution/:paramCOD?',
    component: AttentionHome,
    meta: {Auth: true},
    props: true,
    children: [
          {
            path: 'info',
            name: 'AttentionInfo',
            component: AttentionInfo,
            redirect: '/attention/info/evolution/:paramCOD?',
            props: true,
            meta: {Auth: true},
            children: [
              {
                path: 'evolution/:paramCOD?',
                name: 'AttentionInfoEvolution',
                component: AttentionInfoEvolution,
                meta: {Auth: true},
                props(route) {
                  return  route.query || {}
                }
              },
              {
                path: 'incidents/:paramCOD?',
                name: 'AttentionInfoIncidents',
                component: AttentionInfoIncidents,
                meta: {Auth: true},
                props(route) {
                  return  route.query || {}
                }
              }
            ]
          },
          {
            path: 'apps',
            name: 'AttentionApps',
            component: AttentionApps,
            redirect: '/attention/apps/indicators/:paramCOD?',
            props: true,
            meta: {Auth: true},
            children: [
              {
                path: 'indicators/:paramCOD?',
                name: 'AttentionAppsIndicators',
                component: AttentionAppsIndicators,
                meta: {Auth: true},
                props(route) {
                  return  route.query || {}
                }
              },
              {
                path: 'reviews/:paramCOD?',
                name: 'AttentionAppsReviews',
                component: AttentionAppsReviews,
                meta: {Auth: true},
                props(route) {
                  return  route.query || {}
                }
              }
          ]
        },
    ]
  }