<template>
    <span class="googleRating" :style="`height:${height}px; background-size: ${height}px; width: ${base * height}px`">
        <span class="googleRating--fill" :style="`width: ${percent}%; height: ${height}px; background-size: ${height}px`">
        </span>
    </span>
</template>
<script>
export default {
    props: {
        nota: {
            default: 0,
            required: true
        },
        height: {
            default: 16,
        },
        base: {
            default: 5,
        }
    },
    computed: {
        percent() {
            return (this.nota * 100 / this.base)
        }
    }
}

</script>
<style scoped>
.googleRating {
    background-repeat: repeat-x;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'%3E%3Cpolygon fill='%23dadce0' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/%3E%3C/svg%3E");
    display: inline-block;
    overflow: hidden;
    position: relative;
}

.googleRating--fill {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'%3E%3Cpolygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    display: block;
}
</style>