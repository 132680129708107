<template lang="pug">
.card-content
    .row.text-left.mt-2
        chart-evolution-main-number-opinion(:chartData="getChartData()" style="width:100%; height:280px")
</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import ChartEvolutionMainNumberOpinion from '@/components/charts/DeliveryEvolutionMainNumberOpinion.vue'
import commonMixins from "@/mixins/common.js";
export default {
    props: ['chartData', 'isDelivery'],
    mixins: [commonMixins],
    components: { SkeletonCard, ChartEvolutionMainNumberOpinion },
    methods: {
        getChartData() {
            return {
                max: this.chartData?.site_rating_base,
                labels: this.chartData?.months || this.chartData?.days,
                datasets: [{
                    data: this.ratings,
                    label: this.$gettext('Valoration'),
                    yAxisID: "y-axis-1",
                    fill: false,
                    borderColor: '#B51C32',
                    pointBackgroundColor: '#B51C32',
                    pointBorderColor: '#B51C32',
                    pointRadius: 5,
                    datalabels: {
                        padding: 3,
                        align: 'end',
                        anchor: 'end',
                    },
                }, {
                    data: this.chartData.n_reviews,
                    label: `Nº ${this.$gettext('Opinions')}`,
                    yAxisID: "y-axis-2",
                    fill: false,
                    borderColor: '#4a90e2',
                    pointBackgroundColor: '#4a90e2',
                    pointRadius: 4,
                    datalabels: {
                        display: false,
                        padding: 20
                    },
                }]
            }
        }
    },
    computed: {
        ratings() {
            let numbers = this.chartData.ratings
            let numbersFormat
            if (numbers) {
                numbersFormat = numbers.map((number) => {
                    return this.$options.filters.formatNumber(number, 1, 1).replace(',', '.')
                })
            }
            return numbersFormat
        }
    }

}
</script>

<style lang="scss" scoped>
.headerChart {
    width: 100%;
    display: block;
    font-size: 0.75rem;
}

h3 {
    float: left;
    font-weight: normal;
    padding: 0 20px;
    font-size: 0.70rem;

    &.nreviews {
        float: right;
        color: #4a90e2;
    }
}
</style>
