<template lang="pug">
  div
    span {{ message }}
</template>

<script>
import SrvLocal from '@/services/local.js'

export default {
  data() {
    return {
      message: ''
    }
  },
  async mounted() {
    const res = await this.getValidatedTokenGoogle()
    if (res.data.validated_token) {
      window.opener.location.reload(false);
      window.close()
    } else {
      this.message = 'Error'
    }
  },
  methods: {
    async getValidatedTokenGoogle() {
      return SrvLocal.getValidateToken(this.$route.query.code, this.$route.query.state)
    }
  }
}
</script>