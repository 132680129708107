<template>
    <div>
        <Section v-for="(section, index) in sections" :data="section" @productOk="$emit('productOk', $event)" @sectionDeleteOk="$emit('sectionDeleteOk',$event)" :key="index"></Section>
    </div>
</template>
<script>
import Section from '@/components/local/menu/Section.vue'
export default {
    components: { Section },
    props: {
        sections: {
            default: [],
            required: true
        }
    },
}
</script>