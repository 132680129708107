// Stores
import Stores from "@/store/index";
// importamos los tipos de mutación de vuex
import * as types from "@/store/mutation-types";
// Config
import appConfig from "@/config-app.js";
// funciones comunes
import SrvCommon from "@/services/common";

// Axios
import axios from "axios";
axios.defaults.baseURL = appConfig.api;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {
  getSummarySatisfactionSurvey() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      let query = `/summary_satisfaction_survey/`;

      // SI EL NIVEL NO EXISTE ES CENTRO
      if (Stores.getters.getNodeLevel == 9) {
        if (Stores.getters.getNode) {
          query += `?center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `?node=${Stores.getters.getNode}`;
        }
      }

      if (Stores.getters.getDateAfter) {
        query += `&after=${Stores.getters.getDateAfter}T00:00:00`;
      }

      if (Stores.getters.getDateBefore) {
        query += `&before=${Stores.getters.getDateBefore}T00:00:00`;
      }

      query += `&tree=${Stores.getters.getTreeSelected.id}`;

      let configHeaders = {};

      axios
        .get(query, configHeaders)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          setTimeout(() => {
            resolve(res);
          }, 1000);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getSatisfactionComments(params, excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      let query = `/satisfaction_surveys/`;
      let limit = 25;
      if (params["limit"]) {
        limit = params["limit"];
      }
      query += `?limit=${limit}`;
      if (params["offset"]) {
        query += `&offset=${params["offset"]}`;
      }
      if (params["language"]) {
        for (var lang in params["language"]) {
          query += `&language=${params["language"][lang].value}`;
        }
      }
      if (params["sentiment"]) {
        for (var sentiment in params["sentiment"]) {
          query += `&sentiment=${params["sentiment"][sentiment].value}`;
        }
      }
      if (params["reason"]) {
        for (var reason in params["reason"]) {
          query += `&reason=${params["reason"][reason].value}`;
        }
      }
      if (params["survey_type"]) {
        for (var survey_type in params["survey_type"]) {
          query += `&${params["survey_type"][survey_type].value.toLowerCase()}=true`;
        }
      }
      if (Stores.getters.getDateAfter) {
        query += `&after=${Stores.getters.getDateAfter}T00:00:00`;
      }
      if (Stores.getters.getDateBefore) {
        query += `&before=${Stores.getters.getDateBefore}T00:00:00`;
      }
      // SI EL NIVEL NO EXISTE ES CENTRO
      if (Stores.getters.getNodeLevel == 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`;
        }
      }

      // SI VIENE EL TIPO DE CENTRO
      if (Stores.getters.getTypesRestaurantSelected.length > 0) {
        Stores.getters.getTypesRestaurantSelected.forEach((type) => {
          query += `&center_type=${type}`;
        });
      }

      query += `&tree=${Stores.getters.getTreeSelected.id}`;
      query += `&ordering=${params["ordering"]}`;

      let configHeaders = {};
      if (excel) {
        query += `&format=xlsx`;
        configHeaders = SrvCommon.getConfigHeadersExcel();
      }

      axios
        .get(query, configHeaders)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          setTimeout(() => {
            resolve(res);
          }, 1000);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getSatisfactionRanking(order = "-name", excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/ranking_satisfaction_survey/`;
      query = SrvCommon.getBasicParamsQuery(query);

      if (Stores.getters.getSurveyTypesSelected) {
        for (var survey_type in Stores.getters.getSurveyTypesSelected) {
          query += `&${Stores.getters.getSurveyTypesSelected[survey_type].key.toLowerCase()}=true`;
        }
      }

      query += "&ordering=" + order;
      // llamada a la API
      let token = SrvCommon.getToken();
      let configHeaders = {};
      if (excel) {
        query += `&format=xlsx`;
        configHeaders = SrvCommon.getConfigHeadersExcel();
      }

      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query, configHeaders)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getSatisfactionRankingNext(url) {
    return new Promise((resolve, reject) => {
      let token = SrvCommon.getToken();

      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(url)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getQuestions() {
    return new Promise((resolve, reject) => {
      let token = SrvCommon.getToken();

      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(SrvCommon.getBasicParamsQuery("satisfaction_survey_questions/"))
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getSatisfactionSurveyQR(ordering) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      let query = `/satisfaction_surveys_qr/`;

      // SI EL NIVEL NO EXISTE ES CENTRO
      if (Stores.getters.getNodeLevel == 9) {
        if (Stores.getters.getNode) {
          query += `?center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `?node=${Stores.getters.getNode}`;
        }
      }
      query += `&tree=${Stores.getters.getTreeSelected.id}`;
      query += `&ordering=${ordering}`;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          setTimeout(() => {
            resolve(res);
          }, 1000);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getSummarySatisfactionStatsSurvey({ disabledReviewType = true }) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      let query = `/summary_satisfaction_stats_survey/`;

      // SI EL NIVEL NO EXISTE ES CENTRO
      if (Stores.getters.getNodeLevel == 9) {
        if (Stores.getters.getNode) {
          query += `?center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `?node=${Stores.getters.getNode}`;
        }
      }

      if (Stores.getters.getDateAfter) {
        query += `&after=${Stores.getters.getDateAfter}T00:00:00`;
      }

      if (Stores.getters.getDateBefore) {
        query += `&before=${Stores.getters.getDateBefore}T00:00:00`;
      }

      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach((sit) => {
          query += `&site=${sit}`;
        });
      }

      if (Stores.getters.getTypesRestaurantSelected.length > 0) {
        Stores.getters.getTypesRestaurantSelected.forEach((type) => {
          query += `&center_type=${type}`;
        });
      }

      if (Stores.getters.getRegionSelected.length > 0) {
        Stores.getters.getRegionSelected.forEach((reg) => {
          query += `&region=${reg}`;
        });
      }

      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach((type) => {
          query += `&center_model=${type}`;
        });
      }

      if (Stores.getters.getTypesSelected && !disabledReviewType) {
        query += `&review_type=${Stores.getters.getTypesSelected}`;
      }

      query += `&tree=${Stores.getters.getTreeSelected.id}`;

      let configHeaders = {};

      axios
        .get(query, configHeaders)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          setTimeout(() => {
            resolve(res);
          }, 1000);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
};
