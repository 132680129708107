<template lang="pug">
    v-row.px-2.ma-0
        //GRID TIPO
        div.margin-bottom-0.col-6.d-flex(v-if="typeNode !== 'center'")
            skeleton-card(v-if="isLoading")
            v-card.gridFull.ma-0(v-if="!isLoading")
                .d-flex.headTitleGrid
                    .block-title.titleGrid
                        translate.mb-0.title-card Number of Opinions
                .card-content
                    .row.text-left
                        .tableGlobal.tableNumeroReclamacionesSugerencias
                            div.headerTableRanking.row.ma-0
                                div.headerItem.col-name
                                    p
                                div.headerItem.col-numero
                                    p.mb-0 #[translate Reviews]
                                div.headerItem.col-tend
                                    p.mb-0 #[translate Tend]
                                div.headerItem.col-googlePlay
                                    p.mb-0 #[translate Google Play]
                                div.headerItem.col-appStore
                                    p.mb-0 #[translate App Store]
                            div.contentTableRanking.row.ma-0
                                p.ml-3(v-if="!dataApp") #[translate No data]
                                div.itemContent.col-12.pa-0(v-for="(item, index) in dataApp" :key="index")
                                    div.item.col-name
                                        span(v-if="index == 0") {{item.name}}
                                        span.link-text(v-if="index>0" @click="goItemNextLevel(item, item.is_center)") {{item.name}}
                                    div.item.col-numero
                                        p.mb-0.font-weight-med {{ item.review_count | formatNumber }}
                                    div.item.col.col-tend
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.review_count,item.tend_review_count)")
                                            span {{ item.tend_review_count | formatNumber }}
                                    div.item.col-googlePlay(v-if="item.rating.length")
                                        p.mb-0.font-weight-med(v-if="item.rating[0].hasOwnProperty('count')") {{ item.rating[0].count | formatNumber }}
                                    div.item.col-appStore(v-if="item.rating.length")
                                        p.mb-0.font-weight-med(v-if="item.rating[1].hasOwnProperty('count')") {{ item.rating[1].count | formatNumber }}

        //GRID TIPO
        div.margin-bottom-0.col-6.d-flex(v-if="typeNode !== 'center'")
            skeleton-card(v-if="isLoading")
            v-card.gridFull.ma-0(v-if="!isLoading")
                .d-flex.headTitleGrid
                    .block-title.titleGrid
                        translate.mb-0.title-card Valoration
                .card-content
                    .row.text-left
                        .tableGlobal.tableNumeroReclamacionesValoracion
                            div.headerTableRanking.row.ma-0
                                div.headerItem.col-name
                                    p
                                div.headerItem.col-googlePlay.text-center
                                    img.mr-2(:src="img.googlePlay")
                                    span.mb-0 #[translate Google Play]
                                div.headerItem.col-appStore.text-center
                                    img.mr-2(:src="img.appleStore")
                                    span.mb-0 #[translate App Store]
                            div.contentTableRanking.row.ma-0
                                p.ml-3(v-if="!dataApp") #[translate No data]
                                div.itemContent.col-12.pa-0(v-for="(item, index) in dataApp" :key="index")
                                    div.item.col-name
                                        span(v-if="index == 0") {{item.name}}
                                        span.link-text(v-if="index>0" @click="goItemNextLevel(item, item.is_center)") {{item.name}}
                                    div.item.col-googlePlay.text-center(v-if="item.rating.length")
                                        span.mb-0.font-weight-med {{ item.rating[0].rating_global | formatNumber }}
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.ml-5.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.rating[0].rating_global ,item.rating[0].tend_rating_global )")
                                            span {{ item.rating[0].tend_rating_global | formatNumber }}
                                    div.item.col.col-appStore.text-center(v-if="item.rating.length")
                                        span.mb-0.font-weight-med {{ item.rating[1].rating_global | formatNumber }}
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.ml-5.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.rating[1].rating_global,item.rating[0].tend_rating_global)")
                                            span {{ item.rating[0].tend_rating_global | formatNumber }}



        div.margin-bottom-0.col-12.d-flex
            skeleton-card(v-if="isLoading")
            v-card.gridFull.ma-0(v-if="!isLoading")
                .d-flex.headTitleGrid
                    .block-title.titleGrid
                        translate.mb-0.title-card Evolution of the opinions number
                .card-content
                    .row.text-left
                        .col-12(v-if="data")
                            chart-evolution-app-opinion(:chart-data="getChartData()" :options="optionsEvolution" :style="{height:'210px'}")



</template>

<script>
import SrvAttention from '@/services/attention.js'
import commonMixins from "@/mixins/common.js"
import SkeletonCard from '@/components/common/skeletonCard.vue'
import chartDoughnut from '@/components/charts/doughnut.vue'
import chartLinear from '@/components/charts/Linear.vue'
import ChartEvolutionAppOpinion from '@/components/charts/EvolutionAppOpinion.vue'

export default {
    mixins: [commonMixins],
    components: { SkeletonCard, chartDoughnut, chartLinear,  ChartEvolutionAppOpinion}, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX    mixins: [commonMixins],
    data(){
        return {
            data: [],
            dataApp: [],
            img: {
                googlePlay: require('@/assets/img/Google_Play.svg'),
                appleStore: require('@/assets/img/App_Store.svg')
            },
            colorsOrigen:['#7E2D8E','#FCAE19','#F36627'],
            colorsTipo:['#00C7CA','#FF6950','#E5E5E5','#417505','#F5A623','#9B9B9B','#B4AC52','#9013FE','#FF7700','#4A90E2','#7ED321',
            '#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5','#F5F5F5']
        }
    },
    created(){
      this.$store.commit('LOADING', true)
      if(this.$store.getters.getNode > 0){
          this.getGlobalApp()
        this.getEvolution()
      }
    },
    watch: {
      filterApply: function(){
          this.getGlobalApp()
        this.getEvolution()
      }
    },
    computed: {
        optionsEvolution (){
            return {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: false
                },
                plugins: {
                    datalabels: {
                        display:false
                    }
                },
                scales: {
                    xAxes: [
                        {
                        type: "time",
                        time: {
                            unit: 'day',
                            parser:'DD/MM/YYYY',
                            displayFormats: { day: 'DD/MM/YYYY' },
                            stepSize: 2
                        }
                        }],
                },
                legend: {
                    position: 'left',
                    display:false
                }
            }
        }
    },
    methods: {
        formatNumber (val) {
            return this.$options.filters.formatNumber(val,1,2)
        },
        dataEvolution (){
            let labelsEvolution = this.data.months
            let dataOrigen = this.data.n_reviews

            let dataChart = {
                labels: labelsEvolution,
                datasets: [
                {
                    data: dataOrigen,
                    fill: false,
                    backgroundColor: '#85D9F3',
                    borderColor: '#85D9F3',
                }
                ]
            }
            return dataChart
        },
        getGlobalApp() {
            this.$store.commit('LOADING', true)
            SrvAttention.getGlobalApp()
            .then(res => {
                if (res.status === 200) {
                    this.dataApp = res.data

                    this.$store.commit('LOADING', false)

                    if (this.dataApp[0] === 'not available') {
                      this.$router.go(-1)
                    }
              }
            }, () => {
            })
        },
        getEvolution() {
            this.$store.commit('LOADING', true)
            SrvAttention.getEvolutionApp()
            .then(res => {
                if (res.status === 200) {
                    this.data = res.data
                    this.$store.commit('LOADING', false)
                }
            }, () => {
            })
        },
        getChartData (){
            let competence_datasets = []
            let labelsMonths = []
            if (this.data.length) {
                labelsMonths = this.data[0].months
            }
            for (var i=0; i < this.data.length; i++){
                var item = this.data[i],
                m_reviews = item.n_reviews,
                itemdata = [];
                for (let j=0; j < m_reviews.length; j++){
                    itemdata.push(this.$options.filters.formatNumber(m_reviews[j],1,1).replace(',','.'))
                }
                competence_datasets.push(
                {
                    data: itemdata,
                    label: item.name,
                    fill: false,
                    borderColor: this.colorsTipo[i],
                    backgroundColor: "#FFF",
                    backgroundSize: 3,
                    pointBackgroundColor: "#FFF",
                    pointBorderColor: this.colorsTipo[i],
                    pointRadius: 4,
                    borderWidth: 2,
                    pointStyle: 'circle',
                    tension: 0
                }
                )
            }
            return {
                labels: labelsMonths,
                datasets: competence_datasets
            }
        }

    }
}
</script>

<style lang="scss" scoped>
.gridFull{
  width: 100%;
}
.height-100{
    height: 100%;
}
.legendTipo.percent0:first-child{
    margin-top: 80px;
}
.tends{
    margin: 30px 0 0 30px;
    .arrow{
        margin-right: 10px;
    }
}

.tableGlobal {
    &.tableNumeroReclamacionesSugerencias{
        .headerItem{
            justify-content: center;
        }
        .col-name{
            max-width: 32.33%;
            flex: 0 0 32.33%;
            margin-right: 2.5%;
            padding-left: 10px;
            text-align: left;
            &.textBlackGroup{
                color:#000;
            }
            &.media-cadena{
                color:#979797;
                span{
                    letter-spacing: -0.02px;
                }
            }
        }
        .col-numero{
            max-width: 11.47%;
            flex: 0 0 11.47%;
            justify-content: center;
        }
        .col-tend{
            max-width: 10.47%;
            flex: 0 0 10.47%;
            margin-right: 4.5%;
            justify-content: center;
        }
        .col-googlePlay{
            max-width: 14.47%;
            flex: 0 0 14.47%;
            margin-right: 4.5%;
            justify-content: center;
        }
        .col-appStore{
            max-width: 14.47%;
            flex: 0 0 14.47%;
            margin-right: 4.5%;
            justify-content: center;
        }
    }
&.tableNumeroReclamacionesValoracion{
    .headerItem{
        justify-content: center;
    }
    .col-name{
        max-width: 34.33%;
        flex: 0 0 34.33%;
        margin-right: 2.5%;
        padding-left: 10px;
        text-align: left;
        &.textBlackGroup{
            color:#000;
        }
        &.media-cadena{
            color:#979797;
            span{
                letter-spacing: -0.02px;
            }
        }
    }
    .col-googlePlay{
        max-width: 28.47%;
        flex: 0 0 28.47%;
        margin-right: 3%;
        justify-content: center;
    }
    .col-appStore{
        max-width: 28.47%;
        flex: 0 0 28.47%;
        margin-right: 3%;
        justify-content: center;
    }
}
}
</style>
