
<template>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 17.2 17.2" style="enable-background:new 0 0 17.2 17.2;" xml:space="preserve"  :class="{'dBlock': block}">
  <g id="Capa_1_1_" transform="translate(0 0)">
	<path id="Trazado_5333" class="st0" d="M14.3,14.5c-1.9,0.8-4.1,0.8-6.1,0c-1.4-0.7-2.5-1.8-3.2-3h7.2c0.6,0,1.1-0.4,1.1-1l0,0
		c0-0.7-0.6-0.8-1.1-0.8H4.5C4.4,9,4.4,8.4,4.5,7.8h7.7c0.6,0,1.1-0.4,1.1-1l0,0c0-0.7-0.6-0.8-1.1-0.8H5.1c1.8-3.6,5.8-4.8,9.2-3.3
		c0.4,0.3,1,0,1.2-0.4c0.3-0.4,0-1.1-0.4-1.2C10.9-1.1,5.6,0.7,3.4,5C3.1,5.3,3,5.4,3,5.7H1.6c-0.7,0.1-1.1,0.4-1.1,1
		c0,0.6,0.4,1,1,1l0,0h1.1c-0.1,0.7-0.1,1.2,0,1.9h-1c-0.7,0-1.1,0.4-1.1,1s0.4,1,1,1l0,0h1.4c1,2.1,2.5,3.9,4.5,4.8
		c1.1,0.6,2.5,1,3.7,0.8c1.4,0,2.6-0.3,3.7-0.8c0.4-0.1,0.7-0.7,0.4-1.1c0,0,0-0.1-0.1-0.1c-0.1-0.4-0.6-0.7-1-0.6
		C14.3,14.5,14.3,14.5,14.3,14.5L14.3,14.5z"  :fill="color"/>
</g>
</svg>
</template>

<script>
export default {
  name: "IcoPrice",
  props: {
    color: {
        type: String,
        default: '#000000',
        required: false
    },
    block: {
        type: Boolean,
        default: true,
        required: false
    }
  }
}
</script>

<style scoped>
    .dBlock{
        display: block;
    }
</style>