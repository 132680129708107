<template lang="pug">
    div
        v-dialog.dialogEdit(v-model="show"
            width="680")
            v-card.dialogEdit
                v-card-text.pb-0
                v-container
                    div.gr-loader-snipper--wrapper(v-if="loading")
                        span.gr-loader-snipper
                    skeleton-card(v-if="loading")
                    v-row#headerModalEdit.pt-3(v-if="!loading")
                        v-col(cols="10")
                            span(v-if="!isAdverbia") #[translate Videos del restaurante]
                            span(v-else) Videos del negocio
                        v-col.mt-5(cols="2")
                            img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                    v-row#bodyModalEdit(v-if="!loading")
                        v-col(cols="12")
                            v-container
                                v-row
                                    Errors(v-if="errors" :errors="errors")
                                    v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                                        div.messageError
                                            span {{ messageError ? messageError : $gettext('An error occurred while recording.') }}                                                                      
                                    v-col.text-left.pb-0(cols="8")
                                        p
                                            span #[translate Los requisitos del video son los siguientes:]
                                            br
                                            span #[translate Resolución: 720p o superior]
                                            br
                                            span #[translate Peso: máximo 75 MB]
                                            br
                                            span #[translate Duración: máximo 30 segundos]
                                    v-col.pb-0(cols="4")
                                    v-col.text-left(cols="12")                                    
                                        v-row                                        
                                            v-col(md="6")                                                
                                                v-file-input.addPhoto.d-flex.flex-column.align-center.justify-center.py-8(
                                                    :rules="rules"
                                                    v-model="image"
                                                    id="file" ref="file"
                                                    :placeholder="placeholderAddPhoto"
                                                    prepend-icon="mdi-camera"
                                                    label="")
                                            v-col(md="6")

                                                    

                                        
                    v-row#footerModalEdit
                        v-col(cols="12")
                            button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
                            button.btnSaveEditModal(@click="showConfirm = true")  #[translate Save]
        ModalConfirmMultipleEdit(:visible="showConfirm"  @close="showConfirm=false" @confirmed="save()")
        edited-ok(:show="showOk" @update:show="updateShow")
            p.mt-3.py-3.text-center 
                strong Editado correctamente.
</template>
<script>
import commonMixins from '@/mixins/common.js'
import SrvLocal from '@/services/local.js'
import SkeletonCard from '@/components/common/skeletonCard.vue'
import ModalConfirmMultipleEdit from '@/components/local/confirmMultipleEdit.vue'
import Errors from '@/components/common/Errors.vue'
import EditedOk from "@/components/modals/EditedOk.vue";

export default {
    mixins: [commonMixins],
    components: { SkeletonCard, ModalConfirmMultipleEdit, Errors, EditedOk},
    props: ['visible', 'idsBussinesModeMultiple'],
    data(){
        return {
            loading: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            name: '',
            image: null,
            showConfirm: false,
            showOk: false,
            data: {},
            categorieVideo: {name: "Otras", video_type: "ADDITIONAL"},
            rules: [
                value => !value || value.size < 75000000 || this.$gettext('Avatar size should be less than 5 MB!'),
            ],
            file: '',
            saveStatus: true,
            messageError: null,
            errors: null
        }
    },
    watch: {
        visible (newValue){
            if (newValue) {
                this.image = null

            }
        }
    },
    computed: {
        placeholderAddPhoto () {
            return this.$gettext('Añadir video')
        },
        show: {
            get () {
                return this.visible
            },
            set (value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    methods: {
        updateShow(event) {
            this.showOk = event;
            this.$emit("close");
        },
        handleFileUpload(){
            this.file = this.$refs.file.input.files[0];
        },
        async save () {
            // IDS
            this.loading = true
            let valid = true
            if (valid) {         
                try{
                    const formData = new FormData()
                    formData.append('video_details', JSON.stringify({"center_ids":this.idsBussinesModeMultiple}));
                    formData.append('update_type', 'VIDEO');
                    formData.append('videos', this.image);
                    formData.append('categories', 'ADDITIONAL');
                    const res = await SrvLocal.setMultipleBatchUpdate(formData)
                    if (res.status === 200 || res.status === 201) {
                        this.showOk = true
                    }
                } catch(e) {
                    this.errors = e.response.data.error === 'Problem creating video' ? 'Ha ocurrido un problema al subir el video' : e.response.data.error
                    this.loading = false
                }
            }
            this.loading = false
        }
    }
}
</script>
<style lang="scss" scoped>
    .boxThumbnails{
        border-top:1px solid #000;
    }
    .addPhoto{
        border: 2px solid #00ADFC;
        max-height: 53px;
        color: #00ADFC;
    }
    .preview{
        height: 73px;
        width: 80%;
    }
    .titleSelectCategory{
        font-weight: 600;
    }
    .boxPhoto{
        height: 65px;
        overflow: hidden;
    }
    .item{
        border-bottom: 1px solid #e6e3e3;
        .removePhoto{
            img{
                width: 20px;
                display: block;
                cursor:pointer;
            }
        }
    }
    .messageError{
        background-color: #F5B1B8;
        border: 1px solid #F93549;
        color: #F93549;
        padding:15px;
    }
    .infoSticky{
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #fff;
    }
</style>