<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: "EvolutionLastYear",
  extends: Bar,
  mixins: [reactiveProp],
  props:['chartData'],
  data: () => ({
    type: 'bar',
    options: {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
            left: 20,
            right: 20,
            top: 10,
            bottom: 10
        }
      },
      legend: {
          display: false,
      },
      scaleLabel: {
        fontColor: '#000',
        fontSize: '56px'
      },
      plugins: {
        // Change options for ALL labels of THIS CHART
        datalabels: {
            display: false
        }
      },
      scales: {
            xAxes: [{
                stacked: true,
                barThickness: 10,
                maxBarThickness: 15,
            }],
            yAxes: [{
                stacked: true,
              ticks: {
                  min: 0,
                  max: 100
              }
            }]
      }
    },
  }),
  watch: {
    chartData () {
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
