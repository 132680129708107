<template lang="pug">
div
    v-row.px-2.ma-0(:class="{'premiumDisabled': !hasPremium}")
        // LOGO MARCA     
        v-col.margin-bottom-0.flex-full(sm="12" :class="{'premiumDisabled': !hasPremium}")
            skeleton-card(v-if="isLoading")
            v-card.gridFull.h-auto.my-4.mt-2.ma-0.pl-6.pr-9(v-if="!isLoading")
                v-row.ma-0.headAndButtons.items-end
                    v-col.text-14(sm="6")
                        strong.mr-16 #[translate Servicios]
                    v-col.justify-end.d-flex(sm="6")
                      <button @click="openAdd" class="btnAdd text-14">{{ $gettext('Agregar un servicio') }}</button>
                      <div id="automaticSync" class="ml-2 d-flex">
                        <button class="btnSyncExportGoogle mr-2" @click="getServicesUpdateToGoogle()">
                          <span> {{ $gettext('Subir servicios a google') }} </span>
                          <div class="gr-loader-snipper--wrapper" v-if="loadingSync">
                            <span class="gr-loader-snipper"></span>
                          </div>
                        </button>
                      </div>
                v-row.ma-0(v-if="services.length > 0")
                    v-col.text-14(sm="3" v-for="(serv, index) in services")
                      <Service :key="index" :data="serv" :index="index" :idBussines="center" @saved="refreshPosts" @deletePost="refreshPosts"></Service>                    
                v-row.ma-0(v-else)
                    v-col.text-14(sm="3")
                      <ServiceEmpty></ServiceEmpty>
                edited-ok(:show="showOk" @update:show="showOk = $event")
                  p.mt-3.py-3.text-center 
                    strong Bloqueadas las secciones correctamente.
                <add @saved="refreshPosts" :idBussines="center" :visible="showAddNew" @close="showAddNew = $event"></add>

</template>

<script>
  import SkeletonCard from '@/components/common/skeletonCard.vue'
  import SrvLocal from '@/services/local.js'
  import SrvLocalServices from '@/services/localServices.js'
  import commonMixins from "@/mixins/common.js"
  import localMixins from "@/mixins/local.js"
  //FICHAS EDICION
  import EditedOk from '@/components/modals/EditedOk.vue'
  import Service from '@/components/local/services/Service.vue'
  import ServiceEmpty from '@/components/local/services/ServiceEmpty.vue'
  import add from '@/components/local/services/add.vue'

  export default {
    components: {
        SkeletonCard,
        EditedOk,
        Service,
        ServiceEmpty,
        add
    }, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
    mixins: [commonMixins, localMixins],
    data() {
      return {
        showEdit: true, // TEMPORAL PARA SUBIDAS A PRO
        dataProfile: [],
        services: [],
        showAddEvent: false,
        showAddQuotation: false,
        showAddNew: false, 
        messageError: null,
        loadingSync: false,
        loadingImportGoogle: false,
        sections: null,
        showOk: false,
    }   
    },
    async created() {
      this.$store.commit('LOADING', true)
      this.loading = true
      this.center = Number(this.$store.getters.getNode)
      await this.getLocalMarketing()
      this.loading = false
      this.$store.commit('LOADING', false)
    },    
    watch: {
      filterApply: function () {
        this.getLocalMarketing()
      }
    },
    methods: {   
      async getServicesUpdateToGoogle() {
        this.loadingSync = true
        try {
          const res = await SrvLocalServices.sendServicesToGoogle(this.center)
          if (res.status === 200) {
            // console.log(res)
          }
        } catch (e) {
          // console.log(e)
        } 
        this.loadingSync = false
      },
      openAdd(){
        if (this.hasPremium) {
          this.showAddNew = true
        }
      },
      refreshPosts(){
        this.getLocalMarketing()
      },  
      async getLocalMarketing() {
        this.$store.commit('LOADING', true)
        const resLocal = await SrvLocal.getLocalMarketing()
        if (resLocal.status === 200) {
          this.dataProfile = resLocal.data
          this.facebookConfig = resLocal.data.facebook_config
        }
        if (this.hasPremium) {
          const resServices = await SrvLocalServices.getServices(this.center)
          if (resServices.status === 200) {
            this.services = resServices.data ? resServices.data : []
            this.$store.commit('LOADING', false)
          }
        } else {
          this.$store.commit('LOADING', false)
        }     
      },      
    }
  }
  </script>
<style scoped>
  .btnAdd{
    background-color: #00ADFC;
    color:#fff;
    padding: 9.5px;
  }
  .headAndButtons{
    border-bottom:2px solid #E7E7E7;
  }
</style>
