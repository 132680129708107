<template lang="pug">
div
    v-dialog.dialogEdit(v-model="show"
        width="680")
        v-card.dialogEdit
            v-card-text.pb-0
            v-container
                div.gr-loader-snipper--wrapper(v-if="loading")
                    span.gr-loader-snipper
                v-row#headerModalEdit.pt-3
                    v-col(cols="10")
                        strong #[translate Selecciona información a descargar]
                    v-col.mt-5(cols="2")
                        img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                v-row
                    v-col.col-12
                        v-list
                            v-checkbox.mt-0.pt-2(
                                v-model= "all"
                                color="primary"
                                label="Descargar todo el informe"
                                hide-details
                                @click="handleAllSectionClick"
                            )
                                template(slot="label")
                                    a.text-13 #[translate Descargar todo el informe]
                    v-col.col-6
                        p.text-center.text-14 #[translate Secciones]
                        v-list.oneSelectTreeList.pa-0
                            ul.pl-0
                                li.px-2
                                    v-checkbox.mt-0.mb-3.pt-0(
                                        v-model= "allSection"
                                        :color="color"
                                        :disabled="all"
                                        label="Todas las secciones"
                                        hide-details
                                        @click="handleAllSectionClick"
                                    )
                                        template(slot="label")
                                            a #[translate Todas las secciones]
                        v-list.oneSelectTreeList.pa-0.pl-4
                            ul.pl-0
                                li.px-2(v-for="(item, key) in sections")
                                    v-checkbox.mt-0.mb-3.pt-0(
                                        v-model= "selectedSection"
                                        multiple
                                        :disabled="all"
                                        :color="color"
                                        :label="item.name"
                                        :value="item.key"
                                        hide-details
                                    )
                                        template(slot="label")
                                            a {{ item.name }}
                    v-col.col-6
                        p.text-center.text-14 #[translate Sitios]
                        v-list.oneSelectTreeList.pa-0
                            ul.pl-0
                                li.px-2(v-for="(item, key) in opinionSites")
                                    v-checkbox.mt-0.mb-3.pt-0(
                                        v-model= "selected"
                                        :disabled="all"
                                        :color="color"
                                        :label="item.name"
                                        :value="key"
                                        hide-details
                                        @click="cleanChild(item)"
                                    )
                                        template(slot="label")
                                            a {{ item.name }}
                                    ul.treeChild(v-if="item.sites && item.sites.length > 0")
                                        li(v-for="elem in item.sites")
                                            v-checkbox.mt-0.mb-3.pt-0(
                                                v-model= "selectedChild"
                                                multiple
                                                :disabled="all"
                                                :color="color"
                                                :label="elem.name"
                                                :value="elem.code"
                                                :name="item.name"
                                                @click="setChild(elem, item)"
                                                hide-details

                                            )
                                                template(slot="label")
                                                    a {{ elem.name }}

                v-row#footerModalEdit
                    v-col(cols="12")
                        button.btnSaveEditModal(@click="save()")  #[translate Confirmar]
</template>

<script>
import commonMixins from '@/mixins/common.js'

export default {
    mixins: [commonMixins],
    props: ['visible', 'idBussines', 'isDetails'],
    data() {
        return {
            loading: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            selectedChild: [],
            selected: '',
            selectedSection: [],
            allSection: true,
            all: false,
            selectedChildName: '',
            sections: [{ name: "Resumen", key: "summary" }, { name: "Opinions", key: "reviews" }, { name: "GPS", key: "gps" }, { name: "Estadísticas", key: "stats" }, { name: "Ranking", key: "ranking" }, { name: "Competencia", key: "competence" }]
        }
    },
    mounted() {
        this.handleAllSectionClick()
    },
    watch: {
        visible(newValue) {
            if (newValue) {
                this.data = {}
            }
        },
        selectedSection(value) {
            if (value.length < this.sections.length && this.allSection) this.allSection = false;
        },
    },
    computed: {
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        },
        sectionKeys() {
            return this.sections.map(section => section.key)
        },
        color() {
            return this.all ? "gray" : "primary"
        }
    },
    methods: {
        handleAllSectionClick() {
            this.allSection ? this.selectAllSectionChild() : this.cleanSectionChild()
        },
        save() {
            const sites = []
            for (const opinionSite in this.opinionSites) {
                for (const site of this.opinionSites[opinionSite].sites) {
                    if (this.selectedChild.includes(site.code)) {
                        sites.push(site.id)
                    }
                }
            }

            const payload = this.all ? {} : { sites, sections: this.selectedSection, delivery: this.selected === "delivery", internet: this.selected === "internet" }

            this.$emit('confirmed', payload)
            this.$emit('close')
        },
        cleanSectionChild() {
            this.selectedSection = []
        },
        selectAllSectionChild() {
            this.selectedSection = [...this.sectionKeys]
        },
        cleanChild(item) {
            if (this.selected) {
                const sitesKeys = item.sites.map(item => item.code)
                this.selectedChild = [...sitesKeys]
                return
            }

            this.selectedChildName = ''
            this.selectedChild = ''
        },
        setChild(val, father) {
            if (this.selected === father.name.toLowerCase()) {
                this.selected = father.name.toLowerCase()
                this.selectedChildName = this.selectedChildName !== val.name ? val.name : ''
            } else {
                this.selected = father.name.toLowerCase()
                this.selectedChildName = this.selectedChildName !== val.name ? val.name : ''
                this.selectedChild = [val.code]
            }
        }

    }
}
</script>

<style lang="scss" scoped>
label {
    color: #1976d2;
    font-size: 13px;
    font-weight: 500;
}

p {
    border: 1px solid #1976d2;
    color: #1976d2;
    border-radius: 10px;
    padding: 10px
}
</style>
    
    