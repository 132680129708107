<!-- 
  Fecha: 05/01/2021
  Componente para pintar select con checkbox 
  Propiedades 
    options: array de objetos (Necesarios campos id y name)
  Eventos:
    checked: evento del componente padre  
-->

<template lang="pug">
   div.multiSelect
    v-select(
            v-model="selected"
            :items="options"
            return-object
            multiple
            :placeholder="text.label"
            persistent-hint
            item-text="ssss"
          )
      div.selectAll(
          slot="prepend-item"
          ripple
          @click="toggle"
          name="ss")
          span.iconSelectAll
            v-icon(:color="options.length > 0 ? 'indigo darken-4' : ''") {{ icon }}
          span #[translate  Select All]   
      template(v-slot:selection="{ item, index }")
          p.mb-0.typeRestaurantSelected.text-truncate(v-if="index === 0" :class="{'controlWMore':selected.length == 1,'controlW':selected.length > 1}") {{ item.text }}
          p.mb-0.ml-1.typeRestaurantSelected(v-if="index === 1" class="caption")  (+{{ selected.length - 1 }} )
      template(v-slot:item="{ active, item, attrs, on }")
          v-list-item(v-on="on" v-bind="attrs" #default="{ active }")
            v-list-item-action
              v-checkbox.sinHover(:input-value="active")
            v-list-item-content
              v-list-item-title
                v-row(no-gutters align="center")
                  v-chip(v-if="item.box" text-color="white" :color="item.box" class="tipo" small)
                  span {{ item.text }}
                  v-spacer
                  
      v-divider.mt-2(slot="prepend-item") 
      template(v-slot:append-item)
          div.filtrar
            button(@click="filter()") #[translate Apply]
          span.d-none #[translate All]

</template>

<script>
export default {
  name: 'MultiSelect',
  props: ['options','itemsChecked'],
  data() {
    return {
      show: true,
      selected: [],
      text:{
        label: this.$gettext('All')

      }
      
    }
  },
  created(){
    this.selected = this.itemsChecked;
  },
  methods: {
    filter: function(){
      document.getElementById("navbar-limit").click()
      this.$emit('checked', this.selected)
      this.show = false;
    },
    toggle () {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.selected = []
        } else {
          this.selected = this.options.slice()
        }
      })
    },
  },
  computed:{
      likesAll () {
        let resultado = false
        if(this.selected){
          resultado = this.selected.length === this.options.length
        }
        return resultado;
      },
      likesSome () {
        let resultado = false
        if(this.selected){
          resultado = this.selected.length > 0 && !this.likesAll
        }
        return resultado;
      },
      icon () {
        if (this.likesAll) return 'mdi-close-box'
        if (this.likesSome) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
  },
  watch: {
    itemsChecked(){
      this.selected = this.itemsChecked
    },
  }

}
</script>

<style lang="scss" scoped>
.multiSelect{
  height: 32px;
  .typeRestaurantSelected{
    font-size: 0.75rem;
    color: #4A90E2;
  }
}
span{
  font-size: 0.75rem;
}
.v-chip.tipo{
    width: 15px;
    height: 15px;
    display: block;
    border-radius: 3px;
    padding:0;
    margin-right: 10px;
  }
.selectAll{
  padding:0 16px;
  text-align: left;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1rem;
  margin:10px 0;
  .iconSelectAll{
    margin-right: 12px;
  }
}
.filtrar{
  text-align: center;
  border-top: 1px solid #E2E2E2;
  button{
    background-color: #00ADFC;
    color:#fff;
    padding:5px 40px;
    margin:10px 0;
    font-weight: 600;
  }
}
</style>