<template lang="pug">
    div.margin-bottom-0.col-6
        skeleton-card(v-if="isLoading")        
        v-card.gridFull.ma-0(v-if="!isLoading")
            .d-flex.headTitleGrid
                .block-title.titleGrid
                    translate.mb-0.title-card Opinion websites
                .block-help.ml-auto
                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="40" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                        template(v-slot:activator="{ on, attrs }")
                            img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                        span(v-if="!isAdverbia") {{helpOpinionWebs}}
                        span(v-else) {{ helpOpinionWebsAdverbia }}
                    v-icon.mb-1(v-if="typeNode!=='group'" @click="goFeedback()") mdi-chevron-right
                
            .card-content
                .row.text-left
                    #tableWebOpiniones.tableGlobal
                        div.headerTableRanking.row.ma-0
                            div.headerItem.col-name
                                p
                            div.headerItem.col-nopiniones
                                p.mb-0 #[translate Nº of opinions]
                            div.headerItem.col-nlocales
                                p.mb-0 #[translate No. of locals]
                            div.headerItem.col-puntuacion
                                p.mb-0 #[translate Punctuation]

                        div.contentTableRanking.row.ma-0
                            p.ml-3(v-if="!data") #[translate No data]
                            div.row.ma-0(v-if="data[0] && data[0].rating")
                                div.itemContent.col-12.pa-0(v-for="(item, index) in data[0].rating" :key="index")
                                    div.item.col-name
                                        .boxLogo
                                            img(:src="item.logo")
                                        span {{item.name}}
                                    div.item.col-nopiniones.text-right
                                        span.mb-0.sNumber {{item.count | formatNumber}}
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.mb-0.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.count, item.tend_count)")
                                            span {{ item.tend_count | formatNumber }}
                                    div.item.col-nlocales.text-right
                                        span.mb-0 {{item.text_count | formatNumber}}
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.mb-0.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.text_count, item.tend_text_count)")
                                            span {{ item.tend_text_count | formatNumber }}
                                    div.item.col-puntuacion.text-right
                                        span.mb-0.sNumber(:class="getColorNote(item.rating_global)") {{ item.rating_global | formatNumber }}
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.mb-0.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.rating_global, item.tend_rating_global)")
                                            span {{ item.tend_rating_global | formatNumber }}

</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import commonMixins from "@/mixins/common.js";

export default {
    props:['data'],
    mixins: [commonMixins],
    components: { SkeletonCard },
    data(){
        return {
            helpOpinionWebs: this.$gettext('Depending on the selected period and the filters, the websites where opinions have been received during that period are shown and the trend is calculated with respect to the same period of the previous year. It is analyzed about the group of restaurants that marks the selection in the left menu.'),
            helpOpinionWebsAdverbia: 'En función del periodo seleccionado y de los filtros, se muestran las webs donde se han recibido opiniones durante ese periodo y se calcula la tendencia respecto al mismo periodo del año anterior. Se analiza sobre el grupo de negocios que marca la selección en el menú de la izquierda.'

        }
    },
}
</script>

<style lang="scss" scoped>
.tableGlobal{
 
    .headerTableRanking{
        .headerItem{
            text-align: right;
            font-size: 0.75rem;
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: right;                
            img{
                cursor: pointer;
            }
            
        }
    }
    .contentTableRanking{
        .itemContent{
            text-align: center;
            font-size: 0.875rem;
            border-top: 1px solid #9797973d;
            height: 41px;
            display: flex;
            align-items: center;
        }
    }
}
#tableWebOpiniones{
    .col-name{
        max-width: 35.33%;
        flex: 0 0 35.33%;
        margin-right: 2.5%;
        padding-left: 10px;
        text-align: left;
        img{
            height: 28px;

        }
        .boxLogo,span{
            display: inline-block;
            vertical-align: middle;
        }
        .boxLogo{
            width: 27%;
        }
    }
    .col-nopiniones{
        max-width: 18.47%;
        flex: 0 0 18.47%;
        margin-right: 4.5%;
        justify-content: right;
        .sNumber{
            min-width: 50%;
            display: inline-block;
        }
        .arrow{
            margin-left: 5%;
        }
    }
    .col-nlocales{
        max-width: 18.47%;
        flex: 0 0 18.47%;
        margin-right: 4.5%;
    }
    .col-puntuacion{
        max-width: 10.47%;
        flex: 0 0 10.47%;
        .sNumber{
            min-width: 50%;
            display: inline-block;
        }
        .arrow{
            margin-left: 9%;
        }
    }
}
    
</style>