import { render, staticRenderFns } from "./ClaimSACSuggestions.vue?vue&type=template&id=0fc7f825&scoped=true&lang=pug"
import script from "./ClaimSACSuggestions.vue?vue&type=script&lang=js"
export * from "./ClaimSACSuggestions.vue?vue&type=script&lang=js"
import style0 from "./ClaimSACSuggestions.vue?vue&type=style&index=0&id=0fc7f825&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fc7f825",
  null
  
)

export default component.exports