import { render, staticRenderFns } from "./InfoCompetence.vue?vue&type=template&id=fa4d9bd2&scoped=true"
import script from "./InfoCompetence.vue?vue&type=script&lang=js"
export * from "./InfoCompetence.vue?vue&type=script&lang=js"
import style0 from "./InfoCompetence.vue?vue&type=style&index=0&id=fa4d9bd2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa4d9bd2",
  null
  
)

export default component.exports