<template lang="pug">
    div
        date-picker(class="calendar-cn" v-model="calendar.date" :lang="calendar.lang" :format="calendar.format" type="date" :first-day-of-week="calendar.firstDayOfWeek" :disabled-date="notBeforeToday" range :clearable="calendar.clearable" not-before="2016-01-01" @input="changeCalendar" confirm)
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js";

export default {
    mixins: [commonMixins],
    data(){
        return{
            'calendar': {
                'before': this.$store.getters.getDateBefore,
                'after': this.$store.getters.getDateAfter,
                'clearable': false,
                'date':'',
                lang: {
                    formatLocale: {
                        firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                },
                'format': 'DD/MM/YYYY', // 'MMM YYYY'
                'firstDayOfWeek': 1
            }
        }
    },
    components: { DatePicker },
    created(){
        if (this.$store.getters.getDateBefore && this.$store.getters.getDateAfter) {
            this.calendar.date = [this.$moment(this.$store.getters.getDateAfter).toDate(), this.$moment(this.$store.getters.getDateBefore).toDate()]
        } else {
            this.calendar.date = [this.$moment().add({'months': -1}), this.$moment()]
        }
    },
    methods:{
        notBeforeToday(date) {
            return date > new Date(new Date().setHours(0, 0, 0, 0));
        },
        changeCalendar (value) {
            if (value.length === 2) {
                this.$store.commit(types.COMMON_DATE_AFTER, this.$moment(value[0]).format('YYYY-MM-DD'));
                this.$store.commit(types.COMMON_DATE_BEFORE, this.$moment(value[1]).format('YYYY-MM-DD'));
            }
        },
    },
    watch: {
        filtersDate(){
            this.updateUrl()
            this.calendar.date = [this.$moment(this.$store.getters.getDateAfter).toDate(), this.$moment(this.$store.getters.getDateBefore).toDate()]
            this.calendar.after = this.$moment(this.$store.getters.getDateAfter).format('YYYY-MM-DD') 
            this.calendar.before = this.$moment(this.$store.getters.getDateBefore).format('YYYY-MM-DD')

        }
    }    
}
</script>
<style scoped>
.mx-datepicker-range{
    max-width: 200px;
}
</style>