<template lang="pug">
  router-view
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>

</style>