<template>
    <span class="trypAdvisorRating">
        <span class="trypAdvisorRating--fill" :style="'width:' + percent + '%'">
        </span>
    </span>
</template>
<script>
export default {
    props: {
        nota: {
            default: 0,
            required: true
        }
    },
    computed: {
        percent(){
            return (this.nota * 100 / 5)
        }
    }
}
</script>
<style scoped>
.trypAdvisorRating {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 21.2 19' enable-background='new 0 0 21.2 19' xml:space='preserve'%3E%3Ccircle fill='none' stroke='%2300aa6c' stroke-width='2' stroke-miterlimit='10' cx='10.6' cy='9.5' r='7.5'/%3E%3C/svg%3E");
  background-repeat: repeat-x;
  display: inline-block;
  height: 16px;
  width: 81px;
  text-align: left;
}
.trypAdvisorRating, .trypAdvisorRating .trypAdvisorRating--fill {
    background-size: 16px;
}
.trypAdvisorRating--fill {
    background-repeat: repeat-x;
    display: inline-block;
    height: 100%;
    vertical-align: top;
    width: 100%;
    text-align: left;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 21.2 19' enable-background='new 0 0 21.2 19' xml:space='preserve'%3E%3Ccircle fill='%2300aa6c' stroke='%2300aa6c' stroke-width='2' stroke-miterlimit='10' cx='10.6' cy='9.5' r='7.5'/%3E%3C/svg%3E");
}
</style>