<template>
  <div>
    <div class="selectCenters">
      <div class="buscador">
        <span class="legend" v-if="isGenericEntity">{{ $gettext('Selecciona hasta 5 negocios para comparar') }}</span>
        <span class="legend" v-else>{{ $gettext('Selecciona hasta 5 locales para comparar') }}</span>
        <input type="text" v-model="searchText" class="" placeholder="Buscar" />
      </div>
      <div class="itemsDisponibles scrollCustom">
        <div class="w-full h-[277px] border border-[#94DAF6] rounded-1 p-1">
          <div class=" max-h-[235px] overflow-y-auto">
            <div class="px-1 text-13 flex items-center" :class="{ 'bg-[#F7F8FA]': index % 2 === 0 }"
              v-for="(item, index) in itemsFiltered" :key="index">
              <input type="checkbox" class="mr-1" :value="item.id" v-model="itemsSelected" :id="'worker' + index"
                :disabled="(itemsSelected.length + itemsActiveIds.length) >= 5 || itemsActiveIds.some(it => it === item.id)" />
              <label :for="'worker' + index">
                {{ item.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="acciones d-flex flex-column items-center justify-center">
        <div class="d-flex flex-column items-center">
          <span class="text-14">{{ $gettext('Añadir') }}</span>
          <button :class="{ 'bg-blue': itemsSelected && itemsSelected.length > 0 }" @click="addItems()">
            <svg xmlns="http://www.w3.org/2000/svg" width="10.414" height="18.828" viewBox="0 0 10.414 18.828">
              <g id="Icon_Arrow-Down" transform="translate(9.414 1.414) rotate(90)">
                <path id="Shape" d="M8,0,0,8l8,8" transform="translate(16) rotate(90)" fill="none" stroke="#fff"
                  stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
              </g>
            </svg>
          </button>
        </div>
        <div class="d-flex flex-column mt-3 items-center">

          <button :class="{ 'bg-blue': itemsSelectedRemove && itemsSelectedRemove.length > 0 }" @click="removeItems()">
            <svg xmlns="http://www.w3.org/2000/svg" width="10.414" height="18.828" viewBox="0 0 10.414 18.828">
              <g id="Icon_Arrow-Down" transform="translate(9 1.414) rotate(90)">
                <path id="Shape" d="M8,16,0,8,8,0" transform="translate(0 8) rotate(-90)" fill="none" stroke="#fff"
                  stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
              </g>
            </svg>
          </button>
          <span class="text-14">{{ $gettext('Quitar') }}</span>
        </div>
      </div>
      <div class="itemsSeleccionados scrollCustom w-[276px] flex mt-[45px]">
        <div class="w-full border border-[#94DAF6] rounded-1 p-1">
          <div class="max-h-[235px] overflow-y-auto">
            <div class="px-1 text-13 flex items-center" :class="{ 'bg-[#F7F8FA]': index % 2 === 0 }"
              v-for="(item, index) in itemsActive" :key="index">
              <input type="checkbox" class="mr-1" :value="item.id" v-model="itemsSelectedRemove"
                :id="'workerA' + index" />
              <label :for="'workerA' + index">
                {{ item.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="aplicar">
        <button @click="setItems">Comparar</button>
      </div>
    </div>
  </div>
</template>
  
<script>
import brandMixin from '@/mixins/brand'
export default {
  mixins: [brandMixin],
  props: {
    items: [],
    itemsChecked: []
  },
  data() {
    return {
      searchText: '',
      itemsSelected: [],
      itemsActiveIds: [],
      itemsSelectedRemove: []
    }
  },
  computed: {
    itemsFiltered() {
      if (this.items) {
        return this.items.filter(e => e.name.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1)
      }
      return []
    },
    itemsActive() {
      if (this.itemsActiveIds.length === 0) return []
      return this.items.filter(e => this.itemsActiveIds.includes(e.id))
    }
  },
  created() {
    this.itemsActiveIds = this.itemsChecked
  },
  methods: {
    addItems() {
      this.itemsActiveIds = this.itemsSelected.concat(this.itemsActiveIds)
      this.itemsSelected = []
    },
    removeItems() {
      this.itemsActiveIds = this.itemsActiveIds.filter(e => !this.itemsSelectedRemove.includes(e))
      this.itemsSelected = []
      this.itemsSelectedRemove = []
    },
    setItems() {
      this.$emit('setItems', this.itemsActiveIds)
    }
  },
  watch: {
    itemsChecked() {
      this.itemsActiveIds = this.itemsChecked
    }
  }

}
</script>

<style lang="scss">
.selectCenters {
  border: 1px solid #DBDBDB;
  border-radius: 8px;
  background-color: #FAFAFA;
  padding: 21px 10px;
  gap: 1.5rem;
  display: flex;

  .buscador {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .legend {
      font-size: 14px;
      font-style: italic;
    }

    input {
      background-color: #fff;
      border: 1px solid #707070;
      border-radius: 8px;
      padding: 8px;

      ::placeholder {
        color: #4A90E2;
      }
    }
  }

  .itemsDisponibles {
    width: 23%;
  }

  .acciones {
    width: 3%;

    button {
      background-color: #E1E1E1;
      border-radius: 4px;
      padding: 12px 22px;

      svg {
        max-width: none;
        width: 10px;
      }

      &.bg-blue {
        background-color: #1D73FF;
      }
    }
  }

  .itemsSeleccionados {
    width: 23%;
  }

  .itemsDisponibles,
  .itemsSeleccionados {
    background-color: #E8F8FE;
    border-radius: 4px;
    border: 1px solid #CCCCCC;
    font-size: 14px;
    padding: 10px;
    height: 148px;
    overflow-y: auto;
  }

  .aplicar {
    width: 7%;
    margin-right: 19%;
    display: flex;
    align-items: center;

    button {
      background-color: #3A84FF;
      color: #fff;
      border-radius: 4px;
      padding: 9px 20px;
    }
  }
}
</style>