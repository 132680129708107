<template lang="pug">
  div#gridPrincipal.height-100
    v-row.vertical-center.height-100
      v-col.text-left(md="12")
        div#imagenLogin
          img(:src="img.login")
        div#frmLogin  
          img#logo(:src="logoLogin")   
          div#frmForm
            p.cwhite 
              strong #[translate Do not you remember your password?]
            p.cwhite #[translate No problem! ;-)]
            p.cwhite(v-if="!this.statusOk") #[translate Tell us your email and we will send you a link so you can retrieve it.]
            p.mensajeError(v-if="this.errorMsgShow") {{ errorMsg }}
            strong(v-if="this.statusOk") {{ okMsg }}
            form
              div.margin-bottom-32(v-if="!this.statusOk")
                input.fieldWhite(type="email" v-model="email" :placeholder="placeholder.email" :class="{ 'errorField':errorEmail }" @focus="resetError")
                span.mensajeErrorCampo(v-if="errorEmail") #[translate This field is required]
              p
              v-row#recordarmeyOlvidado
                v-col.text-left(md="5")
                  div
                    button.cwhite(@click="home") < #[translate Return]
                v-col.text-right(md="7")
              div.text-center(v-if="!this.statusOk")
                button#entrar( type="button" @click="send" @keyup.enter="send") #[translate Send]
</template>
<script>
import SrvCommon from '@/services/common.js'
import mixinBrand from '@/mixins/brand.js'
export default {
  name: 'Restore',
  mixins: [mixinBrand],
  data () {
    return {
      img: {
        login: require('@/assets/img/portada.jpg'),
        logo: require('@/assets/img/logo-intro.svg')
      },
      error: null,
      email: null,
      errorMsg: false,
      errorEmail: false,
      errorPwd: false,
      errorMsgShow: false,
      statusOk: false,
      okMsg: false,
      placeholder: {
        'email': this.$gettext('Email'),
      },
    }
  },
  created () {
  },
  computed: {
    year () {
      return (new Date()).getFullYear()
    },
    config_app () {
      return this.$config.configApp
    }
  },
  methods: {
    send () {
      let email = this.email
      this.resetError()

      let RegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,4})+$/
      if (email === null || email === '' || !email.match(RegEx)) {
        this.errorMsg = true
        this.errorEmail = true
      }

      if (!this.errorMsg) {
          SrvCommon.restorePassword({email: email})
          .then(() => {
            // console.log(res)
            this.statusOk = true
            this.okMsg = this.$gettext('check your email for restore password')
          }, (error) => {
            this.errorMsgShow = true
            if (error.response.data.email) {
              this.errorMsg = error.response.data.email[0]
            } else {
              this.errorMsg = this.$gettext('Error! incorrect data')
            }
          })
      }
    },
    home () {
        this.$router.push({name: 'home'})
    },
    resetError () {
      this.errorMsgShow = false
      this.errorEmail = false
      this.errorMsg = false
    },
    autoLogin () {
      this.$store.commit('PENDING') // Para mostrar el preload
      this.$store.commit('LOGIN_SUCCESS')
      this.$store.commit('NOT_PENDING') // Para quitar el preload
    },
  }
}
</script>
<style lang="scss" scoped>
  
  #fraseBienvenida{
    text-align: center;
    font-size: 1.125rem;
  }
  #gridPrincipal{
    max-width: 1200px;
    margin: 0 auto;
  }
  #frmLogin,#imagenLogin{
    display: inline-block;
  }
  #frmLogin{
    width: 39%;
    margin-left: -16%;
    vertical-align: top;
    margin-top: 128px;
    #frmForm{
      max-width: 86%;
      margin: 0 auto;
      .errorField{
        border:2px solid #000;
        color:#000;
      }
      .mensajeError{
        font-weight: bold;
      }
      .mensajeErrorCampo{
        color:#000;
        font-weight: bold;
      }
    }
  }
  #imagenLogin{
    width: 71.2%; // 865 de 1150 
    text-align: left;
    margin-top: 50px;
  }
  #logo{
    margin-bottom: 40px;
    display:inline-block;
    max-width: 468px;
  }
  .fieldWhite{
    background-color: #fff;
    text-align: center;
    border-bottom:0;
    border-radius:5px;
    color:#000;
    max-width: 400px;
    max-height: 46px;
    font-size: 0.938rem;
  }
  .cwhite{
    color:#fff;
  }

  input{
    display:block;
    width: 100%;
    border:none;
    font-size:1.125rem;
    padding:16px 10px;
    &#recordar{
      display: inline;
      width: auto;
      border:none;
    }
  }
  .height-100{
    height:100%;
  }
  #entrar{
    color:#fff;
    border:1px solid #fff;
    border-radius:5px;
    padding:12px 50px;
    margin-top:32px;
    transition: all ease 0.3s;
    &:hover{
      background-color: #fff;
      color:#000;
    }
  }
  #recordarmeyOlvidado{
    .v-input--selection-controls{
      margin-top:0;
      padding-top: 0;
      .theme--light{
        color:#fff;
      }
    }
  }
  @media (max-width: 1024px) {
    #gridPrincipal {
      width: 93%;
    }
    #imagenLogin{
      display: none;
    }
    #frmLogin{
      margin: 0 auto;
      display: block;
      width: 100%;
    }
  }
</style>
