<template lang="pug">
    div.barthreecolors
        .negativeNeutral
            div.boxBar
                .barThree.barGreen(v-if="green !== null && green < 0" :style="'width: ' + percentAbsolute(green) + '%;'" :data-percent="greenLabel")
            div.boxBar
                .barThree.barOrange(v-if="orange !== null && orange < 0" :style="'width: ' + percentAbsolute(orange) + '%;'" :data-percent="orangeLabel")
            div.boxBar
                .barThree.barRed(v-if="red !== null && red < 0" :style="'width: ' + percentAbsolute(red) + '%;'" :data-percent="redLabel")
        .positive
            div.boxBar
                .barThree.barGreen(v-if="green !== null && green >= 0" :style="'width: ' + green + '%;'" :data-percent="greenLabel")
            div.boxBar
                .barThree.barOrange(v-if="orange !== null && orange >= 0" :style="'width: ' + orange + '%;'" :data-percent="orangeLabel")
            div.boxBar
                .barThree.barRed(v-if="red !== null && red >= 0" :style="'width: ' + red + '%;'" :data-percent="redLabel")

</template>
<script>

export default {
  name: "chartFeelingBars",
  props: ['percentGreen','percentOrange','percentRed'],
  data(){
      return {
          green: 0,
          orange: 0,
          red: 0,
          greenLabel: 0,
          orangeLabel: 0,
          redLabel: 0
      }            
  },
  created(){
    this.green = this.$options.filters.RoundedNumber(this.percentGreen);
    this.orange = this.$options.filters.RoundedNumber(this.percentOrange);
    this.red = this.$options.filters.RoundedNumber(this.percentRed);
    this.greenLabel = this.$options.filters.formatNumber(this.percentGreen);
    this.orangeLabel = this.$options.filters.formatNumber(this.percentOrange);
    this.redLabel = this.$options.filters.formatNumber(this.percentRed);
  },
  methods: {
    percentAbsolute(val){
        return Math.abs(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.barthreecolors{
    width: 100%;
    display: flex;
    .boxBar{
        height: 16px;
    }
    .barThree{
        height: 9px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin: 3px 0 0 0;
    }
    .barThree::after{
        content: attr(data-percent);
        position: absolute;
        top: 0;
        left:-40px;
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 0.75rem;
    }
    .negativeNeutral{
        width: 50%;
        text-align: right;
        border-right: 1px solid #707070;
        padding:0;
        .barOrange{
            background-color: #FCAE19;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            &.barThree::after{   
                left: -40px;
            }  
        }
        .barRed{
            background-color: #F36627;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            &.barThree::after{   
                left: -40px;
            }  
        }
        .barGreen{
            background-color: #89C445;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            &.barThree::after{   
                left: -40px;
            }     
        }
    }
    .positive{
        width: 50%;
        padding: 0;
        text-align: left;
        .barGreen{
            background-color: #89C445;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            &.barThree::after{   
                left: inherit;
                right: -40px;
            }     
        }
        .barOrange{
            background-color: #FCAE19;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            &.barThree::after{   
                left: inherit;
                right: -40px;
            }   
        }
        .barRed{
            background-color: #F36627;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            &.barThree::after{   
                left: inherit;
                right: -40px;
            }   
        }
    }
}

</style>