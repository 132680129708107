<template lang="pug">
div
    v-dialog.dialogEdit(v-model="show"
        width="680")
        v-card.dialogEdit
            v-card-text.pb-0
            v-container
                div.gr-loader-snipper--wrapper(v-if="loading")
                    span.gr-loader-snipper
                skeleton-card(v-if="loading")
                v-row#headerModalEdit.pt-3(v-if="!loading")
                    v-col(cols="10")
                        span(v-if="!isGenericEntity")  #[translate Restaurant images]
                        span(v-else) Imágenes del negocio
                    v-col.mt-5(cols="2")
                        img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                v-row#bodyModalEdit(v-if="!loading")
                    v-col(cols="12")
                        v-container( class="scrollCustom pt-0" style="height:400px;overflow-y:scroll")
                            v-row.infoSticky
                                Errors(v-if="errors" :errors="errors")
                                v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                                    div.messageError
                                        span {{ messageError ? messageError : $gettext('An error occurred while recording.') }}                                                                      
                                v-col.text-left.pb-0(cols="8")
                                    p
                                        span #[translate The photo size requirements are as follows:]
                                        br
                                        span #[translate Size: 250 × 250 - 5000 × 5000 pix]
                                        br
                                        span Tamaño categoría "Cover foto":  480 x 270 - 2120 x 1192 pix
                                        br
                                        span #[translate Weight: 10 KB - 5 MB]
                                v-col.text-right(cols="12")
                                    button(@click="addItem") Añadir foto

                            v-row.item(v-for="(item, index) in items" :key="item.hash")
                                v-col(cols="2")
                                    button.removePhoto.ml-2(v-if="items.length > 1" @click="removeItem(item)")
                                        img.remove(:src="imgtrash" width="20")
                                v-col.pb-0.d-flex.items-center(cols="10")
                                    img.preview(v-if="item.url" :src="item.url" :id="'img' + index")                                        
                                v-col(cols="2")
                                v-col.text-left(cols="10")                                    
                                    v-row                                        
                                        v-col(md="6")                                                
                                            v-file-input.addPhoto(
                                                :rules="rules"
                                                @change="Preview_image(item)"
                                                v-model="item.image"
                                                id="file" ref="file"
                                                accept="image/png, image/jpeg, image/bmp"
                                                :placeholder="placeholderAddPhoto"
                                                prepend-icon="mdi-camera"
                                                label="")
                                        v-col(md="6")
                                            span.titleSelectCategory #[translate Select a Category]:
                                            select.selectFullEditModal(v-model="item.category")
                                                option(v-for="cat in categoriesPhoto" :value="cat") {{ cat.name}}

                    //v-col.boxThumbnails(cols="12")
                        v-container
                            v-row
                                v-col.text-left(cols="12")                                    
                                    v-row                                        
                                        v-col(md="2" v-for="(photo, index) in data.googlephotos")
                                            .photo
                                                span {{ photo.category }}
                                                div.boxPhoto
                                                    img(:src="photo.photo_thumbnail_url")
                                                div.text-center.removePhoto
                                                    img(:src="imgtrash" @click="removePhoto(index)")



                v-row#footerModalEdit
                    v-col(cols="12")
                        button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
                        button.btnSaveEditModal(@click="showConfirm = true")  #[translate Save]
    modal-confirm-multiple-edit(:visible="showConfirm"  @close="showConfirm=false" @confirmed="save()")
    warning-state-custom(:visible="showLastMessage"  @close="showLastMessage=false" @confirmed="showLastMessage=false")
        p #[translate Comprueba el estado de la subida de imagenes en la sección de estado de tareas]
        router-link(:to="{name: 'LocalStatusTasks', params: {paramCOD: this.$route.params.paramCOD}, query: queryParams}") #[translate Ir a sección de estado de las tareas]
</template>
<script>
import commonMixins from '@/mixins/common.js'
import brandMixins from '@/mixins/brand.js'
import SrvLocal from '@/services/local.js'
import SkeletonCard from '@/components/common/skeletonCard.vue'
import ModalConfirmMultipleEdit from '@/components/local/confirmMultipleEdit.vue'
import Errors from '@/components/common/Errors.vue'
import warningStateCustom from './warningStateCustom.vue'

export default {
    mixins: [commonMixins, brandMixins],
    components: { SkeletonCard, ModalConfirmMultipleEdit, Errors, warningStateCustom },
    props: ['visible', 'idsBussinesModeMultiple', 'paramCOD'],
    data() {
        return {
            loading: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            noMap: require('@/assets/img/no-map.svg'),
            imgtrash: require('@/assets/img/ico-trash.svg'),
            name: '',
            url: null,
            image: null,
            showConfirm: false,
            data: {},
            categoriesPhoto: [],
            rules: [
                value => !value || value.size < 5000000 || this.$gettext('Avatar size should be less than 5 MB!'),
            ],
            file: '',
            items: [],
            saveStatus: true,
            messageError: null,
            showLastMessage: false,
            errors: null
        }
    },
    watch: {
        visible(newValue) {
            if (newValue) {
                this.items = []
                this.addItem()
                this.data = {}
                this.loadData()
            }
        }
    },
    computed: {
        placeholderAddPhoto() {
            return this.$gettext('Add a photo')
        },
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                } else {
                    this.addItem()
                }
            }
        }
    },
    methods: {
        addItem() {
            this.items.push({ hash: String(new Date().valueOf()), category: { name: "Otras", photo_type: "ADDITIONAL" }, image: null, url: null, file: null })
        },
        handleFileUpload() {
            this.file = this.$refs.file.input.files[0];
        },
        removeItem(key) {
            this.items.splice(key, 1)
        },
        Preview_image(item) {
            if (item.image) {
                item.url = URL.createObjectURL(item.image)
            } else {
                item.url = ''
            }
        },

        async loadData() {
            this.loading = true
            const res = await SrvLocal.getCategoriesPhoto(this.idsBussinesModeMultiple[0], true)
            if (res.status === 200) {
                this.categoriesPhoto = res.data
                this.loading = false
            }
        },
        async save() {
            this.loading = true
            let valid = true
            if (this.items.filter(e => e.category.photo_type == 'COVER').length > 0) {
                valid = false
                this.errors = 'No se puede subir más de una foto en la categoria Cover foto'
            }
            let somePhotoWithErrorSize = this.items.some((e, index) => {
                let img = document.querySelector('#img' + index)
                if (e.category.photo_type !== 'COVER' && (img.naturalHeight < 250 || img.naturalHeight > 5000 || img.naturalWidth < 250 || img.naturalWidth > 5000)) {
                    return true
                }
                /* minimum 480 x 270; maximum 2120 x 1192 */
                if (e.category.photo_type === 'COVER' && (img.naturalHeight < 270 || img.naturalHeight > 1192 || img.naturalWidth < 480 || img.naturalWidth > 2120)) {
                    return true
                }
            })
            if (somePhotoWithErrorSize) {
                this.errors = 'Se deben respetar los requisitos de las imagenes.'
                valid = false
            }
            if (valid) {
                try {
                    const formData = new FormData()
                    formData.append('img_details', JSON.stringify({ "center_ids": this.idsBussinesModeMultiple }));
                    formData.append('update_type', 'IMG');
                    this.items.filter(e => e.image).forEach(e => {
                        formData.append('images', e.image)
                        formData.append('categories', e.category.photo_type)
                    })

                    const res = await SrvLocal.setMultipleBatchUpdate(formData)
                    if (res.status === 200 || res.status === 201) {
                        this.showLastMessage = true;
                        this.$emit("successAction")
                    }
                } catch (e) {
                    this.loading = false
                }
            }
            this.loading = false
        }
    }
}
</script>
<style lang="scss" scoped>
.boxThumbnails {
    border-top: 1px solid #000;
}

.addPhoto {
    border: 2px solid #00ADFC;
    max-height: 53px;
    color: #00ADFC;
}

.preview {
    height: 73px;
    width: 80%;
}

.titleSelectCategory {
    font-weight: 600;
}

.boxPhoto {
    height: 65px;
    overflow: hidden;
}

.item {
    border-bottom: 1px solid #e6e3e3;

    .removePhoto {
        img {
            width: 20px;
            display: block;
            cursor: pointer;
        }
    }
}

.messageError {
    background-color: #F5B1B8;
    border: 1px solid #F93549;
    color: #F93549;
    padding: 15px;
}

.infoSticky {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
}
</style>