import stores from "@/store";
import * as types from "@/store/mutation-types";

/**
 * Process filters in order: Sites Opinions > Regions > Restaurant Types > Center Models
 */
export async function processFilter(query, routeName) {
  // PAGINAS DONDE NO CARGAMOS LOS FILTROS
  if (['LocalHome', 'LocalRestaurant', 'rateAnalysis', 'priceVariations','rateComparator','aggregatorsRank','provincesRank',
'promotionsAnalysis', 'promoComparator', 'promoNews', 'promoFinished','promoPizzas','GlobalGastroRanking'].includes(routeName)) return { queryHasChanged: false }

  let queryHasChanged = false

  await Promise.all([
    stores.dispatch('getSitesOpinions'),
    stores.dispatch('getRegions'),
    stores.dispatch('getTypesRestaurant'),
    stores.dispatch('getCenterModels')
  ])

  // Remove selected Region if doesn't exists on current Regions pool
  if (query.region) {

    const regions = stores.getters.getRegions.map(reg => String(reg.text))
    const selectedRegions = query.region.split(';').map(String)
    let result = []
    selectedRegions.forEach(item => {
      if (regions.includes(item)) {
        result.push(item)
      }
    })

    stores.commit(types.COMMON_REGION_SELECTED, result)
    if (!result.length) {
      delete query.region
      queryHasChanged = true
    } else if (result.join(';') !== query.region) {
      queryHasChanged = true
    }

  }

  // Remove selected Restaurant Types if doesn't exists on current Restaurant Types pool
  if (query.typeRestaurant) {
    const typesRestaurant = stores.getters.getTypesRestaurant.map(model => Number(model.value))
    const selectedTypesRestaurant = query.typeRestaurant.split(';').map(Number)
    let result = []
    selectedTypesRestaurant.forEach(item => {
      if (typesRestaurant.includes(item)) {
        result.push(item)
      }
    })

    stores.commit(types.COMMON_TYPES_RESTAURANT_SELECTED, result)

    if (!result.length) {
      delete query.typeRestaurant
      queryHasChanged = true
    } else if (result.join(';') !== query.typeRestaurant) {
      queryHasChanged = true
    }
  }

  // Remove selected Center Models if doesn't exists on current Center Models pool
  if (query.center_models) {
    const models = stores.getters.getCenterModels.map(model => Number(model.value))
    const selectedCenterModels = query.center_models.split(';').map(Number)
    let result = []
    selectedCenterModels.forEach(item => {
      if (models.includes(item)) {
        result.push(item)
      }
    })

    stores.commit(types.COMMON_CENTER_MODELS_SELECTED, result)

    if (!result.length) {
      delete query.center_models
      queryHasChanged = true
    } else if (result.join(';') !== query.center_models) {
      queryHasChanged = true
    }
  }

  
  return {queryHasChanged, query}
}
