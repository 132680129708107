<template lang="pug">
  div
      v-dialog.dialogEdit(v-model="show"
          width="527")
          v-card.dialogEdit
              v-card-text.pb-0
              v-container#modalLocalMenu
                  div.gr-loader-snipper--wrapper(v-if="loading")
                      span.gr-loader-snipper
                  v-row#headerModalEdit.pt-3
                      <svg class="close" xmlns="http://www.w3.org/2000/svg" width="12.607" height="12.607" viewBox="0 0 12.607 12.607"  @click.stop="show=false">
                            <path id="source_icons_cancel_4_" data-name="source_icons_cancel (4)" d="M6.758,17.243,12,12m5.243-5.243L12,12m0,0L6.758,6.757M12,12l5.243,5.243" transform="translate(-5.698 -5.697)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                      </svg>
                      v-col.d-flex.items-center(cols="10")
                        <svg xmlns="http://www.w3.org/2000/svg" width="26.39" height="33.5" viewBox="0 0 26.39 33.5">
                            <g id="Grupo_11506" data-name="Grupo 11506" transform="translate(-35.938 -425.333)">
                                <g id="Rectángulo_3052" data-name="Rectángulo 3052" transform="translate(36 425.333)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                <rect width="26" height="30" stroke="none"/>
                                <rect x="1" y="1" width="24" height="28" fill="none"/>
                                </g>
                                <line id="Línea_1402" data-name="Línea 1402" x2="16.393" transform="translate(41.183 431.567)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                <line id="Línea_1391" data-name="Línea 1391" x2="24.39" transform="translate(36.938 457.833)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                                <rect id="Rectángulo_3178" data-name="Rectángulo 3178" width="17" height="10" rx="4" transform="translate(41 435.833)" fill="#fff"/>
                            </g>
                        </svg>

                        span.ml-2.ch(v-if="!edit && !remove") {{ $gettext('Añadir sección') }}
                        span.ml-2.ch2(v-else-if="!edit && remove") {{ $gettext('Eliminar sección') }} {{  data.name }}
                        span.ml-2.ch3(v-else) {{ $gettext('Editar sección') }} {{  data.name }}
                        
                  v-row#bodyModalEdit
                      v-col(cols="12")
                          v-container
                              v-row(v-if="!remove")
                                div.w-full(v-if="createdOk")
                                    p.my-9.text-center.w-full Sección creada correctamente.
                                div.w-full(v-else-if="updatedOk")
                                    p.my-9.text-center.w-full Sección editada correctamente.
                                div.w-full(v-else)
                                  v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                                      div.messageError
                                          span {{ messageError ? messageError : $gettext('An error occurred while recording.') }}
                                  v-col.text-left.pt-6.pb-0(cols="12")
                                      label #[translate Nombre de la sección]
                                      input.d-block.w-full(type="text" v-model="data.name")
                                  v-col.text-left.pt-6.pb-0(cols="12")
                                      label #[translate Descripción]
                                      textarea.d-block.w-full(type="text" v-model="data.description")
                                  //v-col.text-left.d-flex.items-center.pt-6
                                    <v-checkbox id="visible" class="mr-2 my-0 py-0 d-inline" v-model="data.hide" hide-details value :ripple="false"></v-checkbox>
                                    label(for="visible") La sección es visible a los clientes
                              v-row(v-else)
                                    p.my-9.text-center.w-full(v-if="!removedOk") ¿Seguro que quieres eliminar la sección y todos sus platos?
                                    p.my-9.text-center.w-full(v-else) Sección eliminada correctamente.

                                  
                                  
                  v-row#footerModalEdit
                      v-col.d-flex.items-center.justify-center(cols="12" v-if="!removedOk && !updatedOk && !createdOk")
                        <ButtonAddItem v-if="!edit && !remove" @click.native="save()"><span class="text-14">Añadir sección</span></ButtonAddItem>
                        <ButtonCancel class="mr-3" v-if="remove && !removedOk" @click.native="remove = false;edit=true;">Cancelar</ButtonCancel>
                        
                        <ButtonRemoveItem v-if="edit && !remove" :isActive="remove" @click.native="remove = true;edit = false">Eliminar sección</ButtonRemoveItem>
                        <ButtonRemoveItem v-if="!edit && remove" :isActive="remove" @click.native="save()">Eliminar sección</ButtonRemoveItem>
                        <ButtonUpdateItem v-if="edit" class="ml-3" @click.native="save()">Actualizar sección</ButtonUpdateItem>
                      v-col.d-flex.items-center.justify-center(v-else)
                        <ButtonCancel class="mr-3"  @click.native="show = false">Aceptar</ButtonCancel>
                          //button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
                          //button.btnSaveEditModal(@click="save()")  #[translate Save]

</template>
<script>

import commonMixins from '@/mixins/common.js'
//import SrvLocal from '@/services/local.js'
import SrvLocalMenu from '@/services/localMenu.js'
import ButtonAddItem from '@/components/local/menu/ButtonAddItem.vue'
import ButtonUpdateItem from '@/components/local/menu/ButtonUpdateItem.vue'
import ButtonRemoveItem from '@/components/local/menu/ButtonRemoveItem.vue'
import ButtonCancel from '@/components/local/menu/ButtonCancel.vue'
export default {
  mixins: [commonMixins],
  components: {ButtonAddItem, ButtonUpdateItem, ButtonRemoveItem, ButtonCancel},
  props: {
    visible: {
        default: false,
        required: false
    },
    idBussines: {
        default: null
    },
    isEdit: {
        default: false,
        required: false,
        type: Boolean
    },
    isRemove: {
        default: false,
        required: false,
        type: Boolean
    },
    data: {
        default: () => ({id:null, name: null, description: null, hide:false})
        
    }
  },
  data(){
      return {
        removedOk: false,
        createdOk: false,
        updatedOk: false,
          loading: false,
          showAddEditSection: false,
          imgClose: require('@/assets/img/ico-close-edit.svg'),
          categories: [],
          priceRange: [],
          customCategories: [],
          googlePrimaryCategoryCustom: {},
          section: 'data-restaurant',
          saveStatus: true,
          messageError: null
      }
  },
  watch: {
      visible (newValue){
          if (newValue) {
              //this.data = {}
              this.removedOk = false
              this.createdOk = false
              this.updatedOk = false
              this.$emit('remove', false)
              if (this.data.id) {
                this.$emit('edit',true)
              } 
              //this.loadData()
          }
      }
  },
  computed: {
      show: {
          get () {
              return this.visible
          },
          set (value) {
              if (!value) {
              this.$emit('close')
              }
          }
      },
      remove: {
          get () {
              return this.isRemove
          },
          set (val) {
                this.$emit('remove', val)
          }
      },
      edit: {
          get () {
              return this.isEdit
          },
          set (val) {
                this.$emit('edit', val)
          }
      },
  },
  methods: {
      
      async save () {
        const dataCloned = Object.assign({}, this.data)
        let action = this.data.id ? 'updated' : 'created'
        if (this.remove) { 
            action = 'delete'
        }
        const res = await SrvLocalMenu.setSection(this.idBussines, {"menu":"Menú", "section": dataCloned}, action, action === 'delete' ? this.data.id : null)
        if (res.status === 201 || res.status === 200) {
            if (!res.data.items) {
                res.data.items = []
            }
            this.$emit('sectionCreatedOk', res.data)
        }
        if (res.status === 200) {
            this.updatedOk = true
        }
        if (res.status === 201) {
            this.createdOk = true
        }
        if (res.status === 204) {
            this.removedOk = true
            this.$emit('sectionDeleteOk', this.data.id)
        }
      }
  }
}
</script>
<style lang="scss" scoped>
  #autocompleteCategories{
      background-color: #fff;
      input{
          font-size: 0.875rem !important;
      }
  }
  .theme--light.v-text-field--solo-inverted.v-input--is-focused>.v-input__control>.v-input__slot {
      background-color: #fff;
  }
  .messageError{
      background-color: #F5B1B8;
      border: 1px solid #F93549;
      color: #F93549;
      padding:15px;
  }

</style>
