<template lang="pug">
    div
        v-dialog.dialogEdit(v-model="show"
            width="680")
            v-card.dialogEdit
                v-card-text.pb-0
                v-container
                    div.gr-loader-snipper--wrapper(v-if="loading")
                        span.gr-loader-snipper
                    v-row#headerModalEdit.pt-3
                        v-col(cols="10")
                            span(v-if="!isGenericEntity") #[translate Restaurant data]
                            span(v-else) Datos del negocio
                        v-col.mt-5(cols="2")
                            img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                    v-row#bodyModalEdit.scrollCustom(style="height:400px; overflow-y:scroll")
                        v-col(cols="12")
                            v-container
                                v-row
                                    v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                                        div.messageError
                                            span {{ messageError ? messageError : $gettext('An error occurred while recording.') }}
                                    v-col.text-left.pt-6.pb-0(cols="12")
                                        span #[translate Nombre de la empresa]
                                        input.inputFullEditModal(type="text" v-model="data.nameCompany")
                                    v-col.text-left.pt-6.pb-0(cols="12")
                                        span #[translate Sitio web]
                                        input.inputFullEditModal(type="text" v-model="data.urlWeb")
                                    v-col.text-left.pb-0(v-if="!isAdverbia" cols="12")
                                        span #[translate Enlace a la carta]
                                        input.inputFullEditModal(type="text" v-model="data.urlCarta")
                                    v-col.text-left.pb-0(cols="12")
                                        span #[translate Descripción]
                                        textarea.inputFullEditModal(v-model="data.description")
                                    v-col
                                        div(v-for="(type, index) in data.googleextraopendaytype")
                                            strong {{ type.hour_type ? type.hour_type : 'Horario de apertura' }}
                                            v-row.boxDay(v-for="(day, indexDay) in Object.keys(type.days)")
                                                v-col.text-left.pt-6.pb-0(cols="12")
                                                    strong.text-12 {{ type.days[day].name }}
                                                v-col.text-left.pt-0.pb-0(cols="4")
                                                    .optionsFullEditModal
                                                        input(type="radio" v-model="type.days[day].isclosed" :id="'cerrado' + day + 'Si'" value="false" :name="'cerrado' + day" ) 
                                                        label.ml-2(:for="'cerrado' + day + 'Si'") #[translate Open]    
                                                        input.ml-6(type="radio" v-model="type.days[day].isclosed" :id="'cerrado' + day + 'No'" value="true" :name="'cerrado' + day") 
                                                        label.ml-2(:for="'cerrado' + day + 'No'") #[translate Closed]
                                                v-col.text-left.pt-0.pb-0.pr-0(cols="5")
                                                    .rangeHours.mb-2(v-for="(hours, index) in type.days[day].times")
                                                        input.time(type="time" v-model="hours.starttime") 
                                                        span  - 
                                                        input.time(type="time" v-model="hours.endtime")
                                                        img.ml-2(:src="imgtrash" width="13" @click="deleteHours(type.days[day],index)")
                                                v-col.btnAddHours.text-right.pt-3.pb-5(cols="3")
                                                    span(@click="addMoreHours(type.days[day])") #[translate Add more hours]
                                                        img.ml-2.pt-3(:src="imgPlusHours")
                                        hour-types(v-if="!isAdverbia" :dataProfile="data" :hasPremium="true" @onCreateSchedul="initSchedul")                
                    v-row#footerModalEdit
                        v-col(cols="12")
                            button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
                            button.btnSaveEditModal(@click="showConfirm = true")  #[translate Save]
        ModalConfirmMultipleEdit(:visible="showConfirm"  @close="showConfirm=false" @confirmed="save" :isDetails="true")
</template>
<script>
import commonMixins from '@/mixins/common.js'
import SrvLocal from '@/services/local.js'
import hourTypes from '@/components/local/hourTypes.vue'
import ModalConfirmMultipleEdit from '@/components/local/confirmMultipleEdit.vue'
export default {
    mixins: [commonMixins],
    props: ['visible','idsBussines'],
    components: {hourTypes, ModalConfirmMultipleEdit},
    data(){
        return {
            loading: false,
            showConfirm: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            data: {nameCompany: null, urlWeb: null, urlCarta: null, description: null, googleextraopendaytype: [{hour_type: null, days: {"MONDAY":{"name":"LUNES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"TUESDAY":{"name":"MARTES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"WEDNESDAY":{"name":"MIÉRCOLES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"THURSDAY":{"name":"JUEVES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"FRIDAY":{"name":"VIERNES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"SATURDAY":{"name":"SÁBADO","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"SUNDAY":{"name":"DOMINGO","isclosed":false,"times":[{"starttime":null,"endtime":null}]}}}]},
            categories: [],
            priceRange: [],
            customCategories: [],
            googlePrimaryCategoryCustom: {},
            section: 'data-restaurant',
            saveStatus: true,
            messageError: null,
            imgtrash: require('@/assets/img/ico-trash.svg'),
            imgPlusHours: require('@/assets/img/ico-plus-hours.svg'),
        }
    },
    watch: {
        visible (newValue){
            if (newValue) {
                ///this.data = {}
            }
        }
    },
    computed: {
        show: {
            get () {
                return this.visible
            },
            set (value) {
                if (!value) {
                this.$emit('close')
                }
            }
        },        
    },
    methods: {
        addMoreHours (item) {
            if (item.times && item.times.length < 4) {
                item.times.push({endtime: "",starttime: ""})
            }
        },
        deleteHours (item, index) {
            item.times.splice(index,1)
        },
        initSchedul(item){
            this.data.googleextraopendaytype.push({hour_type: item.localize_display_name, days: {"MONDAY":{"name":"LUNES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"TUESDAY":{"name":"MARTES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"WEDNESDAY":{"name":"MIÉRCOLES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"THURSDAY":{"name":"JUEVES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"FRIDAY":{"name":"VIERNES","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"SATURDAY":{"name":"SÁBADO","isclosed":false,"times":[{"starttime":null,"endtime":null}]},"SUNDAY":{"name":"DOMINGO","isclosed":false,"times":[{"starttime":null,"endtime":null}]}}})
        },
        async save () {
            let actions = []
            if (this.data.description) {
                actions.push({"section": "details-restaurant", "mask": "profile", "tree": this.$store.getters.getTreeSelected.id, "center_ids":this.idsBussines, "section_value":{"long_description":this.data.description}})
            }
            if (this.data.nameCompany) {
                actions.push({"section": "data-restaurant", "mask": "title", "tree": this.$store.getters.getTreeSelected.id, "center_ids":this.idsBussines, "section_value":{"name": this.data.nameCompany}})
            }
            if (this.data.urlWeb) {
                actions.push({"section": "data-restaurant", "mask": "websiteUri", "tree": this.$store.getters.getTreeSelected.id, "center_ids":this.idsBussines, "section_value":{"url":this.data.urlWeb}})
            }
            if (this.data.urlCarta) {
                actions.push({"section": "social-media-restaurant", "mask": "attributes/url_menu", "tree": this.$store.getters.getTreeSelected.id, "center_ids":this.idsBussines, "section_value":{"menu_url":this.data.urlCarta}})
            }

            
            if (this.data.googleextraopendaytype.filter(e => !e.hour_type).length > 0) {
                let hours = this.data.googleextraopendaytype.filter(e => !e.hour_type)[0]
                let edited = false
                Object.keys(hours.days).forEach(e => {
                    hours.days[e].times.forEach(h => {if (h.starttime || h.endtime) { edited = true}})
                })
                if (edited){
                    actions.push({"section": "hours-restaurant", "mask":"regularHours", "tree": this.$store.getters.getTreeSelected.id, "center_ids":this.idsBussines, "section_value": {googleopenday: hours.days}})
                }
            }
            if (this.data.googleextraopendaytype.filter(e => e.hour_type).length > 0) {
                let hours = this.data.googleextraopendaytype.filter(e => e.hour_type)
                let editedHours = []
                hours.forEach(hourType => {
                    let edited = false
                    Object.keys(hourType.days).forEach(e => {
                        hourType.days[e].times.forEach(h => {if (h.starttime || h.endtime) { edited = true}})
                    })
                    if (edited){
                        editedHours.push(hourType)
                    }
                })
                
                actions.push({"section": "morehours", "mask": "moreHours", "tree": this.$store.getters.getTreeSelected.id, "center_ids":this.idsBussines, "section_value": {googleextraopendaytype: editedHours}})
            }
            this.loading = true
            
            await Promise.all(actions.map((e) => {
                return SrvLocal.setUpdateInfo(e)
            }))
            this.loading = false
            

            /*
            if (this.saveStatus) {
                const res = await SrvLocal.syncBunsiness(this.idBussines)
                if (Object.keys(res.data).includes('success') && !res.data.success) {
                    this.saveStatus = false
                    this.messageError = res.data.error.message
                } else {
                    this.show = false
                    this.$emit('refreshSync')
                }
            }
            this.loading = false*/
        }
    }
}
</script>
<style lang="scss" scoped>
    #autocompleteCategories{
        background-color: #fff;
        input{
            font-size: 0.875rem !important;
        }
    }
    .theme--light.v-text-field--solo-inverted.v-input--is-focused>.v-input__control>.v-input__slot {
        background-color: #fff;
    }
    .messageError{
        background-color: #F5B1B8;
        border: 1px solid #F93549;
        color: #F93549;
        padding:15px;
    }
</style>
