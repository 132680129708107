<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="800px" height="800px"
        viewBox="0 0 64 64" aria-hidden="true" role="img" class="iconify iconify--emojione"
        preserveAspectRatio="xMidYMid meet">
        <path d="M17 58V6C8 11.2 2 20.9 2 32s6 20.8 15 26z" fill="#699635">
        </path>
        <path d="M32 2c-5.5 0-10.6 1.5-15 4v52c4.4 2.6 9.5 4 15 4c16.6 0 30-13.4 30-30S48.6 2 32 2" fill="#ed4c5c">
        </path>
        <g fill="#ffe62e">
            <path
                d="M17 20c-6.6 0-12 5.4-12 12s5.4 12 12 12s12-5.4 12-12s-5.4-12-12-12m0 22.5c-5.8 0-10.5-4.7-10.5-10.5S11.2 21.5 17 21.5S27.5 26.2 27.5 32S22.8 42.5 17 42.5">
            </path>
            <path
                d="M21.1 43.7c-.2.2-.5.2-.7 0L6.7 25.6c-.2-.2-.2-.5 0-.7l.3-.3c.2-.2.5-.2.7 0l13.7 18.1c.2.2.2.5 0 .7l-.3.3">
            </path>
            <path d="M17.2 19.5c.3 0 .5.2.5.5v24c0 .3-.2.5-.5.5h-.5c-.3 0-.5-.2-.5-.5V20c0-.3.2-.5.5-.5h.5">

            </path>

            <path d="M28.9 33.3L17 31.2v1.5l11.7 2.1c.3 0 .5-.1.6-.4l.1-.5c0-.2-.2-.5-.5-.6">

            </path>

            <path d="M17 31.1l-9.4-6.6c-.2-.2-.5-.1-.7.1l-.3.4c-.2.2-.1.5.1.7L17 32.9v-1.8">

            </path>

            <path d="M16.6 30l-9.4 9.4c-.2.2-.2.5 0 .7l.4.4c.2.2.5.2.7 0l8.3-8.3V30">

            </path>

            <path d="M17 25.5L4.5 31.4c-.2.1-.4.4-.2.7l.2.5c.1.2.4.4.7.2L17 27.2v-1.7">

            </path>

            <path d="M28.1 27.2l-11.9-2.1v1.5l11.7 2.1c.3 0 .5-.1.6-.4l.1-.5c-.1-.3-.3-.6-.5-.6">

            </path>

            <path d="M16.9 38.9c-.2.1-.4.4-.2.7l.2.5c.1.2.4.4.7.2l10-4.6l.5-1.9l-11.2 5.1">

            </path>

            <path d="M16.9 35.5L5.7 31.4V33l11.2 4.1z">

            </path>

            <path d="M9.1 38.8v1.5l14.8 1c.6-.4 1.1-.9 1.6-1.4L9.1 38.8">

            </path>

        </g>

        <path d="M12 27v7c0 2.8 2.2 5 5 5s5-2.2 5-5v-7H12" fill="#ffffff">

        </path>

        <path d="M17 41c-3.9 0-7-3.1-7-7v-9h14v9c0 3.9-3.1 7-7 7m-3-12v5c0 1.7 1.3 3 3 3s3-1.3 3-3v-5h-6" fill="#ed4c5c">

        </path>

        <g fill="#0071bc">

            <path d="M16.1 29.4V31c0 .3.2.5.5.5h.8c.3 0 .5-.2.5-.5v-1.7h-1.8z">

            </path>

            <path d="M16.1 31.9v1.7c0 .3.2.5.5.5h.8c.3 0 .5-.2.5-.5v-1.7h-1.8">

            </path>

            <path d="M14.1 31.9v1.7c0 .3.2.5.5.5h.8c.3 0 .5-.2.5-.5v-1.7h-1.8">

            </path>

            <path d="M18.1 31.9v1.7c0 .3.2.5.5.5h.8c.3 0 .5-.2.5-.5v-1.7h-1.8">

            </path>

            <path d="M16.1 34.3V36c0 .3.2.5.5.5h.8c.3 0 .5-.2.5-.5v-1.7h-1.8">

            </path>

        </g>

        <g fill="#ffffff">

            <circle cx="17" cy="32.9" r=".2">

            </circle>

            <circle cx="16.6" cy="33.4" r=".2">

            </circle>

            <circle cx="17.4" cy="33.4" r=".2">

            </circle>

            <circle cx="16.6" cy="32.4" r=".2">

            </circle>

            <circle cx="17.4" cy="32.4" r=".2">

            </circle>

            <circle cx="17" cy="30.4" r=".2">

            </circle>

            <circle cx="16.6" cy="30.9" r=".2">

            </circle>

            <circle cx="17.4" cy="30.9" r=".2">

            </circle>

            <circle cx="16.6" cy="29.9" r=".2">

            </circle>

            <circle cx="17.4" cy="29.9" r=".2">

            </circle>

            <circle cx="19" cy="32.9" r=".2">

            </circle>

            <circle cx="18.6" cy="33.4" r=".2">

            </circle>

            <circle cx="19.4" cy="33.4" r=".2">

            </circle>

            <circle cx="18.6" cy="32.4" r=".2">

            </circle>

            <circle cx="19.4" cy="32.4" r=".2">

            </circle>

            <circle cx="15" cy="32.9" r=".2">

            </circle>

            <circle cx="14.6" cy="33.4" r=".2">

            </circle>

            <circle cx="15.4" cy="33.4" r=".2">

            </circle>

            <circle cx="14.6" cy="32.4" r=".2">

            </circle>

            <circle cx="15.4" cy="32.4" r=".2">

            </circle>

            <circle cx="17" cy="35.4" r=".2">

            </circle>

            <circle cx="16.6" cy="35.9" r=".2">

            </circle>

            <circle cx="17.4" cy="35.9" r=".2">

            </circle>

            <circle cx="16.6" cy="34.9" r=".2">

            </circle>

            <circle cx="17.4" cy="34.9" r=".2">

            </circle>

        </g>

        <path fill="#ffe62e"
            d="M16 28h2l-.2-1v-1h.2v-.5h-.3v.3h-.1v-.3h-.3v.3h-.2v-.3h-.2v.3h-.2v-.3h-.3v.3h-.2v-.3H16v.5h.2v1z">

        </path>

        <g fill="#3e4347">

            <path d="M17.3 28h-.1v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4h-.1v-.4c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.4">

            </path>

            <path d="M17 26.9h-.6v-.5c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.5m-.5-.1h.4v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4">

            </path>

            <path d="M17.6 26.9H17v-.5c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.5m-.5-.1h.4v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4">

            </path>

        </g>

        <path fill="#ffe62e"
            d="M11 28h2l-.2-1v-1h.2v-.5h-.3v.3h-.1v-.3h-.3v.3h-.2v-.3h-.2v.3h-.2v-.3h-.3v.3h-.2v-.3H11v.5h.2v1z">

        </path>

        <g fill="#3e4347">

            <path d="M12.3 28h-.1v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4h-.1v-.4c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.4">

            </path>

            <path d="M12 26.9h-.6v-.5c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.5m-.5-.1h.4v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4">

            </path>

            <path d="M12.6 26.9H12v-.5c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.5m-.5-.1h.4v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4">

            </path>

        </g>

        <path fill="#ffe62e"
            d="M11 33.9h2l-.2-1v-1h.2v-.5h-.2v.3h-.2v-.3h-.2v.3h-.3v-.3h-.2v.3h-.2v-.3h-.2v.3h-.2v-.3H11v.5h.3v1z">

        </path>

        <g fill="#3e4347">

            <path d="M12.3 33.9h-.1v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4h-.1v-.4c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.4">

            </path>

            <path d="M12 32.8h-.6v-.5c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.5m-.5-.1h.4v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4">

            </path>

            <path d="M12.7 32.8h-.6v-.5c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.5m-.5-.1h.4v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4">

            </path>

        </g>

        <path fill="#ffe62e"
            d="M21 34h2l-.2-1v-1h.2v-.4h-.3v.2h-.1v-.2h-.3v.2h-.2v-.2h-.2v.2h-.2v-.2h-.3v.2h-.2v-.2H21v.4h.2v1z">

        </path>

        <g fill="#3e4347">

            <path d="M22.3 34h-.1v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4h-.1v-.4c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.4">

            </path>

            <path d="M22 32.9h-.6v-.5c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.5m-.5-.1h.4v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4">

            </path>

            <path d="M22.6 32.9H22v-.5c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.5m-.5-.1h.4v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4">

            </path>

        </g>

        <path fill="#ffe62e"
            d="M21 28h2l-.2-1v-1h.2v-.5h-.3v.3h-.1v-.3h-.3v.3h-.2v-.3h-.2v.3h-.2v-.3h-.3v.3h-.2v-.3H21v.5h.2v1z">

        </path>

        <g fill="#3e4347">

            <path d="M22.3 28h-.1v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4h-.1v-.4c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.4">

            </path>

            <path d="M22 26.9h-.6v-.5c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.5m-.5-.1h.4v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4">

            </path>

            <path d="M22.6 26.9H22v-.5c0-.2.1-.3.3-.3c.2 0 .3.1.3.3v.5m-.5-.1h.4v-.4c0-.1-.1-.2-.2-.2s-.2.1-.2.2v.4">

            </path>

        </g>

        <path fill="#ffe62e"
            d="M14.2 39.6l1.4-1.5l-.9-.5l-.7-.7l.2-.2l-.3-.3l-.2.2l.2.2l-.1.1l-.2-.2l-.2.2l.2.1l-.2.2l-.1-.2l-.2.2l.2.2l-.2.1l-.1-.2l-.2.2l.1.2l-.1.1l-.1-.2l-.2.2l.3.3h.1l.1-.1l.7.7z">

        </path>

        <g fill="#3e4347">

            <path
                d="M15.1 38.6l-.4-.2c-.1-.1-.2-.1-.3 0c-.1.1-.1.2 0 .3l.3.3l-.1.1l-.3-.3c-.1-.1-.1-.3 0-.4s.3-.1.4 0l.4.2">

            </path>

            <path
                d="M14.1 38.1l-.4.4l-.3-.3c-.1-.1-.1-.3 0-.4s.3-.1.4 0l.3.3m-.4.3l.3-.3l-.3-.3c-.1-.1-.2-.1-.3 0s-.1.2 0 .3l.3.3">

            </path>

            <path
                d="M14.6 37.6l-.4.4l-.3-.3c-.1-.1-.1-.3 0-.4s.3-.1.4 0l.3.3m-.4.3l.3-.3l-.3-.3c-.1-.1-.2-.1-.3 0s-.1.2 0 .3l.3.3">

            </path>

        </g>

        <path fill="#ffe62e"
            d="M19.8 39.6l-1.4-1.5l.8-.5l.7-.7l-.1-.2l.3-.3l.2.2l-.2.2l.1.1l.2-.2l.2.2l-.2.1l.2.2l.1-.2l.2.2l-.2.2l.2.1l.1-.2l.2.2l-.2.2l.2.1l.1-.2l.2.2l-.3.3h-.1L21 38l-.7.7z">

        </path>

        <g fill="#3e4347">

            <path d="M18.9 38.6l.4-.2c.1-.1.2-.1.3 0s.1.2 0 .3l-.4.3l.1.1l.3-.3c.1-.1.1-.3 0-.4c-.1-.1-.3-.1-.4 0l-.3.2">

            </path>

            <path
                d="M20.2 37.8c.1-.1.3-.1.4 0c.1.1.1.3 0 .4l-.3.3l-.4-.4l.3-.3m.3.3c.1-.1.1-.2 0-.3c-.1-.1-.2-.1-.3 0l-.2.3l.3.3l.2-.3">

            </path>

        <path
            d="M19.7 37.3c.1-.1.3-.1.4 0c.1.1.1.3 0 .4l-.3.3l-.4-.4l.3-.3m.4.3c.1-.1.1-.2 0-.3c-.1-.1-.2-.1-.3 0l-.3.3l.3.3l.3-.3">

        </path>

    </g>

</svg></template>
    
<script>
export default {
    name: "flag-pt"
}
</script>