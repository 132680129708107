<template lang="pug">
  div
    v-dialog(v-model='open' scrollable='' max-width='800px' :persistent="true")
      v-card.facebook-locations(v-if="loggedIn" )
        v-toolbar(flat)
          v-toolbar-title {{ $gettext('Connect Facebook') }}
          v-spacer
        v-divider
        v-card-text.py-4.overflow-hidden
          v-text-field(label="Buscar" :disabled="!accountsFiltered.length" autofocus v-model='accountQuery' hide-details="true" solo)
        v-divider
        v-card-text(style='height: 300px;')
          div.gr-loader-snipper--modal(v-if="loading")
            span.gr-loader-snipper
          div(v-else)
            div.d-flex.pt-5.align-center(v-if="!accountsFiltered.length")
              span #[translate There's no records]
            v-radio-group(v-model='accountSelection' column='')
              v-radio(v-for="account in accountsFiltered" :label='account.name' :value='account.id')
                template(slot="label")
                  span.d-inline-flex.align-center.text--primary(:class="{ 'text--secondary': currentFacebookLinkeded[account.id], 'font-italic': currentFacebookLinkeded[account.id] }")
                    | {{ account.name }}
                    | ({{ account.id }})
                    | {{ currentFacebookLinkeded[account.id] ? `[${currentFacebookLinkeded[account.id].name}]` : '' }}
                    span.d-inline-flex.justify-center.align-center(v-if="account.findingLocations")
                      div.gr-loader-spinner-inline
                        span.gr-loader-spinner
                    span.ml-2.px-2.py-0.rounded-lg.bg-gray.color-blue.text-caption(v-else-if="account.hasLocations" @mousedown="showLocationsModal(account.id)")
                      | {{ $gettext('view more') }}
        v-divider
        v-card-actions.justify-center
          v-btn.py-2.my-1(color='primary' tile min-width="100" max-height="30" :disabled="!accountSelection" @click='linkFacebookFromAccounts')
            | {{ $gettext('Connect') }}


      v-card.facebook-locations(v-else)
        v-toolbar(flat)
          v-toolbar-title {{ $gettext('Connect Facebook') }}
          v-spacer
        v-divider
        v-card-text(style='height: 300px; display: flex; justify-content: center; align-items: center;')
          div.gr-loader-snipper--modal(v-if="loading")
            span.gr-loader-snipper
          div(v-else)
            div.d-flex.pt-5.align-center
              v-btn.py-2.my-1(color='primary' tile min-width="100" max-height="30" @click='login')
                | {{ $gettext('ACCESS') }}
      v-dialog(v-model='showLocations' scrollable='' max-width='1200px')
        v-card.facebook-locations
          v-toolbar(flat)
            v-toolbar-title Select Facebook Page
            v-spacer
            v-btn(icon @click='showLocations = false')
              v-icon mdi-close-circle-outline
          v-divider
          v-card-text.py-4.overflow-hidden
            v-text-field(label="Buscar" autofocus v-model='locationQuery' hide-details="true" solo)
          v-divider
          v-card-text(style='height: 500px;')
            div.gr-loader-snipper--modal(v-if="locationsLoading")
              span.gr-loader-snipper
            div(v-else)
              v-radio-group(v-model='locationSelection' column='')
                v-radio(v-for="account in locationsFiltered" :label='account.name' :value='account.id')
                  template(slot="label")
                    span.d-inline-flex.align-center.text--primary(:class="{ 'text--secondary': currentFacebookLinkeded[account.id], 'font-italic': currentFacebookLinkeded[account.id] }")
                      | {{ account.name }}
                      | {{ account.store_location_descriptor ? `[${account.store_location_descriptor}]` : '' }}
                      strong.ml-1 ({{ account.id }})
                      span.ml-1 {{ currentFacebookLinkeded[account.id] ? `[${currentFacebookLinkeded[account.id].name}]` : '' }}
          v-divider
          v-card-actions.justify-center
            v-btn.py-2.my-1(color='blue darken-1' outlined tile min-width="100" :disabled="processing" max-height="30" text='' @click='showLocations = false')
              | {{ $gettext('Close') }}
            v-btn.py-2.my-1(color='primary' tile min-width="100" max-height="30" :loading="processing" :disabled="!locationSelection || processing" @click='linkFacebookFromLocations')
              | {{ $gettext('Connect') }}
    v-dialog(v-model='noAccess' scrollable='' max-width='800px' :persistent="true")
      v-card.facebook-locations
        v-card-text(style='height: 300px; display: flex; justify-content: center; align-items: center;')
          div.gr-loader-snipper--modal(v-if="loading")
            span.gr-loader-snipper
          div(v-else)
            div.d-flex.pt-5.align-center
              img(src="https://img-9gag-fun.9cache.com/photo/a1rL3vY_460s.jpg")
    v-dialog(v-model='ready' scrollable='' max-width='800px' :persistent="true")
      v-card.facebook-locations
        v-toolbar(flat)
          v-toolbar-title {{ $gettext('Connect Facebook') }}
          v-spacer
        v-divider
        v-card-text(style='height: 300px; display: flex; justify-content: center; align-items: center;')
          div
            div.d-flex.pt-5.align-center(style="text-align: center")
              strong
                | {{ $gettext('Thank you, you can now return to the GastroRanking PRO app!') }}
    v-dialog(v-model='error' scrollable='' max-width='800px' :persistent="true")
      v-card.facebook-locations
        v-toolbar(flat)
          v-toolbar-title {{ $gettext('Connect Facebook') }}
          v-spacer
        v-divider
        v-card-text(style='height: 300px; display: flex; justify-content: center; align-items: center;')
          div
            div.d-flex.pt-5.align-center(style="text-align: center")
              strong
                | {{ $gettext('Oops, there was a problem, please contact us!') }}

</template>

<script>
/* global FB */
import {FacebookService} from "@/helper/facebook";

export default {
  name: "FacebookLocations",
  props: {
    appId: {
      type: String,
      default() {
        return '863241963700072'
      }
    },
    apiVersion: {
      type: String,
      default() {
        return 'v9.0'
      }
    },
    currentFacebookLinkeded: {
      type: Object,
      default() {
        return {}
      }
    },
    selected: {
      type: String,
      default() {
        return undefined
      }
    },
    center: {
      type: Number,
      default() {
        return undefined
      }
    }
  },
  data() {
    return {
      open: false,
      loading: false,
      locationsLoading: false,
      accounts: [],
      locations: [],
      loggedIn: false,
      showLocations: false,
      accountSelection: undefined,
      locationSelection: undefined,
      userToken: '',
      accountQuery: '',
      locationQuery: '',
      processing: false,
      ready: false,
      noAccess: false,
      facebookURL: undefined,
      error: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.url) {
        this.facebookURL = this.$route.query.url
        this.open = true
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 5000)
      } else {
        this.loading = false
        this.open = false
        this.noAccess = true
      }
    })
  },
  watch: {
    open: {
      immediate: true,
      async handler(value) {
        this.locations = []
        this.locationsLoading = true
        this.loading = true
        this.accounts = []
        await this.$nextTick(async () => {
          value && await this.load()
        })
      },
    }
  },
  methods: {
    async load() {
      this.loading = true
      this.processing = false
      const fb = new FacebookService(this.appId)
      await fb.initFacebookSdk()
      await this.getLoginStatus()
      this.loading = false
    },
    async linkFacebookFromAccounts() {
      this.processing = true

      const access_token = this.accountsFiltered.filter(item => item.id === this.accountSelection)[0]['access_token']
      const user_token = this.userToken
      const page_id = this.accountSelection
      const date = new Date('03 Aug 1978 00:00:00 PDT');

      const response = await fetch(this.facebookURL, {
        method: 'POST',
        headers: new Headers({
          'Authorization': '',
          'Content-Type': 'application/json;charset=UTF-8'
        }),
        body: JSON.stringify({
          access_token,
          page_id,
          facebook_user_token: user_token,
          access_token_last_update: date.toUTCString(),
          fb_user_access_token_last_update: date.toUTCString()
        })
      })

      if (response.status === 201) {
        //const data = await response.json()
        this.loading = false
        this.open = false
        this.noAccess = false
        this.ready = true
      } else {
        this.processing = false
        this.loading = false
        this.open = false
        this.noAccess = false
        this.ready = false
        this.error = true
      }
    },
    async linkFacebookFromLocations() {
      this.processing = true

      const access_token = this.locationsFiltered.filter(item => item.id === this.locationSelection)[0]['access_token']
      const user_token = this.userToken
      const page_id = this.locationSelection
      const date = new Date('03 Aug 1978 00:00:00 PDT');

      const response = await fetch(this.facebookURL, {
        method: 'POST',
        headers: new Headers({
          'Authorization': '',
          'Content-Type': 'application/json;charset=UTF-8'
        }),
        body: JSON.stringify({
          access_token,
          page_id,
          facebook_user_token: user_token,
          access_token_last_update: date.toUTCString(),
          fb_user_access_token_last_update: date.toUTCString()
        })
      })

      if (response.status === 200) {
        //const data = await response.json()
        this.loading = false
        this.open = false
        this.noAccess = false
        this.ready = true
      } else {
        this.processing = false
        this.loading = false
        this.open = false
        this.noAccess = false
        this.ready = false
        this.error = true
      }
      this.showLocations = false;
    },
    async showLocationsModal(pageId) {
      this.locationsLoading = true
      this.showLocations = true
      this.locations = []
      await this.getLocations(pageId)
      this.locationsLoading = false
    },
    getLoginStatus() {
      this.locationsLoading = true
      this.locations = []
      this.loading = true
      this.accounts = []
      return new Promise((resolve) => {
        FB.getLoginStatus(async ({authResponse}) => {
          if (authResponse) {
            this.loggedIn = true
            this.userToken = authResponse.accessToken
            await this.getAccounts()
          } else {
            this.locationsLoading = true
            this.locations = []
            this.loggedIn = false
          }
          resolve()
        }, true);
      })
    },
    async login() {
      FB.login(async (response) => {
          if (response.authResponse) {
            this.userToken = response.accessToken
            await this.getLoginStatus()
          } else {
            this.loading = false
            this.loggedIn = false
          }
      },
      {scope: 'public_profile,email,pages_read_engagement,pages_manage_engagement,instagram_basic,instagram_manage_comments,pages_show_list,business_management,pages_manage_metadata', auth_type: 'rerequest'})
    },
    getAccounts() {
      this.accountSelection = undefined
      this.loading = true
      this.accounts = []
      return new Promise((resolve) => {
        FB.api(
          "/me/accounts",
          (response) => {

            if(!response.data) return resolve()
            if(!response.data.length) return resolve()

            this.accounts = response.data.sort(function (a, b) {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
            })
            if (this.accounts.filter(item => item.id === this.selected).length) {
              this.accountSelection = this.selected
            }
            this.accounts.forEach((account) => {
              this.$set(account, 'findingLocations', true)
              this.$set(account, 'hasLocations', false)
              FB.api(
                `/${account.id}/locations?fields=name,access_token,store_location_descriptor&limit=1`,
                (response) => {
                  if (!response.error) {
                    this.$set(account, 'hasLocations', response.data.length > 0)
                  }
                  this.$set(account, 'findingLocations', false)
                  resolve()
                }
              );
            })
            this.loading = false
          }
        );
      })
    },
    getLocations(pageId) {
      this.locationSelection = undefined
      this.locationsLoading = true
      this.locations = []

      return new Promise((resolve) => {
        FB.getLoginStatus(async ({authResponse}) => {
          if (authResponse) {
            const locationsPromise = new Promise((resolve) => {
              FB.api(
                `/${pageId}/locations?fields=name,access_token,store_location_descriptor&limit=999999`,
                (response) => {

                  if(!response.data.length) {
                    this.locations = []
                    return resolve()
                  }

                  this.locations = response.data.sort((a, b) => {
                    const nameA = a.name.replace(/\s{2,}/g, ' ') + ' ' + String(a.store_location_descriptor).toUpperCase();
                    const nameB = b.name.replace(/\s{2,}/g, ' ') + ' ' + String(b.store_location_descriptor).toUpperCase();
                    return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
                  })
                  if (this.locations.filter(item => item.id === this.selected).length) {
                    this.locationSelection = this.selected
                  }
                  resolve()
                }
              );
            })
            resolve(locationsPromise)
          } else {
            await this.login()
          }
        }, true);
      })
    }
  },
  computed: {
    accountsFiltered() {
      if (isNaN(this.accountQuery)) {
        return this.accounts.filter(item => {
          return (String(item.name).toUpperCase()).normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(this.accountQuery.toUpperCase())
        })
      } else {
        return this.accounts.filter(item => {
          return String(item.id).includes(this.accountQuery)
        })
      }
    },
    locationsFiltered() {
      if (isNaN(this.locationQuery)) {
        return this.locations.filter(item => {
          return (item.name + ' ' + String(item.store_location_descriptor).toUpperCase()).normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(this.locationQuery.toUpperCase())
        })
      } else {
        return this.locations.filter(item => {
          return String(item.id).includes(this.locationQuery)
        })
      }
    },
    centerId() {
      return this.center
    }
  }
}
</script>

<style lang="scss">
.facebook-locations {
  .v-label {
    font-size: 0.8em;
  }
}
</style>
