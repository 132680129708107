<template lang="pug">
.card-content
    .row.text-left.mt-2
        chart-competence-last-year.mt-6.mb-6(:chartData="getChartData" style="width:100%;height:280px" :style="{'height': heightChart}")
        .legendBrand.d-flex.text-caption.mx-5.flex-wrap.mb-4
            span.d-flex.ml-2.mb-2(v-for="(item, index) in dataShow" @click="desactiveData(index)" :class="{'opacity-50':!item.show}") 
                .circleBrand(:style="'border-color:'+colorsChart[index]" :class="{'active': item.show}")
                span.ml-1 {{ item.name }}
</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import ChartCompetenceLastYear from '@/components/charts/CompetenceLastYear.vue'
import commonMixins from "@/mixins/common.js";
export default {
    props: { chartData: { type: Array }, xAxisKey: { type: String, default: "count_total" } },
    mixins: [commonMixins],
    components: { SkeletonCard, ChartCompetenceLastYear },
    data() {
        return {
            colorsChart: ["#000000", "#f93549", "#9723fe", "#f8e71c", "#979797", "#b51c32",
                "#123459", "#a12494", "#128844", "#876512", "#219374", "#435871", "#46b47c", "#a3b446", "#b446a7",
                "#e63737", "#730808", "#334a5d"]
        }
    },
    computed: {
        heightChart() {
            if (this.getChartData.datasets.length > 5)
                return '480px'
            return '280px'
        },
        getChartData() {
            let competence_datasets = []
            let labelsMonths = []
            if (this.chartData.length) {
                labelsMonths = this.chartData[0].months
            }

            let chartDataFiltered = this.chartData.filter(e => e.show)
            for (var i = 0; i < chartDataFiltered.length; i++) {
                var item = chartDataFiltered[i],
                    m_reviews = item.monthly_reviews,
                    itemdata = [];
                for (let j = 0; j < m_reviews.length; j++) {
                    let value = 0
                    if (this.xAxisKey !== "count_total" && Object.hasOwnProperty.call(m_reviews[j], this.xAxisKey)) {
                        value = m_reviews[j][this.xAxisKey] != 0 ? m_reviews[j][this.xAxisKey] : 0
                    } else {
                        value = m_reviews[j][this.xAxisKey] != 0 ? m_reviews[j][this.xAxisKey] / m_reviews[j].count_center : 0
                    }

                    itemdata.push(this.$options.filters.formatNumber(value, 1, 1).replace(',', '.'))
                }
                competence_datasets.push(
                    {
                        data: itemdata,
                        label: item.name,
                        fill: false,
                        borderColor: this.colorsChart[this.getColorByName(item.name)],
                        backgroundColor: "#FFF",
                        backgroundSize: 3,
                        pointBackgroundColor: "#FFF",
                        pointBorderColor: this.colorsChart[this.getColorByName(item.name)],
                        pointRadius: 4,
                        borderWidth: 2,
                        pointStyle: 'circle',
                        tension: 0
                    }
                )
            }
            return {
                labels: labelsMonths,
                datasets: competence_datasets
            }
        },
        dataShow() {
            return this.chartData
        }
    },
    methods: {
        desactiveData(index) {
            this.chartData[index].show = !this.chartData[index].show
        },
        getColorByName(name) {
            return this.chartData.findIndex(e => e.name === name)
        }
    },
    created() { },
    mounted() {
        let totals = []
        this.chartData.forEach((element, index) => {
            let acc = 0
            element.monthly_reviews.forEach(e => {
                acc += e[this.xAxisKey]
            })
            totals[index] = acc
        });
        let mainAndBrandExclude = totals.slice(2, totals.length)
        let minorBrand = Math.min(...mainAndBrandExclude)
        let mayorBrand = Math.max(...mainAndBrandExclude)
        let minorBrandIndex = mainAndBrandExclude.findIndex(e => e == minorBrand) + 2
        let majorBrandIndex = mainAndBrandExclude.findIndex(e => e == mayorBrand) + 2
        this.chartData.forEach((e, index) => {
            if (![0, 1, minorBrandIndex, majorBrandIndex].includes(index)) {
                e.show = false
            }
        })
    },
}
</script>

<style lang="scss" scoped>
.legendBrand {
    cursor: pointer;

    .circleBrand {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        border-style: solid;
        border-width: 2px;

        &.active {
            //background-color: black;
        }
    }
}
</style>
