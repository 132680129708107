<template lang="pug">
    div.col-6.margin-bottom-0(:class="{'col-12':data[0] && data[0].competence && data[0].competence.length > 4 || data.length > 4}" v-if="(typeNode === 'group' && data.length > 1) || (typeNode !== 'group' && data[0] && data[0].competence && data[0].competence.length > 1)" )
        skeleton-card(v-if="isLoading")
        v-card.gridFull.ma-0(v-if="!isLoading" :class="{'borderDelivery': typeOpinionSelected === 'delivery' || isDelivery}")
            
            .d-flex.headTitleGrid.d-flex
                .block-title.titleGrid
                    translate.mb-0.title-card Competence
                    span.ml-1.tDelivery(v-if="typeOpinionSelected === 'delivery' || isDelivery") Delivery
                .block-legend.d-flex.align-center
                    p.mx-3.mb-0.chip.chip-red
                    translate.color-red Brand
                    p.mx-3.mb-0.chip.chip-black
                    translate.color-black Average competence
                    
                .block-help.ml-auto
                    v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="30" nudge-left="10" content-class="tooltip-help tooltip-help-left")
                        template(v-slot:activator="{ on, attrs }")
                            img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                        span(v-if="!isAdverbia") {{HelpCompetence}}
                        span(v-else) {{ HelpCompetenceAdverbia }}
                v-icon.mb-1(v-if="typeNode!=='group'" @click="goNetworkCompetence(isDelivery ? 'delivery':'internet')") mdi-chevron-right

            .card-content
                #boxCompetenceGraph.row.text-left                 
                    v-col.col-3
                        p.color-red Valoración                         
                            br
                            span {{ getChartData.lines.brand.rating_global | formatNumber(1,1) }} 
                        p.text-12 Valoración #[br] media competencia      
                            br                  
                            span {{ infoSector.rating_global | formatNumber(1,1) }} 
                        p.color-red Nº de opiniones                        
                            br
                            span {{ getChartData.lines.brand.review_count | formatNumber(1,1) }}
                        p.text-12 Media nº opiniones#[br] competencia#[br] {{ infoSector.review_count | formatNumber(1,1) }}
                    v-col.col-9
                        chart-competence(:chartData="getChartData")

</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import commonMixins from "@/mixins/common.js";
import ChartCompetence from "@/components/charts/Competence.vue"
export default {
    props:['data', 'isDelivery'],
    components: { SkeletonCard, ChartCompetence },
    mixins: [commonMixins],
    data(){
        return {
            chartdataGlobal: null,
            HelpCompetence: this.$gettext('Comparative analysis of the indicators of each brand, with respect to the average of its competitors in the selected period. It is analyzed about the group of restaurants that marks the selection in the left menu and the filters applied.'),
            HelpCompetenceAdverbia: 'Análisis comparativo de los indicadores de cada marca, respecto a la media de sus competidores en el periodo seleccionado. Se analiza sobre el grupo de negocios que marca la selección en el menú de la izquierda y los filtros aplicados.'
        }
    },
    created(){

    },
    computed: {
        infoSector(){
            return this.getChartData.lines.sector
        },
        getChartData (){
            let labels = []
            let data = []
            let lines = {sector: {rating_global:null, review_count: null}, brand: {rating_global:null, review_count: null}}
            if (this.typeNode === 'group') {
                labels = this.data.filter((item,index) => index !== 0 && item.sector_avg_review_count)
                data = this.data.filter((item,index) => index !== 0 && item.sector_avg_review_count)
                lines.sector.rating_global = this.getNoteNormalized(data[0].sector_avg_rating_global)
                lines.sector.review_count = data[0].sector_avg_review_count
                lines.brand.rating_global = this.getNoteNormalized(data[0].review_rating_global)
                lines.brand.review_count = data[0].avg_review_count
                labels = labels.map(e => e.name)
                data = data.map(e => this.getNoteNormalized(e.review_rating_global))
            }
            if (this.typeNode === 'brand' || this.typeNode === 'center') {
                labels = [this.data[0], ...this.data[0].competence.filter((c,index) => index !== 0)]
                data =  [this.data[0], ...this.data[0].competence.filter((c,index) => index !== 0)]
                lines.sector.rating_global = this.getNoteNormalized(this.data[0].competence[0].avg_rating_global) || this.getNoteNormalized(this.data[0].competence[0].review_rating_global)
                lines.sector.review_count = this.data[0].competence[0].avg_review_count
                lines.brand.rating_global = this.getNoteNormalized(data[0].review_rating_global)
                lines.brand.review_count = data[0].avg_review_count
                labels = labels.map(e => e.name)
                data = [this.getNoteNormalized(data[0].review_rating_global), ...data.slice(1,data.length).map(e => this.getNoteNormalized(e.avg_rating_global) || this.getNoteNormalized(e.review_rating_global))]
            }
            let bgColors = data.map((e, index) => index === 0 ? '#F93549' : e > 8 ? '#4a91e3' : '#00A6D9' )
            return  {
                labels: labels,
                lines,
                datasets: [
                {
                  label: "",                  
                  data: data,
                  backgroundColor: bgColors
                }
              ]
            }
        },
        getAvgSector () {
            if(this.data[0] && this.data[0].competence){
                return {'sector_avg_review_count' : this.data[0].competence[0].avg_review_count, 'sector_avg_rating_global': this.getNoteNormalized(this.data[0].competence[0].avg_rating_global)}
            } else {
                return {'sector_avg_review_count' : 0, 'sector_avg_rating_global': 0}
            }
        }
    },
}
</script>

<style lang="scss" scoped>
#boxCompetenceGraph{
    padding: 24px;
}
.chip{
    width: 20px;
    height: 8px;
    border-radius: 15px;
    &.chip-blue{
        background-color: #00A6D9;
    }
    &.chip-black{
        background-color: #222226;
    }
    &.chip-red{
        background-color: #F93549;
    }
}
.color-blue{
    font-size: 0.75rem;
    color:#00A6D9;
}
.color-black{
    font-size: 0.75rem;
    color: rgba(22, 22, 22, 0.8);
}
.color-red{
    font-size: 0.75rem;
    color: #F93549;
}

</style>
