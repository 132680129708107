<!--
  Fecha: 05/01/2021
  Componente para pintar select con checkbox
  Propiedades
    options: array de objetos (Necesarios campos id y name)
  Eventos:
    checked: evento del componente padre
-->

<template lang="pug">
  div.multiSelect.oneSelect
    v-select(
            v-model="selected"
            :items="options"
            :placeholder="placeHolderAll"
            persistent-hint
            item-text="ssss"
            @input="filter()"
            item-value="text"
          )

      template(v-slot:selection="{ item, index }")
          p.mb-0.typeRestaurantSelected(v-if="index === 0") {{ item.text }}
          p.mb-0.ml-1.typeRestaurantSelected(v-if="index === 1" class="caption")  (+{{ selected.length - 1 }} )
      template(v-slot:item="{ active, item, attrs, on }")
          v-list-item(v-on="on" v-bind="attrs" #default="{ active }")
            v-list-item-action
              v-checkbox.sinHover(:input-value="active")
            v-list-item-content
              v-list-item-title
                v-row(no-gutters align="center")
                  v-chip(v-if="item.box" text-color="white" :color="item.box" class="tipo" small)
                  span {{ item.text }}
                  v-spacer

</template>

<script>
export default {
  name: 'OneSelect',
  props: ['options','itemsChecked', 'filterFor'],
  data() {
    return {
      selected: [],
    }
  },
  watch:{
    itemsChecked(){
      this.selected = this.itemsChecked
    }
  },
  methods: {
    filter: function(){
      this.$emit('checked', this.selected, this.filterFor)
    },
  },
  computed:{
      placeHolderAll() {
        return this.$gettext('All')
      },
      likesAll () {
        let resultado = false
        if(this.selected){
          resultado = this.selected.length === this.options.length
        }
        return resultado;
      },
      likesSome () {
        let resultado = false
        if(this.selected){
          resultado = this.selected.length > 0 && !this.likesAll
        }
        return resultado;
      },
      icon () {
        if (this.likesAll) return 'mdi-close-box'
        if (this.likesSome) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
  },
  created(){
    this.selected = this.itemsChecked;
  }
}
</script>

<style lang="scss" scoped>
.multiSelect{
  height: 32px;
  .typeRestaurantSelected{
    font-size: 0.75rem;
    color: #4A90E2;
  }
}
span{
  font-size: 0.75rem;
}
.v-chip.tipo{
    width: 15px;
    height: 15px;
    display: block;
    border-radius: 3px;
    padding:0;
    margin-right: 10px;
  }
.selectAll{
  padding:0 16px;
  text-align: left;
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1rem;
  .iconSelectAll{
    margin-right: 12px;
  }
}
.filtrar{
  text-align: center;
  border-top: 1px solid #E2E2E2;
  button{
    background-color: #00ADFC;
    color:#fff;
    padding:5px 40px;
    margin-top:10px;
    font-weight: 600;
  }
}
</style>
