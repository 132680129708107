<template lang="pug">
    div
        v-dialog.dialogEdit(v-model="show"
            width="680")
            v-card.dialogEdit
                v-card-text.pb-0
                v-container
                    div.gr-loader-snipper--wrapper(v-if="loading")
                        span.gr-loader-snipper
                    v-row#headerModalEdit.pt-3
                        v-col(cols="10")
                            span #[translate Borrar]
                        v-col.mt-5(cols="2")
                            img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                    v-row#bodyModalEdit
                        v-col(cols="12")
                            v-container
                                v-row
                                    v-col.text-left.pt-6.pb-4(cols="12")
                                        div
                                            slot                                    
                                    
                            

                    v-row#footerModalEdit
                        v-col(cols="12")
                            button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
                            button.btnSaveEditModal(@click="save()")  #[translate Aceptar]
</template>
<script>
import commonMixins from '@/mixins/common.js'
export default {
    mixins: [commonMixins],
    props: ['visible','idBussines'],
    data(){
        return {
            loading: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            imgCalendar: require('@/assets/img/local/calendar.svg'),
            name: '',
            data: {topicType: 'STANDARD', local_image: null, summary: null, actionType: null, actionTypeUrl: null},
        }
    },
    computed: {
        show: {
            get () {
                return this.visible
            },
            set (value) {
                if (!value) {
                this.$emit('close')
                }
            }
        }
    },
    methods: {
        async save () {
            this.$emit('removeAccepted')
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
    .boxDeliveryService{
        background-color: #F2F2F2;
        border-radius: 4px;
        width: 100%;
        margin: 6px;
        &.noService{
            .urlDeliveryService{
                color: #CECECE;
            }
        }
        img{
            vertical-align: middle;
        }
    }
    .long{
        height: 136px;
    }
    .messageError{
        background-color: #F5B1B8;
        border: 1px solid #F93549;
        color: #F93549;
        padding:15px;
    }
    #bodyModalEdit{
        .inputFullEditModal{
            &.textDisabled{
                background-color: #f8f8f8;
                color:#CECECE;
            }
        }
    }
    .icoCalendar{
        height: 36px;
    }
</style>
