
export default {
    path: '/surveys/:paramCOD?',
    name: 'HealthyPokeHome',
    component: () => import(/* webpackChunkName: "HealthyPokeHome" */ '../views/HealthyPokeHome/Home.vue'),
    meta: { Auth: true },
    props: true,
    children: [
      {
        path: '/surveys/satisfaction/:paramCOD?',
        name: 'HealthyPokeSatisfactionHome',
        component: () => import(/* webpackChunkName: "HealthyPokeSatisfactionHome" */ '../views/HealthyPokeHome/HealthyPokeSatisfactionHome/Home.vue'),
        meta: { Auth: true },
        redirect: '/surveys/satisfaction/indicators/:paramCOD?',
        props: true,
        children: [
          {
            path: '/surveys/satisfaction/indicators/:paramCOD?',
            name: 'HealthyPokeIndicatorsHome',
            component: () => import(/* webpackChunkName: "HealthyPokeIndicatorsHome" */ '../views/HealthyPokeHome/HealthyPokeSatisfactionHome/HealthyPokeIndicatorsHome/Home.vue'),
            meta: { Auth: true },
            props: true,
            children: []
          },
          {
            path: '/surveys/satisfaction/comments/:paramCOD?',
            name: 'HealthyPokeCommentsHome',
            component: () => import(/* webpackChunkName: "HealthyPokeCommentsHome" */ '../views/HealthyPokeHome/HealthyPokeSatisfactionHome/HealthyPokeCommentsHome/Home.vue'),
            meta: { Auth: true },
            props: true,
            children: []
          },
          {
            path: '/surveys/satisfaction/ranking/:paramCOD?',
            name: 'HealthyPokeRankingHome',
            component: () => import(/* webpackChunkName: "HealthyPokeRankingHome" */ '../views/HealthyPokeHome/HealthyPokeSatisfactionHome/HealthyPokeRankingHome/Home.vue'),
            meta: { Auth: true },
            props: true,
            children: []
          },
          {
            path: '/surveys/satisfaction/surveysqr/:paramCOD?',
            name: 'HealthyPokeSurveysHome',
            component: () => import(/* webpackChunkName: "HealthyPokeRankingHome" */ '../views/HealthyPokeHome/HealthyPokeSatisfactionHome/HealthyPokeSurveysHome/Home.vue'),
            meta: { Auth: true },
            props: true,
            children: []
          }
          
        ]
      },
    ]
  }