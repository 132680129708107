export default {
    path: '/delivery-reviews/:paramCOD?',
    name: 'DeliveryReviewsHome',
    component: () => import(/* webpackChunkName: "DeliveryReviewsHome" */ '../views/DeliveryReviews/Home.vue'),
    meta: { Auth: true, disabledForAdverbia: true },
    props: true,
    children: [
      {
        path: '/delivery-reviews/reviews/:paramCOD?',
        name: 'DeliveryReviewsReviews',
        component: () => import(/* webpackChunkName: "DeliveryReviewsReviews" */ '../views/DeliveryReviews/Reviews/Home.vue'),
        meta: { Auth: true, disabledForAdverbia: true, loadCategories: true },
        props: true,
      },
      {
        path: '/delivery-reviews/keywords/:paramCOD?',
        name: 'DeliveryReviewsKeywords',
        component: () => import(/* webpackChunkName: "DeliveryReviewsKeywords" */ '../views/DeliveryReviews/Keywords/Home.vue'),
        meta: { Auth: true, disabledForAdverbia: true, loadCategories: true },
        props: true,
      },
      {
        path: '/delivery-reviews/dish/:paramCOD?',
        name: 'DeliveryReviewsDish',
        component: () => import(/* webpackChunkName: "DeliveryReviewsKeywords" */ '../views/DeliveryReviews/Dish/Home.vue'),
        meta: { Auth: true, disabledForAdverbia: true, loadCategories: true },
        props: true,
      }
    ]
  }