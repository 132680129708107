// Stores
import Stores from '@/store/index'
// Importamos mutaciones
import * as types from '@/store/mutation-types'

// Config
import appConfig from '@/config-app.js'

// importamos servicios
//import SrvUsu from '@/services/users'

import axios from 'axios'
axios.defaults.baseURL = appConfig.api 
// axios.defaults.headers.common['Authorization'] = localStorage.getItem('_token')
/*let token = false;
if(window.localStorage.getItem('_token') || window.sessionStorage.getItem('_token')){
  token = true;
}*/
const user = {
  state: {
    isLoggedIn: false, // Si no esta el token en False y si lo esta es trueb
    user: null,
    pending: false,
    userName: ''
  },
  actions: {
    login ({commit}, user) {
      commit(types.PENDING) // Para mostrar el preload
      axios.defaults.headers.common['Authorization'] = ''
      return new Promise((resolve, reject) => {
        axios.post('/api-token-auth/', user)
          .then(res => {
            resolve(res)
          })
          .catch(error => {
            commit(types.NOT_PENDING)
            reject(error.response)
          })
      })
    },
    autoLogin ({commit}) {
      commit('PENDING') // Para mostrar el preload
      commit('LOGIN_SUCCESS')
      /*SrvUsu.getDetails()
        .then(res => {
          if (res.status === 200) {
            const user = res.data
            dispatch('setUser', user)
            commit('LOGIN_SUCCESS')
            commit('NOT_PENDING') // Para quitar el preload
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        }, () => {
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        })*/
    },
    logout ({commit}) {
      window.localStorage.removeItem('_token')
      window.sessionStorage.removeItem('_token')
      window.localStorage.removeItem('_user_info')
      window.sessionStorage.removeItem('_user_info')
      window.sessionStorage.removeItem('_user')
      window.localStorage.removeItem('_user')
      
      // Reseteamos el país
      commit(types.UPDATE_USER_COUNTRY, null)
      commit(types.LOGOUT)
    }, 
    
    updateCountry ({commit}, country) {
      Stores.dispatch('removeClient')
      commit(types.UPDATE_USER_COUNTRY, country)
    },
    setUser ({commit}, data) {
      commit(types.SET_USER, data)
    }
  },
  mutations: {
    [types.LOGIN_SUCCESS] (state) {
      state.isLoggedIn = true
    },
    [types.LOGOUT] (state) {
      state.isLoggedIn = false
    },
    [types.SET_USER] (state, data) {
      state.user = data
    },
    [types.PENDING] (state) {
      state.pending = true
    },
    [types.NOT_PENDING] (state) {
      state.pending = false
    },    
    [types.UPDATE_USER_COUNTRY] (state, data) {
      if (state.info && state.info.country) {
        state.info.country = data
      }
    },
    [types.USER_NAME] (state, data) {
      state.userName = data
    }
  },
  getters: {
    isLoggedIn: state => {
      return state.isLoggedIn
    },
    user: state => {
      return state.user
    },
    userInfo: state => {
      return state.info
    },
    role: state => {
      let userRole = false
      if (state.user) {
        userRole = state.user.role
      }
      return userRole
    },
    countries: state => {
      const country = {'id': 'eu', 'label': 'Europe'}
      let countries = [country]
      if (state.user && state.user.countries) {
        countries = [country, ...state.user.countries]
      }

      return countries
    },
    userName: state => {
      if(state.userName !== ''){
        return state.userName
      } else if (window.localStorage.getItem("_user")){
        return window.localStorage.getItem("_user")
      } else if (window.sessionStorage.getItem("_user")){
        return window.sessionStorage.getItem("_user")
      }
    },
  }
}

export default user
