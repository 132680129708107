<template>
    <div class="flex flex-col">
        <header class="px-4 relative">
            <svg xmlns="http://www.w3.org/2000/svg" width="199.456" height="35.851" viewBox="0 0 199.456 35.851">
                <g id="Grupo_100" data-name="Grupo 100" transform="translate(-147.333 -26.149)">
                    <g id="Grupo_97" data-name="Grupo 97" transform="translate(147.333 26.149)">
                    <path id="Trazado_1323" data-name="Trazado 1323" d="M1266.083,136.251a16.993,16.993,0,0,0,5.336,12.7,18,18,0,0,0,12.964,5.227,16.016,16.016,0,0,0,2.868-.217,17.41,17.41,0,0,0,10.053-5.01c.443-.431.883-.863,1.323-1.339,1.411-1.554,2.866-3.282,4.367-5.226l4.453-5.831-4.983-6.953a51.519,51.519,0,0,0-3.837-4.751q-.66-.648-1.323-1.3a17.152,17.152,0,0,0-10.053-5.011,16.012,16.012,0,0,0-2.868-.217,17.825,17.825,0,0,0-12.964,5.228A16.93,16.93,0,0,0,1266.083,136.251Z" transform="translate(-1266.082 -118.324)" fill="#f41938"/>
                    <path id="Trazado_1324" data-name="Trazado 1324" d="M1485,302.553a3.35,3.35,0,1,1,2.37.983A3.238,3.238,0,0,1,1485,302.553Zm5.723,2.421a3.354,3.354,0,0,1-3.352,3.358,3.2,3.2,0,0,1-2.366-.989.1.1,0,0,1-.029-.019v2.368a5.153,5.153,0,0,0,2.394.557,5.269,5.269,0,0,0,5.267-5.275V294.9l-1.915.96v.24a5.275,5.275,0,1,0,0,8.154Z" transform="translate(-1468.682 -284.367)" fill="#fff"/>
                    </g>
                    <g id="Grupo_98" data-name="Grupo 98" transform="translate(115.67 8.204)">
                    <path id="Trazado_1335" data-name="Trazado 1335" d="M90.587,31.011a5.038,5.038,0,0,1,3.577,1.672V31.228h1.588V41.866c0,3.886-2.585,5.753-5.618,5.753a6.729,6.729,0,0,1-3.909-1.173v-1.91a7.83,7.83,0,0,0,3.991,1.346c2.116,0,3.948-1.216,3.948-3.365V40.238a4.6,4.6,0,0,1-3.751,2.17c-2.814,0-5.082-2.279-5.082-5.579,0-3.582,2.268-5.818,5.257-5.818ZM87.3,37.7A3.548,3.548,0,0,0,90.489,40.6c2.085.133,3.7-1.6,3.675-4.009l-.021-2.084a4.007,4.007,0,0,0-3.213-1.78C88.274,32.727,86.838,35.281,87.3,37.7Z" transform="translate(0 -1.463)" fill="#fff"/>
                    <path id="Trazado_1336" data-name="Trazado 1336" d="M110.3,32.63V31.218h1.587V41.936H110.3V40.185a5.131,5.131,0,0,1-3.9,2.171c-2.8,0-5.059-2.279-5.059-5.58,0-3.582,2.258-5.818,5.232-5.818A5.673,5.673,0,0,1,110.3,32.63Zm-6.846,5.129a3.549,3.549,0,0,0,3.211,2.856c2.066.109,3.657-1.639,3.634-4.033L110.28,34.5a4.03,4.03,0,0,0-3.213-1.8C104.384,32.7,102.947,35.331,103.456,37.758Z" transform="translate(-3.038 -1.453)" fill="#fff"/>
                    <path id="Trazado_1337" data-name="Trazado 1337" d="M124.442,31.626v1.959a5.361,5.361,0,0,0-2.916-1.023c-1.4,0-2.092.8-2.092,1.514,0,.556.334,1.2,1.358,1.558l1.869.668a2.841,2.841,0,0,1,2.16,2.76c0,1.892-1.714,3.406-4.1,3.406a7.227,7.227,0,0,1-3.428-.98V39.528a6.819,6.819,0,0,0,3.294,1.157c1.4,0,2.271-.69,2.271-1.625,0-.712-.556-1.069-1.6-1.447l-1.647-.6a2.8,2.8,0,0,1-2.137-2.938c0-1.892,1.536-3.295,3.917-3.295A5.737,5.737,0,0,1,124.442,31.626Z" transform="translate(-6.063 -1.419)" fill="#fff"/>
                    <path id="Trazado_1338" data-name="Trazado 1338" d="M132.044,30.536h3.176v1.588h-3.176v6.361a1.27,1.27,0,0,0,1.377,1.455,3.2,3.2,0,0,0,1.8-.521v1.455a3.346,3.346,0,0,1-2.284.759,2.893,2.893,0,0,1-2.877-2.9V32.124h-1.985v-.517c1.45-.626,2.386-2.113,3.123-4.247h.847Z" transform="translate(-8.107 -0.771)" fill="#fff"/>
                    <path id="Trazado_1339" data-name="Trazado 1339" d="M145.693,31.316v2.127a2.75,2.75,0,0,0-1.617-.565c-1.506,0-2.326,1.194-3.146,2.649v6.419h-1.588V31.228h1.588V33.4a4.324,4.324,0,0,1,3.411-2.388A3,3,0,0,1,145.693,31.316Z" transform="translate(-10.244 -1.463)" fill="#fff"/>
                    <path id="Trazado_1340" data-name="Trazado 1340" d="M159.9,36.668a5.666,5.666,0,1,1-5.666-5.71A5.606,5.606,0,0,1,159.9,36.668Zm-9.487,0a3.824,3.824,0,1,0,3.821-3.972A3.8,3.8,0,0,0,150.417,36.668Z" transform="translate(-11.995 -1.453)" fill="#fff"/>
                    <path id="Trazado_1341" data-name="Trazado 1341" d="M173.246,30.847v4.147a3.028,3.028,0,0,0-1.743-.521,2.557,2.557,0,0,0-2.227,1.65v5.731h-3.97V30.739h3.97v1.976c.673-1.173,1.408-2.193,2.752-2.193A2.494,2.494,0,0,1,173.246,30.847Z" transform="translate(-15.169 -1.371)" fill="#fff"/>
                    <path id="Trazado_1342" data-name="Trazado 1342" d="M181.4,30.386a3.794,3.794,0,0,1,2.779,1.352V30.713h3.573V41.828h-3.573V39.975a3.233,3.233,0,0,1-2.975,2.18c-2.231,0-4.305-1.874-4.305-5.906,0-3.749,2.074-5.863,4.5-5.863Zm-1.389,5.978a1.962,1.962,0,0,0,1.976,2.105,2.223,2.223,0,0,0,2.193-2.322V35.083a3.828,3.828,0,0,0-2.3-.912A2.037,2.037,0,0,0,180.01,36.364Z" transform="translate(-17.368 -1.345)" fill="#fff"/>
                    <path id="Trazado_1343" data-name="Trazado 1343" d="M204.438,34.963v6.865h-3.97l-.021-6.252a1.347,1.347,0,0,0-1.45-1.5,2.025,2.025,0,0,0-1.7,1.064v6.686H193.72V30.713h3.573v1.438a3.861,3.861,0,0,1,3.206-1.766C202.814,30.386,204.438,31.956,204.438,34.963Z" transform="translate(-20.558 -1.345)" fill="#fff"/>
                    <path id="Trazado_1344" data-name="Trazado 1344" d="M214.767,37,213.459,38.4v2.484h-3.573V25.4h3.573v8.834l3.552-4.467h4.534l-4.468,4.773,4.839,6.342h-4.947Z" transform="translate(-23.625 -0.399)" fill="#fff"/>
                    <path id="Trazado_1345" data-name="Trazado 1345" d="M231.12,29.369V40.483h-3.573V32.544h-1.984V29.369Zm-4.448-3.815A2.3,2.3,0,0,1,229,23.3a2.259,2.259,0,1,1-2.323,2.258Z" transform="translate(-26.598)" fill="#fff"/>
                    <path id="Trazado_1346" data-name="Trazado 1346" d="M246.079,34.963v6.865h-3.573V35.669a1.492,1.492,0,0,0-1.584-1.5c-.8,0-1.989,1.064-1.989,1.064v6.593h-3.573V30.713h3.573v1.438a3.861,3.861,0,0,1,3.206-1.766C244.454,30.386,246.079,31.956,246.079,34.963Z" transform="translate(-28.456 -1.345)" fill="#fff"/>
                    <path id="Trazado_1347" data-name="Trazado 1347" d="M258.92,31.734v-1.02h3.573V41.46c0,3.517-3.087,5.861-6.475,5.861a8.943,8.943,0,0,1-4.641-1.173v-3.3a10.144,10.144,0,0,0,4.376,1.108c1.826,0,3.167-1.194,3.167-2.476V39.939a3.615,3.615,0,0,1-3.343,2.171c-2.506,0-4.838-1.866-4.838-5.883,0-3.734,2.331-5.839,5.058-5.839A4.484,4.484,0,0,1,258.92,31.734Zm-2.3,2.437a2.037,2.037,0,0,0-1.867,2.193,1.963,1.963,0,0,0,1.976,2.105,2.223,2.223,0,0,0,2.193-2.322V35.084a3.828,3.828,0,0,0-2.3-.912Z" transform="translate(-31.373 -1.345)" fill="#fff"/>
                    </g>
                </g>
            </svg>
            <svg class="absolute left-20 bottom-22neg" xmlns="http://www.w3.org/2000/svg" width="21.5" height="21.5" viewBox="0 0 21.5 21.5">
                <path id="Trazado_1348" data-name="Trazado 1348" d="M875.333,78V99.5l21.5-21.5Z" transform="translate(-875.333 -78)" fill="#075e54"/>
            </svg>

        </header>
        <div v-if="stage === 'form'" class="text-center px-4">
            <p class="text-16 mt-3 text-center">Se acaban de agregar fotos <br />
a tu Perfil de Negocio</p>
            <div class="text-18 py-3 text-left">{{ data.photos[0].business }}</div>
            <div class="" v-for="(photo, index) in data.photos" :key="index">
                <div class="bg-white rounded-5 mb-2 pa-2">
                    <div class="flex">
                        <div class="">
                            <img :src="photo.customer.profile_photo_url" width="37" />
                        </div>
                        <div class="flex-1 text-left pl-2">
                            <span>agregó una foto</span>
                            <p>{{ photo.customer.profile_name }}</p>
                        </div>
                        <div class="text-right">
                            {{ $moment(photo.source_date).format('DD/MM/YYYY') }}
                        </div>
                    </div>
                    <img :src="photo.thumbnail_url" class="d-block m-auto" />
                    <a :href="photo.take_down_url" target="_blank" class="bg-red border-red text-red rounded-5 pa-3 w-full mt-2 d-block">Denunciar foto</a>
                </div>        
            </div>

            
        </div>
        <div v-if="stage === 'correcto'" class="text-center px-4 mt-8">
            <div class="rounded-5 bg-white text-center flex flex-col py-8">
                <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" width="52.781" height="52.781" viewBox="0 0 52.781 52.781">
                    <g id="Grupo_10781" data-name="Grupo 10781" transform="translate(0.5 0.5)">
                         <path id="Trazado_5006" data-name="Trazado 5006" d="M7,18.056l7.167,7.167L30.891,8.5" transform="translate(6.945 9.029)" fill="none" stroke="#3cc480" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                        <path id="Trazado_5007" data-name="Trazado 5007" d="M25.891,49.781A23.891,23.891,0,1,0,2,25.891,23.891,23.891,0,0,0,25.891,49.781Z" transform="translate(0 0)" fill="none" stroke="#3cc480" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                    </g>
                </svg>
                <p class="text-14 mt-6">La respuesta se ha publicado correctamente.</p>
            </div>
        </div>
        <div v-if="stage === 'error'" class="text-center px-4 mt-8">
            <div class="rounded-5 bg-white text-center flex flex-col py-8">                
                <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" width="56.187" height="50.7" viewBox="0 0 56.187 50.7">
                    <g id="Grupo_11302" data-name="Grupo 11302" transform="translate(2.571 2.5)">
                        <path id="Trazado_5008" data-name="Trazado 5008" d="M47.911,48.712H7.043a5.081,5.081,0,0,1-4.4-7.614L23.072,5.561a5.081,5.081,0,0,1,8.81,0L52.316,41.1A5.081,5.081,0,0,1,47.911,48.712Z" transform="translate(-1.954 -3.012)" fill="none" stroke="#f41938" stroke-linecap="round" stroke-width="5"/>
                        <path id="Trazado_5009" data-name="Trazado 5009" d="M12,9V19.163" transform="translate(13.523 6.213)" fill="none" stroke="#f41938" stroke-linecap="round" stroke-width="5"/>
                        <path id="Trazado_5010" data-name="Trazado 5010" d="M12,17.027,12.025,17" transform="translate(13.523 18.536)" fill="none" stroke="#f41938" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                    </g>
                </svg>

                <p class="text-14 mt-6">Error</p>
            </div>
        </div>
        <div v-if="stage === 'errortoken'" class="text-center px-4 mt-8">
            <div class="rounded-5 bg-white text-center flex flex-col py-8">                
                <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" width="56.187" height="50.7" viewBox="0 0 56.187 50.7">
                    <g id="Grupo_11302" data-name="Grupo 11302" transform="translate(2.571 2.5)">
                        <path id="Trazado_5008" data-name="Trazado 5008" d="M47.911,48.712H7.043a5.081,5.081,0,0,1-4.4-7.614L23.072,5.561a5.081,5.081,0,0,1,8.81,0L52.316,41.1A5.081,5.081,0,0,1,47.911,48.712Z" transform="translate(-1.954 -3.012)" fill="none" stroke="#f41938" stroke-linecap="round" stroke-width="5"/>
                        <path id="Trazado_5009" data-name="Trazado 5009" d="M12,9V19.163" transform="translate(13.523 6.213)" fill="none" stroke="#f41938" stroke-linecap="round" stroke-width="5"/>
                        <path id="Trazado_5010" data-name="Trazado 5010" d="M12,17.027,12.025,17" transform="translate(13.523 18.536)" fill="none" stroke="#f41938" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                    </g>
                </svg>

                <p class="text-14 mt-6">Token expirado</p>
            </div>
        </div>
    </div>
</template>
<script>
import SrvWhatsapp from '@/services/whatsapp'
export default {
    data () {
        return {
            stage: 'loading',
            slug: null,
            token: null,
            data: null,
            response: null,
            loadResponseChatgpt: false
        }
    },
    async created(){
        if (this.$route.query.slug) {
            this.slug = this.$route.query.slug
            const res = await SrvWhatsapp.getTokenPhotosBySlug(this.slug)
            if (res.status === 200) {
                this.token = res.data['token']
                try {
                    const resData = await SrvWhatsapp.getPhotosData(this.token)
                    if (resData.status === 200) {
                        this.data = resData.data
                        this.stage = 'form'
                        if (this.data.reply) {
                            this.response = this.data.reply.text
                        }
                    }
                } catch (e) {
                    if (e.response.data.detail === 'Invalid or expired token.') {
                        this.stage = 'errortoken'
                    }
                }
            }
        }
    },
    methods: {
        
        
        async sendResponse() {
            let load = false
            if (!load) {
                load = true
                let data = JSON.stringify({
                    "reply_text": this.response,
                    "token": this.token
                });
                try {
                    const res = await SrvWhatsapp.setResponse(data)
                    if (res.status === 200) {
                        
                        this.stage = 'correcto'
                        this.response = null
                    }
                } catch (e) {
                    this.stage = 'error'
                }
                load = false
            }
        }
    }
}
</script>
<style scoped>
a{
    text-decoration: none;
}
.m-auto{
    margin: 0 auto;
}
.flex{display:flex}
.flex-col{flex-direction: column}
.text-18{font-size:18px}
.text-16{font-size:16px}
.text-15{font-size:15px}
.text-14{font-size:14px}
.text-center{
    text-align: center
}
.border-none{
    border: none !important;
}
.rounded-5{
    border-radius: 5px;
}
.bg-white{
    background-color: white
}
.py-14{
    padding-top: 14px;
    padding-bottom: 14px;
}
header{
    border-top: 24px solid #000;
    background-color: #075e54;
    padding: 8px 0;
}
.respuesta{
    border: 1px solid #25D366;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
    min-height: 111px;
}
.text-green{
    color: #25D366;
}
.text-white {
    color: #fff;
}
.bg-green{
    background-color: #25D366;
}
.bg-old{
    background-color: #DCD7D0;
}
.bg-red{
    background-color: #F9BBBB;
}
.border-red{
    border: 1px solid #D7373F;
}
.text-red{
    color: #D7373F;
}
.w-full{
    width: 100%;
}
.shadow{
    -webkit-box-shadow: 0.5px 0.5px 10px 0px rgba(0,0,0,0.17);
-moz-box-shadow: 0.5px 0.5px 10px 0px rgba(0,0,0,0.17);
box-shadow: 0.5px 0.5px 10px 0px rgba(0,0,0,0.17);
}
.absolute{
    position: absolute;
}
.bottom-22neg{
    bottom: -22px;
}
.left-20{
    left: 20px;
}
</style>