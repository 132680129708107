<template lang="pug">
v-row.px-2.ma-0
    //GRID NÚMERO DE OPINIONES   
    box-opiniones(:dataSummary="dataSummary" col="col-9")     

    // CAJA ENCUESTAS DE SATISFACCIÓN MEDALLIA ALSEA Y EL RESTO PROPIAS DE GR
    box-encuestas-satisfaccion(:dataSummary="stats"  col="col-3" :title="is_alsea ? $gettext('Opiniones en Medallia') : $gettext('Datos QR')")

    // SAC- WOE
    div.margin-bottom-0.col-4(v-if="1!=1 && is_alsea")
        skeleton-card(v-if="isLoading")
        v-card.gridFull.ma-0(v-if="!isLoading && dataSummary.length" :class="{'borderDelivery': typeOpinionSelected === 'delivery'}")
            .d-flex.headTitleGrid
                .block-title.titleGrid
                    translate.mb-0.title-card SAC- WOE
                    span.ml-1.tDelivery(v-if="typeOpinionSelected === 'delivery'") Delivery
                .block-help.ml-auto
                    v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="30" nudge-left="10" content-class="tooltip-help tooltip-help-left")
                        template(v-slot:activator="{ on, attrs }")
                            img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                        span {{helpValoration}}
                    v-icon.mb-1(@click="goWoe()") mdi-chevron-right
            .card-content
                .row.text-left
                    #tableKantar.tableGlobal
                        div.headerTableRanking.row.ma-0
                            div.headerItem.col-name
                                p
                            div.headerItem.col-quiz
                                p.mb-0 #[translate Claims]
                            div.headerItem.col-satisfaccion
                                p.mb-0 #[translate Suggestions]

                        div.contentTableRanking.row.ma-0
                            p.ml-3(v-if="!dataSummary") #[translate No data]
                            div.itemContent.col-12.pa-0(v-for="(item, index) in dataSummary" :key="index")
                                div.item.col-name.text-left.pl-2(:class="getTextBlackIfGroup(item)")
                                    span(v-if="index == 0") {{item.name}}
                                    span.link-text(v-if="index>0" @click="goItemNextLevel(item)") {{item.name}}
                                div.item.col-quiz.text-right
                                    span.pr-4 {{item.claim_count}}
                                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                        template(v-slot:activator="{ on, attrs }")
                                            p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(item.claim_count, item.tend_claim_count)")
                                        span {{ item.tend_claim_count | formatNumber }}
                                div.item.col-satisfaccion.text-right
                                    span.pr-4 {{item.suggestion_count}}
                                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                        template(v-slot:activator="{ on, attrs }")
                                            p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(item.suggestion_count, item.tend_suggestion_count)")
                                        span {{ item.tend_suggestion_count | formatNumber }}

                            div.itemContent.col-12.pa-0(v-if="typeNode === 'center'")
                                div.item.col-name.media-cadena.d-flex.justify-start
                                    translate.text-start Chain mean
                                div.item.col-quiz.text-right.pr-4
                                    span.pr-4 {{dataSummary[0].chain_data.claim_count}}
                                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                        template(v-slot:activator="{ on, attrs }")
                                            p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(dataSummary[0].chain_data.claim_count, dataSummary[0].chain_data.tend_claim_count)")
                                        span {{ dataSummary[0].chain_data.tend_claim_count | formatNumber }}

                                div.item.col-satisfaccion.text-right.pr-4
                                    span.pr-4 {{dataSummary[0].chain_data.suggestion_count}}
                                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                        template(v-slot:activator="{ on, attrs }")
                                            p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(dataSummary[0].chain_data.suggestion_count, dataSummary[0].chain_data.tend_suggestion_count)")
                                        span {{ dataSummary[0].chain_data.tend_suggestion_count | formatNumber }}

    //GRID WEBS DE OPINIONES
    grid-web-opiniones-note-real(v-if="dataSummary.length && !is_alsea && (typeOpinionSelected === '' || typeOpinionSelected === null || typeOpinionSelected === 'internet') " :data="dataSummary")

    //GRID WEBS DE DELIVERY
    grid-web-delivery(v-if="dataSummary.length && !is_alsea && showDelivery" :data="dataSummary")

    //GRID EVOLUTION MAIN NUMBER OPINIONS
    div.margin-bottom-0.col-6(v-if="(typeOpinionSelected === 'internet' || typeOpinionSelected === ''  || typeOpinionSelected === null)")
        skeleton-card(v-if="isLoading")
        v-card.gridFull.ma-0(v-if="!isLoading && dataSummary.length")
            .d-flex.headTitleGrid
                .block-title.titleGrid
                    translate.mb-0.title-card Evolution of the average rating and number of web opinions
                .block-help.ml-auto
                    v-tooltip(left color="#222226" open-on-click=true max-width="550" nudge-bottom="30" nudge-left="10" content-class="tooltip-help tooltip-help-left")
                        template(v-slot:activator="{ on, attrs }")
                            img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                        div
                            p.mb-0.pb-0 En función de los filtros seleccionados, se muestra la evolución de la Valoración media y la media de las Opiniones del mes en curso y un año hacia atrás.
                            p.mb-0.pb-0 Se analiza sobre el grupo de restaurantes que marca la selección del menú izquierdo.
            div                    
                box-evolution-main-number-opinion(:chartData="dataEvolutionLastYear")

    //GRID EVOLUTION MAIN NUMBER OPINIONS DELIVERY
    div.margin-bottom-0.col-6(v-if="showDelivery")
        skeleton-card(v-if="isLoading")
        v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading && dataSummary.length")
            .d-flex.headTitleGrid
                .block-title.titleGrid
                    translate.mb-0.title-card Evolution of Average Rating and Number of Opinions
                    span.ml-1.tDelivery Delivery
                .block-help.ml-auto
                    v-tooltip(left color="#222226" open-on-click=true max-width="550" nudge-bottom="30" nudge-left="10" content-class="tooltip-help tooltip-help-left")
                        template(v-slot:activator="{ on, attrs }")
                            img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                        div
                            p.mb-0.pb-0 En función de los filtros seleccionados, se muestra la evolución de la Valoración media y la media de las Opiniones del mes en curso y un año hacia atrás.
                            p.mb-0.pb-0 Se analiza sobre el grupo de restaurantes que marca la selección del menú izquierdo.
            div
                box-evolution-main-number-opinion(:chartData="dataEvolutionLastYearDelivery" :renderMediaInfo="true")

    //GRID % CATEGORÍAS POR MARCA INTERNET
    div.margin-bottom-0(v-if="!is_alsea && (typeOpinionSelected === 'internet' || typeOpinionSelected === '' || typeOpinionSelected === null)" 
    :class="colsCategories")
        skeleton-card(v-if="isLoading")
        v-card.gridFull.ma-0(v-if="!isLoading && dataSummary.length")
            .d-flex.headTitleGrid
                .block-title.titleGrid
                    translate.mb-0.title-card % Categories
                .block-help.ml-auto
                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="40" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                        template(v-slot:activator="{ on, attrs }")
                            img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                        span {{helpCategoryBrand}}
                    v-icon.mb-1(v-if="typeNode!=='group'" @click="goFeedback()") mdi-chevron-right
            .card-content
                .row.text-left
                    #tableCategoriasMarcas.tableGlobal
                        v-col.col-12.headerTableRanking
                            v-row.headerItem.items-center.justify-start.pl-4.pt-4
                                span.mr-1.chip-legend.positive
                                translate.mr-3 Positives
                                span.mr-1.chip-legend.neutral
                                translate.mr-3 Neutral
                                span.mr-1.chip-legend.negative
                                translate.mr-3 Negatives

                        v-row.headerTableRanking.row.ma-0.no-gutters(v-if="dataSummary.length")
                            div.offset-1
                                span
                            v-col.headerItem.justify-center(v-for="(categorie, indexc) in dataSummary[0].categories" :key="indexc" :style="getWithColCategory(75, dataSummary[0].categories)")
                                p.mb-0 {{ indexc }}

                        v-row.contentTableRanking.no-gutters.h-auto
                            p.ml-3(v-if="!dataSummary") #[translate No data]
                            div.itemContent.col-12(v-for="(item, index) in dataSummary" :key="index")
                                v-col.d-flex.col-1.justify-start(:class="getTextBlackIfGroup(item)")
                                    span.text-start(:class="{'link-text': index > 0}" @click="()=> index > 0 ? goItemNextLevel(item) : null") {{item.name}}
                                v-col.text-center(v-for="(categorie, index) in item.categories_sentiment_percent" :key="index")
                                    v-row.no-gutters.pb-2
                                        v-col.col-6
                                            p.mb-0 {{ item.categories[categorie.name] | formatNumber }} %
                                        v-col.col-6
                                            p.mb-0 {{ categorie.count | formatNumber }}
                                    v-row.no-gutters
                                        chart-bar-three-colors(:percentGreen="categorie.positive" :percentOrange="categorie.neutral" :percentRed="categorie.negative" :render-details="true" :total="categorie.count")
                            div.itemContent.col-12.pa-0(v-if="typeNode === 'center' && dataSummary.length > 0 && dataSummary[0].chain_data")
                                v-col.col-1
                                    div.media-cadena.d-flex.justify-start
                                        translate.text-start Chain mean
                                v-col.text-center(v-for="(categorie, index) in dataSummary[0].chain_data.categories_sentiment_percent" :key="index")
                                    v-row.no-gutters.pb-2
                                        v-col.col-6
                                            p.mb-0 {{ categorie.percent | formatNumber }} %
                                        v-col.col-6
                                            p.mb-0 {{ categorie.count | formatNumber }}
                                    v-row.no-gutters
                                        chart-bar-three-colors(:percentGreen="categorie.positive" :percentOrange="categorie.neutral" :percentRed="categorie.negative" :render-details="true" :total="categorie.count")

    //GRID % CATEGORÍAS POR MARCA DELIVERY
    div.margin-bottom-0.col-12(v-if="!is_alsea && showDelivery")
        skeleton-card(v-if="isLoading")
        v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading && dataSummary.length")
            .d-flex.headTitleGrid
                .block-title.titleGrid
                    translate.mb-0.title-card % Categories
                    span.ml-1.tDelivery Delivery
                .block-help.ml-auto
                    v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="40" nudge-left="10" content-class="tooltip-help tooltip-help-left")
                        template(v-slot:activator="{ on, attrs }")
                            img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                        span {{helpCategoryBrand}}
                    v-icon.mb-1(v-if="typeNode!=='group'" @click="goFeedback()") mdi-chevron-right
            .card-content
                .row.text-left
                    #tableCategoriasMarcas.tableGlobal
                        v-col.col-12.headerTableRanking
                            v-row.headerItem.items-center.justify-start.pl-4.pt-4
                                span.mr-1.chip-legend.positive
                                translate.mr-3 Positives
                                span.mr-1.chip-legend.neutral
                                translate.mr-3 Neutral
                                span.mr-1.chip-legend.negative
                                translate.mr-3 Negatives

                        v-row.headerTableRanking.row.ma-0.no-gutters(v-if="dataSummary.length")
                            div.offset-1
                                span
                            v-col.headerItem.justify-center(v-for="(categorie, indexc) in dataSummary[0].delivery_categories" :key="indexc")
                                p.mb-0 {{ indexc }}

                        v-row.contentTableRanking.no-gutters.h-auto
                            p.ml-3(v-if="!dataSummary") #[translate No data]
                            div.itemContent.col-12(v-for="(item, index) in dataSummary" :key="index")
                                v-col.col-1.d-flex.justify-start(:class="getTextBlackIfGroup(item)")
                                    span.text-start(:class="{'link-text': index > 0}" @click="()=> index > 0 ? goItemNextLevel(item) : null") {{item.name}}
                                v-col.text-center(v-for="(categorie, index) in item.delivery_categories_sentiment_percent" :key="index")
                                    v-row.no-gutters.pb-2
                                        v-col.col-6
                                            p.mb-0 {{ item.delivery_categories[categorie.name] | formatNumber }} %
                                        v-col.col-6
                                            p.mb-0 {{ categorie.count | formatNumber }}
                                    v-row.no-gutters
                                        chart-bar-three-colors(:percentGreen="categorie.positive" :percentOrange="categorie.neutral" :percentRed="categorie.negative" :render-details="true" :total="categorie.count")
                            div.itemContent.col-12.pa-0(v-if="typeNode === 'center' && dataSummary.length > 0 && dataSummary[0].chain_data")
                                v-col.col-1
                                    div.media-cadena.d-flex.justify-start
                                        translate Chain mean
                                v-col.text-center(v-for="(categorie, index) in dataSummary[0].chain_data.delivery_categories_sentiment_percent" :key="index")
                                    v-row.no-gutters.pb-2
                                        v-col.col-6
                                            p.mb-0 {{ categorie.percent | formatNumber }} %
                                        v-col.col-6
                                            p.mb-0 {{ categorie.count | formatNumber }}
                                    v-row.no-gutters
                                        chart-bar-three-colors(:percentGreen="categorie.positive" :percentOrange="categorie.neutral" :percentRed="categorie.negative" :render-details="true" :total="categorie.count")

    //GRID EVOLUTION LAST YEAR CHART
    div.margin-bottom-0.col-6(v-if="(typeOpinionSelected === 'internet' || typeOpinionSelected === ''  || typeOpinionSelected === null)")
        skeleton-card(v-if="isLoading")
        v-card.gridFull.ma-0(v-if="!isLoading && dataSummary.length")
            .d-flex.headTitleGrid
                .block-title.titleGrid
                    translate.mb-0.title-card Evolution of Sentiment on Opinion Websites
                .block-help.ml-auto
                    v-tooltip(left color="#222226" open-on-click=true max-width="550" nudge-bottom="30" nudge-left="10" content-class="tooltip-help tooltip-help-left")
                        template(v-slot:activator="{ on, attrs }")
                            img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                        div
                            p.mb-0.pb-0 En función de los filtros seleccionados, se muestra la evolución del Sentimiento del mes en curso y un año hacia atrás. Una vez analizadas las opiniones de Internet obtenemos el sentimiento de las mismas, siendo los valores:
                            ul.mt-0.pt-0
                                li #[translate Positives]: 10 – 7,5
                                li #[translate Neutral]: 7,5 - 5
                                li #[translate Negatives]: 5 - 0
                            p.mb-0.pb-0 Se analiza sobre el grupo de restaurantes que marca la selección del menú izquierdo.

            box-evolution-last-year(:chartData="dataEvolutionLastYear" :isDelivery="false")

    //GRID EVOLUTION LAST YEAR CHART
    div.margin-bottom-0.col-6(v-if="showDelivery")
        skeleton-card(v-if="isLoading")
        v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading && dataSummary.length")
            .d-flex.headTitleGrid                     
                .block-title.titleGrid
                    translate.mb-0.title-card Evolution of Sentiment on Websites of 
                    span.ml-1.tDelivery Delivery
                .block-help.ml-auto
                    v-tooltip(left color="#222226" open-on-click=true max-width="550" content-class="tooltip-help tooltip-help-left")
                        template(v-slot:activator="{ on, attrs }")
                            img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                        div
                            p.mb-0.pb-0 En función de los filtros seleccionados, se muestra la evolución del Sentimiento del mes en curso y un año hacia atrás. Una vez analizadas las opiniones de los Agregadores obtenemos el sentimiento de las mismas, siendo los valores:   
                            ul.mt-0.pt-0 
                                li #[translate Positives]: 10 – 7,5
                                li #[translate Neutral]: 7,5 - 5
                                li #[translate Negatives]: 5 - 0
                            p.mb-0.pb-0 Se analiza sobre el grupo de restaurantes que marca la selección del menú izquierdo.

            box-evolution-last-year(:chartData="dataEvolutionLastYearDelivery" :isDelivery="true" )

    //GRID GASTRORANKING PROMOTER SCORE
    div.margin-bottom-0.col-6(v-if="!is_alsea")
        skeleton-card(v-if="isLoading")
        v-card.gridFull.ma-0(v-if="!isLoading && dataSummary.length" :class="{'borderDelivery': typeOpinionSelected === 'delivery'}")
            .d-flex.headTitleGrid
                .block-title.titleGrid
                    translate.mb-0.title-card GastroRanking Promote Score (GPS)
                    span.ml-1.tDelivery(v-if="typeOpinionSelected === 'delivery'") Delivery
                .block-help.ml-auto
                    v-tooltip(left color="#222226" open-on-click=true max-width="650" nudge-bottom="245" nudge-left="10" content-class="tooltip-help tooltip-help-left")
                        template(v-slot:activator="{ on, attrs }")
                            img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                        div
                            span #[translate The GastroRanking Promoter Score (GPS) is an indicator analogous to the NPS (Net Promote Score) and serves to measure customer loyalty and satisfaction. It works by analyzing the probability that a person will recommend the restaurant. But instead of using surveys, use the notes that customers leave on the Internet.]
                            br
                            span #[translate In this way we define:]
                            ul
                                li #[translate Rating from 1 to 3 stars: They are detractors. Customers who not only won't recommend, but probably won't speak highly of the restaurant. There are studies that indicate that a high value of this indicator is directly related to the growth capacity of the business]
                                li #[translate 4-star rating: They are neutral customers. They have left satisfied, but have not had a "Wow!" to assure us that they will speak highly of the restaurant]
                                li #[translate 5 star rating. They are considered promoters. They are very satisfied customers who are highly likely to recommend the restaurant]
                            span #[translate The GPS is a very useful indicator to obtain a very quick view of the customer experience in any restaurant and to follow its evolution allows to easily identify the impact of the measures adopted to improve said experience. The importance of this KPI lies in the fact that there are empirical studies that directly relate the NPS with the growth capacity of a business. The higher the value, the more opportunity for growth.]
                            br
                            span #[translate And the scale of values shown is as follows]
                            ul
                                li #[translate -100% to 0.50%: Red]
                                li #[translate 0.51% to 25.0%: Orange]
                                li #[translate Above 25.0%: Green]
                            span #[translate It is analyzed about the group of restaurants that marks the selection in the left menu]
                    v-icon.mb-1(v-if="typeNode!=='group'" @click="goNetworkGps()") mdi-chevron-right
            .card-content
                .row.text-left
                    #tableGPS.tableGlobal
                        div.headerTableRanking.row.ma-0
                            div.headerItem.col-name
                                p
                            div.headerItem.col-progress
                                .ticksGpsPercent
                                    .negative.font-weight-med -100
                                    .neutral.font-weight-med 0
                                    .positive.font-weight-med +100
                                //span.boxNote(v-for="index in 10") {{ index }}
                            div.headerItem.col-media
                                p.mb-0 #[translate Average]
                            div.headerItem.col-pa
                                p.mb-0 #[translate p.a.]
                            div.headerItem.col-tend
                                p.mb-0 #[translate Tend.]

                        div.contentTableRanking.row.ma-0
                            p.ml-3(v-if="!dataSummary") #[translate No data]
                            div.itemContent.col-12.pa-0(v-for="(item, index) in dataSummary" :key="index")
                                div.item.col-name(:class="getTextBlackIfGroup(item)")
                                    span(v-if="index == 0") {{item.name}}
                                    span.link-text(v-if="index>0" @click="goItemNextLevel(item)") {{item.name}}
                                div.item.col-progress
                                    //p.mb-0.progressBar(:class="getColorProgressBarGPS(item.gps)" :style="'width:' + getWidthProgressBarGPS(item.gps)+ '%'")
                                    .ticksGpsPercentBody
                                        .negative
                                            p.mb-0.progressBar(v-if="item.gps < 0" :class="getColorProgressBarGPS(item.gps)" :style="'width:' + getWidthProgressBarGPS(item.gps)+ '%'")
                                        .neutral
                                        .positive
                                            p.mb-0.progressBar(v-if="item.gps > 0" :class="getColorProgressBarGPS(item.gps)" :style="'width:' + getWidthProgressBarGPS(item.gps)+ '%'")
                                div.item.col-media.text-right
                                    p.mb-0 {{ item.gps | formatNumber }} %
                                div.item.col-pa.text-right
                                    p.mb-0 {{ item.tend_gps | formatNumber }} %
                                div.item.col-tend
                                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                        template(v-slot:activator="{ on, attrs }")
                                            p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(item.gps, item.tend_gps)")
                                        span {{ item.tend_gps | formatNumber }}
                            div.itemContent.col-12.pa-0(v-if="typeNode === 'center' && dataSummary.length > 0 && dataSummary[0].chain_data")
                                div.item.col-name.media-cadena.d-flex.justify-start
                                    translate Chain mean
                                div.item.col-progress
                                    .ticksGpsPercentBody
                                        .negative
                                            p.mb-0.progressBar(v-if="dataSummary[0].chain_data.gps < 0" :class="getColorProgressBarGPS(dataSummary[0].chain_data.gps)" :style="'width:' + getWidthProgressBarGPS(dataSummary[0].chain_data.gps)+ '%'")
                                        .neutral
                                        .positive
                                            p.mb-0.progressBar(v-if="dataSummary[0].chain_data.gps > 0" :class="getColorProgressBarGPS(dataSummary[0].chain_data.gps)" :style="'width:' + getWidthProgressBarGPS(dataSummary[0].chain_data.gps)+ '%'")
                                div.item.col-media.text-right
                                    p.mb-0 {{ dataSummary[0].chain_data.gps | formatNumber }} %
                                div.item.col-pa.text-right
                                    p.mb-0 {{ dataSummary[0].chain_data.tend_gps | formatNumber }} %
                                div.item.col-tend
                                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                        template(v-slot:activator="{ on, attrs }")
                                            p.mb-0(v-bind="attrs" v-on="on" :class="getArrow(dataSummary[0].chain_data.gps, dataSummary[0].chain_data.tend_gps)")
                                        span {{ dataSummary[0].chain_data.tend_gps | formatNumber }}
    //GRID COVER MANAGER
    grid-category-cover-manager(:data="infoCoverManager" v-if="showCoverManager")

    //GRID COMPETENCIA
    grid-competencia.pa-3(v-if="hasCompetence && dataSummary.length" :data="dataSummary")

    info-competence(v-if="competenceWithChildren.length" :data="competenceWithChildren" :render-arrow="false")

    competence-last-year

    valoration-last-year

    //GRID Ranking de restaurantes
    grid-ranking-restaurantes(v-if="dataSummary.length && !is_alsea && (typeNode === 'group' || typeNode === 'brand')" :data="dataSummary")
</template>
<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import GridWebOpiniones from '@/components/global/WebOpiniones.vue'
import GridWebOpinionesNoteReal from '@/components/global/WebOpinionesNoteReal.vue'
import GridWebDelivery from '@/components/global/WebDelivery.vue'
import GridRankingRestaurantes from '@/components/global/RankingRestaurantes.vue'
import GridCompetencia from '@/components/global/Competencia.vue'
import GridCategoryCoverManager from '@/components/global/CategoryCoverManager.vue'
import ChartBarThreeColors from '@/components/charts/BarTrheeColors.vue'
import BoxEvolutionLastYear from '@/components/network/EvolutionLastYear.vue'
import BoxCompetenceLastYear from '@/components/network/CompetenceLastYear.vue'
import BoxEvolutionMainNumberOpinion from '@/components/network/EvolutionMainNumberOpinion.vue'
import BoxEncuestasSatisfaccion from '@/components/global/BoxEncuestasSatisfaccion.vue'
import BoxSentimiento from '@/components/common/BoxSentimiento.vue'
import BoxOpiniones from '@/components/common/BoxOpiniones.vue'
import SrvRankingSatisfaction from '@/services/ranking-satisfaction.js'
import ValorationLastYear from '@/components/common/ValorationLastYear.vue'
import CompetenceLastYear from '@/components/common/CompetenceLastYear.vue'
import InfoCompetence from "@/components/competence/InfoCompetence.vue"

import SrvGlobal from '@/services/global.js'
import SrvNetWork from '@/services/network.js'
import commonMixins from "@/mixins/common.js"
import brandMixin from '@/mixins/brand'

export default {
    components: { InfoCompetence, BoxOpiniones, BoxSentimiento, BoxEncuestasSatisfaccion, GridCategoryCoverManager, SkeletonCard, ChartBarThreeColors, GridWebOpinionesNoteReal, GridWebOpiniones, GridWebDelivery, GridRankingRestaurantes, GridCompetencia, BoxEvolutionLastYear, BoxEvolutionMainNumberOpinion, BoxCompetenceLastYear, ValorationLastYear, CompetenceLastYear }, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
    mixins: [commonMixins, brandMixin],
    data() {
        return {
            dataSummary: [],
            stats: [],
            dataEvolutionLastYear: [],
            dataEvolutionLastYearDelivery: [],
            dataCompetenceLastYear: [],
            infoCoverManager: [],
            // Help Common
            helpCategoryBrand: this.$gettext('Percentages of the different categories that have been detected in the opinions of the selected period compared to the same period of the previous year (Tend). Use the Sites selector to see only your information. And the filter to segment. It is analyzed about the group of restaurants that marks the selection in the left menu and the filters applied.'),
            helpValoration: this.$gettext('From the analysis of the opinion notes, we obtain the valuation data and its trend, comparing them with the same selected period of the previous year. It is analyzed about the group of restaurants that marks the selection in the left menu'),
        }
    },
    created() {
        this.$store.commit('LOADING', true)
        if (this.$store.getters.getNode > 0 && this.$route.query.tree && this.$route.query.before && this.$route.query.after) {
            this.getGlobal()
            this.getStats()
            this.getEvolutionLastYear()
            if (this.hasDelivery) {
                this.getEvolutionLastYearDelivery()
            }
            if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
                this.getCompetenceLastYear()
            }
        }
    },
    watch: {
        filterApply: function () {
            this.getGlobal()
            this.getEvolutionLastYear()
            if (this.hasDelivery) {
                this.getEvolutionLastYearDelivery()
            }
            if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
                this.getCompetenceLastYear()
            }
        }
    },
    computed: {
        colsCategories() {
            if (this.dataSummary && this.dataSummary[0]) {
                return this.dataSummary[0].categories.length <= 6 ? 'col-6' : 'col-12'
            }
            return 'col-6'
        },
        competenceWithChildren() {
            const items = []

            if (this?.dataSummary?.length && this?.dataSummary?.[0]?.competence?.length) {

                const keysToExtract = [
                    "name",
                    "id",
                    "review_count",
                    "review_count_rated",
                    "n_centers",
                    "avg_review_count",
                    "review_rating_global",
                    "review_count_positive",
                    "review_count_neutral",
                    "review_count_negative",
                    "review_percent_positive",
                    "review_percent_neutral",
                    "review_percent_negative",
                    "is_center",
                    "gps",
                    "gps_percent_positive",
                    "gps_percent_negative",
                    "categories_sentiment_percent", //feeling_by_categories
                ]

                const sectorKeys = [
                    "name",
                    "review_count",
                    "review_count_rated",
                    "avg_review_count",
                    "review_rating_global",
                    "review_count_positive",
                    "review_count_neutral",
                    "review_count_negative",
                    "review_percent_positive",
                    "review_percent_neutral",
                    "review_percent_negative",
                    "gps",
                    "gps_percent_positive",
                    "gps_percent_negative",
                    "feeling_by_categories",
                ]

                const brandData = keysToExtract.reduce((acc, key) => {
                    if (key in this?.dataSummary?.[0]) {
                        const _key = key === "categories_sentiment_percent" ? "feeling_by_categories" : key;
                        acc[_key] = this?.dataSummary?.[0]?.[key];
                    }
                    return acc;
                }, {})

                const sector = sectorKeys.reduce((acc, key) => {
                    if (key in this?.dataSummary?.[0]?.competence?.[0]) {
                        acc[key] = this?.dataSummary?.[0]?.competence?.[0]?.[key];
                    }
                    return acc;
                }, {})

                items.push({
                    items: [{
                        ...brandData,
                        sector: { ...sector }
                    }], showCompetence: true
                })

                const children = this?.dataSummary?.[0]?.competence.filter((_, index) => index > 0)

                items?.[0].items.push(...children)
            }
            return items
        }
    },
    methods: {
        getInfoCoverManager() {
            SrvGlobal.getInfoCoverManager()
                .then(res => {
                    if (res.status === 200) {
                        this.infoCoverManager = res.data
                    }
                }, () => {
                })
        },
        getCompetenceLastYear() {
            SrvNetWork.getCompetenceLastYear()
                .then(res => {
                    if (res.status === 200) {
                        res.data.forEach((e) => e.show = true)
                        this.dataCompetenceLastYear = res.data
                    }
                }, () => {
                })
        },
        getEvolutionLastYear() {
            SrvNetWork.getEvolutionLastYear()
                .then(res => {
                    if (res.status === 200) {
                        this.dataEvolutionLastYear = res.data
                    }
                }, () => {
                })
        },
        getEvolutionLastYearDelivery() {
            SrvNetWork.getEvolutionLastYear('delivery')
                .then(res => {
                    if (res.status === 200) {
                        this.dataEvolutionLastYearDelivery = res.data
                    }
                }, () => {
                })
        },
        getGlobal() {
            this.$store.commit('LOADING', true)
            SrvGlobal.getGlobal()
                .then(async res => {
                    if (res.status === 200) {

                        /* ORDENAMOS ELEMENTOS SALVO EL PRIMERO*/
                        const array1 = res.data.shift()
                        const array2 = res.data
                            .sort((a, b) => {
                                // Old sort
                                return a.name.localeCompare(b.name);
                            })

                        this.dataSummary = [].concat(array1, array2)
                        if (this.showCoverManager) {
                            await this.getInfoCoverManager()
                        }
                        this.$store.commit('LOADING', false)
                    }
                }, () => {
                })
        },
        async getStats() {
            try {
                const resStats = await SrvRankingSatisfaction.getSummarySatisfactionStatsSurvey({ disabledReviewType: true })
                if (resStats.status === 200) {
                    this.stats = resStats.data
                }
            } catch (error) {
                // console.log("[ERROR: getStats]:", error)
            }
        },
        getColorProgressBarGPS(percent) {
            if (percent <= 0.50) {
                return 'progressRed'
            } else if (percent < 25.0) {
                return 'progressOrange'
            } else {
                return 'progressGreen'
            }
        },
        getWidthProgressBarGPS(percent) {
            return Math.abs(percent)
        }
    }
}
</script>
<style lang="scss" scoped>
.colChartBrand {
    width: 10%;
}

.v-application #tableRanking .elevation-1 {
    box-shadow: none !important;
}

#tableKantar {
    .col-name {
        max-width: 43.33%;
        flex: 0 0 43.33%;
        padding-left: 10px;
        text-align: left;

        &.media-cadena {
            color: #979797;

            span {
                letter-spacing: -0.02px;
            }
        }
    }

    .col-quiz {
        max-width: 26.33%;
        flex: 0 0 26.33%;
    }

    .col-satisfaccion {
        max-width: 28.33%;
        flex: 0 0 28.33%;
        margin-right: 2%;
    }
}

#tableCategoriasMarcas {
    .contentTableRanking {
        .itemContent {
            min-height: 70px !important;
        }
    }

    .col-name {
        max-width: 25%;
        flex: 0 0 25%;
        padding-left: 10px;
        text-align: left;

        &.textBlackGroup {
            color: #000;
        }

        &.media-cadena {
            color: #979797;

            span {
                letter-spacing: -0.02px;
            }
        }
    }

    .col-numero {
        flex: auto;
        margin-right: 2%;
    }
}

#tableGPS {
    .col-name {
        max-width: 27.33%;
        flex: 0 0 27.33%;
        padding-left: 10px;
        text-align: left;

        &.textBlackGroup {
            color: #000;
        }

        &.media-cadena {
            color: #979797;

            span {
                letter-spacing: -0.02px;
            }
        }
    }

    .headerItem {
        &.col-progress {
            align-items: center;

            .ticksGpsPercent {
                font-size: 1rem;
                position: relative;
                width: 100%;

                .negative {
                    position: absolute;
                    left: 0;
                    top: -13px;
                }

                .neutral {
                    position: absolute;
                    left: 50%;
                    top: -13px;
                    margin-left: 50%;
                    left: -4.5px;
                }

                .positive {
                    position: absolute;
                    right: 0;
                    top: -13px;
                }
            }
        }
    }

    .col-progress {
        max-width: 35.93%;
        flex: 0 0 35.93%;

        .ticksGpsPercentBody {
            background-color: #E4E4E4;
            height: 12px;
            position: relative;
            width: 100%;

            .negative {
                position: absolute;
                left: 0;
                width: 50%;
                top: 0;
                direction: rtl;
            }

            .neutral {
                position: absolute;
                left: 50%;
                height: 24px;
                width: 1px;
                background-color: #707070;
                top: -6px;
                z-index: 1;
            }

            .positive {
                position: absolute;
                right: 0;
                width: 50%;
                top: 0;
            }

            .progressBar {
                height: 12px;

                &.progressRed {
                    background-color: #D61A23;
                }

                &.progressOrange {
                    background-color: #FCAE19;
                }

                &.progressGreen {
                    background-color: #89C445;
                }
            }
        }


        .boxNote {
            width: 9%;
            margin-right: 1%;
            height: 19px;
            background-color: #ccc;
            color: #fff;
            text-align: center;
            display: inline-block;
            border-radius: 4px;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
                background-color: #D61A23;
            }

            &:nth-child(7),
            &:nth-child(8) {
                background-color: #FCAE19;
            }

            &:nth-child(9),
            &:nth-child(10) {
                background-color: #89C445;
            }
        }
    }

    .col-media {
        max-width: 9.93%;
        flex: 0 0 9.93%;
        margin-right: 2%;
        justify-content: right;
    }

    .col-pa {
        max-width: 9.93%;
        flex: 0 0 9.93%;
        margin-right: 2%;
        justify-content: right;
    }

    .col-tend {
        max-width: 9.93%;
        flex: 0 0 9.93%;
        margin-right: 2%;
        justify-content: center;
    }
}
</style>
