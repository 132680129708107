import { render, staticRenderFns } from "./WebOpiniones.vue?vue&type=template&id=4db78891&scoped=true&lang=pug"
import script from "./WebOpiniones.vue?vue&type=script&lang=js"
export * from "./WebOpiniones.vue?vue&type=script&lang=js"
import style0 from "./WebOpiniones.vue?vue&type=style&index=0&id=4db78891&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4db78891",
  null
  
)

export default component.exports