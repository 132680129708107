import Auth from '../views/Auth.vue'
// USER
import RestorePassword from '../views/User/Restore.vue'
import RestorePasswordSet from '../views/User/RestoreSet'

// GLOBAL
import GlobalRoutes from '@/router/global.js'

// FEEDBACK
import FeedBackRoutes from '@/router/feedback.js'

// NETWORK
import NetworkRoutes from '@/router/network.js'

// DELIVERY
import DeliveryRoutes from '@/router/delivery.js'

// DELIVERY FEEDBACK
import DeliveryFeedBackRoutes from '@/router/deliveryfeedback.js'

// SATISFACTION ALSEA
import SatisfactionAlseaRoutes from '@/router/satisfactionalsea.js'

// SATISFACTION
import SatisfactionRoutes from '@/router/satisfaction.js'

// LOCAL
import LocalMarketingRoutes from '@/router/localmarketing.js'

// REPORTS
import ReportsRoutes from '@/router/reports.js'

// NOT FOUNT PAGE
import NotFound from '../views/404.vue'

// GASTROPRICING
import GpRateAnalysisRoutes from '@/router/gprateanalysis.js'
import GpPromoAnalysisRoutes from '@/router/gppromoanalysis.js'

import FacebookConfig from "../views/FacebookConfig.vue"

// ATTENTION
import AttentionRoutes from '@/router/attention.js'

// APP
import AppRoutes from '@/router/app.js'

// APP
import LocalValidateTokenGoogle from '../views/Local/Pages/LocalValidateTokenGoogle.vue'

import WhatsAppResponse from '@/views/Whatsapp/Response.vue'
import WhatsAppPhotos from '@/views/Whatsapp/Photos.vue'

const routes = [
  {
    path: '/real-time-reviews',
    name: 'WhatsAppResponse',
    component: WhatsAppResponse, 
    meta: {Auth: false}
  },
  {
    path: '/real-time-customer-photos',
    name: 'WhatsAppPhotos',
    component: WhatsAppPhotos, 
    meta: {Auth: false}
  },  
  {
    path: '/',
    name: 'home',
    component: Auth,
    meta: {Auth: false}
  },
  {
    path: '/business/oauth2callback',
    name: 'validatetokengoogle',
    component: LocalValidateTokenGoogle,
    meta: {Auth: true}
  },
  AppRoutes,
  GlobalRoutes,
  ReportsRoutes,
  FeedBackRoutes,
  AttentionRoutes,
  SatisfactionRoutes,
  SatisfactionAlseaRoutes,
  NetworkRoutes,
  DeliveryRoutes,
  DeliveryFeedBackRoutes,
  LocalMarketingRoutes,
  GpRateAnalysisRoutes,
  GpPromoAnalysisRoutes,
  {
    path: '/config/facebook',
    name: 'FacebookConfig',
    component: FacebookConfig,
    props: false,
    meta: {Auth: false},
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
    meta: {Auth: false}
  },
  {
    path: '/restore',
    name: 'Restore',
    component: RestorePassword,
    props: false,
    meta: {Auth: false},
  },
  {
    path: '/restore/password',
    name: 'RestoreSet',
    component: RestorePasswordSet,
    props: false,
    meta: {Auth: false},
  },
  {
    path: '/restore/password',
    name: 'restorePasswordTokenCheck',
    component: RestorePasswordSet,
    props: false,
    meta: {Auth: false},
  }
]

export default routes
