<template>
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   width="16.902"
   height="16.902"
   viewBox="0 0 16.902 16.902"
   version="1.1"
   id="svg3729"
   sodipodi:docname="fr (1).svg"
   inkscape:version="0.92.4 (5da689c313, 2019-01-14)">
  
  <defs
     id="defs3733" />
  <sodipodi:namedview
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1"
     objecttolerance="10"
     gridtolerance="10"
     guidetolerance="10"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:window-width="2560"
     inkscape:window-height="1377"
     id="namedview3731"
     showgrid="false"
     inkscape:zoom="71.589159"
     inkscape:cx="8.4510002"
     inkscape:cy="9.5684878"
     inkscape:window-x="1072"
     inkscape:window-y="-8"
     inkscape:window-maximized="1"
     inkscape:current-layer="svg3729" />
  <g
     id="Grupo_8831"
     data-name="Grupo 8831"
     transform="rotate(90,8.4500055,8.4513503)">
    <path
       id="Trazado_2017"
       data-name="Trazado 2017"
       d="M 0.249,6.421 A 8.449,8.449 0 1 1 6.421,16.653 8.448,8.448 0 0 1 0.249,6.421"
       transform="translate(0.001)"
       inkscape:connector-curvature="0"
       style="fill:#0056a3" />
    <path
       id="Trazado_2018"
       data-name="Trazado 2018"
       d="M 11.512,5.767 H 27.481 A 8.544,8.544 0 0 0 21.533,0.256 8.442,8.442 0 0 0 11.512,5.766"
       transform="translate(-11.045)"
       inkscape:connector-curvature="0"
       style="fill:#da2308" />
    <path
       id="Trazado_2019"
       data-name="Trazado 2019"
       d="m 16.728,135.484 0.011,-0.051 0.019,-0.089 0.01,-0.052 q 0.009,-0.046 0.017,-0.092 l 0.009,-0.047 q 0.01,-0.058 0.019,-0.115 v -0.023 q 0.011,-0.069 0.021,-0.139 v -0.037 q 0.007,-0.051 0.013,-0.1 c 0,-0.016 0,-0.032 0.006,-0.048 0.006,-0.016 0.007,-0.061 0.01,-0.091 v -0.05 c 0,-0.031 0.006,-0.061 0.008,-0.092 0,-0.016 0,-0.031 0,-0.047 0,-0.035 0.005,-0.07 0.007,-0.106 v -0.032 q 0,-0.069 0.007,-0.139 c 0,-0.008 0,-0.015 0,-0.023 0,-0.038 0,-0.076 0,-0.115 0,-0.015 0,-0.029 0,-0.044 q 0,-0.047 0,-0.094 c 0,-0.016 0,-0.032 0,-0.048 0,-0.016 0,-0.062 0,-0.092 0,-0.015 0,-0.03 0,-0.046 0,-0.034 0,-0.068 0,-0.1 0,-0.012 0,-0.023 0,-0.035 Q 16.88,133.4 16.869,133.263 c 0,-0.012 0,-0.024 0,-0.036 0,-0.033 -0.006,-0.067 -0.009,-0.1 v -0.044 c 0,-0.031 -0.006,-0.063 -0.01,-0.094 l -0.005,-0.043 c 0,-0.033 -0.008,-0.067 -0.013,-0.1 v -0.035 q -0.019,-0.136 -0.042,-0.271 v -0.024 c -0.006,-0.036 -0.013,-0.073 -0.02,-0.109 l -0.007,-0.037 c -0.006,-0.033 -0.013,-0.065 -0.02,-0.1 l -0.008,-0.039 -0.022,-0.1 -0.008,-0.033 q -0.014,-0.061 -0.03,-0.122 v -0.009 q -0.016,-0.064 -0.033,-0.127 v 0 q -0.1,-0.352 -0.225,-0.693 H 0.5 a 7.766,7.766 0 0 0 -0.243,0.766 7.565,7.565 0 0 0 -0.145,3.179 v 0.014 q 0.01,0.056 0.02,0.112 v 0.024 l 0.021,0.1 0.007,0.031 c 0.007,0.033 0.014,0.066 0.021,0.1 l 0.008,0.037 c 0.007,0.031 0.014,0.062 0.022,0.093 l 0.011,0.043 0.022,0.087 0.018,0.066 c 0.009,0.033 0.018,0.066 0.027,0.1 l 0.028,0.1 0.013,0.043 c 0.009,0.031 0.018,0.061 0.028,0.091 l 0.014,0.043 0.03,0.091 0.014,0.042 0.032,0.091 0.015,0.041 0.034,0.091 0.008,0.021 v 0 l 0.021,0.056 h 15.859 l 0.021,-0.056 v 0 c 0.018,-0.047 0.035,-0.094 0.052,-0.141 l 0.005,-0.014 q 0.033,-0.093 0.064,-0.186 l 0.009,-0.026 c 0.019,-0.058 0.037,-0.117 0.055,-0.176 l 0.011,-0.037 q 0.031,-0.106 0.059,-0.212 c 0.012,-0.046 0.023,-0.092 0.035,-0.138 l 0.01,-0.044 c 0.007,-0.032 0.015,-0.063 0.022,-0.095"
       transform="translate(-0.008,-125.384)"
       inkscape:connector-curvature="0"
       style="fill:#f5f6f7" />
  </g>
</svg>

</template>

<script>
export default {
  name: "flag-fr"
}
</script>