<script>
import Chart from 'chart.js'
import { generateChart, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

// CUSTOMIZAMOS LA GRAFICA DE LINEA PARA AÑADIR BARRAS DE COLORES A LOS LATERALES
Chart.defaults.LineDays = Chart.defaults.line;
Chart.defaults.global.legend.labels.usePointStyle = true;
Chart.controllers.LineDays = Chart.controllers.line.extend({ 
  
})
const CustomLine = generateChart('custom-linear', 'LineDays')
export default {
  name: "valid_name",
  extends: CustomLine,
  mixins: [reactiveProp],
  props:['chartData', 'options'],
  data: () => ({       
  }),
  watch: {
    chartData () {
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted () {
    // MOSTRAMOS VALORES DEL EJE Y A IZQUIERDA Y DERECHA

    Chart.plugins.register({
      beforeInit: function(chart) {
        // MAXIMOS Y MINIMOS EN GRAFICA LINEAL
        let pasos = 2;
        if(chart.chart.config.type == 'LineDays'){
            if(chart.config.data.labels.length > 30){ 
            pasos = Math.ceil(chart.config.data.labels.length / 15);
            }
        
            chart.config.options.scales.xAxes[0].time.stepSize = pasos;
        }
      }
    });
    this.renderChart(this.chartData, this.options)
  }
}
</script>