// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
//
//
// business_category/
export default {
  getCategories(id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/center/${id}/mybusiness/services/categories-list/`
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getPricesTypes() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/mybusiness/services/prices-types/`
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getServicesByCategory(id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/center/${id}/mybusiness/services/list-by-categroy/`
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getServices(id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/center/${id}/mybusiness/services/`
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  setService(id, data, to_facebook = null, to_instagram = null) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/center/${id}/mybusiness/services/`
      let method = 'post'
      if (data.id) {
        method = 'put'
        query += data.id + '/'
      } 
      if (to_facebook || to_instagram) {
        query += '?'
      }
      if (to_facebook) {
        query += 'to_facebook=True'
      }
      if (to_instagram) {
        if (to_facebook) {
          query += '&'
        }
        query += 'to_instagram=True'
      }
      
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios[method](query, data)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  deleteService(id, service) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/center/${id}/mybusiness/services/`
      let method = 'delete'
      query += `${service}/`
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios[method](query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  sendServicesToGoogle(id) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/center/${id}/mybusiness/services/patch-to-google/`
      let method = 'get'
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios[method](query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  
  setServiceMultiple(data) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/local_marketing/batch_update/`
      let method = 'post'
      let token = SrvCommon.getToken()
      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios[method](query, data)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }
}
