<template lang="pug">
div
    success-state(v-if="showSuccess" :visible="showSuccess" @close="showSuccess=false" @confirmed="showSuccess=false")
    error-state(v-else-if="showError" :visible="showError" @close="showError=false" @confirmed="showError=false")
    v-dialog.dialogEdit(v-else v-model="show"
        width="680")
        v-card.dialogEdit
            v-card-text.pb-0
            v-container
                div.gr-loader-snipper--wrapper(v-if="loading")
                    span.gr-loader-snipper
                v-row#headerModalEdit.pt-3
                    v-col(cols="10")
                        span(v-if="!post && !isEditionMode") #[translate Añadir novedad]
                        span(v-else) #[translate Editar novedad]
                    v-col.mt-5(cols="2")
                        img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                v-row#bodyModalEdit
                    v-col(cols="12")
                        <Errors v-if="errors" :errors="errors" />
                    v-col(cols="6")
                        v-container
                            v-row
                                v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                                    div.messageError
                                        span {{ $gettext('An error occurred while recording.') }}
                                v-col.text-left.pb-0(cols="12")
                                    span #[translate Añade una descripción]
                                    span.text-red *
                                    textarea.inputFullEditModal.rounded(v-model="data.summary")
                                    span.text-12.text-red(v-if="errorSummary") Campo obligatorio
                                v-col.text-left.pb-0(cols="12" class="d-flex items-center")
                                    span.mr-2 #[translate Añadir botón]
                                    select.selectFullEditModal.rounded(v-model="data.actionType" class="flex-1")
                                        option(v-for="(boton, index) in buttons" :value="boton.name") {{ boton.name_es }}
                                v-col.text-left.pb-0(cols="12" :class="{'opacity-50': disabledUrlButton}")
                                    span #[translate Enlace de tu botón]
                                    input.inputFullEditModal.rounded(type="url"  :disabled="disabledUrlButton" v-model="data.actionTypeUrl")
                                v-col.text-left.pb-0(cols="12" v-if="isDemoFacebook && !data.createTime")
                                    p.mb-0 Publicar tambien en:
                                    v-row
                                        v-col.d-flex.items-center(cols="12")
                                            input.mr-1(type="checkbox" v-model="data.to_facebook")
                                            img.mr-4(src="@/assets/img/local/FCB.svg" width="25")
                                            input.mr-1(type="checkbox" v-model="data.to_instagram")
                                            img(src="@/assets/img/local/INS.svg" width="25")
                    v-col(cols="6")
                        <addPhotos class="mt-7" @removeImage="remove()" @loadImage="local_image = $event" :image="photo" :maxSize="5000000"></addPhotos>
                        <span class="mt-8 d-block">Programar esta publicación:</span>
                        <div class="d-flex">                                
                            <DatePicker v-model="data.dateProgramation" class="w-full flex-1 mr-1 max-w-auto rounded" id="dateProgramation" :disabled-date="disabledAfterStartDate" :open.sync="showDateProgramation"></DatePicker>
                            <label @click="showDateProgramation = true">
                                <img :src="imgCalendar" class="icoCalendar"  />
                            </label>
                            <input type="time" class="inputFullEditModal rounded w-auto ml-3" v-model="data.timeProgramation" />
                        </div>
                v-row#footerModalEdit
                    v-col(cols="12")
                        button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
                        button.btnSaveEditModal(v-if="idsBussinesModeMultiple" @click="showConfirm=true")
                            span(v-if="isEditionMode") #[translate Guardar cambios]
                            span(v-else) #[translate Publicar]
                        button.btnSaveEditModal(v-else @click="validate()")
                            span(v-if="isEditionMode") #[translate Guardar cambios]
                            span(v-else) #[translate Publicar]
                        button.btnDeleteEditModal(v-if="isEditionMode" @click="showDeleteModal=true")  
                            span #[translate Cancelar programación]
    modal-confirm-multiple-edit(:visible="showConfirm"  @close="showConfirm=false" @confirmed="validate()")
    modal-confirm-delete(:visible="showDeleteModal" @close="showDeleteModal=false" @confirmed="deleteEvent(data.id)")
</template>
<script>
import commonMixins from '@/mixins/common.js'
import SrvLocalPosts from '@/services/localPosts.js'
import DatePicker from 'vue2-datepicker';
import addPhotos from '@/components/local/posts/addPhotos.vue'
import ModalConfirmMultipleEdit from '@/components/local/confirmMultipleEdit.vue'
import ModalConfirmDelete from "@/components/local/confirmDelete.vue"
import Errors from '@/components/common/Errors.vue'
import SuccessState from "@/components/local/successState.vue"
import ErrorState from "@/components/local/errorState.vue"

import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es'
export default {
    mixins: [commonMixins],
    props: ['visible', 'idBussines', 'post', 'idsBussinesModeMultiple', 'editionData'],
    components: { DatePicker, addPhotos, ModalConfirmMultipleEdit, Errors, ModalConfirmDelete, SuccessState, ErrorState },
    data() {
        return {
            loading: false,
            showConfirm: false,
            showDeleteModal: false,
            showSuccess: false,
            showError: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            imgCalendar: require('@/assets/img/local/calendar.svg'),
            name: '',
            showDateInit: false,
            showDateEnd: false,
            showDateProgramation: false,
            errorSummary: false,
            errors: null,
            buttons: [],
            data: { topicType: 'STANDARD', local_image: null, summary: null, actionType: "NONE", actionTypeUrl: null, to_facebook: false, to_instagram: false, dateProgramation: null, timeProgramation: null },
            local_image: null,
            section: 'details-restaurant',
            saveStatus: true,
            disableKeys: ["CALL", "NONE"]
        }
    },
    watch: {
        editionData() {
            this.data = { ...this.data, ...this.editionData }
            if (this?.editionData?.local_image) this.local_image = this.editionData.local_image
        },
        visible(newValue) {
            if (newValue) {
                this.showError = false
                this.showSuccess = false
                if (!this.isEditionMode) {
                    if (this.post) {
                        this.data = Object.assign({}, this.post)
                        this.local_image = this.data.media.length > 0 ? this.data.media[0].googleUrl : null
                        this.data.actionType = this.data.callToAction ? this.data.callToAction.actionType : null
                        this.data.actionTypeUrl = this.data.callToAction ? this.data.callToAction.url : null
                        this.data.dateProgramation = null
                        this.data.timeProgramation = null
                    } else {
                        this.data = { topicType: 'STANDARD', local_image: null, summary: null, actionType: "NONE", actionTypeUrl: null }
                    }
                }
                this.loadData()
            }
        },
        summary(newVal) {
            if (newVal) {
                this.errorSummary = false
            }
        }
    },
    computed: {
        disabledUrlButton() {
            return !this.data.actionType || this.disableKeys.includes(this.data.actionType)
        },
        isEditionMode() {
            return this.editionData
        },
        photo() {
            return this.local_image
        },
        summary() {
            return this.data.summary
        },
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    methods: {
        emitSuccessEvent() {
            this.$emit("handleSuccess")
        },
        disabledAfterStartDate(date) {
            const today = new Date(this.$moment(this.data.startDate).add(1, 'days'));
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        async loadData() {
            try {
                const res = await SrvLocalPosts.getButtonActions()
                if (res.status === 200) {
                    this.buttons = res.data.filter(e => e.name !== 'ACTION_TYPE_UNSPECIFIED')
                    this.buttons = [...this.buttons, {
                        id: "none",
                        name: "NONE",
                        name_es: "Ninguno"
                    }]
                }
            } catch (error) {
                // console.error("[LOAD_DATA_ADD_NEW_ERROR]: ", error)
            }
        },
        async deleteEvent(id) {
            try {
                this.loading = true;
                await SrvLocalPosts.deleteScheduledPost(id)
                this.emitSuccessEvent();
                this.show = false;
                this.showSuccess = true;
            } catch (error) {
                this.showError = true;
            } finally {
                this.loading = false;
            }
        },
        validate() {
            let error = false
            if (!this.data.summary) {
                this.errorSummary = true
                error = true
            } else {
                this.errorSummary = false
            }

            if (!error) {
                this.save()
            }
        },
        remove() {
            delete this.data.media
            this.local_image = null
        },
        async save() {
            if (!this.loading) {
                this.loading = true
                const form = new FormData()
                form.append('topicType', 'STANDARD')
                if (this.local_image && !this.data.media) {
                    form.append('local_image', this.local_image)
                }
                if (this.data.summary) {
                    form.append('summary', this.data.summary)
                }

                if (this.data.actionType && this.data.actionType != "NONE") {
                    form.append('actionType', this.data.actionType)
                    form.append('actionTypeUrl', this.data.actionTypeUrl)
                }
                if (this.data.name) {
                    form.append('name', this.data.name)
                }
                let valid = true
                if (this.data.actionType && !this.disableKeys.includes(this.data.actionType) && !this.data.actionTypeUrl) {
                    valid = false
                    this.errors = 'El campo de enlace es obligatorio'
                }
                // Si es una creación simple de un post para un centro
                if (!this.idsBussinesModeMultiple && !this.data.dateProgramation && !this.data.timeProgramation) {
                    if (valid) {
                        const res = await SrvLocalPosts.setPost(this.idBussines, form, this.data.to_facebook, (this.local_image || this.data.media) && this.data.to_instagram ? true : false)
                        if (res.status === 200) {
                            this.$emit('saved', { data: res.data, 'action': 'update' })
                            this.$emit('close')
                        }
                        if (res.status === 201) {
                            this.$emit('saved', { data: res.data, 'action': 'create' })
                            this.$emit('close')
                        }
                    }
                }

                if ((this.data.dateProgramation && !this.data.timeProgramation) || (!this.data.dateProgramation && this.data.timeProgramation)) {
                    valid = false
                    this.errors = 'Si desea programar esta publicación debe completar el campo de fecha y de hora'
                }

                // si vienen del listado para crear un post para varios centros
                if (this.idsBussinesModeMultiple || (this.data.dateProgramation && this.data.timeProgramation)) {
                    let formMultiple = new FormData()
                    let objClean = {}
                    Object.entries(this.data).forEach((e) => {
                        if (e[1]) {
                            if (e[0] !== "actionType" || e[1] !== "NONE") {
                                objClean[e[0]] = e[1]
                            }
                        }
                    })

                    delete objClean.local_image
                    delete objClean.timeProgramation
                    delete objClean.dateProgramation
                    let centers = this.idsBussinesModeMultiple

                    if (!this.idsBussinesModeMultiple && this.data.dateProgramation && this.data.timeProgramation) {
                        centers = [this.idBussines]
                    }

                    if (this.data.dateProgramation && this.data.timeProgramation) {
                        formMultiple.append('scheduled_datetime', this.$moment(this.data.dateProgramation).format('YYYY-MM-DD') + '-' + this.data.timeProgramation + ':00')
                    }
                    formMultiple.append('post_details', JSON.stringify({ "center_ids": centers, "update_type": "POST", "post_details": objClean }))
                    if (this.local_image) {
                        formMultiple.append('image', this.local_image)
                    }
                    formMultiple.append('update_type', 'POST');
                    if (valid) {
                        try {
                            const res = this.isEditionMode ? await SrvLocalPosts.updateScheduledPost(formMultiple, this.data.id) : await SrvLocalPosts.setPostMultiple(formMultiple)
                            if (res.status === 200) {
                                this.show = false
                                this.showSuccess = true
                                this.emitSuccessEvent();
                            }
                        } catch (error) {
                            this.showError = true;
                        }
                    }
                }
                this.loading = false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.boxDeliveryService {
    background-color: #F2F2F2;
    border-radius: 4px;
    width: 100%;
    margin: 6px;

    &.noService {
        .urlDeliveryService {
            color: #CECECE;
        }
    }

    img {
        vertical-align: middle;
    }
}

.long {
    height: 136px;
}

.messageError {
    background-color: #F5B1B8;
    border: 1px solid #F93549;
    color: #F93549;
    padding: 15px;
}

#bodyModalEdit {
    .inputFullEditModal {
        &.textDisabled {
            background-color: #f8f8f8;
            color: #CECECE;
        }
    }
}

.icoCalendar {
    height: 36px;
}
</style>
