<template lang="pug">
div
  label(class="switch")
    input(type="checkbox" v-model="syncState" :disabled="status == 'disconnected'")
    span(class="slider round" :class="{'disabled': status == 'disconnected', 'cursor-inherit': !isExtended}")
</template> 

<script>
export default {
  props: {
    sync: {
      type: Boolean,
      default: false,
      required: true
    },
    pos: {
      type: Number,
      default: 0,
      required: true
    },
    id: {
      default: null,
      required: true
    },
    status: {
      type: String,
      default: null,
      required: true
    },
    isExtended: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  computed: {
    syncState: {
      get: function () {
        return this.sync
      },
      set: function (newValue) {
        this.$emit('changeSync', { key: this.pos, state: newValue, id: this.id })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #8A8A8A;
    -webkit-transition: .4s;
    transition: .4s;

    &.cursor-inherit {
      cursor: inherit;
    }

    &.disabled {
      background-color: #ccc;
    }

    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      inset: 0px;
      right: 10px;
      left: -2px;
      margin: auto;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    &.round {
      border-radius: 20px;

      &:before {
        border-radius: 50%;
      }
    }
  }
}

input:checked+.slider {
  background-color: #89C445;
}

input:focus+.slider {
  box-shadow: 0 0 1px #89C445;
}

input:checked+.slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}
</style>