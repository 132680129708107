<template lang="pug">
  div(style="height: 300px; width: 100%")    
    l-map(
      v-if="showMap"
      :zoom="zoom"
      :center="cords"
      :options="mapOptions"
      style="width: 100%"
      @update:zoom="zoomUpdate")
      l-tile-layer(
        :url="url"
        :attribution="attribution"
      )      
      l-marker(:lat-lng="cords" :icon="icon")
</template>

<script>
import L from "leaflet"
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon } from "vue2-leaflet"
import 'leaflet/dist/leaflet.css'
export default {
  name: "Example",
  props: ['long','lat'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon
  },
  data() {
    return {
      zoom: 17,      
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      
      currentZoom: 11.5,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true,
      icon: L.icon({
        iconUrl: require('@/assets/img/pin_gr.svg'),
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
    }
  },
  computed: {
    cords: function(){ 
      return [this.lat, this.long]
    }
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    innerClick() {
      alert("Click!");
    }
  }
};
</script>
