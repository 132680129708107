<template lang="pug">
div.boxSectionHeaderAndBody
  div#navtop(:class="{'anchoEscritorioSubnavCerrado': subNavClosed, 'noTree': $route.meta.isGP}")
    div.sectionmenu
      div.firstlevel
        router-link(:to="{name: 'SatisfactionQuiz', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Satisfaction surveys]
        router-link(v-if="1!=1" :to="{name: 'SatisfactionSac', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate SAC - WOE]
        select-country-and-logout
  div.gridSection
    div.sectionmenu
      div.secondlevel
        .quiz
          router-link(v-if="$router.history.current.name.indexOf('SatisfactionQuiz') >= 0" :to="{name: 'SatisfactionQuizIndicators', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Indicators]
          router-link(v-if="nodeLevel && $router.history.current.name.indexOf('SatisfactionQuiz') >= 0" :to="{name: 'SatisfactionQuizComments', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Comments]
          router-link(v-if="nodeLevel && $router.history.current.name.indexOf('SatisfactionQuiz') >= 0" :to="{name: 'SatisfactionQuizRankings', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Rankings]
        .sac
          router-link(v-if="$router.history.current.name.indexOf('SatisfactionSac') >= 0" :to="{name: 'SatisfactionSacEvolution', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Evolution]
          router-link(v-if="nodeLevel && $router.history.current.name.indexOf('SatisfactionSac') >= 0" :to="{name: 'SatisfactionSacClaims', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Claims]
          router-link(v-if="nodeLevel && $router.history.current.name.indexOf('SatisfactionSac') >= 0" :to="{name: 'SatisfactionSacSuggestions', params: {paramCOD: this.paramCOD}, query: queryParams}") #[translate Suggestions]
      div.pr-5.thirstlevel(:class="{'pt-12': this.$router.history.current.name.indexOf('Satisfaction') >= 0}")
        v-row.flex-center
          v-col.pr-0
            bread-crumbs( :paramCOD="paramCOD")
          v-col.col-filters.col-filters-big-center.py-0
            div.filters
              span.downloadExcel(v-if="showDownloadInHome" @click="downloadExcel()")
                img(:src="imgCommons.download" width="10")
              div.gr-filter-input-wrapper(v-if="typeNode !== 'center'")
                span.label #[img.mr-2(:src="img.imgRestaurant")] #[translate Type of Restaurant]
                div.menu
                  filter-tree-menu(label-class="text-capitalize-first-letter" :options="restaurantTypes" :itemsChecked="typeRestaurantSelected" @checked="onCheckRestaurantTypes" id-key="value" label-key="text")
              div.filter.filtroDate
                range-date
      router-view.bg-gray

</template>

<script>
import RangeDate from '@/components/filters/RangeDates.vue'
import MultiSelect from '@/components/filters/Multipleselect.vue'
import BreadCrumbs from '@/components/common/Breadcrumbs.vue'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import FilterTreeMenu from "@/components/filters/filterTreeMenu"
import SelectCountryAndLogout from "@/components/common/SelectCountryAndLogout.vue"

export default {
  props: ['paramCOD'],
  mixins: [commonMixins],
  data() {
    return {
      img: {
        imgRestaurant: require('@/assets/img/icon_restaurant.png')
      },
    }
  },
  components: { FilterTreeMenu, RangeDate, MultiSelect, BreadCrumbs, SelectCountryAndLogout },
  created() {
    this.$store.commit(types.UPDATE_SECTION, 3)
  }
}
</script>

<style lang="scss" scoped>
#satisfactionhome {
  background-color: #F2F2F2;
  height: 100%;
  border-top-left-radius: 30px;
}
</style>
