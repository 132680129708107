<template lang="pug">
div.margin-bottom-0.col-12
    skeleton-card(v-if="isLoading")        
    v-card.gridFull.ma-0(v-if="!isLoading" :class="{'borderDelivery': typeOpinionSelected === 'delivery'}")
        .d-flex.headTitleGrid
            .block-title.titleGrid
                translate(v-if="!isGenericEntity").mb-0.title-card Ranking of restaurants last month
                translate(v-else).mb-0.title-card Business ranking last month
                span.ml-1.tDelivery(v-if="typeOpinionSelected === 'delivery'") Delivery
            .block-help.ml-auto
                v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="20" nudge-left="10" content-class="tooltip-help tooltip-help-left")
                    template(v-slot:activator="{ on, attrs }")
                        img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                    span {{helpRankinkRestaurantLastYear}}
                v-icon.mb-1(v-if="typeNode!=='group'" @click="goNetworkValoration()") mdi-chevron-right
        .card-content
            .row.text-left
                v-col.col-6
                    .tableGlobal
                        div.headerTableRanking.row.ma-0
                            div.headerItem.col-top5
                                p.mb-0 #[translate Top 5]
                            div.headerItem.col-nombre
                                p.mb-0.text-left #[translate Name]
                            div.headerItem.col-valoracion
                                p.mb-0 #[translate Assessment]
                            div.headerItem.col-marca
                                p.mb-0.text-left(v-if="typeNode==='group'") #[translate Brand]
                                p.mb-0.text-left(v-else) #[translate Zona]
                            div.headerItem.col-provincia
                                p.mb-0.text-left #[translate Province]

                        div.contentTableRanking.row.ma-0
                            p.ml-3(v-if="!data") #[translate No data]
                            div.itemContent.col-12.pa-0(v-for="(item, index) in data[0].top_centers" :key="index")
                                div.item.col-top5
                                    span {{item.rating_global_ranking}}
                                div.item.col-nombre
                                    p.mb-0.text-left 
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.ellipsis1Col(v-bind="attrs" v-on="on") {{item.name}}
                                            span {{item.name}}
                                div.item.col-valoracion
                                    p.mb-0.box(:style="'background-color:' + getColorNote(item.rating_global,'color')") {{item.rating_global | formatNumber}}
                                div.item.col-marca
                                    p.mb-0.text-left {{ item.level.name }}
                                div.item.col-provincia
                                    p.mb-0.text-left {{ item.region }}

                v-col.col-6
                    .tableGlobal
                        div.headerTableRanking.row.ma-0
                            div.headerItem.col-bottom5
                                p.mb-0 #[translate Bottom 5]
                            div.headerItem.col-nombre
                                p.mb-0.text-left #[translate Name]
                            div.headerItem.col-valoracion
                                p.mb-0 #[translate Assessment]
                            div.headerItem.col-marca
                                p.mb-0.text-left(v-if="typeNode==='group'") #[translate Brand]
                                p.mb-0.text-left(v-else) #[translate Zona]
                            div.headerItem.col-provincia
                                p.mb-0.text-left #[translate Province]

                        div.contentTableRanking.row.ma-0
                            p.ml-3(v-if="!data") #[translate No data]
                            div.itemContent.col-12.pa-0(v-for="(item, index) in data[0].bottom_centers" :key="index")
                                div.item.col-bottom5
                                    span {{item.rating_global_ranking}}
                                div.item.col-nombre
                                    p.mb-0.text-left
                                        v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                                            template(v-slot:activator="{ on, attrs }")
                                                span.ellipsis1Col(v-bind="attrs" v-on="on") {{item.name}}
                                            span {{item.name}}
                                div.item.col-valoracion
                                    p.mb-0.box(:style="'background-color:' + getColorNote(item.rating_global,'color')") {{item.rating_global | formatNumber}}
                                div.item.col-marca
                                    p.mb-0.text-left {{ item.level.name }}
                                div.item.col-provincia
                                    p.mb-0.text-left {{ item.region }}
                                    

</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import commonMixins from "@/mixins/common.js";
import brandMixin from '@/mixins/brand.js'

export default {
    props: ['data'],
    components: { SkeletonCard },
    mixins: [commonMixins, brandMixin],
    data() {
        return {
            helpRankinkRestaurantLastYear: this.$gettext('Summary of the 5 best and worst restaurants of the company in the selected period. It is analyzed about the group of restaurants that marks the selection in the left menu')
        }
    },
}
</script>

<style lang="scss" scoped>
.tableGlobal {
    .col-top5 {
        max-width: 12.33%;
        flex: 0 0 12.33%;
        margin-right: 2.5%;
        padding-left: 10px;
        text-align: left;
    }

    .col-bottom5 {
        max-width: 12.33%;
        flex: 0 0 12.33%;
        margin-right: 2.5%;
        padding-left: 10px;
        text-align: left;
        letter-spacing: 0.012px;
    }

    .col-valoracion {
        max-width: 10.47%;
        flex: 0 0 10.47%;
        margin-right: 4.5%;
        justify-content: left !important;

        .box {
            width: 50px;
            border-radius: 5px;
            color: #fff;
        }
    }

    .col-marca {
        max-width: 24.47%;
        flex: 0 0 24.47%;
        margin-right: 4.5%;
        text-align: left;
    }

    .col-provincia {
        max-width: 12.47%;
        flex: 0 0 12.47%;
        text-align: left;
        margin-right: 2%;
    }

    .col-nombre {
        max-width: 26.47%;
        flex: 0 0 26.47%;
        text-align: left;
        // color:#4A90E2;
    }

    .headerTableRanking {
        .headerItem {
            text-align: left;
            font-size: 0.75rem;
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            color: rgba(22, 22, 22, 0.8);

            &.col-marca {
                text-align: left;
                justify-content: initial;
            }

            &.col-provincia {
                text-align: left;
                justify-content: initial;
            }

            &.col-nombre {
                text-align: left;
                justify-content: initial;
            }

            &.col-top5 {
                color: #89C445;
                text-align: left;
                justify-content: initial;
            }

            &.col-bottom5 {
                color: #D0021B;
                text-align: left;
                justify-content: initial;
            }

            img {
                cursor: pointer;
            }
        }
    }

    .contentTableRanking {
        .itemContent {
            text-align: center;
            font-size: 0.875rem;
            border-top: 1px solid #9797973d;
            height: 41px;
            display: flex;
            align-items: center;
        }
    }
}
</style>