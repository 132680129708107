import "es6-promise/auto";

// Importamos paquetes basicos
import Vue from "vue";
import vuetify from "@/plugins/vuetify"; // path to vuetify export
import "vuetify/dist/vuetify.min.css";

// Configuración
import Configuration from "@/plugins/configuration";
import appConfig from "@/config-app.js";

// Rutas
import VueRouter from "vue-router";
import routes from "@/router";

// Stores
import stores from "@/store/index";

// Axios
import axios from "axios";

// Moment
import VueMoment from "vue-moment";

// App
import App from "@/App.vue";

import VueLodash from "vue-lodash";
import lodash from "lodash";
//Cookies reactivas
import VueCookies from "vue-cookies-reactive";

// Idioma
import GetTextPlugin from "vue-gettext";
import translations from "@/translations/translation.json";
// eslint-disable-next-line no-unused-vars
import SrvCommon from "@/services/common";
import SrvLocal from "@/services/local";
// eslint-disable-next-line no-unused-vars
import * as types from "@/store/mutation-types";

import { processFilter } from "@/services/filters";
import setQuery from "@/services/setquery.js";

import { getTreeAboutConfig } from "@/services/tree.js";
import configApp from "@/config-app.js";

// import from flag dependency to render icons
import "/node_modules/flag-icons/css/flag-icons.min.css";

// Cargamos iconos y fuentes para vuetify
let iconosVuetify = document.createElement("link");
iconosVuetify.href = "https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css";
iconosVuetify.rel = "stylesheet";
document.querySelector("head").appendChild(iconosVuetify);

let iconosVuetify2 = document.createElement("link");
iconosVuetify2.href = "https://fonts.googleapis.com/css?family=Material+Icons";
iconosVuetify2.rel = "stylesheet";
document.querySelector("head").appendChild(iconosVuetify2);

Vue.config.productionTip = false;

Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg !== 'Invalid prop: custom validator check failed for prop "selectionType".') {
    const hasConsole = typeof console !== "undefined";
    if (hasConsole && !Vue.config.silent) {
      console.error(`[Vue warn]: ${msg}${trace}`);
    }
  }
};

// Configuración para idiomas
Vue.use(GetTextPlugin, {
  availableLanguages: {
    es: "Español",
    en: "Inglés",
    pt: "Portugues",
  },
  defaultLanguage: appConfig.language,
  languageVmMixin: {
    computed: {
      currentKebabCase: function () {
        return this.current.toLowerCase().replace("_", "-");
      },
    },
  },
  translations: translations,
  silent: true,
});
Vue.use(Configuration, appConfig);
Vue.use(VueRouter);
Vue.use(VueMoment);
Vue.use(VueLodash, { name: "custom", lodash: lodash });
Vue.use(VueCookies);
// Configuración del router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 2500);
      });
    } else {
      return { x: 0, y: 10 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  document.body.className = `body-${to.name}`;
  stores.commit("PENDING");
  stores.commit(types.GLOBAL_LOADING, true);
  let processResult = {
    queryHasChanged: false,
  };

  // Comprobamos si la ruta necesita autentificación y si el usuario esta logado
  let poToken = window.localStorage.getItem("_token") || window.sessionStorage.getItem("_token");
  if (to.meta.Auth && !stores.state.user.isLoggedIn) {
    if (poToken) {
      stores.commit("USER_NAME", window.localStorage.getItem("_user") || window.sessionStorage.getItem("_user"));
      await stores.dispatch("autoLogin");
    } else {
      next({ path: "/" });
    }
  }

  //OBTENEMOS LA INFO DEL USUARIO Y CARGAMOS EL ARBOL NECESARIO
  if (stores.state.user.isLoggedIn) {
    stores.commit("LOGIN_SUCCESS");
    if (!stores.getters.getTreeSelected) {
      await SrvCommon.getAccount(stores.state.common.userInfo.default_node ?? to.query.tree);
      await getTreeAboutConfig();
    }
  }

  //OBTENEMOS ELEMENTO ACTIVO
  //SETEAMOS LOS QUERYS EN STORE
  setQuery(to);

  //CARGAMOS CATEGORIAS EN LAS VISTAS
  // CARGAR CATEGORIAS SI LA SECCIÓN ES UNA DE LAS CITADAS EN EL ARRAY
  if (stores.state.user.isLoggedIn) {
    if (to.meta.loadCategories) {
      await stores.dispatch("getCategories");
      // COMPROBAMOS CATEGORIA SELECCIONADA DENTRO DE LAS CARGADAS POR EL ELEMENTO ACTIVO
      if (stores.getters.getOpinionsCategoriesSelected && stores.getters.getOpinionsCategoriesSelected.length > 0) {
        let deleteFilterCategory = true;
        stores.getters.getCategories["internet"].categories.forEach((e) => {
          if (e.id == stores.getters.getOpinionsCategoriesSelected[0].value) {
            deleteFilterCategory = false;
          }
        });
        if (deleteFilterCategory) {
          stores.commit(types.FEEDBACK_FILTER_OPINIONS_CATEGORIES_SELECTED, []);
        }
      }
    }
  }

  // SETEAMOS TIPO A INTERNET SOLO EN VISTA FEEDBACK/OPINIONES
  let change = false;
  if (!stores.getters.getTypesSelected && (to.name === "FeedBackOpinions" || to.name === "FeedBackKeywords")) {
    await stores.commit(types.COMMON_TYPES_SELECTED, "internet");
    await stores.commit(types.COMMON_TYPES_SELECTED_INTERNET_AUTOMATIC, true);
  }

  if (configApp.brand() === "adverbia" && to.meta.disabledForAdverbia) {
    next({ name: "NetworkHome" });
  }

  //CONTROLAMOS EL USER INFO PARA PODER COMPROBAR SECCIONES
  if (stores.getters.getUserInfo) {
    let withoutAccess = false;
    // CONTROLAMOS QUE SOLO ACCEDAN A LA VISTA LOS QUE TIENEN ACTIVOS LOS INFORMES
    if (!stores.getters.getUserInfo?.has_reports && to.name === "ReportsList") {
      withoutAccess = true;
    }
    // CONTROLAMOS QUE SOLO ACCEDAN A LA VISTA LOS QUE TIENEN ACTIVO GP (SOLO ALSEA)
    if (!stores.getters.getUserInfo?.config?.has_gp && to.meta.isGP) {
      withoutAccess = true;
    }
    // CONTROLAMOS QUE SOLO ACCEDAN A LA VISTA LOS QUE TIENEN ACTIVO GP (SOLO ALSEA)
    if (!stores.getters.getUserInfo?.config?.has_delivery_analytics && to.name === "NetworkAggregators") {
      withoutAccess = true;
    }

    if (!stores.getters.getUserInfo?.has_excel_reports && to.name === "ReportsListExcel") {
      withoutAccess = true;
    }

    if (stores.getters.getUserInfo.email && stores.getters.getUserInfo.email.indexOf("@gastroranking.es") === -1 && stores.getters.getUserInfo.email.indexOf("@theysay.me") === -1 && to.name === "rateComparatorPizzas") {
      withoutAccess = true;
    }
    if (withoutAccess) {
      next({ path: "/global", params: to.params, query: to.query });
    }
  }

  if (stores.state.user.isLoggedIn) {
    if (to.params.paramCOD) {
      let partes = to.params.paramCOD.split("-");
      stores.commit(types.SET_NODE, partes[partes.length - 2]);
      let slug = to.params.paramCOD.toString().substr(0, to.params.paramCOD.toString().lastIndexOf("-"));
      if (partes[partes.length - 1] === "C") {
        stores.commit(types.SET_NODE_LEVEL, 9);
      } else {
        Vue.nextTick(() => {
          let elementoTree = document.querySelector('[data-id="' + partes[partes.length - 2] + '"][data-type="N"]');
          stores.commit(types.SET_NODE_LEVEL, elementoTree.getAttribute("data-level"));
        });
      }

      stores.commit(types.BREAD_CRUMB, slug);

      // TEMA PETICIONES COMPROBAR CON TIEMPO
      if (!stores.getters.processFilter) {
        processResult = await processFilter(to.query, to.name);
      }

      Vue.nextTick(() => {
        // SI EL PRIMER NODO DEL PARAMCOD NO ES EL PRIMERO DEL ARBOL OBTENEMOS EL SLUG CORRECTO
        // CUANDO LLEGAN AL DASHBOARD DESDE ENLACES EXTERNOS COMO LOS EMAILS DE NOTIFICACIONES
        let partesSlug = slug.split("-");
        if (!from.name && !document.querySelector('[data-slug="' + slug + '"]')) {
          let itemValidado = document.querySelector('[data-id="' + partesSlug[partesSlug.length - 1] + '"][data-type="' + to.params.paramCOD.toString().slice(-1) + '"]');
          router.push({ name: to.name, params: { paramCOD: itemValidado.getAttribute("data-slug") + "-" + to.params.paramCOD.toString().slice(-1) }, query: to.query });
        }
        const firstSlug = slug.split("-").length ? slug.split("-")[0] : slug;
        let firstSlugDOM = undefined;

        if (stores.getters.getShowTree) {
          firstSlugDOM = document.querySelector('[data-slug="' + firstSlug + '"]');

          firstSlugDOM.scrollIntoView({ block: "start", behavior: "smooth" });

          let abiertos = document.querySelectorAll(".dropdownTreeOpen");
          let slugWithOutLastItem = slug.toString().lastIndexOf("-") > -1 ? slug.toString().substr(0, slug.toString().lastIndexOf("-")) : slug;
          let todos = document.querySelectorAll(".dropdownTree");
          for (let i = 0; i < abiertos.length; i++) {
            abiertos[i].click();
          }
          for (let x = 0; x < todos.length; x++) {
            const id = todos[x].getAttribute("data-id").toString();

            const regex = new RegExp(`\\b${id}\\b`, "gi");

            const matched = slugWithOutLastItem.toString().match(regex);

            if (matched && matched.length > 0) {
              todos[x].click();
            }
          }

          window.scrollTo(0, 0);

          Vue.nextTick(() => {
            setTimeout(() => {
              stores.dispatch("setRouteLoading", false);
              if (!to.query.no_tree) {
                window.scrollTo(0, 0);
                let elementoActivoArbol = document.querySelector('[data-slug="' + slug + '"]');
                elementoActivoArbol.scrollIntoView({ block: "start", behavior: "smooth" });
              }
              // SETEAMOS SI TIENE DELIVERY
              let itemTreeDomActive = document.querySelector('[data-slug="' + slug + '"]');
              if (itemTreeDomActive && itemTreeDomActive.getAttribute("data-has-delivery")) {
                stores.commit(types.COMMON_HAS_DELIVERY, true);
              } else {
                stores.commit(types.COMMON_HAS_DELIVERY, false);
              }
              // SETEAMOS SI TIENE COMPPETENCIA
              if (itemTreeDomActive && itemTreeDomActive.getAttribute("data-has-competence")) {
                stores.commit(types.COMMON_HAS_COMPETENCE, true);
              } else {
                stores.commit(types.COMMON_HAS_COMPETENCE, false);
              }
              // SETEAMOS SI TIENE EMPLOYEES
              if (itemTreeDomActive && itemTreeDomActive.getAttribute("data-has-employees")) {
                stores.commit(types.COMMON_HAS_EMPLOYEES, true);
              } else {
                stores.commit(types.COMMON_HAS_EMPLOYEES, false);
              }
              // SETEAMOS SI TIENE EMPLOYEES
              if (itemTreeDomActive && itemTreeDomActive.getAttribute("data-has-dishkeywords")) {
                stores.commit(types.COMMON_HAS_DISHKEYWORDS, true);
              } else {
                stores.commit(types.COMMON_HAS_DISHKEYWORDS, false);
              }
              // SETEAMOS SI TIENE CUSTOM SURVEYS
              if (itemTreeDomActive && itemTreeDomActive.getAttribute("data-has-custom-surveys")) {
                stores.commit(types.COMMON_HAS_CUSTOM_SURVEYS, true);
              } else {
                stores.commit(types.COMMON_HAS_CUSTOM_SURVEYS, false);
              }
            }, 30);
          });
        } else {
          stores.dispatch("setRouteLoading", false);
        }
      });
    }
  }

  stores.commit("NOT_PENDING"); // Para quitar el preload
  if (processResult.queryHasChanged) {
    stores.commit(types.COMMON_DO_NOT_PROCESS_FILTER, false);
    next({ path: to.path, params: to.params, query: processResult.queryHasChanged });
  } else if (change) {
    next({ path: to.path, params: to.params, query: to.query });
  } else {
    next();
  }
  if (stores.getters.getUserInfo?.premium_localmarketing) {
    const res = await SrvLocal.getNotificationStats();
    if (res.status === 200) {
      stores.commit(types.LOCAL_MARKETING_NOTIFICATIONS_STATS, res.data);
    }
  }
});

// number filter
Vue.filter("formatNumber", (val, min = 0, max = 1) => {
  if (typeof val !== "string") {
    if (val === null) {
      return "-";
    }
    return Number(val).toLocaleString("de-De", { minimumFractionDigits: min, maximumFractionDigits: max });
    // se deja en alemán para mostrar punto decimal en los miles return Number(val).toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
  }
  return val;
});
Vue.filter("RoundedNumber", (val) => {
  if (typeof val !== "string") {
    if (val === null) {
      return "-";
    }
    return Math.round(val);
  }
  return val;
});

Vue.filter("ValueFromPercent", (val, percent) => {
  if (typeof val == "number" && typeof percent == "number") {
    return (val * percent) / 100;
  }
  return val;
});

Vue.filter("PercentFromValue", (val, base) => {
  if (typeof val == "number" && typeof base == "number") {
    return (val * 100) / base;
  }
  return val;
});

Vue.filter("IntegerValue", (val) => {
  if (typeof val == "number") {
    return Math.trunc(val);
  }
  return val;
});

Vue.filter("Capitalize", (val) => {
  if (typeof val == "string") {
    return val.charAt(0).toUpperCase() + val.slice(1);
  }
  return val;
});

// Creamos app
new Vue({
  router: router,
  store: stores,
  axios,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
