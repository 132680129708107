<template lang="pug">
v-menu(v-model="menu" :content-class="classFixedMenuVuetify" :close-on-content-click="false" :nudge-width="100" absolute offset-y)
  template(v-slot:activator="{ on, attrs }")
    button.selector.text-left.primary--text.d-flex(v-bind="attrs" v-on="on")
      span.flex-fill.text-left
        | {{ OptionSelected === 'internet' || OptionSelected === 'delivery' ? OptionSelected.charAt(0).toUpperCase() + OptionSelected.slice(1) : OptionSelected  }}
      v-icon.gr-filter-icon(small color="#4EA5F5" :class="{ 'gr-filter-icon-open' : menu }")
        | mdi-chevron-down
      span.numberItemsSelected(v-if="selectedChild.length > 1") +{{selectedChild.length - 1 }}
  v-card
    v-card.overflow-y-auto.gr-filter-tree-menu_treeview(flat)
      v-card-text
        v-list.oneSelectTreeList.pa-0
          ul.pl-0
            li.px-2(v-for="(item, key) in options")
              v-checkbox.mt-0.mb-3.pt-0(
                v-model= "selected"
                color="primary"
                :label="item.name"
                :value="key"
                hide-details
                :class="{'d-none': onlyNetwork && key !== 'internet'}"
                @click="cleanChild()"
              )
                template(slot="label")
                  a {{ item.name }}
              ul.treeChild(v-if="item.sites && item.sites.length > 0" :class="{'d-none': onlyNetwork && key !== 'internet'}")
                li(v-for="elem in item.sites")
                  v-checkbox.mt-0.mb-3.pt-0(
                    v-model= "selectedChild"
                    multiple
                    color="primary"
                    :label="elem.name"
                    :value="elem.code"
                    :name="item.name"
                    @click="setChild(elem, item)"
                    hide-details

                  )
                    template(slot="label")
                      a {{ elem.name }}

    v-divider
    v-card-actions.justify-center
      v-btn.px-8(color="primary" elevation="1" tile small @mousedown="filter")
        | #[translate Apply]



</template>

<script>
export default {
  name: 'OneSelectTree',
  props: {
    'options': [], 'itemsChecked': String, 'itemCheckChild': [], onlyNetwork: {
      type: Boolean,
      required: false,
      default: false
    },
    'classFixedMenuVuetify': {
      default: '',
      type: String,
      required: false
    },
  },
  data() {
    return {
      menu: false,
      selected: '',
      selectedChild: [],
      selectedChildName: '',
      label: this.$gettext('All')
    }
  },
  created() {
    this.selected = this.itemsChecked
    // pasamos los ids checados a code
    if (this.selected && this.options[this.selected]) {
      this.options[this.selected].sites.forEach((s) => {
        if (this.itemCheckChild.includes(s.id)) {
          this.selectedChild.push(s.code)
        }
      })
    }
    //this.selectedChild = this.itemCheckChild
  },
  mounted() {
    if (this.options !== '' && this.options.length === undefined) {
      //controlar el poner +1 con varios elementos
      this.getChildName(this.selectedChild[this.selectedChild.length - 1])
    }
  },
  watch: {
    itemsChecked() {
      this.selected = this.itemsChecked
    },
    itemCheckChild() {
      this.selectedChild = []
      if (this.selected && this.options[this.selected]) {
        this.options[this.selected].sites.forEach((s) => {
          if (this.itemCheckChild.includes(s.id)) {
            this.selectedChild.push(s.code)
          }
        })
      }
    },
    options() {
      if (this.options !== '' && this.selectedChild.length > 0 && this.selected !== '') {
        this.getChildName(this.selectedChild[this.selectedChild.length - 1])
      }
    }
  },
  methods: {
    filter: function () {
      document.getElementById("navbar-limit").click()

      let idsChecked = []
      if (this.options[this.selected] && this.options[this.selected].sites) {
        this.options[this.selected].sites.forEach((s) => {
          if (this.selectedChild.includes(s.code)) {
            idsChecked.push(s.id)
          }
        })
      }
      this.$emit('checked', this.selected, idsChecked)
    },
    setChild(val, father) {
      //controlar que no se marquen de diferentes padres
      if (this.selected === father.name.toLowerCase()) {
        this.selected = father.name.toLowerCase()
        this.selectedChildName = this.selectedChildName !== val.name ? val.name : ''
      } else {
        this.selected = father.name.toLowerCase()
        this.selectedChildName = this.selectedChildName !== val.name ? val.name : ''
        this.selectedChild = [val.code]
      }
    },
    cleanChild() {
      this.selectedChildName = ''
      this.selectedChild = ''
    },
    getChildName(id) {
      let noParent = true
      let newFilter = false
      this.selectedChildName = ''
      Object.entries(this.options).forEach(([key, value]) => {
        if (this.selected === key) {
          noParent = false
        }
        if (value.sites.length > 0) {
          value.sites.forEach(elem => {
            if (elem.id == id) {
              this.selectedChildName = elem.name
            }
          })
        }
      })
      if (this.selectedChildName === '') {
        //this.cleanChild()
        newFilter = true
      }
      if (this.selected !== '' && noParent) {
        this.selected = ''
        newFilter = true
      }
      if (newFilter) {
        this.filter()
      }
    },
  },
  computed: {
    OptionSelected() {
      if (this.selectedChild != '' && this.selectedChild !== null) {
        let name = ''
        if (this.options[this.selected] && this.options[this.selected].sites.length > 0) {
          name = this.options[this.selected].sites.filter(s => s.code === this.selectedChild[this.selectedChild.length - 1])[0].name
        }
        return name
      } else if (this.selected != '' && this.selected !== null) {
        return this.selected
      } else {
        return this.$gettext('All')
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.topDoubleMenu {
  top: 180px !important;
}

.filtrar {
  text-align: center;
  border-top: 1px solid #E2E2E2;
  padding: 2px 15px;

  button {
    background-color: #00ADFC;
    color: #fff;
    padding: 5px 40px;
    margin-top: 10px;
    font-weight: 600;
  }

}

.selector {
  position: relative;

  .numberItemsSelected {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #1976d2 !important;
    border-color: #1976d2 !important;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    height: 20px;
    letter-spacing: 0;
    min-width: 20px;
    padding: 4px 6px;
    pointer-events: auto;
    position: absolute;
    text-align: center;
    text-indent: 0;
    top: -8px;
    transition: .3s cubic-bezier(.25, .8, .5, 1);
    white-space: nowrap;
    display: inline-block;
    line-height: 1;
  }
}
</style>
