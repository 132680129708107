<template>
    <button class="buttonEditItem d-flex items-center px-3 py-1  text-14">
        <slot></slot>
    </button>
</template>

<script>
export default{

}
</script>

<style scoped>
.buttonEditItem{
    background-color: #F2F2F2;
    color: #222226;
    border-radius: 8px;
    
}
</style>