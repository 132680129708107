<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12" height="12" viewBox="0 0 12 12">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectángulo_107" data-name="Rectángulo 107" width="12" height="12" fill="none"/>
      </clipPath>
    </defs>
    <g id="Grupo_8830" data-name="Grupo 8830" transform="translate(0.146 0.049)">
      <g id="Grupo_8784" data-name="Grupo 8784" transform="translate(-0.146 -0.049)" clip-path="url(#clip-path)">
        <path id="Trazado_2013" data-name="Trazado 2013" d="M.176,4.533a5.964,5.964,0,1,1,4.357,7.223A5.964,5.964,0,0,1,.176,4.533" transform="translate(0.103 0.034)" fill="#002a5d"/>
        <path id="Trazado_2014" data-name="Trazado 2014" d="M10.2,1.766l-2.4,1.6V.3Q7.6.233,7.4.182A5.936,5.936,0,0,0,4.12.3V3.365L1.731,1.773A5.924,5.924,0,0,0,.437,3.739l.635.424H.283q-.059.186-.107.378A5.934,5.934,0,0,0,.3,7.841h.781L.46,8.261a6,6,0,0,0,1.313,1.952L4.12,8.649v2.993q.2.066.415.119A5.935,5.935,0,0,0,7.8,11.647v-3l2.357,1.571a5.925,5.925,0,0,0,1.316-1.95l-.639-.424h.8q.071-.212.126-.438a5.938,5.938,0,0,0-.106-3.247h-.8l.645-.43a5.993,5.993,0,0,0-1.3-1.962" transform="translate(0.101 0.029)" fill="#f4f2ed"/>
        <path id="Trazado_2015" data-name="Trazado 2015" d="M3.211,4.162,1,2.689a5.912,5.912,0,0,0-.4.706l1.156.767Zm7.734-1.484a5.993,5.993,0,0,0-.494-.642L7.8,3.8v.366H8.71Zm-9.932,6.6a5.986,5.986,0,0,0,.494.641L4.13,8.175V7.842H3.172ZM11.85,4.928H7.042V.105a5.929,5.929,0,0,0-2.148,0V4.928H.105A5.929,5.929,0,0,0,.114,7.075H4.9v4.763a5.929,5.929,0,0,0,2.148,0V7.076h4.8a5.929,5.929,0,0,0,.012-2.147M8.767,7.844l2.171,1.444a5.908,5.908,0,0,0,.4-.7l-1.116-.742Z" transform="translate(0.092 0.028)" fill="#da2308"/>
        <path id="Trazado_2016" data-name="Trazado 2016" d="M196.877,191.063h0v.387a.689.689,0,0,0,.036-.208.437.437,0,0,0-.036-.179" transform="translate(-190.826 -185.256)" fill="#112f53"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "flag-es"
}
</script>