<template lang="pug">
    div
        v-dialog(v-model="dialogLimitExcededExcel"
            hide-overlay
            width="459")
            v-card
                v-card-text.pb-0
                v-container
                    v-row#headModalExcededLimit.text-center
                        v-col.mt-5(cols="12")
                            img.icoWarning(:src="imgCommons.warning" width="140" height="140")
                            img.close-modal.cursor-pointer(:src="imgCommons.close" @click="dialogLimitExcededExcel=false")
                    v-row.mt-15
                        v-col.boxInfo(cols="12").mt-5.text-center
                            strong #[translate DISCHARGE LIMIT]
                            p #[translate Esta peticion supera el limite de 100.000 elementos. Por favor, reduzca el rango de fechas e inténtelo de nuevo.]
                            button.btn.pr-10.pl-10.pt-3.pb-3.mb-5(@click="dialogLimitExcededExcel=false") #[translate Understood]
</template>

<script>
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"

export default {
    mixins: [commonMixins],
    computed: {
        dialogLimitExcededExcel: {
            get: function () {
                return this.$store.getters.getLimitExcededExcel
            },
            set: function (newValue) {
                this.$store.commit(types.LIMIT_EXCEDED_EXCEL, newValue)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#headModalExcededLimit{
    background-color: #DBE1E1;
}
.close-modal{
    position: absolute;
    top:15px;
    right: 15px;
}
.icoWarning{
    margin-bottom: -70px;
}
.btn{
    background-color: #F93549;
    color:#fff;
    font-size: 0.875rem;
    border-radius: 4px;
}
.boxInfo{
    color: #4D4F5C;
    font-size: 1rem;
    p{
        max-width: 344px;
        margin-left:auto;        
        margin-right: auto;
    }
}
</style>