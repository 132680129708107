import stores from "@/store";
import * as types from "@/store/mutation-types";
import SrvCommon from "@/services/common.js";
import appConfig from "@/config-app.js";

const removeDuplicatedItems = (tree) => {
  try {
    return tree.reduce((acc, curr) => {
      if (curr.children.length > 0) {
        const filteredData = curr.children.reduce((_acc, _curr) => {
          if (_curr.children.length === 1 && _curr.name === _curr.children[0].name) {
            return (_acc = [..._acc, ..._curr.children]);
          }
          return (_acc = [..._acc, _curr]);
        }, []);
        return (acc = [...acc, { ...curr, children: filteredData }]);
      }
      return (acc = [...acc, { ...curr }]);
    }, []);
  } catch (error) {
    return tree;
  }
};

export async function getTreeAboutConfig() {
  if (appConfig.featureConfig.showAllTreesInSubnav()) {
    const rest = await Promise.all(
      stores.getters.getUserInfo.trees.map(async (tree) => {
        return SrvCommon.getTree(tree.id);
      })
    );
    let treeData = [];
    rest.map((e, index) => {
      e.data.map((c) => {
        c.tree = stores.getters.getUserInfo.trees[index].id;
        treeData.push(c);
      });
    });

    const filteredTree = removeDuplicatedItems(treeData);

    stores.commit(types.SET_TREE_DATA, filteredTree);
    stores.commit(types.SET_TREE_DATA_WITHOUT_FILTER, treeData);
  } else {
    const res = await SrvCommon.getTree();
    if (res.status === 200) {
      // Remove duplicated tree's items
      const filteredTree = removeDuplicatedItems(res.data);

      filteredTree.map((t) => {
        t.tree = stores.getters.getUserInfo.trees.filter((e) => stores.getters.getTreeSelected.id === e.id)[0].id;
        return t;
      });
      stores.commit(types.SET_TREE_DATA, filteredTree);
      stores.commit(types.SET_TREE_DATA_WITHOUT_FILTER, res.data);
    }
  }
}
