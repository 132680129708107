<template lang="pug">
    div
        v-dialog.dialogEdit(v-model="show"
            width="680")
            v-card.dialogEdit
                v-card-text.pb-0
                v-container
                    div.gr-loader-snipper--wrapper(v-if="loading")
                        span.gr-loader-snipper
                    v-row#headerModalEdit.pt-3(:class="{'adverbia': isAdverbia}")
                        v-col(cols="10" class="d-flex items-center")
                            <img :src="isAdverbia ? ImgRobotAdverbia : ImgRobot" width="30" class="mr-2" />
                            span #[translate Análisis de la Competencia]
                        v-col.mt-5(cols="2")
                            <svg class="close"  @click.stop="show=false" xmlns="http://www.w3.org/2000/svg" width="12.607" height="12.607" viewBox="0 0 12.607 12.607">
                                <path id="source_icons_cancel_4_" data-name="source_icons_cancel (4)" d="M6.758,17.243,12,12m5.243-5.243L12,12m0,0L6.758,6.757M12,12l5.243,5.243" transform="translate(-5.698 -5.697)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                </svg>

                    v-row#bodyModalEdit(v-if="!response")
                        v-col(cols="12" class="text-center")
                            <img :src="isAdverbia ? ImgRobotAdverbiaBig : ImgRobotBig" width="100" />
                        v-col(cols="12" class="text-center")
                            <img :src="ImgLoad" width="100" />
                        v-col(cols="12" class="text-center text-red")
                            <span>..Preparando análisis..</span>
                    v-row#bodyModalEdit(v-else)
                        v-col(cols="12")
                            <div class="text-left scrollCustom ma-3 pr-2" style="height:400px; overflow-y:scroll">
                                <p>{{ response }}</p>
                            </div>
                            
                        


                    
                    
                    v-row#footerModalEdit
                        v-col(cols="12")
                           
</template>
<script>
import commonMixins from '@/mixins/common.js'
import brandMixin from '@/mixins/brand.js'
export default {
    mixins: [commonMixins, brandMixin],
    props: ['visible','response'],
    data(){
        return {
            loading: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            ImgRobot: require('@/assets/img/robot_pp.png'),
            ImgRobotBig: require('@/assets/img/robot_p.png'),
            ImgRobotAdverbia: require('@/assets/img/robot_ad_peque.png'),
            ImgRobotAdverbiaBig: require('@/assets/img/robot_ad_modal.png'),
            ImgLoad: require('@/assets/img/load.svg'),
            name: '',
        }
    },
    computed: {
        show: {
            get () {
                return this.visible
            },
            set (value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        },
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
    #headerModalEdit{
        background-color: #F93549;
        color:#fff;
        border-bottom: 0;
        &.adverbia{
            background-color: #162133;
        }
        .close{
            position: absolute;
            right: 20px;
            top: 20px;
        }
    }
    #bodyModalEdit{
        font-size: 13px;
        line-height: 28px;
        .inputFullEditModal{
            &.textDisabled{
                background-color: #f8f8f8;
                color:#CECECE;
            }
        }
        p{
            white-space: pre-line;
        }
    }
    #footerModalEdit{
        background-color: #F4F6F9;
        padding: 10px;
        border-top:0;
    }
</style>
