<template lang="pug">
    v-row.px-2.ma-0()    
        v-card.gridFull.w-full.h-auto.my-4.ma-0(v-if="!isLoading" :class="{'premiumDisabled': !hasPremium, 'notShadow pa-0': idsBussines, 'pl-6 pr-9': !idsBussines}")
            v-row.ma-0
              v-col.text-14.pa-0(sm="12")
                strong.mr-16(v-if="idsBussines") #[translate Seleccionar días y horas]
                strong.mr-16(v-else) #[translate Días con horario diferente]
                errors.my-2(v-if="messageError" :errors="messageError")
                
                newday(v-for="(day, index) in googlespecialday" :data="day" :id="index" @updateDay="updateDay" @removeDay="removeDay"  :showHead="index == 0")
            v-row.ma-0
              v-col.text-14(sm="12")
                .d-flex
                  button.blue--text(@click="addDay()") Añadir una fecha nueva
                .d-flex.align-center.pa-2.justify-center(v-if="!idsBussines")
                  button.btnBlue(@click="save") #[translate Aplicar]
        edited-ok(:show="showOk" @update:show="updateShow")
            p.mt-3.py-3.text-center 
                strong Editado correctamente.
</template>
<script>
  import SkeletonCard from '@/components/common/skeletonCard.vue'
  import SrvLocal from '@/services/local.js'
  import ConfigurationBlocked from '@/components/local/ConfigurationBlocked.vue'
  import BoxTime from '@/components/local/BoxTime.vue'
  import Newday from '@/components/local/Newday.vue'
  import commonMixins from "@/mixins/common.js"
  import localMixins from "@/mixins/local.js"
  //FICHAS EDICION
  import HeaderCenter from "@/components/local/HeadCenter.vue"
  import Errors from "@/components/common/Errors.vue"
  import EditedOk from "@/components/modals/EditedOk.vue";
  export default {
    props: ["idsBussines", "show", "clickedSaved"],
    components: {
        SkeletonCard,
        HeaderCenter,
        ConfigurationBlocked,
        BoxTime,
        Newday,
        Errors,
        EditedOk
    }, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
    mixins: [commonMixins, localMixins],
    data() {
      return {
        showEdit: true, // TEMPORAL PARA SUBIDAS A PRO
        dataProfile: [],
        messageError: null,
        sections: null,
        showOk: false,
        googlespecialday: [],
        sectionsNotCanBlocked: ['storefrontAddress','latlng'],
        img: {
        },
    }   
    },
    async created() {
      this.$store.commit('LOADING', true)
      this.loading = true
      this.$store.commit('LOADING', true)
      this.center = Number(this.$store.getters.getNode)
      await this.getLocalMarketing()
      
      this.loading = false
      this.$store.commit('LOADING', false)
    },    
    watch: {
      filterApply: function () {
        this.getLocalMarketing()
      },
      show: function (newVal) {
      if (newVal) {
        this.googlespecialday = [];
      }
    },
      clickedSaved: function (newVal) {
        if (newVal) {
            this.save();
        }
      },
    },
    methods: {   
    updateShow(event) {
      this.showOk = event;
      this.$emit("close");
    },   
      async getLocalMarketing() {
        this.$store.commit('LOADING', true)
            if (this.hasPremium && !this.idsBussines) {
                const resLocal = await SrvLocal.getLocalMarketing()
                if (resLocal.status === 200) {
                this.dataProfile = resLocal.data
                Object.entries(this.dataProfile.googlespecialday).forEach((key) => 
                    this.googlespecialday.push(key[1])
                )
                this.facebookConfig = resLocal.data.facebook_config
                this.$store.commit('LOADING', false)
                }
            }
        },
        updateDay({data, id}){
            this.googlespecialday[id] = data
        },
        removeDay(id){
            this.googlespecialday.splice(id, 1)
        },
        addDay() {
            this.googlespecialday.push({"start_date":this.$moment().format('YYYY-MM-DD'), "end_date": this.$moment().format('YYYY-MM-DD'), "isclosed":false,"times":[{"starttime": null,"endtime": null}]})
        },            
      
        async save() {
            
            if (this.hasPremium) {       
                let dataDays = []
                let valid = true
                this.googlespecialday.forEach(e => {
                    let dayCloned = Object.assign({},e)
                    dayCloned.start_date = this.$moment(dayCloned.start_date).format('YYYY-MM-DD')
                    dayCloned.end_date = this.$moment(dayCloned.start_date).format('YYYY-MM-DD')
                    let date = this.$moment(dayCloned.start_date).format('YYYY-MM-DD')
                    let nday = {}
                    nday[date] = dayCloned
                    dataDays.push(nday) 
                })
                if (this.googlespecialday.some(e => e.times.some(x => !x.starttime || !x.endtime) && !e.isclosed)) {
                    valid = false
                    this.messageError = 'Debe completar los campos de horas'
                }
                if (valid) {
                    if (!this.idsBussines) {
                        const res = await SrvLocal.setLocalMarketing('morehours-restaurant', {"googlespecialday": dataDays});
                        if (res.status === 200) {
                            this.showOk = true;
                        }
                        if (this.showOk) {
                            await SrvLocal.syncBunsiness(this.dataProfile.id, 'only_hours')
                        }
                    } else {
                        let data = {"section":"morehours-restaurant","mask":"specialHours","tree":this.$store.getters.getTreeSelected.id,"center_ids":this.idsBussines,"section_value":{"googlespecialday": dataDays}};
                        const res = await SrvLocal.setMultipleBatchUpdate(data);
                        if (res.status === 200) {
                            this.showOk = true;
                        }
                    }
                }
                
            }
            
        },
    }
  }
  </script>