<template>
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 17.2 17.2" style="enable-background:new 0 0 17.2 17.2;" xml:space="preserve" :class="{'dBlock': block}">
  <path id="source_icons_pharmacy-circled-cross" transform="translate(0.75 0.75)" class="st0" d="M9.3,13.3h-3
    c-0.7,0-1.2-0.5-1.2-1.2v-1.5H3.6c0,0,0,0,0,0c-0.3,0-0.6-0.1-0.9-0.4C2.5,10,2.4,9.7,2.4,9.3v-3c0-0.7,0.5-1.2,1.2-1.2h1.5V3.6
    c0-0.7,0.5-1.2,1.2-1.2h3c0.7,0,1.2,0.5,1.2,1.2v1.5h1.5c0.7,0,1.2,0.5,1.2,1.2v3c0,0.7-0.5,1.2-1.2,1.2h-1.5v1.5
    c0,0.3-0.1,0.6-0.4,0.9S9.7,13.3,9.3,13.3z M9.1,12.1C9.1,12.1,9.1,12.1,9.1,12.1L9.1,12.1z M6.6,11.8h2.4v-1.5
    c0-0.7,0.5-1.2,1.2-1.2h1.5V6.6h-1.5c-0.7,0-1.2-0.5-1.2-1.2V3.9H6.6v1.5c0,0.7-0.5,1.2-1.2,1.2H3.9v2.4h1.5c0.7,0,1.2,0.5,1.2,1.2
    V11.8z M3.6,9.1C3.6,9.1,3.6,9.1,3.6,9.1L3.6,9.1C3.6,9.1,3.6,9.1,3.6,9.1z M6.6,3.6L6.6,3.6C6.6,3.6,6.6,3.6,6.6,3.6z M7.8,16.4
    c-4.7,0-8.6-3.9-8.6-8.6s3.9-8.6,8.6-8.6c4.7,0,8.6,3.9,8.6,8.6S12.6,16.4,7.8,16.4z M7.8,0.8c-3.9,0-7.1,3.2-7.1,7.1
    s3.2,7.1,7.1,7.1c3.9,0,7.1-3.2,7.1-7.1S11.8,0.8,7.8,0.8z"   :fill="color" />
</svg>

</template>

<script>
export default {
  name: "IcoHealth",
  props: {
    color: {
        type: String,
        default: '#000000',
        required: false
    },
    block: {
        type: Boolean,
        default: true,
        required: false
    }
  }
}
</script>

<style scoped>
    .dBlock{
        display: block;
    }
</style>