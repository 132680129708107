<template lang="pug">
    div
      gp-filter-top#filtersFixed(@getData="getPromotionsItems" :type="'promotions'")
      v-container.gastroprice-content.with-menu.d-flex.flex-column(fluid)    
        .block-content.row.ma-0
          v-container(v-if="isLoading" fluid)
            v-skeleton-loader.mr-9(type="table")
          #headerFixedARank(v-if="!isLoading" fluid)
            v-container#tablePromoNews.table-data.pa-0.mb-0
              .table-content                  
                .header.border-b-dark-gray.font-weight-med(v-if="data")
                  .container-fluid
                    .row.d-flex
                      v-col.border-col-rigth.pt-3(cols="3")
                        translate Brand
                      v-col.border-col-rigth.pt-3(cols="2")
                        translate Aggregator
                      v-col.border-col-rigth.pt-3
                        translate Promotion
                      v-col.border-col-rigth.pt-3.text-center(cols="1")
                        translate Price
                      v-col.border-col-rigth.pt-3.text-center(cols="2")
                        translate Restaurants
          v-container#tablePromoNews.table-data.pa-0.mt-0(v-if="!isLoading" fluid)
            .table-content             
              .content-item.align-center.border-b-gray
                .container-fluid(v-for="(itemBrand, indexBrands) in data")
                  .container-fluid(v-for="(itemPromo, indexPromos) in itemBrand.table")
                    .row.d-flex.rowTextInitial
                      v-col.border-col-rigth.pt-3.align-center.justify-center.colBrand(cols="3" :class="{'gray': indexPromos === 0}")
                        img.img-brand.pr-2(v-if="indexPromos == 0" :src="itemBrand.logo" height="25")
                        span.name-brand.font-weight-med(v-if="indexPromos == 0" ) {{itemBrand.name}}
                      v-col.border-col-rigth.pt-3.namePromo(cols="2" :class="{'gray': itemPromo.first_promo}")
                        img.imgAggregator.pr-5(v-if="itemPromo.first_promo" :src="getImageAggregator(itemPromo.delivery_platform)"  height="25")
                        translate.font-weight-med(v-if="itemPromo.first_promo") {{itemPromo.delivery_platform}}
                      v-col.border-col-rigth.pt-3.gray
                        .font-weight-med(v-if="itemPromo.menu_items.length > 0") {{itemPromo.promo}}
                        .withOutPromo(v-else) #[translate No promotions]                    
                      v-col.border-col-rigth.pt-3.text-center.gray(cols="1")
                        span &nbsp;
                      v-col.border-col-rigth.pt-3.text-center.gray(cols="2")
                        span &nbsp;
                    .row.d-flex(v-for="(itemMenu, indexMenu) in itemPromo.menu_items")
                      v-col.border-col-rigth.pt-2.pb-2.align-center.justify-center(cols="3")
                        span &nbsp;
                      v-col.border-col-rigth.pt-2.pb-2.text-end.colMenu(cols="2")
                        translate.mr-4 {{itemMenu.star_date}}
                      v-col.border-col-rigth.pt-2.pb-2.colMenu.text-truncate
                        v-tooltip(right color="#fff" nudge-bottom="40" :open-delay="1000" content-class="tooltip-name" transition="slide-x-transition")
                          template(v-slot:activator="{ on, attrs }")
                            span.name.text-truncate(v-bind="attrs" v-on="on") {{ itemMenu.name }}
                          .tooltip
                            span {{ itemMenu.name }}
                            br
                            span {{ itemMenu.description }}
                      v-col.border-col-rigth.pt-2.pb-2.text-end.font-weight-med.colMenu(cols="1")
                        span {{itemMenu.price  | formatNumber(2,2) }} €
                      v-col.border-col-rigth.pt-2.pb-2.text-center.numberCentersMenuItem.colMenu(cols="2")
                        span.cursor-pointer(@click="showDetailsMenuItem(itemBrand.id, itemBrand.name,itemMenu,itemPromo)") {{itemMenu.n_centers}} #[translate Restaurants]
                        
                  
              
          v-dialog( v-model="dialog"
            hide-overlay
            width="1100")
              v-card
                v-card-text.pb-0
                  v-container.pt-12
                    img.close-modal.cursor-pointer(:src="img.close" @click="dialog=false")
                    v-row
                      v-col(cols="12")
                        .row
                          v-col(cols="4")
                            translate.black--text Promotion
                            span.ml-4.primary--text.font-weight-med {{infoModal.namePromotion}}
                          v-col(cols="4")
                            translate.black--text Brand
                            span.ml-4.primary--text.font-weight-med {{infoModal.brand}}
                          v-col(cols="4")
                            translate.black--text Average Price
                            span.ml-4.primary--text.font-weight-med {{ infoModal.price | formatNumber(2,2) }} €
                          v-col(cols="4")                        
                            span.primary--text
                              span {{infoModal.centers}} 
                              translate.black--text restaurants 
                          v-col(cols="4")
                            translate.black--text Aggregator
                            span.ml-4.primary--text.font-weight-med {{infoModal.aggregator}}

                #tableHistorial.scrollYCustom
                  div
                    .header.row
                      v-col.border-col-rigth.pt-3.pb-3(cols="2")
                        translate Restaurant
                      v-col.border-col-rigth.pt-3.pb-3(cols="2")
                        translate Direction
                      v-col.border-col-rigth.pt-3.pb-3(cols="2")
                        translate Locality
                      v-col.border-col-rigth.pt-3.pb-3(cols="2")
                        translate Province
                      v-col.border-col-rigth.pt-3.pb-3
                        translate Code Postal
                      v-col.border-col-rigth.pt-3.pb-3.text-center
                        translate Price
                    div.container.pr-0.pl-0(v-if="dataModal && dataModal.table")
                      .row.border-b-gray(v-for="(row, i) in dataModal.table.rows"  :class="{'bg-ligth-gray': i % 2 != 0}")
                        v-col.align-center.border-col-rigth(cols="2")
                          span {{ row[0] }}
                        v-col.align-center.border-col-rigth(cols="2")
                          span {{ row[1] }}
                        v-col.align-center.border-col-rigth(cols="2")
                          span {{ row[2] }}
                        v-col.align-center.border-col-rigth(cols="2")
                          span {{ row[3] }}
                        v-col.align-center.border-col-rigth.text-center
                          span {{ row[4] }}
                        v-col.align-center.border-col-rigth.text-center
                          strong {{ row[5] | formatNumber(2,2) }} €

</template>

<script>
import GpFilterTop from '@/components/filters/filter-top.vue'
import SrvGastroPricing from '@/services/gastropricing.js'

export default {
  components: {GpFilterTop},
  name: 'promoComparator',
  data() {
    return {
      data: [],
      dataModal: [],
      infoModal: {},
      img:{
        close: require('@/assets/img/plus.png')
      },
      dialog: false
    }
  },
  created(){
  },
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    }
  },
  methods: {
    getImageAggregator(aggregator){
      let aggregatorLoweCase = aggregator.toLowerCase()
      if(aggregatorLoweCase === 'deliveroo'){
        return require("@/assets/img/aggregators/Deliveroo.png")
      } else if (aggregatorLoweCase === "glovo"){
        return require("@/assets/img/aggregators/Glovo.png")
      } else if (aggregatorLoweCase === "just eat"){
        return require("@/assets/img/aggregators/Just Eat.png")
      } else if (aggregatorLoweCase === "uber eats"){
        return require("@/assets/img/aggregators/Uber Eats.png")
      }
    },
    getPromotionsItems(data){
      this.$store.commit('LOADING', true)
      SrvGastroPricing.getPromotionsItemsNews(data.brand, data.filters)
      .then(res => {
          if (res.status === 200) {
            this.data = res.data.brand
            this.data = [this.data,...res.data.competitors]
            this.$store.commit('LOADING', false)
          } 
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    showDetailsMenuItem(brand, brandName, menu, promotion){
      
      this.infoModal.namePromotion = promotion.promo
      this.infoModal.brand = brandName
      this.infoModal.centers = menu.n_centers
      this.infoModal.price = menu.price
      this.infoModal.aggregator = promotion.delivery_platform
      let province = this.$store.getters.hasProvince
      let city = this.$store.getters.hasCity
      SrvGastroPricing.getPromotionsItemModalData(brand,menu.id, promotion.delivery_platform_id, promotion.promo,'new',province, city)
        .then(res => {
            if (res.status === 200) {
              this.$store.commit('LOADING', false)
              this.dataModal = res.data
              this.dialog = true
            } 
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
    }
  },
  mounted () {
    let elementBody = document.querySelector('.body-promoNews .gastroprice-content')
    elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
    this.$nextTick(() =>{
      window.addEventListener('resize', function () {
        let elementBody = document.querySelector('.body-promoNews .gastroprice-content')
        if (elementBody) {
          elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
        }
      })
    })
  }
}
</script>
<style lang="scss" scoped>
.tooltip-name{
    color: #000;
    border: 1px solid #0000008c!important;
    transition: none!important;
    max-width: 600px;
}
.modal-small-text{
  font-size: 12px;
}
.modal-big-text{
  font-size: 22px;
}
.border-in-modal{
  border: 1px solid #D9DFE8;
  border-radius: 10px;
}
.close-modal{
  position: absolute;
  right: 11px;
  top: 14px;
}
#tablePromoNews{
  background-color: #fff;
  .row{
    margin:0;
  }
  .rowTextInitial{
    font-size: 0.813rem;
  }
  .gray{
    background-color: #FBFBFB;
    border-bottom:1px solid #ccc;
    border-top:1px solid #ccc;
  }
  .withOutPromo{
    font-size: 10px;
    color: #4A4A4A;
    display: inline-block;
    vertical-align: middle;
  }
  .colMenu{
    border-bottom: 1px solid #D9DFE8;
    font-size: 0.813rem;
  }
  .namePromo{
    border-bottom:1px solid #ccc;
    .imgAggregator{
      display: inline-block;
      vertical-align: middle;
      width: 45px;
      height: auto;
    }
    span{
      display: inline-block;
      vertical-align: middle;
    }
  }
  .height-40{
    .col{
      height: 40px;
    }
  }
  .header{
    font-size: 14px;
    height: auto;
    background-color: #fff;
  }
  .colBrand{
    .img-brand{
      display: inline-block;
      vertical-align: middle;
      max-width: 81px;
      max-height: 40px;
    }
    span{
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  .numberCentersMenuItem{
    text-align: right;
    color:var(--v-terciary-base);
  }
}
#tableHistorial{
  margin-top:10px;
  border-top: 10px solid #000;
  height: 289px;
  overflow-y: scroll;
  font-size: 0.813rem;
  
  .header{
    &.row{
      margin:0;
      font-weight: 600;
      border-bottom: 2px solid #000;
    }
  }
  .container{
    padding: 0;
    .row{
      margin: 0;
    }
  }
}

#headerFixedARank{
  position: sticky;
  width: 100%;
  background-color: #f2f2f2;
  top: 118px;
  .container{
    max-width: initial !important;
  }
}
</style>