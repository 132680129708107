<template lang="pug">
v-card.text-14.mb-4.gridFull.h-auto.ml-3.mb-4.ma-0.pl-6.pr-9(v-if="data" :class="{'notShadow': !hasShadow,'premiumDisabled': !hasPremium}")
    v-row.ma-0
        v-col.d-flex.align-center.pt-6(sm="12")
            img.mr-4(:src="img.warning" :title="data.help_text")
            strong.mr-16 {{ data.title }}
        v-col.d-flex.align-center.pt-6(sm="12" v-if="confirmed")
            p #[translate Notificación confirmada correctamente.]
    v-row.ma-0(v-if="!confirmed && data.type_notification !== 'attributes'")
        v-col.d-flex.align-center.rounded-sm.borderNotificationAction.ml-13
            span.red--text.flex-1 {{ data.message }}
            button.btn.btnRejected.mr-2(v-if="data.type_notification === 'acept'" @click="processNotification('REJECTED')") #[translate Rechazar]
            button.btnBlue(v-if="data.type_notification === 'acept'" @click="processNotification('APPROVED')") #[translate Aceptar]
            
            button.btnBlue(v-if="data.type_notification === 'update'") #[translate Actualizar]
    div(v-if="!confirmed && data.type_notification === 'acept'")
        v-row.ma-0
            v-col.ml-10(sm="12")
                p.mb-1.font-weight-bold #[translate Actual]
                div(v-if="data.key === 'regularHours'")
                    v-col.pb-1(md="12" v-for="(dayNormal, indexNormal) in Object.keys(data.actual.googleopenday)")
                        v-row
                            v-col.pb-0(md="2")
                                p.mb-0.day.text-12 {{ data.actual.googleopenday[dayNormal].name }}
                            v-col.pb-0.text-left(md="8" v-if="!data.actual.googleopenday[dayNormal].isClosed")
                                span.ml-2(v-for="hor in data.actual.googleopenday[dayNormal].times" :class="{'blueEdit': JSON.stringify(data.anterior.googleopenday[dayNormal].times) != JSON.stringify(data.actual.googleopenday[dayNormal].times)}")
                                    span {{ hor.starttime }} - {{ hor.endtime }}
                            v-col(v-else)
                                span.ml-2.stateKo #[translate Closed]
                div(v-else-if="data.key === 'categories'")
                    p.pl-3.mb-0
                        strong Principal
                    span.pl-3 {{ data.actual.primaryCategory }}
                    div.pl-3(v-if="data.actual.additionalCategories")
                        strong Adicionales
                        p.ma-0(v-for="(sub) in data.actual.additionalCategories") {{ sub }} 
                p.mb-1(v-else v-for="(item, index) in data.actual") {{ item }}
        v-row.ma-0
            v-col.ml-10(sm="12")
                p.mb-1.font-weight-bold #[translate Anterior]
                div(v-if="data.key === 'regularHours'")
                    v-col.pb-1(md="12" v-for="(dayNormal, indexNormal) in Object.keys(data.anterior.googleopenday)")
                        v-row
                            v-col.pb-0(md="2")
                                p.mb-0.day.text-12 {{ data.anterior.googleopenday[dayNormal].name }}
                            v-col.pb-0.text-left(md="8" v-if="!data.anterior.googleopenday[dayNormal].isClosed")
                                span.ml-2(v-for="hor in data.anterior.googleopenday[dayNormal].times" :class="{'blueEdit': JSON.stringify(data.anterior.googleopenday[dayNormal].times) != JSON.stringify(data.actual.googleopenday[dayNormal].times)}")
                                    span {{ hor.starttime }} - {{ hor.endtime }}
                            v-col(v-else)
                                span.ml-2.stateKo #[translate Closed]
                div(v-else-if="data.key === 'categories'")
                    p.pl-3.mb-0
                        strong Principal
                    span.pl-3 {{ data.anterior.primaryCategory }}
                    div.pl-3(v-if="data.anterior.additionalCategories")
                        strong Adicionales
                        p.ma-0(v-for="(sub) in data.anterior.additionalCategories") {{ sub }} 
                p.mb-1(v-else v-for="(item, index) in data.anterior") {{ item }}
    div(v-else-if="!confirmed && data.type_notification === 'update'")
        v-row.ma-0
            v-col.ml-10(sm="12")
                p.mb-1.font-weight-medium #[translate Datos...]
    div(v-else-if="!confirmed && data.type_notification === 'info'")
        v-row.ma-0
            v-col.ml-10(sm="12" v-if="data.key === 'latlng'")
                p.mb-1.font-weight-bold #[translate Actual]
                span Latitud {{ data.actual[0].latitude }} Longitud {{ data.actual[0].longitude }}
                p.mb-1.font-weight-bold #[translate Anterior]
                span Latitud {{ data.anterior[0].latitude }} Longitud {{ data.anterior[0].longitude }}
        v-row.ma-0
            v-col.ml-10(sm="12" v-if="data.key === 'storefrontAddress'")
                p.mb-1.font-weight-bold #[translate Actual]
                p.mb-0(v-if="data.actual.locality") Localidad: {{ data.actual.locality }}
                p.mb-0(v-if="data.actual.addressLines") Datos dirección:
                    ul
                        li(v-for="(line, index) in data.actual.addressLines" :key="index")
                            span {{ line }}
                p.mb-1.font-weight-bold #[translate Anterior]
                p.mb-0(v-if="data.anterior.locality") Localidad: {{ data.anterior.locality }}
                p.mb-0(v-if="data.anterior.addressLines") Datos dirección:
                    ul
                        li(v-for="(line, index) in data.anterior.addressLines" :key="index")
                            span {{ line }}
    div(v-else-if="!confirmed && data.type_notification === 'attributes'")
        v-row.ma-0.ml-10(v-for="(item, index) in data.bool_attributes")
            v-col.py-1(sm="6")
                span {{ item.display_name }}
            v-col.py-1.d-flex(sm="6")
                button.queOfreceCheck.mr-2(:class="{'on': item.suggested_status}" @click="changeAttr(item, true)") Si
                button.queOfreceCheck(:class="{'on': !item.suggested_status}" @click="changeAttr(item, false)") No
                .opacity-50.ml-8(v-if="item.current_value")
                    button.queOfreceCheck.mr-2(:class="{'on': item.current_value.values[0]}") Si
                    button.queOfreceCheck(:class="{'on': !item.current_value.values[0]}") No
        v-row.ma-0
            v-col.d-flex.align-center.ml-13
                span.red--text.flex-1
                button.btnBlue(@click="processNotification")  #[translate Guardar]
</template>

<script>
import SrvLocal from '@/services/local.js'
import localMixins from "@/mixins/local.js"

export default{
    props: ['data', 'idBussines'],
    mixins: [localMixins],
    data(){
        return {
            img: {
                warning: require('@/assets/img/warning.svg'),
            },
            confirmed: false
        }
    },
    methods: {
        async processNotification(status) {
            if (this.hasPremium) {
                let data = {"data": [{affected_field: this.data.key, status}]}
                if (this.data.key === 'attributes') {
                    data = {data: this.data.bool_attributes.map(e => {return {affected_field: e.affected_field, status: e.suggested_value.values[0] === e.suggested_status ? 'APPROVED' : 'REJECTED'} })}
                }
                const res = await SrvLocal.setNotifications(this.idBussines,data)
                if (res.status === 200) {
                    this.confirmed = true
                }
            }
        },
        changeAttr(item, status){
            if (this.hasPremium) {
                item.suggested_status = status
            }
        }
    }
}
</script>
<style>
body .notShadow{
    box-shadow: none !important;
}
.blueEdit{
    background-color:#E0EEFB;
}
</style>