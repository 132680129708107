<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: "CompetenceLastYear",
  extends: Line,
  mixins: [reactiveProp],
  props:['chartData'],
  data: () => ({
    type: 'line',
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
          display: true,
          position: 'bottom',
          align: 'start',
          labels: {
            padding: 18
          }
      },
      plugins: {
        // Change options for ALL labels of THIS CHART
        datalabels: {
            display: false
        }
      },
      layout: {
          padding: {
              left: 20,
              right: 40,
              top: 10,
              bottom: 10
          }
      },
      showTooltips: false,
      scales: {
        yAxes: [
          {
              type: "linear",
              display: true,
              ticks: {
                  fontColor: "#4a90e2"
              },
          },
        ]
      },
    },
  }),
  watch: {
    chartData () {
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
