<template lang="pug">
div.margin-bottom-0.col-12(v-if="hasCompetence && (typeNode === 'brand' || typeNode === 'center')")
    skeleton-card(v-if="isLoading")
    v-card.gridFull.ma-0(v-if="!isLoading && dataCompetenceLastYearFilteredByBrand.length")
        .d-flex.headTitleGrid
            .block-title.titleGrid
                translate.mb-0.title-card(v-if="!isGenericEntity") Competence. Evolution of the number of opinions per restaurant
                span(v-else).mb-0.title-card Competencia. Evolución del número de opiniones por negocio
            .block-help.ml-auto
                v-tooltip(left color="#222226" open-on-click=true max-width="550" nudge-top="20" nudge-left="10" content-class="tooltip-help tooltip-help-left")
                    template(v-slot:activator="{ on, attrs }")
                        img(:src="imgCommons.help" v-bind="attrs" v-on="on")
                    div
                        p.mb-0.pb-0(v-if="!isGenericEntity") En función de los filtros seleccionados, se muestra la evolución de los datos de la marca frente a sus competidores:  Media de las notas de todos los restaurantes /nº de restaurantes.
                        p.mb-0.pb-0(v-else) En función de los filtros seleccionados, se muestra la evolución de los datos de la marca frente a sus competidores:  Media de las notas de todos los negocios /nº de negocios.

        box-competence-last-year(:chartData="dataCompetenceLastYearFilteredByBrand")
</template>
            
<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import SrvNetwork from '@/services/network.js'
import commonMixins from "@/mixins/common.js"
import brandMixin from "@/mixins/brand.js"
import BoxCompetenceLastYear from '@/components/network/CompetenceLastYear.vue'
import BoxFeelingCategory from '@/components/global/FeelingCategory.vue'
import FilterTreeMenu from "@/components/filters/filterTreeMenu"
import InfoCompetence from "@/components/competence/InfoCompetence.vue"

export default {
    mixins: [commonMixins, brandMixin],
    components: { InfoCompetence, FilterTreeMenu, BoxFeelingCategory, SkeletonCard, BoxCompetenceLastYear },
    data() {
        return {
            dataCompetenceLastYear: [],
            competenceBrandsSelected: [],
            filterContainerHeight: 0,
            maxWidth: 0
        }
    },
    created() {
        if (this.$store.getters.getNode > 0) {
            if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
                this.getCompetenceLastYear()
            }
        }
    },
    expose: ['setFilteredBrands'],
    methods: {
        setFilteredBrands(brands) {
            this.competenceBrandsSelected = [...brands]
        },
        getCompetenceLastYear() {
            SrvNetwork.getCompetenceLastYear()
                .then(res => {
                    if (res.status === 200) {
                        this.dataCompetenceLastYear = res.data.map((e) => ({ ...e, show: true }))
                    }
                }, () => {
                })
        },
    },
    watch: {},
    computed: {
        brandsCompetenceActive() {
            return this.competenceBrandsSelected.map(c => c.value || c.code)
        },
        dataCompetenceLastYearFilteredByBrand() {
            let brands = this.dataCompetenceLastYear
            if (this.competenceBrandsSelected.length > 0) {
                brands = this.dataCompetenceLastYear.filter((b) => {
                    if (this.brandsCompetenceActive.includes(b.name)) {
                        return b
                    }
                })
                brands = [this.dataCompetenceLastYear[0], this.dataCompetenceLastYear[1], ...brands]
            }
            return brands
        },
    },
}
</script>
            
<style lang="scss" scoped></style>
            