<template lang="pug">
    div
      gp-filter-top-analysis#filtersFixed(@getData="getPizzasRanking" :type="'rateComparatorPizzas'" @downloadExcelPizzas="downloadExcelPizzas()")
      
      v-container.gastroprice-content.with-menu.d-flex.flex-column(fluid)  
         
        .block-content.row.ma-0
          v-container.titlePizzasSection.ml-0.mb-2.pa-0(v-if="!isLoading")
            strong {{ $gettext('Most repeated prices of Pizzas by format and channel') }}
          v-container.table-data.ma-0.pa-0(v-if="isLoading" fluid)
            v-skeleton-loader(type="table")                  
          v-container.boxLeftFormats.table-data.ma-0.pa-0.flipped-block.flipped(v-if="!isLoading" style="position:absolute; top: 153px;background-color: transparent;" fluid)
            .table-content
              .header.d-flex.headerProvince(style="height:82px" v-if="data && data.header")
                v-col.fixed-width.p-relative
              .header.d-flex.headerProvince(style="height:97px" v-if="data && data.header")
                v-col.fixed-width.p-relative.labelFormatoPizzas
                  span {{ $gettext('Pizzas format') }}                
              .content-item.pr-5.d-flex.align-center.border-bottom-gray(v-if="data" v-for="(row, index) in data.rows" :class="{'bg-ligth-gray': index % 2 != 0}")
                v-col.fixed-width.colFormat( v-for="(item, i) in row.slice(0, 1)")
                  span.labelFormato {{ getCustomSize(row, item) }}
          v-container.items.table-data.ma-0.pa-0.flipped-block.body.flipped(v-if="!isLoading" style="margin-left: 220px !important; top: 64px;" fluid)
            #pizzasComparatorData.table-content
              .header.border-b-dark-gray.d-flex.headerColumnsData(v-if="data && dataCustom")
                v-col.fixed-width.p-relative.border-col-rigth.text-center.pr-5( v-for="(item, index) in dataCustom")
                  translate.font-weight-med.font-14(v-if="index == 0")
                  .logoBrandPizzas.text-center
                    img(:src="item.head.logo" width="70")
                    span.font-weight-med.font-14 {{item.head.name}}
                  div.subHeadChannel
                    v-col(v-for="subHead in item.subHead")
                      div.precioMedio
                        span {{ $gettext('Most repeated price') }}
                      div.nameChannel
                        span {{ subHead }}  
              
                  .rowPrices.content-item.d-flex.align-center.border-bottom-gray(v-if="data" v-for="(row, index) in item.precios" :class="{'bg-ligth-gray': index % 2 != 0}")
                    v-col.border-col-rigth.text-center.font-weight-med( v-for="(itemR, i) in row")
                      div
                        span {{itemR | formatNumber(2,2) }} 
                        span(v-if="itemR") €
                        div.pricePrev(v-if="item.preciosPrev[index][i] && (item.preciosPrev[index][i] !== itemR)") {{ item.preciosPrev[index][i] | formatNumber(2,2) }} 
                          span(v-if="itemR") €

</template>

<script>
import GpFilterTopAnalysis from '@/components/filters/filter-top-analysis.vue'
import SrvGastroPricing from '@/services/gastropricing.js'
import commonMixins from "@/mixins/common.js";
import * as types from '@/store/mutation-types'

export default {
  components: { GpFilterTopAnalysis },
  name: 'rateComparatorPizzas',
  props: ['paramCOD'],
  mixins: [commonMixins],
  data() {
    return {
      data: [],
      idSortCol: '',
      sortDesc: true,
      numberChannels: 3,
      dataCustom: [],
      brandId: 378
    }
  },
  created(){
  },
  computed: {
    isLoading () {
      return this.$store.getters.isLoading
    }
  },
  methods: {
    getCustomSize (row, item) {
      let size
      if (item === 'M' && row[1] === 1) {
        size = '1 Mediana'
      }
      if (item === 'M' && row[1] === 2) {
        size = '2 Medianas'
      }
      if (item === 'F' && row[1] === 1) {
        size = '1 Familiar'
      }
      if (item === 'F' && row[1] === 2) {
        size = '2 Familiares'
      }
      return size
      
    },
    generateExcelGP (res) {
      
      let nameBrand = ''
      this.$store.getters.hasBrands.forEach(element => {
        if (element.id === this.brandId) {
          nameBrand = element.name
        }
      });
      var blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });
      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(blob)
      
      link.download = `${nameBrand}.xlsx`
      this.$store.commit(types.DOWNLOADING_EXCEL, false)
      link.click()
    },
    downloadExcelPizzas () {
      this.$store.commit(types.DOWNLOADING_EXCEL, true)
      let filters = {}
      filters.province = this.$store.getters.hasProvince
      SrvGastroPricing.getPizzasRanking(this.brandId, filters, true)
        .then(res => {
            if (res.status === 200) {
              this.generateExcelGP(res)
            } else {
              // empty
            }
        }, () => { })
    },
    getPizzasRanking(data){
      this.$store.commit('LOADING', true)      
      SrvGastroPricing.getPizzasRanking(this.brandId, data.filters, false)
      .then(res => {
          if (res.status === 200) {
            this.data = res.data.table
            // CUSTOM HEADERS FIRST LEVEL
            this.dataCustom = []
            if (this.data) {
              let lengthHeaders = this.data.header.length              
              for (let i = 2; i < lengthHeaders; i+=this.numberChannels) {
                this.dataCustom.push({head: this.data.header[i], subHead: [], precios: [], preciosPrev: []})
                for (let c = i; c < (i+this.numberChannels); c++) {
                  this.dataCustom[this.dataCustom.length - 1].subHead.push(this.data.header[c].channel)                  
                }
                // ROWS
                for (let r = 0; r < this.data.rows.length; r++) {
                  let pos = this.dataCustom.length - 1
                  this.dataCustom[pos].precios.push([this.data.rows[r][i], this.data.rows[r][i+1], this.data.rows[r][i+2]])
                  this.dataCustom[pos].preciosPrev.push([this.data.prev_rows[r][i], this.data.prev_rows[r][i+1], this.data.prev_rows[r][i+2]])
                }
              }
            }
            
            this.$store.commit('LOADING', false)
            this.$nextTick(() =>{
              let caja = document.querySelector('.flipped-block.body')
              let boxFormats = document.querySelector('.boxLeftFormats')
              if ( caja.scrollWidth > (parseInt(caja.offsetWidth) + 2)) {
                let boxFormats = document.querySelector('.boxLeftFormats')
                boxFormats.style.top = '161px'
                if (window.innerWidth < 1400) {
                  boxFormats.style.top = '156px' 
                }
              } else {
                boxFormats.style.top = '153px'
              }
            })
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })

    },
    sortCol(id){
      if(id === this.idSortCol){
        this.sortDesc = !this.sortDesc
      }
      this.idSortCol = id
      this.sortArray()
    },
    sortArray(){

      this.data.rows = this.data.rows.sort((a, b) => {
        return b[this.idSortCol] - a[this.idSortCol]
      })
      if(!this.sortDesc){
        this.data.rows = this.data.rows.reverse()
      }
      return this.data
    }
  },
  mounted () {
    this.$nextTick(() =>{
      window.addEventListener('resize', function () {
        let caja = document.querySelector('.flipped-block.body')
        let boxFormats = document.querySelector('.boxLeftFormats')
        if (caja.scrollWidth > (parseInt(caja.offsetWidth) + 2)) {          
          boxFormats.style.top = '161px'
          if (window.innerWidth < 1400) {
            boxFormats.style.top = '156px' 
          }
        } else {
          boxFormats.style.top = '153px'
        }
      })
    })
  }
}
</script>
<style lang="scss" scoped>
.gastroprice-content{
  padding-top: 65px;
}
#gastropricing #pizzasComparatorData{
   .border-col-rigth{
    &:last-child{
      border-right:1px solid #cecece;
    }
   }
.fixed-width {
    flex: 0 0 268px;
    width: 268px;
    padding:0 !important;
    margin: 0;
}
}
.table-content{
  width: fit-content;
  .header{
    .sort-icon{
      position: absolute;
      right: 5px;
      bottom: 5px;
      .v-icon{
        font-size: 16px;
      }
    }
  }
  .content-item{
    span{
      font-size: 0.813rem;

    }
  }
}

.flipped-block{
  width: 95%;
  margin: auto;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #498FE1 #e1e1e1;
    //SCROLL
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #498FE1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: #498FE1;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #498FE1;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track:hover{
    background: #E6EAF0;
  }
  &::-webkit-scrollbar-track:active {
    background: #E6EAF0;
  }

}
.flipped, .flipped .table-content{
  transform:rotateX(180deg);
  -ms-transform:rotateX(180deg); /* IE 9 */
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
  height: max-content;
}

.inactive{
  stroke: #CECECE;
  path{
    stroke: #CECECE;
  }
  line{
    stroke: #CECECE;
  }
  &.activeSort{
    path{
    stroke: #4A90E2;
    }
    line{
      stroke: #4A90E2;
    }
    &.sortAsc{
      transform: rotate(180deg);
    }
  }
}

.absolute {
  position: absolute;
}

.border-bottom-gray {
  border-bottom: 1px solid #D9DFE8;  
}

.items {
  cursor: inherit;
}
.subHeadChannel{
  border-top: 1px solid #4A4A4A;
  border-bottom: 1px solid #4A4A4A;
  display: flex;
  .col{
    width: 33%;
    &:nth-child(2){
      border-left: 1px solid #CECECE;
      border-right: 1px solid #CECECE;
    }
  }
}
.colFormat{
  height: 67px;
    align-items: center;
    display: flex;
}
.rowPrices{
  .col{
    width: 33%;
    height: 67px;
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
    .pricePrev{
      color:#a4a4a4;
      font-weight: 400;
    }
  }  
}
.precioMedio{
  font-size: 10px;
  color: #CECECE;
}
.nameChannel{
  span{
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  }
}

.logoBrandPizzas{
    justify-content: center;
    align-items: center;
    display: flex;
    padding:20px 0;
    img{
      margin-right: 30px;
    }
}
.titlePizzasSection{
  padding-top: 80px ;
}
.labelFormatoPizzas{
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.labelFormato{
  font-size: 14px;
  font-weight: 600;
  color: #161719;
}
</style>
