<template lang="pug">
div
    v-dialog(v-model="showModal"
        width="459")
        v-card
            v-card-text#warningEditRestaurantsHeader.text-center.py-4
                <svg xmlns="http://www.w3.org/2000/svg" width="52.781" height="52.781" viewBox="0 0 52.781 52.781">
                    <g id="Grupo_10781" data-name="Grupo 10781" transform="translate(0.5 0.5)">
                        <path id="Trazado_5006" data-name="Trazado 5006" d="M7,18.056l7.167,7.167L30.891,8.5" transform="translate(6.945 9.029)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                        <path id="Trazado_5007" data-name="Trazado 5007" d="M25.891,49.781A23.891,23.891,0,1,0,2,25.891,23.891,23.891,0,0,0,25.891,49.781Z" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
                    </g>
                </svg>
            v-container
                v-row
                    v-col
                        slot
                
            v-container#warningEditRestaurantsFooter.mt-3
                v-row
                    v-col.d-flex.items-center.justify-center
                        button.btnCancelEditModal.pa-3(@click.stop="close()") #[translate Cerrar]
                
</template>

<script>
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"

export default {
    mixins: [commonMixins],
    props:['show'],
    methods: {
        close(){
            this.$emit('update:show', false)
        }
    },
    data(){
        return {
            
        }
    },
    computed: {
        showModal: {
            get () {
                return this.show
            },
            set (value) {
                this.$emit('update:show', value)
            }
        },
        dialogDownloadingExcel: {
            get: function () {
                return this.$store.getters.getDownloadingExcel
            },
            set: function (newValue) {
                this.$store.commit(types.DOWNLOADING_EXCEL, newValue)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#headModalExcededLimit{
    background-color: #DBE1E1;
}
#boxCentersNotUpdated{
    height: 233px;
    overflow-y: scroll;
    flex:0 0 388px;
    margin: 0 auto;
    .center{
        border-bottom: 1px solid #707072;
        .name{
            color: #6D9ED6;
        }
        .row{
            margin:0;
        }
    }
    .center:nth-child(even){
        background-color: #F7F8FD;
    }
}
.close-modal{
    position: absolute;
    top:15px;
    right: 15px;
}
.icoWarning{
    margin-bottom: -70px;
}
.boxInfo{
    color: #4D4F5C;
    font-size: 1rem;
}
.btnCancelEditModal{
    background-color: #fff;
}
#warningEditRestaurantsHeader{
    background-color: #3DC481;
}
#warningEditRestaurantsFooter{
    background-color: #F5F6FA;
}
</style>