<template lang="pug">
  div
    v-dialog(v-model='open' scrollable='' max-width='400px' :persistent="true")
      v-card.facebook-locations
        v-toolbar(flat)
          v-toolbar-title Facebook Bulk Update
          v-spacer
          v-btn(icon @click='$emit("update:open", false)' :disabled="processing")
            v-icon mdi-close-circle-outline
        v-divider
        v-card-text(style='height: 150px;')
          div.gr-loader-snipper--modal.flex-column(v-if="finished")
            div This process has finished successfully
          div.gr-loader-snipper--modal.flex-column(v-else-if="processing")
            div Processing {{ processed }}%
            span.gr-loader-snipper
          div.gr-loader-snipper--modal.flex-column(v-else-if="loading")
            div Retrieving {{ data.length }} Items
            span.gr-loader-snipper
          div.d-flex.justify-center.items-center.fill-height(v-else)
            span
              | Encountered {{ data.length }} Items
        v-divider
        v-card-actions.justify-center
          v-btn.py-2.my-1(color='blue darken-1' outlined tile min-width="100" max-height="30" :disabled="processing" text='' @click='$emit("update:open", false)')
            | Close
          v-btn.py-2.my-1(v-if="!hideRun" color='primary' tile min-width="100" max-height="30" :disabled="!canRun" @click='processChunks')
            | Run

</template>

<script>
/* global FB */
import {FacebookService} from "@/helper/facebook";
import SrvGlobal from "@/services/local";

export default {
  name: "FacebookBulkUpdate",
  props: {
    appId: {
      type: String,
      required: true,
      default() {
        return ''
      }
    },
    apiVersion: {
      type: String,
      required: true,
      default() {
        return ''
      }
    },
    currentFacebookLinkeded: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    open: {
      type: Boolean,
      required: true,
      default() {
        return false
      }
    },
  },
  data() {
    return {
      loading: false,
      processing: false,
      processed: 0,
      userToken: '',
      data: [],
      canRun: false,
      hideRun: false,
      finished: false
    }
  },
  watch: {
    open: {
      immediate: true,
      async handler(value) {
        this.canRun = false
        await this.$nextTick(async () => {
          value && await this.load()
        })
      },
    }
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async load() {
      this.finished = false
      this.hideRun = false
      this.loading = true
      const fb = new FacebookService(this.appId)
      await fb.initFacebookSdk()
      await this.getLoginStatus()
      this.loading = false
    },
    getLoginStatus() {
      return new Promise((resolve) => {
        FB.getLoginStatus(async ({authResponse}) => {
          if (authResponse) {
            this.userToken = authResponse.accessToken
            await this.getAccounts()
          } else {
            await this.login()
          }
          resolve()
        }, true);
      })
    },
    async login() {
      FB.login(async (authResponse) => {
          this.userToken = authResponse.accessToken
          await this.getLoginStatus()
        },
        {scope: 'public_profile,email,pages_read_engagement,pages_manage_engagement,instagram_basic,instagram_manage_comments,pages_show_list,business_management,pages_manage_metadata', auth_type: 'rerequest'})
    },
    async processChunks() {
      Array.prototype.chunk = function (size) {
        let result = [];

        while (this.length) {
          result.push(this.splice(0, size));
        }

        return result;
      }

      this.processing = true
      this.hideRun = true
      // eslint-disable-next-line no-unused-vars
      const chunks = [...this.data].chunk(10);

      let qty = 0
      for (let index in chunks) {
        qty += chunks[index].length

        // Turn on/off in config-app.js
        if (this.$config.featureConfig['facebookBulkSetup']) {
          const {status, data} = await SrvGlobal.linkFacebook(chunks[index])
          if (status === 200 && data.success) {
            this.$emit("update", data)
          }
        } else {
          await this.sleep(100)
        }

        this.processed = Math.round(qty * 100 / this.data.length)
      }

      this.processing = false
      this.finished = true

    },
    getAccounts() {
      this.accountSelection = undefined
      this.data = []
      return new Promise((resolve) => {
        FB.api(
          "/me/accounts",
          async (response) => {

            const getData = async (item) => {
              if (this.currentFacebookLinkeded[item.id]) {
                this.data.push({
                  center: this.currentFacebookLinkeded[item.id].center_id,
                  access_token: item.access_token,
                  user_token: this.userToken,
                  page_id: item.id
                })

                const locations = await new Promise(resolve => {
                  FB.api(`/${item.id}/locations?fields=name,access_token,store_location_descriptor&limit=999999`, response => {
                    resolve(response)
                  })
                })

                if (!locations.error) {
                  if (locations.data.length) {
                    locations.data.forEach((item) => {
                      if (this.currentFacebookLinkeded[item.id]) {
                        this.data.push({
                          center: this.currentFacebookLinkeded[item.id].center_id,
                          access_token: item.access_token,
                          user_token: this.userToken,
                          page_id: item.id
                        })
                      }
                    })
                  }
                }
              }
            }

            await Promise.all(response.data.map(async (item) => {
              await getData(item)
            }));

            this.canRun = this.data.length > 0
            resolve()
          }
        );
      })
    }
  },
  computed: {
    centerId() {
      return this.center
    }
  }
}
</script>

<style lang="scss">
.facebook-locations {
  .v-label {
    font-size: 0.8em;
  }
}
</style>
