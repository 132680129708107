import Vue from "vue";
import Vuex from "vuex";

// Stores
import User from "@/store/user";
import Navbar from "@/store/navbar";
import Subnav from "@/store/subnav";
import Common from "@/store/common";
import Satisfaction from "@/store/satisfaction";
import Feedback from "@/store/feedback";
import { Booking } from "@/store/bookings";
import GastroPricing from "@/store/gastropricing";
import Reports from "@/store/reports";
import AppReviews from "@/store/appsreviews";
import Local from "@/store/local";
Vue.use(Vuex);

const stores = new Vuex.Store({
  modules: {
    user: User,
    navbar: Navbar,
    subnav: Subnav,
    common: Common,
    satisfaction: Satisfaction,
    feedback: Feedback,
    gastropricing: GastroPricing,
    reports: Reports,
    appreviews: AppReviews,
    local: Local,
    booking: Booking,
  },
});

export default stores;
