<template lang="pug">
    div
      gp-filter-top-analysis#filtersFixed(@getData="getMenuItems" :type="'comparator'")
      v-container.gastroprice-content.with-menu.d-flex.flex-column.pt-0(fluid)      
        .block-content.row.flex-nowrap
          v-col(v-if="isLoading" cols="12")
            v-skeleton-loader.mr-9(type="table")
          v-col(v-if="!isLoading" cols="12")
            #scrollHorizontal
              .col-item.mr-4(v-if="data.brand")
                .head.d-flex
                  v-col.d-flex.align-center.border-col-rigth(cols="8")                  
                  v-col.text-center.align-self-end.px-0(cols="4")                  
              .col-item.mr-4(v-if="data.competitors" v-for="item in data.competitors")
                .head.d-flex
                  v-col.d-flex.align-center.border-col-rigth(cols="8")                  
                  v-col.text-center.align-self-end.px-0(cols="4")
            #header.d-flex.pb-0
              .col-item.mr-4(v-if="data.brand")
                .head.d-flex.p-relative
                  v-col.d-flex.align-center.border-col-rigth(cols="8")
                    img.img-brand.pr-2(:src="data.brand.logo")
                    span.name-brand {{data.brand.name}}                  
                  v-col.text-center.align-self-start.px-0(cols="4")
                    span.type-family {{familyActive}}
                    translate.average-text Most repeated price
                    .boxOrder
                      span(@click="cleanOrder()") 
                        v-icon mdi-close
                      span(@click="setOrderData(1,'desc')") 
                        v-icon(:class="{'arrowActive': ord === 'desc'}") mdi-arrow-up
                      span(@click="setOrderData(1,'asc')")
                        v-icon(:class="{'arrowActive': ord === 'asc'}")  mdi-arrow-down
              .col-item.mr-4(v-if="data.competitors" v-for="item in data.competitors")
                .head.d-flex.p-relative
                  v-col.d-flex.align-center.border-col-rigth(cols="8")
                    img.img-brand.pr-2(:src="item.logo")
                    span.name-brand {{item.name}}
                  v-col.text-center.align-self-start.px-0(cols="4")
                    span.type-family {{familyActive}}
                    translate.average-text Most repeated price
                    .boxOrder
                      span(@click="cleanOrder()") 
                        v-icon mdi-close
                      span(@click="setOrderData(1,'desc')") 
                        v-icon(:class="{'arrowActive': ord === 'desc'}") mdi-arrow-up
                      span(@click="setOrderData(1,'asc')")
                        v-icon(:class="{'arrowActive': ord === 'asc'}")  mdi-arrow-down
            #body.d-flex
              .col-item.mr-4(v-if="data.brand")            
                .row-content.d-flex(v-for="(item, index) in dataBrandSorted" :class="{'bg-ligth-gray': index % 2 != 0}")
                  v-col.d-flex.border-col-rigth.py-0.align-center(cols="8")
                    v-tooltip(right color="#fff" nudge-bottom="40" :open-delay="1000" content-class="tooltip-name")
                      template(v-slot:activator="{ on, attrs }")
                        span.name.text-truncate(v-bind="attrs" v-on="on") {{item[0]}}
                      .tooltip
                        span {{item[0]}}
                        br
                        span {{item[2]}}
                  v-col.text-center(cols="4")
                    span {{item[1] | formatNumber(2,2)}} €
              .col-item.mr-4(v-if="data.competitors" v-for="item in data.competitors")            
                .row-content.d-flex(v-for="(it, index) in sortedCompetitors(item.table.rows)" :class="{'bg-ligth-gray': index % 2 != 0}")
                  v-col.d-flex.border-col-rigth.py-0.align-center(cols="8")
                    v-tooltip(right color="#fff" nudge-bottom="40" :open-delay="1000" content-class="tooltip-name" transition="slide-x-transition")
                      template(v-slot:activator="{ on, attrs }")
                        span.name.text-truncate(v-bind="attrs" v-on="on") {{it[0]}}
                      .tooltip
                        span {{it[0]}}
                        br
                        span {{it[2]}}
                  v-col.text-center(cols="4")
                    span {{it[1] | formatNumber(2,2)}} €

</template>

<script>
import SrvGastroPricing from '@/services/gastropricing.js'
import GpFilterTopAnalysis from '@/components/filters/filter-top-analysis.vue'
export default {
  components: {GpFilterTopAnalysis},
  name: 'rateComparator',
  data() {
    return {
      data: [],
      familyActive: '',
      fieldOrder: 0,
      ord: ''
    }
  },
  created(){
    this.$nextTick(() =>{
      
    })
  },
  computed: {
    isLoading(){      
      return this.$store.getters.isLoading
    },
    dataBrandSorted () {
      return this.lodash.orderBy(this.data.brand.table.rows, this.fieldOrder, this.ord)
    },
  },
  methods: {
    cleanOrder () {
      this.fieldOrder = 0
      this.ord = ''
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    sortedCompetitors (data) {
      return this.lodash.orderBy(data, this.fieldOrder, this.ord)
    },
    setOrderData (campo, ord) { 
      this.fieldOrder = campo
      this.ord = ord
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    getMenuItems(data){
      if(data.filters.family){
        this.familyActive = data.filters.family.name
      } else {
        this.familyActive = ''
      }
      this.$store.commit('LOADING', true)
      SrvGastroPricing.getMenuItems(data.brand, data.filters)
      .then(res => {
          if (res.status === 200) {
            this.data = res.data
            this.$store.commit('LOADING', false)
            this.$nextTick(() =>{
              let s1 = document.querySelector('.body-rateComparator #scrollHorizontal')
              let s2 = document.querySelector('.body-rateComparator #header')
              let s3 = document.querySelector('.body-rateComparator #body')
              if (s1 && s2 && s3) {                
                s1.addEventListener('scroll', function () {
                    s2.scrollLeft = s1.scrollLeft
                    s3.scrollLeft = s1.scrollLeft
                });
                s1.setAttribute('style','width:'+s3.offsetWidth+'px;padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
                s2.setAttribute('style','width:'+s3.offsetWidth+'px;padding-top:'+ (document.getElementById('filtersFixed').offsetHeight + 18) + 'px;')
                s3.style = 'margin-top: ' + (s2.offsetHeight ) + 'px !important;'
              }
            })
          } 
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
  },
  mounted () {    
    this.$nextTick(() =>{
      //let elementBody = document.querySelector('.body-rateComparator .gastroprice-content')
      //elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight ) + 'px;')
      window.addEventListener('resize', function () {
        let elementBody = document.querySelector('.body-rateComparator .gastroprice-content')
        if (elementBody) {
          elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight - 72) + 'px;')
        }
        let s1 = document.querySelector('.body-rateComparator #scrollHorizontal')        
        let s2 = document.querySelector('.body-rateComparator #header')
        let s3 = document.querySelector('.body-rateComparator #body')
        if (s1 && s2 && s3 ) {
          s1.setAttribute('style','width:'+s3.offsetWidth+'px;padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
          s2.setAttribute('style','width:'+s3.offsetWidth+'px;padding-top:'+ (document.getElementById('filtersFixed').offsetHeight + 18) + 'px;')
          s3.style = 'margin-top: ' + (s2.offsetHeight ) + 'px !important;'
        }
      })
    })
  } 
}
</script>

<style lang="scss" scoped>
.arrowActive{
  color:#498FE1;
}
.boxOrder{
  line-height: 0;
  span{
    display: inline-block;
    margin: 0 5px;    
    .v-icon{
      font-size: 16px;
      cursor:pointer;
    }
  }
}
.gastroprice-content{
  padding-top: 20px;
}
#scrollHorizontal{
  position: fixed;
  padding-top:28px;
  z-index: 2;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-color: #498FE1 #e1e1e1;
  .col-item{
    background-color: transparent;
    border:none;
    border-radius: 0;
    height: 0;
  }
    //SCROLL
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #498FE1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: #498FE1;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #498FE1;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track:hover{
    background: #E6EAF0;
  }
  &::-webkit-scrollbar-track:active {
    background: #E6EAF0;
  }
}
#body{
  overflow: hidden;
  margin-top: 176px !important;
}
#header{
  padding-top: 50px;
  position: fixed;
  width: 85%;
  display: flex;
  overflow: hidden;  
}
.tooltip-name{
    color: #000;
    border: 1px solid #0000008c!important;
    opacity: 1!important;
    transition: none!important;
    max-width: 600px;
}
// scrollbar TOP
.flipped-block{
  width: 95%;
  margin: auto;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: #498FE1 #e1e1e1;
    //SCROLL
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #498FE1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: #498FE1;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #498FE1;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track:hover{
    background: #E6EAF0;
  }
  &::-webkit-scrollbar-track:active {
    background: #E6EAF0;
  }

}

.col-item{
  width: 310px;
  min-width: 310px;
  background-color: #fff;
  border: 1px solid #D9DFE8;
  border-radius: 5px;
  .head{
    border-bottom: 1px solid #4A4A4A;
    .img-brand{
      max-width: 81px;
      max-height: 40px;
    }
    .name-brand{
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .type-family{
      display: block;
      font-size: 0.813rem;
      font-weight: 600;
      margin-top: -6px;
    }
    .average-text{
      font-size: 10px;
      font-weight: 600;
      color:#CECECE;
      line-height: 0;
    }
  }
  .row-content{
    font-size: 0.813rem;
    font-weight: 600;
    border-bottom: 1px solid #D9DFE8;
    height: 42px;
  }
}

</style>