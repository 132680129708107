<template>
<div class="localPost text-14">
    <div class="image">
    </div>
    <div class="infoPost">
        <div class="d-flex items-end">
            <strong class="flex-1">
                
            </strong>
            <button v-if="data.structure_type === 'free_form_service'" class="mr-2" @click="edit()">
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
                    <g id="Grupo_9689" data-name="Grupo 9689" transform="translate(0)">
                        <g id="Grupo_8463" data-name="Grupo 8463" transform="translate(0 0)">
                        <rect id="Rectángulo_2239" data-name="Rectángulo 2239" width="31" height="31" rx="4" transform="translate(0)" fill="#f2f2f2"/>
                        </g>
                        <g id="edit-3_2_" data-name="edit-3 (2)" transform="translate(7.154 8.609)">
                        <path id="Trazado_4643" data-name="Trazado 4643" d="M12,20h9.164" transform="translate(-4.872 -4.503)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Trazado_4644" data-name="Trazado 4644" d="M15.141,3.441a1.9,1.9,0,0,1,2.7,0,1.929,1.929,0,0,1,0,2.715L6.6,17.47,3,18.376l.9-3.62Z" transform="translate(-3 -2.879)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                    </g>
                </svg>
            </button>
            <button @click="showConfirmDelete = true">
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
                    <g id="Grupo_11099" data-name="Grupo 11099" transform="translate(0 0.115)">
                        <rect id="Rectángulo_2872" data-name="Rectángulo 2872" width="31" height="31" rx="4" transform="translate(0 -0.115)" fill="#f2f2f2"/>
                        <g id="trash-2_2_" data-name="trash-2 (2)" transform="translate(8.812 7.22)">
                        <path id="Trazado_4678" data-name="Trazado 4678" d="M3,6H17.187" transform="translate(-3 -2.847)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <path id="Trazado_4679" data-name="Trazado 4679" d="M16.034,5.153V16.187a1.576,1.576,0,0,1-1.576,1.576H6.576A1.576,1.576,0,0,1,5,16.187V5.153m2.365,0V3.576A1.576,1.576,0,0,1,8.941,2h3.153A1.576,1.576,0,0,1,13.67,3.576V5.153" transform="translate(-3.424 -2)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <line id="Línea_948" data-name="Línea 948" y2="5.133" transform="translate(5.634 7.108)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        <line id="Línea_949" data-name="Línea 949" y2="5.133" transform="translate(8.714 7.108)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                        </g>
                    </g>
                </svg>

            </button>
        </div>
        <div class="">
            <p><strong>{{ $gettext('Categoría') }}:</strong> {{ data.category_name }}</p>
            <p><strong>{{ $gettext('Servicio') }}:</strong> {{ data.display_name }}</p>
            <p><strong>{{ $gettext('Precio') }}:</strong> {{ data.service_price[0].price_type == 'NO_PRICE' ? 'Fijo' : data.service_price[0].price }}</p>
            <p><strong>{{ $gettext('Descripción') }}:</strong> {{ data.description }}</p>
        </div>
    </div>
    <add @saved="editedPost" :post="data" :index="index" :idBussines="idBussines" :visible="showDetail" @close="showDetail = $event"></add>

    <removePost :visible="showConfirmDelete" @close="showConfirmDelete = $event" @removeAccepted="deletePost">
        <p class="text-center">¿Estas seguro que desea borrar el servicio?</p>
    </removePost>
</div>
</template>

<script>
import commonlMixins from '@/mixins/common.js'
import localMixins from '@/mixins/local.js'
import SrvLocalServices from '@/services/localServices.js'
import removePost from '@/components/local/services/removeService.vue'
import add from '@/components/local/services/add.vue'

export default{
    props: ['data', 'idBussines','index'],
    mixins: [localMixins, commonlMixins],
    components: {
        removePost,add},
    data(){
        return {
            status: 'ok',
            showDetail: false,
            showConfirmDelete: false,
        }
    },
    methods: {
        editedPost(data){
            this.$emit('saved', data)
        },
        async deletePost(){
            const res = await SrvLocalServices.deleteService(this.idBussines, this.data.id)
            if (res.status === 204) {
                this.$emit('deletePost', this.data.id)
            }
        },
        edit(){
            this.showDetail = true
        }
    }
}
</script>
<style lang="scss" scoped>

.localPost{
    border-radius: 4px;
    border:1px solid #CECECE;
    .image{
        position: relative;
        .type{
            position: absolute;
            border-radius: 20px;
            background-color: #fff;
            width: calc(100% - 30px);
            margin-left: 15px;
            top: 14px;
            padding: 10px 15px;
            display: flex;
            align-items: center;
        }
    }
    .infoPost{
        padding: 10px 18px;
    }
}

</style>