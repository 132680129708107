// Config
import appConfig from "@/config-app.js";
export default {
  computed: {
    logoLogin() {
      if (appConfig.brand() === "adverbia") {
        return require("@/assets/img/adverbia/logo-login.svg");
      } else {
        return require("@/assets/img/logo-intro.svg");
      }
    },
    logoNav() {
      if (appConfig.brand() === "adverbia") {
        return require("@/assets/img/adverbia/nav-logo.svg");
      } else {
        return require("@/assets/img/nav/nav-logo.svg");
      }
    },
    brandDashboard() {
      return appConfig.brand();
    },
    isAdverbia() {
      if (this.brandDashboard === "adverbia") {
        return true;
      }
      return false;
    },
    isGenericEntity() {
      return this.isAdverbia || this.$store.getters?.getTreeData[0]?.is_hotel;
    },
    nameSectionMain() {
      if (this.isAdverbia) {
        return "NetworkHome";
      }
      return "GlobalHome";
    },
  },
};
