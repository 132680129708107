// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {

  getGlobalFeeling () {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/global_sentiment/`;
      query = SrvCommon.getBasicParamsQuery(query)

      if(Stores.getters.getTypesSelected){
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if(Stores.getters.getSitesDeliverySelected.length > 0){
        Stores.getters.getSitesDeliverySelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }

      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },
  getGpsFeeling () {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/delivery_gps/`;
      query = SrvCommon.getBasicParamsQuery(query)
      if(Stores.getters.getTypesSelected){
        query += `&review_type=${Stores.getters.getTypesSelected}`
      }
      if(Stores.getters.getSitesDeliverySelected.length > 0){
        Stores.getters.getSitesDeliverySelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }

      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },


}
