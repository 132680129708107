<template lang="pug">
    div(:style="'width:100vw;height:100vh;background-image: url(' + img.bg + ');background-size: cover;background-repeat: no-repeat;background-position: center center;max-width:100%;'")
        div.box(v-if="show == 'ok'")
            .text-center.pb-5
                img.logo(:src="img.logo")
            .box-white
                .borderTopGR
                div.boxFace
                    img(:src="img.face")
                p(class="nameOfFile") #[translate Account validated!]
                p(class="legend") #[translate Thank you, we have started to configure your account, when we finish the process you will receive a notification email to be able to access the application.]
        div.box(v-if="show == 'ko'")
            .text-center.pb-5
                img.logo(:src="img.logo")
            .box-white
                .borderTopGR
                div.boxFace
                    img(:src="img.faceError")
                p.pb-9(class="legend") {{ error }}


</template>

<script>

import SrvUsers from '@/services/users.js'
export default {
    data: function (){
        return {
            img: {
                logo: require('@/assets/img/logo-intro.svg'),
                bg: require('@/assets/img/bg_app_view.svg'),
                face: require('@/assets/img/smile.svg'),
                faceError: require('@/assets/img/face_error.svg')
            },
            show: '',
            error: ''
        }
    },
    created () {
        let uid = this.$route.query.uid
        let token = this.$route.query.token
        SrvUsers.getValidatedApp(uid, token)
            .then(res => {
                if (res.status === 200) {
                    this.show = 'ok'
                }
                }, (error) => {
                    this.show = 'ko'
                    this.error = error.response.data.detail
                })
    }
}
</script>

<style lang="scss" scoped>
#login{
    justify-content: left;
}
.borderTopGR{
    border: 40px solid #F93549;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
html,body{
            height: 100%;
        }
        body{
            margin: 0;
            font-family: 'Source Sans Pro', sans-serif;
        }
        .box{
            width: 506px;
            text-align: center;
            padding-left: 13.51vw;
            padding-top: 18.47vh;
        }
        .boxFace{
            margin-top: 64px;
        }
        .box-white{
            background-color: #ffffff;
            border-radius: 10px;
            margin-bottom: 26px;
            
        }
        .nameOfFile{
            font-size: 22px;
            font-weight: 600;
            color: #333333;
            margin-bottom: 0;
        }
        .legend{
            font-size: 16px;
            color:#333333;
            padding: 34px 15px 51px 15px;
            margin: 0;
        }
        .logo{
            max-width: 370px;
        }
        @media (max-width: 768px) {
            .box{
                padding: 0;
                padding-top: 18.47vh;
                margin: 0 auto;
                width: 90%;
            }
        }
</style>
