<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 18" :class="{'dBlock': block}">
  <g id="Capa_2" data-name="Capa 2">
    <g id="Capa_1-2" data-name="Capa 1">
      <path :fill="color" class="cls-1" d="M19.4,5.91a10.52,10.52,0,0,0-2-1.77A3.07,3.07,0,0,0,17,3.87l-.32-.24L15.16,2.45l-1.37-1L12.61.5A2.18,2.18,0,0,0,10.72.06a2.31,2.31,0,0,0-1.5,1.16A2.89,2.89,0,0,0,10,4.78l.36.29H3.18A2.2,2.2,0,0,0,1.12,7.36a2.45,2.45,0,0,0,.11.71A2.27,2.27,0,0,0,0,10.13a2.45,2.45,0,0,0,.63,1.65A2.41,2.41,0,0,0,.35,12.9a2.19,2.19,0,0,0,2.07,2.29h.32a1.67,1.67,0,0,0-.05.49A2.21,2.21,0,0,0,4.76,18H15.27a4.71,4.71,0,0,0,.53,0c2.51,0,4.41-1.88,4.94-4.51A12.75,12.75,0,0,0,21,11,8.06,8.06,0,0,0,19.4,5.91ZM11,3.29A1.1,1.1,0,0,1,10.75,2a.58.58,0,0,1,.35-.27.6.6,0,0,1,.5.13l1.16.89,1.4,1.07L15.69,5c.21.18.46.36.72.55a10.65,10.65,0,0,1,1.73,1.48c1.15,1.41,1.31,3.66,1.07,5.41h0l-.12.7a3.56,3.56,0,0,1-3.77,3.19H4.81a.41.41,0,0,1-.29-.18c-.14-.21-.14-.85.21-.84h5.52a.86.86,0,0,0,.61-.29.92.92,0,0,0,.24-.65.9.9,0,0,0-.91-.86H2.46A.5.5,0,0,1,2.08,13a.48.48,0,0,1,.34-.5h7.2a.88.88,0,0,0,.84-.94.9.9,0,0,0-.91-.86H2.15a.48.48,0,0,1-.38-.55.48.48,0,0,1,.34-.5H9.35A.86.86,0,0,0,10,9.36a.87.87,0,0,0,.23-.64.89.89,0,0,0-.91-.86H3.19c-.16,0-.31-.24-.31-.52a.47.47,0,0,1,.35-.5h9.55a.88.88,0,0,0,.78-.58.83.83,0,0,0-.24-1"/>
    </g>
  </g>
</svg>
</template>

<script>
export default {
  name: "IcoService",
  props: {
    color: {
        type: String,
        default: '#000000',
        required: false
    },
    block: {
        type: Boolean,
        default: true,
        required: false
    }
  }
}
</script>

<style scoped>
    .dBlock{
        display: block;
    }
</style>