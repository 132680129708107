<template lang="pug">
.ma-0.newDay.pt-3.mb-5.pb-5
    .d-flex.text-14.items-start
        div.d-flex.mr-8
            .d-flex.flex-col.align-start.mr-4
                button
                    img(:src="img.date")
                button
                    img(:src="img.remove" @click="removeDay")
            div
                <DatePicker v-model="day.start_date" class="w-full flex-1 mr-2 max-w-auto" @change="updateDay" :lang="calendar.lang" :format="calendar.format" type="date" :first-day-of-week="calendar.firstDayOfWeek"></DatePicker>
                div.d-flex.align-center.mt-2
                    switch-close(@changeSync="changeClosed" :sync="day.isclosed" :id="day.start_date" :pos="1" :isExtended="false" status="")
                    span.ml-2 Cerrado
        v-col.relative
            span.absolute(v-if="showHead" style="top: -32px; left:0;") Apertura
            span.absolute(v-if="showHead" style="top: -32px; left:90px;") Cierre  
            box-time(:data="day.times" :closed="day.isclosed" @updateTimes="updateTimes") 
</template>

<script>
import SwitchClose from '@/components/local/SwitchClose.vue';
import BoxTime from '@/components/local/BoxTime.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es'
export default {
    props: {
        'data': {
            required: true
        },
        'id': {
            required: true
        },
        showHead: {
            default: false
        }
    },
    components: {SwitchClose, BoxTime, DatePicker},
    data() {
        return {
            img: {
                date: require('@/assets/img/newday.svg'),
                remove: require('@/assets/img/removeday.svg')
            },
            day: null,
            'calendar': {
                'before': this.$store.getters.getDateBefore,
                'after': this.$store.getters.getDateAfter,
                'clearable': false,
                'date':'',
                lang: {
                    formatLocale: {
                        firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                },
                'format': 'DD/MM/YYYY', // 'MMM YYYY'
                'firstDayOfWeek': 1
            }
        }
    },
    created() {
        this.day = this.data
        this.day.start_date = this.$moment(this.day.start_date).toDate()
    },
    methods: {
        changeClosed(event) {
            this.day.isclosed = event.state
            this.updateDay()
        },
        updateDay() {
            this.$emit('updateDay', {data: this.day,  id: this.id})
        },
        updateTimes(event) {
            this.day.times = event
            this.$emit('updateDay', {data: this.day,  id: this.id})
        },
        removeDay() {
            this.$emit('removeDay', this.id)
        }
    },
    watch: {
        data() {
            this.day = this.data
            this.day.start_date = this.$moment(this.day.start_date).toDate()
        }
    }
}
</script>

<style>
.newDay{
    border-bottom: 1px solid #DEDEDE;
}
</style>