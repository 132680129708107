// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'
axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {
getGlobalApp (version = false, excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/summary_appstore/`
      query = SrvCommon.getBasicParamsQuery(query)

      query += `&review_type=app_store`

      if(Stores.getters.getSitesSelected.length > 0){
        Stores.getters.getSitesSelected.forEach(sit => {
          query += `&site=${sit}`
        })
      }
      if (version) {
        query += `&version=reviews`
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach(type => {
          query += `&center_model=${type}`
        })
      }

      // llamada a la API
      let token = SrvCommon.getToken();
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query,configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },

  getEvolutionApp (version = false, excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/apps/last_year/`
      query = SrvCommon.getBasicParamsQuery(query)

      query += `&review_type=app_store`

      if (version) {
        query += `&version=reviews`
      }

      // llamada a la API
      let token = SrvCommon.getToken();
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query,configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },

  getReviewsApp (order, excel = false) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING)
      let query = `/reviews/`;

      if(Stores.getters.getDateAfter){
        let after = Stores.getters.getDateAfter;
        if(after.indexOf('T')){
          let partes = after.split('T');
          after = partes[0];
        }
        query += `?after=${after}`
      }

      if(Stores.getters.getDateBefore){
        let before = Stores.getters.getDateBefore;
        if(before.indexOf('T')){
          let partes = before.split('T');
          before = partes[0];
        }
        query += `&before=${before}`
      }

      // SI EL NIVEL NO EXISTE ES CENTRO
      if(Stores.getters.getNodeLevel === 9){
        if(Stores.getters.getNode){
          query += `&center=${Stores.getters.getNode}`
        }
      }else{
        if(Stores.getters.getNode){
          query += `&node=${Stores.getters.getNode}`
        }
      }

      // SI VIENE EL TIPO DE CENTRO
      if(Stores.getters.getTypesRestaurantSelected.length > 0){
        Stores.getters.getTypesRestaurantSelected.forEach(type => {
          query += `&center_type=${type}`
        })
      }

      query += `&review_type=app_store`

      if(Stores.getters.getOpinionSitesAppSelected.length){
        query += `&site=${Stores.getters.getOpinionSitesAppSelected[0].value}`
      }
      if (order === 'source_date') {
        order += ',source_time'
      } else if (order === '-source_date') {
        order += ',-source_time'
      }
      query += '&ordering='+order+',-id'
      let configHeaders = {}
      if (excel) {
        query += `&format=xlsx`
        configHeaders = SrvCommon.getConfigHeadersExcel()
      }
      // llamada a la API
      let token = SrvCommon.getToken();

      // llamada a la API
      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query,configHeaders)
        .then(res => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  },

  getReviewsNextApp (url) {
    return new Promise((resolve, reject) => {
      let token = SrvCommon.getToken();

      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(url)
        .then(res => {
          Stores.commit(types.NOT_PENDING)
            resolve(res)
        })
        .catch(error => {
          Stores.commit(types.NOT_PENDING)
            const status = error.response.status
            if (status === 401) {
              SrvCommon.logout()
            }
            reject(error)
        })
    })
  },

}
