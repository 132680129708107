<template lang="pug">
    div
      gp-filter-top-analysis#filtersFixed(@getData="getAggregatorsRanking" :type="'aggregators'")
      v-container.gastroprice-content.with-menu.d-flex.flex-column(fluid)
        .block-content.row    
          v-container.table-data.ma-5.pa-0(v-if="isLoading" fluid)
            v-skeleton-loader(type="table")
          #headerFixedARank(v-show="!isLoading" fluid)
            v-container.table-data.pa-0.mb-0
              .table-content
                .header.border-b-dark-gray.d-flex(v-show="dataHeader")
                  v-col(v-for="(item, index) in dataHeader" :class="{'border-col-rigth-dark col-3': index === 0, 'border-col-rigth text-center': index > 0}")
                    translate.font-weight-med.font-14(v-if="index == 0") ITEM MENU
                    .block-aggregator.d-flex.align-center.p-relative(v-else)
                      img.aggregatorIcon(:src="getImg(item)")
                      span.ml-3.font-weight-med.font-14 {{item}}
                      .boxOrder
                        span(@click="cleanOrder()") 
                          v-icon mdi-close
                        span(@click="setOrderData(index,'desc')") 
                          v-icon(:class="{'arrowActive': ord === 'desc'}") mdi-arrow-up
                        span(@click="setOrderData(index,'asc')")
                          v-icon(:class="{'arrowActive': ord === 'asc'}")  mdi-arrow-down
          v-container.table-data.pa-0(v-show="!isLoading" fluid)          
              .content-item.d-flex.align-center.border-b-gray(v-show="dataSorted" v-for="(row, index) in dataSorted" :class="{'bg-ligth-gray': index % 2 != 0}")
                v-col(v-for="(item, i) in row" :class="{'border-col-rigth-dark col-3': i === 0, 'border-col-rigth text-center font-weight-med': i > 0}")
                  span(v-if="item !== 0") {{item | formatNumber(2,2)}} 
                  span(v-else) -
                  span(v-if="item &&  i > 0") €

</template>

<script>
import GpFilterTopAnalysis from '@/components/filters/filter-top-analysis.vue'
import SrvGastroPricing from '@/services/gastropricing.js'

export default {
  components: {GpFilterTopAnalysis},
  name: 'aggregatorsRank',
  data() {
    return {
      dataRows: [],
      dataHeader: [],
      fieldOrder: 0,
      ord: ''
    }
  },
  created(){
  },
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    },
    dataSorted () {
      return this.lodash.orderBy(this.dataRows, this.fieldOrder, [this.ord,this.ord,this.ord,this.ord])
    }
  },
  methods: {
    cleanOrder () {
      this.fieldOrder = 0
      this.ord = ''
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    setOrderData (campo, ord) {
      this.fieldOrder = campo
      this.ord = ord
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    getAggregatorsRanking(data){
      this.$store.commit('LOADING', true)
      SrvGastroPricing.getAggregatorsRanking(data.brand, data.filters)
      .then(res => {
          if (res.status === 200) {
            this.dataHeader = res.data.table.header
            this.dataRows = res.data.table.rows.map((ele) => {
              ele[1] = ele[1] || null
              ele[2] = ele[2] || null
              ele[3] = ele[3] || null

              return ele
            })
            this.$nextTick(() =>{
                let navtop = document.querySelector('#navtop')
                let headerRank = document.querySelector('#headerFixedARank')
                headerRank.setAttribute('style','top:' + (Number(navtop.offsetHeight) + 62) + 'px')
            })
            this.$store.commit('LOADING', false)
          } 
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    getImg(img){
      return require("@/assets/img/aggregators/"+ img +".png")
    }
  },
  mounted () {
    let elementBody = document.querySelector('.gastroprice-content')
    elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
    this.$nextTick(() =>{      
      window.addEventListener('resize', function () {
        let elementBody = document.querySelector('.gastroprice-content')
        if (elementBody) {
          elementBody.setAttribute('style','padding-top:'+ (document.getElementById('filtersFixed').offsetHeight) + 'px;')
        }
        
      })
    })
  },
  watch: {
    isLoading() {
      // console.log(old, ne)
    }
  }
}
</script>
<style lang="scss" scoped>
.block-content{
  margin: 0;
}
.aggregatorIcon{
  height: 27px;
}
.boxOrder{
  position: absolute;
  bottom: -24px;
  right: -5px;
  width: 100%;
  text-align: center;
  span{
    display: inline-block;
    margin: 0 5px;    
    .v-icon{
      font-size: 16px;
      cursor:pointer;
      &.arrowActive{
        color:#498FE1;
      }
    }
  }
}
.content-item{
  height: 42px;
  span{
    font-size: 0.813rem;

  }
}
#headerFixedARank{
  position: sticky;
  background-color: #fff;
  width: 100%;
  font-size: 0.875rem;
  z-index: 10;
  .container{
    max-width: initial !important;
  }
}
#marginTopCleanFixed{
  padding-top: 70px !important;
}
.header{
  .block-aggregator{
    justify-content: center;
  }
}
</style>