<template lang="pug">
div.d-flex.justify-center.items-center
    span.pr-2.text-12 {{ label }}
    one-color-bar(:width="width" :innerText="innerText")
    span.pl-2.text-12 {{ rightLabel }}
</template>

<script>
import OneColorBar from '../charts/OneColorBar.vue';
export default {
    components: { OneColorBar },
    props: {
        width: {
            type: Number,
            default: 0
        },
        label: {
            type: String,
            default: "-"
        },
        rightLabel: {
            type: String,
            default: "-"
        },
        innerText: {
            type: String,
            required: false
        }
    },
}
</script>


<style lang="scss" scoped>
span {
    color: #707070;
}
</style>