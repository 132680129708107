<template>
    <div class="w-full">
        <div class="detractores">
            <div class="labelPercent">{{ negative | formatNumber }}%</div>
            <div class="boxProgress" :style="'height:' + height + 'px'">
                <div class="complete" :style="'width:' + negative + '%;height:' + height + 'px'"></div>
            </div>
        </div>
        <div class="promotores">
            <div class="boxProgress" :style="'height:' + height + 'px'">
                <div class="complete" :style="'width:' + positive + '%;height:' + height + 'px'"></div>
            </div>
            <div class="labelPercent">{{ positive | formatNumber }}%</div>
        </div>
    </div>    
</template>
<script>
export default {
    props: ['positive', 'negative','height'],

}
</script>
<style lang="scss" scoped>
.promotores, .detractores{
           display: inline-block;
           width: 50%;
           .labelPercent, .boxProgress{
               display: inline-block;
           }
           .labelPercent{
               width: 34%;
           }
           .boxProgress{
               width: 66%;
               background-color: #E5E5E5;
               height: 10px;
               position:relative;
               .complete{
                   height: 10px;
                   position: absolute;
               }
           }
       }
       .promotores{
           border-left: 1px solid black;
           text-align: left;
           img{
               margin-left: 10px;
               vertical-align: middle;
           }
           .boxProgress{
               .complete{
                   background-color: #8AC543;
               }
           }
           .labelPercent{
               text-align: center;
           }
       }
       .detractores{
           img{
               margin-right: 10px;
               vertical-align: middle;
           }
           .boxProgress{
               .complete{
                   background-color: #F93247;
                   right: 0;
               }
           }
       }
</style>
