// Importamos mutaciones
import * as types from "@/store/mutation-types";
import * as actionsTypes from "@/store/actions-types";
import SrvCommon from "@/services/common.js";

const common = {
  state: {
    after: "",
    before: "",
    pending: false,
    typesRestaurant: [],
    typesRestaurantSelected: [],
    centerModels: [],
    centerModelsSelected: [],
    allSitesOpinions: [],
    sitesOpinions: [],
    sitesSelected: [],
    typesSelected: null,
    internetAutomatic: false,
    reasonsSelected: [],
    reasons: [],
    categories: [],
    regions: [],
    regionSelected: [],
    sitesDeliverySelected: [],
    loading: "",
    queryParams: "",
    userInfo: [],
    treeSelected: undefined,
    downloadingExcel: false,
    limitExcededExcel: false,
    globalLoading: false,
    processFilter: false,
    hasDelivery: false,
    hasCompetence: false,
    hasEmployees: false,
    hasDishKeywords: false,
    hasCustomSurveys: false,
    aggregatorSelectedId: null,
    momentSelectedId: null,
    distanceSelectedId: null,
    distanceMap: 2000,
    distanceMapMax: 2000,
    distanceMapActive: false,
    showNotInternet: true,
    showNotDelivery: true,
    isHotel: false,
    topSectionHeight: 0,
  },
  mutations: {
    [types.COMMON_SHOW_NOT_INTERNET](state, data) {
      state.showNotInternet = data;
    },
    [types.COMMON_SHOW_NOT_DELIVERY](state, data) {
      state.showNotDelivery = data;
    },
    [types.COMMON_DISTANCE_MAP_ACTIVE](state, data) {
      state.distanceMapActive = data;
    },
    [types.COMMON_DISTANCE_MAP](state, data) {
      state.distanceMap = data;
    },
    [types.COMMON_DISTANCE_MAP_MAX](state, data) {
      state.distanceMapMax = data;
    },
    [types.COMMON_DO_NOT_PROCESS_FILTER](state, data) {
      state.processFilter = data;
    },
    [types.COMMON_DATE_AFTER](state, data) {
      state.after = data;
    },
    [types.COMMON_DATE_BEFORE](state, data) {
      state.before = data;
    },
    [types.COMMON_CENTER_MODELS](state, data) {
      state.centerModels = data;
    },
    [types.COMMON_CENTER_MODELS_SELECTED](state, data) {
      state.centerModelsSelected = data;
    },
    [types.COMMON_TYPES_RESTAURANT](state, data) {
      state.typesRestaurant = data;
    },
    [types.COMMON_TYPES_RESTAURANT_SELECTED](state, data) {
      state.typesRestaurantSelected = data;
    },
    [types.COMMON_SITES_SELECTED](state, data) {
      state.sitesSelected = data;
    },
    [types.COMMON_TYPES_SELECTED](state, data) {
      state.typesSelected = data;
    },
    [types.COMMON_TYPES_SELECTED_INTERNET_AUTOMATIC](state, data) {
      state.internetAutomatic = data;
    },
    [types.COMMON_ALL_SITES_OPINIONS](state, data) {
      state.allSitesOpinions = data;
    },
    [types.COMMON_SITES_OPINIONS](state, data) {
      state.sitesOpinions = data;
    },
    [types.REASONS_SELECTED](state, data) {
      state.reasonsSelected = data;
    },
    [types.COMMON_REASONS](state, data) {
      state.reasons = data;
    },
    [types.COMMON_CATEGORIES](state, data) {
      state.categories = data;
    },
    [types.COMMON_REGIONS](state, data) {
      state.regions = data;
    },
    [types.COMMON_REGION_SELECTED](state, data) {
      state.regionSelected = data;
    },
    [types.COMMON_SITES_DELIVERY_SELECTED](state, data) {
      state.sitesDeliverySelected = data;
    },
    [types.LOADING](state, data) {
      state.loading = data;
    },
    [types.GLOBAL_LOADING](state, data) {
      state.globalLoading = data;
    },
    [types.QUERY_PARAMS](state, data) {
      state.queryParams = data;
    },
    [types.USER_INFO](state, data) {
      state.userInfo = data;
    },
    [types.TREE_SELECT](state, data) {
      state.treeSelected = data;
    },
    [types.DOWNLOADING_EXCEL](state, data) {
      state.downloadingExcel = data;
    },
    [types.LIMIT_EXCEDED_EXCEL](state, data) {
      state.limitExcededExcel = data;
    },
    [types.COMMON_HAS_DELIVERY](state, data) {
      state.hasDelivery = data;
    },
    [types.COMMON_IS_HOTEL](state, data) {
      state.isHotel = data;
    },
    [types.COMMON_HAS_COMPETENCE](state, data) {
      state.hasCompetence = data;
    },
    [types.COMMON_HAS_EMPLOYEES](state, data) {
      state.hasEmployees = data;
    },
    [types.COMMON_HAS_DISHKEYWORDS](state, data) {
      state.hasDishKeywords = data;
    },
    [types.COMMON_HAS_CUSTOM_SURVEYS](state, data) {
      state.hasCustomSurveys = data;
    },
    [types.COMMON_AGGREGATOR_ID](state, data) {
      state.aggregatorSelectedId = data;
    },
    [types.COMMON_MOMENT_ID](state, data) {
      state.momentSelectedId = data;
    },
    [types.COMMON_DISTANCE_ID](state, data) {
      state.distanceSelectedId = data;
    },
    [types.COMMON_TOP_SECTION_HEIGHT](state, data) {
      state.topSectionHeight = data;
    },
  },
  actions: {
    async getCenterModels({ commit, state }) {
      let optionExist = [];
      commit(types.COMMON_DO_NOT_PROCESS_FILTER, false);
      commit("PENDING");
      const res = await SrvCommon.getCenterModels();
      if (res.status === 200) {
        let typesCenterCustom = [];
        typesCenterCustom = res.data.results.map(function (obj) {
          return { value: obj.id, text: obj.name };
        });
        if (state.centerModelsSelected) {
          state.centerModelsSelected.forEach((item) => {
            typesCenterCustom.forEach((element) => {
              if (element.value === item.value) {
                optionExist.push(element);
              }
            });
          });
        }
        commit(types.COMMON_CENTER_MODELS_SELECTED, optionExist);
        commit(types.COMMON_CENTER_MODELS, typesCenterCustom);
        commit("NOT_PENDING");
      }
    },
    async getTypesRestaurant({ commit, state }) {
      let optionExist = [];
      commit(types.COMMON_DO_NOT_PROCESS_FILTER, false);
      commit("PENDING");
      const res = await SrvCommon.getTypesRestaurant();
      if (res.status === 200) {
        let typesCenterCustom = [];
        typesCenterCustom = res.data.results.map(function (obj) {
          return { value: obj.id, text: obj.name, external_id: obj.external_id };
        });
        if (state.typesRestaurantSelected) {
          state.typesRestaurantSelected.forEach((item) => {
            typesCenterCustom.forEach((element) => {
              if (element.value === item.value) {
                optionExist.push(element);
              }
            });
          });
        }
        commit(types.COMMON_TYPES_RESTAURANT_SELECTED, optionExist);
        commit(types.COMMON_TYPES_RESTAURANT, typesCenterCustom);
        commit("NOT_PENDING");
      }
    },
    async getAllSitesOpinions({ commit }) {
      commit(types.COMMON_DO_NOT_PROCESS_FILTER, false);
      commit(types.COMMON_ALL_SITES_OPINIONS, "");
      commit("PENDING");
      const res = await SrvCommon.getAllSitesOpinions();
      if (res.status === 200) {
        commit(types.COMMON_ALL_SITES_OPINIONS, res.data);
        commit("NOT_PENDING");
      }
    },
    async getSitesOpinions({ commit }) {
      commit(types.COMMON_DO_NOT_PROCESS_FILTER, false);
      commit(types.COMMON_SITES_OPINIONS, "");
      commit("PENDING");
      const res = await SrvCommon.getSitesOpinions();
      if (res.status === 200) {
        commit(types.COMMON_SITES_OPINIONS, res.data);
        commit("NOT_PENDING");
      }
    },
    async getReasons({ commit }) {
      commit(types.COMMON_DO_NOT_PROCESS_FILTER, false);
      commit(types.COMMON_REASONS, []);
      commit("PENDING");
      const res = await SrvCommon.getReasons();
      if (res.status === 200 && res.data["delivery"] && res.data["delivery"]["complaint_reasons"]) {
        commit(types.COMMON_REASONS, res.data["delivery"]["complaint_reasons"]);
        commit("NOT_PENDING");
      }
    },
    async getCategories({ commit }) {
      commit(types.COMMON_DO_NOT_PROCESS_FILTER, false);
      commit(types.COMMON_CATEGORIES, []);
      commit("PENDING");
      const res = await SrvCommon.getCategories();
      if (res.status === 200) {
        if (res.data.delivery) {
          let cDelivery = res.data.delivery.categories.map((c) => {
            c.type = "delivery";
            return c;
          });
          res.data.delivery.categories = cDelivery;
        }
        if (res.data.internet) {
          let cInternet = res.data.internet.categories.map((c) => {
            c.type = "internet";
            return c;
          });
          res.data.internet.categories = cInternet;
        }

        commit(types.COMMON_CATEGORIES, res.data);
        commit("NOT_PENDING");
      }
    },
    async getRegions({ commit }) {
      commit(types.COMMON_DO_NOT_PROCESS_FILTER, false);
      commit(types.COMMON_REGIONS, []);
      commit("PENDING");
      const res = await SrvCommon.getRegions();
      if (res.status === 200) {
        let regionsCustom = [];
        let r = res.data.filter((e) => e);
        regionsCustom = r.map(function (item, key) {
          return { value: key, text: item };
        });
        commit(types.COMMON_REGIONS, regionsCustom);
        commit("NOT_PENDING");
      }
    },
    restorePasswordSet({ commit }, password, token) {
      commit("PENDING"); // Para mostrar el preload
      SrvCommon.restorePasswordSet(password, token).then(
        (res) => {
          if (res.status === 200) {
            commit("NOT_PENDING"); // Para quitar el preload
          } else {
            // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
          }
        },
        () => {
          // this.message = this.$gettext('Se ha producido un error. Intentelo más tarde.')
        }
      );
    },
    [types.COMMON_REGION_SELECTED]({ commit }, data) {
      commit(types.COMMON_REGION_SELECTED, data);
    },
    setRouteLoading({ commit }, value) {
      commit(types.GLOBAL_LOADING, value);
    },
    [actionsTypes.SET_IS_HOTEL]({ commit }, data) {
      commit(types.COMMON_IS_HOTEL, data);
    },
  },
  getters: {
    getDateAfter: (state) => {
      return state.after;
    },
    getDateBefore: (state) => {
      return state.before;
    },
    getTypesRestaurant: (state) => {
      return state.typesRestaurant;
    },
    getTypesRestaurantSelected: (state) => {
      return state.typesRestaurantSelected;
    },
    getCenterModels: (state) => {
      return state.centerModels;
    },
    getCenterModelsSelected: (state) => {
      return state.centerModelsSelected;
    },
    getAllSitesOpinions: (state) => {
      return state.allSitesOpinions;
    },
    getSitesOpinions: (state) => {
      return state.sitesOpinions;
    },
    getReasonsSelected: (state) => {
      return state.reasonsSelected;
    },
    getReasons: (state) => {
      return state.reasons;
    },
    getCategories: (state) => {
      return state.categories;
    },
    getRegions: (state) => {
      return state.regions;
    },
    getRegionSelected: (state) => {
      return state.regionSelected;
    },
    getSitesDeliverySelected: (state) => {
      return state.sitesDeliverySelected;
    },
    getSitesSelected: (state) => {
      return state.sitesSelected;
    },
    getTypesSelected: (state) => {
      return state.typesSelected;
    },
    getInternetAutomatic: (state) => {
      return state.internetAutomatic;
    },
    isLoading: (state) => {
      return state.loading;
    },
    isRouteLoading: (state) => {
      return state.globalLoading;
    },
    isPending: (state) => {
      return state.pending;
    },
    queryParams: (state) => {
      return state.queryParams;
    },
    getUserInfo: (state) => {
      return state.userInfo;
    },
    getTreeSelected: (state) => {
      return state.treeSelected;
    },
    getDownloadingExcel: (state) => {
      return state.downloadingExcel;
    },
    getLimitExcededExcel: (state) => {
      return state.limitExcededExcel;
    },
    processFilter: (state) => {
      return state.processFilter;
    },
    getHasDelivery: (state) => {
      return state.hasDelivery;
    },
    getHasCompetence: (state) => {
      return state.hasCompetence;
    },
    getHasEmployees: (state) => {
      return state.hasEmployees;
    },
    getHasDishKeywords: (state) => {
      return state.hasDishKeywords;
    },
    getHasCustomSurveys: (state) => {
      return state.hasCustomSurveys;
    },
    getAggregatorId: (state) => {
      return state.aggregatorSelectedId;
    },
    getMomentId: (state) => {
      return state.momentSelectedId;
    },
    getDistanceId: (state) => {
      return state.distanceSelectedId;
    },
    getDistanceMap: (state) => {
      return state.distanceMap;
    },
    getDistanceMapMax: (state) => {
      return state.distanceMapMax;
    },
    getDistanceMapActive: (state) => {
      return state.distanceMapActive;
    },
    getShowNotInternet: (state) => {
      return state.showNotInternet;
    },
    getShowNotDelivery: (state) => {
      return state.showNotDelivery;
    },
    getIsHotel: (state) => {
      return state.isHotel;
    },
    getTopSectionHeight: (state) => {
      return state.topSectionHeight;
    },
  },
};

export default common;
