import stores from "@/store";
import * as types from "@/store/mutation-types";

export default function setQuery(to) {
    if (stores.state.user.isLoggedIn) {
        //SI LLEGA PARAMETRO NO_TREE EVITAMOS PINTAR EL ARBOL
        if (to.query.no_tree) {
          stores.commit(types.TREE_SHOW_TREE, false)
        }
        // Load Parameters
        stores.commit(types.QUERY_PARAMS, to.query)
    
        // Save url params filters
        if (to.query.type) {
          stores.commit(types.COMMON_TYPES_SELECTED, to.query.type)
        }
        if (to.query.site) {
          stores.commit(types.COMMON_SITES_SELECTED, to.query.site.split(';').map(Number))
        }
        if (to.query.search) {
          stores.commit(types.FEEDBACK_OPINIONS_SEARCH, to.query.search)
        }        
        if (to.query.after) {
          stores.commit(types.COMMON_DATE_AFTER, to.query.after)
        }
        if (to.query.before) {
          stores.commit(types.COMMON_DATE_BEFORE, to.query.before)
        }
        if (to.query.region) {
          stores.commit(types.COMMON_REGION_SELECTED, to.query.region.split(';').map(String))
        }
        if (to.query.sitesdelivery) {
          stores.commit(types.COMMON_SITES_DELIVERY_SELECTED, to.query.sitesdelivery.split(';').map(Number))
        }
        if (to.query.center_models) {
          stores.commit(types.COMMON_CENTER_MODELS_SELECTED, to.query.center_models.split(';').map(Number))
        }
        if (to.query.center_type) {
          stores.commit(types.COMMON_TYPES_RESTAURANT_SELECTED, to.query.center_type.split(';').map(Number))
        }
        if (to.query.aggregator) {
          stores.commit(types.COMMON_AGGREGATOR_ID, to.query.aggregator)
        }
        if (to.query.moment) {
          stores.commit(types.COMMON_MOMENT_ID, to.query.moment)
        }
        if (to.query.distance) {
          stores.commit(types.COMMON_DISTANCE_ID, to.query.distance)
        }
        
      }
}