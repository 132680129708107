import Vue from 'vue'

export class FacebookService {
  constructor(appID) {
    this.appID = appID;
  }

  initFacebookSdk() {
    return new Promise(resolve => {
      const vue_fb = {}
      vue_fb.install = (Vue, options) => {
        if (!window.fbAsyncInit) {
          (function (d, s, id) {
            let js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
              return
            }
            js = d.createElement(s)
            js.id = id
            js.src = "//connect.facebook.net/en_US/sdk.js"
            fjs.parentNode.insertBefore(js, fjs)
          }(document, 'script', 'facebook-jssdk'))

          window.fbAsyncInit = function onSDKInit() {
            const FB = window.FB;
            FB.init(options)
            Vue.FB = FB
            resolve()
            window.dispatchEvent(new Event('fb-sdk-ready'))
          }
          Vue.FB = undefined
        } else {
          resolve()
        }
      }


      Vue.use(vue_fb, {
        appId: this.appID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v17.0'
      })
    });
  }
}

