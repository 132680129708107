
// Importamos mutaciones
import * as types from '@/store/mutation-types'

const navbar = {
  state: {
    'idSection': '',
  },
  mutations: {
    [types.UPDATE_SECTION] (state, data) {
      state.idSection = data
    },
  },
  getters: {
    'getIdSection': state => {
      return state.idSection
    },
  }
}

export default navbar
