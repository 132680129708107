
// Importamos mutaciones
import * as types from '@/store/mutation-types'

const reports = {
  state: {
    reports: [],
    reportTypesSelected: []
  },
  mutations: {
    [types.REPORTS] (state, data) {
        state.reports = data
    },
    [types.REPORT_FILTER_TYPES_SELECTED] (state, data) {
        state.reportTypesSelected = data
    }, 
  },
  getters: {
    'getReports': state => {
        return state.reports
    },
    'getReportTypesSelected': state => {
        return state.reportTypesSelected
    },
  }
}

export default reports