<template lang="pug">
span.category(:class="className")
  span(@click="linkFilterByCategory(category)") 
    span(v-if="isSubcat") {{ category.subcat.name }}
    span(v-else) {{ category.name }}
  span.optionRemove(v-if="hasEditTags" @click="removeTag") - 
</template>
<script>
export default {
  props: {
    category: {},
    isSubcat: {
      type: Boolean,
      required: false,
      default: false
    },
    hasEditTags: {
      type: Boolean,
      required: true,
      defaulf: false
    },
    hasLink: {
      type: Boolean,
      required: false,
      default: false
    },
    indexComment: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    className() {
      let className = ''
      if (this.isSubcat) {
        className = 'cat-' + this.category.unique_name.replaceAll(" ", '-') + ' subcat'
      } else {
        className = 'cat-' + this.category.unique_name.replaceAll(" ", '-')
      }
      if (this.hasEditTags) {
        className += ' hasEdit'
      }

      return className
    }
  },
  methods: {
    removeTag() {
      if (this.hasEditTags) {
        this.$emit('removeTag', { category: this.category, indexComment: this.indexComment })
      }
    },
    linkFilterByCategory(category) {
      if (this.hasLink) {
        // name, type, value
        let val = { name: '', type: '', value: null }
        val.name = category.name
        if (this.$store.getters.getSitesSelected === '' || this.$store.getters.getSitesSelected === 'internet') {
          val.type = 'internet'
        } else {
          val.type = 'delivery'
        }
        val.value = category.id
        this.$emit('linkFilterByCategory', val)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.category {
  background-color: #eaeaea;
  border-radius: 15px;
  padding: 2px 10px;
  font-size: 0.75rem;
  color: #666;
  margin-right: 7px;
  margin-bottom: 5px;
  display: inline-flex;
  cursor: pointer;
  position: relative;

  &.hasEdit {
    padding-right: 26px;
  }

  .optionRemove {
    top: 0;
    position: absolute;
    right: 0;
    background-color: #CBCBCB;
    height: 22px;
    width: 22px;
    text-align: center;
    border-radius: 22px;
    color: #707070;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.cat-quality,
  &.cat-Instalaciones {
    background-color: #919ECA;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }

  &.cat-price,
  &.cat-Prix,
  &.cat-comfort-and-hygiene {
    background-color: #817078;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }


  &.cat-kitchen {
    background-color: #919ECA;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }

  &.cat-service,
  &.cat-facilities-and-services {
    background-color: #737B95;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }

  &.cat-Salud,
  &.cat-Santé,
  &.cat-Ubicación,
  &.cat-hygiene,
  &.cat-environment,
  &.cat-staff {
    background-color: #7BB7CE;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }

  &.cat-atmosphere {
    background-color: #C0B8AB;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }

  &.cat-product,
  &.cat-Personal {
    background-color: #94A6BC;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }

  &.cat-time {
    background-color: #9D90D2;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }

  &.cat-order,
  &.cat-Restauración {
    background-color: #8A8A8A;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }

  &.cat-Error-App,
  &.cat-location {
    background-color: #B43547;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }

  &.cat-delivery,
  &.cat-restaurant-industry {
    background-color: #f70;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }

  &.cat-Services {
    background-color: #FB1807;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }
  &.cat-experience {
    background-color: #7D98D3;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }
  &.cat-staff {
    background-color: #000000;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }
  &.cat-price {
    background-color: #FFFF0B;
    color: #000;

    &.subcat {
      opacity: 0.6;
    }
  }
  &.cat-products {
    background-color: #447129;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }
  &.cat-promo_2x1 {
    background-color: #c16118;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }
  &.cat-drink {
    background-color: #1b9b72;
    color: #fff;

    &.subcat {
      opacity: 0.6;
    }
  }

}
</style>