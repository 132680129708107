<template lang="pug">
div
    v-dialog(v-model="dialogDownloadingExcel"
        width="459")
        v-card
            v-card-text.pb-0
            v-container
                v-row#headModalExcededLimit
                    v-col.mt-5.text-center(cols="12")
                        img.icoWarning(:src="imgCommons.modalDownload" width="140" height="140")
                        img.close-modal.cursor-pointer(:src="imgCommons.close" @click="dialogDownloadingExcel=false")
                v-row.mt-15
                    v-col.boxInfo.text-center(cols="12").mt-5
                        strong #[translate DOWNLOADING file...]
</template>

<script>
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"

export default {
    mixins: [commonMixins],
    computed: {
        dialogDownloadingExcel: {
            get: function () {
                return this.$store.getters.getDownloadingExcel
            },
            set: function (newValue) {
                this.$store.commit(types.DOWNLOADING_EXCEL, newValue)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#headModalExcededLimit {
    background-color: #DBE1E1;
}

.close-modal {
    position: absolute;
    top: 15px;
    right: 15px;
}

.icoWarning {
    margin-bottom: -70px;
}

.boxInfo {
    color: #4D4F5C;
    font-size: 1rem;
}</style>