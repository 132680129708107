<template>
    <div :id="'section' + data.id" class="cartSection">
        <div class="d-flex">
            <div class="flex-1">
                <span class="cartSectionName">{{ data.name }}</span>
                <p classs="cartSectionDescription">{{ data && data.description ? data.description : '' }}</p>
            </div>
            <div class="d-flex mb-1 cartSectionActions">
                <ButtonEditItem @click.native="editSection"><span class="text-12 ">Editar sección</span></ButtonEditItem>
                <ButtonAddItem class=" ml-2 " @click.native="addProduct"><span class="text-12">Añadir producto</span>
                </ButtonAddItem>
            </div>
        </div>

        <div class="cartSectionGridDish">
            <Dish v-for="(dish, indexDish) in data.items" :data="dish" @productOk="$emit('productOk', $event)"
                :idSection="data.id" :key="indexDish"></Dish>
        </div>
        <ModalAddSection :data="data" :visible="showAddEditSection" :idBussines="center"
            @sectionDeleteOk="$emit('sectionDeleteOk', $event)" @sectionCreatedOk="$emit('sectionCreatedOk', $event)"
            @close="showAddEditSection = false" @remove="remove = $event" @edit="edit = $event" :isEdit="edit" :isRemove="remove">
        </ModalAddSection>
        <ModalAddDish :idSection="data.id" :visible="showAddEditProduct" :idBussines="center"
            @productOk="$emit('productOk', $event)" @close="showAddEditProduct = false" @remove="remove = $event"
            @edit="edit = $event" :isEdit="edit" :isRemove="remove"></ModalAddDish>
    </div>
</template>

<script>
import ModalAddDish from '@/components/local/menu/ModalAddDish.vue'
import ModalAddSection from '@/components/local/menu/ModalAddSection.vue'
import localMixins from "@/mixins/local.js"

import ButtonAddItem from '@/components/local/menu/ButtonAddItem.vue'
import ButtonEditItem from '@/components/local/menu/ButtonEditItem.vue'
import Dish from '@/components/local/menu/Dish.vue'
export default {
    components: { Dish, ButtonAddItem, ButtonEditItem, ModalAddDish, ModalAddSection },
    mixins: [localMixins],
    props: {
        data: {
            default: {},
            required: true
        }
    },
    created() {
        this.center = Number(this.$store.getters.getNode)
    },
    data() {
        return {
            center: null,
            showAddEditSection: false,
            showAddEditProduct: false,
            remove: false,
            edit: false
        }
    },
    methods: {
        addProduct() {
            if (this.hasPremium) {
                this.showAddEditProduct = true
            }
        },
        editSection() {
            if (this.hasPremium) {
                this.showAddEditSection = true
            }
        },
    }
}
</script>
<style>
.cartSection {
    border-radius: 8px;
    background-color: #fff;
    padding: 33px 18px 22px 18px;
    margin-bottom: 24px;
}

.cartSectionActions {
    align-self: baseline;
}

.cartSectionName {
    font-size: 18px;
    color: #222226;
}

.cartSectionDescription {
    font-size: 14px;
}

.cartSectionGridDish {
    display: flex;
    flex-wrap: wrap;
}
</style>