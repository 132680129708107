<template lang="pug">
  div
    
    div.margin-bottom-0.gridFull
      v-row.flex-center.block-head.mb-0
        v-col(md="2")
          .block-count.pl-1
            span.mr-1.font-weight-med {{claimsCounts}}
            translate Claims             
        v-col(md="10") 
          div.filters
            span.downloadExcel.mr-5(@click="downloadExcelCustom()")
              img(:src="imgCommons.download" width="10")
            span.labelFilter #[translate Origin]:
            div.filter                  
              multi-select.inline-block(:options="originTypes" :itemsChecked="originTypesSelected" @checked="onCheckoriginTypes")
            span.labelFilter #[translate character]:
            div.filter                  
              multi-select.inline-block(:options="characterTypes" :itemsChecked="characterTypesSelected" @checked="onCheckcharacterTypes")
            
      v-row.contentOutPadding.mt-0
        v-col(md="12").contentOutPadding.tablaGR 
          v-data-table#tableClaims(
          :headers="headers"
          :items="claims"
          :page.sync="claims.length / 19"
          :items-per-page="claims.length"
          hide-default-footer
          class="elevation-1"
          item-key="external_id"
          :loading="loading"
          :loading-text="loadingtext"
          )
              template(v-slot:item.text="{ item }")
                .d-flex.align-baseline#block-comment
                  div.boxNoteAndName                      
                    span.boxDown(v-if="showDownComment(item)")
                      img.down(:src="imgCommons.down" :class="{'rotate':item.comentariocompleto}" @click="mostrarComentario(item)")
                  div
                    p.comentario.mb-0(id="parraf" :style="'max-width: ' + totalWidth + 'px'" :class="{'comentarioRecortado': (!item.comentariocompleto)}" dark) {{ getTextOpinion(item) }}
                    
              template(v-slot:item.kind="{ item }")
                  p.btnCentro.py-1.mb-0 {{ item.kind.name }}                
              template(v-slot:item.center="{ item }")
                span.btnCentro.link-text(dark @click="goItemNextLevelCustom(item)")  {{ item.center.name }}
              template(v-slot:item.source_date="{ item }")
                span.text-date( dark) {{ item.source_date | moment("DD/MM/YYYY") }}
      
</template>

<script>
import MultiSelect from '@/components/filters/Multipleselect.vue'
import SrvSatisfaction from '@/services/satisfaction.js'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"

export default {
    mixins: [commonMixins],
    components: {MultiSelect}, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
    data(){
        return {
            claims: [], // COLECCION DE COMENTARIOS
            originTypes: [ ],
            originTypesSelected: [], // ELEMENTOS SELECCIONADOS DEL FILTRO
            characterTypes: [],
            characterTypesSelected: [],
            headers: [ // CABECERAS DE LA TABLE COMENTARIOS
              { text: this.$gettext('CLAIM'), value: 'text', sortable: false, width: '35%' },
              { text: this.$gettext('RESTAURANT'), value: 'center', sortable: false, width: '30%'},
              { text: this.$gettext('ORIGIN'), value: 'source.name', sortable: false, width: '25%'},
              { text: this.$gettext('DATE'), value: 'source_date', sortable: false, width: '15%'},
            ],
            loading: true, // BOOLEANA PARA MOSTRAR LOADING EN LA TABLE
            loadingtext: this.$gettext('Loading claims'),
            totalWidth: 680,
            nextScrollUrl: null,
            nextCall: false,
            claimsCounts: ''
        }
    },
    // OBTENERMOS DEL STORE LOS FILTROS APLICADOS 
    created(){
      this.originTypesSelected = this.$store.getters.getOriginTypesSelected
      this.characterTypesSelected = this.$store.getters.getCharacterTypesSelected
  
      this.getKinds()
      this.getSources()
      if(this.$store.getters.getNode > 0){
        this.getClaimsCount()
        this.getClaims()
      }
      if(this.claims.length == 0){
        if(this.$store.getters.getClaims.length > 0){
          this.claims = this.$store.getters.getClaims.results     
          this.loading = false
        }         
      }
    },
    mounted() {
      this.scroll()
    },
    watch: {
      filterApply: function(){   
        this.nextScrollUrl = null      
        this.getKinds()
        this.getSources()
        this.getClaims()
        this.getClaimsCount()
      },
      totalWidth: function() {
        return this.totalWidth
      },    
    },
    computed: {
      
    },
    methods: {
      downloadExcelCustom () {
        this.downloadExcel(null,this.claimsCounts)
      },
      goItemNextLevelCustom(item){
        let itemCustom = {}
        itemCustom.id = item.center.id
        itemCustom.name = item.center.name
        itemCustom.level = 9
        this.goItemNextLevel(itemCustom,true)
      },
      getTextOpinion(item){
        let text = ''
        
        if(item.text !== null && item.text !==''){
          text += item.text
        }
        return text
      },
      
      showDownComment: function(item){
        if(item.text && item.text.length > 80){
          return true;
        }
        return true
      },
      totalCommentWidth() {
        setTimeout(() => {
          if (document.querySelector('#tableClaims')) {
            let box = document.querySelector('#tableClaims')
            this.totalWidth = (box.offsetWidth * 55) / 100
            let comentarios = document.querySelectorAll('.comentario')
            for (let i = 0; i < comentarios.length; i++) {
              comentarios[i].style.width = this.totalWidth + 'px'
            }
          }
          window.addEventListener('resize', function () {
            if (document.querySelector('#tableClaims')) {
              let box = document.querySelector('#tableClaims')
              let totalWidth = (box.offsetWidth * 55) / 100
              let comentarios = document.querySelectorAll('.comentario')
              for (let i = 0; i < comentarios.length; i++) {
                comentarios[i].style.width = totalWidth + 'px'
              }
            }
          })
        }, 100)
      },
      // SE PULSA PARA DESPLEGAR COMENTARIO COMPLETO
      // atributo "comentariocompleto mapeado en la petición al api"
      mostrarComentario: function(item){
        if(item.comentariocompleto == true){
          item.comentariocompleto = false
        }else{
          item.comentariocompleto = true
        }
      },
      // FUNIONES LLAMADAS DESDE EL COMPONENTE MULTISELECT CON EMMIT
     
      onCheckoriginTypes(val) {
        this.originTypesSelected = val
        this.$store.commit(types.SATISFACTION_FILTER_ORIGIN_TYPES_SELECTED, val)
        this.nextScrollUrl = null
        this.getClaims()
        this.getClaimsCount()
      },
      onCheckcharacterTypes(val) {
        this.characterTypesSelected = val
        this.$store.commit(types.SATISFACTION_FILTER_CHARACTER_TYPES_SELECTED, val)
        this.nextScrollUrl = null
        this.getClaims()
        this.getClaimsCount()

      },      
      // LLAMAMOS AL SERVICIO QUE CARGA LOS COMENTARIOS CON LOS FILTROS APLICADOS
      getClaims: function(){
        this.$store.commit('LOADING', true)
            if(!this.nextScrollUrl) {
                SrvSatisfaction.getSatisfactionSacClaims('claims')    
                    .then(res => {
                        if (res.status === 200) {
                          let custom = res.data.results.map((obj)=>({...obj,['comentariocompleto']:false,['verrespuesta']:false}))
                          this.claims = custom;
                          this.nextScrollUrl = res.data.next
                          this.$store.commit(types.FEEDBACK_OPINIONS, {results: custom})
                          this.loading = false
                          this.totalCommentWidth()
                          this.$store.commit('LOADING', false)

                        } else {
                        // this.message = 'Se ha producido un error. Intentelo más tarde.'
                        }
                    }, () => {
                        // this.message = 'Se ha producido un error. Intentelo más tarde.'
                    })
            } else{
                 SrvSatisfaction.getSatisfactionSacClaimsNext(this.nextScrollUrl)    
                    .then(res => {
                        if (res.status === 200) {
                          let custom = res.data.results.map((obj)=>({...obj,['comentariocompleto']:false,['verrespuesta']:false}))
                          custom.forEach(element => {
                              this.claims.push(element)
                          });                        
                          this.nextScrollUrl = res.data.next
                          this.$store.commit(types.FEEDBACK_OPINIONS, {results: custom})
                          this.nextCall = false
                          this.totalCommentWidth()                          
                        } else {
                        // this.message = 'Se ha producido un error. Intentelo más tarde.'
                        }
                    }, () => {
                        // this.message = 'Se ha producido un error. Intentelo más tarde.'
                    })
            }
        },
        getClaimsCount(){
          SrvSatisfaction.getSatisfactionSacClaimsCount()    
              .then(res => {
                  if (res.status === 200) {
                    this.claimsCounts = res.data
                  } else {
                  // this.message = 'Se ha producido un error. Intentelo más tarde.'
                  }
              }, () => {
                  // this.message = 'Se ha producido un error. Intentelo más tarde.'
              })
            
        },
        getKinds: function(){
          SrvSatisfaction.getSatisfactionSacClaimsKinds()    
            .then(res => {
                if (res.status === 200) {
                  this.characterTypes = res.data.results.map(function(obj){
                    var rObj = {'value':obj.id,'text':obj.name}
                    return rObj
                  });
                } 
            }, () => {
                // this.message = 'Se ha producido un error. Intentelo más tarde.'
            })
            
        },
        getSources: function(){
          SrvSatisfaction.getSatisfactionSacClaimsSources()    
            .then(res => {
                if (res.status === 200) {
                  this.originTypes = res.data.results.map(function(obj){
                    var rObj = {'value':obj.id,'text':obj.name}
                    return rObj
                  });
                } 
            }, () => {
                // this.message = 'Se ha producido un error. Intentelo más tarde.'
            })
            
        },
        scroll () {
            window.onscroll = () => {
                if(this.$route.name === 'SatisfactionSacClaims'){
                    var body = document.body,
                        html = document.documentElement
                    var height = Math.max( body.scrollHeight, body.offsetHeight, 
                        html.clientHeight, html.scrollHeight, html.offsetHeight )
                    height = parseInt(height,10) -1
                    let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height || parseInt((document.documentElement.scrollTop + window.innerHeight).toFixed(0),10) + 1 == height || (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height + 1
                    if (this.nextScrollUrl && bottomOfWindow && !this.nextCall) {
                        this.nextCall = true
                        this.getClaims()
                    }
                }
            }
        },

    },
}
</script>

<style lang="scss" scoped>


.boxNoteAndName{
  min-width: 30px;
  .boxDown{
    min-width: 20px;
    width: 10%;
    text-align: center;
    .down{
      margin-top:5px;
      display: inline-block;
      transition: all ease 0.3s;
      cursor: pointer;
      &.rotate{
        transform:rotate(90deg)
      }
    }
  }
 
  .boxDown{
    display: inline-block;
    vertical-align: middle;
  }
}


.comentario{
  transition: all ease 0.3s;
  font-size: 0.813rem;
  margin-top: -4px;
  
  &.comentarioRecortado{
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    min-width: 0;
  }
}

.filters{
  padding:15px 0;
}
.block-count{
  font-size: 1.125rem;
}
.block-head{
  border-bottom:1px solid #DFE3E6;
}

</style>