<template lang="pug">
    v-row#boxMapCompetence.pa-0.ma-0            
        //GRID SENTIMIENTO
        div.margin-bottom-0.pa-0.col-12
            skeleton-card(v-if="isLoading")        
            v-card.gridFull.ma-0.pa-0(v-if="!isLoading")
                div(style="height: calc(100vh - 121px); width: 100%;position:relative")



                    v-col.col-filters.col-filters-big-home.pt-0.pb-0
                        div.filters
                            div.gr-filter-input-wrapper
                                span.label #[img.mr-2(:src="img.imgSites")] #[translate Opinion Sites]
                                div.menu
                                    one-select-tree.inline-block(:options="opinionSites" :itemsChecked="'internet'" :onlyNetwork="true" :itemCheckChild="siteSelected" @checked="onCheckOpinionsSites")  
                            div.filter.filtroDate
                                range-date

                            div.filter
                                filter-tree-menu( style="background-color:#ffffff;" :options="brandsCompetence" label-class="text-capitalize-first-letter" :itemsChecked="brandsCompetenceActive" @checked="onCheckPaintCompetence" id-key="code" label-key="name")

                    .d-flex
                        l-map(
                        v-if="showMap"
                        :zoom="zoom"
                        :maxZoom="18"
                        :minZoom="4"
                        :center="cords"
                        :options="mapOptions"
                        style="width: 75%;height: calc(100vh - 100px);"
                        class="flex-1"
                        @update:zoom="zoomUpdate"
                        @update:center="refreshLocation")
                            l-tile-layer(
                                :url="url"
                                :attribution="attribution"

                            )
                            img(:src="isAdverbia ? imgInfoAdverbia : img.imgInfo" style="position:absolute; right:30px; top: 60px;z-index:1000;box-shadow: 0 0 3px #ccc")
                            vue2-leaflet-marker-cluster(:options="getClusterOptions")
                                l-circle-marker( v-for="(c, index) in restaurants" :key="index" :lat-lng="[c.latitude, c.longitude]" :fillOpacity="0.6" :radius="radiusByPuntuation(c.review_count)" :fillColor="backgroundColorCenterMap(c)" :color="colorBorderByPuntuation(c)")
                                    marker-center-competence(:data="c")             
                            //vue2-leaflet-marker-cluster
                                l-circle-marker( v-for="(c, index) in restaurantsCompetence" :key="index" :lat-lng="[c.latitude, c.longitude]" :fillOpacity="0.6" :radius="radiusByPuntuation(c.review_count)" :fillColor="backgroundColorCenterMap(c)" :color="colorBorderByPuntuation(c)")
                                    marker-center-competence(:data="c")
                        <GetInfoCentersCompetenceChatGPT v-if="typeNode === 'center' " :centers="restaurants"></GetInfoCentersCompetenceChatGPT>
</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import commonMixins from "@/mixins/common.js"
import OneSelectTree from '@/components/filters/OneSelectTree.vue'
import RangeDate from '@/components/filters/RangeDates.vue'
import L from "leaflet"
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon, LCircleMarker } from "vue2-leaflet"
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import 'leaflet/dist/leaflet.css'
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
import SrvNetWork from '@/services/network.js'
import FilterTreeMenu from "@/components/filters/filterTreeMenu"
import MarkerCenterCompetence from "@/components/network/markerCenterCompetence"
import GetInfoCentersCompetenceChatGPT from "@/components/competence/GetInfoCentersCompetenceChatGPT.vue"
export default {
    mixins: [commonMixins],
    components: {
        SkeletonCard,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        LTooltip,
        LIcon,
        LCircleMarker,
        Vue2LeafletMarkerCluster,
        OneSelectTree,
        RangeDate,
        FilterTreeMenu,
        MarkerCenterCompetence,
        GetInfoCentersCompetenceChatGPT
    },
    data() {
        return {
            data: [],
            url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            currentZoom: 11.5,
            showParagraph: false,
            brandsCompetenceSelected: [],
            distance: 2000,
            mapOptions: {
                zoomSnap: 0.5
            },
            img: {
                imgRestaurant: require('@/assets/img/icon_restaurant.png'),
                imgRestaurantWhite: require('@/assets/img/restaurant-white.png'),
                imgSites: require('@/assets/img/filter-sites.png'),
                imgInfo: require('@/assets/img/infoMap.png'),
                imgInfoAdverbia: require('@/assets/img/infoMapAdverbia.png')
            },
            showMap: true
        }
    },
    created() {
        this.getRestaurants()

    },
    methods: {
        onCheckPaintCompetence(v) {
            this.brandsCompetenceSelected = v
        },
        getRestaurants() {
            this.$store.commit('LOADING', true)
            SrvNetWork.getRestaurantsMap()
                .then(res => {
                    if (res.status === 200) {
                        this.data = res.data
                        this.brandsCompetenceSelected = []
                        //this.brandsCompetenceSelected = this.brandsCompetence          
                        this.$store.commit('LOADING', false)
                    }
                }, () => {
                })
        },
        radiusByPuntuation(n_opinions) {
            if (n_opinions <= 10) {
                return 7
            } else if (n_opinions <= 30) {
                return 12
            } else {
                return 17
            }
        },
        colorBorderByPuntuation(c) {
            if (c.isOwn) {
                return '#000000'
            }
            if (c.review_rating_global == null) {
                return '#cccccc'
            }
            return this.getColorByNoteNormalized({ note: this.getNoteNormalized(c.review_rating_global), type: 'color' })
        },
        backgroundColorCenterMap(c) {
            if (c.review_rating_global == null) {
                return '#cccccc'
            }
            return this.getColorByNoteNormalized({ note: this.getNoteNormalized(c.review_rating_global), type: 'color' })
        },
        zoomUpdate(zoom) {
            this.currentZoom = zoom
        },
        refreshLocation(center) {
            let x = center
            x + center
        }
    },
    computed: {
        getDistance() {
            return this.$store.getters.getDistanceMap
        },
        getDistanceActive() {
            return this.$store.getters.getDistanceMapActive
        },
        getClusterOptions() {
            return {
                spiderfyOnMaxZoom: true,
                spiderfyDistanceMultiplier: 4,
                //spiderLegPolylineOptions: {smoothFactor: 3.0,weight: 2.5, color: "green", opacity: 0.5},
                iconCreateFunction: (cluster) => {
                    let childrens = cluster.getAllChildMarkers()
                    let nameClass = 'clusterCompetence'
                    for (let i = 0; childrens.length - 1 >= i; i++) {
                        if (childrens[i].options.color === '#000000') {
                            nameClass = 'clusterOwn'
                            break
                        }
                    }
                    const html = `<div><span>${cluster.getChildCount()}</span></div>`
                    return L.divIcon({
                        className: nameClass,
                        html,
                        iconSize: [40, 40],
                        iconAnchor: [40, 40]
                    });
                }
            }
        },
        restaurants() {
            const own = this.data.own_centers.map((e) => {
                e.isOwn = true
                return e
            })
            let res = this.data.competence_centers
            let resFiltered
            if (this.brandsCompetenceSelected.length > 0) {
                resFiltered = res.filter((e) => {
                    if (this.brandsCompetenceActive.includes(e.brand_name)) {
                        return true
                    }
                    return false
                })
            } else {
                resFiltered = res
            }
            return [...own, ...resFiltered]
        },
        brandsCompetence() {
            let brands = []
            if (this.data && this.data.competence_centers) {
                this.data.competence_centers.forEach((c) => {
                    if (!brands.includes(c.brand_name)) {
                        brands.push(c.brand_name)
                    }
                })
            }
            let brandsWithCode = brands.map((b) => {
                b = { code: b, name: b }
                return b
            })
            return brandsWithCode
        },
        brandsCompetenceActive() {
            return this.brandsCompetenceSelected.map(c => c.code || c.value)
        },
        zoom() {
            if (this.typeNode === 'center') {
                return 16
            }
            return 7
        },
        cords: function () {
            if (this.typeNode === 'center') {
                return [this.data.own_centers[0].latitude, this.data.own_centers[0].longitude]
            } else {
                if (this.prefixContry.indexOf('_es') > -1) {
                    // ESPAÑA
                    return ['40.418889', '-3.691944']
                } else if (this.prefixContry.indexOf('_pt') > -1) {
                    // PORTUGAL
                    return ['38.708042', '-9.139016']
                } else if (this.prefixContry.indexOf('_usa') > -1) {
                    // USA
                    return ['38.895', '-77.036667']
                } else if (this.prefixContry.indexOf('_fr') > -1) {
                    // FRANCIA
                    return ['48.856944', '2.351389']
                } else {
                    return ['40.418889', '-3.691944']
                }
            }
        },
        prefixContry() {
            let treeLocalStorage = this.$store.getters.getTreeSelected
            return treeLocalStorage.prefix
        }
    },
    watch: {
        getDistance(newVal) {
            if (newVal) {
                this.getRestaurants()
            }
        },
        getDistanceActive() {
            this.getRestaurants()
        }
    }
}
</script>

<style lang="scss" scoped>
#boxMapCompetence {
    .selectFilterBrandMap {
        position: inherit;
        top: 10px;
        left: 80px;
        z-index: 100;
        padding-left: 0;
        margin-top: 0;
        border: 0;

        .v-input__control {
            .v-input__slot {
                border: 0;
            }
        }
    }
}

.filters {
    display: flex;
    justify-content: flex-end;
    column-gap: 1em;
    position: absolute;
    left: 80px;
    top: 10px;
    z-index: 10;

    .filter {
        &.filtroDate {
            margin-right: 0;
        }
    }
}

#boxInfoMap {
    border-radius: 5px;
    background-color: #fff;
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 2;
}</style>