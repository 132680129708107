<template lang="pug">

  div
    dish-ranking-center(v-if="nodeLevel === 9")
    dish-ranking-global(v-else)

</template>

<script>
import Stores from "@/store";
import DishRankingGlobal from "@/views/FeedBack/Pages/DishRankingGlobal";
import DishRankingCenter from "@/views/FeedBack/Pages/DishRankingCenter";

export default {
  components: {DishRankingCenter, DishRankingGlobal},
  computed: {
    nodeLevel() {
      return Stores.getters.getNodeLevel
    }
  }
}
</script>

