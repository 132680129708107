<template lang="pug">
div(class="main-container")
    button(@click="show=!show" class="") {{ this.title }}
    div(v-if="show" class="options")
        ul
            li(v-for="(option, key) in options" :key="`${option}_${key}`" @click="setSelectedOption(option)") {{ option }}
    </div>
</template>

<script>
export default {
    props: {
        leftIcon: {
            type: String,
        },
        options: {
            type: Array,
        },
        title: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            show: false,
            selected: null
        }
    },
    watch: {
        title() {
            this.selected = this.title
        }
    },
    methods: {
        setSelectedOption(value) {
            this.selected = value
        }
    },
}
</script>

<style lang="scss" scoped>
.main-container {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    min-width: fit-content;

    font-size: 14px;
    line-height: 1.4;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    text-align: left;
    overflow: hidden;

    button {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        height: 34px;
        padding: 6px 30px;
        padding-left: 10px;
    }

    .options {
        position: absolute;
        margin-top: 1px;
        background-color: #fff;
        margin-bottom: 1px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        z-index: 2001;
        padding: 10px;

        ul {
            list-style-type: none;
            padding: 0;

            li {
                font-size: 0.75rem;
                color: #1976d2;
                line-height: 20px;
                margin-bottom: 12px;
                cursor: pointer;
            }
        }
    }
}
</style>