<template lang="pug">
    div
        div.margin-bottom-0.gridFull
          v-row#headerWithPagination(v-if="comments")
            v-col.contentOutPadding.text-left(md="8")
              span.totalComments {{ totalComments }} #[translate Comments]
            v-col.contentOutPadding.text-right#pagination(md="4")
              div
                span.numberOfPage {{ page }} #[translate of] {{ pageCount }} #[translate pages]
                v-pagination#paginatorComments(v-if="comments"
                  v-model="page"
                  :length="pageCount"
                  @input="handlePageChange"
                  )
                  
          v-row.flex-center
            v-col(md="12")
              div.filters
                span.downloadExcel.mr-5(@click="downloadExcelCustom()")
                  img(:src="imgCommons.download" width="10")
                span.labelFilter #[translate Comment type]:
                div.filter                  
                  multi-select.inline-block(:options="commentTypes" :itemsChecked="commentTypesSelected" @checked="onCheckCommentTypes")
                span.labelFilter #[translate Language]:
                div.filter                  
                  multi-select.inline-block(:options="commentLanguage" :itemsChecked="commentLanguageSelected" @checked="onCheckCommentLanguage")
                span.labelFilter #[translate Reason]:
                div.filter                  
                  multi-select.inline-block.optionsWidth(:options="reasonTypes" :itemsChecked="reasonTypesSelected"  @checked="onCheckReasonTypes")
                span.labelFilter #[translate Type of Consumption]:
                div.filter                  
                  multi-select.inline-block(:options="consumptionTypes" :itemsChecked="consumptionTypesSelected"   @checked="onCheckConsumptionTypes")
          v-row.contentOutPadding  
            v-col(md="12").contentOutPadding.tablaGR 
              v-data-table#tableComments(
              :headers="headers"
              :items="comments"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              class="elevation-1"
              :loading="loading"
              :loading-text="loadingtext"
              :server-items-length="totalComments"
              @page-count="pageCount = $event")
                  template(v-slot:item.sentiment="{ item }")
                    div.text-center
                      span.tipo(dark :class="{ 'tipoPositivo': (item.sentiment == 1),'tipoNegativo': (item.sentiment == 2),'tipoSinContratiempo': (item.sentiment == 3),'tipoConContratiempo': (item.sentiment == 4)}")
                      div(v-if="showDownComment(item)")
                        img.down(:src="imgCommons.down" :class="{'rotate':item.comentariocompleto}" @click="mostrarComentario(item)")
                  template(v-slot:item.text="{ item }")
                    div#block-comment
                      p.comentario(id="parraf" :style="'max-width: ' + totalWidth + 'px'" :class="{'comentarioRecortado': (!item.comentariocompleto)}" dark) {{ item.text }}
                      div.categories(v-if="item.categories && item.categories.length > 0")
                        span.category(v-for="category in item.categories" :class="'cat-' + category.id") {{ category.name }}
                  template(v-slot:item.center.name="{ item }")
                    span.btnCentro( dark) {{ item.center.name }}                    
                  template(v-slot:item.survey_type="{ item }")
                    span.text-origin( dark) {{ getNameComsuptionTypes(item.survey_type) }}
                  template(v-slot:item.source_date="{ item }")
                    span.text-date( dark) {{ item.source_date | moment("DD/MM/YYYY") }}
          v-row#itemsPagePagination 
            v-col.text-left(md="8")
              span #[translate Rows per page]: 
              v-select.selectItemsPerPage(v-model="itemsPerPage" :items="numbersItemsPerPage"
                :label="itemsPerPage.toString()"
                max-with="50"
                @change="handlePageSizeChange"
                solo)

            v-col.text-right(md="4")
              v-pagination.paginationFooter(v-if="comments"
                  v-model="page"
                  :length="pageCount"
                  @input="handlePageChange"
                  )
</template>

<script>
import MultiSelect from '@/components/filters/Multipleselect.vue'
import SrvSatisfaction from '@/services/satisfaction.js'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"

export default {
    components: {MultiSelect}, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
    mixins: [commonMixins],
    data(){
        return {
            comments: [], // COLECCION DE COMENTARIOS
            page: 1, // PAGINA EN LA QUE ESTAMOS
            pageCount: 0, // PAGINAS TOTALES
            itemsPerPage: 25, // ELEMENTOS POR PAGINA
            commentTypes: [ // ELEMENTOS FILTRO POR TIPO DE COMENTARIO
              {value: 1, text: this.$gettext('Positive'), box:'#89C445'},
              {value: 2, text: this.$gettext('Negative'), box:'#EC4444'},
              {value: 3, text: this.$gettext('Sin contratiempo'), box:'#414DE9'},
              {value: 4, text: this.$gettext('Con contratiempo'), box:'#BD10E0'}
            ],
            commentTypesSelected: [], // ELEMENTOS SELECCIONADOS DEL FILTRO
            commentLanguage: [ // ELEMENTOS FILTRO POR IDIOMA
              {value: 1, text: this.$gettext('Spanish')},
              {value: 2, text: this.$gettext('Catalan')},
              {value: 3, text: this.$gettext('English')},
              {value: 4, text: this.$gettext('Portuguese')}
            ],
            commentLanguageSelected: [], // ELEMENTOS SELECCIONADOS DEL FILTRO
            reasonTypes: [ // ELEMENTOS DEL FILTRO MOTIVO
              {value: 1, text: this.$gettext('Personal care')},
              {value: 2, text: this.$gettext('Speed')},
              {value: 3, text: this.$gettext('Puntuality')},
              {value: 4, text: this.$gettext('Product')},
              {value: 5, text: this.$gettext('Cleaning')},
              {value: 6, text: this.$gettext('Price')},
              {value: 7, text: this.$gettext('Others')}
            ],
            reasonTypesSelected: [], // ELEMENTOS SELECCIONADOS DEL FILTRO
            consumptionTypes: [ // ELEMENTOS DEL FILTRO TIPOS DE CONSUMO
              {value: 1, text: this.$gettext('Local')},
              {value: 2, text: this.$gettext('Take Away')},
              {value: 3, text: this.$gettext('Delivery')},
              {value: 4, text: 'Sin tipo'}
            ],
            consumptionTypesSelected: [], // ELEMENTOS SELECCIONADOS DEL FILTRO
            numbersItemsPerPage: [25,50,100], // OPCIONES DE NÚMERO DE COMENTARIOS POR PAGINA
            headers: [ // CABECERAS DE LA TABLE COMENTARIOS
              {
                text: this.$gettext('FEELING'),
                align: 'start',
                sortable: false,
                value: 'sentiment',
                width: '5%'
              },
              { text: this.$gettext('COMMENT'), value: 'text', sortable: false, width: '68%' },
              { text: this.$gettext('RESTAURANT'), value: 'center.name', sortable: false, width: '9%'},
              { text: this.$gettext('ORIGIN'), value: 'survey_type', sortable: false, width: '9%'},
              { text: this.$gettext('DATE'), value: 'source_date', sortable: false, width: '9%'},
            ],
            options: {}, // OPCIONES DE V-TABLE
            loading: true, // BOOLEANA PARA MOSTRAR LOADING EN LA TABLE
            loadingtext: this.$gettext('Loading comments'),
            totalComments: 0, // CONTADOR TOTAL DE COMENTARIOS
            totalWidth: 680
        }
    },
    // OBTENERMOS DEL STORE LOS FILTROS APLICADOS 
    created(){
     
      this.commentTypesSelected = this.$store.getters.getCommentTypesSelected
      this.commentLanguageSelected = this.$store.getters.getCommentLanguageSelected
      this.reasonTypesSelected = this.$store.getters.getReasonTypesSelected
      this.consumptionTypesSelected = this.$store.getters.getConsumptionTypesSelected
      if(this.$store.getters.getNode > 0){
        this.getCommentsWithFilter()    
      }
      if(this.comments.length == 0){
        if(this.$store.getters.getComments.length > 0){
          this.comments = this.$store.getters.getComments.results
          this.totalComments = this.$store.getters.getComments.count
          if(this.totalComments == 0){
            this.page = 0
          }
          this.pageCount = Math.ceil( this.totalComments / this.itemsPerPage )
          this.loading = false
        }         
      }
    },
    watch: {
      filterApply: function(){
          this.getCommentsWithFilter()
      },
      pageCount: function(val){
        return val;
      },
      totalWidth: function() {
        return this.totalWidth
      }

    },
    methods: {
      downloadExcelCustom () {
        this.downloadExcel(this.getRequestParams(), this.totalComments)
      },
      showDownComment: function(item){
        if(item.text && item.text.length > 80){
          return true
        }
        return true
      },
      totalCommentWidth() {
        setTimeout(() => { 
          if(document.getElementById('block-comment')){
            this.totalWidth = document.getElementById('block-comment').clientWidth
          }
        }, 100)
      },
      // SE PULSA PARA DESPLEGAR COMENTARIO COMPLETO
      // atributo "comentariocompleto mapeado en la petición al api"
      mostrarComentario: function(item){
        if(item.comentariocompleto == true){
          item.comentariocompleto = false
        }else{
          item.comentariocompleto = true
        }
      },
      // CUANDO APLICAMOS FILTRO POSICIONAMOS PAGINA INICIAL Y OBTENEMOS COMENTARIOS
      getCommentsWithFilter: function(){
        this.page = 1
        this.getComments()
      },
      // OBTENEMOS EL NOMBRE DEL TIPO DE SERVICIO
      getNameComsuptionTypes(val){
        let res = this.consumptionTypes.filter(element => element.value == val)
        if(res.length > 0){
          return res[0].text
        }
      },
      // FUNIONES LLAMADAS DESDE EL COMPONENTE MULTISELECT CON EMMIT
      onCheckCommentTypes(val) {
        this.commentTypesSelected = val
        this.$store.commit(types.SATISFACTION_FILTER_COMMENT_TYPES_SELECTED, val)
        this.getCommentsWithFilter()
      },
      onCheckCommentLanguage(val) {
        this.commentLanguageSelected = val
        this.$store.commit(types.SATISFACTION_FILTER_COMMENT_LANGUAGES_SELECTED, val)
        this.getCommentsWithFilter()
      },
      onCheckReasonTypes(val) {
        this.reasonTypesSelected = val
        this.$store.commit(types.SATISFACTION_FILTER_REASON_TYPES_SELECTED, val)
        this.getCommentsWithFilter()
      },
      onCheckConsumptionTypes(val) {
        this.consumptionTypesSelected = val
        this.$store.commit(types.SATISFACTION_FILTER_CONSUMPTION_TYPES_SELECTED, val)
        this.getCommentsWithFilter()
      },
      // LLAMAMOS AL SERVICIO QUE CARGA LOS COMENTARIOS CON LOS FILTROS APLICADOS
      getComments: function(){
        const params = this.getRequestParams()
        this.loading = true

        SrvSatisfaction.getSatisfactionComments(params)
        .then(res => {
          if (res.status === 200) {            
            // MAPEAR COMENTARIOS PARA AÑADIR CAMPO
            let custom = res.data.results.map((obj)=>({...obj,['comentariocompleto']:false}))
            this.comments = custom;
            this.totalComments = res.data.count
            this.$store.commit(types.SATISFACTION_COMMENTS, {results: custom, count: res.data.count})
            if(this.totalComments == 0){
              this.page = 0;
            }
            this.pageCount = Math.ceil( this.totalComments / this.itemsPerPage )
            this.loading = false
            this.totalCommentWidth()
          }
        }, () => {
        })
      },
      // SE ACTUALIZA EL NÚMERO DE PAGINA
      handlePageChange(value) {
        this.page = value
        this.getComments()
      },
      // SE CARGAN DE NUEVO LOS COMENTARIOS MOSTRANDO POR PANTALLA EL NÚMERO DE ELEMENTOS SELECCIONADO
      handlePageSizeChange(size) {
        this.itemsPerPage = size
        this.page = 1
        this.getComments()
      },
      // PREPARAMOS LOS FILTROS A APLICAR EN LA LLAMADA AL API
      getRequestParams() {
        let params = {};
        if ((this.itemsPerPage * this.page) - this.itemsPerPage > 0) {
          params["offset"] = (this.itemsPerPage * this.page) - this.itemsPerPage
        }
        params["limit"] = this.itemsPerPage
        if(this.commentLanguageSelected){
          params["language"] = this.commentLanguageSelected
        }
        if(this.commentTypesSelected){
          params["sentiment"] = this.commentTypesSelected
        }
        if(this.reasonTypesSelected){
          params["reason"] = this.reasonTypesSelected
        }
        if(this.consumptionTypesSelected){
          params["survey_type"] = this.consumptionTypesSelected
        }
         
        return params
      },
    }
}
</script>

<style lang="scss" scoped>
.categories{
  margin-bottom: 15px;
  .category{
    background-color: #eaeaea;
    border-radius: 15px;
    padding: 2px 10px;
    font-size: 0.75rem;
    color:#fff;
    margin-right: 7px;
    &.cat-14{
      background-color: #379E91;
    }
    &.cat-5{
      background-color: #379E91;
    }
    &.cat-4{
      background-color: #CB655D;
    }
    &.cat-3{
      background-color: #744D60;
    }
    &.cat-2{
      background-color: #F59643;
    }
    &.cat-1{
      background-color: #919ECA;
    }
  }
  
}
.down{
  margin-top:10px;
  display: inline-block;
  transition: all ease 0.3s;
  cursor: pointer;
  &.rotate{
    transform:rotate(90deg)
  }
}
.comentario{
  transition: all ease 0.3s;
  font-size: 0.813rem;
  margin-top: -4px;
  
  &.comentarioRecortado{
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
    min-width: 0;
  }
}
.tipo{
  width: 15px;
  height: 15px;
  display: block;
  border-radius:3px;
  margin: 0 auto;
}

.totalComments{
  display: inline-block;
  padding:5px 12px 3px 12px;
  font-size: 1.125rem;
  font-weight: 700;  
}
.filters{
  padding:15px 0;
  border-bottom:1px solid #DFE3E6;
  display: flex;
  align-items: center;
  column-gap: normal;
}

</style>