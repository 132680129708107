<template lang="pug">
div
  div.margin-bottom-0.gridFull
    v-row.flex-center.b-bottom.block-head.mb-0
      v-col(md="2")
        .block-count.pl-1
          span.mr-1.font-weight-med {{reportCounts}}
          translate Reports


      v-col(md="9")
        div.filters
          span.labelFilter #[translate Periodo]:
            div.filter.ml-2
              filter-tree-menu(:options="reportsTypesLoad" label-class="text-capitalize-first-letter" :itemsChecked="reportTypesSelected" @checked="onCheckReportTypes" id-key="code" label-key="name")
      v-col.text-right(md="1")
        .block-help.ml-auto
          v-tooltip(left color="#222226" open-on-click=true max-width="550" nudge-top="20" nudge-left="10" content-class="tooltip-help tooltip-help-left")
            template(v-slot:activator="{ on, attrs }")
              img(:src="imgCommons.help" v-bind="attrs" v-on="on")
            div
              p.mb-0.pb-0(v-if="!isAdverbia") Opción de descargar los informes, en función del grupo de restaurantes que marca la selección del menú izquierdo. 
              p.mb-0.pb-0(v-else) Opción de descargar los informes, en función del grupo de negocios que marca la selección del menú izquierdo. 
              p.mb-0.pb-0 Nota importante: los datos que se muestran en el informe, serán los datos que haya en el momento de la descarga. Posteriormente pueden variar por la demora de entrada de datos en los sites
    v-row.contentOutPadding.mt-0
      v-col(md="12").contentOutPadding.tablaGR
        v-data-table(
        :headers="headers"
        :items="reports"
        :page.sync="reports.length / 19"
        :items-per-page="reportCounts"
        hide-default-footer
        class="elevation-1"
        item-key="idGenerate"
        :loading="loading"
        :loading-text="loadingtext"
        @
        )
          template(v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }")
            span {{ header.text }}                  
          template(v-slot:item.report="{ item }")
            div#block-comment
              span(:class="{'paddingAnual': item.period === 'year', 'paddingTrimestral': item.period === 'quarter', 'paddingMensual': item.period === 'month', 'paddingSemanal': item.period === 'week', }") {{ getReportName(item) }}
          template(v-slot:item.type="{ item }")
            span.type(:class="{'paddingAnual': item.period === 'year', 'paddingTrimestral': item.period === 'quarter', 'paddingMensual': item.period === 'month', 'paddingSemanal': item.period === 'week', }") {{ getTypeReport(item.period) }}
          template(v-slot:item.start_date="{ item }")
            span.text-date( dark) {{ item.start_date | moment("DD/MM/YYYY") }}
          template(v-slot:item.end_date="{ item }")
            span.text-date( dark) {{ item.end_date | moment("DD/MM/YYYY") }}
          template(v-slot:item.view="{ item }").text-center
            img.cursor-pointer(:src="img.pdf" width="24" @click="handlePdfClick(item)")

    modal-filters(:visible="showFiltersModal"  @close="showFiltersModal=false" @confirmed="downloadReport")
</template>

<script>
import SrvReports from '@/services/reports.js'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import FilterTreeMenu from "@/components/filters/filterTreeMenu"
import ModalFilters from '@/components/reports/modalFilters.vue'

export default {
  mixins: [commonMixins],
  components: { FilterTreeMenu, ModalFilters }, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
  data() {
    return {
      img: {
        pdf: require('@/assets/img/ico-pdf.svg'),
      },
      reports: [],
      reportsTypes: [],
      item: null,
      headers: [ // CABECERAS DE LA TABLE COMENTARIOS
        {
          text: this.$gettext('INFORME'),
          align: 'start',
          sortable: false,
          value: 'report',
          width: '37%'
        },
        { text: this.$gettext('Periodo').toUpperCase(), value: 'type', sortable: false, width: '27%' },
        { text: this.$gettext('FROM'), value: 'start_date', sortable: false, width: '15%' },
        { text: this.$gettext('TO'), value: 'end_date', sortable: false, width: '15%' },
        { text: this.$gettext('VIEW'), value: 'view', sortable: false, width: '6%' }
      ],
      options: {}, // OPCIONES DE V-TABLE
      loading: false, // BOOLEANA PARA MOSTRAR LOADING EN LA TABLE
      loadingtext: this.$gettext('Loading reports'),
      totalWidth: 0,
      nextScrollUrl: null,
      nextCall: false,
      reportCounts: 0,
      showFiltersModal: false
    }
  },
  // OBTENERMOS DEL STORE LOS FILTROS APLICADOS
  async created() {
    await SrvReports.getReportsTypes()
      .then(res => {
        if (res.status === 200) {
          this.reportsTypes = res.data.results
          this.loading = false
        } else {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        }
      }, () => {
        // this.message = 'Se ha producido un error. Intentelo más tarde.'
      })
    if (this.$store.getters.getNode > 0) {
      this.getReports()
    }
    if (this.reports.length === 0) {
      if (this.$store.getters.getReports.length > 0) {
        this.reports = this.$store.getters.getReports.results
        this.loading = false
      }
    }
  },
  mounted() {
    this.scroll()
  },
  watch: {
    filterApply: function () {
      this.nextScrollUrl = null
      this.getReports()
    },
    totalWidth: function () {
      return this.totalWidth
    },
    reports(reports) {
      const data = {}
      reports.forEach(item => data[item.id] = item)
      window.data = data
    }
  },
  computed: {
    reportsTypesLoad() {
      return this.reportsTypes
    },
    reportTypesSelected() {
      return this.$store.getters.getReportTypesSelected.map(c => c.value)
    }
  },
  methods: {
    handlePdfClick(item) {
      this.item = item;
      this.showFiltersModal = true
    },
    getReportName(item, separador = ' ') {
      let name = ''
      let oStartDate = item.start_date.split('-')
      let oEndDate = item.end_date.split('-')
      if (item.period === 'year') {
        name = oStartDate[0]
      } else if (item.period === 'quarter') {
        name = this.months[parseInt(oStartDate[1]) - 1] + separador + 'a' + separador + this.months[parseInt(oEndDate[1]) - 1] + separador + 'del' + separador + oEndDate[0]
      } else if (item.period === 'month') {
        name = oStartDate[2] + separador + 'a' + separador + oEndDate[2] + separador + 'de' + separador + this.months[parseInt(oEndDate[1]) - 1]
      } else if (item.period === 'week') {
        name = oStartDate[2] + separador + 'a' + separador + oEndDate[2] + separador + 'de' + separador + this.months[parseInt(oEndDate[1]) - 1]
      }
      return name
    },
    downloadReport(obj) {
      this.$store.commit(types.DOWNLOADING_EXCEL, true)
      SrvReports.getReportDownload(this.item.start_date, this.item.end_date, this.item.period, obj)
        .then(res => {
          if (res.status === 200) {
            var blob = new Blob([res.data], {
              type: res.headers["content-type"],
            })
            const link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            let type = 'N'
            let id
            if (this.item.center) {
              type = 'C'
              id = this.item.center.id
            } else {
              id = this.item.node.id
            }

            let center = document.querySelector('[data-id="' + id + '"][data-type="' + type + '"]')
            let elementName = center.getAttribute('data-name')
            let name = elementName.replaceAll(' ', '-')
            link.download = `report_${name}_${this.getTypeReport(this.item.period)}_${this.getReportName(this.item, '-')}.pdf`
            this.$store.commit(types.DOWNLOADING_EXCEL, false)
            link.click()

          }
        }, () => {
        }).finally(() => { this.showFiltersModal = false })
    },
    getTypeReport(type) {
      let typeName = ''
      if (type === 'week') {
        typeName = this.$gettext('Weekly')
      } else if (type === 'month') {
        typeName = this.$gettext('Monthly')
      } else if (type === 'quarter') {
        typeName = this.$gettext('Quarterly')
      } else if (type === 'year') {
        typeName = this.$gettext('Yearly')
      }
      return typeName
    },
    // FUNIONES LLAMADAS DESDE EL COMPONENTE MULTISELECT CON EMMIT
    onCheckReportTypes(val) {
      this.$store.commit(types.REPORT_FILTER_TYPES_SELECTED, val);
      this.nextScrollUrl = null
      this.getReports()
    },
    // LLAMAMOS AL SERVICIO QUE CARGA LOS COMENTARIOS CON LOS FILTROS APLICADOS
    sortArray(arrFiltered) {
      // Sort array
      arrFiltered = arrFiltered.sort((a, b) => {
        if (new Date(a.end_date) > new Date(b.end_date)) return -1
        if (new Date(a.end_date) < new Date(b.end_date)) return 1
        if (b.period_days > a.period_days) return 1
        if (b.period_days < a.period_days) return -1
      })
      return arrFiltered
    },
    getReports: function () {
      if (!this.nextScrollUrl) {
        SrvReports.getReports()
          .then(res => {
            if (res.status === 200) {
              this.reports = this.sortArray(res.data.results)
              this.reportCounts = res.data.count
              this.nextScrollUrl = res.data.next
              //this.$store.commit(types.FEEDBACK_OPINIONS, {results: custom})
              this.loading = false
            } else {
              // this.message = 'Se ha producido un error. Intentelo más tarde.'
            }
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      } else {
        SrvReports.getReportsNext(this.nextScrollUrl)
          .then(res => {
            if (res.status === 200) {
              this.nextScrollUrl = res.data.next
              this.reportCounts = res.data.count
              res.data.results.forEach(element => {
                this.reports.push(element)
              });
              this.reports = this.sortArray(this.reports)
              //this.$store.commit(types.FEEDBACK_OPINIONS, {results: custom})
              this.nextCall = false
            } else {
              // this.message = 'Se ha producido un error. Intentelo más tarde.'
            }
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      }
    },
    scroll() {
      window.onscroll = () => {
        if (this.$route.name === 'ReportsList') {
          var body = document.body,
            html = document.documentElement;
          var height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);
          height = parseInt(height, 10) - 1
          let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height || parseInt((document.documentElement.scrollTop + window.innerHeight).toFixed(0), 10) + 1 == height || (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height + 1
          if (this.nextScrollUrl && bottomOfWindow && !this.nextCall) {
            this.nextCall = true
            this.getReports()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.paddingAnual {
  color: #000;
  font-weight: 600;
}

.paddingTrimestral {
  color: #FF7700;
  padding-left: 30px;
}

.paddingMensual {
  padding-left: 60px;
  color: #417505;
}

.paddingSemanal {
  padding-left: 90px;
  color: #8143B8;
}

.type {
  padding-left: 0;
}

.filters {
  padding: 15px 0;
}

.block-count {
  font-size: 1.125rem;
}

.block-head {
  border-bottom: 1px solid #DFE3E6;
}
</style>
