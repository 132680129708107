// Importamos mutaciones
import * as types from "@/store/mutation-types";

const subnav = {
  state: {
    open: true,
    breadcrumb: "",
    openIds: [],
    node: null,
    nodeLevel: null,
    treeData: [],
    treeDataWithOutFilter: [],
    showTree: true,
    centerNoTree: null,
    notificationStats: null,
  },
  mutations: {
    [types.OPEN_SUBNAV](state, data) {
      state.open = data;
    },
    [types.BREAD_CRUMB](state, data) {
      state.breadcrumb = data;
    },
    [types.TREE_OPEN_IDS](state, data) {
      state.openIds = data;
    },
    [types.TREE_SHOW_TREE](state, data) {
      state.showTree = data;
    },
    [types.SET_NODE](state, data) {
      state.node = data;
    },
    [types.SET_NODE_LEVEL](state, data) {
      state.nodeLevel = data;
    },
    [types.SET_TREE_DATA](state, data) {
      state.treeData = data;
    },
    [types.SET_TREE_DATA_WITHOUT_FILTER](state, data) {
      state.treeDataWithOutFilter = data;
    },
    [types.TREE_CENTER_NO_TREE](state, data) {
      state.centerNoTree = data;
    },
    [types.LOCAL_MARKETING_NOTIFICATIONS_STATS](state, data) {
      state.notificationStats = data;
    },
  },
  getters: {
    getOpen: (state) => {
      return state.open;
    },
    getBreadcrumb: (state) => {
      return state.breadcrumb;
    },
    getOpenIds: (state) => {
      return state.openIds;
    },
    getNode: (state) => {
      return state.node;
    },
    getNodeLevel: (state) => {
      return state.nodeLevel;
    },
    getTreeData: (state) => {
      return state.treeData;
    },
    getTreeDataWithOutFilter: (state) => {
      return state.treeDataWithOutFilter;
    },
    getShowTree: (state) => {
      return state.showTree;
    },
    getCenterNoTree: (state) => {
      return state.centerNoTree;
    },
    getNotificationStats: (state) => {
      return state.notificationStats;
    },
  },
};

export default subnav;
