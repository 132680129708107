<template lang="pug">
div
    v-dialog.dialogEdit(v-model="show"
        width="680")
        v-card.dialogEdit
            v-card-text.pb-0
            v-container
                div.gr-loader-snipper--wrapper(v-if="loading")
                    span.gr-loader-snipper
                v-row#headerModalEdit.pt-3
                    v-col(cols="10")
                        span(v-if="!isGenericEntity") #[translate Restaurant details]
                        span(v-else) Detalles del negocio
                    v-col.mt-5(cols="2")
                        img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
                v-row#bodyModalEdit
                    v-col(cols="12")
                        v-container
                            v-row
                                v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                                    div.messageError
                                        span {{ $gettext('An error occurred while recording.') }}
                                v-col.text-left.pt-6.pb-0(cols="12")
                                    span #[translate Short description]
                                    textarea.textareaFullEditModal(v-model="data.short_description" @input="checkLimitTextarea('short_description', limitShortDescription)")
                                v-col.text-left.pb-0(cols="12")
                                    span #[translate Long description]
                                    textarea.textareaFullEditModal.long(v-model="data.long_description" @input="checkLimitTextarea('long_description', limitLongDescrioption)")
                                v-col.text-left.pb-0(cols="12")
                                    span #[translate Keywords that stand out from the business]
                                    input.inputFullEditModal(type="text" v-model="data.keywords")
                                v-col.text-left.pb-0(cols="12")
                                    span #[translate Languages served]
                                    input.inputFullEditModal(type="text" v-model="data.languages")
                                v-col.text-left.pb-0(cols="12")
                                    span #[translate Special services]
                                    textarea.textareaFullEditModal(v-model="data.special_services")
                                v-col.text-left.pb-0(cols="12" v-if="hasMenu && !isGenericEntity")
                                    span #[translate Pick-up service at the establishment]
                                    .optionsFullEditModal
                                        input(type="radio" v-model="data.has_pickup" id="servicioRecogidaSi" value="true" name="servicioRecogida" )
                                        label.ml-3(for="servicioRecogidaSi") #[translate Yes]
                                        input.ml-10(type="radio" v-model="data.has_pickup" id="servicioRecogidaNo" value="false" name="servicioRecogida")
                                        label.ml-3(for="servicioRecogidaNo") #[translate No]
                                v-col.text-left.pb-0(cols="12" v-if="hasMenu && !isGenericEntity")
                                    span #[translate Home delivery service]
                                    .optionsFullEditModal
                                        input(type="radio" v-model="data.has_delivery" @click="hasDeliveryRestaurant = true" id="servicioDomicilioSi" value="true" name="servicioDomicilio" )
                                        label.ml-3(for="servicioDomicilioSi") #[translate Yes]
                                        input.ml-10(type="radio" v-model="data.has_delivery" @click="hasDeliveryRestaurant = false" id="servicioDomicilioNo" value="false" name="servicioDomicilio")
                                        label.ml-3(for="servicioDomicilioNo") #[translate No]
                                v-col.colDeliveryServices(md="12" v-if="!isGenericEntity")
                                    v-row
                                        v-col(md="6")
                                            v-row
                                                .boxDeliveryService
                                                    v-col.pb-0.text-left(md="12")
                                                        input(type="checkbox" v-model="data.own_delivery_service" :disabled="!hasDeliveryRestaurant")
                                                        span.ml-2.d-inline-block #[translate Own]
                                                    v-col.urlDeliveryService(md="12")
                                                        input.inputFullEditModal(type="text" :class="{'textDisabled': !hasDeliveryRestaurant}" v-model="data.url_own_delivery" :disabled="!hasDeliveryRestaurant")
                                        v-col(md="6")
                                            v-row
                                                .boxDeliveryService(class="noService")
                                                    v-col.pb-0.text-left(md="12")
                                                        input(type="checkbox" v-model="data.other_delivery_service"  :disabled="!hasDeliveryRestaurant")
                                                        img.ml-2.d-inline-block(:src="img.deliveryOtro" width="29")
                                                        span.ml-2.d-inline-block #[translate Other]
                                                    v-col.urlDeliveryService(md="12")
                                                        input.inputFullEditModal(type="text" :class="{'textDisabled': !hasDeliveryRestaurant}" v-model="data.url_other_delivery" :disabled="!hasDeliveryRestaurant")
                                        v-col(md="6" v-if="data.center_sites_delivery")
                                            v-row
                                                .boxDeliveryService
                                                    v-col.pb-0.text-left(md="12")
                                                        input(type="checkbox" v-model="data.center_sites_delivery.delivery_sites.GLV.active" :disabled="!hasDeliveryRestaurant")
                                                        img.ml-2.d-inline-block(:src="img.deliveryGlovo" width="64")
                                                    v-col.urlDeliveryService(md="12")
                                                        input.inputFullEditModal(type="text" :class="{'textDisabled': !hasDeliveryRestaurant}" v-model="data.center_sites_delivery.delivery_sites.GLV.url" :disabled="!hasDeliveryRestaurant")
                                        v-col(md="6" v-if="hasMenu && data.center_sites_delivery")
                                            v-row
                                                .boxDeliveryService(class="noService")
                                                    v-col.pb-0.text-left(md="12")
                                                        input(type="checkbox" v-model="data.center_sites_delivery.delivery_sites.JUS.active" :disabled="!hasDeliveryRestaurant")
                                                        img.ml-2.d-inline-block(:src="img.deliveryJusteat" width="64")
                                                    v-col.urlDeliveryService(md="12")
                                                        input.inputFullEditModal(type="text" :class="{'textDisabled': !hasDeliveryRestaurant}" v-model="data.center_sites_delivery.delivery_sites.JUS.url" :disabled="!hasDeliveryRestaurant")
                                        v-col(md="6" v-if="hasMenu && data.center_sites_delivery")
                                            v-row
                                                .boxDeliveryService(class="noService")
                                                    v-col.pb-0.text-left(md="12")
                                                        input(type="checkbox" v-model="data.center_sites_delivery.delivery_sites.UBE.active" :disabled="!hasDeliveryRestaurant")
                                                        img.ml-2.d-inline-block(:src="img.deliveryUbereats" width="48")
                                                    v-col.urlDeliveryService(md="12")
                                                        input.inputFullEditModal(type="text" :class="{'textDisabled': !hasDeliveryRestaurant}" v-model="data.center_sites_delivery.delivery_sites.UBE.url" :disabled="!hasDeliveryRestaurant")

                v-row#footerModalEdit
                    v-col(cols="12")
                        button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
                        button.btnSaveEditModal(@click="save()")  #[translate Save]
</template>
<script>
import commonMixins from '@/mixins/common.js'
import SrvLocal from '@/services/local.js'

export default {
    mixins: [commonMixins],
    props: ['visible', 'idBussines', 'hasMenu'],
    data() {
        return {
            loading: false,
            imgClose: require('@/assets/img/ico-close-edit.svg'),
            name: '',
            servicioRecogida: 'si',
            servicioDomicilio: 'si',
            data: {},
            hasDeliveryRestaurant: false,
            limitShortDescription: 300,
            limitLongDescrioption: 750,
            img: {
                deliveryOtro: require('@/assets/img/delivery-otro.svg'),
                deliveryUbereats: require('@/assets/img/delivery-ubereats.svg'),
                deliveryGlovo: require('@/assets/img/delivery-glovo.svg'),
                deliveryDeliveroo: require('@/assets/img/delivery-deliveroo.svg'),
                deliveryJusteat: require('@/assets/img/delivery-justeat.svg')
            },
            section: 'details-restaurant',
            saveStatus: true
        }
    },
    watch: {
        visible(newValue) {
            if (newValue) {
                this.data = {}
                this.loadData()
            }
        }
    },
    computed: {
        show: {
            get() {
                return this.visible
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    methods: {

        async loadData() {
            //CARGAMOS DATOS
            this.loading = true
            await SrvLocal.getLocalMarketing(this.section)
                .then(res => {
                    if (res.status === 200) {
                        this.data = res.data
                        this.hasDeliveryRestaurant = this.data.has_delivery
                        this.loading = false
                    }
                }, () => {
                })
        },
        async save() {
            this.loading = true
            if (!this.hasDeliveryRestaurant) {
                if (this.hasMenu) {
                    this.data.center_sites_delivery.delivery_sites.DEL.active = false
                    this.data.center_sites_delivery.delivery_sites.DEL.url = ''
                    this.data.center_sites_delivery.delivery_sites.JUS.active = false
                    this.data.center_sites_delivery.delivery_sites.JUS.url = ''
                    this.data.center_sites_delivery.delivery_sites.UBE.active = false
                    this.data.center_sites_delivery.delivery_sites.UBE.url = ''
                }
                this.data.center_sites_delivery.delivery_sites.GLV.active = false
                this.data.center_sites_delivery.delivery_sites.GLV.url = ''
            }
            await SrvLocal.setLocalMarketing(this.section, this.data)
                .then(res => {
                    if (res.status !== 200) {
                        this.saveStatus = false
                    }
                }, () => {
                })
            if (this.saveStatus) {
                await SrvLocal.syncBunsiness(this.idBussines, 'only_detail_info').then()
                this.show = false
                this.$emit('refreshSync')
            }
            this.loading = false
        }
    }
}
</script>
<style lang="scss" scoped>
.boxDeliveryService {
    background-color: #F2F2F2;
    border-radius: 4px;
    width: 100%;
    margin: 6px;

    &.noService {
        .urlDeliveryService {
            color: #CECECE;
        }
    }

    img {
        vertical-align: middle;
    }
}

.long {
    height: 136px;
}

.messageError {
    background-color: #F5B1B8;
    border: 1px solid #F93549;
    color: #F93549;
    padding: 15px;
}

#bodyModalEdit {
    .inputFullEditModal {
        &.textDisabled {
            background-color: #f8f8f8;
            color: #CECECE;
        }
    }
}
</style>
