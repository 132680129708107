// Stores
import Stores from '@/store/index'
// importamos los tipos de mutación de vuex
import * as types from '@/store/mutation-types'
// Config
import appConfig from '@/config-app.js'
// funciones comunes
import SrvCommon from '@/services/common'

// Axios
import axios from 'axios'

axios.defaults.baseURL = appConfig.api
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

export default {

  getGastroRank() {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING)

      let query = `/gastrorank/`;

      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `?center=${Stores.getters.getNode}`
        }
      } else {
        if (Stores.getters.getNode) {
          query += `?node=${Stores.getters.getNode}`
        }
      }

      query += `&tree=${Stores.getters.getTreeSelected.id}`

      let token = SrvCommon.getToken();

      axios.defaults.headers.common['Authorization'] = 'Token ' + token
      axios.get(query)
        .then(res => {
          Stores.commit(types.NOT_PENDING)
          resolve(res)
        })
        .catch(error => {
          Stores.commit(types.NOT_PENDING)
          const status = error.response.status
          if (status === 401) {
            SrvCommon.logout()
          }
          reject(error)
        })
    })
  }
}
